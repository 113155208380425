import { TrackEvent } from './TrackEvent';

export class InsuranceInfoCollection extends TrackEvent {
  _eventName = 'insurance_info_collection';

  constructor(payload: {
    /**
     * Updated insurance quote in dollars PER TICKET.
     */
    final_quote: number;
    /**
     * Number of tickets being purchased (seatCount)
     */
    quantity: number;
    /**
     * Total purchase price in dollars including fees and taxes, subtracting
     * promo discounts
     */
    post_fee_price: number;
  }) {
    super({ payload: payload as unknown as null });
  }
}
