import React from 'react';

import { Click } from 'analytics/events/Click';
import AlarmClock from 'icons/AlarmClockIcon';
import FlashIcon from 'icons/FlashIcon';
import { MODALS } from 'store/modules/modals/modals';
import colors from 'styles/colors.constants';
import { storage } from 'utils/storage';

export const dealsData = {
  flash_deal: {
    subTitle: 'Exclusive Discount',
    title: '⚡Flash Deal⚡',
    titleSlider: '⚡Flash Deals⚡',
    tagTitle: 'Flash Deal',
    tagIcon: (fill = 'black') => (
      <FlashIcon width="14" height="14" fill={fill} />
    ),
    analytics: 'flash',
    color: colors.yellowTernary,
    shadow: 'rgba(255, 237, 76, 0.2)',
    modal: MODALS.FLASH_DEAL_INFO,
    interaction: Click.INTERACTIONS.FLASH_DEAL_INFO(),
  },
  zone_deal: {
    subTitle: '15 Minutes to Book',
    title: 'Zone Deal',
    titleSlider: 'Zone Deals',
    tagTitle: 'Zone Deal',
    tagIcon: (fill = 'black') => (
      <AlarmClock width="14" height="14" fill={fill} />
    ),
    analytics: 'zone',
    color: colors.gametimeGreenLight,
    shadow: 'rgba(101, 255, 181, 0.2)',
    modal: MODALS.ZONE_DEAL_INFO,
    interaction: Click.INTERACTIONS.ZONE_DEAL_INFO(),
  },
};

export const dealTypes = {
  zoneDeal: 'zone_deal',
  flashDeal: 'flash_deal',
  bestDeal: 'best',
  featuredDeal: 'featured',
};

export const ZONE_DEALS_EVENT_STORAGE_KEY = 'ZONE_DEALS_EVENT';
export const SB_EVENT_STORAGE_KEY = 'SB_EVENT_STORAGE_KEY';

/**
 * @deprecated just use the Listing model dealType
 */
export function isListingZoneDeal(listing) {
  return listing?.dealType === 'zone';
}

/**
 * @deprecated just use the Listing model dealType
 */
export function isListingFlashDeal(listing) {
  return listing?.dealType === 'flash';
}

export function saveUnlockZoneDealInfo(eventId) {
  const { appendItem } = storage(ZONE_DEALS_EVENT_STORAGE_KEY);

  const zoneDeal = {
    eventId: eventId,
    showInfoModal: true,
  };
  appendItem(zoneDeal);
  return zoneDeal;
}

export function getUnlockedZoneDealInfo(eventId) {
  const data = storage(ZONE_DEALS_EVENT_STORAGE_KEY).getItem() || [];
  const unlockedEvents = Array.isArray(data) ? data : [data];

  return unlockedEvents.find(
    (unlockedEvent) => unlockedEvent.eventId === eventId
  );
}

export function updateDealInfo(eventId, data) {
  const { updateItem } = storage(ZONE_DEALS_EVENT_STORAGE_KEY);
  updateItem((accum, item) => {
    if (item.eventId === eventId) {
      accum.push({ ...item, ...data });
    } else {
      accum.push(item);
    }
    return accum;
  });
}

export function hasSeenSBModal(eventId) {
  const superBowlId = storage(SB_EVENT_STORAGE_KEY).getItem();
  return superBowlId === eventId;
}

export function setSBModalSeen(eventId) {
  const { setItem } = storage(SB_EVENT_STORAGE_KEY);

  setItem(eventId);
}
