import { REDUCERS } from 'store/modules/reducer.constants';

export const userPurchaseCreditCard = (globalState) => {
  return globalState[REDUCERS.PURCHASE].creditCard;
};

export const userPurchaseZipSelector = (globalState) =>
  userPurchaseCreditCard(globalState).zip;

export const userPurchaseCardsFetchedSelector = (globalState) =>
  userPurchaseCreditCard(globalState).cardsFetched;
