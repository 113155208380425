import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMaskito } from '@maskito/react';
import { Form, Formik } from 'formik';
import Mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import SimpleButton, { TYPES } from 'components/Buttons/SimpleButton';
import TextInput from 'components/Inputs/TextInput';
import { EmailFillIcon, PhoneFillIcon } from 'icons';
import { profileFormHasChanges } from 'pages/MyAccount/utils';
import {
  setFormSubmitted as setFormSubmittedDispatch,
  setFormSubmitting as setFormSubmittingDispatch,
} from 'store/modules/app/app.ui';
import { updateUser as updateUserDispatch } from 'store/modules/user/actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import colors from 'styles/colors.constants';
import { maskitoPhoneOptions, normalizePhoneForMaskito } from 'utils/phone';

import { VALID_EMAIL_REGEX } from '../../../../constants';

import styles from './ProfileForm.module.scss';

const ProfileFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(VALID_EMAIL_REGEX, 'Invalid email address')
    .trim()
    .required('Please enter an email address.'),
  phone: Yup.string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter a valid phone number.'
    )
    .trim()
    .required('Please enter a phone number'),
  firstName: Yup.string().trim().required('Please enter a first name.'),
  lastName: Yup.string().trim().required('Please enter a last name.'),
});

const ProfileForm = ({
  formCallback,
  updateUser,
  user,
  setLoading,
  setLoaded,
}) => {
  const analytics = useAnalyticsContext();

  const { email, first_name, last_name, phone } = user;

  Mailcheck.defaultDomains.push('gametime.co');
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [error, setError] = useState(null);

  const clickTracker = new ClickTracker()
    .targetPageType(Click.TARGET_PAGE_TYPES.NONE())
    .sourcePageType(Click.SOURCE_PAGE_TYPES.PROFILE())
    .interaction(Click.INTERACTIONS.SAVE_PROFILE());

  const onSubmitHandler = async (values, { setSubmitting }) => {
    analytics.track(new Click(clickTracker.json()));
    setError(null);
    setLoading(true);
    const newValues = {
      ...user,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
    };

    try {
      if (emailSuggestion) {
        await updateUser(newValues);
      } else {
        await Mailcheck.run({
          email: values.email.trim(),
          suggested: (suggestion) => {
            setEmailSuggestion(suggestion.full);
          },
          empty: async () => {
            await updateUser(newValues);
          },
        });
      }
      setLoading(false);
      setLoaded(true);
      setTimeout(() => {
        setLoaded(false);
      }, 1e3);
    } catch (err) {
      setError('There was an error applying changes to your profile.');
      setLoading(false);
      setSubmitting(false);
    }
  };

  const phoneInputRef = useMaskito({
    options: maskitoPhoneOptions,
  });

  return (
    <Formik
      innerRef={formCallback}
      initialValues={{
        firstName: first_name || '',
        lastName: last_name || '',
        email,
        phone,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={ProfileFormValidationSchema}
    >
      {({
        touched,
        errors,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles['form-row']}>
              <TextInput
                id="first-name"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.firstName && touched.firstName,
                  message: errors.firstName,
                }}
                value={values.firstName}
              />
              <TextInput
                id="last-name"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.lastName && touched.lastName,
                  message: errors.lastName,
                }}
                value={values.lastName}
              />
            </div>
            <TextInput
              id="email"
              name="email"
              label="Email Address"
              autoComplete="email"
              placeholder="Email Address"
              onChange={(e) => {
                setEmailSuggestion('');
                handleChange(e);
              }}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.email && touched.email,
                message: errors.email,
              }}
              value={values.email}
              startIcon={<EmailFillIcon fill={colors.white} />}
              info={{
                hasInfo: emailSuggestion,
                infoMessage: `Did you mean ${emailSuggestion}?`,
              }}
            />
            <TextInput
              id="phone"
              name="phone"
              label="Phone Number"
              placeholder="Phone Number"
              type="tel"
              ref={phoneInputRef}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.phone && touched.phone,
                message: errors.phone,
              }}
              value={normalizePhoneForMaskito(values.phone)}
              startIcon={<PhoneFillIcon fill={colors.white} />}
            />
            {error && (
              <div className={styles.error}>
                <span className={styles['error-text']}>{error}</span>
              </div>
            )}
            <SimpleButton
              text="save changes"
              className={styles.submit}
              type={TYPES.GREEN_MEDIUM}
              disabled={isSubmitting || !profileFormHasChanges(user, values)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ProfileForm.propTypes = {
  setLoading: PropTypes.func,
  setLoaded: PropTypes.func,
  formCallback: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  user: selectUserDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUserDispatch(user)),
  setLoading: (isSubmitting) =>
    dispatch(setFormSubmittingDispatch(isSubmitting)),
  setLoaded: (isSubmitted) => dispatch(setFormSubmittedDispatch(isSubmitted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
