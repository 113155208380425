import React from 'react';
import classNames from 'classnames';

import colors from 'styles/colors.constants';

import styles from './CmsContentCard.module.scss';

const getIsLightColor = (hexColor: string) => {
  // Remove the # if present
  hexColor = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the relative luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Return true if light, false if dark
  return luminance > 127.5;
};
type CardFields = {
  background_color: string;
  title: string;
  subtitle: string;
  button_cta_text: string;
  button_link: string;
  tag_text: string;
  image: string;
};

type CmsContentCardProps = {
  fields: CardFields;
  isMobile?: boolean;
};

const CmsContentCard: React.FC<CmsContentCardProps> = ({
  fields,
  isMobile = false,
}) => {
  const {
    title,
    subtitle,
    background_color: backgroundColor,
    button_cta_text: buttonCtaText,
    button_link: buttonLink,
    tag_text: tagText,
    image,
  } = fields;
  // the theme of the button background is the opposite of the card background
  const isLightColor = getIsLightColor(backgroundColor || colors.gray600);

  return (
    <div
      key={title}
      className={classNames(styles['cms-content-card'], {
        [styles['dark-text']]: isLightColor,
      })}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className={styles.details}>
        <div className={styles.tag}>
          <span className={styles['tag-text']}>{tagText}</span>
        </div>
        <div className={styles['title-container']}>
          <h2 className={styles.title}>{title}</h2>
          <h3 className={styles.subtitle}>{subtitle}</h3>
        </div>

        {!isMobile && (
          <div
            style={{ backgroundColor }}
            className={classNames(styles['link-button'], {
              [styles.dark]: isLightColor,
            })}
          >
            <a href={buttonLink}>{buttonCtaText}</a>
          </div>
        )}
      </div>
      <img className={styles.img} src={image} alt={title} />
    </div>
  );
};

export default CmsContentCard;
