import { getSlashedDate } from 'utils/datetime';

export const HOMEPAGE_TITLE =
  'Last-Minute Sports, Music & Shows Tickets | Gametime';

export const getMetroPerformersPageTitle = ({
  currentMetro,
  categoryGroupConfig,
}) => {
  if (!currentMetro) {
    return 'Performers near me';
  }
  const { name: metroName } = currentMetro;
  const categoryName = categoryGroupConfig
    ? categoryGroupConfig.titleEventType
    : null;

  return `${metroName} ${categoryName || 'Event'} Tickets`;
};

export const getListingPageTitle = ({ headline, alias, row, formatted }) =>
  `${headline} - ${alias}, Row ${row} - ${formatted}`;

export const getEventPageTitle = (fullEvent) => {
  const {
    venue: { name: venueName, city, state },
    event: { datetimeLocal, tbd },
  } = fullEvent;

  let eventName = fullEvent.getName();
  const eventDate = tbd ? '' : ` - ${getSlashedDate(datetimeLocal)}`;

  // Concert Tickets is showing up as Concert Tickets Tickets
  if (eventName.toLowerCase().endsWith('Tickets')) {
    eventName = eventName.slice(0, 'Tickets'.length);
  }

  return `${eventName} Tickets${eventDate} at ${venueName} in ${city}, ${state}`;
};
