import React from 'react';

import CheckCircleFilledIcon from 'icons/CheckCircleFilledIcon';
import CircleLineIcon from 'icons/CircleLineIcon';
import colors from 'styles/colors.constants';

interface Props {
  isSelected?: boolean;
  selectedColor?: string;
  unselectedColor?: string;
}

const CheckmarkIndicator = ({
  isSelected = false,
  selectedColor = colors.gametimeGreen,
  unselectedColor = colors.gray300,
}: Props) => {
  return isSelected ? (
    <CheckCircleFilledIcon fill={selectedColor} />
  ) : (
    <CircleLineIcon fill={unselectedColor} />
  );
};

export default CheckmarkIndicator;
