import React from 'react';

export default function CircleQuestionFillIcon({
  fill = '#F0F0F0',
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47725 2 2 6.47726 2 12C2 17.5227 6.47725 22 12 22C17.5228 22 22 17.5227 22 12C22 6.47726 17.5228 2 12 2ZM10.4607 9.82373H8.58174C8.61641 8.09727 9.82285 6.90469 11.9515 6.90469C13.9345 6.90469 15.2449 8.00713 15.2449 9.60879C15.2449 10.6488 14.7388 11.3769 13.7611 11.9523C12.839 12.4862 12.5824 12.826 12.5824 13.4916V13.8591H10.745L10.7312 13.4777C10.641 12.3684 11.0224 11.7443 11.9792 11.1827C12.8736 10.6488 13.144 10.316 13.144 9.67119C13.144 9.02637 12.6171 8.56875 11.8267 8.56875C11.0293 8.56875 10.5023 9.06104 10.4607 9.82373ZM12.9915 16.0293C12.9915 16.7573 12.5131 17.208 11.7365 17.208C10.96 17.208 10.4746 16.7573 10.4746 16.0293C10.4746 15.2943 10.96 14.8437 11.7365 14.8437C12.5131 14.8437 12.9915 15.2943 12.9915 16.0293Z"
        fill={fill}
      />
    </svg>
  );
}
