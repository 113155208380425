import _get from 'lodash/get';
import _memoize from 'lodash/memoize';

export const sortObjectsByProperty = (objects, propertyPath, compareFn) => {
  return objects.sort((a, b) => {
    const valueA = _get(a, propertyPath, 0);
    const valueB = _get(b, propertyPath, 0);
    return compareFn(valueA, valueB);
  });
};

export const memoizedSortObjectsByProperty = _memoize(
  sortObjectsByProperty,
  (objects, propertyPath) => {
    return JSON.stringify({ objects, propertyPath });
  }
);
