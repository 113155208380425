import { JsonParseError } from 'utils/errors';

export async function parseJson<T>(jsonString: string): Promise<T> {
  try {
    // Attempt to parse the JSON string.
    return JSON.parse(jsonString) as T;
  } catch (error) {
    // Handle specific errors from JSON.parse.
    if (
      error instanceof TypeError ||
      error instanceof RangeError ||
      error instanceof SyntaxError
    ) {
      // Handle these errors to avoid catching unrelated errors like "Fetch is aborted".
      handleJsonStringifyError(error, jsonString);
    } else {
      // Re-throw any other types of errors.
      throw error;
    }
  }
}

function handleJsonStringifyError(error: Error, jsonString: string): never {
  console.info(
    'Error details:',
    JSON.stringify(
      {
        errorName: error.name,
        errorMessage: error.message || 'No message available',
        errorStack: error.stack || 'No stack trace available',
        responseBody: jsonString,
      },
      null,
      2
    )
  );
  throw new JsonParseError('Failed to parse JSON response');
}
