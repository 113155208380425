import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import MetroSelector from 'components/MetroSelector/MetroSelector';
import MetroSelectorContainer from 'components/MetroSelector/MetroSelectorContainer';
import { device, useMediaQuery } from 'hooks';

import MetroEvents from './MetroEvents';

import styles from './MetroContainer.module.scss';

function MetroContainer({ currentMetro, children, heroOptionsIsEmpty }) {
  const isMobile = useMediaQuery(device.down.md);

  if (isMobile) {
    return (
      <div
        className={classNames([
          styles['metro-container'],
          { [styles.heroOptionsIsEmpty]: heroOptionsIsEmpty },
        ])}
      >
        {children}
        <MetroEvents currentMetro={currentMetro} />
      </div>
    );
  }

  return (
    <>
      {children}
      <div
        className={classNames(styles['metro-container'], {
          [styles.heroOptionsIsEmpty]: heroOptionsIsEmpty,
        })}
      >
        <div
          className={classNames(
            styles['metro-selector-container'],
            styles.desktop
          )}
        >
          <MetroSelectorContainer>
            <MetroSelector
              currentMetro={currentMetro}
              redirectToMetro
              titleStyle="metro-title"
              iconStyle="metro-icon"
            />
          </MetroSelectorContainer>
        </div>
        <div className={styles['border-separator']} />
        <MetroEvents currentMetro={currentMetro} />
      </div>
    </>
  );
}

MetroContainer.propTypes = {
  currentMetro: PropTypes.object.isRequired,
  heroOptionsIsEmpty: PropTypes.bool,
  children: PropTypes.node,
};

export default MetroContainer;
