import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export const Description = ({ description }) => {
  if (!description) {
    return null;
  }
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

export default Description;
