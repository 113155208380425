import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAnalyticsContext, View } from 'analytics';
import { fetchSurveyContent } from 'store/modules/content/survey/actions';
import {
  surveyOptionsSelector,
  surveyQuestionsSelector,
} from 'store/modules/content/survey/selectors';
import { getFormattedSurveyData } from 'utils/survey';

import SurveyManager from './SurveyManager';

const PostPurchaseSurveyModal = ({
  fetchContent,
  surveyOptions,
  surveyQuestions,
  transactionId,
  onHide,
}) => {
  const analytics = useAnalyticsContext();

  const [isFetchingContent, setIsFetchingContent] = useState(false);

  useEffect(() => {
    analytics.track(
      new View({
        pageType: View.PAGE_TYPES.MARKETING_SURVEY(transactionId),
      })
    );
  }, [analytics, transactionId]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingContent(true);
      await fetchContent();
      setIsFetchingContent(false);
    };

    if (!surveyQuestions?.length && !isFetchingContent) {
      fetchData();
    }
  }, [isFetchingContent, fetchContent, surveyOptions, surveyQuestions]);

  if (!surveyQuestions?.length) {
    return null;
  }

  const formattedSurveyData = getFormattedSurveyData(
    surveyQuestions,
    surveyOptions
  );

  return (
    <SurveyManager
      surveyQuestions={surveyQuestions}
      transactionId={transactionId}
      onHide={onHide}
      surveyData={formattedSurveyData}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    surveyOptions: surveyOptionsSelector(state),
    surveyQuestions: surveyQuestionsSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchSurveyContent()),
});

PostPurchaseSurveyModal.propTypes = {
  surveyOptions: PropTypes.array,
  surveyQuestions: PropTypes.array,
  fetchContent: PropTypes.func,
  onHide: PropTypes.func,
  transactionId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostPurchaseSurveyModal);
