export const DATE_RANGE_FILTERS = {
  ALL_DATES: 'All Dates',
  TODAY: 'Today',
  THIS_WEEKEND: 'This Weekend',
  NEXT_WEEKEND: 'Next Weekend',
} as const;

export const GAME_TYPE_FILTERS = {
  HOME_AWAY: 'Home & Away',
  HOME: 'Home Games',
  AWAY: 'Away Games',
} as const;
