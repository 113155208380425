import React from 'react';
import PropTypes from 'prop-types';

import styles from './BenefitsAndPerks.module.scss';

const SingleBenefit = ({ name, src, description }) => {
  return (
    <div className={styles['benefit-and-perks-block']}>
      <img className={styles['benefit-and-perks-icon']} src={src} alt={name} />
      <div className={styles['benefit-and-perks-copy-container']}>
        <h3 className={styles['gametime-h3']}>{name}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

SingleBenefit.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SingleBenefit;
