import React from 'react';

import styles from './BulletList.module.scss';

interface BulletListProps {
  listItems: React.ReactNode[];
}

function BulletList({ listItems }: BulletListProps) {
  return (
    <div className={styles['bullet-list']}>
      {listItems.map((item, i) => {
        if (!item) return null;
        return (
          <React.Fragment key={`bullet-item-${item.toString()}-${i}`}>
            <span>{item}</span>
            {i !== listItems.length - 1 && (
              <span className={styles.bullet}>&middot;</span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default BulletList;
