import React from 'react';

import colors from 'styles/colors.constants';

interface AlarmClockIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

const AlarmClockIcon = ({
  width = 16,
  height = 16,
  fill = colors.black,
}: AlarmClockIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 3.81332L11.6 1.23999L10.74 2.25999L13.8067 4.83332L14.6667 3.81332ZM5.25334 2.25999L4.40001 1.23999L1.33334 3.80666L2.19334 4.82666L5.25334 2.25999ZM8.33334 5.33332H7.33334V9.33332L10.5 11.2333L11 10.4133L8.33334 8.83332V5.33332ZM8.00001 2.66666C4.68668 2.66666 2.00001 5.35332 2.00001 8.66666C2.00001 11.98 4.68001 14.6667 8.00001 14.6667C11.3133 14.6667 14 11.98 14 8.66666C14 5.35332 11.3133 2.66666 8.00001 2.66666ZM8.00001 13.3333C5.42001 13.3333 3.33334 11.2467 3.33334 8.66666C3.33334 6.08666 5.42001 3.99999 8.00001 3.99999C10.58 3.99999 12.6667 6.08666 12.6667 8.66666C12.6667 11.2467 10.58 13.3333 8.00001 13.3333Z"
        fill={fill}
      />
    </svg>
  );
};

export default AlarmClockIcon;
