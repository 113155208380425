export const EVENTBAR_VIEWS = {
  LISTINGS: 'listings',
  LISTING_PURCHASE: 'listing_purchase',
  EXPIRED: 'expired',
};

export const DEAL = {
  EXPOSED_PRICE: 'exposed_price',
  EXPOSED_DEAL: 'exposed_deal',
  BEST: 'best',
  CHEAPEST: 'cheapest',
  FLASH: 'flash',
  ZONE: 'zone',
  CONTAINED: 'sm_dot',
  GREAT_VIEW: 'great_view',
  RARE: 'rare',
};

export const SPONSORED_DEAL_STATE = {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  EXPIRED: 'expired',
};

export const MAX_SIDEBAR_WIDTH = 700;

export const CANADIAN_PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YK',
];

export const CANADIAN_METROS = [
  'calgary',
  'edmonton',
  'montreal',
  'ottawa',
  'toronto',
  'vancouver',
  'winnipeg',
];
