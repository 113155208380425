import React from 'react';
import PropTypes from 'prop-types';

import SimpleButton from 'components/Buttons/SimpleButton';
import Spinner from 'components/Spinner/Spinner';
import { device, useMediaQuery } from 'hooks';

import styles from './SeatMapLoadIndicator.module.scss';

const SeatMapLoadIndicator = ({
  loaded = false,
  error = false,
  isFetching = false,
  isOrderSeatMap,
}) => {
  const isMobile = useMediaQuery(device.down.md);
  const reload = () => {
    if (typeof window !== 'undefined') {
      window.location.reload(false);
    }
  };

  if ((loaded && isFetching) || (!loaded && isOrderSeatMap)) {
    return (
      <div className={styles.container}>
        <Spinner isTransparentBackdrop isFullscreen={isMobile} />
      </div>
    );
  }

  return (
    <>
      {loaded ? null : (
        <div className={styles.container}>
          {error ? (
            <div className={styles['error-container']}>
              <p className={styles['error-title']}>Something went wrong here</p>

              <SimpleButton
                text="Retry"
                onClick={reload}
                className={styles['reload-button']}
              />
            </div>
          ) : (
            <Spinner
              text="Getting you the best seats"
              isTransparentBackdrop
              isFullscreen={isMobile}
            />
          )}
        </div>
      )}
    </>
  );
};

SeatMapLoadIndicator.propTypes = {
  loaded: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  isOrderSeatMap: PropTypes.bool,
};

export default SeatMapLoadIndicator;
