import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height'>;

export default function TrashBinIcon({ width = '24', height = '24' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_2432_341)">
        <path
          d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V8H6V19ZM19 4.5C19 3.94772 18.5523 3.5 18 3.5H15.5L14.6464 2.64645C14.5527 2.55268 14.4255 2.5 14.2929 2.5H9.70711C9.5745 2.5 9.44732 2.55268 9.35355 2.64645L8.5 3.5H6C5.44772 3.5 5 3.94772 5 4.5V6H19V4.5Z"
          fill="#0E0E0F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2432_341">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
