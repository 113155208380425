export const gametimePreviewImageUrl =
  'https://hassets.gametime.co/assets/Homepage/imessage_preview.png';

export const PICKS_VIDEO_SRC =
  'https://player.vimeo.com/video/1000777326?h=c46dbe2a92';

export const HERO_IMAGE_TOP_URL =
  'https://assets.gametime.co/static_pages/picks/Background_Top@3x.jpg?auto=webp';

export const HERO_IMAGE_BOTTOM_URL =
  'https://assets.gametime.co/static_pages/picks/Background_Bottom@3x.jpg?auto=webp';

export const SEARCH_HERO_BACKGROUND_URL =
  'https://assets.gametime.co/discover/SearchHeroBackground.jpg?auto=webp';
