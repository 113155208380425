import { combineReducers } from 'redux';

import collections from './Collections/filterReducer';
import fullEvents from './FullEvents/filterReducer';
import performers from './Performers/filterReducer';
import performersInContext from './PerformersInContext/filterReducer';
import venues from './Venues/filterReducer';

export default combineReducers({
  collections,
  fullEvents,
  performersInContext,
  performers,
  venues,
});
