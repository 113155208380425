// 2025 Super Bowl

export const SUPER_BOWL_SLUG = 'nflsuperbowl';
export const SUPER_BOWL_EVENT_ID = '669a8a5deb0816069b8b440b';
export const SUPER_BOWL_PERFORMERS_IDS = [];
export const SUPER_BOWL_FAQ_RELATIVE_URL =
  '/blog/super-bowl-lix-frequently-asked-questions';

export const isSuperBowl = (eventId) => eventId === SUPER_BOWL_EVENT_ID;

export const isSuperBowlPerformer = (performerId) =>
  SUPER_BOWL_PERFORMERS_IDS.includes(performerId);
