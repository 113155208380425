import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function IOSAppStoreIcon({
  fill = colors.white,
  height = '32',
  width = '32',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_83_1831)">
        <path
          d="M16.3333 0C25.1333 0 32.3333 7.2 32.3333 16C32.3333 24.8 25.1333 32 16.3333 32C7.53325 32 0.333252 24.8 0.333252 16C0.333252 7.2 7.53325 0 16.3333 0ZM16.3333 3.2C9.29325 3.2 3.53325 8.96 3.53325 16C3.53325 23.04 9.29325 28.8 16.3333 28.8C23.3733 28.8 29.1333 23.04 29.1333 16C29.1333 8.96 23.3733 3.2 16.3333 3.2ZM11.2133 21.28L9.93325 23.52C9.61325 24.16 8.81325 24.32 8.17325 24C7.53325 23.68 7.37325 22.88 7.69325 22.24L8.65325 20.64C9.77325 20.32 10.5733 20.48 11.2133 21.28ZM18.2533 10.72L22.0933 17.44H25.4533C26.2533 17.44 26.7332 18.08 26.7332 18.72C26.7332 19.52 26.0933 20 25.4533 20H23.5333L24.8133 22.24C25.1333 22.88 24.9733 23.68 24.3333 24C23.6933 24.32 22.8933 24.16 22.5733 23.52L17.7733 15.04C16.6533 13.28 17.4533 11.36 18.2533 10.72ZM18.7333 5.76C19.3733 6.08 19.5333 6.88 19.2133 7.52L13.6133 17.28H17.7733C19.0533 17.28 19.8533 18.88 19.2133 19.84H7.21325C6.41325 19.84 5.93325 19.2 5.93325 18.56C5.93325 17.76 6.57325 17.28 7.21325 17.28H10.5733L14.7333 9.92L13.4533 7.52C13.1333 6.88 13.2933 6.08 13.9333 5.76C14.5733 5.44 15.3733 5.6 15.6933 6.24L16.3333 7.36L16.9733 6.4C17.2933 5.6 18.0933 5.44 18.7333 5.76Z"
          fill="#5F5F62"
        />
      </g>
      <defs>
        <clipPath id="clip0_83_1831">
          <rect
            width="32"
            height="32"
            fill={fill}
            transform="translate(0.333252)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
