import { Dispatch } from 'redux';

import { getReviews, GetReviewsParams } from 'services/reviews/reviews.service';
import { GetReviewsResponse } from 'types';

import { ACTIONS } from './constants';

interface GetReviewsRequestAction {
  type: typeof ACTIONS.GET_REVIEWS_REQUEST;
}

interface GetReviewsRequestSuccessAction {
  type: typeof ACTIONS.GET_REVIEWS_REQUEST_SUCCESS;
  result: GetReviewsResponse;
}

interface GetReviewsRequestFailureAction {
  type: typeof ACTIONS.GET_REVIEWS_REQUEST_FAILURE;
  error: string;
}

type ReviewsActionTypes =
  | GetReviewsRequestAction
  | GetReviewsRequestSuccessAction
  | GetReviewsRequestFailureAction;

export const fetchReviews =
  (params: GetReviewsParams) =>
  async (dispatch: Dispatch<ReviewsActionTypes>) => {
    dispatch({ type: ACTIONS.GET_REVIEWS_REQUEST });

    try {
      const result = await getReviews(params);
      dispatch({
        type: ACTIONS.GET_REVIEWS_REQUEST_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: ACTIONS.GET_REVIEWS_REQUEST_FAILURE,
        error,
      });
    }
  };
