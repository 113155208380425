import { ACTIONS } from './actions';

const initialState = {};

const toObject = (collections) => {
  const collectionsObject = {};
  (collections || []).forEach((collection) => {
    collectionsObject[collection.id] = collection;
  });

  return collectionsObject;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_COLLECTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        ...toObject(action.result.collections),
      };
    default:
      return state;
  }
}
