import { CATEGORIES } from 'store/modules/categories/category.helpers';

export const MLB_LINK = 'https://www.mlb.com';
export const MLB_PRIVACY_POLICY_LINK =
  'https://www.mlb.com/official-information/privacy-policy';
export const MLB_ALL_TEAMS_LINK = 'https://www.mlb.com/team';

export const isMLBEvent = (fullEvent) => {
  return fullEvent.category === CATEGORIES.MLB;
};
