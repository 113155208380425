export const SEAT_MAP_IMAGE_WIDTH = 2560;
export const SEAT_MAP_IMAGE_HEIGHT = 1936;

/* Config settings MapV1 */
export const MIN_ZOOM = 1;
export const MAX_ZOOM = {
  DESKTOP: 5,
  MOBILE: 10,
};
export const ZOOM_DELTA = 0.35;
export const SEATMAP_HEIGHT_RATIO =
  SEAT_MAP_IMAGE_HEIGHT / SEAT_MAP_IMAGE_WIDTH;
export const SEATMAP_WIDTH_RATIO = SEAT_MAP_IMAGE_WIDTH / SEAT_MAP_IMAGE_HEIGHT;
export const SEATMAP_PADDING = 80; // this will make the map pins at the edges of seatmap visible

export const PIN = {
  LG: 'LG',
  SM: 'SM',
};
