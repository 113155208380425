import {
  FETCH_SURVEY_CONTENT,
  FETCH_SURVEY_CONTENT_FAIL,
  FETCH_SURVEY_CONTENT_SUCCESS,
} from './actions';

const initialState = {
  content: {
    survey: {
      surveyContent: null,
    },
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SURVEY_CONTENT_SUCCESS:
      const { slug, fields } = action?.result || {};

      return {
        ...state,
        [slug]: fields,
      };
    case FETCH_SURVEY_CONTENT_FAIL:
    case FETCH_SURVEY_CONTENT:
    default:
      return state;
  }
}
