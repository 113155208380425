import { UPDATE_IP_EXAMPLE } from './constants';
import { AppContextAction, AppContextState } from './types';

export const initialState: AppContextState = {
  isMobile: false,
  browser: null,
  browserVersion: null,
  os: null,
  phone: null,
  tablet: null,
  isIOS: null,
  isAndroid: null,
  ipExample: '1234-example',
  datadogSession: {
    recordRate: 0,
    sampleRate: 0,
  },
};

export function appContextReducer(
  state: AppContextState,
  action: AppContextAction
) {
  switch (action.type) {
    case UPDATE_IP_EXAMPLE:
      return {
        ...state,
        ipExample: action.payload,
      };
    default:
      return state;
  }
}
