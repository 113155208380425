import React, { MouseEvent, TouchEvent } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import _merge from 'lodash/merge';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import { isInvalidHref } from 'utils/url';

interface LinkProps {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  replace?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  to?: To;
  onClick?: (event: MouseEvent | TouchEvent) => void;
  mpActions?: object;
  track?: string | object;
  clickTracker?: object;
  tabIndex?: number | undefined;
  useTouchEvent?: boolean;
  color?: string;
  style?: React.CSSProperties;
  title?: string;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

const Link = ({
  children,
  className,
  disabled,
  onClick,
  replace,
  to,
  mpActions,
  clickTracker,
  color,
  href,
  ...props
}: LinkProps) => {
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();

  const handleClick = (event: MouseEvent | TouchEvent) => {
    if (disabled || replace || (!to && isInvalidHref(href))) {
      event.preventDefault();
    }

    if (disabled) {
      event.stopPropagation();
      return;
    }

    if (mpActions) {
      analytics.track(mpActions);
    }

    if (clickTracker) {
      const trackProperties =
        clickTracker instanceof ClickTracker
          ? clickTracker.json()
          : clickTracker;

      analytics.track(
        new Click(_merge({}, clickContext, trackProperties) as Click)
      );
    }

    if (onClick) {
      onClick(event);
    }
  };

  if (disabled) {
    props.style = props.style || {};
    props.tabIndex = -1;
    props.style.cursor = 'not-allowed';
  }

  if (!to) {
    return (
      <a
        className={className}
        href={href}
        onClick={handleClick}
        color={color}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      className={className}
      to={to}
      color={color}
      onClick={handleClick}
      {...props}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
