import { TRACK } from 'analytics/constants';

export class TrackEvent {
  _eventName = 'trackEvent';

  // Default the properties to null to mark as optional in typings
  constructor({
    pageType = null,
    dev = null,
    payload = null,
    interaction = null,
    sourcePageType = null,
    targetPageType = null,
    ...params
  }) {
    this.pageType = pageType;
    this.dev = dev;
    this.payload = payload;
    this.interaction = interaction;
    this.sourcePageType = sourcePageType;
    this.targetPageType = targetPageType;
    this.params = params;
  }

  eventName() {
    return this._eventName;
  }

  toParams() {
    const params = {
      ...this.params,
    };

    if (typeof this.interaction === 'object') {
      for (const key in this.interaction) {
        if (Object.prototype.hasOwnProperty.call(this.interaction, key)) {
          params[key] = this.interaction[key];
        }
      }
    } else if (this.interaction) {
      params[TRACK.INTERACTION] = this.interaction;
    }

    if (typeof this.pageType === 'object') {
      for (const key in this.pageType) {
        if (Object.prototype.hasOwnProperty.call(this.pageType, key)) {
          params[key] = this.pageType[key];
        }
      }
    } else if (this.pageType) {
      params[TRACK.PAGE_TYPE] = this.pageType;
    }

    if (typeof this.sourcePageType === 'object') {
      for (const key in this.sourcePageType) {
        if (Object.prototype.hasOwnProperty.call(this.sourcePageType, key)) {
          params[key] = this.sourcePageType[key];
        }
      }
    } else if (this.sourcePageType) {
      params[TRACK.SOURCE_PAGE_TYPE] = this.sourcePageType;
    }

    if (typeof this.targetPageType === 'object') {
      for (const key in this.targetPageType) {
        if (Object.prototype.hasOwnProperty.call(this.targetPageType, key)) {
          params[key] = this.targetPageType[key];
        }
      }
    } else if (this.targetPageType) {
      params[TRACK.TARGET_PAGE_TYPE] = this.targetPageType;
    }

    for (const key in this.dev) {
      if (Object.prototype.hasOwnProperty.call(this.dev, key)) {
        params[`dev_${key}`] = this.dev[key];
      }
    }

    for (const key in this.payload) {
      if (Object.prototype.hasOwnProperty.call(this.payload, key)) {
        params[`payload_${key}`] = this.payload[key];
      }
    }

    for (const key in params) {
      if (
        params[key] === null &&
        Object.prototype.hasOwnProperty.call(params, key)
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
