import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'react-router-dom';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import GTLogin from 'components/Login/GTLogin';
import { LogoColor } from 'logos';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { searchStringToQueryObject } from 'utils/url';

import styles from './Login.module.scss';

class Login extends Component {
  static propTypes = {
    customPathname: PropTypes.string,
    user: PropTypes.object,
    router: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      this.props.router.navigate(this.props.customPathname || '/my-tickets', {
        replace: true,
      });
    }
  }

  render() {
    return (
      <ContainerTemplate
        header={<MinimalHeader search showCategories showHamburger />}
      >
        <HeadTitle title="Sign In" />
        <div className={styles['login-page']}>
          <div className={styles['gt-billboard']}>
            <LogoColor className={styles.logo} />
          </div>
          <div className={styles['login-container']}>
            <div className={styles['login-wrapper']}>
              <GTLogin customPathname={this.props.customPathname} />
            </div>
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

const LoginWrapper = withRouter(
  connect((state, { location: { search } }) => {
    const query = searchStringToQueryObject(search);
    return {
      user: selectUserDetails(state),
      customPathname: query?.redirect,
    };
  })(Login)
);

const loader =
  (_context) =>
  async ({ request, context = _context }) => {
    const {
      store: { getState, dispatch },
    } = context;

    const user = selectUserDetails(getState());
    if (!user) {
      await dispatch(fetchMetros());
      return null;
    }
    const search = new URL(request.url).search;
    const query = searchStringToQueryObject(search);
    const urlRedirect = query?.redirect ?? '/my-tickets';
    return redirect(urlRedirect);
  };

LoginWrapper.loader = loader;

export default LoginWrapper;
