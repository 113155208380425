export const MOBILE_VIEW = {
  MAP: 'map',
  LIST: 'list',
  HYBRID: 'hybrid',
} as const;

export type MobileEventViewType =
  (typeof MOBILE_VIEW)[keyof typeof MOBILE_VIEW];

export type MobileEventView = {
  type: MobileEventViewType;
  isList: boolean;
  isMap: boolean;
};
