export const ACTIONS = {
  PURCHASE_STARTED: 'purchase_started',
  UPDATE_DEFAULT_CARD: 'update_default_card',
  ADD_NEW_CARD_SUCCESS: 'add_new_card_success',
  DELETE_CARD: 'delete_card',
  ADD_NEW_CARD_ERROR: 'add_new_card_error',
  SUBMIT_PURCHASE_PHONE: 'submit_purchase_phone',
  SENT_MAGIC_LINK: 'sent_magic_link',
  SUBMIT_TMM_TICKET: 'submit_tmm_ticket',
  SUBMIT_TEXT_TRANSFER_TICKET: 'submit_text_transfer_ticket',
  OPEN_AFFIRM_MODAL: 'open_affirm_modal',
};
