import React from 'react';
import classNames from 'classnames';

import styles from './Container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
  disableGutters?: boolean;
  className?: string;
}

const Container = ({
  children,
  disableGutters = false,
  className = '',
}: ContainerProps) => {
  const classes = classNames(styles.container, className, {
    [styles['disable-gutters']]: disableGutters,
  });

  return <div className={classes}>{children}</div>;
};

export default Container;
