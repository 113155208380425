import {
  setAuthCookie,
  setBranchIdentity,
  setmParticleIdentity,
} from './actions';
import { USER_ACTION_TYPES } from './user.actionTypes';
import { DEFAULT_IP_GEOLOCATION } from './user.constants';

// function that sets all user related things client side
const loginUser = (user) => {
  setAuthCookie(user);
  setBranchIdentity(user);
  setmParticleIdentity(user);
};

/* ********************* REDUCERS *********************** */

const initialState = {
  details: null,
  abExperimentAssignments: {},
  zListingsAssignments: {},
  zSearchAssignments: {},
  featureFlagAssignments: {},
  isBot: false,
  forterToken: '',
  isNewUser: false,
  promos: [],
  externalAccount: null,
  ipGeolocation: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_ACTION_TYPES.CREATE_USER_SUCCESS:
      loginUser(action.result);
      return {
        ...state,
        details: action.result,
        // used to trigger registering new user email with Braze for welcome email
        isNewUser: true,
      };
    case USER_ACTION_TYPES.UPDATE_USER_SUCCESS:
    case USER_ACTION_TYPES.FETCH_USER_SUCCESS:
      loginUser(action.result);
      return {
        ...state,
        details: action.result,
      };
    case USER_ACTION_TYPES.LOGOUT:
      return {
        ...state,
        details: null,
        isNewUser: false,
        promos: [],
        promosForListing: [],
        externalAccount: null,
      };
    case USER_ACTION_TYPES.LOAD_AB_EXPERIMENT_ASSIGNMENTS:
      return {
        ...state,
        abExperimentAssignments: action.assignments,
      };
    case USER_ACTION_TYPES.LOAD_ZLISTINGS_ASSIGNMENTS:
      return {
        ...state,
        zListingsAssignments: action.zListings,
      };
    case USER_ACTION_TYPES.LOAD_ZSEARCH_ASSIGNMENTS:
      return {
        ...state,
        zSearchAssignments: action.zSearch,
      };
    case USER_ACTION_TYPES.LOAD_FEATURE_FLAG_ASSIGNMENTS:
      return {
        ...state,
        featureFlagAssignments: action.featureFlags,
      };
    case USER_ACTION_TYPES.FETCH_PROMO_CODES_SUCCESS:
      return {
        ...state,
        promos: action.result.user_promos,
      };
    case USER_ACTION_TYPES.FETCH_PROMOS_FOR_LISTING_SUCCESS:
      return {
        ...state,
        promosForListing: action.result.user_promos,
      };
    case USER_ACTION_TYPES.SET_IS_BOT:
      return {
        ...state,
        isBot: action.isBot,
      };
    case USER_ACTION_TYPES.SET_FORTER_TOKEN:
      return {
        ...state,
        forterToken: action.forterToken,
      };
    case USER_ACTION_TYPES.REQUEST_ACCOUNT_DELETION:
      return {
        ...state,
        details: { ...state.details, pending_deletion: true },
      };
    case USER_ACTION_TYPES.CREATE_USER_EXTERNAL_ACCOUNT_SUCCESS:
    case USER_ACTION_TYPES.FETCH_USER_EXTERNAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        externalAccount: action.result.info,
      };
    case USER_ACTION_TYPES.FETCH_USER_IP_GEOLOCATION_SUCCESS:
      return {
        ...state,
        ipGeolocation: {
          country: action.result.country_code,
          region: action.result.region_code,
          city: action.result.city,
          zip: action.result.postal_code,
          ll: [action.result.latitude, action.result.longitude],
        },
      };
    case USER_ACTION_TYPES.FETCH_USER_IP_GEOLOCATION_FAIL:
      return {
        ...state,
        ipGeolocation: DEFAULT_IP_GEOLOCATION,
      };
    default:
      return state;
  }
}
