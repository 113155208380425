import React from 'react';
import classNames from 'classnames';

import styles from './PriceButton.module.scss';

export interface PriceButtonColors {
  backgroundColor: string;
  color: string;
}

interface PriceButtonProps {
  priceButtonColors: PriceButtonColors;
  price?: string;
  className?: string;
}

export default function PriceButton({
  priceButtonColors,
  price,
  className,
}: PriceButtonProps) {
  const ctaText = price ? `FROM ${price}` : 'Get Tickets';

  return (
    <div className={styles['price-button-container']}>
      <span
        className={classNames([className, styles['price-button']])}
        style={{
          backgroundColor: priceButtonColors.backgroundColor,
          color: priceButtonColors.color,
        }}
      >
        {ctaText}
      </span>
    </div>
  );
}
