import React from 'react';

type Props = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'aria-hidden'
>;

export default ({
  width = 24,
  height = 24,
  'aria-hidden': ariaHidden = true,
}: Props) => (
  <svg
    width={width}
    height={height}
    aria-hidden={ariaHidden}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.5818 4 4 7.5818 4 12C4 16.4182 7.5818 20 12 20C16.4182 20 20 16.4182 20 12C20 7.5818 16.4182 4 12 4ZM14.4393 9.14645L11 12.5858L9.56061 11.1464C9.36535 10.9512 9.04877 10.9512 8.85351 11.1464L8.1464 11.8536C7.95114 12.0488 7.95114 12.3654 8.1464 12.5607L10.2928 14.7071C10.6834 15.0976 11.3165 15.0976 11.7071 14.7071L15.8535 10.5607C16.0488 10.3654 16.0488 10.0488 15.8535 9.85355L15.1464 9.14645C14.9511 8.95118 14.6346 8.95118 14.4393 9.14645Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4"
        y1="4"
        x2="4"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65FFB5" />
        <stop offset="1" stopColor="#00D5E6" />
      </linearGradient>
    </defs>
  </svg>
);
