import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CaretDownIcon from 'icons/CaretDownIcon';
import colors from 'styles/colors.constants';

import styles from './Menu.module.scss';

const Menu = ({
  size = 'lg',
  label,
  icon = null,
  onClick,
  disabled = false,
  testId = '',
}) => {
  const { gametimeGreenLight: gtGreen } = colors;

  const rootStyles = classNames(
    styles.menu,
    styles[`size-${size}`],
    styles['color-primary'],
    {
      [styles.disabled]: disabled,
      [styles['icon-chip']]: icon !== null,
    }
  );

  const labelStyles = classNames(styles.label, {
    [styles['icon-chip']]: icon !== null,
  });

  return (
    <div data-testid={testId} className={rootStyles} onClick={onClick}>
      {icon && icon}
      <div className={labelStyles}>{label}</div>
      <CaretDownIcon fill={gtGreen} />
    </div>
  );
};

Menu.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  testId: PropTypes.string,
};

export default Menu;
