import React from 'react';

import DealsTag from 'components/Deals/DealsTag';
import { dealTypes } from 'components/Deals/helpers';
import FeaturedListingsTag from 'components/FeaturedListingsTag/FeaturedListingsTag';
import { Deal, FullEvent, Listing } from 'models';

import styles from './Tag.module.scss';

type TagProps = {
  listing: Listing;
  fullEvent: FullEvent;
  isZoneDeal: boolean;
  isFlashDeal: boolean;
  dealInfo: Deal | null;
};

const Tag = ({
  listing,
  fullEvent,
  isZoneDeal,
  isFlashDeal,
  dealInfo,
}: TagProps) => {
  if (isZoneDeal || isFlashDeal) {
    return (
      <div className={styles['featured-info']}>
        <DealsTag
          listing={listing}
          fullEvent={fullEvent}
          dealType={isFlashDeal ? dealTypes.flashDeal : dealTypes.zoneDeal}
          isListingPage
        />
      </div>
    );
  }

  if (dealInfo) {
    return (
      <div className={styles['featured-info']}>
        <FeaturedListingsTag
          title={dealInfo.title}
          color={dealInfo.color}
          icon={dealInfo.iconURL}
        />
      </div>
    );
  }

  return null;
};

export default Tag;
