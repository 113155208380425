import React from 'react';
import PropTypes from 'prop-types';

import SimpleButton, { TYPES } from 'components/Buttons/SimpleButton';
import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import VerifiedIcon from 'icons/VerifiedIcon';

import styles from './SuperBowlModal.module.scss';

const SuperBowlModal = ({ onHide, ...otherProps }) => {
  return (
    <GTUniversalModal onHide={onHide} {...otherProps}>
      <div className={styles.modal}>
        <div className={styles['info-block']}>
          <header className={styles['info-header']}>Zone Tickets</header>
          <span className={styles['info-text']}>
            Per NFL policy, exact seat locations aren’t allocated until a few
            weeks before the game. Your ticket guarantees a seat within a
            specific zone, designated by the colors on the map.
          </span>
        </div>
        <div className={styles['info-block']}>
          <header className={styles['info-header']}>Questions?</header>
          <span className={styles['info-text']}>
            Let’s chat! Give us a call at (833) 741-0574.
          </span>
        </div>
        <SimpleButton
          className={styles['close-button']}
          type={TYPES.GREEN_LIGHT_SOLID}
          text="got it"
          onClick={onHide}
        />
        <div className={styles['guarantee-container']}>
          <div className={styles['verified-icon']}>
            <VerifiedIcon />
          </div>
          <span className={styles['guarantee-primary-text']}>
            Verified Tickets
          </span>
          <span className={styles['guarantee-secondary-text']}>
            100% Guaranteed
          </span>
        </div>
      </div>
    </GTUniversalModal>
  );
};

SuperBowlModal.propTypes = {
  onHide: PropTypes.func,
};

export default SuperBowlModal;
