export class SearchKeystroke {
  constructor(props) {
    this.searchTerm = props.searchTerm;
    this.searchSessionID = props.searchSessionID;
    this.dev = props.dev;
    this.payload = props.payload;
  }

  eventName() {
    return 'search_keystroke';
  }

  toParams() {
    const params = {
      search_term: this.searchTerm,
      search_session_id: this.searchSessionID,
    };

    for (const key in this.dev) {
      if (Object.prototype.hasOwnProperty.call(this.dev, key)) {
        params[`dev_${key}`] = this.dev[key];
      }
    }

    for (const key in this.payload) {
      if (Object.prototype.hasOwnProperty.call(this.payload, key)) {
        params[`payload_${key}`] = this.payload[key];
      }
    }

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
