import React from 'react';
import PropTypes from 'prop-types';

import flashDealsBackground from 'assets/FlashDealsBackground.svg';
import GTInfoModal from 'components/GTInfoModal/GTInfoModal';

import styles from './FlashDealModal.module.scss';

const FlashDealModal = ({ show, onHide }) => {
  return (
    <GTInfoModal
      show={show}
      onHide={onHide}
      className={styles.flash}
      buttonText="Got It"
      style={{
        content: {
          backgroundImage: `url(${flashDealsBackground})`,
        },
      }}
      buttonClassName={styles['flash-close']}
    >
      <div className={styles['flash-icon']}>
        <span className={styles['flash-icon-text']}>Flash Deal</span>
      </div>
      <h2 className={styles['flash-header']}>Snag a Flash Deal</h2>
      <p className={styles['flash-content']}>
        Flash Deals give fans big discounts that you can only find on Gametime.
      </p>
      <p className={styles['flash-content']}>
        Supplies are limited for these exclusive sales, so snag your tickets
        now.
      </p>
    </GTInfoModal>
  );
};

FlashDealModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default FlashDealModal;
