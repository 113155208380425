import { Request } from 'express';

import { IpGeolocation } from 'types/http';

const EUROPEAN_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'EU',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export function getRemoteAddress(req: Request) {
  return req.socket.remoteAddress;
}

export function getXffIp(req: Request) {
  const xff = req.get('x-forwarded-for');

  return typeof xff === 'string' ? xff.split(',')[0] : null;
}

export function getClientIp(req: Request) {
  if (Object.keys(req).length === 0) return null;

  const ip = getXffIp(req) || getRemoteAddress(req);

  // `ip` is undefined, or is returning an invalid IP address
  if (typeof ip !== 'string' || ip.length < 8) {
    return null;
  }

  // Handle IPV4 mapped to IPV6 addresses
  if (ip.substring(0, 7).toLowerCase() === '::ffff:') {
    return ip.substring(7);
  }

  return ip;
}

export function isAllowedAccess(ipGeolocation: IpGeolocation) {
  return !EUROPEAN_COUNTRY_CODES.includes(ipGeolocation.country);
}
