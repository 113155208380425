// this function merges state with normalized entities to the entity id level, ie the latest will replace the old
const normalizeMerge = (state, { entities }, nestedKeys = []) => {
  const newState = {
    ...state,
  };

  Object.keys(entities).forEach((key) => {
    if (nestedKeys.indexOf(key) > -1) {
      newState[key] = {
        ...normalizeMerge(newState[key], { entities: entities[key] }),
      };
    }
    newState[key] = {
      ...entities[key],
      ...newState[key],
    };
  });

  return newState;
};

export default normalizeMerge;
