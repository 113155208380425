import { Notification } from './Notifications';

export const CREDIT_CARD_ERROR_NOTIFICATION: Notification = {
  title: 'Error',
  message: 'Please try again or use a different payment method.',
  autoClose: 5000,
};

export const SUGGESTED_SEAT_COUNT_NOTIFICATION: Notification = {
  title: 'Tickets are going fast!',
  message:
    'There are no tickets left for the quantity you had selected. We are showing you a lower available quantity instead.',
  autoClose: 3000,
};
