import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './GTUniversalModal.module.scss';

const GTUniversalModal = ({
  show = false,
  onHide = () => {},
  styleName = '',
  ...otherProps
}) => {
  return (
    <Modal
      className={classNames(styles['gt-universal-modal'], styleName)}
      overlayClassName={styles['gt-universal-modal-overlay']}
      isOpen={show}
      onRequestClose={onHide}
      ariaHideApp={false}
      {...otherProps}
    />
  );
};

GTUniversalModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  styleName: PropTypes.string,
};

export default GTUniversalModal;
