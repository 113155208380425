import React from 'react';
import classNames from 'classnames';

import styles from './CheckoutCard.module.scss';

interface Props {
  title: string | React.ReactNode;
  headerButton?: React.ReactNode;
  description?: string;
  body: React.ReactNode;
  noPadding?: boolean;
}

export default function CheckoutCard({
  title,
  headerButton,
  description,
  body,
  noPadding = false,
}: Props) {
  return (
    <div className={styles['checkout-card']} tabIndex={-1}>
      <div className={styles['header']}>
        <div className={styles['title-row']}>
          <h2 className={styles['title']}>{title}</h2>
          {headerButton && (
            <div className={styles['header-button']}>{headerButton}</div>
          )}
        </div>
        {description && (
          <span className={styles['description']}>{description}</span>
        )}
      </div>
      <div
        className={classNames(styles['body'], {
          [styles['no-padding']]: noPadding,
        })}
      >
        {body}
      </div>
    </div>
  );
}
