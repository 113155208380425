export class NoEventsFound {
  constructor({ performer_name }) {
    this.performer_name = performer_name;
  }

  eventName() {
    return 'no_events_found';
  }

  toParams() {
    const params = {
      performer_name: this.performer_name,
    };

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
