import { differenceInDays, parseISO } from 'date-fns';
import _get from 'lodash/get';
import { schema } from 'normalizr';

import { CATEGORY_GROUP_CONFIG } from 'modules/CategoryGroups/CategoryGroups.helpers';
import {
  getEventPath,
  getPerformerPathByPerformer,
} from 'store/modules/resources/resource.paths';
import { getContrastColor } from 'utils/colors';

import { ListingSchema } from './listing.schema';
import { SCHEMA_ENTITIES } from './schemaEntities';
import { VenueSchema } from './venue.schema';

const upcomingEventForPerformer = (performer) => performer.schedule[0];

export const EventSchema = new schema.Entity(
  SCHEMA_ENTITIES.EVENTS,
  {
    venue: VenueSchema,
    ticket_data: [ListingSchema],
  },
  {
    processStrategy: (input) => {
      const category = _get(
        input,
        'display_data.event_category',
        CATEGORY_GROUP_CONFIG.SPORT.id
      );
      const path = getEventPath(input);
      return {
        ...input,
        category,
        path,
      };
    },
  }
);

export const PerformerSchema = new schema.Entity(
  SCHEMA_ENTITIES.PERFORMERS,
  {
    schedule: [EventSchema],
  },
  {
    idAttribute: (input) => `${input.metro}-${input.team_id}`,
    processStrategy: (input) => {
      const performer = input;
      const nextPerformerEvent = upcomingEventForPerformer(performer);

      const dateDifference =
        nextPerformerEvent &&
        differenceInDays(
          parseISO(nextPerformerEvent.stats.datetime_local),
          new Date()
        );
      const upcoming = dateDifference >= 0 && dateDifference <= 7;

      const path = getPerformerPathByPerformer(performer);
      const category =
        nextPerformerEvent && nextPerformerEvent.display_data.event_category;
      const resource = 'performer';
      const tags = [category];
      const primaryColor = `#${performer.primary_color.slice(0, 6)}`;
      const contrastColor = getContrastColor(primaryColor);

      if (upcoming) {
        tags.push('upcoming');
      }

      return {
        ...performer,
        category,
        path,
        resource,
        tags,
        primaryColor,
        contrastColor,
      };
    },
  }
);

export const PerformersSchema = [PerformerSchema];
