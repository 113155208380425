import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function CloseLineIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="close-line-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64637 17.6464C5.45111 17.4511 5.45111 17.1346 5.64637 16.9393L16.9393 5.6464C17.1345 5.45114 17.4511 5.45114 17.6464 5.6464L18.3535 6.35351C18.5487 6.54877 18.5487 6.86535 18.3535 7.06061L7.06059 18.3535C6.86532 18.5488 6.54874 18.5488 6.35348 18.3535L5.64637 17.6464Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35348 5.64649C6.54874 5.45123 6.86532 5.45123 7.06058 5.64649L18.3535 16.9394C18.5487 17.1346 18.5487 17.4512 18.3535 17.6465L17.6464 18.3536C17.4511 18.5489 17.1345 18.5489 16.9393 18.3536L5.64637 7.06071C5.45111 6.86544 5.45111 6.54886 5.64637 6.3536L6.35348 5.64649Z"
        fill={fill}
      />
    </svg>
  );
}
