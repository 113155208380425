import React from 'react';

import { FilterDropdownOption } from 'components/FilterControl/FilterDropdown';
import GTAnimationModal from 'components/GTAnimationModal';

import styles from './FilterModal.module.scss';

interface FilterModalProps {
  show: boolean;
  onHide: VoidFunction;
  options: FilterDropdownOption[];
  title: string;
}

const FilterModal = ({ show, onHide, options, title }: FilterModalProps) => {
  return (
    <GTAnimationModal
      className="filter-modal"
      headerTitle={title}
      show={show}
      onHide={onHide}
    >
      {options.map((option) => (
        <span
          key={option.name}
          className={styles['filter-option']}
          onClick={option.onClick}
        >
          <span className={styles['option-name']}>{option.name}</span>
        </span>
      ))}
    </GTAnimationModal>
  );
};

export default FilterModal;
