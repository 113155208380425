import { LISTING } from './listing.actionTypes';

export const setLockedCarouselHover = (isHovered) => {
  return {
    type: LISTING.SET_LOCKED_CAROUSEL_HOVER,
    isHovered,
  };
};

export const updateMapHarmony = (updating = true) => {
  return {
    type: LISTING.UPDATE_MAP_HARMONY,
    updating,
  };
};

export const updateListMapHarmonyToggle = (toggleIsOn) => {
  return {
    type: LISTING.UPDATE_LIST_MAP_HARMONY_TOGGLE,
    toggleIsOn,
  };
};

export const updateUnlockedSponsoredDeals = (eventId) => {
  return {
    type: LISTING.UPDATE_UNLOCKED_ZONE_DEAL_EVENTS,
    eventId,
  };
};

export const setHoveredListingId = (hoveredListingId = '') => {
  return {
    type: LISTING.SET_HOVERED_LISTING_ID,
    hoveredListingId,
  };
};
