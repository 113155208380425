import { USER_ACTION_TYPES } from 'store/modules/user/user.actionTypes';

import { USER_PURCHASE_ACTION_TYPES } from '../userPurchases.actionsTypes';

import { userPurchaseCardsFetchedSelector } from './creditCard.selectors';

export const creditCardInitialState = {
  cards: {},
  cardsFetched: false,
  zip: null,
};

export default function creditCardReducer(
  state = creditCardInitialState,
  action = {}
) {
  switch (action.type) {
    case USER_PURCHASE_ACTION_TYPES.FETCH_USER_CARDS_SUCCESS:
      return {
        ...state,
        cardsFetched: true,
        cards: action.result.card_data.cards.reduce((cards, card) => {
          cards[card.token] = card;
          return cards;
        }, {}),
      };
    case USER_PURCHASE_ACTION_TYPES.SAVE_PURCHASE_ZIP:
      return {
        ...state,
        zip: action.zip,
      };
    case USER_ACTION_TYPES.LOGOUT:
      return {
        ...creditCardInitialState,
      };

    default:
      return state;
  }
}

export const saveUserPurchaseZip = (zip) => ({
  type: USER_PURCHASE_ACTION_TYPES.SAVE_PURCHASE_ZIP,
  zip,
});

export function fetchUserCards(user, options = {}) {
  const { force = false } = options;

  return (dispatch, getState) => {
    const globalState = getState();

    if (!force && userPurchaseCardsFetchedSelector(globalState)) {
      return Promise.resolve();
    }

    return dispatch({
      types: [
        USER_PURCHASE_ACTION_TYPES.FETCH_USER_CARDS,
        USER_PURCHASE_ACTION_TYPES.FETCH_USER_CARDS_SUCCESS,
        USER_PURCHASE_ACTION_TYPES.FETCH_USER_CARDS_FAIL,
      ],
      promise: (httpClient) =>
        httpClient.request({
          path: `/v1/user/${user.id}`,
          authUser: user,
        }),
    });
  };
}

export const createUserCard = (user, nonce, reCaptchaToken) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.CREATE_USER_CARD,
    USER_PURCHASE_ACTION_TYPES.CREATE_USER_CARD_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.CREATE_USER_CARD_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      // a trailing slash is required for this endpoint
      path: '/v1/payment-methods/',
      method: 'POST',
      headers: {
        'X-Gt-Recaptcha-Token': reCaptchaToken,
      },
      authUser: user,
      body: {
        payment_method_nonce: nonce,
        email: user.email,
        phone: user.phone,
        scan_card: true,
        vendor_name: 'braintree',
      },
    }),
});

export const deleteUserCard = (user, cardId) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.REMOVE_USER_CARD,
    USER_PURCHASE_ACTION_TYPES.REMOVE_USER_CARD_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.REMOVE_USER_CARD_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/payment-methods/${cardId}`,
      method: 'DELETE',
      authUser: user,
    }),
});

export const fetchUserDeviceVerified = (user, payment_token) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.FETCH_USER_DEVICE_VERIFIED,
    USER_PURCHASE_ACTION_TYPES.FETCH_USER_DEVICE_VERIFIED_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.FETCH_USER_DEVICE_VERIFIED_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: '/v1/payment-methods/verify-device',
      method: 'POST',
      authUser: user,
      body: {
        payment_token,
      },
    }),
});

export const verifyCVV = (user, cardId, nonce) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.VERIFY_CVV,
    USER_PURCHASE_ACTION_TYPES.VERIFY_CVV_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.VERIFY_CVV_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/payment-methods/${cardId}/verify-cvv`,
      method: 'POST',
      authUser: user,
      body: {
        nonce,
      },
    }),
});
