import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'icons/ArrowIcon';
import { SORT_IDS } from 'store/modules/userPreference/user.preference.selectors';

const SortLabel = ({ sortId }) => {
  if (sortId === SORT_IDS.PRICEL || sortId === SORT_IDS.PRICEH) {
    const isPriceL = sortId === SORT_IDS.PRICEL;
    return (
      <>
        Price
        <ArrowIcon
          height="12"
          width="18"
          viewBox={isPriceL ? '4 4 20 20' : '0 0 20 20'}
          style={{
            transform: isPriceL ? 'rotate(270deg)' : 'rotate(90deg)',
          }}
        />
      </>
    );
  }

  if (sortId === SORT_IDS.DISCOUNT) {
    return 'Discount';
  }

  return 'Sort By';
};

SortLabel.propTypes = {
  sortId: PropTypes.string.isRequired,
};

export default SortLabel;
