import { Performer } from 'models';

export function getPerformerPathname(
  performerOne: Performer | undefined,
  performerTwo: Performer | undefined,
  isPerformerParkingPage: boolean
): string | undefined {
  // Sort performers alphabetically
  const [primaryPerformer, secondaryPerformer] = [
    performerOne,
    performerTwo,
  ].sort((a, b) => {
    if (!a || !b) return 0;
    return a.slug.localeCompare(b.slug);
  });

  if (isPerformerParkingPage) {
    return primaryPerformer
      ? primaryPerformer.getParkingPath(secondaryPerformer)
      : secondaryPerformer?.getParkingPath();
  }

  if (primaryPerformer && secondaryPerformer) {
    return primaryPerformer.getMatchupPath(secondaryPerformer);
  }

  return primaryPerformer
    ? primaryPerformer.getPath()
    : secondaryPerformer?.getPath();
}
