import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import { DEFAULT_OG_PARAMS, generateFastlyImagePathname } from 'helpers/image';

const Image = ({ src, alt }) => {
  if (!src) {
    return null;
  }
  return (
    <Helmet>
      <meta
        property="og:image"
        content={generateFastlyImagePathname(src, DEFAULT_OG_PARAMS)}
      />
      <meta property="og:image:width" content={DEFAULT_OG_PARAMS.width} />
      <meta property="og:image:height" content={DEFAULT_OG_PARAMS.height} />
      {alt ? <meta property="og:image:alt" content={alt} /> : null}
    </Helmet>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;
