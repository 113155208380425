import React from 'react';
import PropTypes from 'prop-types';

import { dealTypes } from 'components/Deals/helpers';

import styles from './CellContent.module.scss';

const CellContent = ({ dealType, title, color, icon }) => {
  return (
    <div className={styles.container}>
      <span className={styles['sub-title']} style={{ color }}>
        Exclusive Discount
      </span>
      {dealType === dealTypes.zoneDeal ? (
        <div className={styles['title-container']}>
          {icon}
          <h3 className={styles.title}>{title}</h3>
          {icon}
        </div>
      ) : (
        <div className={styles['title-container']}>
          <h3 className={styles.title}>{title}</h3>
        </div>
      )}
    </div>
  );
};

CellContent.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  dealType: PropTypes.oneOf([dealTypes.zoneDeal, dealTypes.flashDeal]),
  icon: PropTypes.node.isRequired,
};

export default CellContent;
