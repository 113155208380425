import React from 'react';
import classNames from 'classnames';

import CaretDownIcon from 'icons/CaretDownIcon';
import colors from 'styles/colors.constants';

import styles from './Chip.module.scss';

type ChipProps = {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  label: string;
  isMenu?: boolean;
  icon?: React.ReactNode;
  alignCenter?: boolean;
  onClick?: () => void;
  testId?: string;
};

export default function Chip({
  size = 'md',
  color = 'primary',
  disabled = false,
  label,
  isMenu = false,
  icon = null,
  alignCenter = false,
  testId = '',
  onClick,
}: ChipProps) {
  const rootStyles = classNames(
    styles.chip,
    styles[`size-${size}`],
    styles[`color-${color}`],
    {
      [styles.disabled]: disabled,
      [styles['is-menu']]: isMenu,
      [styles['icon-chip']]: icon !== null,
      [styles['align-center']]: alignCenter,
      [styles.clickable]: onClick !== undefined,
    }
  );

  const labelStyles = classNames(styles.label, {
    [styles['icon-chip']]: icon !== null,
  });

  return (
    <div
      data-testid={testId}
      className={rootStyles}
      onClick={onClick && onClick}
    >
      {icon && icon}
      <div className={labelStyles}>{label}</div>
      {isMenu && (
        <CaretDownIcon
          fill={
            color === 'secondary' ? colors.gray900 : colors.gametimeGreenLight
          }
        />
      )}
    </div>
  );
}
