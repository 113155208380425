const FETCH_GLOBAL_HEADER = 'FETCH_GLOBAL_HEADER';
export const FETCH_GLOBAL_HEADER_FAIL = 'FETCH_GLOBAL_HEADER_FAIL';
export const FETCH_GLOBAL_HEADER_SUCCESS = 'FETCH_GLOBAL_HEADER_SUCCESS';

export const fetchGlobalHeaderTab = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_GLOBAL_HEADER,
      FETCH_GLOBAL_HEADER_SUCCESS,
      FETCH_GLOBAL_HEADER_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('*', 'promo-header-tabs'),
  });
