import React from 'react';
import PropTypes from 'prop-types';

import GiftIcon from 'icons/GiftIcon';
import colors from 'styles/colors.constants';

import styles from './DetailPromoCard.module.scss';

const DetailPromoCard = ({ title = '', description = '' }) => (
  <div className={styles['details-promo-card']}>
    <div className={styles['card-body']}>
      <div className={styles['icon-container']}>
        <GiftIcon fill={colors.gametimeGreenLight} />
      </div>
      <div className={styles['text-container']}>
        <p className={styles.title}>{title}</p>
        <span className={styles.subtitle}>{description}</span>
      </div>
    </div>
  </div>
);

DetailPromoCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DetailPromoCard;
