import { TRACK } from './constants';

export class HoverTracker {
  constructor(targetType) {
    this.hoverProperties = {
      [TRACK.TARGET_PAGE_TYPE]: targetType,
    };
  }

  interaction(interaction, payload) {
    this.hoverProperties[TRACK.INTERACTION] = interaction;
    this.payload(payload);
    return this;
  }

  payload(payload) {
    if (payload) {
      this.hoverProperties[TRACK.PAYLOAD] = {
        ...this.hoverProperties[TRACK.PAYLOAD],
        ...payload,
      };
    }
    return this;
  }

  sourcePageType(sourcePageType) {
    this.hoverProperties[TRACK.SOURCE_PAGE_TYPE] = sourcePageType;
    return this;
  }
  targetPageType(targetPageType) {
    this.hoverProperties[TRACK.TARGET_PAGE_TYPE] = targetPageType;
    return this;
  }
  dev(dev) {
    if (dev) {
      this.hoverProperties[TRACK.DEV] = {
        ...this.hoverProperties[TRACK.DEV],
        ...dev,
      };
    }
    return this;
  }
  json() {
    return this.hoverProperties;
  }
}
