import React from 'react';

import Image from 'components/Image/Image';
import { Performer } from 'models';

import styles from './MatchupImage.module.scss';

const MATCHUP_AT_LOGO = 'https://assets.gametime.co/Icons/Matchup@3x.png';

interface MatchupImageProps {
  homePerformer: Performer;
  awayPerformer: Performer;
  lazyLoad?: boolean;
}

const MatchupImage = ({
  homePerformer,
  awayPerformer,
  lazyLoad,
}: MatchupImageProps) => {
  return (
    <div className={styles['matchup-image']}>
      <div
        className={styles['matchup-performer']}
        style={{ backgroundColor: awayPerformer.logoBgColor }}
      >
        <Image
          className={styles['team-image']}
          alt={awayPerformer.shortName}
          src={awayPerformer.logoImageUrl}
          lazyLoad={lazyLoad}
        />
      </div>
      <Image
        className={styles['matchup-at-symbol']}
        alt="vs"
        src={MATCHUP_AT_LOGO}
        lazyLoad={lazyLoad}
      />
      <div
        className={styles['matchup-performer']}
        style={{ backgroundColor: homePerformer.logoBgColor }}
      >
        <Image
          className={styles['team-image']}
          alt={homePerformer.shortName}
          src={homePerformer.logoImageUrl}
          lazyLoad={lazyLoad}
        />
      </div>
    </div>
  );
};

export default MatchupImage;
