import React from 'react';

import { useInsurance } from './components/InsuranceOptions/InsuranceOptions.utils';

interface CheckoutHookProviderChildProps {
  insurance: ReturnType<typeof useInsurance>;
}

interface CheckoutHookProviderProps {
  useInsuranceOptions: Parameters<typeof useInsurance>[0];
  children: (props: CheckoutHookProviderChildProps) => React.ReactNode;
}

/**
 * Provides hook functionality for the Checkout page.
 */
export function CheckoutHookProvider({
  children,
  useInsuranceOptions,
}: CheckoutHookProviderProps) {
  const insurance = useInsurance(useInsuranceOptions);

  return children({ insurance });
}
