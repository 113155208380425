import { eventType, zoomType } from '../../constants/state.constants';
import { getCenterPosition } from '../../utils/state.utils';
import { animate, handleCancelAnimation } from '../animations/animations.utils';

import {
  calculateZoomToNode,
  handleZoomToViewCenter,
  isValidZoomNode,
  resetTransformations,
} from './handlers.utils';

export const zoomIn =
  (contextInstance) =>
  (step = 0.5, animationTime = 300, animationType = 'easeOut') => {
    contextInstance.transformState.interaction = {
      eventType: eventType.click,
      zoomType: zoomType.zoomIn,
    };

    handleZoomToViewCenter(
      contextInstance,
      1,
      step,
      animationTime,
      animationType
    );
  };

export const zoomOut =
  (contextInstance) =>
  (step = 0.5, animationTime = 300, animationType = 'easeOut') => {
    contextInstance.transformState.interaction = {
      eventType: eventType.click,
      zoomType: zoomType.zoomOut,
    };

    handleZoomToViewCenter(
      contextInstance,
      -1,
      step,
      animationTime,
      animationType
    );
  };

export const setTransform =
  (contextInstance) =>
  (
    newPositionX,
    newPositionY,
    newScale,
    animationTime = 300,
    animationType = 'easeOut'
  ) => {
    const { positionX, positionY, scale } = contextInstance.transformState;
    const { wrapperComponent, contentComponent } = contextInstance;
    const { disabled } = contextInstance.setup;

    if (disabled || !wrapperComponent || !contentComponent) return;

    const targetState = {
      positionX: isNaN(newPositionX) ? positionX : newPositionX,
      positionY: isNaN(newPositionY) ? positionY : newPositionY,
      scale: isNaN(newScale) ? scale : newScale,
    };

    animate(contextInstance, targetState, animationTime, animationType);
  };

export const resetTransform =
  (contextInstance) =>
  (animationTime = 200, animationType = 'easeOut') => {
    contextInstance.transformState.interaction = {
      eventType: eventType.reset,
      zoomType: zoomType.zoomOut,
    };
    resetTransformations(contextInstance, animationTime, animationType);
  };

export const centerView =
  (contextInstance) =>
  (scale, animationTime = 200, animationType = 'easeOut') => {
    const { transformState, wrapperComponent, contentComponent } =
      contextInstance;
    if (wrapperComponent && contentComponent) {
      const targetState = getCenterPosition(
        scale || transformState.scale,
        wrapperComponent,
        contentComponent
      );

      animate(contextInstance, targetState, animationTime, animationType);
    }
  };

export const zoomToElement =
  (contextInstance) =>
  (node, scale, animationTime = 600, animationType = 'easeOut') => {
    handleCancelAnimation(contextInstance);

    const { wrapperComponent } = contextInstance;

    const target =
      typeof node === 'string' ? document.getElementById(node) : node;

    if (
      wrapperComponent &&
      isValidZoomNode(target) &&
      target &&
      wrapperComponent.contains(target)
    ) {
      const targetState = calculateZoomToNode(contextInstance, target, scale);
      animate(contextInstance, targetState, animationTime, animationType);
    }
  };
