/**
 * Event for tracking the redemption of a promo code on a user's account.
 *
 * @param status if the code was redeemed successfully
 * @param page_type the page the code was redeemed on; currently only allowed in My Tickets
 * @param code the promo code redeemed
 * @param code_type if the code was a promo or credit code; currently only promo codes allowed'
 */
export class RedeemCode {
  constructor({ status, page_type = 'my_tickets', code, code_type = 'promo' }) {
    this.status = status;
    this.page_type = page_type;
    this.code = code;
    this.code_type = code_type;
  }

  eventName() {
    return 'redeem_code';
  }

  toParams() {
    const params = {
      status: this.status,
      page_type: this.page_type,
      code: this.code,
      code_type: this.code_type,
    };

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
