import React from 'react';
import PropTypes from 'prop-types';

import ActivePromo from '../ActivePromo/ActivePromo';

import styles from './ActivePromos.module.scss';

const ActivePromos = ({ activePromos = [] }) => {
  return (
    <>
      <div>
        <hr className={styles.divider} />
      </div>
      <div className={styles['active-promos']}>
        <h2 className={styles.heading}>Active Promos</h2>
        <p className={styles.description}>
          Applicable promotions will automatically be applied at checkout. Limit
          of 1 promo per purchase.
        </p>
        {activePromos.map((promo) => {
          const promoDetails = promo.promo_details;
          return (
            <ActivePromo
              key={promo.promo_id}
              title={promoDetails.title}
              description={promoDetails.customer_description}
              creationDateString={promo.created_at}
              conditions={promo.promo_conditions}
            />
          );
        })}
      </div>
    </>
  );
};

ActivePromos.propTypes = {
  activePromos: PropTypes.array,
};

export default ActivePromos;
