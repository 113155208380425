import { getVenues } from 'services/venue/venue.service';

import { ACTIONS } from './constants';
import { selectVenueBySlug, selectVenuesByFilters } from './selectors';

export const fetchVenues = (params) => (dispatch, getState) => {
  const state = getState();

  if (
    (params.slug && selectVenueBySlug(state, params.slug)) ||
    selectVenuesByFilters(state, params)
  ) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      ACTIONS.GET_VENUES_REQUEST,
      ACTIONS.GET_VENUES_REQUEST_SUCCESS,
      ACTIONS.GET_VENUES_REQUEST_FAILURE,
    ],
    params,
    promise: () => getVenues(params),
  });
};

export const fetchVenueBySlug = (slug) => fetchVenues({ slug });
