const getAddressComponentByType = (geoCodeAddressComponents, type) =>
  geoCodeAddressComponents.find((addressComponent) =>
    addressComponent.types?.includes(type)
  );

/*
 * Parse Google's geocoding response address components into address object.
 * Sample geo code https://developers.google.com/maps/documentation/geocoding/start#sample-request
 */

export const parseGeoCodeAddressComponents = (geoCodeAddressComponents) => {
  const streetNumberComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'street_number'
  );
  const streetNumber = streetNumberComponent
    ? streetNumberComponent.long_name
    : '';

  const streetAddressComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'route'
  );
  const streetAddress = streetAddressComponent
    ? streetAddressComponent.long_name
    : '';

  const postalCodeComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'postal_code'
  );
  const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';

  const localityComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'locality'
  );
  const locality = localityComponent ? localityComponent.long_name : '';

  const regionComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'administrative_area_level_1'
  );
  const region = regionComponent ? regionComponent.short_name : '';

  const countryComponent = getAddressComponentByType(
    geoCodeAddressComponents,
    'country'
  );
  const country = countryComponent ? countryComponent.short_name : '';

  return {
    street_address: `${streetNumber} ${streetAddress}`,
    postal_code: postalCode,
    locality,
    region,
    country_code_alpha2: country,
  };
};

export const DEFAULT_NEARBY_DISTANCE = 140;
export const distanceBetweenLocations = (
  { lat: lat1, lon: lon1 },
  { lat: lat2, lon: lon2 }
) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const radtheta = (Math.PI * (lon1 - lon2)) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  return dist;
};
