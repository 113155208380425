import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: absolute;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const AnimatedCircle = ({ open, color }) =>
  open ? (
    <Container
      data-testid="animated-circle"
      color={color}
      animate={{ scale: 700, opacity: 0 }}
      transition={{ duration: 0.25 }}
    />
  ) : null;

AnimatedCircle.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
};

export default AnimatedCircle;
