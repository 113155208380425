import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PayPalPay from 'store/modules/userPurchases/paypal/paypal';

import styles from './PayPalButton.module.scss';

const PayPalButton = ({ total, onPaymentSuccess, onClick }) => {
  const paypalRef = React.useRef();

  useEffect(() => {
    PayPalPay(paypalRef, total, onClick)
      .then((payload) => {
        onPaymentSuccess(payload);
      })
      .catch((err) => {
        console.error('Error: ', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return <div id="paypal-button" ref={paypalRef} className={styles.button} />;
};

PayPalButton.propTypes = {
  onPaymentSuccess: PropTypes.func,
  onClick: PropTypes.func,
  total: PropTypes.number.isRequired,
};

export default PayPalButton;
