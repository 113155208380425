import { createSelector } from 'reselect';

import { Listing } from 'models';
import { isGTPicksFilterSelector } from 'store/modules/app/app.ui';

import type { RootState } from '../reducer';

const selectListingsState = (state: RootState) => state.listings;

const selectListings = (state: RootState) =>
  selectListingsState(state).listings;

const selectDisplayGroups = (state: RootState) =>
  selectListingsState(state).displayGroups;

export const selectAvailableLots = (state: RootState) =>
  selectListingsState(state).availableLots;

export const selectHasInventory = (state: RootState) =>
  Object.keys(selectListings(state)).length > 0;

export const selectListingsParams = (state: RootState) =>
  selectListingsState(state).params;

export const selectIsListingsLoading = (state: RootState) =>
  selectListingsState(state).status === 'loading';

export const selectIsAllInPricing = (state: RootState) =>
  selectListingsParams(state).all_in_pricing;

export const selectListingPriceQuartile = (state: RootState) =>
  selectListingsParams(state).quartile;

/**
 * Selects the quantity used for the Listings V2 request params. This value
 * will always exist if listings have been fetched. Listings V3 will return
 * this value on the response.
 *
 * This is the value that should be used whenever looking at the quantity for
 * all listings.
 */
export const selectListingsQuantity = (state: RootState) =>
  selectListingsParams(state).quantity;

/**
 * Selector to create a map of Listing models. Since createSelector is memoized
 * with a default cache size of 1, the map will be created only once and the
 * same map will be returned whenever the selector is called with the same
 * quantity.
 *
 * Memoization only works if the quantity is the same wherever the selector is
 * called, which should be the case with our current implementation.
 */
const selectListingsModels = createSelector(
  selectListings,
  selectListingsQuantity,
  (listings, quantity) => {
    if (!quantity) {
      return {};
    }

    return Object.fromEntries(
      Object.entries(listings).map(([listingId, listingData]) => {
        const listing = new Listing(listingData, quantity);
        return [listingId, listing];
      })
    );
  }
);

export const selectListingById = (state: RootState, listingId: string) => {
  const listings = selectListingsModels(state);

  return listings[listingId] as Listing | undefined;
};

/**
 * Selector to return listings in display groups by quantity.
 *
 * This selector is currently handling the GT Picks filtering logic, which
 * eventually should be handled by the backend. This is a temporary solution
 * because making the query to backend is taking too long.
 */
export const selectListingsInDisplayGroups = createSelector(
  selectListingsModels,
  selectDisplayGroups,
  selectListingsQuantity,
  isGTPicksFilterSelector as (state: RootState) => boolean,
  (listingsModels, displayGroups, quantity, isGTPicksFilterActive) => {
    if (!quantity) {
      return [];
    }

    // remove deals list display group if GTPicks filter is active
    const filteredDisplayGroups = isGTPicksFilterActive
      ? displayGroups.filter(
          (displayGroup) => displayGroup.type !== 'deals_list'
        )
      : displayGroups;

    return filteredDisplayGroups.map((displayGroup) => {
      const listings =
        displayGroup.listings[quantity]?.map(
          (listingId) => listingsModels[listingId]
        ) ?? [];

      if (isGTPicksFilterActive) {
        // filter to only exclusive deals if GTPicks filter is active
        return {
          ...displayGroup,
          listings: listings.filter((listing) => listing.isExclusivesDeal),
        };
      }

      return {
        ...displayGroup,
        listings,
      };
    });
  }
);

/**
 * Selector to return a list of all unique IDs of listings in display groups
 */
export const selectDisplayedListingIds = createSelector(
  selectListingsInDisplayGroups,
  (displayGroups) =>
    Array.from(
      displayGroups.reduce<Set<string>>((listingIds, displayGroup) => {
        displayGroup.listings.forEach((listing) => {
          listingIds.add(listing.id);
        });
        return listingIds;
      }, new Set<string>())
    )
);

/**
 * Selector to return a flat list of all unique listing models in display groups
 */
export const selectDisplayedListings = createSelector(
  selectDisplayedListingIds,
  selectListingsModels,
  (uniqueListingIds, listingsModels) =>
    uniqueListingIds.map((listingId) => listingsModels[listingId])
);

/**
 * Selector to return the listing with the highest price in the displayed
 * listings. This is used for setting the highest price on the FullEvent model
 * to use when creating the Event Schema.
 */
export const selectHighestPriceListing = createSelector(
  selectDisplayedListings,
  (listings) =>
    listings.reduce<Listing | undefined>(
      (a, b) => (a?.price.prefee > b.price.prefee ? a : b),
      undefined
    )
);
