import React from 'react';
import PropTypes from 'prop-types';

import CircleCheckIcon from 'icons/CircleCheckIcon';
import LoadingIndicatorIcon from 'icons/LoadingIndicatorIcon';

import styles from './LoadingIcon.module.scss';

const LoadingIcon = ({ isLoading = true }) => {
  return (
    <div className={styles['icon-container']}>
      {isLoading ? (
        <LoadingIndicatorIcon className={styles.icon} />
      ) : (
        <CircleCheckIcon />
      )}
    </div>
  );
};

LoadingIcon.propTypes = {
  isLoading: PropTypes.bool,
};

export default LoadingIcon;
