/**
 * Flattens a JavaScript object or array into an object with nested paths
 * as keys.
 *
 * @param {object} object
 * @param {string} [originPath = ""]
 * @returns - flattened object with paths as keys
 */
export function flatten(object, originPath = '') {
  return Object.entries(object).reduce((flat, [key, value]) => {
    const path = `${originPath.length ? originPath + '.' : ''}${key}`;
    if (value && typeof value === 'object') {
      Object.assign(flat, flatten(value, path));
    } else {
      flat[path] = value;
    }
    return flat;
  }, {});
}
