import React from 'react';
import PropTypes from 'prop-types';

import { BTN_TYPES, Click, DeepLinkClickTracker, PAYLOAD } from 'analytics';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_ACTIONS } from 'components/DeepLink/DeepLink.constants';
import { device, useMediaQuery } from 'hooks/useMediaQuery';

const DeepLinkBadge = ({ campaignType, badgeImgSrc, altMessage }) => {
  const isMobile = useMediaQuery(device.down.md);
  return (
    <DeepLink
      campaign={campaignType}
      fireFacebookEvent
      clickTracker={new DeepLinkClickTracker(isMobile).interaction(
        Click.INTERACTIONS.BUTTON(),
        {
          [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
          [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.DOWNLOAD,
        }
      )}
    >
      <img src={badgeImgSrc} alt={altMessage} />
    </DeepLink>
  );
};

DeepLinkBadge.propTypes = {
  campaignType: PropTypes.string,
  badgeImgSrc: PropTypes.string.isRequired,
  altMessage: PropTypes.string.isRequired,
};

export default DeepLinkBadge;
