import React from 'react';

interface SparklesIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

export default function SparklesIcon({
  width = '16',
  height = width,
  fill = 'black',
}: SparklesIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="sparkles-icon"
    >
      <path
        d="M8.36675 2.43508C8.15946 2.22779 8.09568 1.87699 7.79272 1C7.48976 1.87699 7.42598 2.22779 7.21869 2.43508C7.0114 2.64237 6.6606 2.70615 5.78361 3.00911C6.6606 3.31207 7.0114 3.37585 7.21869 3.58314C7.42598 3.79043 7.48976 4.14123 7.79272 5.01822C8.09568 4.14123 8.15946 3.79043 8.36675 3.58314C8.57404 3.37585 8.92484 3.31207 9.80183 3.00911C8.92484 2.70615 8.57404 2.64237 8.36675 2.43508Z"
        fill={fill}
      />
      <path
        d="M12.1001 4.23621C12.3393 4.97692 12.4589 5.34727 12.7038 5.59224C12.9488 5.83721 13.3192 5.95681 14.0599 6.196C14.3075 6.27597 14.5966 6.36931 14.9362 6.48518C14.5683 6.61068 14.2598 6.70917 13.9989 6.79244C13.2957 7.01692 12.939 7.13078 12.7008 7.36512C12.4556 7.60637 12.3361 7.97529 12.0935 8.72398C12.015 8.96624 11.9236 9.24825 11.8109 9.57857C11.6885 9.2197 11.5917 8.91783 11.5098 8.66203C11.2822 7.95177 11.1684 7.59664 10.9339 7.36217C10.6994 7.12771 10.3443 7.01391 9.63405 6.78631C9.37825 6.70435 9.07639 6.60762 8.71751 6.48518C9.04783 6.37248 9.32984 6.28109 9.5721 6.20259C10.3208 5.95998 10.6897 5.84043 10.931 5.59525C11.1653 5.35709 11.2792 5.00038 11.5036 4.29715C11.5869 4.03629 11.6854 3.72775 11.8109 3.35989C11.9268 3.6995 12.0201 3.98856 12.1001 4.23621Z"
        fill={fill}
      />
      <path
        d="M6.12899 6.93008C6.48682 8.0398 6.66402 8.58936 7.02833 8.94979C7.38575 9.30339 7.92327 9.47496 8.98812 9.81485C9.38294 9.94087 9.85025 10.09 10.4077 10.2802C9.87844 10.4607 9.43044 10.6048 9.04859 10.7277C7.94651 11.0822 7.39547 11.2594 7.03132 11.6236C6.66717 11.9877 6.48992 12.5388 6.1354 13.6409C6.01257 14.0227 5.86846 14.4707 5.68793 15C5.50598 14.4666 5.36103 14.0157 5.23764 13.6319C4.88406 12.5321 4.70754 11.9831 4.34488 11.6199C3.98335 11.2579 3.43682 11.0807 2.34549 10.7267C1.96738 10.6041 1.52387 10.4603 1 10.2802C1.55191 10.0905 2.01462 9.94155 2.40564 9.81572C3.45999 9.47641 3.99304 9.30486 4.34787 8.95338C4.7107 8.59397 4.88716 8.04641 5.24407 6.93892C5.36606 6.56039 5.50913 6.11645 5.68793 5.59225C5.86533 6.11237 6.00756 6.55348 6.12899 6.93008Z"
        fill={fill}
      />
    </svg>
  );
}
