import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmsContentCard from 'ui/cmsContentCard/CmsContentCard';
import { GridContainer } from 'ui/Layout/GridContainer';

import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { generateBreadcrumbSchema } from 'components/Breadcrumbs/breadcrumb.helpers';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import JsonLD from 'components/JsonLD/JsonLD';
import MetroPickerCollection from 'components/MetroPickerCollection/MetroPickerCollection';
import { device, useMediaQuery } from 'hooks';
import { HOMEPAGE_TITLE } from 'modules/pageTitles';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import { gametimePreviewImageUrl } from 'pages/constants';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { CATEGORY_GROUP_IDS } from 'store/modules/categories/category.helpers';
import { fetchPerformerEventsContent } from 'store/modules/content/performers/actions';
import { selectPerformerEventsContent } from 'store/modules/content/performers/selectors';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { selectPopularCollectionByMetro } from 'store/modules/data/Collections/selectors';
import { fetchTrendingPerformersByCategoryGroup } from 'store/modules/data/Performers/actions';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectAllMetros,
  selectClosestMetro,
  selectMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';

import Reviews from './components/Reviews';

import styles from './HomeV1.module.scss';

type FetchCollectionArgs = { view: string; metro: string };
type FetchCollection = (args: FetchCollectionArgs) => void;
type CmsContent = { fields: CmsContentFields };
type CmsContentFields = {
  title: string;
  subtitle: string;
  button_cta_text: string;
  button_link: string;
  tag_text: string;
  image: string;
  background_color: string;
};

const HomeV1 = () => {
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(device.down.md);
  const params = useParams();

  // hooks
  // metro logic, todo make its own hook or a more sophisticated selector
  const userMetro = useSelector(selectUserMetro);
  const closestMetro = useSelector(selectClosestMetro);
  const metroId = params.metroId || userMetro?.id || closestMetro.id;
  const currentMetro = useSelector((state) => selectMetro(state, metroId));
  const metros = useSelector(selectAllMetros);

  // collection
  const popularCollection = useSelector(selectPopularCollectionByMetro);

  // butterContent
  const cmsContent: CmsContent[] = useSelector(selectPerformerEventsContent);

  const fetchOnMetroChange = async (metroId: string) => {
    setIsFetching(true);
    await Promise.all([
      fetchCollections({
        view: COLLECTION_VIEWS.WEB_DISCOVER,
        metro: metroId,
      })(dispatch),
      fetchTrendingPerformersByCategoryGroup(
        metroId,
        CATEGORY_GROUP_IDS.sports
      )(dispatch),
    ]);
    setIsFetching(false);
  };

  const handleMetroChange = async (_metroId: string) => {
    if (_metroId === metroId) return null;
    await fetchOnMetroChange(_metroId);
  };

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={
        <MinimalHeader
          search
          showCategories
          showAccount
          showHamburger
          showGTShield
          isHomepage
          showGTMessage
        />
      }
      className={styles['home-container']}
    >
      <div>
        <HeadTitle
          title={HOMEPAGE_TITLE}
          helmetProps={{ titleTemplate: '%s' }}
        />
        <HeadImage src={gametimePreviewImageUrl} />
        <JsonLD json={generateBreadcrumbSchema([HOMEPAGE_BREADCRUMB_CONFIG])} />
      </div>
      <GridContainer>
        <div className={styles['hero-item']}>
          <div className={styles['search-placeholder']} />
          <div className={styles['popular-events']}>
            {Object.keys(popularCollection || {}).length > 0 && (
              <MetroPickerCollection
                collection={popularCollection}
                collectionTitle={`${popularCollection?.title} in`}
                currentMetro={currentMetro}
                metros={metros}
                closestMetro={closestMetro}
                isLoading={isFetching}
                showPriceInImage={false}
                handleMetroChange={handleMetroChange}
                updateCurrentLocation={updateCurrentLocation}
                updateUserPreference={updateUserPreference}
              />
            )}
          </div>
        </div>
        <div className={styles['teams-item']} />
        <div className={styles['artists-item']} />
        <div className={styles['events-item']} />
        <div className={styles['events-item']}>
          {cmsContent.length > 0 && (
            <div className={styles['events-container']}>
              <h2 className={styles['events-title']}>Can't Miss Events</h2>
              <div className={styles['cards-container']}>
                {cmsContent.map(({ fields }) => (
                  <CmsContentCard
                    fields={fields}
                    isMobile={isMobile}
                    key={fields.button_link}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles['choose-gametime-item']} />
        <div className={styles['reviews-item']}>
          <Reviews />
        </div>
        <div className={styles['download-item']} />
      </GridContainer>
    </ContainerTemplate>
  );
};

type UpdateCurrentLocation = {
  type: string;
  location: unknown;
};

type StoreProperties = {
  dispatch: (calback: FetchCollection | UpdateCurrentLocation) => void;
  getState: () => unknown;
};

type LoaderCallbackParams = {
  context: ContextPartial;
  params?: { metroId?: string };
};

type ContextPartial = { store: StoreProperties };

const loader =
  (_context: ContextPartial) =>
  async ({ context: { store } = _context, params }: LoaderCallbackParams) => {
    const { dispatch, getState } = store;
    const _metroId = params?.metroId;
    await dispatch(fetchMetros());
    const state = getState();
    const metroId =
      _metroId || selectUserMetro(state)?.id || selectClosestMetro(state)?.id;
    const currentLocation = currentLocationSelector(state);

    if (!currentLocation) {
      dispatch(updateCurrentLocation(metroId));
    }
    const promises = [
      dispatch(
        fetchCollections({
          view: COLLECTION_VIEWS.WEB_DISCOVER,
          metro: metroId,
        })
      ),
      dispatch(
        fetchTrendingPerformersByCategoryGroup(
          metroId,
          CATEGORY_GROUP_IDS.sports
        )
      ),
      dispatch(fetchPerformerEventsContent('homepage')),
    ];
    await Promise.all(promises);
    return null;
  };

HomeV1.loader = loader;

export default HomeV1;
