import config from 'config/config';
import { selectListingsQuantity } from 'store/modules/listings/selectors';
import {
  getAssociatedListingPathname,
  selectAlgoliaSearchParams,
} from 'store/modules/location';
import { searchStringToQueryObject } from 'utils/url';

export const selectCheckoutMagicLinkParams = (state, location) => {
  const seatCount = selectListingsQuantity(state);
  const query = searchStringToQueryObject(location.search);
  const searchParams = selectAlgoliaSearchParams(query);
  searchParams.set(
    [config.cookiesConfig.USER_EXTRAS.parameters.SEAT_COUNT.query],
    seatCount
  );

  if (query.zoom) {
    searchParams.set('zoom', query.zoom);
  }

  const listingPath = getAssociatedListingPathname(location.pathname);
  const params = {
    pathname: `${listingPath}/checkout`,
    search: Object.fromEntries(searchParams),
  };

  return params;
};
