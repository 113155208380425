import React from 'react';
import classNames from 'classnames';

import styles from './Emoji.module.scss';

interface Props {
  /** Unicode representation of an Emoji character */
  emoji: string;
  /** Unicode variant of an Emoji character */
  variant?: string;
  /** Sets large styling for emoji. Default value is `false`. */
  isLarge?: boolean;
}

const Emoji = ({ emoji, isLarge = false, variant = '' }: Props) => {
  const emojiFromCodePoint = String.fromCodePoint(+emoji, +variant);

  return (
    <span
      aria-hidden="true"
      data-testid="emoji"
      className={classNames(styles.emoji, {
        [styles.large]: isLarge,
      })}
    >
      {emojiFromCodePoint}
    </span>
  );
};

export default Emoji;
