import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PostPurchaseSurveyModal.module.scss';

export const SurveySelectionButton = ({ title, onClick, isSelected }) => {
  return (
    <button
      type="button"
      className={classNames(styles['survey-option'], {
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

SurveySelectionButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};
