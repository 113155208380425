import config from 'config/config';
import { envIsLikeDev } from 'config/environments';

import {
  FETCH_PERFORMER_CONTENT,
  FETCH_PERFORMER_CONTENT_FAIL,
  FETCH_PERFORMER_CONTENT_SUCCESS,
  FETCH_PERFORMER_EVENTS_CONTENT_SUCCESS,
} from './actions';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PERFORMER_EVENTS_CONTENT_SUCCESS:
      const { fields: homepageFields } = action.result;
      return {
        ...state,
        homepage: {
          ...homepageFields,
          active: envIsLikeDev(config.env) || fields.active,
        },
      };
    case FETCH_PERFORMER_CONTENT:
      return {
        ...state,
      };
    case FETCH_PERFORMER_CONTENT_SUCCESS:
      const { slug, fields } = action.result;
      return {
        ...state,
        [slug]: {
          ...fields,
          // this allow us to test categories and changes before publishing them
          active: envIsLikeDev(config.env) || fields.active,
        },
      };
    case FETCH_PERFORMER_CONTENT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
