import React from 'react';

import MLBIcon from 'icons/MLBIcon';

import styles from './MLBMarketplaceTag.module.scss';

const MLBMarketplaceTag = () => {
  return (
    <div className={styles['marketplace-tag-container']}>
      <MLBIcon />
      <span>Authorized Ticket Marketplace of MLB</span>
    </div>
  );
};

export default MLBMarketplaceTag;
