import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { ViewBetMGMOfferEvent } from 'analytics/events/ViewBetMGMOffer';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';

import Background from './assets/betmgm-background.png';
import Logo from './assets/betmgm-logo.png';

import styles from './DetailBetMGMOffer.module.scss';

const BETMGM_URL =
  'https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1674906';

const DetailBetMGMOffer = ({ eventId, transactionId }) => {
  const clickTracker = new ClickTracker()
    .interaction(Click.INTERACTIONS.REDEEM_OFFER())
    .sourcePageType(Click.SOURCE_PAGE_TYPES.ORDER_CONFIRMATION())
    .targetPageType(Click.TARGET_PAGE_TYPES.BETMGM());

  const analytics = useAnalyticsContext();

  useEffect(() => {
    analytics?.track(
      new ViewBetMGMOfferEvent({
        page_type: 'order_confirmation',
        transaction_id: transactionId,
        event_id: eventId,
      })
    );
  }, [analytics, eventId, transactionId]);

  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
      }}
      className={styles['detail-betting-upsell']}
    >
      <img src={Logo} alt="BetMGM" className={styles.logo} />
      <h4 className={styles.heading}>
        First Bet Offer: Get up to $1500, Score $100 in Gametime Credit
      </h4>
      <span className={styles.subtitle}>
        Sign up and place your first bet to get up to $1500 in bonus bets on
        BetMGM to automatically qualify to receive $100 in Gametime credit.
      </span>
      <SimpleLinkButton
        href={BETMGM_URL}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.cta}
        type="white_medium"
        text="REDEEM OFFER"
        clickTracker={clickTracker}
      />
      <span className={styles.footer}>
        GAMBLING PROBLEM? CALL 1-800-GAMBLER
      </span>
    </div>
  );
};

DetailBetMGMOffer.propTypes = {
  eventId: PropTypes.string,
  transactionId: PropTypes.string,
};

export default DetailBetMGMOffer;
