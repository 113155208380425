import React, { useRef } from 'react';
import classNames from 'classnames';

import { useOnClickOutside } from 'hooks';

import styles from './FilterControl.module.scss';

interface FilterControlProps {
  onClick: VoidFunction;
  onClickOutside: VoidFunction;
  children: React.ReactNode;
  modal: React.ReactNode;
  isActive: boolean;
}

const FilterControl = ({
  onClick,
  onClickOutside,
  children,
  modal,
  isActive,
}: FilterControlProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onClickOutside);

  return (
    <div ref={ref} className={styles['filter-control']}>
      <button
        className={classNames(styles['filter-button'], {
          [styles['active']]: isActive,
        })}
        onClick={onClick}
      >
        {children}
      </button>
      {modal}
    </div>
  );
};

export default FilterControl;
