import config from 'config/production.config';
import { Event, FullEvent, Performer, Venue } from 'models';
import { CategoryGroup } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { CATEGORY_URLS } from 'store/modules/categories/category.helpers';

import { getLocationSchema } from '../location/location';
import { getOfferSchema } from '../offer/offer';

import {
  CATEGORY_GROUP_TO_EVENT_TYPE_MAP,
  EVENT_ATTENDANCE_MODE,
  EVENT_STATUS,
  EVENT_TYPES,
  PERFORMER_TYPES,
} from './constants';
import { EventJsonLD, PerformerSchema } from './types';

export default class EventSchema {
  fullEvent: FullEvent;
  primaryPerformer?: Performer;
  secondaryPerformer?: Performer;
  venue: Venue;
  event: Event;

  constructor(fullEvent: FullEvent) {
    const { venue, event } = fullEvent;
    this.fullEvent = fullEvent;
    this.primaryPerformer = fullEvent.getPrimaryPerformer();
    this.secondaryPerformer = fullEvent.getSecondaryPerformer();
    this.venue = venue;
    this.event = event;
  }

  get status() {
    return this.fullEvent.isValid()
      ? EVENT_STATUS.SCHEDULED
      : EVENT_STATUS.CANCELLED;
  }

  get type() {
    const categoryGroup: CategoryGroup = this.fullEvent.getCategoryGroup();

    if (categoryGroup in CATEGORY_GROUP_TO_EVENT_TYPE_MAP) {
      return CATEGORY_GROUP_TO_EVENT_TYPE_MAP[categoryGroup];
    }

    return EVENT_TYPES.EVENT;
  }

  get url() {
    return `https://${config.domain}${this.fullEvent.getPath()}`;
  }

  get emoji() {
    if (this.event.category in CATEGORY_URLS) {
      return CATEGORY_URLS[this.event.category].emoji;
    }
    return '';
  }

  get name() {
    const { event } = this.fullEvent;
    return this.emoji ? `${this.emoji} ${event.name}` : event.name;
  }

  get description() {
    const { event, venue } = this.fullEvent;
    const performer = this.fullEvent.getPrimaryPerformer();
    const eventName = event.name;
    const { name: venueName, city, state } = venue;

    const categoryGroupText = performer?.categoryGroup
      ? `${performer.categoryGroup} `
      : '';

    return `Gametime is offering last-minute ${categoryGroupText}tickets to ${eventName} at ${venueName} in ${city}, ${state}`;
  }

  getNameWithEmoji(performer: Performer) {
    const emojiPrefix = this.emoji ? `${this.emoji} ` : '';
    return `${emojiPrefix}${performer.name}`;
  }

  getPerformerSchema(performer: Performer): PerformerSchema {
    return {
      '@type': PERFORMER_TYPES.PERFORMIN_GROUP,
      name: this.getNameWithEmoji(performer),
      url: `https://${config.domain}${performer.getPath()}`,
      image: performer.heroImageUrl,
    };
  }

  getJsonLD(): EventJsonLD {
    const eventJsonLD: EventJsonLD = {
      '@context': 'http://schema.org',
      '@type': this.type,
      name: this.name,
      description: this.description,
      location: getLocationSchema({ venue: this.venue, eventType: this.type }),
      offers: getOfferSchema(this.fullEvent),
      url: this.url,
      startDate: this.event.datetimeUtc,
      endDate: this.event.datetimeUtc,
      eventAttendanceMode: EVENT_ATTENDANCE_MODE.OFFLINE,
      eventStatus: this.status,
    };

    if (this.primaryPerformer) {
      eventJsonLD.performer = this.getPerformerSchema(this.primaryPerformer);
      eventJsonLD.image = this.fullEvent.getPrimaryPerformerImageUrl();
    }

    return eventJsonLD;
  }
}
