import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { AnimatePresence, motion } from 'framer-motion';
import withOutletContext from 'hoc/withOutletContext';
import withRouter from 'hoc/withRouter';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import Alert from 'ui/Alert';
import SponsorDealBadge from 'ui/SponsorDealBadge';
import { variants as dealVariants } from 'ui/SponsorDealBadge/SponsorDealBadge';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  PAYLOAD,
  PurchaseListing,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import AffirmPriceBanner from 'components/Affirm/PriceBanner/PriceBanner';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import {
  generateBreadcrumbSchema,
  getCategoryBreadcrumbConfig,
  getEventBreadcrumbConfig,
  getListingBreadcrumbConfig,
} from 'components/Breadcrumbs/breadcrumb.helpers';
import {
  isListingFlashDeal,
  isListingZoneDeal,
} from 'components/Deals/helpers';
import DeliveryBadge from 'components/DeliveryFormat/DeliveryBadge/DeliveryBadge';
import EventDateTime from 'components/EventDateTime/EventDateTime';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import JsonLD from 'components/JsonLD/JsonLD';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import PricingSummary from 'components/PricingSummary/PricingSummary';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import UrgencyMessage from 'components/UrgencyMessage/UrgencyMessage';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import {
  selectIsWebDetailsStackedExperiment,
  selectIsWebExclusivesV1Experiment,
  selectIsWebExclusivesV3Experiment,
} from 'experiments';
import { DealStarIcon, VerifiedIcon } from 'icons';
import { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import { getListingPageTitle } from 'modules/pageTitles';
import { isTopValueDeal } from 'pages/Event/components/ListingFocusedCard/ListingFocusedCard';
import {
  getCurrencyPrefix,
  isDefaultAllInState,
  isFaceValueState,
} from 'pages/Event/helpers';
import {
  DEAL_VALUE_PERCENTILE,
  URGENCY_MESSAGING_THRESHOLD,
  WIDTH_MOBILE_SEAT_MAP,
} from 'pages/Listing/constants';
import { deliveryFormatForListing } from 'store/datatypes/DELIVERY_FORMATS';
import {
  deliveryTypeFor,
  isThirdPartyDelivery,
} from 'store/datatypes/DELIVERY_TYPES';
import {
  lastZoomLevelSelector,
  updateLastVisitedListingId,
} from 'store/modules/app/app.ui';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { selectIsVenueAllInPrice } from 'store/modules/data/Listings/selectors';
import { isListingDetailsPage } from 'store/modules/history/history';
import {
  fetchListings,
  updateListingsQuantity,
} from 'store/modules/listings/actions';
import {
  selectIsAllInPricing,
  selectListingById,
} from 'store/modules/listings/selectors';
import { getAssociatedEventPathname } from 'store/modules/location';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { extendedPurchaseSelector } from 'store/modules/purchase/purchase.selectors';
import { fetchDisclosures } from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  isPurchasableListingSelector,
  selectAllDeals,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { fetchUserPromoCodesForListing } from 'store/modules/user/actions';
import {
  selectUserDetails,
  userPromosForListingPriceSelector,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { fetchCompleteUserPurchases } from 'store/modules/userPurchases/actions';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import {
  selectCompleteUserPurchases,
  selectIsInitialUserPurchase,
} from 'store/modules/userPurchases/userPurchases.selectors';
import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';
import { getArticle } from 'utils/strings/title-case';
import { isSuperBowl } from 'utils/superBowl';
import { searchStringToQueryObject } from 'utils/url';

import GTCoverage from './components/GTCoverage';
import IncludesFees from './components/IncludesFees';
import QuantityPicker from './components/QuantityPicker';
import Tag from './components/Tag';
import DetailsStack from './DetailsStack/DetailsStack';
import { getSeatRange } from './utils';

import styles from './Listing.module.scss';

@TrackPageView(
  ({
    listing,
    fullEvent,
    extendedPurchase,
    showAllInPricing,
    isPromoEligible,
  }) => ({
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.LISTING({
      fullEvent,
      listing,
      displayed_price:
        _get(extendedPurchase, 'totalPrice') /
        (listing?.currentSeatCount > 0 ? listing.currentSeatCount : 1),
    }),
    payload: {
      all_in_filter: showAllInPricing,
      [PAYLOAD.PROMO_ELIGIBLE]: isPromoEligible,
      ...mapListingTrackingData(listing.listingTrackingData),
    },
  })
)
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.LISTING(),
  payload: mapListingTrackingData(listing.listingTrackingData),
}))
@withAnalyticsContext
class Listing extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    listing: PropTypes.object,
    updateUserPreference: PropTypes.func,
    params: PropTypes.shape({
      listingId: PropTypes.string.isRequired,
      eventId: PropTypes.string.isRequired,
    }).isRequired,
    extendedPurchase: PropTypes.object,
    updateLastVisitedListingId: PropTypes.func.isRequired,
    isPurchasableListing: PropTypes.bool.isRequired,
    fullEvent: PropTypes.object,
    isListingFlow: PropTypes.bool.isRequired,
    performer: PropTypes.object, // eslint-disable-line react/no-unused-prop-types,
    showModal: PropTypes.func.isRequired,
    fetchDisclosures: PropTypes.func.isRequired,
    allDeals: PropTypes.object,
    allDisclosures: PropTypes.object,
    priceWithPromoApplied: PropTypes.number,
    fetchUserPromoCodesForListing: PropTypes.func,
    event: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    eventId: PropTypes.string, // Used in mapStateToProps
    selectedMetro: PropTypes.string,
    promoAmount: PropTypes.number,
    showAllInPricing: PropTypes.bool.isRequired,
    showFaceValuePricing: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isPromoEligible: PropTypes.bool, // Used in tracking
    seatRange: PropTypes.string,
    router: PropTypes.object.isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
    isExclusivesV1: PropTypes.bool.isRequired,
    isWebDetailsStackedExperiment: PropTypes.bool.isRequired,
    user: PropTypes.object,
    purchases: PropTypes.object,
    isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
    updateListingsQuantity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleFocusQuantityPicker = this.handleFocusQuantityPicker.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
    this.handleSuperbowlModal = this.handleSuperbowlModal.bind(this);
    this.handleAffirmBannerTracker = this.handleAffirmBannerTracker.bind(this);
    this.handlePriceBreakdownInfoClick =
      this.handlePriceBreakdownInfoClick.bind(this);

    this.state = {
      loading: false,
      isUpdatingPricing: false,
    };
  }

  componentDidMount() {
    const { listing, isPurchasableListing } = this.props;
    if (!isPurchasableListing) {
      this.validateRoute(this.props);
      return;
    }

    // listing has disclosures. let's fetch the list of disclosures so we know how to render things
    if (listing.disclosures.length > 0) {
      this.props.fetchDisclosures();
    }

    this.props.updateLastVisitedListingId(listing.id);
  }

  componentDidUpdate() {
    const { isListingFlow, fullEvent, listing, router } = this.props;

    if (!listing) {
      const redirectPath = fullEvent.getPath();
      router.navigate({ pathname: redirectPath }, { replace: true });
    }

    if (isListingFlow) {
      this.validateRoute(this.props);
    }
  }

  validateRoute(props) {
    const {
      fullEvent,
      isPurchasableListing,
      performer,
      location: { pathname },
      router,
    } = props;
    const performerPath = getPerformerPath(performer);

    if (!isPurchasableListing) {
      if (fullEvent.isValid()) {
        const eventPath = getAssociatedEventPathname(pathname);
        router.navigate({ pathname: eventPath }, { replace: true });
      } else if (performerPath) {
        router.navigate({ pathname: performerPath }, { replace: true });
      } else {
        router.navigate({ pathname: '/' }, { replace: true });
      }
    }
  }

  handleAffirmBannerTracker() {
    const { clickContext, analyticsContext } = this.props;
    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.AFFIRM_BANNER();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }
  }

  handlePriceBreakdownInfoClick() {
    const {
      extendedPurchase: {
        seatFee,
        prefeeSeatPrice,
        discount,
        salesTax,
        totalPrice,
      },
      listing,
      fullEvent,
      selectedMetro,
      promoAmount,
      analyticsContext,
      clickContext,
    } = this.props;

    if (analyticsContext) {
      const interaction = Click.INTERACTIONS.PRICE_BREAKDOWN();
      const pageSource = Click.SOURCE_PAGE_TYPES.LISTING();
      const trackProperties = new ClickTracker()
        .interaction({ ...pageSource, ...interaction })
        .json();
      analyticsContext.track(
        new Click(_merge({}, clickContext, trackProperties))
      );
    }

    this.props.showModal(MODALS.PRICE_BREAKDOWN, {
      prefeeSeatPrice,
      currencyPrefix: getCurrencyPrefix(fullEvent, selectedMetro),
      seatCount: listing.currentSeatCount,
      discount,
      seatFee,
      salesTax,
      totalPrice,
      promoAmount,
    });
  }

  handleFocusQuantityPicker() {
    const { analyticsContext, clickContext, listing } = this.props;

    const trackProperties = new ClickTracker()
      .interaction(Click.INTERACTIONS.CHANGE_TICKET_QUANTITY())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.LISTING())
      .targetPageType(Click.TARGET_PAGE_TYPES.TICKET_QUANTITY())
      .payload({ quantity: listing.currentSeatCount })
      .json();

    analyticsContext.track(
      new Click(_merge({}, clickContext, trackProperties))
    );
  }

  async handleChangeQuantity(e) {
    const {
      updateUserPreference,
      updateListingsQuantity,
      fetchUserPromoCodesForListing,
      params,
      clickContext,
      analyticsContext,
    } = this.props;

    const quantity = parseInt(e.target.value, 10);

    const trackProperties = new ClickTracker()
      .interaction(Click.INTERACTIONS.RADIO_FEEDBACK())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.TICKET_QUANTITY())
      .targetPageType(Click.TARGET_PAGE_TYPES.LISTING())
      .payload({ quantity })
      .json();

    analyticsContext.track(
      new Click(_merge({}, clickContext, trackProperties))
    );

    // in addition to when a user updates their seat count manually, we call this method
    // whenever a purchase is initiated, because there are cases where a user is making a purchase
    // with a new quantity (ex: landing on a listing that doesn't actually have
    // the user's original preferred quantity available)
    updateUserPreference({ seatCount: quantity });

    updateListingsQuantity(quantity);

    try {
      const { eventId, listingId } = params;

      this.setState({ isUpdatingPricing: true });
      await fetchUserPromoCodesForListing({ eventId, listingId });
      this.setState({ isUpdatingPricing: false });
    } catch {
      this.setState({ isUpdatingPricing: false });
    }
  }

  initiateCheckoutFlow() {
    this.setState({ loading: true }, () => {
      const { location } = this.props;
      let { pathname } = location;
      if (pathname.endsWith('/')) {
        pathname = pathname.slice(0, -1);
      }

      setTimeout(() => {
        this.setState({ loading: false });
        this.navigateToNextPath(`${pathname}/checkout`);
      }, 200);
    });
  }

  navigateToNextPath(nextPath) {
    const { location } = this.props;

    this.props.router.navigate({
      pathname: nextPath,
      search: location.search,
    });
  }

  renderSeatMap() {
    const { fullEvent, listing } = this.props;
    const mapStyle = {
      backgroundImage: `url(${fullEvent.event.mapUrl}?width=${WIDTH_MOBILE_SEAT_MAP})`,
    };
    const markerStyle = {
      background: colors.gametimeGreenLight,
      top: `calc(${listing.seatMarker.top} - 18px)`,
      left: `calc(${listing.seatMarker.left} - 18px)`,
    };

    return (
      <div className={styles['seat-map-container']}>
        <div className={styles['seat-map']} style={mapStyle} />
        <motion.div
          layout
          className={styles['seat-map-marker']}
          style={markerStyle}
        />
      </div>
    );
  }

  renderTicketPrice() {
    const {
      extendedPurchase: { seatFee, salesTax },
      listing,
      priceWithPromoApplied,
      fullEvent,
      selectedMetro,
      showAllInPricing,
      showFaceValuePricing,
    } = this.props;

    const { isUpdatingPricing } = this.state;

    // The user has changed their seat count, so we need to update pricing
    if (isUpdatingPricing) return null;

    const isDiscounted = listing.isDiscounted();
    const savings = listing.getSavingsAmount() || 0;

    let isPromoApplied = false;
    let totalSavings = 0;
    let primaryPrice = 0;
    let price = showAllInPricing ? listing.getTotalPrice() : listing.getPrice();

    if (isDiscounted) {
      totalSavings = savings * listing.currentSeatCount;
      primaryPrice = showAllInPricing
        ? listing.getTotalPrice() + savings
        : listing.getPrice() + savings;
    }

    /**
     * If the user has at least one promo active in their account, calculate the
     * price to show on the listing details page here. The math for calculating
     * this price is as follows:
     *
     * Final Promo Price (from BE) - (Seat Fees * Seat Count) - (Sales Tax * Seat Count)
     */
    if (priceWithPromoApplied > 0) {
      const totalSeatFee = seatFee * listing.currentSeatCount;
      const totalSalesTax = salesTax * listing.currentSeatCount;
      const totalTaxAndFees = totalSeatFee + totalSalesTax;
      let adjustedTotal = priceWithPromoApplied;

      if (!showAllInPricing) {
        adjustedTotal -= totalTaxAndFees;
      }

      if (adjustedTotal > 0 && adjustedTotal !== price) {
        price = Math.ceil(adjustedTotal / listing.currentSeatCount);
        isPromoApplied = true;
      }
    }

    const showMarkdownBanner = isDiscounted && !isPromoApplied;

    return (
      <div className={styles['ticket-price-container']}>
        {showMarkdownBanner && (
          <div className={styles['discount-banner']}>
            You save ${totalSavings}
          </div>
        )}

        {isPromoApplied && (
          <div
            className={classNames(
              styles['promo-banner'],
              styles['discount-banner']
            )}
          >
            Promo Applied
          </div>
        )}

        <div className={styles['ticket-price']}>
          <AnimatePresence>
            <motion.div
              key={`ticket-price-${listing.id}`}
              className={styles['price-container']}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, position: 'relative' }}
              exit={{ opacity: 0, position: 'absolute' }}
              style={{ display: 'flex' }}
            >
              <span className={styles['price']}>
                {isDiscounted && (
                  <span className={styles['primary']}>
                    <TicketPrice price={primaryPrice} />
                  </span>
                )}
                <span
                  className={classNames(styles['per-ticket'], {
                    [styles['promo']]: isPromoApplied,
                  })}
                >
                  <TicketPrice
                    price={price}
                    currencyPrefix={getCurrencyPrefix(fullEvent, selectedMetro)}
                  />{' '}
                  each
                </span>
              </span>
              {(showFaceValuePricing || showAllInPricing) && (
                <IncludesFees onClick={this.handlePriceBreakdownInfoClick} />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    );
  }

  renderPrice() {
    const { extendedPurchase, priceWithPromoApplied, listing, seatRange } =
      this.props;
    const { isUpdatingPricing } = this.state;
    let { totalPrice = 0 } = extendedPurchase;

    if (priceWithPromoApplied > 0) {
      totalPrice = priceWithPromoApplied;
    }

    return (
      <>
        <div className={styles['price-details']}>
          <div>
            <QuantityPicker
              quantity={listing.currentSeatCount}
              quantities={listing.ticketQuantities}
              onFocus={this.handleFocusQuantityPicker}
              onChangeQuantity={this.handleChangeQuantity}
            />
            {seatRange && <p className={styles['seats-range']}>{seatRange}</p>}
          </div>

          {this.renderTicketPrice()}
        </div>
        {!isUpdatingPricing && totalPrice >= 50 && totalPrice < 20000 && (
          <AffirmPriceBanner
            price={totalPrice}
            clickTracker={this.handleAffirmBannerTracker}
          />
        )}
      </>
    );
  }

  renderContinueButton() {
    const { loading, isUpdatingPricing } = this.state;
    const { listing, extendedPurchase } = this.props;

    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        mpActions={
          new PurchaseListing({
            action: T_ACTIONS.PURCHASE_STARTED,
            purchase_fees: extendedPurchase.fees,
            purchase_total_price: extendedPurchase.totalPrice,
            seat_count: listing.currentSeatCount,
            payload: mapListingTrackingData(listing.listingTrackingData),
          })
        }
        loading={loading}
        disabled={loading || isUpdatingPricing}
        onClick={() => {
          this.initiateCheckoutFlow();
        }}
        clickTracker={new ClickTracker()
          .interaction(Click.INTERACTIONS.CONTINUE_BUTTON())
          .payload(mapListingTrackingData(listing.listingTrackingData))}
      >
        <span>CONTINUE</span>
      </ThemedButtonLoader>
    );
  }

  renderMeta() {
    const { fullEvent, listing } = this.props;
    const { section, sectionGroup, row, viewUrl } = listing;

    const title = getListingPageTitle({
      headline: fullEvent.getMediumName('vs', false),
      alias: `${sectionGroup} ${section}`,
      row,
      formatted: fullEvent.datetimeFriendly,
    });

    const breadcrumbs = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      getCategoryBreadcrumbConfig(fullEvent.event.category),
      getEventBreadcrumbConfig(fullEvent),
      getListingBreadcrumbConfig(listing, fullEvent, title),
    ];

    return (
      <div>
        <HeadTitle title={title} />
        <HeadImage src={viewUrl} />
        <JsonLD json={generateBreadcrumbSchema(breadcrumbs)} />
      </div>
    );
  }

  renderVerifiedTrustSignal() {
    return (
      <div className={styles['trust-signal-container']}>
        <div className={styles['verified-icon']}>
          <VerifiedIcon stroke={colors.black} />
        </div>
        <div className={styles['trust-signal-text-container']}>
          <span className={styles['trust-signal-primary-text']}>
            Verified Tickets
          </span>
          <span className={styles['trust-signal-secondary-text']}>
            100% Gametime Guaranteed
          </span>
        </div>
      </div>
    );
  }

  handleSuperbowlModal() {
    this.props.showModal(MODALS.SUPER_BOWL);
  }

  renderUrgencyMessaging(quantity) {
    return <UrgencyMessage type="listing" quantity={quantity} />;
  }

  renderTopValueBanner() {
    const { listing } = this.props;

    // soon to be backend driven
    const dealVariantText = dealVariants[listing.dealType].text;
    const percentileValue = DEAL_VALUE_PERCENTILE[listing.dealType];
    const title = `You found ${getArticle(dealVariantText)} ${dealVariantText}!`;

    const message = (
      <p className={styles['inline-banner-message']}>
        <b className={styles['inline-banner-title']}>{title}</b> These tickets
        are in the top {percentileValue}% for this event, based on value.
      </p>
    );

    return (
      <div className={styles['inline-banner-container']}>
        <Alert
          message={message}
          icon={<DealStarIcon width={32} height={32} />}
          backgroundColor={colors.graphiteBlack}
          borderColor={colors.gametimeGreenLight}
          textColor={colors.gray200}
        />
      </div>
    );
  }

  render() {
    const {
      fullEvent,
      listing,
      allDisclosures,
      allDeals,
      isExclusivesV1,
      isWebDetailsStackedExperiment,
      showFaceValuePricing,
      showAllInPricing,
      selectedMetro,
      extendedPurchase,
      priceWithPromoApplied,
      seatRange,
      user,
      purchases,
      isWebExclusivesV3Experiment,
    } = this.props;

    const currencyPrefix = getCurrencyPrefix(fullEvent, selectedMetro);

    const showSponsorDealBadge = isExclusivesV1 && listing.isExclusivesDeal;

    if (isObjectEmpty(listing)) {
      return null;
    }
    const maxLotSize = listing.getMaxLotSize();

    // Only show urgency messaging if the maximum available lot size is less than or equal to threshold
    const isUrgencyMessagingEligible =
      maxLotSize > 0 && maxLotSize <= URGENCY_MESSAGING_THRESHOLD;

    const isZoneDeal = isListingZoneDeal(listing);
    const isFlashDeal = isListingFlashDeal(listing);
    const dealType = listing.dealType;
    const dealInfo = allDeals[dealType] || null;

    const showTopValueBanner =
      isWebExclusivesV3Experiment && isTopValueDeal(listing.dealType);

    return (
      <div className={styles['ticket-card']}>
        {this.renderMeta()}
        <div className={styles.card}>
          <motion.div
            className={styles['card-headers']}
            transition={{ duration: 0.5 }}
            variants={{
              on: { x: 'calc(-100% - 7px)' },
              off: { x: 0 },
            }}
            initial={false}
          >
            <div className={styles['card-header']}>
              <DeliveryBadge
                deliveryFormat={deliveryFormatForListing(listing)}
              />
              {isExclusivesV1
                ? showSponsorDealBadge && (
                    <div className={styles['sponsor-deal-badge']}>
                      <SponsorDealBadge variant={listing.dealType} />
                    </div>
                  )
                : (isZoneDeal || isFlashDeal || dealInfo) && (
                    <Tag
                      listing={listing}
                      fullEvent={fullEvent}
                      isZoneDeal={isZoneDeal}
                      isFlashDeal={isFlashDeal}
                      dealInfo={dealInfo}
                    />
                  )}
            </div>
          </motion.div>
          <div className={styles['card-sub-header']}>
            {this.renderSeatMap()}
          </div>
          {isWebDetailsStackedExperiment ? (
            <DetailsStack
              fullEvent={fullEvent}
              listing={listing}
              allDisclosures={allDisclosures}
              continueButton={this.renderContinueButton()}
              onPriceBreakdownInfoClick={this.handlePriceBreakdownInfoClick}
              showFaceValuePricing={showFaceValuePricing}
              showAllInPricing={showAllInPricing}
              currencyPrefix={currencyPrefix}
              isUpdatingPricing={this.state.isUpdatingPricing}
              priceWithPromoApplied={priceWithPromoApplied}
              extendedPurchase={extendedPurchase}
              onAffirmBannerClick={this.handleAffirmBannerTracker}
              seatRange={seatRange}
              ticketQuantities={listing.ticketQuantities}
              onFocusQuantityPicker={this.handleFocusQuantityPicker}
              onChangeQuantity={this.handleChangeQuantity}
              maxLotSize={maxLotSize}
              user={user}
              purchases={purchases}
              handleSuperbowlModal={this.handleSuperbowlModal}
              topValueBanner={showTopValueBanner && this.renderTopValueBanner()}
              verifiedMessage={
                isSuperBowl(fullEvent.id) && this.renderVerifiedTrustSignal()
              }
            />
          ) : (
            <div className={styles['card-body']}>
              {isUrgencyMessagingEligible &&
                this.renderUrgencyMessaging(maxLotSize)}
              {showTopValueBanner && this.renderTopValueBanner()}
              <div className={styles['listing-details']}>
                <h3 className={styles.datetime}>
                  <EventDateTime fullEvent={fullEvent} type="DOTTED" />
                </h3>
                <AnimatePresence>
                  <motion.div
                    key={`pricing-summary-${listing.id}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, position: 'relative' }}
                    exit={{ opacity: 0, position: 'absolute' }}
                    style={{ display: 'flex' }}
                  >
                    <PricingSummary
                      seatCount={listing.currentSeatCount}
                      section={listing.section}
                      rowDesc={listing.row}
                      showSeats={!seatRange}
                      isGeneralAdmission={listing.isGeneralAdmission}
                    />
                  </motion.div>
                </AnimatePresence>
                {isSuperBowl(fullEvent.id) ? (
                  listing.disclosures.includes(ZONE_TICKET_DISCLOSURE) && (
                    <ZoneTag
                      variant="text-info-icon"
                      onTouchEnd={this.handleSuperbowlModal}
                      onClick={this.handleSuperbowlModal}
                    />
                  )
                ) : (
                  <Disclosures
                    allDisclosures={allDisclosures}
                    disclosures={listing.disclosures}
                  />
                )}
              </div>
              {this.renderPrice()}
              <div>
                {isSuperBowl(fullEvent.id) ? (
                  <div className={styles['detail-row']}>
                    {this.renderVerifiedTrustSignal()}
                  </div>
                ) : (
                  <div className={styles['detail-row']}>
                    <GTCoverage />
                  </div>
                )}
                <div className={styles['action-button']}>
                  {this.renderContinueButton()}
                </div>
                {user && <div className={styles.email}>{user.email}</div>}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { listing, location } = props;
  const eventId = props.eventId || props.params.eventId;
  const fullEvent = selectFullEventById(state, eventId);

  const isAllInPricingWithFlag = selectIsVenueAllInPrice(
    state,
    fullEvent.venueState
  );

  const showRegulatoryAllInPricing = isDefaultAllInState(fullEvent?.venueState);
  const showFaceValuePricing = isFaceValueState(fullEvent?.venueState);
  const isAllInPriceActive = selectIsAllInPricing(state);
  const showAllInPricing =
    showRegulatoryAllInPricing || isAllInPriceActive || isAllInPricingWithFlag;

  const isInitialUserPurchase = selectIsInitialUserPurchase(state);

  return {
    extendedPurchase: props.listing && extendedPurchaseSelector(state, props),
    creditCard: userPurchaseCreditCard(state),
    isPurchasableListing: isPurchasableListingSelector(state, props),
    isListingFlow: isListingDetailsPage(location.pathname),
    fullEvent,
    performer: fullEvent && fullEvent.getPrimaryPerformer(),
    prePurchaseStepsNeeded:
      props.listing && isThirdPartyDelivery(deliveryTypeFor(props.listing)),
    allDeals: selectAllDeals(state),
    allDisclosures: allDisclosuresSelector(state),
    priceWithPromoApplied: userPromosForListingPriceSelector(state),
    listing,
    selectedMetro: (selectUserMetro(state) || selectClosestMetro(state))?.name,
    promoAmount: userPromosForListingSavingsSelector(state),
    showAllInPricing,
    showFaceValuePricing,
    isPromoEligible: isInitialUserPurchase,
    seatRange: getSeatRange(listing.seats),
    isExclusivesV1: selectIsWebExclusivesV1Experiment(state),
    isWebDetailsStackedExperiment: selectIsWebDetailsStackedExperiment(state),
    user: selectUserDetails(state),
    purchases: selectCompleteUserPurchases(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
  };
};

const mapDispatchToProps = {
  updateUserPreference,
  updateLastVisitedListingId,
  showModal,
  fetchDisclosures,
  fetchUserPromoCodesForListing,
  updateListingsQuantity,
};

const loader =
  (_context) =>
  async ({
    context: { store: { getState, dispatch } } = _context,
    params: { eventId, listingId },
    request,
  }) => {
    const location = new URL(request.url);
    // TODO: Figure out abortController
    const state = getState();
    const user = selectUserDetails(state);

    if (!user) {
      return null;
    }

    await dispatch(
      fetchCompleteUserPurchases({
        user_id: user.id,
        session_token: user.session_token,
      })
    );

    const { zoom: zoomQueryParam } = searchStringToQueryObject(location.search);
    let zoomLevel = lastZoomLevelSelector(state);

    if (zoomQueryParam && Number.isFinite(parseInt(zoomQueryParam, 10))) {
      zoomLevel = parseInt(zoomQueryParam, 10);
    }
    const listing = selectListingById(state, listingId);
    if (!listing) {
      return dispatch(fetchListings({ eventId, zoom: zoomLevel })).then(() =>
        dispatch(
          fetchUserPromoCodesForListing({
            eventId,
            listingId /*, abortController*/,
          })
        )
      );
    } else {
      await dispatch(
        fetchUserPromoCodesForListing({
          eventId,
          listingId /*, abortController*/,
        })
      );
    }

    return null;
  };

const ListingWrapper = withRouter(
  withOutletContext(
    withAppContext(connect(mapStateToProps, mapDispatchToProps)(Listing))
  )
);

ListingWrapper.loader = loader;

export default ListingWrapper;
