import { useSearchParams } from 'react-router-dom';

import { searchStringToQueryObject } from 'utils/url';

const useQueryParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = searchStringToQueryObject(search.toString());

  return [searchAsObject, setSearch];
};

export default useQueryParams;
