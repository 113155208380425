import { eventType, zoomType } from '../../constants';
import { handleCallback } from '../../utils/callback.utils';
import { getContext } from '../../utils/context.utils';
import { handleCancelAnimation } from '../animations/animations.utils';
import { handleCalculateBounds } from '../bounds/bounds.utils';
import { handleAlignToScaleBounds } from '../zoom/zoom.logic';
import { handleCalculateZoomPositions } from '../zoom/zoom.utils';

import {
  getDelta,
  getMousePosition,
  handleCalculateWheelZoom,
  handleWheelZoomStop,
} from './wheel.utils';

const wheelStopEventTime = 160;
const wheelAnimationTime = 100;

export const handleWheelStart = (contextInstance, event) => {
  const { onWheelStart, onZoomStart } = contextInstance.props;

  if (!contextInstance.wheelStopEventTimer) {
    handleCancelAnimation(contextInstance);
    handleCallback(getContext(contextInstance), event, onWheelStart);
    handleCallback(getContext(contextInstance), event, onZoomStart);
  }
};

export const handleWheelZoom = (contextInstance, event) => {
  const { onWheel, onZoom } = contextInstance.props;

  const { contentComponent, setup, transformState } = contextInstance;
  const { scale } = transformState;
  const { limitToBounds, centerZoomedOut, zoomAnimation, wheel } = setup;
  const { size, disabled } = zoomAnimation;
  const { step } = wheel;

  if (!contentComponent) {
    throw new Error('Component not mounted');
  }

  event.preventDefault();
  event.stopPropagation();

  const delta = getDelta(event, null);
  const newScale = handleCalculateWheelZoom(
    contextInstance,
    delta,
    step,
    !event.ctrlKey
  );

  // if scale not change
  if (scale === newScale) return;

  const bounds = handleCalculateBounds(contextInstance, newScale);

  const mousePosition = getMousePosition(event, contentComponent, scale);

  const isPaddingDisabled = disabled || size === 0 || centerZoomedOut;
  const isLimitedToBounds = limitToBounds && isPaddingDisabled;

  const { x, y } = handleCalculateZoomPositions(
    contextInstance,
    mousePosition.x,
    mousePosition.y,
    newScale,
    bounds,
    isLimitedToBounds
  );

  contextInstance.previousWheelEvent = event;

  const interaction = {
    zoomType: event.deltaY < 0 ? zoomType.zoomIn : zoomType.zoomOut,
    eventType: null,
  };

  contextInstance.setTransformState(newScale, x, y, interaction);

  handleCallback(getContext(contextInstance), event, onWheel);
  handleCallback(getContext(contextInstance), event, onZoom);
};

export const handleWheelStop = (contextInstance, event, handleZoomEnd) => {
  const { onWheelStop, onZoomStop } = contextInstance.props;

  // fire animation
  clearTimeout(contextInstance.wheelAnimationTimer);
  contextInstance.wheelAnimationTimer = setTimeout(() => {
    if (!contextInstance.mounted) return;
    handleAlignToScaleBounds(contextInstance, event.x, event.y);
    contextInstance.wheelAnimationTimer = null;
  }, wheelAnimationTime);

  // Wheel stop event
  const hasStoppedZooming = handleWheelZoomStop(contextInstance, event);
  if (hasStoppedZooming) {
    clearTimeout(contextInstance.wheelStopEventTimer);
    contextInstance.wheelStopEventTimer = setTimeout(() => {
      if (!contextInstance.mounted) return;
      contextInstance.wheelStopEventTimer = null;
      contextInstance.setInteractionState(eventType.wheel);
      handleCallback(getContext(contextInstance), event, onWheelStop);
      handleCallback(getContext(contextInstance), event, onZoomStop);
      handleZoomEnd();
    }, wheelStopEventTime);
  }
};
