import React from 'react';
import PropTypes from 'prop-types';

import { usePinPosition } from '../../hooks';

import SeatMapPinLarge from './SeatMapPinLarge';
import SeatMapPinStick from './SeatMapPinStick';

/* Used on Checkout */
const SeatMapPinSingle = ({ data, scale, width, height }) => {
  const { widthRatio, heightRatio, inverseScale } = usePinPosition(
    width,
    height,
    scale
  );

  if (!data) {
    return null;
  }

  const inverseTranslateX = (inverseScale / 2) * 100;
  const adjustedHeight = 55 * inverseScale;

  const coord = {
    top: data.mapY * heightRatio,
    left: data.mapX * widthRatio,
  };

  return (
    <>
      <div
        data-testid="seat-map-pin-single"
        style={{
          position: 'absolute',
          top: coord.top,
          left: coord.left,
          marginTop: -adjustedHeight,
          transform: `translateX(-${inverseTranslateX}%) scale(${inverseScale})`,
        }}
      >
        <SeatMapPinLarge
          imgProps={{
            src: data.viewUrl,
            alt: 'Seat view',
          }}
        />
      </div>
      <SeatMapPinStick
        coord={coord}
        scale={scale}
        inverseScale={inverseScale}
        isLargePin
      />
    </>
  );
};

export default SeatMapPinSingle;

SeatMapPinSingle.propTypes = {
  data: PropTypes.object,
  scale: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};
