import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from 'ui/LoadingIcon/LoadingIcon';

import { VARIANTS } from './constants';

import styles from './Overlay.module.scss';

/**
 * Overlay used to display over the page when the site is either loading
 * or submitting a form; meant to show over all other elements on the page.
 */
const Overlay = ({
  variant = VARIANTS.DEFAULT,
  isLoading = false,
  onClick = () => {},
}) => {
  if (variant === VARIANTS.LOADING) {
    if (!isLoading) return null;

    return (
      <div className={styles.overlay}>
        <LoadingIcon isLoading />
      </div>
    );
  }

  if (variant === VARIANTS.SUBMITTING) {
    return (
      <div className={styles.overlay} onClick={onClick}>
        <LoadingIcon isLoading={isLoading} />
      </div>
    );
  }

  return <div className={styles.overlay} />;
};

Overlay.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(Overlay);
