import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { Container } from 'ui/Layout';

import ToastNotification from './ToastNotification';

import 'react-toastify/dist/ReactToastify.min.css';
import styles from './Notifications.module.scss';

export type Notification = {
  title: string;
  message: string;
  autoClose: number; // in milliseconds
};

export const showNotification = (notification: Notification) => {
  setTimeout(() => {
    toast(
      <ToastNotification
        title={notification.title}
        message={notification.message}
      />,
      {
        autoClose: notification.autoClose,
      }
    );
  }, 500);
};

const Notifications = () => {
  return (
    <Container className={styles['default-notification']}>
      <ToastContainer
        position="top-center"
        transition={Slide}
        hideProgressBar
        pauseOnFocusLoss={false}
        draggable={false}
        closeOnClick={false}
      />
    </Container>
  );
};

export default Notifications;
