import React from 'react';

export default (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.3706 39.5486C27.732 37.8164 25.9986 36.5925 24 36.5925C22.0014 36.5925 20.268 37.8164 19.6294 39.5486C19.4331 40.0811 19.0585 40.3703 18.7406 40.3703L13.2594 40.3703C12.9117 40.3703 12.6297 40.0883 12.6297 39.7406L12.6297 8.25945C12.6297 7.91166 12.9117 7.62972 13.2595 7.62972L18.7406 7.62972C19.0585 7.62972 19.4331 7.91887 19.6294 8.4514C20.268 10.1836 22.0014 11.4075 24 11.4075C25.9986 11.4075 27.732 10.1836 28.3706 8.45141C28.5669 7.91887 28.9415 7.62972 29.2594 7.62972L34.7406 7.62972C35.0883 7.62972 35.3703 7.91166 35.3703 8.25945L35.3703 39.7406C35.3703 40.0883 35.0883 40.3703 34.7405 40.3703L29.2594 40.3703C28.9415 40.3703 28.5669 40.0811 28.3706 39.5486Z"
      stroke={props.color || '#fff'}
      strokeWidth="1.25945"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6272 30.2799C23.849 30.4429 24.151 30.4429 24.3728 30.2799L29.1639 26.7606C29.487 26.5233 29.6778 26.1464 29.6778 25.7455V19.6954C29.6778 19.4205 29.4994 19.1773 29.2371 19.0947L24.0946 17.4758C24.033 17.4564 23.967 17.4564 23.9055 17.4758L18.7629 19.0947C18.5006 19.1773 18.3223 19.4205 18.3223 19.6954V25.7455C18.3223 26.1464 18.5131 26.5233 18.8361 26.7606L23.6272 30.2799Z"
      stroke={props.color || '#fff'}
      strokeWidth="1.25945"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8926 25.1739C25.8926 26.163 25.1797 26.7409 24.0505 26.7409C22.9275 26.7409 22.1074 26.1808 22.1074 25.0726V21.6289C22.1074 20.5862 22.9402 19.9785 24.0316 19.9785C25.0535 19.9785 25.88 20.5207 25.88 21.4799V22.183H24.7507V21.5753C24.7507 21.194 24.4605 20.9676 24.0505 20.9676C23.5836 20.9676 23.3565 21.2595 23.3565 21.6646V25.0488C23.3565 25.4718 23.6026 25.7399 24.0505 25.7399C24.5299 25.7399 24.7003 25.4778 24.7003 25.1203V24.0598H23.9432V23.178H25.8926V25.1739Z"
      fill={props.color || '#fff'}
    />
  </svg>
);
