import React from 'react';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './MenuDropdownItem.module.scss';

const MenuDropdownItem = ({ icon, label, onClick }) => {
  return (
    <ButtonLoader className={styles['menu-dropdown-item']} onClick={onClick}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.label}>{label}</span>
    </ButtonLoader>
  );
};

MenuDropdownItem.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuDropdownItem;
