import React from 'react';
import { createRoutesFromElements, Route } from 'react-router-dom';

import { selectIsHomepageRedesignV1Experiment } from 'experiments';

import App from '../pages/App/App';
import Blog from '../pages/Blog/Blog';
import Post from '../pages/Blog/Post';
import CategoryPerformers from '../pages/CategoryPerformers/CategoryPerformers';
import Checkout from '../pages/Checkout/Checkout';
import Collection from '../pages/Collection/Collection';
import ContentPage from '../pages/ContentPageCMS/ContentPage';
import Event from '../pages/Event/Event';
import GenrePerformers from '../pages/GenrePerformers/GenrePerformers';
import Healthcheck from '../pages/Healthcheck/Healthcheck';
import Home from '../pages/Home/Home';
import HomeV1 from '../pages/HomeV1/HomeV1';
import Listing from '../pages/Listing/Listing';
import Login from '../pages/Login/Login';
import MainMetro from '../pages/MainMetro/MainMetro';
import MetroPerformers from '../pages/MetroPerformers/MetroPerformers';
import MyAccount from '../pages/MyAccount/MyAccount';
import MyTickets from '../pages/MyTickets/MyTickets';
import NotFound from '../pages/NotFound/NotFound';
import Order from '../pages/Order/Order';
import Performer from '../pages/Performer/Performer';
import Picks from '../pages/Picks/Picks';
import { PURCHASE_PATH } from '../pages/Purchase/constants';
import Purchase from '../pages/Purchase/Purchase';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Search from '../pages/Search/Search';
import Sitemap from '../pages/Sitemap/Sitemap';
import SportsByMetro from '../pages/SportsByMetro/SportsByMetro';
import About from '../pages/StaticPages/About/About';
import Careers from '../pages/StaticPages/Careers/Careers';
import PromoCodes from '../pages/StaticPages/PromoCodes/PromoCodes';
import WhyGametime from '../pages/StaticPages/WhyGametime/WhyGametime';
import Unavailable from '../pages/Unavailable/Unavailable';
import Venue from '../pages/Venue/Venue';

import ProtectedCheckoutRoute from './ProtectedCheckoutRoute';

const loaderWrapper =
  (loader) =>
  async (...loaderArgs) => {
    try {
      const result = await loader(...loaderArgs);
      return result;
    } catch {
      return null;
    }
  };

function getRoutes(context = {}) {
  const isHomepageRedesignV1Experiment =
    context.store?.getState &&
    selectIsHomepageRedesignV1Experiment(context.store.getState());
  const HomeComponent = isHomepageRedesignV1Experiment ? HomeV1 : Home;

  return createRoutesFromElements(
    <Route>
      <Route path="/healthcheck" element={<Healthcheck />} />
      <Route path="/unavailable" element={<Unavailable />} />
      <Route path="/" element={<App />}>
        <Route
          index
          element={<HomeComponent />}
          loader={loaderWrapper(HomeComponent.loader(context))}
        />
        <Route
          path="search"
          element={<Search />}
          loader={loaderWrapper(Search.loader(context))}
        />
        <Route
          path="login"
          element={<Login />}
          loader={loaderWrapper(Login.loader(context))}
        />
        <Route
          path="order/:transactionId"
          element={<Order />}
          loader={loaderWrapper(Order.loader(context))}
        />
        <Route
          path="blog/:slug/*"
          element={<Post />}
          loader={loaderWrapper(Post.loader(context))}
        />
        <Route
          path="blog"
          element={<Blog />}
          loader={loaderWrapper(Blog.loader(context))}
        />
        <Route
          path="my-tickets"
          element={<MyTickets />}
          loader={loaderWrapper(MyTickets.loader(context))}
        />
        <Route
          path="my-account"
          element={<MyAccount />}
          loader={loaderWrapper(MyAccount.loader(context))}
        />
        <Route
          path="c/:categoryId"
          element={<CategoryPerformers />}
          loader={loaderWrapper(CategoryPerformers.loader(context))}
        />
        <Route
          path="/concert-tickets/genre/:genre"
          element={<GenrePerformers />}
          loader={loaderWrapper(GenrePerformers.loader(context))}
        />
        <Route
          path=":collectionDescriptorSlug?/:metro/collection/:slug/:view?"
          element={<Collection />}
          loader={loaderWrapper(Collection.loader(context))}
        />
        <Route
          path=":performerTicketSlug?/performers/:slug/:matchupSlug?/:parkingSlug?"
          element={<Performer />}
          loader={loaderWrapper(Performer.loader(context))}
        />
        <Route
          path=":venueTicketSlug?/venues/:slug"
          element={<Venue />}
          loader={loaderWrapper(Venue.loader(context))}
        />
        <Route
          path=":metroTicketSlug?/metros/:metroId/:categoryGroupId?"
          element={
            isHomepageRedesignV1Experiment ? <HomeV1 /> : <MetroPerformers />
          }
          loader={loaderWrapper(MetroPerformers.loader(context))}
        />
        <Route
          path=":categoryPrefix?/:eventName?/:eventDetails?/events/:eventId"
          element={<Event />}
          loader={loaderWrapper(Event.loader(context))}
        >
          <Route path="listings/:listingId">
            <Route
              index
              element={<Listing />}
              loader={loaderWrapper(Listing.loader(context))}
            />
            <Route element={<ProtectedCheckoutRoute />}>
              <Route path={PURCHASE_PATH} element={<Purchase />} />
              <Route
                path="checkout"
                element={<Checkout />}
                loader={loaderWrapper(Checkout.loader(context))}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="events-near-me"
          element={<MainMetro />}
          loader={loaderWrapper(MainMetro.loader(context))}
        />
        <Route
          path="/sport/:metroId"
          element={<SportsByMetro />}
          loader={loaderWrapper(SportsByMetro.loader(context))}
        />
        <Route
          path="/about"
          element={<About />}
          loader={loaderWrapper(About.loader(context))}
        />
        <Route
          path="/careers"
          element={<Careers />}
          loader={loaderWrapper(Careers.loader(context))}
        />
        <Route
          path="/why-gametime"
          element={<WhyGametime />}
          loader={loaderWrapper(WhyGametime.loader(context))}
        />
        <Route
          path="/promo-codes"
          element={<PromoCodes />}
          loader={loaderWrapper(PromoCodes.loader(context))}
        />
        <Route
          path="/sitemap"
          element={<Sitemap />}
          loader={loaderWrapper(Sitemap.loader(context))}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/picks"
          element={<Picks />}
          loader={loaderWrapper(Picks.loader(context))}
        />

        {/* Path must be the same as the slug from ButterCMS API */}
        <Route
          path="/terms-of-service"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/privacy-policy"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/share-codes"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/price-guarantee"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/gametime-guarantee"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/cookies-policy"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />
        <Route
          path="/press"
          element={<ContentPage />}
          loader={loaderWrapper(ContentPage.loader(context))}
        />

        <Route
          path="*"
          element={<NotFound />}
          loader={loaderWrapper(NotFound.loader(context))}
        />
      </Route>
    </Route>
  );
}

export default getRoutes;
