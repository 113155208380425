export const TRANSFER_TYPES = {
  TM: 'Ticketmaster',
  MLB: 'MLB Ballpark App',
  SG: 'SeatGeek',
  TEXT: 'text message',
  AXS: 'AXS',
};

export function transferTypeFor(listing) {
  return listing.transferType || null;
}
