import { ACTIONS } from './constants';

const initialState = {};

export const getFilterKey = (filters) => {
  const filterKeys = [];

  if (filters.slug) {
    filterKeys.push(filters.slug);
  }

  return filterKeys.length ? filterKeys.join('+') : '';
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_VENUES_REQUEST_SUCCESS:
      const filterKey = getFilterKey(action.params);

      if (filterKey && action.result.results && action.result.results.length) {
        return {
          ...state,
          [filterKey]: action.result.results.map((venue) => venue.slug),
        };
      }
      return state;

    default:
      return state;
  }
}
