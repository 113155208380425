import React, { useState } from 'react';
import { motion } from 'framer-motion';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import PaginationButton from 'components/PaginationButton/PaginationButton';

import styles from './Content.module.scss';

const Content = ({
  body = '',
  contentBody = '',
  isActive = false,
  isInitiallyExpanded = false,
  headline = '',
}) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [isExpanded, setIsContentExpanded] = useState(isInitiallyExpanded);
  if (!isActive) {
    return null;
  }

  const toggle = () => {
    const tracker = new ClickTracker().interaction(
      isExpanded
        ? Click.INTERACTIONS.CLOSE_PANEL()
        : Click.INTERACTIONS.OPEN_PANEL(),
      {
        title: headline,
      }
    );

    analytics.track(new Click(_merge({}, clickContext, tracker.json())));
    setIsContentExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles['content-container']}>
        <motion.div
          transition={{ duration: 0.5 }}
          initial={isExpanded ? 'open' : 'collapsed'}
          animate={isExpanded ? 'open' : 'collapsed'}
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 150 },
          }}
        >
          <div
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: contentBody || body }}
          />
        </motion.div>
        {!isExpanded && <div className={styles['bottom-fade']} />}
      </div>
      <div className={styles['pagination-button-container']}>
        <PaginationButton
          onClick={toggle}
          label={isExpanded ? 'show less' : 'show more'}
          isExpanded={isExpanded}
        />
      </div>
    </>
  );
};

Content.propTypes = {
  body: PropTypes.string,
  contentBody: PropTypes.string,
  isActive: PropTypes.bool,
  isInitiallyExpanded: PropTypes.bool,
  headline: PropTypes.string,
};

export default Content;
