import React from 'react';
import PropTypes from 'prop-types';

import TicketPrice from 'components/TicketPrice/TicketPrice';

import styles from './PurchasePrice.module.scss';

const propTypes = {
  prefeeSeatPrice: PropTypes.number,
  currencyPrefix: PropTypes.string,
  seatCount: PropTypes.number,
  discount: PropTypes.object,
};

const PurchasePrice = ({
  currencyPrefix,
  seatCount,
  prefeeSeatPrice,
  discount,
}) => (
  <ul className={styles['purchase-price']}>
    {discount.available && (
      <li className={styles['primary-price-off']}>
        {`${discount.percentDiscountPercentage}% OFF`}
      </li>
    )}
    <li>
      {seatCount}
      <span className={styles['multiply-symbol']}>&#x2715;</span>
      <TicketPrice price={prefeeSeatPrice} currencyPrefix={currencyPrefix} />
    </li>
  </ul>
);

PurchasePrice.propTypes = propTypes;

export default PurchasePrice;
