import React from 'react';
import { useToggle } from 'react-use';
import PropTypes from 'prop-types';

import {
  INTERACTION,
  LOCKED_DEAL,
  PAYLOAD,
  useAnalyticsContext,
  View,
} from 'analytics';
import { UnlockDealEvent } from 'analytics/events/UnlockDeal';
import { dealsData, dealTypes } from 'components/Deals/helpers';
import Image from 'components/Image/Image';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import AlarmClock from 'icons/AlarmClockIcon';
import SparklesIcon from 'icons/SparklesIcon';
import { FullEvent, Listing } from 'models';
import colors from 'styles/colors.constants';

import AnimationCircle from '../AnimatedCircle';
import CellContent from '../CellTitle/CellContent';
import ClickToUnlockButton from '../ClickToUnlockButton';
import SlidingButton from '../SlidingButton';

import styles from './LockedDeal.module.scss';

const LockedDeal = ({
  onUnlockDeal,
  listings = [],
  fullEvent,
  dealType = dealTypes.zoneDeal,
  isCarousel = false,
  isExclusivesV1 = false,
}) => {
  const analytics = useAnalyticsContext();
  const isMobile = useMediaQuery(device.down.md);
  const deal = dealsData[dealType];
  const [showAnimation, setShowAnimation] = useToggle(false);

  const handleOnUnlock = () => {
    setShowAnimation(true);

    // update anlytics for tracking multiple items in carousel MWEB-4157
    setTimeout(() => {
      const listingsIds = listings.map((listItem) => listItem.id);

      analytics.track(
        new UnlockDealEvent(
          View.PAGE_TYPES.LISTING({
            [PAYLOAD.FULLEVENT]: fullEvent,
            [PAYLOAD.LISTING]: listings[0] || '',
            payload: {
              [PAYLOAD.MODE]: isCarousel
                ? LOCKED_DEAL.CAROUSEL
                : LOCKED_DEAL.LIST,
              [PAYLOAD.DEAL]: deal.analytics,
              [PAYLOAD.LISTINGS_IDS]: listingsIds.join(),
            },
            [PAYLOAD.INTERACTION]: isMobile
              ? INTERACTION.SWIPE
              : INTERACTION.CLICK,
            [PAYLOAD.PAGE_TYPE]: undefined,
          })
        )
      );
      onUnlockDeal();
    }, 400);
  };

  if (listings.length === 0) {
    return null;
  }

  const color = isExclusivesV1 ? colors.exclusiveIconZone : deal.color;
  const shadow = isExclusivesV1 ? 'rgba(175, 147, 255, 0.2)' : deal.shadow;
  const DealIcon = isExclusivesV1 ? SparklesIcon : AlarmClock;

  return (
    <div className={styles['slider-container']}>
      <div className={styles['slider-image']}>
        <Image
          className="image"
          {...listings[0].getImageOptions()}
          lazyLoad={false}
        />
      </div>

      <div className={styles['slider-content']}>
        <CellContent
          dealType={dealType}
          title={deal.titleSlider}
          color={color}
          icon={<DealIcon fill={color} width={18} height={18} />}
        />
        {isMobile ? (
          <SlidingButton onSwipe={handleOnUnlock} color={color} />
        ) : (
          <ClickToUnlockButton
            onClick={handleOnUnlock}
            color={color}
            shadow={shadow}
          />
        )}

        <AnimationCircle open={showAnimation} color={color} />
      </div>
    </div>
  );
};

LockedDeal.propTypes = {
  onUnlockDeal: PropTypes.func.isRequired,
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  dealType: PropTypes.oneOf([dealTypes.flashDeal, dealTypes.zoneDeal]),
  isCarousel: PropTypes.bool,
  isExclusivesV1: PropTypes.bool,
};

export default LockedDeal;
