import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';

import styles from './MetroPill.module.scss';

function MetroPill({ name, displayName, to, clickTracker }) {
  return (
    <Link to={to} title={name} clickTracker={clickTracker}>
      <div className={styles['metro-pill-container']}>
        <span className={styles['metro-pill-title']}>{displayName}</span>
      </div>
    </Link>
  );
}

MetroPill.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  clickTracker: PropTypes.object.isRequired,
};

export default MetroPill;
