import React from 'react';

import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import { CheckCircleFilledIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './MLBInfoCollectionModal.module.scss';

interface MLBInfoCollectionModalProps {
  onSubmit: () => void;
  showMLBInfoCollectionModal: boolean;
  setShowMLBInfoCollectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

const MLBInfoCollectionModal = ({
  onSubmit,
  showMLBInfoCollectionModal,
  setShowMLBInfoCollectionModal,
  email,
}: MLBInfoCollectionModalProps) => (
  <GTUniversalModal show={showMLBInfoCollectionModal}>
    <div className={styles['modal']}>
      <div className={styles['modal-header']}>
        <CheckCircleFilledIcon fill={colors.black} />
      </div>
      <span className={styles['modal-subtitle']}>
        Is this email address correct?
      </span>
      <div className={styles['modal-text-body']}>
        <span className={styles['modal-text']}>
          Make sure your email address is correct -- your tickets will be
          associated with this address.
        </span>
        <span>Email: {email}</span>
      </div>
      <div className={styles['modal-buttons']}>
        <button
          className={styles['modal-cancel-button']}
          onClick={() => setShowMLBInfoCollectionModal(false)}
        >
          let me fix it
        </button>
        <button
          className={styles['modal-submit-button']}
          role="button"
          onClick={() => onSubmit()}
        >
          confirm
        </button>
      </div>
    </div>
  </GTUniversalModal>
);

export default MLBInfoCollectionModal;
