import React from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_TYPES_CONFIG } from 'store/datatypes/PAYMENT_TYPES';

import styles from './CardDetails.module.scss';

const CardDetails = ({
  card: { last_four, expiration_month, expiration_year, card_type },
}) => {
  const cardConfig = PAYMENT_TYPES_CONFIG[card_type];
  const formattedYear = expiration_year.substring(2, 4);

  return (
    <div className={styles['card-container']}>
      {cardConfig?.icon}
      <div className={styles['card-details']}>
        <div className={styles['card-details-primary']}>
          <span className={styles['card-spacing-sm']}>
            {cardConfig?.displayName}
          </span>
          <span className={styles['card-star']} />
          <span className={styles['card-last-four']}>{last_four}</span>
        </div>
        {expiration_month && expiration_year && (
          <div className={styles['card-expiry']}>
            {`Expires ${expiration_month}/${formattedYear}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDetails;

CardDetails.propTypes = {
  card: PropTypes.shape({
    card_type: PropTypes.string,
    expiration_month: PropTypes.string,
    expiration_year: PropTypes.string,
    expired: PropTypes.bool,
    last_four: PropTypes.string,
  }),
};
