import React from 'react';
import PropTypes from 'prop-types';

import DeliveryIcon from 'components/DeliveryFormat/DeliveryIcon/DeliveryIcon';
import ExtendedIconCard from 'components/ExtendedIconCard/ExtendedIconCard';
import IconCard from 'components/IconCard/IconCard';
import colors from 'styles/colors.constants';

import styles from './DetailDeliveryCard.module.scss';

const DetailDeliveryCard = ({
  deliveryFormat,
  title,
  text,
  inlineLink,
  displayExtended = false,
  deliveryIconProps = { fill: colors.gray200 },
  uniformPadding,
  orderedList,
}) => {
  if (displayExtended) {
    return (
      <div className={styles['delivery-container']}>
        <ExtendedIconCard
          icon={
            <DeliveryIcon
              deliveryFormat={deliveryFormat}
              iconProps={deliveryIconProps}
            />
          }
          title={title}
          text={text}
          inlineLink={inlineLink}
          orderedList={orderedList}
        />
      </div>
    );
  }

  return (
    <IconCard
      icon={
        <DeliveryIcon
          deliveryFormat={deliveryFormat}
          iconProps={deliveryIconProps}
        />
      }
      title={title}
      uniformPadding={uniformPadding}
    />
  );
};

DetailDeliveryCard.propTypes = {
  deliveryFormat: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  displayExtended: PropTypes.bool,
  text: PropTypes.string,
  inlineLink: PropTypes.object,
  deliveryIconProps: PropTypes.object,
  uniformPadding: PropTypes.bool,
  orderedList: PropTypes.array,
};

export default DetailDeliveryCard;
