import { ApplePay, create as applePayCreate } from 'braintree-web/apple-pay';
import { create as braintreeClientCreate } from 'braintree-web/client';
import { AnyAction } from 'redux';

import config from 'config/config';
import { USER_PURCHASE_ACTION_TYPES } from 'store/modules/userPurchases/userPurchases.actionsTypes';

interface PromiseAction<R> {
  types: string[];
  promise: () => Promise<R>;
}

interface ApplePayState {
  statusChecked: boolean;
  available: boolean;
  isCapable: boolean;
  applePayInstance?: ApplePay;
}

export const applePayInitialState: ApplePayState = {
  statusChecked: false,
  available: false,
  isCapable: false,
  applePayInstance: undefined,
};

export default function applePayReducer(
  state: ApplePayState = applePayInitialState,
  action?: AnyAction
): ApplePayState {
  switch (action?.type) {
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_SUCCESS:
      return {
        ...state,
        statusChecked: true,
        ...action.result,
      };
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_FAIL:
      return {
        ...state,
        statusChecked: true,
      };
    default:
      return state;
  }
}

export function initializeApplePay() {
  return (
    dispatch: (
      action: PromiseAction<unknown>
    ) => Promise<Partial<ApplePayState>>
  ) => {
    const braintree = () =>
      new Promise<Partial<ApplePayState>>((resolve, reject) => {
        if (typeof window === 'undefined' || !window.ApplePaySession) {
          reject(new Error('Unable to create Apple Pay session.'));
          return;
        }

        if (!window.ApplePaySession.canMakePayments()) {
          reject(new Error('Apple Pay not supported.'));
          return;
        }

        braintreeClientCreate(
          {
            authorization: config.BRAINTREE_CLIENT_TOKEN,
          },
          (clientErr, clientInstance) => {
            if (clientErr) {
              console.error('Error creating client:', clientErr);
              reject(clientErr);
              return;
            }

            applePayCreate(
              {
                client: clientInstance,
              },
              (applePayErr, applePayInstance) => {
                if (applePayErr || !applePayInstance?.merchantIdentifier) {
                  console.error(
                    'Error creating applePayInstance:',
                    applePayErr
                  );
                  reject(applePayErr);
                  return;
                }

                window.ApplePaySession?.canMakePaymentsWithActiveCard(
                  applePayInstance.merchantIdentifier
                ).then((canMakePaymentsWithActiveCard) => {
                  if (canMakePaymentsWithActiveCard) {
                    resolve({
                      available: true,
                      isCapable: true,
                      applePayInstance,
                    });
                    return;
                  }

                  // User has Apple Pay capability on the device, but not activated (i.e. no saved cards)
                  resolve({ isCapable: true });
                });
              }
            );
          }
        );
      });

    return dispatch({
      types: [
        USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY,
        USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_SUCCESS,
        USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_FAIL,
      ],
      promise: braintree,
    });
  };
}
