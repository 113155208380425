export const DEEPLINK_ACTIONS = {
  DOWNLOAD: 'download',
  OPEN_SMS: 'open_sms',
  SEND_SMS: 'send_sms',
  DISMISS: 'dismiss',
};

export const DEEPLINK_CAMPAIGNS = {
  HEADER: 'header',
  HOME_PAGE: 'homepage',
  METRO_PAGE: 'metro_small_banner',
  PERFORMER_PAGE: 'performer_small_banner',
  POST_PURCHASE: 'post_purchase',
  ORDER_CONFIRMATION: 'order_confirmation',
  WHY_GAMETIME: 'why_gametime',
};
