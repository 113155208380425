import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import CloseLineIcon from 'icons/CloseLineIcon';
import colors from 'styles/colors.constants';

import styles from './GTAnimationModal.module.scss';

interface GTAnimationModalProps {
  children: React.ReactNode;
  className?: string;
  onHide?: React.MouseEventHandler<Element>;
  show: boolean;
  headerTitle?: React.ReactNode;
  closeIconColor?: string;
  headerTheme?: 'light' | 'dark';
}

export default function GTAnimationModal({
  children,
  className,
  onHide,
  show,
  headerTitle,
  headerTheme,
  closeIconColor = colors.gray400,
}: GTAnimationModalProps) {
  return (
    <Modal
      className={classNames(styles['gt-modal'], className && styles[className])}
      overlayClassName={styles['gt-modal-overlay']}
      bodyOpenClassName={styles['gt-modal-body-open']}
      isOpen={show}
      onRequestClose={onHide}
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      {headerTitle && (
        <div
          className={classNames(
            styles['modal-header'],
            headerTheme && styles[`theme-${headerTheme}`]
          )}
        >
          <h2 className={styles.title}>{headerTitle}</h2>
          {onHide && (
            <button type="button" className={styles.close} onClick={onHide}>
              <CloseLineIcon fill={closeIconColor} />
            </button>
          )}
        </div>
      )}
      {children}
    </Modal>
  );
}
