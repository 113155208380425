export const framer = {
  transition: {
    duration: 0.2,
    ease: 'easeInOut',
  },
  initial: {
    left: {
      x: 500,
    },
    right: {
      x: -500,
    },
  },
  exit: {
    left: {
      x: 500,
    },
    right: {
      x: -500,
    },
  },
  animate: {
    x: 0,
  },
};
