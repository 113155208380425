import React from 'react';
import PropTypes from 'prop-types';

const LockIcon = ({ fill = '#000000', opacity = 0.8, size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M6.66675 5.16667C6.66675 3.69391 7.86066 2.5 9.33342 2.5H10.6667C12.1395 2.5 13.3334 3.69391 13.3334 5.16667V8.33333H6.66675V5.16667Z"
        stroke={fill}
        strokeWidth="1.66667"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83333 8C4.3731 8 4 8.3731 4 8.83333V17.1667C4 17.6269 4.3731 18 4.83333 18H15.1667C15.6269 18 16 17.6269 16 17.1667V8.83333C16 8.3731 15.6269 8 15.1667 8H4.83333ZM9.83333 11C9.3731 11 9 11.3731 9 11.8333V13.1667C9 13.6269 9.3731 14 9.83333 14H10.1667C10.6269 14 11 13.6269 11 13.1667V11.8333C11 11.3731 10.6269 11 10.1667 11H9.83333Z"
        fill={fill}
      />
    </g>
  </svg>
);

LockIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  opacity: PropTypes.number,
};

export default LockIcon;
