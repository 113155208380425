import React from 'react';
import PropTypes from 'prop-types';

import { BTN_TYPES, Click, DeepLinkClickTracker, PAYLOAD } from 'analytics';
import ButtonLoader from 'components/ButtonLoader/ButtonLoader';
import DeepLink from 'components/DeepLink/DeepLink';
import {
  DEEPLINK_ACTIONS,
  DEEPLINK_CAMPAIGNS,
} from 'components/DeepLink/DeepLink.constants';
import { device, useMediaQuery } from 'hooks/useMediaQuery';

import styles from './GetAppPromo.module.scss';

const GET_APP_CTA = 'download the app';

const GetAppPromo = ({ title, text }) => {
  const isMobile = useMediaQuery(device.down.md);

  return (
    <div className={styles['get-app-container']}>
      <div className={styles['get-app-card']}>
        <h4 className={styles['get-app-card-title']}>{title}</h4>
        <p className={styles['get-app-card-text']}>{text}</p>
        <div className={styles['get-app-card-deeplink']}>
          <DeepLink
            campaign={DEEPLINK_CAMPAIGNS.ORDER_CONFIRMATION}
            clickTracker={new DeepLinkClickTracker(isMobile).interaction(
              Click.INTERACTIONS.BUTTON(),
              {
                [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
                [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.DOWNLOAD,
              }
            )}
            fireFacebookEvent
          >
            <ButtonLoader
              className={styles['get-app-card-button']}
              text={GET_APP_CTA}
            />
          </DeepLink>
        </div>
        <span className={styles['get-app-available']}>
          available on ios & android
        </span>
      </div>
    </div>
  );
};

GetAppPromo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default GetAppPromo;
