import React from 'react';
import PropTypes from 'prop-types';

import BraintreeHostedFieldCVVForm from 'components/BraintreeHostedFieldForm/BraintreeHostedFieldCVVForm';
import GTAnimationModal from 'components/GTAnimationModal';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import colors from 'styles/colors.constants';

import styles from './VerifyCVVModal.module.scss';

const hostedFieldCVVFormId = 'hosted-field-cvv-form';

function VerifyCVVModal({ isOpen, onClose, card, onSubmitVerifyCVVForm }) {
  return (
    <GTAnimationModal
      headerTheme="dark"
      className="verify-cvv-modal"
      show={isOpen}
      onHide={onClose}
      headerTitle="Confirm Your CVV"
    >
      <div className={styles['modal-body']}>
        <div className={`${styles.panel} ${styles['with-divider']}`}>
          <span className={styles.message}>
            It looks like you&apos;re on a new device. Please confirm your CVV
            to complete your purchase.
          </span>
        </div>
        <div className={styles.panel}>
          <BraintreeHostedFieldCVVForm
            card={card}
            onSubmit={onSubmitVerifyCVVForm}
            formId={hostedFieldCVVFormId}
          />
        </div>
        <div className={styles.panel}>
          <ThemedButtonLoader
            backgroundColor={colors.gametimeGreen}
            color={colors.white}
            type="submit"
            form={hostedFieldCVVFormId}
          >
            Confirm
          </ThemedButtonLoader>
        </div>
      </div>
    </GTAnimationModal>
  );
}
VerifyCVVModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  onSubmitVerifyCVVForm: PropTypes.func.isRequired,
};

export default VerifyCVVModal;
