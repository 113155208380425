import React from 'react';

import GTAnimationModal from 'components/GTAnimationModal/GTAnimationModal';
import { CreditCardBackFillIcon } from 'icons';

import AddCardForm from './AddCardForm';

import styles from './AddCardModal.module.scss';

interface AddCardModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: React.ComponentProps<typeof AddCardForm>['onSubmit'];
  title: string;
}

export function getAddCardTitle(hasExistingCards: boolean) {
  if (hasExistingCards) {
    return 'Add New Card';
  } else {
    return 'Pay with Credit Card';
  }
}

export default function AddCardModal({
  isOpen,
  onClose,
  onSubmit,
  title,
}: AddCardModalProps) {
  return (
    <GTAnimationModal
      headerTheme="dark"
      className="add-card-modal"
      show={isOpen}
      onHide={onClose}
      headerTitle={
        <span className={styles['modal-header-title']}>
          <CreditCardBackFillIcon />
          {title}
        </span>
      }
    >
      <AddCardForm onSubmit={onSubmit} />
    </GTAnimationModal>
  );
}
