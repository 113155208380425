import React, { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import { motion, useMotionValue } from 'framer-motion';
import PropTypes from 'prop-types';

import placeHolderImage from 'assets/image_placeholder.svg';
import {
  generateFastlyImagePathname,
  RESPONSIVE_IMAGE_PARAMS,
} from 'helpers/image';
import useLoadImage from 'hooks/useLoadImage';
import useResize from 'hooks/useResize';

import styles from './ImagePan.module.scss';

const propTypes = {
  imageProps: PropTypes.object,
};

const ImagePan = ({ imageProps }) => {
  const { src, alt, seoPostfix, seoPrefix } = imageProps;
  const params = {
    ...RESPONSIVE_IMAGE_PARAMS,
    quality: '70,50',
    crop: '3:1',
    dpr: 2,
  };
  const panUrl = generateFastlyImagePathname(
    src,
    params,
    seoPostfix,
    seoPrefix
  );
  const initialBounds = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
  const [bounds, setBounds] = useState(initialBounds);
  const [interacted, setInteracted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const [panMeasureRef, { width: panWidth = 0, height: panHeight = 0 }] =
    useMeasure();

  const [imgMeasureRef, { width: intrinsicImageWidth = 0 }] = useMeasure();

  const {
    loaded,
    failed,
    dimensions: { width: imageWidth = 0, height: imageHeight = 0 },
  } = useLoadImage(panUrl);

  const x = useMotionValue(0); // Initial x position

  // stop auto panning on resize
  useResize(() => {
    setDisabled(true);
    setInteracted(true);
  });

  const imagePanClassnames = classNames(styles['pan-drag'], {
    [styles['image-loaded']]: loaded,
    [styles['image-failed']]: failed,
  });

  // set and use panning boundaries
  useEffect(() => {
    // subtract 1 to avoid subpixel rendering
    const LRbound = Math.floor((intrinsicImageWidth - panWidth) / 2) - 1;
    const ret = {
      left: -LRbound,
      right: LRbound,
      top: 0,
      bottom: 0,
    };
    setBounds(ret);
  }, [intrinsicImageWidth, imageWidth, panWidth, x, loaded]);

  // disable panning functionality when image is too small for its frame
  useEffect(() => {
    const ready = loaded && panWidth > 0 && imageWidth > 0;
    if (!ready) return;
    const imageAspectRatio = imageWidth / imageHeight;
    const panAspectRatio = panWidth / panHeight;
    const disablePanning = ready && imageAspectRatio <= panAspectRatio;
    if (disablePanning) {
      setDisabled(disablePanning);
    }
  }, [loaded, panWidth, panHeight, imageWidth, imageHeight]);

  // placeholder image source
  useEffect(() => {
    if (failed) setImgSrc(placeHolderImage);
  }, [failed]);

  // success image source
  useEffect(() => {
    if (loaded) setImgSrc(panUrl);
  }, [loaded, panUrl]);

  const recenterImage = () => {
    if (!interacted) {
      setInteracted(true);
    } else {
      x.set(0);
    }
  };

  return (
    <div ref={panMeasureRef} className={styles['image-pan']}>
      <motion.div
        className={imagePanClassnames}
        style={{ x }}
        drag="x"
        initial={{ x: 0 }}
        dragConstraints={{ left: bounds.left, right: bounds.right }}
        dragElastic={0.5}
        dragMomentum={false}
        animate={
          disabled
            ? null
            : {
                x: [0, bounds.right, bounds.left, 0],
              }
        }
        transition={{
          duration: 35,
          ease: disabled ? null : ['easeInOut', 'easeInOut', 'easeInOut'],
          repeat: 0,
        }}
      >
        <img
          ref={imgMeasureRef}
          src={imgSrc}
          alt={alt}
          className={styles.image}
          draggable="false"
          onClick={recenterImage}
        />
      </motion.div>
    </div>
  );
};

ImagePan.propTypes = propTypes;

export default ImagePan;
