/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import AddressAutocompleteInput from 'components/Inputs/AddressAutocompleteInput';
import TextInput from 'components/Inputs/TextInput';
import ApartmentFillIcon from 'icons/ApartmentFillIcon';
import ProfileFillIcon from 'icons/ProfileFillIcon';
import colors from 'styles/colors.constants';

const UserAddressFormValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required.'),
  address: Yup.string().trim().required('Please enter a valid address.'),
  address2: Yup.string().trim(),
});

const UserAddressForm = ({
  handleUserAddressFormSubmit,
  mpActions,
  ctaText = 'CONFIRM',
  clickTracker,
}) => {
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      await handleUserAddressFormSubmit(values);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ name: '', address: '', address2: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserAddressFormValidationSchema}
    >
      {(props) => {
        const {
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <TextInput
              id="user-name"
              name="name"
              label="Name"
              placeholder="Name"
              startIcon={<ProfileFillIcon fill={colors.gray200} />}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.name && touched.name,
                message: errors.name,
              }}
              required
            />
            <AddressAutocompleteInput
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.address && touched.address,
                message: errors.address,
              }}
              value={values.address}
            />
            <TextInput
              id="user-address2"
              name="address2"
              label="Address Line 2"
              placeholder="Unit Number (i.e. Unit 525)"
              startIcon={<ApartmentFillIcon fill={colors.gray200} />}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            <DarkThemedFormButtonLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              clickTracker={clickTracker}
              mpActions={mpActions}
              showBackground
            />
          </Form>
        );
      }}
    </Formik>
  );
};

UserAddressForm.propTypes = {
  handleUserAddressFormSubmit: PropTypes.func.isRequired,
  mpActions: PropTypes.object,
  ctaText: PropTypes.string,
  clickTracker: PropTypes.object,
};

export default UserAddressForm;
