import React from 'react';
import classNames from 'classnames';

import TicketPrice from 'components/TicketPrice/TicketPrice';
import {
  BookingStatus,
  getInsuranceStatus,
} from 'pages/Checkout/components/InsuranceOptions/InsuranceOptions.utils';

import styles from './DetailPriceSummaryCard.module.scss';

interface DetailPriceSummaryCardProps {
  seatFee: number;
  prefeeSeatPrice: number;
  salesTax: number;
  totalPrice: number;
  seatCount: number;
  currencyPrefix?: string;
  promoAmount?: number;
  isCheckout?: boolean;
  insurancePrice?: number;
  insuranceBookingStatus?: BookingStatus;
}

const DetailPriceSummaryCard = ({
  seatFee,
  prefeeSeatPrice,
  salesTax,
  totalPrice,
  seatCount,
  currencyPrefix,
  promoAmount = 0,
  isCheckout = false,
  insurancePrice,
  insuranceBookingStatus,
}: DetailPriceSummaryCardProps) => {
  const insuranceStatus = getInsuranceStatus(insuranceBookingStatus);

  return (
    <div
      className={classNames(styles['detail-price-summary'], {
        [styles['is-checkout']]: isCheckout,
      })}
    >
      <div className={styles['detail-breakdown']}>
        <div className={styles['detail-price-type']}>Tickets</div>
        <div className={styles['detail-price-calc']}>
          {seatCount}
          <span className={styles['multiply-symbol']}>&#x2715;</span>
          <TicketPrice
            currencyPrefix={currencyPrefix}
            price={prefeeSeatPrice}
          />
        </div>
      </div>
      <div className={styles['detail-breakdown']}>
        <div className={styles['detail-price-type']}>Fees</div>
        <div className={styles['detail-price-calc']}>
          {seatCount}
          <span className={styles['multiply-symbol']}>&#x2715;</span>
          <TicketPrice currencyPrefix={currencyPrefix} price={seatFee} />
        </div>
      </div>
      {salesTax > 0 && (
        <div className={styles['detail-breakdown']}>
          <div className={styles['detail-price-type']}>Sales Tax</div>
          <div className={styles['detail-price-calc']}>
            <TicketPrice
              currencyPrefix={currencyPrefix}
              price={salesTax * seatCount}
            />
          </div>
        </div>
      )}
      {promoAmount > 0 && (
        <div className={styles['detail-breakdown']}>
          <div
            className={classNames(
              styles['detail-price-type'],
              styles['detail-promo']
            )}
          >
            Promo Code
          </div>
          <div
            className={classNames(
              styles['detail-price-calc'],
              styles['detail-promo']
            )}
          >
            {`- ${currencyPrefix}${promoAmount}`}
          </div>
        </div>
      )}
      <div className={styles['totals']}>
        <div className={styles['total-price']}>
          <span>Total</span>
          <TicketPrice currencyPrefix={currencyPrefix} price={totalPrice} />
        </div>
        {typeof insurancePrice === 'number' && (
          <>
            <hr className={styles['divider']} />
            <div className={styles['total-price']}>
              <span>XCover</span>
              <div className={styles['insurance-price-container']}>
                {insuranceStatus?.label && (
                  <span
                    className={classNames(
                      styles['insurance-status'],
                      styles[insuranceStatus.label]
                    )}
                  >
                    {insuranceStatus.label}
                  </span>
                )}
                <TicketPrice
                  currencyPrefix={currencyPrefix}
                  price={insurancePrice}
                  isPriceCrossedOut={insuranceStatus?.label === 'cancelled'}
                />
              </div>
            </div>
            {insuranceStatus?.message && (
              <div className={styles['insurance-helper-text-wrapper']}>
                <span className={styles['insurance-helper-text']}>
                  {insuranceStatus.message}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DetailPriceSummaryCard;
