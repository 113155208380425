import React from 'react';
import { useOutletContext } from 'react-router-dom';

function withOutletContext<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return (props: P) => {
    const context = useOutletContext<Partial<P>>() ?? {};
    return <WrappedComponent {...props} {...context} />;
  };
}

export default withOutletContext;
