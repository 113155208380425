import { GetListingsParams } from 'services/listings/listings.service';
import {
  ListingDisplayGroup as ListingDisplayGroup_UNCHECKED,
  ListingV2,
  RequestStatus,
} from 'types';

import type { ListingsAction } from './actions';

// we could set the TSConfig noUncheckedIndexedAccess rule, but it will require
// many changes in the codebase, so we are using Partial here to avoid errors
type ListingDisplayGroup = Omit<ListingDisplayGroup_UNCHECKED, 'listings'> & {
  listings: Partial<ListingDisplayGroup_UNCHECKED['listings']>;
};

export interface ListingsState {
  status: RequestStatus;
  params: Partial<GetListingsParams>;
  listings: Partial<Record<string, ListingV2>>;
  displayGroups: ListingDisplayGroup[];
  availableLots: number[];
  error?: unknown;
}

export const initialState: ListingsState = {
  params: {},
  listings: {},
  availableLots: [],
  displayGroups: [],
  status: 'idle',
  error: undefined,
};

export default function listingsReducer(
  state: ListingsState = initialState,
  action: ListingsAction
): ListingsState {
  switch (action.type) {
    case 'Listings/FetchListingsRequest': {
      return {
        ...state,
        params: action.params,
        status: 'loading',
        error: undefined,
      };
    }

    case 'Listings/FetchListingsSuccess': {
      return {
        ...state,
        listings: action.payload.listings,
        displayGroups: action.payload.display_groups,
        availableLots: action.payload.available_lots,
        status: 'success',
      };
    }

    case 'Listings/FetchListingsFailure': {
      return {
        ...initialState,
        status: 'failure',
        error: action.payload,
      };
    }

    case 'Listings/UpdateListingsParams': {
      return {
        ...state,
        params: action.params,
      };
    }

    case 'Listings/UpdateListingsQuantity': {
      return {
        ...state,
        params: {
          ...state.params,
          quantity: action.quantity,
        },
      };
    }

    default: {
      return state;
    }
  }
}
