import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';
import IconButton from 'ui/IconButton';

import {
  Click,
  ClickTracker,
  HomeClickTracker,
  PAYLOAD,
  TRACK,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import ButtonLoader from 'components/ButtonLoader/ButtonLoader';
import CategoriesButtonList from 'components/Headers/CategoriesButtonList/CategoriesButtonList';
import HeaderDropdown, {
  HEADER_DROPDOWNS,
} from 'components/Headers/HeaderDropdown/HeaderDropdown';
import Link from 'components/Link/Link';
import Riskified from 'components/Riskified/Riskified';
import SearchBox from 'components/Search/SearchBox/SearchBox';
import {
  CaretDownIcon,
  CaretUpIcon,
  CloseLineIcon,
  GTShield,
  HamburgerIcon,
  ProfileFillIcon,
  SearchIcon,
  TicketSetIcon,
} from 'icons';
import { LogoColor } from 'logos';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import {
  mobileHeroSearchBoxSelector,
  showMobileHeroSearchBox,
} from 'store/modules/app/app.ui';
import {
  fetchAllPerformers,
  fetchLocalSportsTeams,
  fetchTrendingPerformersByCategoryGroup,
} from 'store/modules/data/Performers/actions';
import { MODALS, showModal } from 'store/modules/modals/modals';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import colors from 'styles/colors.constants';

import styles from './MinimalHeader.module.scss';

const HEADER_MODES = {
  DEFAULT: 'default',
  SEARCH: 'search',
};
@withAppContext
@connect(
  (state) => ({
    user: selectUserDetails(state),
    webSessionId: appConfigSelector(state).webSessionId,
    currentMetro: selectUserMetro(state) || selectClosestMetro(state),
    mobileHeroSearchBoxActive: mobileHeroSearchBoxSelector(state),
  }),
  {
    showModal,
    fetchAllPerformers,
    fetchLocalSportsTeams,
    fetchTrendingPerformersByCategoryGroup,
    showMobileHeroSearchBox,
  }
)
@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.HEADER(),
}))
@withAnalyticsContext
export default class MinimalHeader extends PureComponent {
  static propTypes = {
    hideMobileHeader: PropTypes.bool,
    search: PropTypes.bool,
    showCategories: PropTypes.bool,
    showHamburger: PropTypes.bool,
    showAccount: PropTypes.bool,
    className: PropTypes.string,
    onSearchResultsAvailabilityChange: PropTypes.func,
    user: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    fetchAllPerformers: PropTypes.func.isRequired,
    handleGTShieldClick: PropTypes.func,
    initialSearchSessionID: PropTypes.string,
    initialKeystrokeSequence: PropTypes.number,
    initialApiSequence: PropTypes.number,
    showGTShield: PropTypes.bool,
    currentMetro: PropTypes.object,
    fetchLocalSportsTeams: PropTypes.func.isRequired,
    webSessionId: PropTypes.string,
    isHomepage: PropTypes.bool,
    hiddenByScroll: PropTypes.bool,
    fetchTrendingPerformersByCategoryGroup: PropTypes.func.isRequired,
    mobileHeroSearchBoxActive: PropTypes.bool,
    showMobileHeroSearchBox: PropTypes.func,
    isMetroPage: PropTypes.bool,
    onHeaderInteraction: PropTypes.func,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onDropdownOpen = this.onDropdownOpen.bind(this);
    this.onDropdownClose = this.onDropdownClose.bind(this);
    this.onDropdownToggle = this.onDropdownToggle.bind(this);
    this.exitMobileSearch = this.exitMobileSearch.bind(this);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleSearchResultClick = this.handleSearchResultClick.bind(this);
    this.onMobileSearchSessionIDChange =
      this.onMobileSearchSessionIDChange.bind(this);
    this.onMobileSearchTextChange = this.onMobileSearchTextChange.bind(this);

    this.state = {
      isDropdownOpen: false,
      dropdownType: null,
      mode: HEADER_MODES.DEFAULT,
      searchFocused: false,
      mobileSearchText: '',
      mobileSearchSessionID: props.initialSearchSessionID,
    };
  }

  componentDidMount() {
    if (this.props.showCategories) {
      this.props.fetchAllPerformers(this.props.currentMetro);
    }

    if (this.props.currentMetro) {
      this.props.fetchTrendingPerformersByCategoryGroup(
        this.props.currentMetro.id
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mobileHeroSearchBoxActive) {
      this.handleSearchButtonClick();
      this.props.showMobileHeroSearchBox();
    }

    if (
      this.props.onHeaderInteraction &&
      this.props.clickContext === prevProps.clickContext
    ) {
      this.props.onHeaderInteraction();
    }
  }

  onDropdownOpen(dropdownType) {
    this.setState({
      isDropdownOpen: true,
      dropdownType,
    });
  }

  onDropdownClose() {
    this.setState({
      isDropdownOpen: false,
      dropdownType: null,
    });
  }

  onDropdownToggle(type) {
    const { isDropdownOpen, dropdownType } = this.state;

    if (isDropdownOpen && type === dropdownType) {
      this.onDropdownClose();
    } else {
      this.onDropdownOpen(type);
    }
  }

  onMobileSearchSessionIDChange(searchSessionID) {
    this.setState({ mobileSearchSessionID: searchSessionID });
  }

  onMobileSearchTextChange(searchText) {
    this.setState({ mobileSearchText: searchText });
  }

  handleSearchButtonClick() {
    const {
      onSearchResultsAvailabilityChange,
      analyticsContext,
      clickContext,
    } = this.props;

    const tracker = new ClickTracker().interaction(
      Click.INTERACTIONS.SEARCH_BAR()
    );
    tracker.sourcePageType({
      source_page_type: clickContext.sourcePageType
        ? clickContext.sourcePageType.source_page_type
        : Click.SOURCE_PAGE_TYPES.UNKNOWN().source_page_type,
    });
    analyticsContext.track(new Click(tracker.json()));

    this.setState({
      mode: HEADER_MODES.SEARCH,
      isDropdownOpen: false,
      type: null,
    });
    onSearchResultsAvailabilityChange(true);
    // TODO for desktop version-window.innerWidth > 575
  }

  exitMobileSearch() {
    const { mobileSearchSessionID, mobileSearchText } = this.state;
    const {
      onSearchResultsAvailabilityChange,
      analyticsContext,
      clickContext,
    } = this.props;

    const tracker = new ClickTracker().interaction(Click.INTERACTIONS.CANCEL());
    tracker.payload({
      [PAYLOAD.UUID]: mobileSearchSessionID,
      [PAYLOAD.SEARCH_TERM]: mobileSearchText,
    });
    tracker.sourcePageType(Click.SOURCE_PAGE_TYPES.SEARCH());
    tracker.targetPageType({
      target_page_type: clickContext.sourcePageType
        ? clickContext.sourcePageType.source_page_type
        : Click.TARGET_PAGE_TYPES.UNKNOWN().target_page_type,
    });
    analyticsContext.track(new Click(tracker.json()));

    this.setState({ mode: HEADER_MODES.DEFAULT });
    onSearchResultsAvailabilityChange(false);
  }

  handleSearchFocus() {
    this.setState({ searchFocused: true });
    this.onDropdownClose();
  }

  handleSearchBlur(hasSearchText = false) {
    this.setState({ searchFocused: hasSearchText });
  }

  handleLoginClick() {
    this.props.showModal(MODALS.LOGIN);
  }

  handleSearchResultClick() {
    this.setState({ mode: HEADER_MODES.DEFAULT });
    this.props.onSearchResultsAvailabilityChange(false);
  }

  renderMobileSearch() {
    const {
      initialSearchSessionID,
      initialKeystrokeSequence,
      initialApiSequence,
    } = this.props;

    return (
      <div
        className={classNames(styles['header-section'], styles['search-mode'])}
      >
        <div className={styles['header-left']}>
          <div className={styles['search-box-mobile']}>
            <SearchBox
              autoFocus
              initialSearchSessionID={initialSearchSessionID}
              initialKeystrokeSequence={initialKeystrokeSequence}
              initialApiSequence={initialApiSequence}
              onSearchSessionIDChange={this.onMobileSearchSessionIDChange}
              onSearchTextChange={this.onMobileSearchTextChange}
              onResultClick={this.handleSearchResultClick}
              searchSessionID={this.state.mobileSearchSessionID}
            />
          </div>
        </div>
        <div className={styles['header-right']}>
          <Link
            className={styles['close-link']}
            onClick={this.exitMobileSearch}
          >
            CLOSE
          </Link>
        </div>
      </div>
    );
  }

  renderSearch() {
    const { searchFocused, mobileSearchSessionID } = this.state;
    const {
      initialSearchSessionID,
      initialKeystrokeSequence,
      initialApiSequence,
      search: searchAvailable,
      handleGTShieldClick,
      appContext: {
        state: { isMobile },
      },
      showGTShield,
      isHomepage,
      isMetroPage,
    } = this.props;

    if (!searchAvailable || (!isMobile && (isHomepage || isMetroPage))) {
      return;
    }

    if (isMobile) {
      return (
        <>
          {showGTShield && (
            <IconButton
              className={styles['minimal-header-button']}
              onClick={handleGTShieldClick}
              icon={<GTShield />}
            />
          )}
          <IconButton
            className={styles['minimal-header-button']}
            onClick={this.handleSearchButtonClick}
            icon={<SearchIcon />}
          />
        </>
      );
    }

    return (
      <div
        className={classNames(styles['search-box-desktop'], {
          [styles.focus]: searchFocused,
        })}
      >
        <SearchBox
          initialSearchSessionID={initialSearchSessionID}
          initialKeystrokeSequence={initialKeystrokeSequence}
          initialApiSequence={initialApiSequence}
          onFocus={this.handleSearchFocus}
          onBlur={this.handleSearchBlur}
          onResultClick={this.handleSearchResultClick}
          isHomepage={isHomepage}
          isFocused={searchFocused}
          searchSessionID={mobileSearchSessionID}
          onSearchSessionIDChange={this.onMobileSearchSessionIDChange}
        />
      </div>
    );
  }

  renderAccount() {
    const { user, isHomepage, isMetroPage } = this.props;

    const showingSearchHero = isHomepage || isMetroPage;

    const renderAuthTab = () => (
      <>
        {!showingSearchHero && <div className={styles['account-separator']} />}
        <ButtonLoader
          className={styles['account-container']}
          onClick={this.handleLoginClick}
        >
          <ProfileFillIcon />
          <div className={styles['account-text']}>Log In</div>
        </ButtonLoader>
      </>
    );

    const renderTicketsItem = () => (
      <>
        {!showingSearchHero && <div className={styles['account-separator']} />}
        <ButtonLoader
          className={styles['menu-button']}
          onClick={() => this.onDropdownToggle(HEADER_DROPDOWNS.MENU)}
        >
          <div className={styles.content}>
            <TicketSetIcon fill={colors.white} />
            <div className={styles['label-group']}>
              <span className={styles.label}>My Tickets</span>
              {this.state.isDropdownOpen ? (
                <CaretUpIcon fill={colors.white} />
              ) : (
                <CaretDownIcon fill={colors.white} />
              )}
            </div>
          </div>
        </ButtonLoader>
      </>
    );
    return !user ? renderAuthTab() : renderTicketsItem();
  }

  renderHamburger() {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    if (!isMobile || !this.props.showHamburger) {
      return null;
    }

    return (
      <IconButton
        className={styles['minimal-header-button']}
        onClick={() => {
          this.onDropdownToggle(HEADER_DROPDOWNS.MOBILE);
        }}
        icon={
          this.state.dropdownType === HEADER_DROPDOWNS.MOBILE ? (
            <CloseLineIcon />
          ) : (
            <HamburgerIcon />
          )
        }
      />
    );
  }

  renderFullHeader() {
    const {
      appContext: {
        state: { isMobile },
      },
      showCategories,
      search: searchAvailable,
      isHomepage,
      isMetroPage,
      showAccount,
    } = this.props;
    const { dropdownType } = this.state;

    const showingSearchHero = isHomepage || isMetroPage;
    const showSearch = searchAvailable || (!isMobile && !showingSearchHero);

    return (
      <div
        className={classNames(styles['header-section'], {
          [styles.mobile]: isMobile,
        })}
      >
        <div className={styles['header-left']}>
          <Link
            to="/"
            className={styles.home}
            clickTracker={new HomeClickTracker()}
            onClick={this.onDropdownClose}
          >
            <LogoColor className={styles.logo} />
          </Link>
          {!isMobile && showCategories && (
            <div className={styles['category-selectors-container']}>
              <CategoriesButtonList
                selected={dropdownType}
                onClick={this.onDropdownToggle}
              />
            </div>
          )}
        </div>
        <div className={styles['header-right']}>
          {showSearch && this.renderSearch()}
          {showAccount && !isMobile && this.renderAccount()}
          {this.renderHamburger()}
        </div>
      </div>
    );
  }

  render() {
    const {
      className,
      hideMobileHeader,
      currentMetro,
      webSessionId,
      hiddenByScroll,
      showCategories,
    } = this.props;
    const { mode, dropdownType } = this.state;

    return (
      <header
        className={classNames(
          styles['search-light'],
          {
            [styles['minimal-header']]: true,
            [styles['hide-mobile']]: hideMobileHeader,
            [styles['hidden-by-scroll']]: hiddenByScroll,
          },
          className
        )}
      >
        <div className={styles['header-container']}>
          {mode === HEADER_MODES.DEFAULT
            ? this.renderFullHeader()
            : this.renderMobileSearch()}
        </div>
        {showCategories && (
          <HeaderDropdown
            type={dropdownType}
            onClose={this.onDropdownClose}
            onCategoryClick={this.onDropdownToggle}
            currentMetro={currentMetro}
            fetchLocalSportsTeams={this.props.fetchLocalSportsTeams}
          />
        )}
        <Riskified webSessionId={webSessionId} />
      </header>
    );
  }
}
