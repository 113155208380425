import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Click, FullEventClickTracker, PAYLOAD } from 'analytics';
import EventContainer from 'components/Event/EventContainer';
import EventsSectionHeader from 'components/FullEventPagination/EventsSectionHeader';
import Section from 'components/Section/Section';
import Spinner from 'components/Spinner/Spinner';
import { Performer } from 'models';
import { fetchParkingFullEvents as fetchFullEventsDispatch } from 'store/modules/data/FullEvents/actions';
import { selectParkingFullEvents } from 'store/modules/data/FullEvents/selectors';

import styles from './ParkingEventsSection.module.scss';

const ParkingEventsSection = ({
  performer,
  eventIds,
  fullEvents,
  fetchFullEvents,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const isEventsEmpty = eventIds.length === 0;

  useEffect(() => {
    if (!isEventsEmpty) {
      fetchFullEvents({ id: eventIds.join(',') }).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [eventIds, fetchFullEvents, isEventsEmpty]);

  if (isLoading) {
    return (
      <div className={styles['spinner-wrapper']}>
        <Spinner />
      </div>
    );
  }

  return (
    <Section
      className={styles.section}
      grayHeadline
      showHeadline={false}
      headline=""
    >
      <EventsSectionHeader
        performer={performer}
        isEventsEmpty={isEventsEmpty}
        renderNoEvents={isEventsEmpty}
        eventType="Parking"
      />
      {!isEventsEmpty &&
        fullEvents.map((fullEvent) => {
          if (!fullEvent.isValid()) {
            return null;
          }

          const eventTracker = new FullEventClickTracker(fullEvent).interaction(
            Click.INTERACTIONS.TILE(),
            {
              [PAYLOAD.SECTION]: 'PARKING',
            }
          );

          return (
            <EventContainer
              key={`full-event-${fullEvent.id}`}
              fullEvent={fullEvent}
              clickTracker={eventTracker}
            />
          );
        })}
    </Section>
  );
};

ParkingEventsSection.propTypes = {
  performer: PropTypes.instanceOf(Performer).isRequired,
  eventIds: PropTypes.array.isRequired,
  fullEvents: PropTypes.array.isRequired,
  fetchFullEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fullEvents: selectParkingFullEvents(state),
});

const mapDispatchToProps = {
  fetchFullEvents: fetchFullEventsDispatch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingEventsSection);
