import React from 'react';
import classNames from 'classnames';

import { Review } from 'types';

import styles from './SimpleReviewCard.module.scss';

interface SimpleReviewCardProps {
  review: Review;
  page: 'homepage' | 'picks';
}

const SimpleReviewCard = ({ review, page }: SimpleReviewCardProps) => {
  const stars = '⭐'.repeat(review.stars);
  return (
    <div className={classNames(styles['review-card'], styles[page])}>
      <div className={styles['review-card-header']}>
        <span className={styles['review-card-stars']}>{stars}</span>
        <span className={styles['review-card-date']}>{review.date}</span>
      </div>
      <div className={styles['review-card-content']}>
        <span className={styles['review-card-title']}>{review.title}</span>
        <span className={styles['review-card-body']}>"{review.review}"</span>
      </div>
    </div>
  );
};

export default SimpleReviewCard;
