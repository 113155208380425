import { animate, handleCancelAnimation } from '../animations/animations.utils';
import { handleCalculateBounds } from '../bounds/bounds.utils';

import {
  getPaddingValue,
  getPanningClientPosition,
  handleNewPosition,
  handlePanningSetup,
  handlePanToBounds,
  handleTouchPanningSetup,
} from './panning.utils';
import {
  handleCalculateVelocity,
  handleVelocityPanning,
} from './velocity.logic';

export function handlePanningStart(contextInstance, event) {
  const { scale } = contextInstance.transformState;

  handleCancelAnimation(contextInstance);
  handleCalculateBounds(contextInstance, scale);
  if (event.touches) {
    handleTouchPanningSetup(contextInstance, event);
  } else {
    handlePanningSetup(contextInstance, event);
  }
}

export function handlePanning(contextInstance, clientX, clientY) {
  const { startCoords, setup } = contextInstance;
  const { sizeX, sizeY } = setup.alignmentAnimation;

  if (!startCoords) return;

  const { x, y } = getPanningClientPosition(contextInstance, clientX, clientY);
  const paddingValueX = getPaddingValue(contextInstance, sizeX);
  const paddingValueY = getPaddingValue(contextInstance, sizeY);

  handleCalculateVelocity(contextInstance, { x, y });
  handleNewPosition(contextInstance, x, y, paddingValueX, paddingValueY);
}

export function handlePanningEnd(contextInstance) {
  if (contextInstance.isPanning) {
    const { velocityDisabled } = contextInstance.setup.panning;
    const { velocity, wrapperComponent, contentComponent } = contextInstance;

    contextInstance.isPanning = false;
    contextInstance.animate = false;
    contextInstance.animation = null;

    const wrapperRect = wrapperComponent?.getBoundingClientRect();
    const contentRect = contentComponent?.getBoundingClientRect();

    const wrapperWidth = wrapperRect?.width || 0;
    const wrapperHeight = wrapperRect?.height || 0;
    const contentWidth = contentRect?.width || 0;
    const contentHeight = contentRect?.height || 0;
    const isZoomed =
      wrapperWidth < contentWidth || wrapperHeight < contentHeight;

    const shouldAnimate =
      !velocityDisabled && velocity && velocity?.total > 0.1 && isZoomed;

    if (shouldAnimate) {
      handleVelocityPanning(contextInstance);
    } else {
      handleAlignToBounds(contextInstance);
    }
  }
}

export function handleAlignToBounds(contextInstance) {
  const { scale } = contextInstance.transformState;
  const { minScale, alignmentAnimation } = contextInstance.setup;
  const { disabled, sizeX, sizeY, animationTime, animationType } =
    alignmentAnimation;

  const isDisabled = disabled || scale < minScale || (!sizeX && !sizeY);

  if (isDisabled) return;

  const targetState = handlePanToBounds(contextInstance);

  if (targetState) {
    animate(contextInstance, targetState, animationTime, animationType);
  }
}
