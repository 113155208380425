import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useBraintreeHostedFields } from 'helpers/Braintree';
import CreditCardBackFillIcon from 'icons/CreditCardBackFillIcon';
import CreditCardsBarIcon from 'icons/CreditCardsBarIcon';
import LockIcon from 'icons/LockIcon';
import colors from 'styles/colors.constants';

import {
  BraintreeHostedFieldGroup,
  hostedFieldStyles,
} from './BraintreeHostedFieldGroup';

import styles from './BraintreeHostedFieldForm.module.scss';

const FIELD_ID = {
  NUMBER: 'card-number',
  CVV: 'card-cvv',
  EXPIRATION_DATE: 'card-expiration-date',
};

const hostedFieldsOptions = {
  number: {
    selector: `#${FIELD_ID.NUMBER}`,
    placeholder: 'Card Number',
    maxCardLength: 16,
    supportedCardBrands: {
      visa: true,
      mastercard: true,
      'american-express': true,
      discover: true,
      jcb: false,
    },
  },
  cvv: {
    selector: `#${FIELD_ID.CVV}`,
    placeholder: 'CVV',
  },
  expirationDate: {
    selector: `#${FIELD_ID.EXPIRATION_DATE}`,
    placeholder: 'MM/YY',
  },
};

function BraintreeHostedFieldForm({
  formId,
  dispatch,
  onSubmit,
  isDisabled = false,
}) {
  const { createHostedFields, hostedFields, fieldErrors, fieldFocus } =
    useBraintreeHostedFields(dispatch);

  const [cardType, setCardType] = useState(null);

  useEffect(() => {
    createHostedFields(hostedFieldsOptions, hostedFieldStyles).then(
      (hostedFieldsInstance) => {
        if (!hostedFieldsInstance) {
          return;
        }

        function autoTabFieldsOnValidityChange(event) {
          const expDateAutoTabbable =
            event.fields.number.isValid &&
            event.fields.number.isFocused &&
            event.fields.expirationDate.isEmpty;
          if (expDateAutoTabbable) {
            hostedFieldsInstance.focus('expirationDate');
          }

          const cvvAutoTabbable =
            event.fields.expirationDate.isValid &&
            event.fields.expirationDate.isFocused &&
            event.fields.cvv.isEmpty;
          if (cvvAutoTabbable) {
            hostedFieldsInstance.focus('cvv');
          }
        }

        function changeCardType(event) {
          if (event.cards.length === 1) {
            setCardType(event.cards[0].type);
          } else {
            setCardType(null);
          }
        }

        hostedFieldsInstance.on(
          'validityChange',
          autoTabFieldsOnValidityChange
        );
        hostedFieldsInstance.on('cardTypeChange', changeCardType);
      }
    );
  }, [createHostedFields]);

  useEffect(() => {
    if (hostedFields) {
      const fieldKeys = Object.keys(hostedFieldsOptions);
      if (isDisabled) {
        fieldKeys.forEach((field) => {
          hostedFields.setAttribute({ field, attribute: 'disabled' });
        });
      } else {
        fieldKeys.forEach((field) => {
          hostedFields.removeAttribute({ field, attribute: 'disabled' });
        });
      }
    }
  }, [hostedFields, isDisabled]);

  const containerRef = React.useRef(null);
  const handleValidateAndSubmit = async (event) => {
    event.preventDefault();

    if (!hostedFields) {
      return;
    }

    const isFieldsValid = hostedFields.validate();

    if (isFieldsValid) {
      onSubmit(hostedFields.tokenize);
    } else {
      setImmediate(() => {
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
    }
  };

  return (
    <form
      ref={containerRef}
      id={formId}
      onSubmit={handleValidateAndSubmit}
      className={styles['form-group']}
    >
      <BraintreeHostedFieldGroup
        label="Credit Card Number"
        hint={
          <>
            <LockIcon fill={colors.gray400} size={16} />
            Secured by PayPal
          </>
        }
        fieldId={FIELD_ID.NUMBER}
        isFocused={fieldFocus.number}
        error={fieldErrors.number}
        endIcon={<CreditCardsBarIcon cardType={cardType} />}
      />
      <div className={styles.row}>
        <BraintreeHostedFieldGroup
          label="Expiration"
          fieldId={FIELD_ID.EXPIRATION_DATE}
          isFocused={fieldFocus.expirationDate}
          error={fieldErrors.expirationDate}
        />
        <BraintreeHostedFieldGroup
          label="CVV"
          fieldId={FIELD_ID.CVV}
          isFocused={fieldFocus.cvv}
          error={fieldErrors.cvv}
          endIcon={<CreditCardBackFillIcon />}
        />
      </div>
    </form>
  );
}

BraintreeHostedFieldForm.propTypes = {
  formId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default connect()(BraintreeHostedFieldForm);
