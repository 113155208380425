import React, { useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Click, ClickTracker } from 'analytics';
import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import Slider from './Slider';

import styles from './ReactSlickCarousel.module.scss';

function ReactSlickArrow({
  onClick,
  onArrowButtonClick,
  direction,
  className,
  isTopArrow = false,
  disabled = false,
  onTopArrowClick,
}) {
  // react-slick will pass the onclick if we can move left or right
  // this is how we can easily enable/disale left or right button
  if (!onClick && !isTopArrow) {
    return null;
  }

  const handleClick = (e) => {
    if (onArrowButtonClick && !isTopArrow) {
      onArrowButtonClick('arrows', direction);
    }

    onClick(e);

    if (isTopArrow && onTopArrowClick) {
      onTopArrowClick();
    }
  };

  const chevronColor = isTopArrow
    ? disabled
      ? colors.gray500
      : colors.white
    : colors.gray800;

  return (
    <button
      type="button"
      onClick={handleClick}
      className={classNames(className, styles[`arrow-${direction}`], {
        [styles['arrow-disabled']]: disabled,
      })}
    >
      <ChevronIcon
        width={16}
        height={16}
        direction={direction}
        strokeWidth={1.5}
        color={chevronColor}
      />
    </button>
  );
}
ReactSlickArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  onArrowButtonClick: PropTypes.func,
  isTopArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  onTopArrowClick: PropTypes.func,
};

export default function ReactSlickCarousel({
  // analytics:
  title,
  analytics,
  clickContext,
  // slider options:
  arrows = true,
  dots = false,
  centerMode = false,
  infinite = false,
  initialSlide = 0,
  speed = 500,
  // slide sizing:
  spacing = 0,
  slideWidth,
  // header renderer
  renderHeader, // (slidesToShow: number) => ReactElement
  // slide items:
  items = [], // Array<Item>
  renderItem, // (item: Item, index: number, goTo: function) => ReactElement
  getItemKey = (item) => item.id, // (item: Item) => string | number
  topArrows = false,
  onTopForwardClick,
  onTopBackClick,
  className,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const trackingHandlers = useMemo(() => {
    if (!(analytics && clickContext && title)) {
      return {};
    }

    const track = (buttonType, direction) => {
      const tracker = new ClickTracker().interaction(
        Click.INTERACTIONS.CAROUSEL,
        {
          buttonType: buttonType,
          collection: title,
          direction,
        }
      );

      analytics.track(
        new Click({
          ...clickContext,
          ...tracker.json(),
        })
      );
    };

    return {
      swipe: (direction) => track('swipe', direction),
      arrowButtonClick: (direction) => track('arrows', direction),
      dotsButtonClick: () => track('dots'),
    };
  }, [analytics, clickContext, title]);

  const [containerRef, { width: sliderWidth }] = useMeasure();
  const slidesToShow = useMemo(() => {
    // react-slick does have a variableWidth option where the slide content
    // size dictates the width of each slide independently, but it really
    // only works with the `infinite` and `centerMode` options. if used
    // alone the carousel can be advanced until the last slide is left-aligned
    // on the carousel track, leaving the remaining width of the carousel
    // empty (there are many inconsistencies in the calculation of slider
    // width with the different combinations of options). unfortunately that
    // only leaves us with the `slidesToShow` option where react-slick sets
    // the width of the slide elements by how many slides we want visible in
    // the total width of the carousel. since our designs require the width
    // of the slides to remain consistent within the breakpoints, we need to
    // calculate the `slidesToShow` value based on the total width of the
    // container and the desired width (including spacing) for each the
    // slides — inefficient, but mostly effective.
    if (centerMode) {
      return 1;
    }

    // slick-list width includes negative margin to hide outer-most padding
    // on first and last slide elements
    const slickListWidth = sliderWidth + spacing;
    // slick-slide width includes inner slide content width and padding
    const slickSlideWidth = slideWidth + spacing;
    // slidesToShow must be >= 1
    return Math.max(slickListWidth / slickSlideWidth, 1);
  }, [centerMode, sliderWidth, slideWidth, spacing]);

  const sliderRef = React.useRef(null);

  const handleAfterChange = (index) => {
    setCurrentSlide(index);
  };

  const isPrevDisabled = currentSlide === 0;
  const isNextDisabled = currentSlide >= items.length - slidesToShow;

  return (
    <>
      {!topArrows ? (
        renderHeader?.(slidesToShow)
      ) : (
        <div className={classNames(className, styles['top-arrows-header'])}>
          {renderHeader?.(slidesToShow)}
          <div className={styles['top-arrows']}>
            <ReactSlickArrow
              className={styles['top-arrow']}
              direction="left"
              onArrowButtonClick={trackingHandlers.arrowButtonClick}
              isTopArrow
              onClick={() => sliderRef.current.slickPrev()}
              disabled={isPrevDisabled}
              onTopArrowClick={onTopBackClick}
            />
            <ReactSlickArrow
              className={styles['top-arrow']}
              direction="right"
              onArrowButtonClick={trackingHandlers.arrowButtonClick}
              isTopArrow
              onClick={() => sliderRef.current.slickNext()}
              disabled={isNextDisabled}
              onTopArrowClick={onTopForwardClick}
            />
          </div>
        </div>
      )}

      <div
        ref={containerRef}
        className={styles.slider}
        style={{
          '--slick-slide-spacing': `${spacing}px`,
          '--slick-slide-width': `${slideWidth}px`,
        }}
      >
        <Slider
          ref={sliderRef}
          centerMode={centerMode}
          // centerMode only works when infinite === true
          // see: https://github.com/akiran/react-slick/issues/2033
          // see: https://github.com/akiran/react-slick/issues/2036
          infinite={centerMode || infinite}
          // in centerMode, use variableWidth and set static px width on slide
          // element — variableWidth is not compatible with custom slidesToShow
          // or slidesToScroll values. alternately, if variableWidth is set
          // when centermode is false there are slide positioning issues.
          variableWidth={centerMode}
          // the value for slidesToShow and slidesToScroll may be entirely
          // omitted but cannot be explicitly set to `undefined` or the slide
          // center position will not be calculated correctly. the default value
          // is `1`.
          slidesToShow={slidesToShow}
          slidesToScroll={Math.floor(slidesToShow)}
          initialSlide={initialSlide}
          arrows={!topArrows && arrows}
          adaptiveHeight={centerMode || infinite}
          dots={dots}
          speed={speed}
          onSwipe={trackingHandlers.swipe}
          prevArrow={
            !topArrows ? (
              <ReactSlickArrow
                direction="left"
                onArrowButtonClick={trackingHandlers.arrowButtonClick}
              />
            ) : undefined
          }
          nextArrow={
            !topArrows ? (
              <ReactSlickArrow
                direction="right"
                onArrowButtonClick={trackingHandlers.arrowButtonClick}
              />
            ) : undefined
          }
          afterChange={handleAfterChange}
        >
          {items.map((item, index) => (
            // React Slick clones direct children and injects props
            <div key={`${getItemKey(item)}-${index}`}>
              <div className={styles.slide}>
                {renderItem(item, index, sliderRef.current?.slickGoTo)}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
ReactSlickCarousel.propTypes = {
  analytics: PropTypes.object,
  clickContext: PropTypes.object,
  title: PropTypes.string,
  arrows: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  centerMode: PropTypes.bool,
  initialSlide: PropTypes.number,
  speed: PropTypes.number,
  spacing: PropTypes.number,
  slideWidth: PropTypes.number.isRequired,
  renderHeader: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.any),
  getItemKey: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  topArrows: PropTypes.bool,
  onTopForwardClick: PropTypes.func,
  onTopBackClick: PropTypes.func,
  className: PropTypes.string,
};
