import React from 'react';
import PropTypes from 'prop-types';

import { PAYLOAD, PerformerClickTracker } from 'analytics';
import GTFullWidthList from 'components/GTFullWidthList/GTFullWidthList';
import Section from 'components/Section/Section';
import { Performer } from 'models';
import { CATEGORY_URLS } from 'store/modules/categories/category.helpers';

import styles from './PerformersSection.module.scss';

export default function PerformersSection(props) {
  const { category, relatedPerformers, sectionTitle } = props;
  if (!relatedPerformers || relatedPerformers.length < 2) {
    return null;
  }

  const relatedPerformersListItems = relatedPerformers.map(
    (relatedPerformer) => ({
      id: relatedPerformer.id,
      text: relatedPerformer.name,
      link: relatedPerformer.getPath(),
      clickTracker: new PerformerClickTracker(relatedPerformer).payload({
        [PAYLOAD.SECTION]: 'related',
      }),
    })
  );

  const performerTitle =
    CATEGORY_URLS[category]?.title || category.toUpperCase();
  const relatedSectionTitle = sectionTitle || `Other ${performerTitle} Events`;

  return (
    <Section
      className={styles['related-section']}
      headline={relatedSectionTitle}
    >
      <GTFullWidthList
        listClassName="relatedList"
        items={relatedPerformersListItems}
      />
    </Section>
  );
}

PerformersSection.propTypes = {
  category: PropTypes.string.isRequired,
  relatedPerformers: PropTypes.arrayOf(PropTypes.instanceOf(Performer)),
  sectionTitle: PropTypes.string,
};
