import { TrackEvent } from './TrackEvent';

const INTERACTION_NAMES = {
  SWIPE: 'swipe',
  CLICK: 'click',
};

export class UnlockDealEvent extends TrackEvent {
  static INTERACTIONS = {
    SWIPE: () => ({ interaction: INTERACTION_NAMES.SWIPE }),
    CLICK: () => ({ interaction: INTERACTION_NAMES.CLICK }),
  };

  _eventName = 'unlock_deal';
}
