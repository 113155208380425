import React, { useEffect, useState } from 'react';
import Measure, { BoundingRect, ContentRect } from 'react-measure';
import classNames from 'classnames';
import objectFitImages from 'object-fit-images';

import placeHolderImage from 'assets/image_placeholder.svg';
import PreloadedImage from 'components/Head/PreloadedImage';
import {
  calcBreakpoint,
  FastlyParams,
  generateFastlyImagePathname,
  getDpr,
  MINIMUM_FASTLY_PARAMS,
  PRELOADED_IMAGE_PARAMS,
  RESPONSIVE_IMAGE_PARAMS,
} from 'helpers/image';

import styles from './Image.module.scss';

objectFitImages(`.${styles.image}`);

type Props = {
  preserveFormat?: boolean;
  lazyLoad?: boolean;
  isPreloaded?: boolean;
  src?: string;
  alt?: string;
  seoPrefix?: string;
  seoPostfix?: string;
  className?: string;
  onError?: VoidFunction;
  onLoad?: VoidFunction;
};

/**
 * allows TS to recognize fetchpriority as an attribute
 */
declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchpriority?: 'high' | 'low' | 'auto';
  }
}

export default function Image(props: Props) {
  const [breakpoints, setBreakpoints] = useState({
    width: -1,
    height: -1,
  });
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const {
    preserveFormat = false,
    lazyLoad = true,
    isPreloaded = false,
    src = '',
    alt,
    seoPrefix,
    seoPostfix,
    className,
    onError,
    onLoad,
  } = props;

  const [responsiveSrc, setResponsiveSrc] = useState(src);

  const dpr = getDpr();

  const getPreloadedSrc = () => {
    return generateFastlyImagePathname(
      src,
      { ...PRELOADED_IMAGE_PARAMS },
      seoPostfix,
      seoPrefix
    );
  };

  const handleResize = (contentRect: ContentRect) => {
    const { width, height } = contentRect.bounds as BoundingRect;
    const breakpointWidth = Math.max(
      calcBreakpoint(width, dpr),
      breakpoints.width
    );
    const breakpointHeight = Math.max(
      calcBreakpoint(height, dpr),
      breakpoints.height
    );

    if (
      breakpointWidth !== breakpoints.width ||
      breakpointHeight !== breakpoints.height
    ) {
      setBreakpoints({
        width: breakpointWidth,
        height: breakpointHeight,
      });
    }
  };

  const handleOnLoad = () => {
    setInitiallyLoaded(true);
    if (onLoad) onLoad();
  };

  const handleOnError = () => {
    setLoadError(true);
    if (onError) onError();
  };

  useEffect(() => {
    const { width, height } = breakpoints;
    const isMinimumFastly = !initiallyLoaded || width < 1 || height < 1;
    const params: FastlyParams = isMinimumFastly
      ? { ...MINIMUM_FASTLY_PARAMS }
      : {
          ...RESPONSIVE_IMAGE_PARAMS,
          width,
          height,
          dpr,
        };

    if (preserveFormat) {
      delete params.format;
    }
    setResponsiveSrc(
      generateFastlyImagePathname(src, params, seoPostfix, seoPrefix)
    );
  }, [
    initiallyLoaded,
    breakpoints,
    dpr,
    preserveFormat,
    seoPostfix,
    seoPrefix,
    src,
  ]);

  if (loadError) {
    return (
      <div className={classNames(className, styles['placeholder-container'])}>
        <img className={styles.placeholder} src={placeHolderImage} alt="" />
      </div>
    );
  }

  const imageSrc = responsiveSrc !== src ? responsiveSrc : getPreloadedSrc();

  return (
    <>
      {isPreloaded && <PreloadedImage src={getPreloadedSrc() as string} />}
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => (
          <img
            alt={alt}
            onLoad={handleOnLoad}
            onError={handleOnError}
            ref={measureRef}
            className={classNames(className, styles.image)}
            src={imageSrc}
            loading={lazyLoad ? 'lazy' : 'eager'}
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={isPreloaded ? 'high' : 'auto'}
          />
        )}
      </Measure>
    </>
  );
}
