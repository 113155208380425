interface SemPromos {
  save20: {
    bannerHeadline: string;
  };
}
type SemPromo = keyof SemPromos;

export const SEM_PROMOS: SemPromos = {
  save20: {
    bannerHeadline: '$20 Off Your First Order Over $150, Use Code SAVE20',
  },
};

export const ACTIVE_SEM_PROMOS: SemPromo[] = ['save20'];
