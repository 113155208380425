import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Click, useAnalyticsContext } from 'analytics';
import { useLogin } from 'components/Login/Login/useLogin';
import { device, useMediaQuery } from 'hooks';

import styles from './PicksSignUp.module.scss';

const MOBILE_PRODUCT_IMAGE_URL =
  'https://assets.gametime.co/static_pages/picks/Mobile_Product%20Shots@3x.png?auto=webp';
const DESKTOP_PRODUCT_IMAGE_URL =
  'https://assets.gametime.co/static_pages/picks/Desktop_Product%20Shots@3x.png?auto=webp';

function PicksSignUp({ dispatch }) {
  const analytics = useAnalyticsContext();
  const [email, setEmail] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const { handleEmailLogin } = useLogin({
    dispatch,
    analytics,
    sourcePageTypeCallback: Click.SOURCE_PAGE_TYPES.ADD_EMAIL,
  });
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSignUp = async (event) => {
    event.preventDefault();
    const isValidEmail = email.match(/^[^@]+@[^@]+\.[^@]+$/);
    if (!isValidEmail) {
      setMessage('Please supply a valid email');
      return;
    }
    setMessage('Hurray! You are signed up!');
    setEmail('');
    setFormDisabled(true);
    handleEmailLogin(email);
  };

  const isMobile = useMediaQuery(device.down.md);

  return (
    <div className={styles['sign-up-container']}>
      <div className={styles['sign-up-card']}>
        <div className={styles['sign-up-content']}>
          <div className={styles['sign-up-title-container']}>
            <span className={styles['sign-up-title']}>
              Our Picks. Your Inbox. You're Welcome.
            </span>
            <span className={styles['sign-up-subtitle']}>
              Sign up to get the latest ticket deals for events in your area.
            </span>
          </div>
          <form onSubmit={handleSignUp} className={styles['sign-up-form']}>
            <div className={styles['sign-up-input-container']}>
              <div className={styles['sign-up-email-label-container']}>
                <label
                  className={styles['sign-up-input-label']}
                  htmlFor="email"
                >
                  Email
                </label>
                <span className={styles['sign-up-input-error']}>{message}</span>
              </div>
              <input
                onChange={handleEmailChange}
                className={styles['sign-up-input']}
                type="email"
                value={email}
                disabled={formDisabled}
                placeholder="Email Address"
              />
            </div>
            <button
              onClick={handleSignUp}
              className={styles['sign-up-button']}
              disabled={formDisabled}
            >
              Sign Up
            </button>
          </form>
        </div>
        <div className={styles['sign-up-image']}>
          <img
            src={
              isMobile ? MOBILE_PRODUCT_IMAGE_URL : DESKTOP_PRODUCT_IMAGE_URL
            }
            alt="Picks"
          />
        </div>
      </div>
    </div>
  );
}

PicksSignUp.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PicksSignUp);
