import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';

import config from 'config/config';
import { envIsDatadogEnv } from 'config/environments';

const EnsurePath = (path) => (WrappedComponent) => {
  class EnhancedComponent extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      router: PropTypes.object.isRequired,
    };

    componentDidMount() {
      const ensurePath = typeof path === 'function' ? path(this.props) : path;

      const { pathname, search } = this.props.location;
      if (ensurePath && ensurePath !== pathname) {
        if (envIsDatadogEnv(config.env)) {
          datadogRum.addAction('ensurePath', { ensurePath, pathname });
        }

        this.props.router.navigate(
          { pathname: ensurePath, search },
          { replace: true }
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return EnhancedComponent;
};

export default EnsurePath;
