import React from 'react';

export default ({ width = '24', height = '24', fill = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.5V1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H12V0.5C12 0.223858 11.7761 0 11.5 0H10.5C10.2239 0 10 0.223858 10 0.5V1H6V0.5C6 0.223858 5.77614 0 5.5 0H4.5C4.22386 0 4 0.223858 4 0.5ZM13 5H3V13H13V5Z"
      fill={fill}
    />
  </svg>
);
