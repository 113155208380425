import React from 'react';
import colors from 'styles/colors.constants';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill || colors.gray200}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 6C1 4.89543 1.89543 4 3 4H21C22.1046 4 23 4.89543 23 6V7H1V6ZM1 9H23V18C23 19.1046 22.1046 20 21 20H3C1.89543 20 1 19.1046 1 18V9ZM19.5 17C19.7761 17 20 16.7761 20 16.5V15.5C20 15.2239 19.7761 15 19.5 15H16.5C16.2239 15 16 15.2239 16 15.5V16.5C16 16.7761 16.2239 17 16.5 17H19.5Z"
    />
  </svg>
);
