import React from 'react';

function getCardIconProps(cardType?: string) {
  switch (cardType) {
    case 'visa': {
      return { width: '27', height: '24', viewBox: '0 0 27 24' };
    }
    case 'master-card': {
      return { width: '27', height: '24', viewBox: '28 0 27 24' };
    }
    case 'american-express': {
      return { width: '27', height: '24', viewBox: '55 0 27 24' };
    }
    case 'discover': {
      return { width: '27', height: '24', viewBox: '82 0 27 24' };
    }
    default: {
      return { width: '108', height: '24', viewBox: '0 0 108 24' };
    }
  }
}

export default function CreditCardsBarIcon({
  cardType,
}: {
  cardType?: string;
}) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...getCardIconProps(cardType)}
    >
      <rect x="1" y="4" width="22" height="16" rx="1" fill="#142787" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0708 9.5C14.6019 9.5 15.0271 9.60952 15.2985 9.71158L15.1128 10.8034L14.9901 10.7453C14.7372 10.6432 14.4127 10.5453 13.9648 10.5523C13.4285 10.5523 13.1803 10.7762 13.1803 10.9856C13.1772 11.2216 13.4704 11.3771 13.9492 11.6101C14.7395 11.9697 15.1045 12.4058 15.0994 12.9789C15.0887 14.0246 14.1539 14.7 12.7139 14.7C12.0996 14.6937 11.5079 14.5718 11.188 14.4312L11.3802 13.3035L11.5568 13.3836C12.0067 13.5717 12.2979 13.648 12.8462 13.648C13.24 13.648 13.6624 13.4937 13.6658 13.1561C13.6684 12.9356 13.4891 12.7781 12.9559 12.5312C12.4362 12.2902 11.7479 11.8864 11.7555 11.1625C11.7637 10.1833 12.7172 9.5 14.0708 9.5ZM11.1803 9.58856H9.81038L8.9539 14.6263H10.3232L11.1803 9.58856ZM18.8113 9.59235H17.7528C17.4249 9.59235 17.1795 9.68206 17.0355 10.0101L15.001 14.6268H16.4395C16.4395 14.6268 16.6746 14.0061 16.7278 13.8698L17.5185 13.8708C17.9342 13.8713 18.3764 13.872 18.4822 13.872C18.5232 14.0483 18.6489 14.6268 18.6489 14.6268H19.92L18.8113 9.59235ZM17.6679 11.4311C17.6679 11.4311 17.2354 12.5492 17.1221 12.8394L18.2593 12.8394C18.2044 12.5872 17.9421 11.3847 17.9421 11.3847L17.8496 10.9503C17.8133 11.0494 17.763 11.1802 17.7245 11.2805C17.6894 11.3717 17.6641 11.4375 17.6679 11.4311ZM5.22643 9.5881H3.01748L3 9.69292C3.53732 9.8233 4.01781 10.0114 4.4389 10.2454L5.65681 14.6209L7.10619 14.6192L9.26285 9.59122H7.81177L6.4706 13.0265L6.32771 12.3284L6.32769 12.3284L6.32771 12.3284L5.84739 10.0134C5.76447 9.69441 5.52396 9.59922 5.22643 9.5881Z"
        fill="white"
      />
      <rect x="29" y="4" width="22" height="16" rx="1" fill="white" />
      <circle cx="37" cy="12" r="5" fill="#EB001B" />
      <circle cx="43" cy="12" r="5" fill="#F89E1B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 16.0002C41.2144 15.088 42 13.6357 42 11.9999C42 10.3641 41.2144 8.91172 40 7.99951C38.7856 8.91172 38 10.3641 38 11.9999C38 13.6357 38.7856 15.088 40 16.0002Z"
        fill="#FF5F00"
      />
      <rect x="57" y="4" width="22" height="16" rx="1" fill="#0072BC" />
      <path
        d="M63.758 14L62.216 9.99805H61.148L59.606 14H60.572L60.824 13.322H62.54L62.792 14H63.758ZM62.306 12.572H61.058L61.682 10.85L62.306 12.572Z"
        fill="white"
      />
      <path
        d="M68.4655 14V9.99805H67.2655L66.2935 12.5L65.3215 9.99805H64.1275V14H64.9795V11.114L66.1075 14H66.4795L67.6075 11.114V14H68.4655Z"
        fill="white"
      />
      <path
        d="M72.0923 14V13.25H70.1123V12.338H72.0503V11.588H70.1123V10.748H72.0923V9.99805H69.2603V14H72.0923Z"
        fill="white"
      />
      <path
        d="M76.3884 14L74.9364 11.942L76.2984 9.99805H75.2724L74.3664 11.366L73.4484 9.99805H72.4344L73.7964 11.948L72.3444 14H73.3584L74.3664 12.536L75.3684 14H76.3884Z"
        fill="white"
      />
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="84"
        y="0"
        width="24"
        height="24"
      >
        <rect x="85" y="4" width="22" height="16" rx="1" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect x="85" y="4" width="22" height="16" rx="2" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108 13.5C108 13.5 100.583 17.9878 87 19.9964H108V13.5Z"
          fill="#F4811F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.6284 12.1987C88.4582 12.3548 88.2371 12.4229 87.887 12.4229H87.7416V10.5583H87.887C88.2371 10.5583 88.4496 10.6219 88.6284 10.7864C88.8158 10.9557 88.9285 11.2182 88.9285 11.4883C88.9285 11.759 88.8158 12.0294 88.6284 12.1987ZM87.9955 10.0806H87.2002V12.9002H87.9913C88.412 12.9002 88.7158 12.7995 88.9824 12.5747C89.2992 12.3085 89.4866 11.9073 89.4866 11.4924C89.4866 10.6602 88.8742 10.0806 87.9955 10.0806Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.7358 12.8998H90.2777V10.0801H89.7358V12.8998Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.6027 11.1623C91.2776 11.0402 91.1822 10.9597 91.1822 10.8072C91.1822 10.6297 91.3522 10.4947 91.5858 10.4947C91.748 10.4947 91.8815 10.5625 92.0226 10.723L92.3062 10.3462C92.0732 10.1394 91.7945 10.0337 91.4899 10.0337C90.9984 10.0337 90.6236 10.3801 90.6236 10.8416C90.6236 11.23 90.7982 11.4289 91.307 11.6148C91.5191 11.6908 91.6271 11.7414 91.6815 11.7754C91.7898 11.8472 91.844 11.9488 91.844 12.0672C91.844 12.2956 91.6651 12.4648 91.4233 12.4648C91.1649 12.4648 90.9567 12.3337 90.832 12.0888L90.4819 12.4309C90.7315 12.803 91.0314 12.9678 91.4437 12.9678C92.0066 12.9678 92.4017 12.5877 92.4017 12.0419C92.4017 11.5939 92.219 11.3911 91.6027 11.1623Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.5723 11.4921C92.5723 12.3209 93.2134 12.9635 94.0384 12.9635C94.2716 12.9635 94.4714 12.917 94.7177 12.7993V12.1519C94.5011 12.3719 94.3092 12.4607 94.0636 12.4607C93.5179 12.4607 93.1306 12.0591 93.1306 11.488C93.1306 10.9467 93.5301 10.5197 94.0384 10.5197C94.2968 10.5197 94.4924 10.6132 94.7177 10.8369V10.1898C94.4799 10.0674 94.2843 10.0166 94.0512 10.0166C93.2303 10.0166 92.5723 10.6723 92.5723 11.4921Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.0138 11.9745L98.2731 10.0806H97.6812L98.8599 12.9726H99.1515L100.351 10.0806H99.7641L99.0138 11.9745Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.596 12.9003H102.133V12.4229H101.138V11.6618H102.096V11.1842H101.138V10.5584H102.133V10.0806H100.596V12.9003Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.191 11.3783H103.033V10.5244H103.2C103.537 10.5244 103.721 10.6678 103.721 10.9422C103.721 11.2257 103.537 11.3783 103.191 11.3783ZM104.278 10.9126C104.278 10.3847 103.92 10.0801 103.295 10.0801H102.492V12.8998H103.033V11.7671H103.104L103.854 12.8998H104.52L103.645 11.7119C104.053 11.6276 104.278 11.3443 104.278 10.9126Z"
          fill="#231F20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.8945 11.4999C94.8945 12.3284 95.556 13 96.3721 13C97.1883 13 97.8498 12.3284 97.8498 11.4999C97.8498 10.6715 97.1883 10 96.3721 10C95.556 10 94.8945 10.6715 94.8945 11.4999Z"
          fill="#F4811F"
        />
        <mask
          id="mask1"
          maskUnits="userSpaceOnUse"
          x="94"
          y="10"
          width="4"
          height="3"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.8945 11.4999C94.8945 12.3284 95.556 13 96.3721 13C97.1883 13 97.8498 12.3284 97.8498 11.4999C97.8498 10.6715 97.1883 10 96.3721 10C95.556 10 94.8945 10.6715 94.8945 11.4999Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1)" />
        <rect
          x="85.25"
          y="4.25"
          width="21.5"
          height="15.5"
          rx="0.75"
          stroke="#C8C8C8"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
}
