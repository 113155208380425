import { TrackEvent } from './TrackEvent';

/**
 * Tracking event for when InsuranceOptions is rendered.
 */
export class InsuranceRendered extends TrackEvent {
  _eventName = 'insurance_rendered';

  constructor(payload: {
    /**
     * Number of tickets being purchased (seatCount)
     */
    quantity: number;
    /**
     * Total purchase price in dollars including fees and taxes, subtracting
     * promo discounts
     */
    post_fee_price: number;
    /**
     * Whether the user is eligible for insurance. True if the quote endpoint
     * returns a quote and positive eligibility, false otherwise.
     */
    insurance_eligible: boolean;
    /**
     * Initial insurance quote in dollars. Will not be present if
     * the purchase is not eligible for insurance.
     */
    insurance_initial_quote?: number;
  }) {
    /**
     * TypeScript can't infer this type for payload when importing from JS, so
     * until we refactor TrackEvent we need to cast it to the type TS infers.
     */
    super({ payload: payload as unknown as null });
  }
}
