import { logout } from 'store/modules/user/actions';

export default function clientMiddleware(httpClient) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, cms, ...rest } = action; // eslint-disable-line no-redeclare

      if (cms) {
        return next(action);
      }

      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(httpClient);

      actionPromise
        .then(
          (result) => {
            // doing this because backend returns status 200s for errors
            const type =
              result && result.status === 'error' ? FAILURE : SUCCESS;
            return next({ ...rest, result, type });
          },
          (error) => {
            if (error) {
              switch (error.message) {
                case 'User not found':
                  dispatch(logout());
                  break;
                default:
                  break;
              }
            }
            return next({ ...rest, error, type: FAILURE });
          }
        )
        .catch((error) => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
}
