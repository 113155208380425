import React from 'react';
import PropTypes from 'prop-types';

import { Click, ClickTracker } from 'analytics';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';
import { useMediaQuery } from 'hooks/useMediaQuery';
import CarIcon from 'icons/CarIcon';

import DesktopBackground from './assets/parking-background-desktop.png';
import MobileBackground from './assets/parking-background-mobile.png';

import styles from './DetailParkingUpsell.module.scss';

const DetailParkingUpsell = ({ eventId, transactionId, eventUrl }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const clickTracker = new ClickTracker()
    .interaction(Click.INTERACTIONS.PARKING_MODULE(), {
      eventId,
      transactionId,
    })
    .targetPageType(Click.TARGET_PAGE_TYPES.EVENT(eventId));

  return (
    <div
      style={{
        backgroundImage: `url(${
          isMobile ? MobileBackground : DesktopBackground
        })`,
      }}
      className={styles['detail-parking-upsell']}
    >
      <h4 className={styles.heading}>
        Need a parking pass
        <span className={styles['hidden-mobile']}> for this event</span>?
      </h4>
      <span className={styles.subtitle}>
        We've got you covered. Find your spot!
      </span>
      <div className={styles['cta-container']}>
        <CarIcon />
        <SimpleLinkButton
          to={eventUrl}
          className={styles.cta}
          type="white_medium"
          text="Find Parking"
          clickTracker={clickTracker}
        />
      </div>
    </div>
  );
};

DetailParkingUpsell.propTypes = {
  eventId: PropTypes.string,
  transactionId: PropTypes.string,
  eventUrl: PropTypes.string,
};

export default DetailParkingUpsell;
