import React from 'react';
import classnames from 'classnames';

import CheckmarkIndicator from 'components/CheckmarkIndicator/CheckmarkIndicator';

import { useRadio } from '../Radio.context';

import styles from './RadioCard.module.scss';

interface RadioCardProps<T> {
  value: T;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
  expandedContent?: React.ReactNode;
}

function RadioCard<T>({
  value,
  title,
  subtitle,
  icon,
  expandedContent,
}: RadioCardProps<T>) {
  const context = useRadio<T>();

  const checked = context.value === value;

  const handleChange = () => {
    context.onChange(value);
  };

  return (
    <div
      className={styles['radio-card']}
      data-testid={`${context.name}/${value}`}
    >
      <label className={styles['header']}>
        <input
          type="radio"
          className={styles['input-sr-only']}
          name={context.name}
          value={typeof value === 'string' ? value : JSON.stringify(value)}
          checked={checked}
          onChange={handleChange}
        />
        <div className={styles.content}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <div className={styles['title-block']}>
            <span className={styles.title}>{title}</span>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
        </div>
        <div className={styles.checkmark}>
          <CheckmarkIndicator isSelected={checked} />
        </div>
      </label>
      {expandedContent && (
        <div
          data-testid={`${context.name}/${value}/expanded-content`}
          className={classnames(styles['expanded-content-drawer'], {
            [styles['is-expanded']]: checked,
          })}
        >
          <div className={styles['expanded-content-overflow']}>
            <div className={styles['expanded-content']}>{expandedContent}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RadioCard;
