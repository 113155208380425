import React, { useEffect } from 'react';

import { HttpClient } from 'services/HttpClient/HttpClient';

import Posting from '../Posting';

import styles from './OpenRoles.module.scss';

interface JobPosting {
  id: number;
  absolute_url: string;
  location: {
    name: string;
  };
  title: string;
}

interface Department {
  id: number;
  jobs: JobPosting[];
  name: string;
}

export interface JobPostingsResponse {
  departments: Department[];
}

const OpenRoles = React.forwardRef<HTMLDivElement>((_props, ref) => {
  const [departments, setDepartments] = React.useState<Department[]>([]);

  useEffect(() => {
    const greenhouseClient = new HttpClient('https://boards-api.greenhouse.io');

    greenhouseClient
      .request<JobPostingsResponse>({
        path: '/v1/boards/gametimeunited/departments',
      })
      .then((response) => {
        if (!response?.departments) {
          throw new Error('Invalid response from Greenhouse API');
        }

        const departmentsWithJobs = response.departments.filter(
          (department) => department.jobs.length > 0
        );

        setDepartments(departmentsWithJobs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div ref={ref} className={styles['open-roles']}>
      <div className={styles.header}>
        <h3 className={styles.heading}>Open Positions</h3>
        <p className={styles.copy}>
          We're growing fast and looking for curious, motivated people to join
          us. Learn more about our teams and apply below!
        </p>
      </div>
      <div className={styles['job-postings']}>
        {departments.map((department) => (
          <div className={styles.department} key={department.id}>
            <h4 className={styles['department-name']}>{department.name}</h4>
            {department.jobs.map((job) => (
              <div key={job.id} className={styles['job-posting']}>
                <Posting
                  url={job.absolute_url}
                  title={job.title}
                  location={job.location.name}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
});

export default OpenRoles;
