import { schema } from 'normalizr';

import { SCHEMA_ENTITIES } from './schemaEntities';

export const VenueSchema = new schema.Entity(
  SCHEMA_ENTITIES.VENUES,
  {},
  {
    idAttribute: 'venue_id',
  }
);
