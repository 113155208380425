import React from 'react';

export default (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16.0001"
      cy="16"
      r="12.5833"
      fill="#27272A"
      stroke={props.stroke || '#FFFFFF'}
      strokeWidth="1.5"
    />
    <circle
      cx="16.0001"
      cy="12"
      r="4.58333"
      stroke={props.stroke || '#FFFFFF'}
      strokeWidth="1.5"
    />
    <path
      d="M6.66675 24.4172C9.55564 21.0839 17.3334 16.4172 25.3334 24.4172"
      stroke={props.stroke || '#FFFFFF'}
      strokeWidth="1.5"
    />
  </svg>
);
