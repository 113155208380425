import React from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

export const VARIANTS = {
  DEFAULT: 'default',
  DARK: 'dark',
  GAMETIME_GREEN_LIGHT: 'gametime-green-light',
} as const;

type Variant = (typeof VARIANTS)[keyof typeof VARIANTS];

interface SpinnerProps {
  isFullscreen?: boolean;
  style?: React.CSSProperties;
  format?: Variant;
  text?: string;
  isTransparentBackdrop?: boolean;
}

const Spinner = ({
  isFullscreen = false,
  style = {},
  format = VARIANTS.DEFAULT,
  text,
  isTransparentBackdrop = false,
}: SpinnerProps) => (
  <div
    data-testid="spinner"
    className={classNames(styles.spinner, styles[format], {
      [styles.fullscreen]: isFullscreen,
      [styles['transparent-backdrop']]: isTransparentBackdrop,
    })}
    style={style}
  >
    {text && (
      <span data-testid="spinner-text" className={styles.text}>
        {text}{' '}
      </span>
    )}
    <span data-testid="spin-icon" className={styles['spin-icon']} />
  </div>
);

export default Spinner;
