import React from 'react';

import colors from 'styles/colors.constants';

interface StarMediumIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

export default function StarMediumIcon({
  width = '16',
  height = '16',
  fill = colors.black,
}: StarMediumIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="star-medium-icon"
    >
      <path
        d="M7.21129 1.64173C7.52907 0.980268 8.47093 0.980268 8.78871 1.64173L10.1092 4.39033C10.2367 4.65584 10.4893 4.83935 10.7812 4.87862L13.8034 5.28508C14.5306 5.3829 14.8217 6.27867 14.2908 6.78529L12.0848 8.89048C11.8717 9.09384 11.7752 9.39077 11.8281 9.68055L12.3754 12.6804C12.5071 13.4023 11.7451 13.9559 11.0992 13.6075L8.41536 12.16C8.15611 12.0202 7.84389 12.0202 7.58464 12.16L4.90078 13.6075C4.2549 13.9559 3.49291 13.4023 3.62463 12.6804L4.17194 9.68055C4.22481 9.39077 4.12833 9.09384 3.91523 8.89048L1.7092 6.78529C1.17831 6.27867 1.46936 5.3829 2.19665 5.28508L5.21876 4.87862C5.5107 4.83935 5.76328 4.65584 5.89084 4.39033L7.21129 1.64173Z"
        fill={fill}
      />
    </svg>
  );
}
