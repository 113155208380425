import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from 'ui/Checkbox';

import {
  PAYLOAD,
  SOURCE_PAGE_TYPE_NAMES,
  useAnalyticsContext,
} from 'analytics';
import { EmailSubscriptionEvent } from 'analytics/events/EmailSubscription';
import { Listing } from 'models';
import { fetchMLBTeamLinksContent } from 'store/modules/content/mlb/actions';
import { selectMLBTeamLink } from 'store/modules/content/mlb/selectors';
import { MLB_LINK, MLB_PRIVACY_POLICY_LINK } from 'utils/mlb';

import styles from './MLBOptInSection.module.scss';

const MLBOptInSection = ({
  performerName,
  fetchContent,
  mlbTeamLink,
  isOptedIn,
  listing,
  onOptInChange,
}) => {
  const analytics = useAnalyticsContext();

  useEffect(() => {
    const fetchData = async () => {
      await fetchContent();
    };

    fetchData();
  }, [fetchContent]);

  const handleOptIn = () => {
    analytics.track(
      new EmailSubscriptionEvent({
        page_type: SOURCE_PAGE_TYPE_NAMES.CHECKOUT,
        event_id: listing.eventId,
        listing_id: listing.id,
        payload: {
          [PAYLOAD.SUBSCRIPTION]: !isOptedIn ? 'subscribe' : 'unsubscribe',
        },
      })
    );

    onOptInChange();
  };

  const performerLink = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={mlbTeamLink}
      data-testid="mlb-team-link"
    >
      {performerName}
    </a>
  );

  const mlbLink = (
    <a target="_blank" rel="noopener noreferrer" href={MLB_LINK}>
      MLB
    </a>
  );

  const privacyPolicyLink = (
    <a target="_blank" rel="noopener noreferrer" href={MLB_PRIVACY_POLICY_LINK}>
      MLB Privacy Policy
    </a>
  );

  return (
    <>
      <div className={styles['mlb-opt-in']}>
        <Checkbox isSelected={isOptedIn} onClick={handleOptIn} />
        <span className={styles['opt-in-message']}>
          I would like to receive commercial emails from {mlbLink}, the{' '}
          {performerLink}, and their partners. I agree to the MLB Privacy
          Policy.
        </span>
      </div>
      <div className={styles['mlb-terms']}>
        <span className={styles['terms-and-conditions']}>
          I understand that data I provide in connection with my purchase will
          be provided to {mlbLink} and the {performerName} for fulfillment
          purposes and use and processing otherwise in accordance with the{' '}
          {privacyPolicyLink}.
        </span>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  mlbTeamLink: selectMLBTeamLink(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchMLBTeamLinksContent()),
});

MLBOptInSection.propTypes = {
  mlbTeamLink: PropTypes.string,
  fetchContent: PropTypes.func,
  performerName: PropTypes.string.isRequired,
  isOptedIn: PropTypes.bool.isRequired,
  listing: PropTypes.instanceOf(Listing).isRequired,
  onOptInChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MLBOptInSection);
