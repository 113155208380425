import React from 'react';
import PropTypes from 'prop-types';

import AppRequiredFillIcon from 'icons/AppRequiredFillIcon';
import DeliveryOnlyIcon from 'icons/DeliveryOnlyIcon';
import DeliveryThirdPartyIcon from 'icons/DeliveryThirdPartyIcon';
import LocalPickupOnlyIcon from 'icons/LocalPickupOnlyIcon';
import MultiTicketFillIcon from 'icons/MultiTicketFillIcon';
import PhoneDownloadIcon from 'icons/PhoneDownloadIcon';
import PhoneTicketFillIcon from 'icons/PhoneTicketFillIcon';
import PrintFillIcon from 'icons/PrintFillIcon';
import { DELIVERY_FORMATS } from 'store/datatypes/DELIVERY_FORMATS';

const DeliveryIcon = ({ deliveryFormat, iconProps }) => {
  switch (deliveryFormat) {
    case DELIVERY_FORMATS.LOCAL_PICKUP_ONLY:
      return <LocalPickupOnlyIcon {...iconProps} />;
    case DELIVERY_FORMATS.APP_REQUIRED:
      return <AppRequiredFillIcon {...iconProps} />;
    case DELIVERY_FORMATS.DELIVERY_THIRD_PARTY:
    case DELIVERY_FORMATS.MLB_BALLPARK:
    case DELIVERY_FORMATS.TEXT_MESSAGE:
    case DELIVERY_FORMATS.PACIOLAN:
      return <DeliveryThirdPartyIcon {...iconProps} />;
    case DELIVERY_FORMATS.PRINT_ONLY:
      return <PrintFillIcon {...iconProps} />;
    case DELIVERY_FORMATS.DELIVERY_ONLY:
      return <DeliveryOnlyIcon {...iconProps} />;
    case DELIVERY_FORMATS.MOBILE:
      return <PhoneTicketFillIcon {...iconProps} />;
    case DELIVERY_FORMATS.MLB_ORDER:
      return <PhoneDownloadIcon {...iconProps} />;
    default:
      return <MultiTicketFillIcon {...iconProps} />;
  }
};

DeliveryIcon.propTypes = {
  deliveryFormat: PropTypes.string.isRequired,
  iconProps: PropTypes.object,
};

export default DeliveryIcon;
