import _shuffle from 'lodash/shuffle';
import _snakeCase from 'lodash/snakeCase';

import { isObjectEmpty } from './objects';

export const OTHER_OPTION = {
  option: 'Other',
  follow_up: false,
  show_as_other: false,
  has_follow_up: true,
  follow_up_title: 'Other selections',
  follow_up_subtitle: 'Please select all that apply.',
  follow_up_placeholder: 'Other',
};

export const MAX_LENGTH_FOLLOW_UP = 30;
export const MAX_LENGTH_OTHER = 50;

export const CTA_STATES = {
  CLOSE: 'close',
  DONE: 'done',
  NEXT: 'next',
};

export const getFormattedSurveyData = (surveyQuestions, surveyOptions) => {
  const formattedData = {};
  surveyOptions.forEach((option) => {
    const questionID = option.question_id;
    const optionsForQuestion = formattedData[questionID] || [];

    if (isObjectEmpty(option.following)) {
      optionsForQuestion.push(option);
    }
    formattedData[questionID] = optionsForQuestion;
  });

  surveyQuestions.forEach((question) => {
    if (question.is_randomized) {
      formattedData[question.id] = _shuffle(formattedData[question.id]);
    } else if (question.reverse_chance > 0) {
      const chance = Math.random() * 100;
      if (chance < question.reverse_chance) {
        formattedData[question.id].reverse();
      }
    }
    if (question.has_other) {
      formattedData[question.id].push(OTHER_OPTION);
    }

    const currentQuestionData = formattedData[question.id];

    const formattedWithFollowUps = currentQuestionData.map((option) => {
      const followUpOptions = getFollowUpOptions(surveyOptions, option);
      if (option.has_follow_up) {
        option.followUps = option.follow_up_randomized
          ? _shuffle(followUpOptions)
          : followUpOptions;
      }

      return option;
    });

    formattedData[question.id] = formattedWithFollowUps;
  });

  return formattedData;
};

export const getFollowUpOptions = (surveyOptions, option) => {
  const followUpOptions = surveyOptions.filter(
    (surveyOption) => surveyOption.following === option.option
  );
  return followUpOptions;
};

export const getSelectedFollowUps = (questionOptions, selectedOptions) => {
  const selectedFollowUps = questionOptions.filter((option) => {
    return selectedOptions.includes(option) && option.has_follow_up;
  });
  return selectedFollowUps;
};

export const getNextStep = (
  surveyData,
  currentStep,
  currentQuestion,
  selectedOptions
) => {
  const currentQuestionOptions = surveyData[currentQuestion.id];
  const selectedFollowUps = getSelectedFollowUps(
    currentQuestionOptions,
    selectedOptions
  );
  if (!selectedFollowUps || selectedFollowUps.length < 1) {
    return null;
  }
  if (currentStep === currentQuestion.id) {
    return selectedFollowUps[0].option;
  }
  const stepIndex = selectedFollowUps.findIndex(
    (option) => option.option === currentStep
  );
  if (stepIndex + 1 < selectedFollowUps.length) {
    return selectedFollowUps[stepIndex + 1].option;
  }
  return null;
};

export const getNextQuestion = (surveyQuestions, currentQuestion) => {
  const index = surveyQuestions.findIndex((question) => {
    return question.id === currentQuestion.id;
  });

  if (index < surveyQuestions.length - 1) {
    return surveyQuestions[index + 1];
  }
  return null;
};

export const getQuestionStepFormValues = (surveyData, question, step) => {
  const questionStepOptions = surveyData[question.id];

  if (step === question.id) {
    return {
      options: questionStepOptions,
      title: question.title,
      subtitle: question.subtitle,
      textInputProps: question.has_input
        ? {
            maxLength: MAX_LENGTH_FOLLOW_UP,
            placeholder: question.input_placeholder,
          }
        : undefined,
    };
  }

  const optionForStep = questionStepOptions.find(
    (option) => option.option === step
  );

  if (optionForStep && optionForStep.has_follow_up) {
    return {
      options: optionForStep.followUps,
      title: optionForStep.follow_up_title,
      subtitle: optionForStep.follow_up_subtitle,
      textInputProps: {
        maxLength:
          optionForStep === OTHER_OPTION
            ? MAX_LENGTH_OTHER
            : MAX_LENGTH_FOLLOW_UP,
        placeholder: optionForStep.follow_up_placeholder,
      },
    };
  }

  return null;
};

export const isLastStep = (
  surveyData,
  surveyQuestions,
  currentStep,
  currentQuestion,
  selectedOptions
) => {
  const nextStep = getNextStep(
    surveyData,
    currentStep,
    currentQuestion,
    selectedOptions
  );

  const nextQuestion = getNextQuestion(surveyQuestions, currentQuestion);

  return !nextStep && !nextQuestion;
};

const isOptionSelected = (selectedOptions, option) => {
  return !!selectedOptions.find((selected) => {
    return (
      selected.option === option.option &&
      selected.following === option.following
    );
  });
};

const getOptionSnakeCaseTitle = (option) => {
  const optionTitle = option.following
    ? `${option.following} ${option.option}`
    : option.option;
  return _snakeCase(optionTitle);
};

export const getSurveyResponsePayload = (
  surveyData,
  question,
  selectedOptions,
  inputFields
) => {
  const questionOptions = surveyData[question.id].reduce((options, option) => {
    options.push(option);
    if (!isObjectEmpty(option.followUps)) {
      options.push(...option.followUps);
    }
    return options;
  }, []);

  const formattedQuestionOptions = questionOptions.map(getOptionSnakeCaseTitle);

  const response = {};
  questionOptions.forEach((option) => {
    const formattedOption = getOptionSnakeCaseTitle(option);
    const data = {
      selected: isOptionSelected(selectedOptions, option),
    };

    if (option.has_follow_up) {
      const input = inputFields[option.option];
      data.input = input;
    }
    response[formattedOption] = data;
  });
  if (question.has_input) {
    const questionInput = {
      selected: true,
      input: inputFields[question.id],
    };
    response[_snakeCase(question.id)] = questionInput;
  }

  return {
    question: question.title,
    possible_answers: JSON.stringify(formattedQuestionOptions),
    response: JSON.stringify(response),
  };
};
