export default class Author {
  constructor({
    first_name = 'Jackie',
    last_name = 'Berkery',
    email = 'jackie.berkery@gametime.co',
    slug = 'jackie-berkery',
    bio = '',
    title = '',
    profile_image = 'https://cdn.buttercms.com/8EOJiPl6T2O2AvAPJDnH',
  }) {
    this.firstName = first_name;
    this.lastName = last_name;
    this.email = email;
    this.slug = slug;
    this.bio = bio;
    this.title = title;
    this.profileImage = profile_image;
  }
}
