import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button, { TYPES } from 'components/Buttons/SimpleButton';
import CardDetails from 'components/CardDetails/CardDetails';
import GTAnimationModal from 'components/GTAnimationModal';
import Link from 'components/Link/Link';
import Spinner, { VARIANTS } from 'components/Spinner/Spinner';

import styles from './EditCardModal.module.scss';

const EditCardModal = ({
  cards,
  isOpen = false,
  onClose,
  deleteCard,
  clickTracker,
}) => {
  const [isLoading, setLoading] = useState(false);

  const handleDelete = (token) => {
    setLoading(true);
    deleteCard(token).finally(() => {
      setLoading(false);
      if (Object.keys(cards).length <= 1) {
        onClose();
      }
    });
  };

  return (
    <GTAnimationModal
      headerTheme="dark"
      className="edit-card-modal"
      show={isOpen}
      onHide={onClose}
      headerTitle="Edit Payment Method"
    >
      <div
        className={classNames(styles['card-list'], {
          [styles.loading]: isLoading,
        })}
      >
        {Object.values(cards).map((card) => {
          return (
            <div key={card.token} className={styles['card-item']}>
              <CardDetails card={card} />

              <Link
                className={styles['delete-button']}
                clickTracker={clickTracker}
                onClick={() => handleDelete(card)}
                disabled={isLoading}
              >
                Delete
              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles['edit-card-footer']}>
        <Button
          fullWidth
          disabled={isLoading}
          onClick={onClose}
          text="save"
          type={TYPES.GREEN_SOLID}
        />
      </div>

      {isLoading && (
        <Spinner
          style={{ height: 120 }}
          format={VARIANTS.GAMETIME_GREEN_LIGHT}
        />
      )}
    </GTAnimationModal>
  );
};

EditCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cards: PropTypes.object,
  deleteCard: PropTypes.func.isRequired,
  clickTracker: PropTypes.object,
};

export default EditCardModal;
