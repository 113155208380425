import React from 'react';
import classNames from 'classnames';

import styles from './IconSprite.module.scss';

export const IconTypes = [
  'best',
  'cheapest',
  'flash',
  'zone',
  'rare',
  'great_view',
  'dollar',
  'tag',
  'super',
  'amazing',
  'great',
  'zone_exclusives',
] as const;
type IconType = (typeof IconTypes)[number];

/**
 * type predicate function to narrow `string` down to `IconType`
 */
function isIconType(iconType: string): iconType is IconType {
  return IconTypes.includes(iconType as IconType);
}

export type Props = {
  iconType: string;
  variant?: 'color' | 'black';
};

const IconSprite = ({ iconType, variant = 'black' }: Props) => {
  if (!isIconType(iconType)) {
    return null;
  }

  return (
    <div
      data-testid="icon-sprite"
      className={classNames(styles['icon-sprite'], styles[`${iconType}-icon`], {
        [styles[`${iconType}-icon-color`]]: variant === 'color',
      })}
    />
  );
};

export default React.memo(IconSprite);
