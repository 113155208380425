import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './DarkThemedFormButtonLoader.module.scss';

const DarkThemedFormButtonLoader = ({ showBackground, ...otherProps }) => (
  <div
    className={classNames(styles['dark-button-container'], {
      [styles.background]: showBackground,
    })}
  >
    <ButtonLoader
      className={styles['dark-themed-form-button']}
      {...otherProps}
    />
  </div>
);

DarkThemedFormButtonLoader.propTypes = {
  showBackground: PropTypes.bool,
};

export default DarkThemedFormButtonLoader;
