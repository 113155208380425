import React, { Component } from 'react';
import classNames from 'classnames';

import { LogoLarge, LogoSmall } from 'logos';

import styles from './Unavailable.module.scss';

class Unavailable extends Component {
  render() {
    return (
      <div className={styles.unavailable}>
        <div className={styles['logo-container']}>
          <LogoSmall
            className={classNames(styles.logo, styles['logo-mobile'])}
          />
          <LogoLarge
            className={classNames(styles.logo, styles['logo-desktop'])}
          />
        </div>
        <div className={styles.text}>
          Sorry, Gametime does not support purchasing for events in your
          country.
        </div>
      </div>
    );
  }
}

export default Unavailable;
