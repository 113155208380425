export const NCAA_CATEGORY = 'ncaa';
export const NBA_CATEGORY = 'nba';
export const OTHER_CATEGORY = 'other';

export const API_SPORTS_SUB_CATEGORIES = ['mlb', 'nba', 'nfl', 'nhl', 'mls'];
export const STATIC_SPORTS_SUB_CATEGORIES = [NCAA_CATEGORY, OTHER_CATEGORY];

export const SPORTS_SUB_CATEGORIES = API_SPORTS_SUB_CATEGORIES.concat(
  STATIC_SPORTS_SUB_CATEGORIES
);
export const MAX_PERFORMERS_MOBILE = 40;
export const MAX_PERFORMERS_DESKTOP = 42;

export const NON_NBA_TEAMS = [
  'nbabig3',
  'nbaad',
  'nbaguang',
  'nbamaccabi',
  'nbamlb',
  'nbanzb',
  'nbasesi',
  'nbasla',
  'nbashang',
  'nbaspain',
  'nbasyd',
];

export const CATEGORIES_DROPDOWN = {
  SPORTS: 'sports',
  MUSIC: 'music',
  THEATER: 'theater',
};

export const SHOWS_SUB_CATEGORIES = ['comedy', 'theater'];
