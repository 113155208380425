import { createSelector } from 'reselect';

import { VenueContent } from 'models';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _selectVenuesContent = (state) => state.content.venues;

const _selectVenueContentBySlug = (state, slug) =>
  _selectVenuesContent(state)[slug];

/* Returns Models */
export const selectVenueContentBySlug = createSelector(
  _selectVenueContentBySlug,
  (venueContentObject) =>
    venueContentObject ? new VenueContent(venueContentObject) : null
);
