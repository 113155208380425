import { PAYLOAD } from 'analytics/constants';

type ListingTrackingData = {
  dealType: string;
  featuredType: string;
  isPromo: boolean;
  isSponsored: boolean;
  seatAttributes: string;
};

export const mapListingTrackingData = (
  listingTrackingData: ListingTrackingData
) => ({
  [PAYLOAD.DEAL_TYPE]: listingTrackingData.dealType,
  [PAYLOAD.FEATURED_TYPE]: listingTrackingData.featuredType,
  [PAYLOAD.IS_PROMO]: listingTrackingData.isPromo,
  [PAYLOAD.IS_SPONSORED]: listingTrackingData.isSponsored,
  [PAYLOAD.SEAT_ATTRIBUTES]: listingTrackingData.seatAttributes,
});
