import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'fill' | 'aria-hidden'
>;

export default function WarningCircleFillIcon({
  width = '24',
  height = '24',
  fill = colors.white,
  'aria-hidden': ariaHidden = true,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      aria-hidden={ariaHidden}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="warning-circle-fill-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47725 2 2 6.47726 2 12C2 17.5227 6.47725 22 12 22C17.5228 22 22 17.5227 22 12C22 6.47726 17.5228 2 12 2ZM11.0709 13.7065L10.8906 6.99482H13.1094L12.9222 13.7065H11.0709ZM12 17.208C11.2234 17.208 10.7381 16.7573 10.7381 16.0293C10.7381 15.2943 11.2234 14.8437 12 14.8437C12.7835 14.8437 13.2619 15.2943 13.2619 16.0293C13.2619 16.7573 12.7835 17.208 12 17.208Z"
        fill={fill}
      />
    </svg>
  );
}
