import React from 'react';

import { CategoryClickTracker, PerformerClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import { ChevronIcon } from 'icons';

import styles from './LinksSection.module.scss';

interface CategoryLink {
  title: string;
  to: string;
  clickTracker?: CategoryClickTracker | PerformerClickTracker;
}

export interface CategoryLinksSection {
  title: string;
  links: CategoryLink[];
}

const LinksSection = ({ title, links }: CategoryLinksSection) => {
  return (
    <>
      <span className={styles['links-section-title']}>{title}</span>
      {links.map((link) => {
        return (
          <Link
            className={styles['link']}
            key={link.title}
            to={link.to}
            clickTracker={link.clickTracker}
          >
            <span className={styles['link-title']}>{link.title}</span>
            <ChevronIcon
              width={16}
              height={16}
              direction="right"
              strokeWidth={1.5}
            />
          </Link>
        );
      })}
    </>
  );
};

export default LinksSection;
