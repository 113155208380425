import { useCallback, useEffect } from 'react';

const useResize = (cb) => {
  const handleResize = useCallback(cb, [cb]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};

export default useResize;
