import React from 'react';

export default (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.0627 22.7565C41.9869 22.6559 40.1704 20.2665 37.0827 17.8867C32.9346 14.6898 28.4107 13 24 13C19.5891 13 15.0653 14.6898 10.9174 17.8867C7.82025 20.2736 6.01234 22.6567 5.93734 22.7566L5.68973 23.085C5.28142 23.6267 5.28142 24.3733 5.68973 24.915L5.93701 25.243C6.01234 25.3432 7.82025 27.7264 10.9174 30.1133C15.0653 33.3102 19.5891 35 24 35C28.4107 35 32.9346 33.3102 37.0827 30.1133C40.1705 27.7334 41.9869 25.3441 42.0627 25.2435L42.3104 24.9149C42.7187 24.3733 42.7186 23.6267 42.3104 23.0851L42.0627 22.7565ZM41.0634 24.4143C40.9919 24.5091 39.2791 26.7617 36.3283 29.0362C32.4005 32.0633 28.1375 33.6634 24 33.6634C19.8625 33.6634 15.5994 32.0634 11.6719 29.0362C8.71205 26.7552 7.00815 24.5094 6.93676 24.4144C6.75179 24.1691 6.75213 23.8305 6.9371 23.5852C7.00815 23.4907 8.71196 21.2449 11.6718 18.9639C15.5993 15.9367 19.8624 14.3366 24 14.3366C28.1373 14.3366 32.4004 15.9367 36.3282 18.9639C39.2792 21.2383 40.9919 23.4909 41.0632 23.5856C41.2482 23.8308 41.2483 24.169 41.0634 24.4143Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M42.0627 22.7565C41.9869 22.6559 40.1704 20.2665 37.0827 17.8867C32.9346 14.6898 28.4107 13 24 13C19.5891 13 15.0653 14.6898 10.9174 17.8867C7.82025 20.2736 6.01234 22.6567 5.93734 22.7566L5.68973 23.085C5.28142 23.6267 5.28142 24.3733 5.68973 24.915L5.93701 25.243C6.01234 25.3432 7.82025 27.7264 10.9174 30.1133C15.0653 33.3102 19.5891 35 24 35C28.4107 35 32.9346 33.3102 37.0827 30.1133C40.1705 27.7334 41.9869 25.3441 42.0627 25.2435L42.3104 24.9149C42.7187 24.3733 42.7186 23.6267 42.3104 23.0851L42.0627 22.7565ZM41.0634 24.4143C40.9919 24.5091 39.2791 26.7617 36.3283 29.0362C32.4005 32.0633 28.1375 33.6634 24 33.6634C19.8625 33.6634 15.5994 32.0634 11.6719 29.0362C8.71205 26.7552 7.00815 24.5094 6.93676 24.4144C6.75179 24.1691 6.75213 23.8305 6.9371 23.5852C7.00815 23.4907 8.71196 21.2449 11.6718 18.9639C15.5993 15.9367 19.8624 14.3366 24 14.3366C28.1373 14.3366 32.4004 15.9367 36.3282 18.9639C39.2792 21.2383 40.9919 23.4909 41.0632 23.5856C41.2482 23.8308 41.2483 24.169 41.0634 24.4143Z"
      stroke={props.color || '#fff'}
    />
    <path
      d="M24 17C20.1402 17 17 20.1401 17 24C17 27.8599 20.1402 31 24 31C27.8598 31 31 27.8598 31 24C31 20.1402 27.8598 17 24 17ZM24 29.7392C20.8355 29.7392 18.2607 27.1646 18.2607 23.9999C18.2607 20.8352 20.8355 18.2606 24 18.2606C27.1646 18.2606 29.7394 20.8352 29.7394 23.9999C29.7394 27.1646 27.1646 29.7392 24 29.7392Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M24 17C20.1402 17 17 20.1401 17 24C17 27.8599 20.1402 31 24 31C27.8598 31 31 27.8598 31 24C31 20.1402 27.8598 17 24 17ZM24 29.7392C20.8355 29.7392 18.2607 27.1646 18.2607 23.9999C18.2607 20.8352 20.8355 18.2606 24 18.2606C27.1646 18.2606 29.7394 20.8352 29.7394 23.9999C29.7394 27.1646 27.1646 29.7392 24 29.7392Z"
      stroke={props.color || '#fff'}
    />
    <path
      d="M23.6475 28H24.3848V27.1705C25.7673 27.0507 26.3848 26.2212 26.3848 25.2811C26.3848 24.0461 25.3433 23.6866 24.3848 23.4378V21.8433C24.8364 21.9355 25.2788 22.1475 25.629 22.4977L26.2373 21.6959C25.7581 21.235 25.1313 20.9401 24.3848 20.8571V20H23.6475V20.8479C22.4585 20.9493 21.7028 21.6959 21.7028 22.6359C21.7028 23.8341 22.6982 24.1751 23.6475 24.4147V26.2028C22.9839 26.1014 22.4493 25.7512 22.1083 25.3733L21.5 26.212C21.9885 26.7189 22.6982 27.0968 23.6475 27.1705V28ZM25.2788 25.3825C25.2788 25.7419 25.0207 26.1198 24.3848 26.212V24.6083C24.8917 24.765 25.2788 24.9677 25.2788 25.3825ZM22.8088 22.553C22.8088 22.1475 23.1313 21.8618 23.6475 21.7972V23.2442C23.159 23.0968 22.8088 22.9124 22.8088 22.553Z"
      fill={props.color || '#fff'}
    />
  </svg>
);
