/**
 * Takes a string and hashes it with the given algorithm, or SHA-1 if not specified. The
 * function then returns a Promise with a string that is the hashed, hex string of the
 * input string.
 *
 * Usage: hash(string).then(stringHex => console.log(stringHex))
 *
 * Based on: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 *
 * @param stringToHash the string to hash with the given algorithm
 * @param algorithm the algorithm to hash the string with, SHA-1 by default
 * @returns a Promise with a hashed, hex string
 */
export const hash = async (
  stringToHash: string,
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512' = 'SHA-1'
): Promise<string> => {
  if (typeof window === 'undefined') {
    throw new Error('hash() cannot be used outside of a browser environment.');
  }

  const msgUint8 = new TextEncoder().encode(stringToHash); // encode as (utf-8) Uint8Array
  const hashBuffer = await window.crypto.subtle.digest(algorithm, msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  return hashHex;
};
