import { initialSetup, initialState } from '../constants/state.constants';

export const createState = ({
  initialScale = initialState.scale,
  initialPositionX = initialState.positionX,
  initialPositionY = initialState.positionY,
}) => {
  return {
    previousScale: initialScale,
    scale: initialScale,
    positionX: initialPositionX,
    positionY: initialPositionY,
    interaction: {
      eventType: initialState.interaction.eventType,
      zoomType: initialState.interaction.zoomType,
    },
  };
};

export const createSetup = (props) => {
  const newSetup = { ...initialSetup };

  Object.keys(props).forEach((key) => {
    const validValue = typeof props[key] !== 'undefined';
    const validParameter = typeof initialSetup[key] !== 'undefined';
    if (validParameter && validValue) {
      const dataType = Object.prototype.toString.call(initialSetup[key]);
      const isObject = dataType === '[object Object]';
      const isArray = dataType === '[object Array]';
      if (isObject) {
        newSetup[key] = { ...initialSetup[key], ...props[key] };
      } else if (isArray) {
        newSetup[key] = [...initialSetup[key], ...props[key]];
      } else {
        newSetup[key] = props[key];
      }
    }
  });

  return newSetup;
};
