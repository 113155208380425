import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PerformersInContextCarousel from 'components/PerformersInContextCarousel/PerformersInContextCarousel';
import { CATEGORIES } from 'store/modules/categories/category.helpers';
import { makeGetSelectPerformersByCategory } from 'store/modules/data/Performers/selectors';
import { generatePerformerLinks } from 'store/modules/performers/performers.helpers';
import { ascSort } from 'utils/sorts';

import SimplePerformersSection from './components/SimplePerformersSection';
import TextPerformerGroupings from './components/TextPerformerGroupings';
import { getPerformersByDivision } from './components/utils';
import {
  DIVISION_NAMES,
  DIVISIONS_BY_CATEGORY,
  DIVISIONS_ORDERED_KEYS_BY_CATEGORY,
} from './constants';

import styles from './SportsPerformers.module.scss';

function _SportsPerformers(props) {
  const {
    performersInContextByCategory = [],
    performers,
    category,
    currentMetro,
  } = props;

  const nearbyPerformers = [];

  performersInContextByCategory.forEach((pic) => {
    const {
      nextFullEvent: {
        venue: { metro },
      },
    } = pic;

    if (metro === currentMetro.id) {
      nearbyPerformers.push(pic);
    }
  });

  // season of the specific sport might be over...so PIC might be empty
  // we still want to show all teams within their divisions
  const performerSlugsMap = { ...DIVISIONS_BY_CATEGORY[category] };
  const keysArray = Object.keys(performerSlugsMap);
  keysArray.forEach((key) => {
    performerSlugsMap[key] = false;
  });

  const prfmrs = performersInContextByCategory
    .map((pic) => {
      // only add teams in the slugs map
      if (
        !performerSlugsMap[pic.performer.slug] &&
        performerSlugsMap[pic.performer.slug] === false
      ) {
        performerSlugsMap[pic.performer.slug] = true;
        return pic.performer;
      }
      return null;
    })
    .filter((a) => !!a);

  if (prfmrs.length !== keysArray.length) {
    performers.forEach((p) => {
      // it's one of the teams we care about and not added yet
      if (!performerSlugsMap[p.slug] && performerSlugsMap[p.slug] === false) {
        prfmrs.push(p);
      }
    });
  }

  ascSort(prfmrs, 'name');

  const performersByDivision = getPerformersByDivision(prfmrs, category);

  const linksByDivisionCategory = DIVISIONS_ORDERED_KEYS_BY_CATEGORY[
    category
  ].map((key) => ({
    title: DIVISION_NAMES[key],
    links: generatePerformerLinks(performersByDivision[key]) || [],
  }));

  const groupsHeading = `All ${category.toUpperCase()} Teams`;

  return (
    <div className={styles['sports-performers-container']}>
      <SimplePerformersSection
        performers={nearbyPerformers}
        title="Top Teams Nearby"
      />
      {nearbyPerformers.length > 0 && category === CATEGORIES.NFL && (
        <PerformersInContextCarousel
          carouselTitle="Events Near You"
          performersInContext={nearbyPerformers}
          sectionIndex={0}
        />
      )}
      <TextPerformerGroupings
        heading={groupsHeading}
        linkGroups={linksByDivisionCategory}
      />
    </div>
  );
}

_SportsPerformers.propTypes = {
  performersInContextByCategory: PropTypes.array.isRequired,
  performers: PropTypes.array.isRequired,
  currentMetro: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const selectPerformersByCategory = makeGetSelectPerformersByCategory();

  return {
    performers: selectPerformersByCategory(state, props.category),
  };
}

export default connect(mapStateToProps)(_SportsPerformers);
