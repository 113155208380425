import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import GTSurveyModal from 'components/GTSurveyModal/GTSurveyModal';
import {
  activeModalPropsSelector,
  activeModalSelector,
  hideModal,
  initActiveModalFromLocation,
  MODALS,
} from 'store/modules/modals/modals';

import ErrorModal from './ErrorModal/ErrorModal';
import FlashDealModal from './FlashDealModal/FlashDealModal';
import GetAppModal from './GetAppModal/GetAppModal';
import LoginModal from './LoginModal/LoginModal';
import PriceBreakdownModal from './PriceBreakdownModal/PriceBreakdownModal';
import SuperBowlModal from './SuperBowlModal/SuperBowlModal';
import { ZoneDealsInfoModal } from './ZoneDealModal/ZoneDealsInfoModal';

class ModalManager extends Component {
  static propTypes = {
    activeModal: PropTypes.string,
    modalProps: PropTypes.object,
    hideModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      closingModal: '',
    };
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(initActiveModalFromLocation(this.props.query));
    if (typeof window !== 'undefined') {
      // Hides open survey modal if user navigates
      window.onpopstate = () => {
        const { activeModal, hideModal } = this.props;
        if (activeModal) hideModal(activeModal);
      };
    }
  }

  onHide(modal) {
    return () => {
      this.setState({ closingModal: modal }, () => {
        this.props.hideModal(modal);
        // Give the modal time to run its fade out animation before removing the component.
        setTimeout(() => {
          this.setState({ closingModal: '' });
        }, 500);
      });
    };
  }

  render() {
    const { activeModal, modalProps } = this.props;
    return (
      <div>
        {(activeModal === MODALS.GET_APP ||
          this.state.closingModal === MODALS.GET_APP) && (
          <GetAppModal
            show={activeModal === MODALS.GET_APP}
            onHide={this.onHide(MODALS.GET_APP)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.PRICE_BREAKDOWN ||
          this.state.closingModal === MODALS.PRICE_BREAKDOWN) && (
          <PriceBreakdownModal
            show={activeModal === MODALS.PRICE_BREAKDOWN}
            onHide={this.onHide(MODALS.PRICE_BREAKDOWN)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.ERROR ||
          this.state.closingModal === MODALS.ERROR) && (
          <ErrorModal
            show={activeModal === MODALS.ERROR}
            onHide={this.onHide(MODALS.ERROR)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.LOGIN ||
          this.state.closingModal === MODALS.LOGIN) && (
          <LoginModal
            show={activeModal === MODALS.LOGIN}
            onHide={this.onHide(MODALS.LOGIN)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.SUPER_BOWL ||
          this.state.closingModal === MODALS.SUPER_BOWL) && (
          <SuperBowlModal
            show={activeModal === MODALS.SUPER_BOWL}
            onHide={this.onHide(MODALS.SUPER_BOWL)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.ZONE_DEAL_INFO ||
          this.state.closingModal === MODALS.ZONE_DEAL_INFO) && (
          <ZoneDealsInfoModal
            show={activeModal === MODALS.ZONE_DEAL_INFO}
            onHide={this.onHide(MODALS.SUPER_BOWL)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.FLASH_DEAL_INFO ||
          this.state.closingModal === MODALS.FLASH_DEAL_INFO) && (
          <FlashDealModal
            show={activeModal === MODALS.FLASH_DEAL_INFO}
            onHide={this.onHide(MODALS.FLASH_DEAL_INFO)}
            {...modalProps}
          />
        )}
        {(activeModal === MODALS.POST_PURCHASE_SURVEY ||
          this.state.closingModal === MODALS.POST_PURCHASE_SURVEY) && (
          <GTSurveyModal
            show={activeModal === MODALS.POST_PURCHASE_SURVEY}
            onHide={this.onHide(MODALS.POST_PURCHASE_SURVEY)}
            {...modalProps}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeModal: activeModalSelector(state),
  modalProps: activeModalPropsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalManager)
);
