import { combineReducers } from 'redux';

import app from './app/app';
import uiReducer from './app/app.ui';
import blog from './blog/blogReducer';
import content from './content/contentReducer';
import data from './data/dataReducer';
import dataFilters from './data/filtersReducer';
import genrePerformers from './genrePerformers/genrePerformers';
import listings from './listings/reducer';
import modals from './modals/modals';
import resources from './resources/resource';
import seatmap from './seatmap/seatmap';
import user from './user/user';
import userPreference from './userPreference/userPreference';
import userPurchases from './userPurchases/userPurchases';

const rootReducer = combineReducers({
  user,
  userPurchases,
  app,
  resources,
  userPreference,
  uiReducer,
  listings,
  modals,
  seatmap,
  genrePerformers,
  data,
  dataFilters,
  content,
  blog,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
