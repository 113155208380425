export const PURCHASE_PATH = 'buy';

export const PURCHASE_STEPS = {
  // pre-purchase:
  USER_ZIP: 'user-zip',
  USER_TRANSFER: 'user-transfer',
  USER_TEXT: 'user-text',
  SECURE: 'secure',
  // post-purchase:
  USER_PHONE: 'user-phone',
  USER_VERIFY: 'user-verify',
  USER_ADDRESS: 'user-address',
};

export const INFO_COLLECTION_TEXT = {
  DEFAULT: {
    title: 'Contact Info',
    desc: "You'll receive your tickets via email outside of Gametime. Let us know where to send them.",
  },
  MLB: {
    title: 'MLB Account Info',
    desc: 'We’ll use this information to deliver your tickets from MLB to the Gametime app.',
  },
};
