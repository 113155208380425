import React from 'react';
import classNames from 'classnames';

import { formatPriceWithComma, isInteger, round } from 'utils/number';

import styles from './TicketPrice.module.scss';

interface TicketPriceProps {
  className?: string;
  price: number;
  currencyPrefix?: string;
  showEachSuffix?: boolean;
  isPromoApplied?: boolean;
  isPriceCrossedOut?: boolean;
}

const TicketPrice = ({
  className,
  price,
  currencyPrefix = '$',
  showEachSuffix = false,
  isPromoApplied = false,
  isPriceCrossedOut = false,
}: TicketPriceProps) => {
  if (!price) return null;

  const formattedPrice = round(price);
  const hasFixedDigits = !isInteger(formattedPrice); // If not integer, round to 2 decimal points (12.00)

  return (
    <span
      className={classNames(className, {
        [styles.promo]: isPromoApplied,
        [styles['crossed-out']]: isPriceCrossedOut,
      })}
    >
      {`${currencyPrefix}${formatPriceWithComma(
        formattedPrice,
        hasFixedDigits
      )}`}
      {showEachSuffix && (
        <span
          className={classNames(styles.suffix, {
            [styles.promo]: isPromoApplied,
          })}
        >
          /ea
        </span>
      )}
    </span>
  );
};

export default TicketPrice;
