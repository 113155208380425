import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Image from 'components/Image/Image';
import ImagePan from 'components/ImagePan/ImagePan';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { selectIsWebDetailsStackedExperiment } from 'experiments';
import { device, useMediaQuery } from 'hooks';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';

import styles from './ListingHero.module.scss';

const ListingHero = ({
  fullEvent,
  listing,
  showZoneTag,
  isWebDetailsStackedExperiment,
}) => {
  const isMobile = useMediaQuery(device.down.md);

  const imagePropsForOriginListing = listing.getImageOptions(fullEvent.venue);

  return (
    <div className={styles['listing-hero']}>
      <div
        className={classNames(styles.image, {
          [styles['is-details-stacked-experiment']]:
            isWebDetailsStackedExperiment,
        })}
      >
        {!isMobile ? (
          <ImagePan imageProps={imagePropsForOriginListing} />
        ) : (
          <Image {...imagePropsForOriginListing} />
        )}
      </div>
      {showZoneTag && (
        <div className={styles['zone-tag-container']}>
          <ZoneTag />
        </div>
      )}
    </div>
  );
};

ListingHero.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  listing: PropTypes.instanceOf(Listing).isRequired,
  showZoneTag: PropTypes.bool,
  isWebDetailsStackedExperiment: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isWebDetailsStackedExperiment: selectIsWebDetailsStackedExperiment(state),
});

export default connect(mapStateToProps)(ListingHero);
