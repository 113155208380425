import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function CheckSquareSelectedIcon({
  width = '24',
  height = '24',
  fill = colors.gametimeGreen,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      data-testid="check-square-selected-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM15.1893 8.39646L10.25 13.3358L8.31061 11.3965C8.11535 11.2012 7.79877 11.2012 7.60351 11.3965L6.8964 12.1036C6.70114 12.2988 6.70114 12.6154 6.8964 12.8107L9.54285 15.4571C9.93337 15.8476 10.5665 15.8476 10.9571 15.4571L16.6035 9.81067C16.7988 9.61541 16.7988 9.29883 16.6035 9.10357L15.8964 8.39646C15.7011 8.2012 15.3846 8.2012 15.1893 8.39646Z"
        fill={fill}
      />
    </svg>
  );
}
