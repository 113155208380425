const FULL_EVENT_TYPES = {
  NEAR: 'nearbyPerformerEvents',
  ALL: 'allPerformerEvents',
  VENUE: 'venueEvents',
};

export const EVENT_TYPES = {
  NEAR: {
    headline: 'Top Events Nearby',
    eventGroup: 'nearby',
    eventType: FULL_EVENT_TYPES.NEAR,
    eventSlug: 'performerSlug',
  },
  ALL: {
    headline: 'All Events',
    eventGroup: 'all',
    eventType: FULL_EVENT_TYPES.ALL,
    eventSlug: 'performerSlug',
  },
  VENUE: {
    headline: 'Venue Events',
    eventGroup: 'venue',
    eventType: FULL_EVENT_TYPES.VENUE,
    eventSlug: 'venueSlug',
  },
};
