import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height'>;

export default function DealStarIcon({ width = '32', height = '32' }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 172 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddi_5456_40337)">
        <path
          d="M81.6478 15.0024C83.1506 14.362 84.8494 14.362 86.3522 15.0024L106.532 23.602L126.883 31.7907C128.398 32.4005 129.6 33.6018 130.209 35.1172L138.398 55.4676L146.998 75.6478C147.638 77.1506 147.638 78.8494 146.998 80.3522L138.398 100.532L130.209 120.883C129.6 122.398 128.398 123.6 126.883 124.209L106.532 132.398L86.3522 140.998C84.8494 141.638 83.1506 141.638 81.6478 140.998L61.4676 132.398L41.1172 124.209C39.6018 123.6 38.4005 122.398 37.7907 120.883L29.602 100.532L21.0024 80.3522C20.362 78.8494 20.362 77.1506 21.0024 75.6478L29.602 55.4676L37.7907 35.1172C38.4005 33.6018 39.6018 32.4005 41.1172 31.7907L61.4676 23.602L81.6478 15.0024Z"
          fill="url(#paint0_linear_5456_40337)"
        />
      </g>
      <g filter="url(#filter1_ii_5456_40337)">
        <path
          d="M83.2159 27.3341C83.7169 27.1207 84.2831 27.1207 84.7841 27.3341L101.956 34.6516L119.272 41.6194C119.777 41.8227 120.177 42.2231 120.381 42.7282L127.348 60.0445L134.666 77.2159C134.879 77.7169 134.879 78.2831 134.666 78.7841L127.348 95.9555L120.381 113.272C120.177 113.777 119.777 114.177 119.272 114.381L101.956 121.348L84.7841 128.666C84.2831 128.879 83.7169 128.879 83.2159 128.666L66.0445 121.348L48.7282 114.381C48.2231 114.177 47.8227 113.777 47.6194 113.272L40.6516 95.9555L33.3341 78.7841C33.1207 78.2831 33.1207 77.7169 33.3341 77.2159L40.6516 60.0445L47.6194 42.7282C47.8227 42.2231 48.2231 41.8227 48.7282 41.6194L66.0445 34.6516L83.2159 27.3341Z"
          fill="url(#paint1_linear_5456_40337)"
        />
      </g>
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.7839 27.3341C84.2829 27.1206 83.7167 27.1206 83.2157 27.3341L66.0443 34.6516L48.728 41.6194C48.2229 41.8226 47.8225 42.2231 47.6192 42.7282L40.6514 60.0445L33.3339 77.2159C33.1205 77.7168 33.1205 78.2831 33.3339 78.784L40.6514 95.9555L47.6192 113.272C47.7448 113.584 47.9457 113.856 48.2001 114.066L120.066 42.2003C119.856 41.9459 119.584 41.745 119.272 41.6194L101.955 34.6516L84.7839 27.3341Z"
        fill="white"
      />
      <g filter="url(#filter2_di_5456_40337)">
        <path
          d="M79.9843 47.5259C81.6022 44.158 86.3978 44.158 88.0157 47.5259L94.3088 60.6253C94.9583 61.9772 96.2443 62.9115 97.7307 63.1115L112.134 65.0486C115.837 65.5467 117.319 70.1075 114.616 72.687L104.102 82.72C103.017 83.7554 102.526 85.2673 102.795 86.7427L105.403 101.039C106.074 104.715 102.194 107.534 98.9057 105.76L86.1148 98.8614C84.7948 98.1495 83.2052 98.1495 81.8852 98.8614L69.0943 105.76C65.8057 107.534 61.9261 104.715 62.5967 101.039L65.2051 86.7427C65.4743 85.2673 64.9831 83.7554 63.8981 82.72L53.3845 72.6869C50.6814 70.1075 52.1633 65.5467 55.8663 65.0486L70.2693 63.1115C71.7557 62.9115 73.0417 61.9772 73.6912 60.6253L79.9843 47.5259Z"
          fill="url(#paint2_linear_5456_40337)"
        />
      </g>
      <g filter="url(#filter3_d_5456_40337)">
        <path
          d="M37.7722 19.7311C37.9448 19.0898 38.8548 19.0898 39.0275 19.7311L41.6118 29.3294C41.672 29.5531 41.8467 29.7278 42.0704 29.788L51.6687 32.3723C52.31 32.545 52.31 33.455 51.6687 33.6276L42.0704 36.212C41.8467 36.2722 41.672 36.4469 41.6118 36.6706L39.0275 46.2689C38.8548 46.9102 37.9448 46.9102 37.7722 46.2689L35.1878 36.6706C35.1276 36.4469 34.9529 36.2722 34.7292 36.212L25.1309 33.6276C24.4896 33.455 24.4896 32.545 25.1309 32.3723L34.7292 29.788C34.9529 29.7278 35.1276 29.5531 35.1878 29.3294L37.7722 19.7311Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter4_d_5456_40337)">
        <path
          d="M147.073 70.5811C147.245 69.9398 148.155 69.9398 148.328 70.5811L150.361 78.1309C150.421 78.3546 150.596 78.5293 150.819 78.5896L158.369 80.6224C159.01 80.795 159.01 81.705 158.369 81.8776L150.819 83.9104C150.596 83.9707 150.421 84.1454 150.361 84.3691L148.328 91.9189C148.155 92.5602 147.245 92.5602 147.073 91.9189L145.04 84.3691C144.98 84.1454 144.805 83.9707 144.581 83.9104L137.031 81.8776C136.39 81.705 136.39 80.795 137.031 80.6224L144.581 78.5896C144.805 78.5293 144.98 78.3546 145.04 78.1309L147.073 70.5811Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter5_d_5456_40337)">
        <path
          d="M24.1724 12.3311C24.345 11.6898 25.255 11.6898 25.4276 12.3311L26.3573 15.784C26.4176 16.0077 26.5923 16.1824 26.816 16.2427L30.2689 17.1724C30.9102 17.345 30.9102 18.255 30.2689 18.4276L26.816 19.3573C26.5923 19.4176 26.4176 19.5923 26.3573 19.816L25.4276 23.2689C25.255 23.9102 24.345 23.9102 24.1724 23.2689L23.2427 19.816C23.1824 19.5923 23.0077 19.4176 22.784 19.3573L19.3311 18.4276C18.6898 18.255 18.6898 17.345 19.3311 17.1724L22.784 16.2427C23.0077 16.1824 23.1824 16.0077 23.2427 15.784L24.1724 12.3311Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter6_d_5456_40337)">
        <path
          d="M154.873 54.0811C155.045 53.4398 155.955 53.4398 156.128 54.0811L157.058 57.534C157.118 57.7577 157.292 57.9324 157.516 57.9927L160.969 58.9224C161.61 59.095 161.61 60.005 160.969 60.1776L157.516 61.1073C157.292 61.1676 157.118 61.3423 157.058 61.566L156.128 65.0189C155.955 65.6602 155.045 65.6602 154.873 65.0189L153.943 61.566C153.883 61.3423 153.708 61.1676 153.484 61.1073L150.031 60.1776C149.39 60.005 149.39 59.095 150.031 58.9224L153.484 57.9927C153.708 57.9324 153.883 57.7577 153.943 57.534L154.873 54.0811Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter7_d_5456_40337)">
        <path
          d="M16.1724 108.331C16.345 107.69 17.255 107.69 17.4276 108.331L18.3573 111.784C18.4176 112.008 18.5923 112.182 18.816 112.243L22.2689 113.172C22.9102 113.345 22.9102 114.255 22.2689 114.428L18.816 115.357C18.5923 115.418 18.4176 115.592 18.3573 115.816L17.4276 119.269C17.255 119.91 16.345 119.91 16.1724 119.269L15.2427 115.816C15.1824 115.592 15.0077 115.418 14.784 115.357L11.3311 114.428C10.6898 114.255 10.6898 113.345 11.3311 113.172L14.784 112.243C15.0077 112.182 15.1824 112.008 15.2427 111.784L16.1724 108.331Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddi_5456_40337"
          x="4.52197"
          y="6.52206"
          width="158.956"
          height="158.956"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.620627 0 0 0 0 0.756863 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0619271 0 0 0 0 0.3625 0 0 0 0 0.272328 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_5456_40337"
            result="effect2_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_5456_40337"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_5456_40337"
          />
        </filter>
        <filter
          id="filter1_ii_5456_40337"
          x="33.1738"
          y="26.174"
          width="101.652"
          height="103.652"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0597861 0 0 0 0 0.732601 0 0 0 0 0.651863 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5456_40337"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_5456_40337"
            result="effect2_innerShadow_5456_40337"
          />
        </filter>
        <filter
          id="filter2_di_5456_40337"
          x="51"
          y="45"
          width="66"
          height="64.3045"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0144645 0 0 0 0 0.610791 0 0 0 0 0.503452 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_5456_40337"
          />
        </filter>
        <filter
          id="filter3_d_5456_40337"
          x="14.2499"
          y="8.85009"
          width="48.3"
          height="48.2998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.956863 0 0 0 0 0.73098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_5456_40337"
          x="126.15"
          y="59.7001"
          width="43.0998"
          height="43.0998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.956863 0 0 0 0 0.73098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_5456_40337"
          x="8.4501"
          y="1.4501"
          width="32.6999"
          height="32.6998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.956863 0 0 0 0 0.73098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_5456_40337"
          x="139.15"
          y="43.2001"
          width="32.6999"
          height="32.6998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.956863 0 0 0 0 0.73098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_5456_40337"
          x="0.450097"
          y="97.4501"
          width="32.6999"
          height="32.6998"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.956863 0 0 0 0 0.73098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5456_40337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5456_40337"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5456_40337"
          x1="87.2"
          y1="-4.66667"
          x2="38.2138"
          y2="124.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9FFE5" />
          <stop offset="0.5" stopColor="#65FFB5" />
          <stop offset="1" stopColor="#2CFFFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5456_40337"
          x1="33"
          y1="27"
          x2="33"
          y2="129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65FFB5" />
          <stop offset="1" stopColor="#00E6CA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5456_40337"
          x1="84"
          y1="45"
          x2="84"
          y2="106.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8FFF7" />
          <stop offset="1" stopColor="#94FAD5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
