import React from 'react';

export default (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4444 5.6H16.6667V4H15.1111V5.6H8.88889V4H7.33333V5.6H6.55556C5.69222 5.6 5.00778 6.32 5.00778 7.2L5 18.4C5 19.28 5.69222 20 6.55556 20H17.4444C18.3 20 19 19.28 19 18.4V7.2C19 6.32 18.3 5.6 17.4444 5.6ZM17.4444 18.4H6.55556V9.6H17.4444V18.4ZM8.11111 11.2H12V15.2H8.11111V11.2Z"
      fill={props.fill || '#fff'}
    />
  </svg>
);
