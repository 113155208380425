import { ACTIONS } from './constants';

const initialState = {};

export const getFilterKey = (filters) => {
  const filterKeys = [];

  if (filters.metro) {
    filterKeys.push(filters.metro);
  }

  if (filters.category) {
    filterKeys.push(filters.category);
  }

  if (filters.category_group) {
    filterKeys.push(filters.category_group);
  }
  if (filters.deals) {
    filterKeys.push(filters.deals);
  }
  if (filters.genre) {
    filterKeys.push(filters.genre);
  }

  return filterKeys.join('+');
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESS:
      const filterKey = getFilterKey(action.params);

      if (filterKey) {
        return {
          ...state,
          [filterKey]: action.result.performers.map(
            (performerInContext) => performerInContext.performer.id
          ),
        };
      }
      return state;

    default:
      return state;
  }
}
