import { useGetSet } from 'react-use';

export function useQueue(initialValue = []) {
  const [getQueue, setQueue] = useGetSet(initialValue);

  return {
    add(value) {
      setQueue((q) => [...q, value]);
    },
    remove() {
      let result;
      setQueue(([first, ...rest]) => {
        result = first;
        return rest;
      });
      return result;
    },
    clear() {
      setQueue([]);
    },
    get first() {
      return getQueue()[0];
    },
    get last() {
      const queue = getQueue();
      return queue[queue.length - 1];
    },
    get size() {
      return getQueue().length;
    },
  };
}
