import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './Buttons.module.scss';

const DropdownButton = ({ text, icon, type, ...rest }) => (
  <ButtonLoader
    className={classNames(styles['simple-button'], styles['dropdown-button'], {
      [styles[type]]: !!type,
    })}
    {...rest}
  >
    <div className={styles.content}>
      {text && <span className={styles.text}>{text}</span>}
      {icon && <span className={styles.icon}>{icon}</span>}
    </div>
  </ButtonLoader>
);

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.node,
};

export default DropdownButton;
