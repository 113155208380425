import React from 'react';

import colors from 'styles/colors.constants';

interface RatingStarIconProps {
  width?: React.SVGProps<SVGSVGElement>['width'];
  height?: React.SVGProps<SVGSVGElement>['height'];
  fill: React.SVGProps<SVGSVGElement>['fill'];
}

const RatingStarIcon = ({
  width = '11',
  height = '11',
  fill = colors.gray,
}: RatingStarIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49337 0.561062C5.5931 0.294337 5.97038 0.294336 6.07011 0.561061L6.9966 3.03882C7.03987 3.15453 7.14812 3.23317 7.27154 3.23857L9.91432 3.35405C10.1988 3.36648 10.3154 3.72529 10.0925 3.90256L8.02237 5.54938C7.92569 5.62629 7.88434 5.75354 7.91735 5.87258L8.62419 8.42171C8.70028 8.69611 8.39506 8.91787 8.15759 8.76071L5.95166 7.30074C5.84864 7.23256 5.71484 7.23256 5.61182 7.30074L3.40588 8.76071C3.16842 8.91787 2.8632 8.69611 2.93929 8.42171L3.64613 5.87258C3.67914 5.75354 3.63779 5.62629 3.54111 5.54938L1.47093 3.90256C1.24808 3.72529 1.36466 3.36648 1.64915 3.35405L4.29194 3.23857C4.41536 3.23317 4.5236 3.15453 4.56687 3.03882L5.49337 0.561062Z"
        fill="#C4C4C8"
      />
    </svg>
  );
};

export default RatingStarIcon;
