import _capitalize from 'lodash/capitalize';

import { CATEGORY_GROUP_CONFIG } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { getEventPageTitle, getListingPageTitle } from 'modules/pageTitles';
import {
  getCategoryName,
  getCategoryPath,
} from 'store/modules/categories/category.helpers';
import { getUrlFromRelativePathname } from 'store/modules/location';
import {
  getMetroPerformersPathByMetro,
  getPerformerPathByPerformer,
} from 'store/modules/resources/resource.paths';

export const getCategoryBreadcrumbConfig = (category) =>
  category && {
    url: getCategoryPath(category),
    name: getCategoryName(category),
  };

export const getPerformerBreadcrumbConfig = (performer) =>
  performer && {
    url: performer.path || getPerformerPathByPerformer(performer),
    name: performer.name,
  };

export const getMetroPerformersBreadcrumbConfig = (currentMetro) =>
  currentMetro && {
    url: getMetroPerformersPathByMetro(currentMetro),
    name: currentMetro.name,
    isMetro: true,
  };

export const getMetroPerformersTagBreadcrumbConfig = (
  currentMetro,
  categoryGroupId,
  name
) =>
  currentMetro &&
  categoryGroupId && {
    url: getMetroPerformersPathByMetro(currentMetro, categoryGroupId),
    name:
      name ||
      _capitalize(
        Object.values(CATEGORY_GROUP_CONFIG).find(
          (categoryGroup) => categoryGroup.id === categoryGroupId
        )?.name || ''
      ),
  };

export const getEventBreadcrumbConfig = (fullEvent) =>
  fullEvent && {
    url: fullEvent.getPath(),
    name: getEventPageTitle(fullEvent),
  };

export const getListingBreadcrumbConfig = (listing, fullEvent, title) =>
  listing && {
    url: listing.getPath(fullEvent),
    name:
      title ||
      getListingPageTitle({
        headline: fullEvent.getMediumName('vs', false),
        alias: `${listing.sectionGroup} ${listing.section}`,
        row: listing.row,
        formatted: fullEvent.datetimeFriendly,
      }),
  };

export const generateBreadcrumbSchema = (breadcrumbs) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map(
    (breadcrumb, index) =>
      breadcrumb && {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': getUrlFromRelativePathname(breadcrumb.url),
          name: breadcrumb.name,
        },
      }
  ),
});
