import React from 'react';

import { CategoryLinksSection } from 'components/LinksSection/LinksSection';
import { Performer } from 'models';
import TextPerformerGroupings from 'pages/CategoryPerformers/components/TextPerformerGroupings';
import {
  generateCategoryLinks,
  MAJOR_LEAGUE_SPORTS_PERFORMERS,
} from 'store/modules/categories/category.helpers';
import {
  generatePerformerLinks,
  generatePopularPerformerLinks,
} from 'store/modules/performers/performers.helpers';

interface PerformerLinksProps {
  relatedPerformers?: Performer[];
  topPickPerformers?: Performer[];
  category?: string;
  heading?: string;
}

const PerformerLinks = ({
  relatedPerformers,
  topPickPerformers,
  category = '',
  heading,
}: PerformerLinksProps) => {
  const linkGroups: CategoryLinksSection[] = [
    {
      title: 'Sports Categories',
      links: generateCategoryLinks(MAJOR_LEAGUE_SPORTS_PERFORMERS),
    },
    {
      title: `Popular ${category.toUpperCase()} Tickets`,
      links: generatePopularPerformerLinks(category, relatedPerformers),
    },
    {
      title: 'Top Picks',
      links: generatePerformerLinks(topPickPerformers),
    },
  ];

  return <TextPerformerGroupings heading={heading} linkGroups={linkGroups} />;
};

export default PerformerLinks;
