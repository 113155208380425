import React from 'react';
import { ValueOf } from 'type-fest';

import { ClickTracker } from 'analytics';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import JsonLD from 'components/JsonLD/JsonLD';
import { PriceButtonColors } from 'components/PriceButton/PriceButton';
import { Badge } from 'components/UrgencyBadge/UrgencyBadge';
import { FullEvent } from 'models';

import EventCell, { EVENT_CELL_TYPES } from './EventCell/EventCell';

interface EventContainerProps {
  fullEvent: FullEvent;
  badgeProps?: Badge;
  clickTracker?: ClickTracker | object;
  onClick?: VoidFunction;
  type: ValueOf<typeof EVENT_CELL_TYPES>;
  isSmall?: boolean;
  isActive?: boolean;
  isSearchBar?: boolean;
  priceButtonColors?: PriceButtonColors;
  showInlinePricing?: boolean;
}

const EventContainer = ({
  fullEvent,
  badgeProps,
  clickTracker,
  type,
  onClick,
  isSmall,
  isActive,
  isSearchBar,
  priceButtonColors,
  showInlinePricing = false,
}: EventContainerProps) => {
  const jsonLD = dataToJsonLD(fullEvent);

  return (
    <>
      <EventCell
        fullEvent={fullEvent}
        badgeProps={badgeProps}
        clickTracker={clickTracker}
        type={type}
        onClick={onClick}
        isSmall={isSmall}
        isActive={isActive}
        isSearchBar={isSearchBar}
        priceButtonColors={priceButtonColors}
        showInlinePricing={showInlinePricing}
      />
      <JsonLD json={jsonLD} />
    </>
  );
};

export default EventContainer;
