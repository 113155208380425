import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

const SearchHeroLocationIcon = ({
  width = '24',
  height = '24',
  fill = '#ffffff',
}) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      d="M12.002 2C7.5896 2 4 5.58597 4 9.99866C4 17.3564 11.7441 21.9293 11.7441 21.9293C11.8216 21.9756 11.9102 22 12.0005 22C12.0908 22 12.1793 21.9756 12.2568 21.9293C12.2568 21.9293 20 17.3564 20 9.99866C20 5.58597 16.4143 2 12.002 2ZM12.001 6.49938C12.9292 6.49938 13.8194 6.86813 14.4758 7.52456C15.1322 8.18098 15.5009 9.07131 15.501 9.99964C15.501 10.4593 15.4104 10.9145 15.2345 11.3392C15.0586 11.7638 14.8008 12.1497 14.4758 12.4747C14.1508 12.7998 13.765 13.0576 13.3403 13.2335C12.9157 13.4094 12.4606 13.4999 12.001 13.4999C11.5413 13.4999 11.0862 13.4094 10.6616 13.2335C10.2369 13.0576 9.85108 12.7998 9.52607 12.4747C9.20107 12.1497 8.94326 11.7638 8.76737 11.3392C8.59148 10.9145 8.50095 10.4593 8.50095 9.99964C8.50095 9.07131 8.8697 8.18098 9.52608 7.52456C10.1825 6.86813 11.0727 6.49938 12.001 6.49938V6.49938Z"
      fill={fill}
    />
  </svg>
);

SearchHeroLocationIcon.propTypes = propTypes;
export default SearchHeroLocationIcon;
