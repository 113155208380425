import { useMemo } from 'react';

import {
  SEAT_MAP_IMAGE_HEIGHT,
  SEAT_MAP_IMAGE_WIDTH,
} from '../SeatMap.constants.js';

const usePinPosition = (width, height, scale) => {
  return {
    widthRatio: useMemo(() => width / SEAT_MAP_IMAGE_WIDTH, [width]),
    heightRatio: useMemo(() => height / SEAT_MAP_IMAGE_HEIGHT, [height]),
    inverseScale: useMemo(() => 1 / scale, [scale]),
  };
};

export default usePinPosition;
