import React from 'react';
import classNames from 'classnames';

import styles from './IconButton.module.scss';

interface IconButtonProps {
  className?: classNames.Argument;
  icon: React.ReactNode;
  onClick: React.HTMLAttributes<HTMLButtonElement>['onClick'];
}

export default function IconButton({
  className,
  icon,
  onClick,
}: IconButtonProps) {
  return (
    <button
      type="button"
      className={classNames(className, styles['icon-button'])}
      onClick={onClick}
      data-testid="icon-button"
    >
      {icon}
    </button>
  );
}
