import React, { Component } from 'react';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import { Click, ClickTracker, withAnalyticsContext } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import { CaretDownIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './MetroSelector.module.scss';

@withAnalyticsContext
@withClickContext()
export default class MetroSelector extends Component {
  static propTypes = {
    currentMetro: PropTypes.object.isRequired,
    redirectToMetro: PropTypes.bool,
    titleStyle: PropTypes.string.isRequired,
    iconStyle: PropTypes.string.isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = { openModal: false };
  }

  toggleModal = () => {
    const { openModal } = this.state;
    const { clickContext, analyticsContext } = this.props;
    if (!openModal) {
      const tracker = new ClickTracker().interaction(
        Click.INTERACTIONS.CHANGE_LOCATION()
      );

      analyticsContext.track(
        new Click(_merge({}, clickContext, tracker.json()))
      );
    }
    this.setState({ openModal: !openModal });
  };

  render() {
    const { currentMetro, redirectToMetro, titleStyle, iconStyle } = this.props;
    const { openModal } = this.state;

    return (
      <>
        <button className={styles[titleStyle]} onClick={this.toggleModal}>
          <span className={styles['metro-name']}>{currentMetro.name}</span>
          <span className={styles[iconStyle]}>
            <CaretDownIcon fill={colors.gametimeGreenLight} />
          </span>
        </button>
        <MetroSelectorModal
          onHide={this.toggleModal}
          show={openModal}
          redirectToMetro={redirectToMetro}
        />
      </>
    );
  }
}
