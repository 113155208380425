import React from 'react';
import PropTypes from 'prop-types';

import InfoIcon from 'icons/InfoIcon';
import colors from 'styles/colors.constants';

import styles from './ZoneTag.module.scss';

const ZoneTag = ({ variant, onClick }) => {
  if (variant === 'text-info-icon') {
    return (
      <button
        type="button"
        className={styles['text-info-container']}
        onClick={onClick}
      >
        <div className={styles['text-info']}>Zone View</div>
        <InfoIcon fill={colors.infoBlue} width="12" height="12" />
      </button>
    );
  }

  /* default */
  return (
    <div className={styles['zone-tag']}>
      <div className={styles['zone-text']}>Zone View</div>
    </div>
  );
};

ZoneTag.propTypes = {
  variant: PropTypes.oneOf(['text-info-icon', 'default']),
  onClick: PropTypes.func,
};

export default ZoneTag;
