import React from 'react';

import { Click, FullEventClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import { HEADER_TYPES } from 'components/SectionHeader/constants';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { PerformerInContext as PerformerInContextModel } from 'models';

interface PerformersInContextCarouselProps {
  performersInContext: PerformerInContextModel[];
  carouselTitle: string;
  sectionIndex: number;
  showSeeAll: boolean;
  seeAllLinkPath?: string;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

export default function PerformersInContextCarousel({
  performersInContext,
  carouselTitle,
  sectionIndex,
  showSeeAll,
  seeAllLinkPath,
}: PerformersInContextCarouselProps) {
  const analytics: Analytics = useAnalyticsContext();

  const isMobile = useMediaQuery(device.down.md);
  const clickContext = useClickContext();

  const renderPerformerInContext = (
    performerInContext: PerformerInContextModel,
    index: number
  ) => {
    // Click tracking dependencies as currently defined by data team.
    // these match Collection's click tracker, but may change as the Performer page evolves
    const nextFullEvent = performerInContext.nextEventsList[0];
    const params = {
      collection: carouselTitle,
      item_index: index,
      sectionIndex,
      get_in_price: nextFullEvent?.getPrice(),
    };
    const clickTracker = new FullEventClickTracker(
      nextFullEvent || {}
    ).interaction(Click.INTERACTIONS.TILE(), params);

    return (
      <CollectionCard
        clickTracker={clickTracker}
        key={`click-carousel-${performerInContext.performer.id}`}
        source={performerInContext}
        lazyLoad
        toListingPage
      />
    );
  };

  return (
    <ReactSlickCarousel
      analytics={analytics}
      clickContext={clickContext}
      items={performersInContext}
      title={carouselTitle}
      renderItem={renderPerformerInContext}
      spacing={16}
      slideWidth={isMobile ? 304 : 344}
      renderHeader={() => (
        <SectionHeader
          title={carouselTitle}
          showSeeAll={showSeeAll}
          seeAllLinkPath={seeAllLinkPath}
          headerType={HEADER_TYPES.BODY}
        />
      )}
    />
  );
}
