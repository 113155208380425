export class SessionStart {
  constructor({ dev }) {
    this.dev = dev;
  }

  eventName() {
    return 'session_start';
  }

  toParams() {
    const params = {};

    for (const key in this.dev) {
      if (Object.prototype.hasOwnProperty.call(this.dev, key)) {
        params[`dev_${key}`] = this.dev[key];
      }
    }

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
