import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Switch.module.scss';

const Switch = ({ id = 'switch', onChange, checked = false, size = 'md' }) => {
  return (
    <>
      <input
        id={id}
        type="checkbox"
        data-testid="switch"
        checked={checked}
        onChange={onChange}
        className={styles['switch-checkbox']}
      />
      <label
        htmlFor={id}
        className={classNames(styles['switch-label'], [
          {
            [styles['switch-label-active']]: checked,
            [styles[`switch-label-${size}`]]: true,
          },
        ])}
      >
        <span
          className={classNames(styles['switch-button'], [
            {
              [styles['switch-button-active']]: checked,
              [styles[`switch-button-${size}`]]: true,
            },
          ])}
        >
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          {''}
        </span>
      </label>
    </>
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['md', 'lg']),
};

export default Switch;
