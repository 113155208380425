import React from 'react';
import { connect } from 'react-redux';
import { Outlet, useOutletContext } from 'react-router-dom';

import CheckoutLogin from 'components/Login/CheckoutLogin';
import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { isUserLoggedIn } from 'store/modules/user/user.selectors';

import PurchaseFlowProvider from './PurchaseFlowContext';

interface Props {
  isLoggedIn: boolean;
}

function ProtectedCheckoutRoute({ isLoggedIn }: Props) {
  const { listing, event } = useOutletContext<{
    listing: Listing;
    event: FullEvent;
  }>();
  if (isLoggedIn) {
    // TODO: we should resolve this legacy pattern and use route params
    // within the child routes to select the data needed for the components

    return (
      <PurchaseFlowProvider listing={listing} event={event}>
        <Outlet context={{ listing, event }} />
      </PurchaseFlowProvider>
    );
  }

  return <CheckoutLogin listing={listing} />;
}

function mapStateToProps(state: unknown) {
  return {
    isLoggedIn: isUserLoggedIn(state),
  };
}

export default connect(mapStateToProps)(ProtectedCheckoutRoute);
