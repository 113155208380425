import React from 'react';
import PropTypes from 'prop-types';

import { TYPES as BUTTON_TYPES } from 'components/Buttons/SimpleButton';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';

import AffirmWhiteLogo from '../assets/affirm_white_logo.svg';

import styles from './AffirmButton.module.scss';

const AffirmButton = ({
  onClick,
  clickTracker,
  disabled,
  logo = AffirmWhiteLogo,
}) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <SimpleLinkButton
      role="button"
      className={styles['affirm-button']}
      type={BUTTON_TYPES.PURPLE_SOLID}
      text={
        <span className={styles['button-content']}>
          Check out with&nbsp;
          <img src={logo} alt="Affirm Logo" height={19} />
        </span>
      }
      onClick={(e) => handleClick(e)}
      clickTracker={clickTracker}
    />
  );
};

AffirmButton.propTypes = {
  onClick: PropTypes.func,
  clickTracker: PropTypes.object,
  disabled: PropTypes.bool,
  logo: PropTypes.string,
};

export default AffirmButton;
