import {
  FETCH_MLB_CONTENT,
  FETCH_MLB_CONTENT_FAIL,
  FETCH_MLB_CONTENT_SUCCESS,
} from './actions';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_MLB_CONTENT_SUCCESS:
      const { slug, fields } = action.result || {};

      return {
        ...state,
        [slug]: fields,
      };
    case FETCH_MLB_CONTENT_FAIL:
    case FETCH_MLB_CONTENT:
    default:
      return state;
  }
}
