import { Listing } from 'types';

export type DeliveryType = Listing['delivery_type'];

export const DELIVERY_TYPES: { [T in DeliveryType]: T } = {
  unknown: 'unknown',
  barcode: 'barcode',
  electronic: 'electronic',
  flashseats: 'flashseats',
  hard: 'hard',
  instant: 'instant',
  mobile: 'mobile',
  local_pickup: 'local_pickup',
  direct: 'direct',
};

export function deliveryTypeFor(listing: { deliveryType: DeliveryType }) {
  return DELIVERY_TYPES[listing.deliveryType];
}

export function isThirdPartyDelivery(deliveryType: DeliveryType) {
  return deliveryType === DELIVERY_TYPES.mobile;
}
