import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Drawer from 'ui/Drawer';

import HeadRobots from 'components/Head/Robots';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import { FullEvent } from 'models';
import Listing, { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import EventInfo from 'pages/Event/components/EventInfo/EventInfo';
import { MAX_SIDEBAR_WIDTH } from 'pages/Event/Event.constants';
import colors from 'styles/colors.constants';

import ListingHero from '../ListingHero/ListingHero';

import styles from './ListingDetails.module.scss';

const ListingDetails = ({
  listing,
  fullEvent,
  isPurchaseRoute,
  isCheckout,
  childProps = {},
  isHarmonyPlusOverlay,
  onBack,
  shouldAnimate,
}) => {
  const [isTransitionEnter, setTransitionEnter] = useState(true);

  const handleBack = () => {
    if (isHarmonyPlusOverlay) {
      setTransitionEnter(false);
      setTimeout(() => {
        onBack();
      }, 500);
    } else {
      onBack();
    }
  };

  if (!listing) {
    return null;
  }

  const listingChildrenProps = {
    listing,
    ...childProps,
  };

  return (
    <>
      <HeadRobots noindex noarchive nofollow />
      <Drawer
        isOpen={isTransitionEnter}
        className={classNames({
          [styles['listing-detail-container']]: isHarmonyPlusOverlay,
        })}
        useDefaultStyles={false}
        drawerKey="listing-details"
        shouldAnimate={shouldAnimate}
        initialStyle={{ x: MAX_SIDEBAR_WIDTH }}
        exitStyle={{ x: MAX_SIDEBAR_WIDTH }}
      >
        <div className={styles['listing-detail-overflow']}>
          <div
            className={classNames(styles['hero-container'], {
              [styles['purchase-route']]: isPurchaseRoute,
              [styles.checkout]: isCheckout,
            })}
          >
            <div className={styles['listing-detail-nav']}>
              <div className={styles['image-gradient']} />
              <InfoHeader
                headerContent={<EventInfo fullEvent={fullEvent} />}
                onBack={handleBack}
                showBack
                backIconColor={colors.white}
              />
            </div>

            <div className={styles['listing-hero']}>
              <ListingHero
                listing={listing}
                fullEvent={fullEvent}
                showZoneTag={listing.disclosures.includes(
                  ZONE_TICKET_DISCLOSURE
                )}
              />
            </div>
          </div>
          <Outlet context={listingChildrenProps} />
        </div>
      </Drawer>
    </>
  );
};

ListingDetails.propTypes = {
  isHarmonyPlusOverlay: PropTypes.bool,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listing: PropTypes.instanceOf(Listing),
  isPurchaseRoute: PropTypes.bool,
  isCheckout: PropTypes.bool,
  childProps: PropTypes.object,
  onBack: PropTypes.func,
  shouldAnimate: PropTypes.bool,
};

export default React.memo(ListingDetails);
