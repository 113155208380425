import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAnalyticsContext, View } from 'analytics';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import MetroPickerCollection from 'components/MetroPickerCollection/MetroPickerCollection';
import { Collection as CollectionModel } from 'models';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { metroPageCollectionsSelector } from 'store/modules/data/Collections/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectAllMetros,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';

import PicksFAQ from './components/PicksFAQ/PicksFAQ';
import PicksHero from './components/PicksHero/PicksHero';
import PicksReviews from './components/PicksReviews/PicksReviews';
import PicksSignUp from './components/PicksSignUp/PicksSignUp';

import styles from './Picks.module.scss';

const Picks = ({
  metros,
  currentMetro,
  closestMetro,
  collections = [],
  dispatch,
}) => {
  const analytics = useAnalyticsContext();
  const [isFetching, setIsFetching] = useState(false);

  const fetchCollectionsOnMetroChange = async (metroId) => {
    setIsFetching(true);
    await dispatch(
      fetchCollections({
        view: COLLECTION_VIEWS.WEB_PERFORMER,
        metro: metroId,
      })
    );
    setIsFetching(false);
  };

  const handleMetroChange = (metroId) => {
    fetchCollectionsOnMetroChange(metroId);
  };

  useEffect(() => {
    analytics.track(new View({ pageType: View.PAGE_TYPES.GAMETIME_PICKS() }));
  }, [analytics]);

  const renderCollections = () => {
    if (collections.length === 0) {
      return (
        <MetroPickerCollection
          collectionTitle="Gametime Picks Near"
          currentMetro={currentMetro}
          metros={metros}
          closestMetro={closestMetro}
          isLoading={isFetching}
          showPriceInImage
          handleMetroChange={handleMetroChange}
        />
      );
    }

    return collections.map((collection) => {
      return (
        <MetroPickerCollection
          key={`${collection.id}-picks-collection`}
          collection={collection}
          collectionTitle="Gametime Picks Near"
          currentMetro={currentMetro}
          metros={metros}
          closestMetro={closestMetro}
          isLoading={isFetching}
          showPriceInImage
          handleMetroChange={handleMetroChange}
        />
      );
    });
  };

  return (
    <GTContainer
      headerProps={{
        search: true,
        showCategories: true,
        showAccount: true,
        showHamburger: true,
      }}
    >
      <HeadTitle title="Gametime Picks" />
      <HeadDescription description="Gametime Picks is the easiest way to find and buy tickets to live events. Get the best deals on great seats at incredible events near you, all with less hassle." />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={styles['picks-container']}>
        <PicksHero />
        <PicksFAQ />
        {renderCollections()}
        <PicksSignUp />
        <PicksReviews />
      </div>
    </GTContainer>
  );
};

Picks.propTypes = {
  metros: PropTypes.array.isRequired,
  closestMetro: PropTypes.object.isRequired,
  currentMetro: PropTypes.object.isRequired,
  collections: PropTypes.arrayOf(PropTypes.instanceOf(CollectionModel))
    .isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const closestMetro = selectClosestMetro(state);
  const currentMetro = selectUserMetro(state) || closestMetro;

  const homeFilterKeys = {
    metro: currentMetro?.id,
    view: COLLECTION_VIEWS.WEB_PERFORMER,
  };

  const collections = metroPageCollectionsSelector(state, homeFilterKeys);

  return {
    metros: selectAllMetros(state),
    currentMetro,
    closestMetro,
    collections,
  };
};

const PicksWrapper = connect(mapStateToProps)(Picks);

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    const { dispatch, getState } = store;
    await dispatch(fetchMetros());

    const state = getState();
    const metro = selectUserMetro(state) || selectClosestMetro(state);
    const currentLocation = currentLocationSelector(state);

    if (!currentLocation) {
      dispatch(updateCurrentLocation(metro.id));
    }

    const promises = [];

    promises.push(
      dispatch(
        fetchCollections({
          view: COLLECTION_VIEWS.WEB_PERFORMER,
          metro: metro.id,
        })
      )
    );

    await Promise.all(promises);

    return null;
  };

PicksWrapper.loader = loader;

export default PicksWrapper;
