import React from 'react';
import PropTypes from 'prop-types';

import IconCard from 'components/IconCard/IconCard';
import LocationDropFillIcon from 'icons/LocationDropFillIcon';
import colors from 'styles/colors.constants';

const DetailVenueCard = ({
  fullEvent,
  svgFill = colors.gray200,
  uniformPadding,
}) => (
  <IconCard
    icon={<LocationDropFillIcon fill={svgFill} />}
    title={fullEvent.venueName}
    subtitle={fullEvent.venueCityState}
    uniformPadding={uniformPadding}
  />
);

DetailVenueCard.propTypes = {
  fullEvent: PropTypes.object.isRequired,
  svgFill: PropTypes.string,
  uniformPadding: PropTypes.bool,
};

export default DetailVenueCard;
