import React from 'react';

import GiftIcon from 'icons/GiftIcon';
import { Condition } from 'types';

import { CONDITION_TYPE } from '../../constants';

import styles from './ActivePromo.module.scss';

type Props = {
  title: string;
  description: string;
  conditions?: Array<Condition>;
  creationDateString: string;
};
interface ValidDaysAfterRedemptionCondition extends Condition {
  int_value: number;
}

const ActivePromo = ({
  title,
  description,
  conditions,
  creationDateString,
}: Props) => {
  function isValidDaysAfterRedemptionCondition(
    condition: Condition
  ): condition is ValidDaysAfterRedemptionCondition {
    return condition.type === CONDITION_TYPE.VALID_DAYS_AFTER_REDEMPTION;
  }

  const validDaysAfterRedemptionCondition = conditions?.find(
    isValidDaysAfterRedemptionCondition
  );

  function getExpirationDateText(validDays: number) {
    const expirationDate = new Date(creationDateString);
    expirationDate.setDate(expirationDate.getDate() + validDays);
    const expMonth = expirationDate.getMonth() + 1;
    const expDay = expirationDate.getDate();
    const expYear = expirationDate.getFullYear();
    return `Expires ${expMonth}/${expDay}/${expYear}`;
  }

  return (
    <div className={styles['active-promo']}>
      <GiftIcon />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      {validDaysAfterRedemptionCondition && (
        <div className={styles['expiration-text-container']}>
          <span className={styles['expiration-text']}>
            {getExpirationDateText(validDaysAfterRedemptionCondition.int_value)}
          </span>
        </div>
      )}
    </div>
  );
};

export default ActivePromo;
