import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Overlay from 'ui/Overlay/Overlay';

import {
  isFormSubmittedSelector,
  isFormSubmittingSelector,
  setFormSubmitted as setFormSubmittedDispatch,
  setFormSubmitting as setFormSubmittingDispatch,
} from 'store/modules/app/app.ui';

const ConnectedAppSubmittingOverlay = ({
  isSubmitting,
  isSubmitted,
  setFormSubmitted,
  setFormSubmitting,
}) => {
  if (!isSubmitting && !isSubmitted) return null;

  const handleBackdropClick = () => {
    setFormSubmitted(false);
    setFormSubmitting(false);
  };

  return (
    <Overlay
      variant="submitting"
      onClick={handleBackdropClick}
      isLoading={isSubmitting}
    />
  );
};

ConnectedAppSubmittingOverlay.propTypes = {
  isSubmitting: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  setFormSubmitted: PropTypes.func,
  setFormSubmitting: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isSubmitting: isFormSubmittingSelector(state),
  isSubmitted: isFormSubmittedSelector(state),
});

const mapDispatchToProps = {
  setFormSubmitted: setFormSubmittedDispatch,
  setFormSubmitting: setFormSubmittingDispatch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAppSubmittingOverlay);
