import _merge from 'lodash/merge';

import commonConfig from './common.config';
import { ENV, isValidEnv } from './environments';
import developmentConfig from './local.config';
import preProductionConfig from './pre-production.config';
import productionConfig from './production.config';
import qaConfig from './qa.config';
import stagingConfig from './staging.config';
import testingConfig from './testing.config';

const env = process.env.ENVIRONMENT || ENV.DEVELOPMENT;

if (!isValidEnv(env)) {
  throw new Error(`Invalid value for process.env.ENVIRONMENT: ${env}`);
}

const envConfigs = {
  [ENV.DEVELOPMENT]: developmentConfig,
  [ENV.TESTING]: testingConfig,
  [ENV.STAGING]: stagingConfig,
  [ENV.QA]: qaConfig,
  [ENV.PRE_PRODUCTION]: preProductionConfig,
  [ENV.PRODUCTION]: productionConfig,
};

const envConfig = envConfigs[env];

export default _merge({ env }, commonConfig, envConfig);
