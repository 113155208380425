import React from 'react';

import { ShieldIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './GTCoverage.module.scss';

const GTCoverage = () => (
  <>
    <section className={styles.container}>
      <div>
        <ShieldIcon fill={colors.gray200} data-testid="shield-icon" />
      </div>

      <div className={styles.content}>
        <h3 className={styles['title']}>Gametime Best Price Guarantee</h3>
        <p className={styles.message}>
          Best price or we'll send you 110% of the difference.
        </p>
      </div>
    </section>
  </>
);

export default GTCoverage;
