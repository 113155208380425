import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  mapListingTrackingData,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import UserZipForm from 'components/UserZipForm/UserZipForm';
import { Listing } from 'models';
import { PURCHASE_CTA_TEXTS } from 'pages/Listing/constants';

@TrackPageView(({ listing }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.VERIFY_ZIPCODE(),
  payload: mapListingTrackingData(listing.listingTrackingData),
}))
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.VERIFY_ZIPCODE(),
  payload: mapListingTrackingData(listing.listingTrackingData),
}))
export default class PurchaseUserZip extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    listing: PropTypes.instanceOf(Listing).isRequired, // Used in tracking
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(form) {
    this.props.onSubmit(form.zipcode);
  }

  render() {
    return (
      <>
        <InfoHeader headerContent="Securing Your Tickets" isDarkTheme bold />
        <ThemedCard
          title="Verify Card ZIP Code"
          desc="To complete your purchase, we need to verify the billing ZIP code for
        your credit card."
        >
          <UserZipForm
            handleUserZipFormSubmit={this.handleSubmit}
            clickTracker={new ClickTracker().interaction(
              Click.INTERACTIONS.CONTINUE_BUTTON()
            )}
            ctaText={PURCHASE_CTA_TEXTS.PURCHASE_USER_ZIP}
          />
        </ThemedCard>
      </>
    );
  }
}
