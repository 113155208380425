import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SearchRecommendedList, { TYPES } from './SearchRecommendedList';

import styles from '../SearchResults/SearchResults.module.scss';

const SearchRecommended = ({
  events,
  teams,
  viewedEvents,
  onClick,
  isMobile,
}) => {
  if (events.length === 0 && teams.length === 0) {
    return null;
  }

  return (
    <section
      className={classNames(styles['search-results'], {
        [styles['search-mobile']]: isMobile,
        [styles['search-mobile-recommended']]: isMobile,
        [styles['search-desktop']]: !isMobile,
      })}
    >
      <SearchRecommendedList
        isViewedEvents
        collection={viewedEvents}
        type={TYPES.events}
        onClick={onClick}
      />

      <SearchRecommendedList
        collection={teams}
        type={TYPES.teams}
        onClick={onClick}
      />

      <SearchRecommendedList
        collection={events}
        type={TYPES.events}
        onClick={onClick}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  const recommended = state.data?.search?.recommended ?? {};

  if (Object.keys(recommended).length === 0) {
    return {
      events: [],
      teams: [],
      viewedEvents: [],
    };
  }

  return {
    events: recommended.popular || [],
    teams: recommended.sport || [],
    viewedEvents: recommended.viewedEvents || [],
  };
};

SearchRecommended.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  teams: PropTypes.arrayOf(PropTypes.object),
  viewedEvents: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(SearchRecommended);
