import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import styles from './MapListSwitch.module.scss';

interface MapListLabelProps {
  isActive: boolean;
  children: React.ReactNode;
}

const MapListLabel = ({ isActive, children }: MapListLabelProps) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const onScroll = useCallback(() => setIsScrolling(true), []);

  useEffect(() => {
    let mounted = true;

    const onScrollEnd = _debounce(() => setIsScrolling(false), 1000);

    const removeListeners = () => {
      window.removeEventListener('scroll', onScroll, true);
      window.removeEventListener('scroll', onScrollEnd, true);
    };

    if (mounted) {
      window.addEventListener('scroll', onScroll, true);
      window.addEventListener('scroll', onScrollEnd, true);
    } else {
      removeListeners();
    }

    return () => {
      mounted = false;
      removeListeners();
    };
  }, [onScroll]);

  return (
    <div
      className={classNames(styles['label-content'], {
        [styles.transparent]: isScrolling,
        [styles.active]: isActive,
      })}
    >
      {children}
    </div>
  );
};

export default MapListLabel;
