import _kebabCase from 'lodash/kebabCase';

export const reverseCamel = (input) => {
  if (!input) {
    return '';
  }
  const result = input.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatTickets = (count, showAllListings = false) =>
  showAllListings ? 'All Tickets' : `${count} Ticket${count === 1 ? '' : 's'}`;

export const formatUrl = (string = '') =>
  encodeURIComponent(_kebabCase(string));

export const numberWithCommas = (num) =>
  num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Removes any trailing slashes in a URL (ex. gametime.co/ -> gametime.co)
export const cleanUrl = (url) => url && url.replace(/\/$/, '');
