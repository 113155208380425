import React, { useState } from 'react';
import { connect } from 'react-redux';
import _merge from 'lodash/merge';
import { Dispatch } from 'redux';
import { ValueOf } from 'type-fest';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import FilterControl from 'components/FilterControl/FilterControl';
import FilterDropdown from 'components/FilterControl/FilterDropdown';
import FilterModal from 'components/FilterModal/FilterModal';
import Section from 'components/Section/Section';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import { device, useMediaQuery } from 'hooks';
import { CalendarIcon, CaretDownIcon, LocationFillIcon } from 'icons';
import { updateCurrentLocation } from 'store/modules/app/app';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import {
  DATE_RANGE_FILTERS,
  GAME_TYPE_FILTERS,
} from './PerformerFilters.constants';

import styles from './PerformerFilters.module.scss';

export type GameType = ValueOf<typeof GAME_TYPE_FILTERS>;
export type DateRangeOptions = ValueOf<typeof DATE_RANGE_FILTERS>;

const FILTERS_TARGET_PAGE_TYPES = {
  [GAME_TYPE_FILTERS.HOME_AWAY]: 'home_and_away',
  [GAME_TYPE_FILTERS.HOME]: 'home_games',
  [GAME_TYPE_FILTERS.AWAY]: 'away_games',
  [DATE_RANGE_FILTERS.ALL_DATES]: 'any_date',
  [DATE_RANGE_FILTERS.TODAY]: 'today',
  [DATE_RANGE_FILTERS.THIS_WEEKEND]: 'this_weekend',
  [DATE_RANGE_FILTERS.NEXT_WEEKEND]: 'next_weekend',
};

interface PerformerFiltersProps {
  currentMetro: Metro;
  closestMetro: Metro;
  metros: Metro[];
  onSelectDateRange: (range: DateRangeOptions) => void;
  isNearEmpty: boolean;
  currentGameTypeFilter: GameType;
  setGameTypeFilter: (gameTypeFilter: GameType) => void;
  setDateRangeFilter: (range: DateRangeOptions) => void;
  dateRangeFilter: DateRangeOptions;
  isHomeAwayFilterEnabled: boolean;
  dispatch: Dispatch;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

const PerformerFilters = ({
  currentMetro,
  closestMetro,
  metros,
  onSelectDateRange,
  isNearEmpty,
  currentGameTypeFilter,
  setGameTypeFilter,
  setDateRangeFilter,
  dateRangeFilter,
  isHomeAwayFilterEnabled,
  dispatch,
}: PerformerFiltersProps) => {
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const isMobile = useMediaQuery(device.down.md);
  const [isMetroModalOpen, setIsMetroModalOpen] = useState(false);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [isGameTypeModalOpen, setIsGameTypeModalOpen] = useState(false);

  const toggleMetroModal = () => {
    setIsMetroModalOpen((prevIsMetroModalOpen) => {
      if (!prevIsMetroModalOpen) {
        const tracker = new ClickTracker().interaction(
          Click.INTERACTIONS.CHANGE_LOCATION()
        );
        analytics.track(
          new Click(_merge({}, clickContext, tracker.json()) as Click)
        );
      }
      return !prevIsMetroModalOpen;
    });
  };

  const toggleGameTypeFilterModal = () => {
    setIsGameTypeModalOpen((prevIsGameTypeModalOpen) => {
      if (!prevIsGameTypeModalOpen) {
        const tracker = new ClickTracker().interaction(
          Click.INTERACTIONS.HOME_AWAY_FILTER()
        );
        analytics.track(
          new Click(_merge({}, clickContext, tracker.json()) as Click)
        );
      }
      return !prevIsGameTypeModalOpen;
    });
  };

  const toggleDateFilter: VoidFunction = () => {
    setIsDateFilterOpen((prevIsDateFilterOpen) => {
      if (!prevIsDateFilterOpen) {
        const tracker = new ClickTracker().interaction(
          Click.INTERACTIONS.DATE_PICKER()
        );
        analytics.track(
          new Click(_merge({}, clickContext, tracker.json()) as Click)
        );
      }
      return !prevIsDateFilterOpen;
    });
  };

  const handleMetroSelect = (selectedMetro: Metro) => {
    dispatch(updateCurrentLocation(selectedMetro.id));
    dispatch(updateUserPreference({ lastVisitedMetro: selectedMetro.id }));
    toggleMetroModal();
  };

  const handleDateRangeSelect = (dateRange: DateRangeOptions) => {
    setDateRangeFilter(dateRange);
    onSelectDateRange(dateRange);

    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.DATE_PICKER())
      .targetPageType({
        target_page_type: FILTERS_TARGET_PAGE_TYPES[dateRange],
      });
    analytics.track(
      new Click(_merge({}, clickContext, tracker.json()) as Click)
    );

    toggleDateFilter();
  };

  const handleGameTypeSelect = (selectedGameType: GameType) => {
    setGameTypeFilter(selectedGameType);

    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.HOME_AWAY_FILTER())
      .targetPageType({
        target_page_type: FILTERS_TARGET_PAGE_TYPES[selectedGameType],
      });
    analytics.track(
      new Click(_merge({}, clickContext as object, tracker.json()) as Click)
    );

    toggleGameTypeFilterModal();
  };

  const metroFilterOptions = [
    {
      name: 'Use My Location',
      onClick: () => handleMetroSelect(closestMetro),
      icon: <LocationFillIcon fill={colors.gametimeGreen} />,
    },
    ...metros.map((metro) => {
      return {
        name: metro.name,
        onClick: () => {
          handleMetroSelect(metro);
        },
      };
    }),
  ];
  const dateRangeFilterOptions = Object.values(DATE_RANGE_FILTERS).map(
    (dateRange) => ({
      name: dateRange,
      onClick: () => handleDateRangeSelect(dateRange),
    })
  );

  const gameTypeFilterOptions = Object.values(GAME_TYPE_FILTERS).map(
    (gameType) => ({
      name: gameType,
      onClick: () => handleGameTypeSelect(gameType),
    })
  );

  const metroFilterModal = isMobile ? (
    <MetroSelectorModal
      show={isMetroModalOpen}
      onHide={() => {
        setIsMetroModalOpen(false);
      }}
    />
  ) : (
    <FilterDropdown show={isMetroModalOpen} options={metroFilterOptions} />
  );

  const dateRangeFilterModal = isMobile ? (
    <FilterModal
      show={isDateFilterOpen}
      onHide={toggleDateFilter}
      options={dateRangeFilterOptions}
      title="Date"
    />
  ) : (
    <FilterDropdown show={isDateFilterOpen} options={dateRangeFilterOptions} />
  );

  const gameTypeFilterModal = isMobile ? (
    <FilterModal
      show={isGameTypeModalOpen}
      onHide={() => {
        setIsGameTypeModalOpen(false);
      }}
      options={gameTypeFilterOptions}
      title="Game Type"
    />
  ) : (
    <FilterDropdown
      show={isGameTypeModalOpen}
      options={gameTypeFilterOptions}
    />
  );

  const handleMetroFilterClickOutside = () => {
    if (!isMobile) {
      setIsMetroModalOpen(false);
    }
  };
  const handleDateFilterClickOutside = () => {
    if (!isMobile) {
      setIsDateFilterOpen(false);
    }
  };

  const handleGameTypeFilterClickOutside = () => {
    if (!isMobile) {
      setIsGameTypeModalOpen(false);
    }
  };

  return (
    <Section
      className={styles['performer-filters-section']}
      showHeadline={false}
      headline=""
    >
      <div className={styles['performer-filters-container']}>
        <div className={styles['performer-filters']}>
          <FilterControl
            onClick={toggleMetroModal}
            onClickOutside={handleMetroFilterClickOutside}
            modal={metroFilterModal}
            isActive={isMetroModalOpen}
          >
            {`${isNearEmpty ? 'No' : ''} Events near `}
            <span className={styles['metro-name']}>{currentMetro.name}</span>
            <CaretDownIcon fill={colors.gametimeGreenLight} />
          </FilterControl>

          {isHomeAwayFilterEnabled && (
            <FilterControl
              onClick={toggleGameTypeFilterModal}
              onClickOutside={handleGameTypeFilterClickOutside}
              modal={gameTypeFilterModal}
              isActive={isGameTypeModalOpen}
            >
              {currentGameTypeFilter}
              <CaretDownIcon fill={colors.gametimeGreenLight} />
            </FilterControl>
          )}
          <FilterControl
            onClick={toggleDateFilter}
            onClickOutside={handleDateFilterClickOutside}
            modal={dateRangeFilterModal}
            isActive={isDateFilterOpen}
          >
            <span className={styles['calendar-icon']}>
              <CalendarIcon height="20" width="20" fill={colors.white} />
            </span>
            <span className={styles['date-filter-text']}>
              {dateRangeFilter}
            </span>
            <CaretDownIcon fill={colors.gametimeGreenLight} />
          </FilterControl>
        </div>
      </div>
    </Section>
  );
};

export default connect()(PerformerFilters);
