import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function CheckCircleSelectedIcon({
  width = '22',
  height = '22',
  fill = colors.gametimeGreen,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      data-testid="check-circle-selected-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C4.92497 0 0 4.92498 0 11C0 17.075 4.92497 22 11 22C17.075 22 22 17.075 22 11C22 4.92498 17.075 0 11 0ZM14.1893 7.39646L9.24995 12.3358L7.31061 10.3965C7.11535 10.2012 6.79877 10.2012 6.60351 10.3965L5.8964 11.1036C5.70114 11.2988 5.70114 11.6154 5.8964 11.8107L8.54285 14.4571C8.93337 14.8476 9.56654 14.8476 9.95706 14.4571L15.6035 8.81067C15.7988 8.61541 15.7988 8.29883 15.6035 8.10357L14.8964 7.39646C14.7011 7.2012 14.3846 7.2012 14.1893 7.39646Z"
        fill={fill}
      />
    </svg>
  );
}
