import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

export const MODALS = {
  GET_APP: 'getapp',
  PRICE_BREAKDOWN: 'pricebreakdown',
  ERROR: 'error',
  LOGIN: 'login',
  SUPER_BOWL: 'superbowl',
  ZONE_DEAL_INFO: 'zone_deal_info',
  FLASH_DEAL_INFO: 'flash_deal_info',
  POST_PURCHASE_SURVEY: 'post_purchase_survey',
};

export const activeModalSelector = (globalState) =>
  globalState[REDUCERS.MODALS].activeModal;
export const activeModalPropsSelector = (globalState) =>
  globalState[REDUCERS.MODALS].props;

const MODALS_ACTION_TYPES = generateActionTypes(
  ['SHOW_MODAL', 'HIDE_MODAL'],
  REDUCERS.MODALS
);

const initialState = {
  activeModal: null,
  props: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MODALS_ACTION_TYPES.SHOW_MODAL:
      return {
        ...state,
        activeModal: action.activeModal,
        props: action.props,
      };
    case MODALS_ACTION_TYPES.HIDE_MODAL:
      return {
        ...state,
        activeModal: null,
      };
    default:
      return state;
  }
}

export const showModal = (modal, props) => ({
  type: MODALS_ACTION_TYPES.SHOW_MODAL,
  activeModal: modal,
  props,
});

export const hideModal = () => ({
  type: MODALS_ACTION_TYPES.HIDE_MODAL,
});

export const initActiveModalFromLocation =
  (query = {}) =>
  (dispatch) => {
    const modalAttributes = Object.keys(query).filter(
      (attribute) =>
        Object.values(MODALS).includes(attribute) &&
        query[attribute] &&
        query[attribute].startsWith('true')
    );

    if (modalAttributes.length) {
      dispatch(showModal(modalAttributes[0]));
    }
  };
