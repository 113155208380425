import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import Link from 'components/Link/Link';
import { CheckShieldIcon } from 'icons';
import { formatPriceWithComma } from 'utils/number';

import { Insurance } from '../InsuranceOptions/InsuranceOptions.utils';

import styles from './StickyPurchaseButtonGroup.module.scss';

function useIsStickyElementFixed() {
  const [isFixed, setIsFixed] = useState(false);

  const cachedRef = useRef<null | { cleanup: VoidFunction }>(null);

  /**
   * Callback ref for sticky element. This function will be called with a
   * reference to the sticky element whenever the value changes, then once
   * with `null` when the element is finally unmounted.
   */
  const stickyCallbackRef: React.RefCallback<HTMLDivElement> = useCallback(
    (stickyNode) => {
      // clean up previous listener instance if it exists
      if (cachedRef.current) {
        cachedRef.current.cleanup();
        cachedRef.current = null;
      }

      if (stickyNode) {
        const intersectionObserver = new IntersectionObserver(
          // the callback is called whenever (one of) the threshold value(s) is
          // crossed.
          ([entry]) => setIsFixed(entry.isIntersecting),
          {
            root: stickyNode.parentElement,
            // A value of 1 is equivalent to the observed element being 100%
            // within the root element. This can also be an array of many values
            // between 0 and 1.
            threshold: 1,
            // setting a margin of -1px (1px inset from the root element border)
            // will evaluate as "no longer 100% intersecting" when the observed
            // element is within that -1px margin.
            rootMargin: '0px 0px -1px 0px',
          }
        );
        intersectionObserver.observe(stickyNode);

        cachedRef.current = {
          cleanup() {
            intersectionObserver.unobserve(stickyNode);
          },
        };
      }
    },
    []
  );

  return [stickyCallbackRef, isFixed] as const;
}

interface Props {
  totalPrice: number;
  purchaseButton: React.ReactNode;
  currencyPrefix?: string;
  insurance?: Insurance;
}
function StickyPurchaseButtonGroup({
  totalPrice,
  purchaseButton,
  currencyPrefix = '$',
  insurance,
}: Props) {
  const [stickyCallbackRef, isFixed] = useIsStickyElementFixed();

  const formattedTotalPrice =
    insurance?.quote && insurance.isBookingInsurance ? (
      <>
        <span>
          {currencyPrefix}
          {formatPriceWithComma(totalPrice + insurance.quote.totalPrice, true)}
        </span>
        <CheckShieldIcon height={16} width={16} />
      </>
    ) : (
      <span>
        {currencyPrefix}
        {formatPriceWithComma(totalPrice)}
      </span>
    );

  return (
    <div
      ref={stickyCallbackRef}
      className={classNames(styles['sticky-purchase-button-group'], {
        [styles['is-fixed']]: isFixed,
      })}
    >
      <span className={styles['terms-and-conditions']}>
        {'By purchasing you agree to our '}
        <Link target="_blank" rel="noopener noreferrer" to="/terms-of-service">
          Terms of Use
        </Link>
        {' and '}
        <Link target="_blank" rel="noopener noreferrer" to="/privacy-policy">
          Privacy Policy
        </Link>
        .
      </span>
      <div className={styles['button-group']}>
        <div className={styles['purchase-button']}>{purchaseButton}</div>
        <div className={styles['total-price']}>
          <span className={styles['amount']}>{formattedTotalPrice}</span>
          <span className={styles['message']}>Total with fees</span>
        </div>
      </div>
    </div>
  );
}

export default StickyPurchaseButtonGroup;
