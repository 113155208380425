import React from 'react';

import { ClickTracker } from 'analytics';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import TableViewCell from 'components/TableViewCell/TableViewCell';

import styles from './MiniCard.module.scss';

interface MiniCardProps {
  title: string;
  subtitle: string;
  isSmall: boolean;
  isSearchBar: boolean;
  isActive: boolean;
  imageProps: { src: string };
  to: string;
  onClick: VoidFunction;
  clickTracker: ClickTracker | object;
}

const MiniCard = ({
  title,
  subtitle,
  isSmall,
  isSearchBar = false,
  isActive = false,
  imageProps,
  to,
  onClick,
  clickTracker,
}: MiniCardProps) => {
  const thumbnail = imageProps.src && (
    <div className={styles.thumbnail}>
      <Image src={imageProps.src} lazyLoad={false} />
    </div>
  );

  return (
    <Link to={to} onClick={onClick} clickTracker={clickTracker}>
      <TableViewCell
        thumbnail={thumbnail}
        title={title}
        subtitle={subtitle}
        isSearchBar={isSearchBar}
        isSmall={isSmall}
        isActive={isActive}
      />
    </Link>
  );
};

export default MiniCard;
