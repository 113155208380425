import { getYear, parseISO } from 'date-fns';

import { FullEvent } from 'models';
import { DAY_DATE_YEAR_FORMAT } from 'utils/datetime';

export const getSeatRange = (seats: string[] = []) => {
  if (seats.length === 0 || seats.includes('*')) {
    return null;
  }

  if (seats.length === 1) {
    return `Selected from seat: ${seats[0]}`;
  }

  return `Selected from seats: ${seats[0]} - ${seats[seats.length - 1]}`;
};

export function getDetailsDate(fullEvent: FullEvent) {
  return getYear(parseISO(fullEvent.event.datetimeLocal)) ===
    getYear(new Date())
    ? fullEvent.getDate()
    : fullEvent.getDate(DAY_DATE_YEAR_FORMAT);
}
