import React from 'react';
import classNames from 'classnames';
import _merge from 'lodash/merge';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';

import styles from './ButtonLoader.module.scss';

interface ButtonLoaderProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  loading?: boolean;
  icon?: React.ReactNode;
  text?: string;
  mpActions?: unknown;
  clickTracker?: ClickTracker | object;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

const ButtonLoader = ({
  children,
  loading,
  icon = null,
  text,
  className,
  mpActions,
  clickTracker,
  onClick,
  disabled,
  ...props
}: ButtonLoaderProps) => {
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }

    if (mpActions) {
      analytics.track(mpActions);
    }

    const trackProperties =
      clickTracker instanceof ClickTracker ? clickTracker.json() : clickTracker;

    analytics.track(
      new Click(_merge({}, clickContext, trackProperties) as Click)
    );

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      className={classNames(className, styles['button-loader'])}
      onClick={handleClick}
      disabled={disabled || loading}
      {...props}
    >
      <span className={styles['button-content']}>
        {loading ? (
          <span data-testid="loader" className={styles.loader} />
        ) : (
          icon
        )}
        {text ? <span>{text}</span> : children}
      </span>
    </button>
  );
};

export default ButtonLoader;
