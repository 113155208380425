import { ACTIONS } from './constants';

const initialState = {};

export const getFilterKey = (filters) => {
  const filterKeys = [];

  if (filters.venueSlug) {
    filterKeys.push(filters.venueSlug);
  }

  if (filters.performerSlug) {
    filterKeys.push(filters.performerSlug);
  }

  if (filters.primaryPerformerId) {
    filterKeys.push(filters.primaryPerformerId);
  }

  return filterKeys.join('+');
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_EVENTS_REQUEST_SUCCESS:
      const {
        params,
        result: { events },
      } = action;
      const filterKey = getFilterKey(params);

      if (filterKey && events && events.length) {
        return {
          ...state,
          [filterKey]: events.map((fe) => fe.event.id),
        };
      }

      return state;
    default:
      return state;
  }
}
