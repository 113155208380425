export const SEARCH_QUERY_ROUTE = 'search';

export const getSearchPath = (searchQuery, searchSessionData) => {
  // NOTE: The base URL here can be anything, this is just a hack to manipulate/generate the relative URL.
  const url = new URL(`/${SEARCH_QUERY_ROUTE}`, 'https://gametime.co');

  if (searchQuery) {
    url.searchParams.append('q', searchQuery);
  }

  if (searchSessionData) {
    url.searchParams.append('d', btoa(JSON.stringify(searchSessionData)));
  }

  return url.pathname + url.search;
};

export const SEARCH_PAGE_TYPES = {
  TYPEAHEAD: 'typeahead',
  FULL: 'full',
};
