export const sortPerformersInContextByDateAndName = (
  performerInContextA,
  performerInContextB
) => {
  /* Sort by time first and then alphabetic if the events are at the same time */
  const fullEventA = performerInContextA.nextFullEvent;
  const fullEventB = performerInContextB.nextFullEvent;

  if (fullEventA && !fullEventB) {
    return -1;
  }

  if (!fullEventA && fullEventB) {
    return 1;
  }

  if (!fullEventA && !fullEventB) {
    return 0;
  }

  const dateDiff =
    new Date(fullEventA.event.datetimeLocal) -
    new Date(fullEventB.event.datetimeLocal);

  if (dateDiff !== 0) {
    return dateDiff;
  }

  return performerInContextA.performer.name.localeCompare(
    performerInContextB.performer.name
  );
};
