import { DIVISIONS_BY_CATEGORY } from '../constants';

export const getPerformersByDivision = (performers, category) => {
  const divisionsByCategoryMap = DIVISIONS_BY_CATEGORY[category];
  if (!divisionsByCategoryMap) {
    return {};
  }

  const divisionsMap = performers.reduce((acc, p) => {
    const key = divisionsByCategoryMap[p.slug];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(p);
    return acc;
  }, {});

  return divisionsMap;
};
