import { useCallback, useEffect, useRef, useState } from 'react';

export const useCountdownTimer = (expiresAt: string) => {
  const calculateTimeLeft = useCallback(() => {
    if (!expiresAt) {
      return {
        days: 0,
        hrs: 0,
        min: 0,
        sec: 0,
        difference: 0,
      };
    }
    const difference = +new Date(expiresAt) - +new Date();
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      sec: Math.floor((difference / 1000) % 60),
      difference,
    };
  }, [expiresAt]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (expiresAt && timeLeft.difference >= 0) {
        setTimeLeft(calculateTimeLeft());
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [expiresAt, timeLeft, calculateTimeLeft]);

  return timeLeft;
};

interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface Countdown {
  isActive: boolean;
  remaining: Duration;
}

function getMSUntil(end?: number | string): number {
  if (!end) {
    return 0;
  }

  const start_ms = Date.now();
  const end_ms = new Date(end).getTime();

  return end_ms - start_ms;
}

const MS_SECOND = 1000;
const MS_MINUTE = MS_SECOND * 60;
const MS_HOUR = MS_MINUTE * 60;
const MS_DAY = MS_HOUR * 24;

function msToDuration(ms: number): Duration {
  return {
    days: Math.floor(ms / MS_DAY),
    hours: Math.floor((ms / MS_HOUR) % 24),
    minutes: Math.floor((ms / MS_MINUTE) % 60),
    seconds: Math.floor((ms / MS_SECOND) % 60),
  };
}

export function useCountdownUntil(end?: string | number): Countdown {
  const [isActive, setIsActive] = useState(false);
  const [remaining, setRemaining] = useState<Duration>(() => msToDuration(0));

  const intervalIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    function cleanupInterval() {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = undefined;
      }
    }

    function setTimer() {
      const remainingMS = getMSUntil(end);
      const isRemainingTime = remainingMS > 0;

      setIsActive(isRemainingTime);
      setRemaining(msToDuration(Math.max(remainingMS, 0)));

      if (!isRemainingTime) {
        cleanupInterval();
      }

      return isRemainingTime;
    }

    // set initial countdown value since setInterval callback only
    // runs at the end of each interval
    const isRemainingTime = setTimer();
    if (isRemainingTime) {
      intervalIdRef.current = setInterval(setTimer, MS_SECOND);
    }

    return () => {
      cleanupInterval();
    };
  }, [end]);

  return {
    remaining,
    isActive,
  };
}
