export class LoginEvent {
  constructor({ status, provider }) {
    this.status = status;
    this.provider = provider;
  }

  eventName() {
    return 'login';
  }

  toParams() {
    const params = {
      pageType: 'none',
      status: this.status,
      provider: this.provider,
    };

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }
    return params;
  }
}
