/* Deals are special attributes that can come in the listings and can add additional 
visual elements or change the way they are displayed in the event page.

We fetch the latest available deal types from the backend and map those deals into the model
described in this file.

Here's the API call for reference:
https://mobile-staging.gametime.co/v1/deals
*/
export default class Deal {
  constructor(data = {}) {
    this.slug = data.slug; // required
    this.title = data.title; // required
    this.color = data.color; // required
    this.topText = data.top_text || '';
    this.unlockDuration = data.unlock_duration || 0;
    this.unlockType = data.unlock_type || '';
    this.iconURL = data.icon_url || '';
    this.position = data.position || '';
  }
}
