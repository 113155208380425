/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const PreloadedImage = ({ src }) => {
  if (!src) {
    return null;
  }

  return (
    <Helmet>
      <link fetchpriority="high" rel="preload" as="image" href={src} />
    </Helmet>
  );
};

PreloadedImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default PreloadedImage;
