import React from 'react';
import PropTypes from 'prop-types';

import ContentSection from 'components/ContentSection/ContentSection';
import faqsToFAQPageJSONLD from 'components/JsonLD/helpers/faqsToFAQPageJSONLD';
import JsonLD from 'components/JsonLD/JsonLD';
import extractFAQs from 'helpers/extractFAQs';
import { hasFAQs } from 'helpers/extractFAQs/extractFAQs';
import { Performer, PerformerContent as PCModel } from 'models';

export default function PerformerContent({
  performerContent,
  performer,
  matchupPerformer,
  isParkingPage,
}) {
  if (
    !performerContent ||
    !performerContent.isActive ||
    !performer ||
    !performerContent.body ||
    (isParkingPage && !performerContent.parkingBody)
  ) {
    return null;
  }
  let headline = `About ${performer.name}`;

  if (performer.isSportsCategoryGroup) {
    headline = `About the ${performer.name}`;
  }

  if (matchupPerformer) {
    headline = `About ${performer.shortName} vs. ${matchupPerformer.shortName}`;
  }

  if (isParkingPage && performerContent.parkingBody) {
    headline = `About Parking for ${performer.name}`;
  }
  const contentBody = isParkingPage
    ? performerContent.parkingBody
    : performerContent.body;

  const faqs =
    contentBody && hasFAQs(contentBody) ? extractFAQs(contentBody) : null;

  return (
    <>
      {faqs && <JsonLD json={faqsToFAQPageJSONLD(faqs)} />}
      <ContentSection
        headline={headline}
        content={performerContent}
        contentBody={contentBody}
      />
    </>
  );
}

PerformerContent.propTypes = {
  performer: PropTypes.instanceOf(Performer),
  performerContent: PropTypes.instanceOf(PCModel),
  matchupPerformer: PropTypes.object,
  isParkingPage: PropTypes.bool,
};
