import { FullEvent } from 'models';
import { CategoryGroup } from 'modules/CategoryGroups/CategoryGroups.helpers';

import { CATEGORY_GROUP_TO_EVENT_TYPE_MAP } from './constants';
import EventSchema from './EventSchema';
import { MusicEventSchema } from './MusicEventSchema';
import SportEventSchema from './SportEventSchema';
import { TheaterEventSchema } from './TheaterEventSchema';
import { EventJsonLD } from './types';

export const getEventJsonLD = (fullEvent: FullEvent): EventJsonLD => {
  const categoryGroup: CategoryGroup = fullEvent.getCategoryGroup();

  const eventType = CATEGORY_GROUP_TO_EVENT_TYPE_MAP[categoryGroup];

  switch (eventType) {
    case 'MusicEvent':
      return new MusicEventSchema(fullEvent).getJsonLD();
    case 'SportsEvent':
      return new SportEventSchema(fullEvent).getJsonLD();
    case 'TheaterEvent':
      return new TheaterEventSchema(fullEvent).getJsonLD();
    default:
      return new EventSchema(fullEvent).getJsonLD();
  }
};
