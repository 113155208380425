import FullEvent from 'models/FullEvent';
import PerformerInContext from 'models/PerformerInContext';
import { normalizedHtmlText } from 'utils/strings/textUtils';

import { getEventJsonLD } from './helpers/event/event';

export function dataToJsonLD(data) {
  if (data) {
    let fullEvent;
    if (data instanceof FullEvent) {
      fullEvent = data;
    } else if (data instanceof PerformerInContext) {
      fullEvent = data.nextFullEvent;
    }

    if (!fullEvent) {
      return null;
    }
    const performer = fullEvent.getPrimaryPerformer();

    if (!performer) {
      return null;
    }

    return getEventJsonLD(fullEvent);
  }

  return null;
}

export function postDataToJSONLD(post) {
  const author = `${post.author?.firstName} ${post.author?.lastName}`;

  // Ensure this schema attribute contains only text, removing non-essentia data (images and HTML tags).
  const articleBody = normalizedHtmlText(post.body);

  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    articleBody,
    articleSection: post.categories.map((p) => p.name).join(','),
    author,
    datePublished: post.publishedAt,
    dateModified: post.publishedAt,
    headline: post.seoTitle,
    image: post.featuredImage,
    keywords: post.tags.map((t) => t.name).join(','),
    publisher: {
      '@type': 'Organization',
      name: 'Gametime',
      url: 'https://gametime.co/',
      logo: {
        '@type': 'ImageObject',
        url: 'https://gametime.co/assets/gametime_logo.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': post.url,
    },
    contributor: {
      '@type': 'Person',
      name: author,
    },
    pageEnd: 1,
    pageStart: 1,
    wordCount: articleBody.split(/\s+/).filter(Boolean).length,
  };
}
