export class NoListingsFound {
  constructor({ performer_name, event_date }) {
    this.performer_name = performer_name;
    this.event_date = event_date;
  }

  eventName() {
    return 'no_listings_found';
  }

  toParams() {
    const params = {
      performer_name: this.performer_name,
      event_date: this.event_date,
    };

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
