import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({
  headline,
  grayHeadline = false,
  children,
  className,
  showHeadline = true,
}) => (
  <section className={`${styles.section} ${className}`}>
    {showHeadline && (
      <h2
        className={classNames(styles['section-headline'], {
          [styles.gray]: grayHeadline,
        })}
      >
        {headline}
      </h2>
    )}
    {children}
  </section>
);

Section.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  grayHeadline: PropTypes.bool,
  showHeadline: PropTypes.bool,
};

export default Section;
