import React, { useState } from 'react';
import IconButton from 'ui/IconButton';

import CheckSquareIcon from 'icons/CheckSquareIcon';
import CheckSquareSelectedIcon from 'icons/CheckSquareSelectedIcon';
import colors from 'styles/colors.constants';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  isSelected?: boolean;
  onClick: React.HTMLAttributes<HTMLButtonElement>['onClick'];
}

const Checkbox = ({ isSelected = false, onClick }: CheckboxProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={styles['checkbox']}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <IconButton
        onClick={onClick}
        icon={
          isSelected ? (
            <CheckSquareSelectedIcon
              fill={isHovered ? colors.gametimeGreenDark : colors.gametimeGreen}
            />
          ) : (
            <CheckSquareIcon />
          )
        }
      />
    </div>
  );
};

export default Checkbox;
