export const FETCH_STATIC_CONTENT = 'FETCH_STATIC_CONTENT';
export const FETCH_STATIC_CONTENT_FAIL = 'FETCH_STATIC_CONTENT_FAIL';
export const FETCH_STATIC_CONTENT_SUCCESS = 'FETCH_STATIC_CONTENT_SUCCESS';

const STATIC_PAGES_WITH_CONTENT_IN_CMS = [
  'about',
  'gametime-guarantee',
  'price-guarantee',
  'share-codes',
  'privacy-policy',
  'terms-of-service',
  'cookies-policy',
  'press',
];

const hasContent = (splat) => STATIC_PAGES_WITH_CONTENT_IN_CMS.includes(splat);

export const fetchStaticContentBySlug = (splat) => (dispatch) => {
  if (!hasContent(splat)) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      FETCH_STATIC_CONTENT,
      FETCH_STATIC_CONTENT_SUCCESS,
      FETCH_STATIC_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('static', splat),
  });
};
