import React from 'react';
import classNames from 'classnames';

import styles from './FilterControl.module.scss';

export type FilterDropdownOption = {
  name: string;
  onClick: VoidFunction;
  icon?: JSX.Element;
};

interface FilterDropdownProps {
  show: boolean;
  options: FilterDropdownOption[];
}

const FilterDropdown = ({ show, options }: FilterDropdownProps) => {
  return (
    <span
      className={classNames(styles['filter-dropdown'], {
        [styles.show]: show,
      })}
    >
      {options.map((option) => (
        <span
          className={styles['filter-option']}
          key={option.name}
          onClick={option.onClick}
        >
          <span className={styles['filter-option-name']}>{option.name}</span>
          {option.icon}
        </span>
      ))}
    </span>
  );
};

export default FilterDropdown;
