import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image/Image';

import styles from './HeroContainer.module.scss';

function HeroContainer({ data, children }) {
  const { title, heroImageUrl, smSubTitle, alt } = data;

  return (
    <div className={styles['hero-container']}>
      <Image src={heroImageUrl} width="100%" alt={alt} />
      <div className={styles['info-container']}>
        <div className={styles.info}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles['sub-title']}>{smSubTitle}</div>
          <div className={styles['child-button']}>{children}</div>
        </div>
      </div>
    </div>
  );
}

HeroContainer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    heroImageUrl: PropTypes.string.isRequired,
    smSubTitle: PropTypes.string,
    alt: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default HeroContainer;
