export const FETCH_SURVEY_CONTENT = 'FETCH_SURVEY_CONTENT';
export const FETCH_SURVEY_CONTENT_FAIL = 'FETCH_SURVEY_CONTENT_FAIL';
export const FETCH_SURVEY_CONTENT_SUCCESS = 'FETCH_SURVEY_CONTENT_FAIL';

export const fetchSurveyContent = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_SURVEY_CONTENT,
      FETCH_SURVEY_CONTENT_SUCCESS,
      FETCH_SURVEY_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('*', 'post-purchase-survey-v2'),
  });
