import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import colors from 'styles/colors.constants';

import styles from './CollectionList.module.scss';

function CollectionList(props) {
  const {
    imageProps,
    imageLink,
    imageClickTracker,
    imageTitle,
    eventCells,
    eventCellsHeight,
  } = props;

  let eventCellsStyle = {};
  if (eventCellsHeight) {
    eventCellsStyle = {
      backgroundColor: colors.gray700,
    };
  }

  return (
    <div>
      {imageProps && (
        <Link
          className={classNames(styles['image-container'], {
            [styles['has-title']]: imageTitle,
          })}
          to={imageLink}
          clickTracker={imageClickTracker}
        >
          <Image {...imageProps} />
          {imageTitle && (
            <div className={styles['image-title']}>{imageTitle}</div>
          )}
        </Link>
      )}
      <div style={eventCellsStyle}>{eventCells}</div>
    </div>
  );
}

CollectionList.propTypes = {
  imageProps: PropTypes.object,
  imageLink: PropTypes.string,
  imageClickTracker: PropTypes.object,
  imageTitle: PropTypes.string,
  eventCells: PropTypes.node,
  eventCellsHeight: PropTypes.number,
};

export default CollectionList;
