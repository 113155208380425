import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'stroke'>;

export default function CheckSquareIcon({
  width = '24',
  height = '24',
  stroke = colors.gray200,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-square-icon"
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="1"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
}
