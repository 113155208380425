import React from 'react';

import AlarmClock from 'icons/AlarmClockIcon';
import FlashIcon from 'icons/FlashIcon';
import colors from 'styles/colors.constants';
import { ListingDeal } from 'types';

import styles from '../../SeatMap.module.scss';

interface SeatMapPinIconProps {
  deal?: ListingDeal & { icon?: (fill: string) => JSX.Element };
  isHighlighted?: boolean;
}

const SeatMapPinIcon = ({ deal, isHighlighted }: SeatMapPinIconProps) => {
  if (!deal) {
    return null;
  }

  if (deal.slug === 'zone') {
    return (
      <div className={styles[`${deal.slug}-deal-icon`]}>
        <AlarmClock
          width="14"
          height="14"
          fill={isHighlighted ? 'black' : deal.color}
        />
      </div>
    );
  }

  if (deal.slug === 'flash') {
    return (
      <div className={styles[`${deal.slug}-deal-icon`]}>
        <FlashIcon
          width="14"
          height="14"
          fill={isHighlighted ? 'black' : deal.color}
        />
      </div>
    );
  }

  if (deal.slug === 'great_view') {
    const fill = isHighlighted ? colors.black : colors.greatViewPurple;
    return (
      <div className={styles['great-view-deal-icon']}>{deal.icon?.(fill)}</div>
    );
  }

  return (
    <svg
      data-src={deal.iconURL}
      className={styles['featured-deal-icon']}
      fill={isHighlighted ? 'black' : deal.color}
      width="16"
      height="16"
    />
  );
};

export default SeatMapPinIcon;
