import { createSelector } from 'reselect';

import { PerformerInContext } from 'models';

const _selectPerformersInContext = (state) => state.data.performersInContextV2;

export const selectPerformersInContext = createSelector(
  _selectPerformersInContext,
  (picState) => {
    return picState.performers.map((pic) => new PerformerInContext(pic));
  }
);
