import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PromoCodeSection.module.scss';

const PromoCodeSection = ({ openPromoCodeModal, isDisabled = false }) => {
  return (
    <div className={classNames(styles['promo-code-card'])}>
      <button
        className={styles['add-button']}
        onClick={openPromoCodeModal}
        disabled={isDisabled}
      >
        Add Promo Code +
      </button>
    </div>
  );
};

PromoCodeSection.propTypes = {
  openPromoCodeModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default PromoCodeSection;
