import React from 'react';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#FFFFFF'}
      d="M4 21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V3C20 1.89543 19.1046 1 18 1H6C4.89543 1 4 1.89543 4 3V21ZM17.5 19C17.7761 19 18 18.7761 18 18.5V5.5C18 5.22386 17.7761 5 17.5 5H6.5C6.22386 5 6 5.22386 6 5.5V18.5C6 18.7761 6.22386 19 6.5 19H17.5Z"
    />
  </svg>
);
