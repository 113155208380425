import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { PatchUpdatePasswordRequestPayload } from 'types';

interface UpdatePasswordRequestParams
  extends PatchUpdatePasswordRequestPayload {
  userId: string;
}

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const updatePassword = ({
  userId,
  token,
  password,
}: UpdatePasswordRequestParams) => {
  return httpClient.request<null, PatchUpdatePasswordRequestPayload>({
    path: `/v1/user/${userId}/update-password`,
    method: 'PATCH',
    body: {
      token,
      password,
    },
  });
};
