import React from 'react';
import PropTypes from 'prop-types';

import styles from './ExtendedIconCard.module.scss';

const ExtendedIconCard = ({ inlineLink, icon, title, text, orderedList }) => {
  return (
    <div className={styles['extended-icon']}>
      {icon}
      <h3 className={styles['extended-icon-headline']}>{title}</h3>
      <p className={styles['extended-icon-subheader']}>{text}</p>
      {orderedList?.length > 0 && (
        <ol className={styles['extended-icon-list']}>
          {orderedList.map((item) => (
            <li key={item.text} className={styles['extended-icon-list-item']}>
              {item.text}
              {item.email && (
                <span className={styles['extended-icon-email']}>
                  {item.email}
                </span>
              )}
            </li>
          ))}
        </ol>
      )}
      {inlineLink}
    </div>
  );
};

ExtendedIconCard.propTypes = {
  inlineLink: PropTypes.object,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  orderedList: PropTypes.array,
};

export default ExtendedIconCard;
