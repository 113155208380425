import { TrackEvent } from './TrackEvent';

const INTERACTION_NAMES = {
  CLOSE: 'close',
  DONE: 'done',
};

export class MarketingSurveyEvent extends TrackEvent {
  static INTERACTIONS = {
    CLOSE: () => ({ interaction: INTERACTION_NAMES.CLOSE }),
    DONE: () => ({ interaction: INTERACTION_NAMES.DONE }),
  };

  _eventName = 'marketing_survey';
}
