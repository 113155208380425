import React from 'react';
import { Chip } from 'ui/Chip';
import ExternalIcon from 'ui/ExternalIcon/ExternalIcon';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { Disclosure as DisclosureModel } from 'models';

import styles from './SeatPerksBlock.module.scss';

type Props = {
  allDisclosures: Partial<Record<string, DisclosureModel>>;
  disclosures: string[];
};

type Analytics = {
  track: (arg: Click | unknown) => void;
};

export default function SeatPerksBlock({ allDisclosures, disclosures }: Props) {
  const analytics: Analytics = useAnalyticsContext();

  const onSeatPerkClick = (disclosureKey: string) => {
    const clickTracker = new ClickTracker()
      .interaction(disclosureKey)
      .sourcePageType(Click.SOURCE_PAGE_TYPES.LISTING());
    analytics.track(new Click(clickTracker.json()));
  };

  return (
    <div className={styles.disclosures}>
      <span className={styles.title}>Seat Perks</span>
      <div className={styles.perks}>
        {disclosures.map((disclosureKey) => {
          const disclosure = allDisclosures[disclosureKey];
          if (!disclosure || !disclosure.isHighlightedDisplay) {
            return null;
          }
          const disclosureIcon = disclosure.iconURL ? (
            <ExternalIcon
              key={disclosureKey}
              data-testid={`disclosure-icon-${disclosureKey}`}
              src={disclosure.iconURL}
              color={disclosure.color}
            />
          ) : null;
          return (
            <Chip
              key={disclosureKey}
              label={disclosure.title}
              icon={disclosureIcon}
              size="sm"
              onClick={() => onSeatPerkClick(disclosureKey)}
            />
          );
        })}
      </div>
    </div>
  );
}
