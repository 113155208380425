import React from 'react';

import colors from 'styles/colors.constants';

type SVGProps = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'fill'
>;
type Props = SVGProps & {
  /** Direction of the chevron, defaults to 'left' */
  direction?: 'up' | 'right' | 'down' | 'left';
  /** Fill of the SVG element, defaults to colors.gray300 (#C4C4C8) */
  color?: string;
  /** Stroke width of the SVG element, defaults to 0.5 */
  strokeWidth?: number;
};

const ChevronIcon = ({
  direction = 'left',
  width = '24',
  height = '24',
  strokeWidth = 0.5,
  color = colors.gray300,
}: Props) => {
  let rotation = 0;
  switch (direction) {
    case 'up':
      rotation = 90;
      break;
    case 'right':
      rotation = 180;
      break;
    case 'down':
      rotation = 270;
      break;
    case 'left':
    default:
      rotation = 0;
      break;
  }

  return (
    <svg
      data-testid="chevron-icon"
      width={width}
      height={height}
      viewBox="0 0 12 24"
      stroke={color}
      strokeWidth={strokeWidth}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41421 13.4142C0.633165 12.6332 0.633165 11.3668 1.41421 10.5858L10.1464 1.85355C10.3417 1.65829 10.6583 1.65829 10.8536 1.85355L12.1464 3.14645C12.3417 3.34171 12.3417 3.65829 12.1464 3.85355L4 12L12.1464 20.1464C12.3417 20.3417 12.3417 20.6583 12.1464 20.8536L10.8536 22.1464C10.6583 22.3417 10.3417 22.3417 10.1464 22.1464L1.41421 13.4142Z"
      />
    </svg>
  );
};

export default ChevronIcon;
