export const DEFAULT_LISTINGS_QUANTITY = 2;

/**
 * Get suggested listing request quantity based on available lots and preferred
 * quantity. If no available lots, return 0. If preferred quantity is not
 * available, default to DEFAULT_LISTINGS_QUANTITY. If DEFAULT_LISTINGS_QUANTITY
 * is not available, default to the lowest quantity available.
 */
export function getSuggestedQuantity(
  availableLots: number[],
  preferredQuantity?: number
): number {
  if (!availableLots.length) {
    return 0;
  }

  if (preferredQuantity && availableLots.includes(preferredQuantity)) {
    return preferredQuantity;
  } else if (availableLots.includes(DEFAULT_LISTINGS_QUANTITY)) {
    return DEFAULT_LISTINGS_QUANTITY;
  } else {
    return availableLots[0];
  }
}
