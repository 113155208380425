export const FETCH_BLOG_POST = 'FETCH_BLOG_POST';
export const FETCH_BLOG_POST_SUCCESS = 'FETCH_BLOG_POST_SUCCESS';
export const FETCH_BLOG_POST_FAIL = 'FETCH_BLOG_POST_FAIL';

export const FETCH_BLOG_POSTS = 'FETCH_BLOG_POSTS';
export const FETCH_BLOG_POSTS_SUCCESS = 'FETCH_BLOG_POSTS_SUCCESS';
export const FETCH_BLOG_POSTS_FAIL = 'FETCH_BLOG_POSTS_FAIL';

export const fetchPosts =
  ({ page = 1, pageSize = 99 }) =>
  (dispatch) =>
    dispatch({
      types: [
        FETCH_BLOG_POSTS,
        FETCH_BLOG_POSTS_SUCCESS,
        FETCH_BLOG_POSTS_FAIL,
      ],
      cms: (cms) =>
        cms.post.list({
          page,
          page_size: pageSize,
          exclude_body: true,
        }),
    });

export const fetchPostBySlug = (slug) => (dispatch) => {
  if (!slug) {
    return Promise.resolve();
  }

  return dispatch({
    types: [FETCH_BLOG_POST, FETCH_BLOG_POST_SUCCESS, FETCH_BLOG_POST_FAIL],
    cms: (cms) => cms.post.retrieve(slug),
  });
};
