import * as React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height'>;

export default function PhoneDownloadIcon({ height = 24, width = 24 }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M17 1.01 7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99ZM17 19H7V5h10v14Zm-1-6h-3V8h-2v5H8l4 4 4-4Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
