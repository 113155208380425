import React from 'react';

import { device, useMediaQuery } from 'hooks';
import { GTLogoIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './PicksBadge.module.scss';

const PicksBadge = () => {
  const isMobile = useMediaQuery(device.down.md);
  const logoSize = isMobile ? '16' : '20';

  return (
    <div className={styles['picks-badge']}>
      <span className={styles['badge-title']}>Gametime Picks</span>
      <GTLogoIcon
        width={logoSize}
        height={logoSize}
        fill={colors.gametimeGreenLight}
      />
    </div>
  );
};

export default PicksBadge;
