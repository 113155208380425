export const EVENT_TYPE = {
  NEARBY: {
    id: 'event_nearby',
    title: 'Events Nearby',
  },
  ALL: {
    id: 'all_events',
    title: 'All Events',
  },
  EVENTS: {
    id: 'events',
    title: 'Events',
  },
  PARKING: {
    id: 'parking',
    title: 'Parking',
  },
};
