export const BREADCRUMB_PAGES = {
  HOME: 'Gametime',
  CATEGORY: 'Category',
  PERFORMER: 'Performer',
};

export const HOMEPAGE_BREADCRUMB_CONFIG = {
  url: '/',
  name: BREADCRUMB_PAGES.HOME,
};
