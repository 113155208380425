export const DIVISION_NAMES = {
  aleast: 'al east',
  alcentral: 'al central',
  alwest: 'al west',
  nleast: 'nl east',
  nlcentral: 'nl central',
  nlwest: 'nl west',
  afceast: 'afc east',
  nfceast: 'nfc east',
  afcnorth: 'afc north',
  nfcnorth: 'nfc north',
  afcsouth: 'afc south',
  nfcsouth: 'nfc south',
  afcwest: 'afc west',
  nfcwest: 'nfc west',
  atlantic: 'atlantic',
  metropolitan: 'metropolitan',
  central: 'central',
  pacific: 'pacific',
  southeast: 'southeast',
  northwest: 'northwest',
  southwest: 'southwest',
  easternconference: 'eastern conference',
  westernconference: 'western conference',
  superbowl: 'super bowl',
  bigTen: 'big 10',
  bigTwelve: 'big 12',
  sec: 'sec',
  acc: 'acc',
  pacTwelve: 'pac 12',
  independent: 'other',
} as const;

export const DIVISIONS_BY_CATEGORY = {
  mlb: {
    mlbbal: 'aleast',
    mlbbos: 'aleast',
    mlbnyy: 'aleast',
    mlbtb: 'aleast',
    mlbtor: 'aleast',
    mlbcws: 'alcentral',
    mlbcle: 'alcentral',
    mlbdet: 'alcentral',
    mlbkc: 'alcentral',
    mlbmin: 'alcentral',
    mlbhou: 'alwest',
    mlblaa: 'alwest',
    mlboak: 'alwest',
    mlbsea: 'alwest',
    mlbtex: 'alwest',
    mlbatl: 'nleast',
    mlbmia: 'nleast',
    mlbnym: 'nleast',
    mlbphi: 'nleast',
    mlbwas: 'nleast',
    mlbchc: 'nlcentral',
    mlbcin: 'nlcentral',
    mlbmil: 'nlcentral',
    mlbpit: 'nlcentral',
    mlbstl: 'nlcentral',
    mlbari: 'nlwest',
    mlbcol: 'nlwest',
    mlblad: 'nlwest',
    mlbsdp: 'nlwest',
    mlbsfg: 'nlwest',
  },
  nfl: {
    nflbuf: 'afceast',
    nflmia: 'afceast',
    nflne: 'afceast',
    nflnyj: 'afceast',
    nfldal: 'nfceast',
    nflnyg: 'nfceast',
    nflphi: 'nfceast',
    nflwas: 'nfceast',
    nflbal: 'afcnorth',
    nflcin: 'afcnorth',
    nflcle: 'afcnorth',
    nflpit: 'afcnorth',
    nflchi: 'nfcnorth',
    nfldet: 'nfcnorth',
    nflgnb: 'nfcnorth',
    nflmin: 'nfcnorth',
    nflhou: 'afcsouth',
    nflind: 'afcsouth',
    nfljac: 'afcsouth',
    nflten: 'afcsouth',
    nflatl: 'nfcsouth',
    nflcar: 'nfcsouth',
    nflno: 'nfcsouth',
    nfltb: 'nfcsouth',
    nflden: 'afcwest',
    nflkc: 'afcwest',
    nflsdc: 'afcwest',
    nfloak: 'afcwest',
    nflari: 'nfcwest',
    nflla: 'nfcwest',
    nflsf: 'nfcwest',
    nflsea: 'nfcwest',
    nflsuperbowl: 'superbowl',
  },
  nhl: {
    nhlbos: 'atlantic',
    nhlbuf: 'atlantic',
    nhldet: 'atlantic',
    nhlfla: 'atlantic',
    nhlmon: 'atlantic',
    nhlott: 'atlantic',
    nhltb: 'atlantic',
    nhltor: 'atlantic',
    nhlcar: 'metropolitan',
    nhlclb: 'metropolitan',
    nhlnjd: 'metropolitan',
    nhlnyi: 'metropolitan',
    nhlnyr: 'metropolitan',
    nhlphi: 'metropolitan',
    nhlpit: 'metropolitan',
    nhlwas: 'metropolitan',
    nhlari: 'central',
    nhlchi: 'central',
    nhlcol: 'central',
    nhldal: 'central',
    nhlmin: 'central',
    nhlnsh: 'central',
    nhlstl: 'central',
    nhlwin: 'central',
    nhlana: 'pacific',
    nhlclg: 'pacific',
    nhledm: 'pacific',
    nhllak: 'pacific',
    nhlsjs: 'pacific',
    nhlsea: 'pacific',
    nhlvan: 'pacific',
    nhllav: 'pacific',
  },
  nba: {
    nbabos: 'atlantic',
    nbabrk: 'atlantic',
    nbanyk: 'atlantic',
    nbaphi: 'atlantic',
    nbator: 'atlantic',
    nbachi: 'central',
    nbacle: 'central',
    nbadet: 'central',
    nbaind: 'central',
    nbamil: 'central',
    nbaatl: 'southeast',
    nbacha: 'southeast',
    nbamia: 'southeast',
    nbaorl: 'southeast',
    nbawas: 'southeast',
    nbaden: 'northwest',
    nbamin: 'northwest',
    nbaokc: 'northwest',
    nbapor: 'northwest',
    nbauth: 'northwest',
    nbagsw: 'pacific',
    nbalac: 'pacific',
    nbalal: 'pacific',
    nbaphx: 'pacific',
    nbasac: 'pacific',
    nbadal: 'southwest',
    nbahou: 'southwest',
    nbamem: 'southwest',
    nbano: 'southwest',
    nbasa: 'southwest',
  },
  mls: {
    mlsatl: 'easternconference',
    mlschi: 'easternconference',
    mlsclb: 'easternconference',
    mlsdc: 'easternconference',
    mlscinn: 'easternconference',
    mlsmiami: 'easternconference',
    mlsmtl: 'easternconference',
    mlsnsh: 'easternconference',
    mlsne: 'easternconference',
    mlsnyfc: 'easternconference',
    mlsny: 'easternconference',
    mlsorl: 'easternconference',
    mlsphi: 'easternconference',
    mlstor: 'easternconference',
    mlsatx: 'westernconference',
    mlscol: 'westernconference',
    mlsdal: 'westernconference',
    mlshou: 'westernconference',
    mlsla: 'westernconference',
    mlslafc: 'westernconference',
    mlsminn: 'westernconference',
    mlspor: 'westernconference',
    mlsrsl: 'westernconference',
    mlssje: 'westernconference',
    mlssea: 'westernconference',
    mlskc: 'westernconference',
    mlsvan: 'westernconference',
  },
  cfb: {
    // big ten
    cfbbigtenchamp: 'bigTen',
    cfbill: 'bigTen',
    cfbind: 'bigTen',
    cfbiowa: 'bigTen',
    cfbmich: 'bigTen',
    cfbmsu: 'bigTen',
    cfbmin: 'bigTen',
    cfbneb: 'bigTen',
    cfbnu: 'bigTen',
    cfbosu: 'bigTen',
    cfbore: 'bigTen',
    cfbpsu: 'bigTen',
    cfbpur: 'bigTen',
    cfbrutg: 'bigTen',
    cfbucla: 'bigTen',
    cfbusc: 'bigTen',
    cfbwash: 'bigTen',
    cfbwis: 'bigTen',
    cfbmd: 'bigTen',
    // big twelve
    cfbari: 'bigTwelve',
    cfbasu: 'bigTwelve',
    cfbbay: 'bigTwelve',
    cfbbig12: 'bigTwelve',
    cfbbyu: 'bigTwelve',
    cfbcolo: 'bigTwelve',
    cfbhou: 'bigTwelve',
    cfbisu: 'bigTwelve',
    cfbkan: 'bigTwelve',
    cfbksu: 'bigTwelve',
    cfbokst: 'bigTwelve',
    cfbtcu: 'bigTwelve',
    cfbttu: 'bigTwelve',
    cfbucf: 'bigTwelve',
    cfbutah: 'bigTwelve',
    cfbwvu: 'bigTwelve',
    cfbcinn: 'bigTwelve',
    // sec
    cfbala: 'sec',
    cfbark: 'sec',
    cfbaub: 'sec',
    cfbfla: 'sec',
    cfbuga: 'sec',
    cfbuk: 'sec',
    cfblsu: 'sec',
    cfbmsst: 'sec',
    cfbmizz: 'sec',
    cfbokla: 'sec',
    cfbmiss: 'sec',
    cfbcottonbowl: 'sec',
    cfbsec: 'sec',
    cfbtenn: 'sec',
    cfbtxam: 'sec',
    cfbtex: 'sec',
    cfbvan: 'sec',
    cfbscar: 'sec',
    // acc
    cfbaccchamp: 'acc',
    cfbbc: 'acc',
    cfbcal: 'acc',
    cfbclem: 'acc',
    cfbduke: 'acc',
    cfbfsu: 'acc',
    cfbgt: 'acc',
    cfblou: 'acc',
    cfbmia: 'acc',
    cfbncst: 'acc',
    cfbunc: 'acc',
    cfbpitt: 'acc',
    cfbsmu: 'acc',
    cfbstan: 'acc',
    cfbsyc: 'acc',
    cfbuva: 'acc',
    cfbvt: 'acc',
    cfbwake: 'acc',
    // pac 12
    cfborst: 'pacTwelve',
    cfbwsu: 'pacTwelve',
    // indy
    cfbnd: 'independent',
  },
} as const;

export const DIVISIONS_ORDERED_KEYS_BY_CATEGORY = {
  mlb: ['aleast', 'alcentral', 'alwest', 'nleast', 'nlcentral', 'nlwest'],
  nba: [
    'atlantic',
    'central',
    'northwest',
    'pacific',
    'southeast',
    'southwest',
  ],
  nfl: [
    'afceast',
    'afcnorth',
    'afcsouth',
    'afcwest',
    'nfceast',
    'nfcnorth',
    'nfcsouth',
    'nfcwest',
    'superbowl',
  ],
  nhl: ['atlantic', 'central', 'metropolitan', 'pacific'],
  mls: ['easternconference', 'westernconference'],
  cfb: ['acc', 'bigTen', 'bigTwelve', 'pacTwelve', 'sec', 'independent'],
} as const;
