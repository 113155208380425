import { ACTIONS } from './actions';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_UPSELL_EVENTS_REQUEST_SUCCESS:
      const {
        params: { event_id },
        result: { events },
      } = action;
      return {
        ...state,
        [event_id]: events,
      };
    default:
      return state;
  }
}
