import React from 'react';
import PropTypes from 'prop-types';

import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import CircleExclamationFillIcon from 'icons/CircleExclamationFillIcon';

import styles from './InvalidCVVModal.module.scss';

export default function InvalidCVVModal({ isOpen, onClose }) {
  return (
    <GTInfoModal
      show={isOpen}
      onHide={onClose}
      icon={<CircleExclamationFillIcon />}
      title="Invalid CVV"
      buttonText="OKAY"
    >
      <span className={styles['modal-body']}>
        You have exceeded 10 attempts to verify this device. Please select
        another payment method or add a new one.
      </span>
    </GTInfoModal>
  );
}
InvalidCVVModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
