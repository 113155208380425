import { MLB_ALL_TEAMS_LINK } from 'utils/mlb';

export const selectMLBContent = (state) => state.content.mlb;

export const selectMLBTeamLinks = (state) => {
  const mlbContent = selectMLBContent(state);
  const mlbTeamLinks = mlbContent?.['mlb-team-links'];

  return mlbTeamLinks?.['mlb_team_links'];
};

export const selectMLBTeamLink = (state, props) => {
  const mlbTeamLinks = selectMLBTeamLinks(state);
  const mlbTeam = mlbTeamLinks?.find(
    (link) => link.team_name === props.performerName
  );

  return mlbTeam?.website_url ?? MLB_ALL_TEAMS_LINK;
};
