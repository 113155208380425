import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import IconButton from 'ui/IconButton';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  StaticPageClickTracker,
  useAnalyticsContext,
  View,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import iosStoreBadge from 'assets/download-on-the-app-store-badge.svg';
import blackLogoIcon from 'assets/gametime-app-icon.png';
import playStoreBadge from 'assets/get-it-on-google-play-badge.svg';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_ACTIONS } from 'components/DeepLink/DeepLink.constants';
import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import UserPhoneForm from 'components/UserPhoneForm/UserPhoneForm';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import CloseLineIcon from 'icons/CloseLineIcon';
import { sendAppDownloadSMS as sendAppDownloadSMSDispatch } from 'store/modules/user/actions';
import { updateUserPreference as updateUserPreferenceDispatch } from 'store/modules/userPreference/userPreference';
import { APP_STORE_LINKS } from 'utils/appStoreLinks';
import {
  extendDefaultBranchState,
  generateBranchLink,
  selectDefaultBranchData,
} from 'utils/branchLink';
import { denormalizePhoneNumber } from 'utils/phone';

import styles from './GetAppModal.module.scss';

const SCREENS = {
  FORM_SCREEN: 'form_screen',
  SUCCESS_SCREEN: 'success_screen',
};

const GetAppModal = ({
  show = false,
  branchData = {},
  defaultBranchData = {},
  sendAppDownloadSMS,
  updateUserPreference,
  clickTracker,
  onHide,
}) => {
  const analytics = useAnalyticsContext();
  const location = useLocation();
  const clickContext = useClickContext();
  const [currentScreen, setCurrentScreen] = useState(SCREENS.FORM_SCREEN);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery(device.down.md);
  const extendedBranchData = {
    ...extendDefaultBranchState({ defaultBranchData, location }),
    ...branchData,
  };

  const handleHide = () => {
    setCurrentScreen(SCREENS.FORM_SCREEN);
    setError(null);
    onHide();
  };

  const handleSubmit = (form) => {
    const { phone } = form;
    const appDownloadRequest = (requestError, branchLink) => {
      const tracker = clickTracker || new ClickTracker();
      tracker.payload({
        [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.SEND_SMS,
        [PAYLOAD.BRANCH_URL]: branchLink,
        [PAYLOAD.PAGE_TYPE]: View.PAGE_TYPES.INSTALL(),
        [PAYLOAD.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.INSTALL(),
      });
      const trackProperties = tracker.json();
      analytics?.track(new Click(_merge({}, clickContext, trackProperties)));

      sendAppDownloadSMS(denormalizePhoneNumber(phone), branchLink)
        .then(() => {
          updateUserPreference({ phone });

          setCurrentScreen(SCREENS.SUCCESS_SCREEN);
        })
        .catch(() => {
          setError('Please try again later.');
        });
    };

    generateBranchLink(extendedBranchData, appDownloadRequest);
  };

  const handleDownloadAppClick = (pageName) => {
    const tracker = new StaticPageClickTracker(pageName).interaction({
      interaction: 'get_app_modal',
    });

    analytics.track(new Click(_merge({}, clickContext, tracker.json())));
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case SCREENS.SUCCESS_SCREEN:
        return (
          <div className={styles.modal}>
            <IconButton
              className={styles.close}
              icon={<CloseLineIcon />}
              onClick={onHide}
            />
            <span className={styles.tada} role="img" aria-hidden="true">
              🎉
            </span>
            <h2 className={styles.title}>Sent</h2>
            <h3 className={styles.subtitle}>
              We've sent a link to your phone.
            </h3>
          </div>
        );

      case SCREENS.FORM_SCREEN:
      default:
        analytics?.track(
          new View({
            pageType: View.PAGE_TYPES.INSTALL(),
            sourcePageType: Click.SOURCE_PAGE_TYPES.INSTALL(),
            interaction: 'get_app_modal',
          })
        );

        return (
          <div className={styles.modal}>
            <IconButton
              className={styles.close}
              icon={<CloseLineIcon />}
              onClick={onHide}
            />
            <div className={styles['modal-content']}>
              <span className={styles['logo-icon']}>
                <img src={blackLogoIcon} alt="" />
              </span>
              <h2 id="get-the-app" className={styles['app-install-title']}>
                Get the App
              </h2>
              {isMobile ? (
                <DeepLink>
                  <div className={styles['app-install-icons']}>
                    <img
                      src={iosStoreBadge}
                      alt="Get the Gametime app on the Apple App Store"
                    />
                    <img
                      src={playStoreBadge}
                      alt="Get the Gametime app on Google Play"
                    />
                  </div>
                </DeepLink>
              ) : (
                <div className={styles['app-install-form']}>
                  <h3 className={styles.subtitle}>
                    Just enter your phone number and we'll
                    <br />
                    send you a link.
                  </h3>
                  <hr className={styles['form-separator']} />
                  <UserPhoneForm
                    handleUserPhoneFormSubmit={handleSubmit}
                    ctaText="Send Link"
                  />
                  {error && <span className={styles.error}>{error}</span>}
                  <p className={styles.note}>
                    <span>Available for free on the </span>
                    <a
                      className={styles.emphasis}
                      href={APP_STORE_LINKS.iOS.url}
                      onClick={() =>
                        handleDownloadAppClick(APP_STORE_LINKS.iOS.pageName)
                      }
                    >
                      App Store
                    </a>
                    <span> and </span>
                    <a
                      className={styles.emphasis}
                      href={APP_STORE_LINKS.android.url}
                      onClick={() =>
                        handleDownloadAppClick(APP_STORE_LINKS.android.pageName)
                      }
                    >
                      Google Play.
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <GTUniversalModal
      show={show}
      onHide={handleHide}
      aria={{ labelledby: 'get-the-app' }}
    >
      {renderScreen()}
    </GTUniversalModal>
  );
};

GetAppModal.propTypes = {
  show: PropTypes.bool.isRequired,
  branchData: PropTypes.object,
  defaultBranchData: PropTypes.object.isRequired,
  sendAppDownloadSMS: PropTypes.func.isRequired,
  updateUserPreference: PropTypes.func.isRequired,
  clickTracker: PropTypes.object,
  onHide: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    defaultBranchData: selectDefaultBranchData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendAppDownloadSMS: (phone, branchLink) =>
    dispatch(sendAppDownloadSMSDispatch(phone, branchLink)),
  updateUserPreference: (preference) =>
    dispatch(updateUserPreferenceDispatch(preference)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetAppModal);
