import { createSelector } from 'reselect';

import PerformerContent from 'models/content/PerformerContent';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _selectPerformersContent = (state) => state.content.performers;

const _selectPerformerContentBySlug = (state, slug) =>
  _selectPerformersContent(state)[slug];

/* Returns Models */
export const selectPerformerContentBySlug = createSelector(
  _selectPerformerContentBySlug,
  (performerContentObject) =>
    performerContentObject ? new PerformerContent(performerContentObject) : null
);

export const selectRelatedPerformers = (state, slug) =>
  state.content.performers[slug]?.related_performers;

const emptyArray = []; // added to help with memoization
export const selectPerformerEventsContent = (state) => {
  const performerContent =
    state.content.performers.homepage?.cant_miss_events || emptyArray;
  if (performerContent.length > 2) {
    return performerContent.slice(0, 2);
  }
  return performerContent;
};
