import React from 'react';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import { Disclosure, Listing } from 'models';
import ListingFocusedCard from 'pages/Event/components/ListingFocusedCard';
import { hasHighlightedDisclosure } from 'store/modules/data/Listings/utils';

import styles from './GalleryViewTooltip.module.scss';

interface GalleryViewTooltipProps {
  children: React.ReactNode;
  listing: Listing;
  isAllInPrice: boolean;
  disableTooltip?: boolean;
  allDisclosures: Record<string, Disclosure>;
  isMLBEvent: boolean;
  onHover: () => void;
}

const GalleryViewTooltip = ({
  children,
  listing,
  isAllInPrice,
  disableTooltip = true,
  allDisclosures,
  isMLBEvent,
  onHover,
}: GalleryViewTooltipProps) => {
  if (disableTooltip) {
    return <>{children}</>;
  }

  const withHighlighteDisclosures: boolean = hasHighlightedDisclosure(
    listing.disclosures,
    allDisclosures
  );

  return (
    <Tooltip
      zIndex={10000}
      showArrow={false}
      placement="topLeft"
      overlayClassName="gallery-view-tooltip"
      trigger={['hover']}
      mouseLeaveDelay={0}
      overlayStyle={{
        padding: '4px 0px',
        opacity: 1,
      }}
      overlayInnerStyle={{ padding: 0 }}
      align={{ overflow: { adjustX: true, adjustY: true } }}
      mouseEnterDelay={0.2}
      afterVisibleChange={(visible) => visible && onHover()}
      overlay={
        <div
          className={classNames(styles['tooltip-container'], {
            [styles['highlighted-disclosures']]: withHighlighteDisclosures,
          })}
        >
          <ListingFocusedCard
            bordered
            listing={listing}
            allDisclosures={allDisclosures}
            isAllInPrice={isAllInPrice}
            isMLBEvent={isMLBEvent}
          />
        </div>
      }
    >
      <div className={styles['marker-container']}>{children}</div>
    </Tooltip>
  );
};

export default GalleryViewTooltip;
