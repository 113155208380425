/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useLockBodyScroll from 'hooks/useLockBodyScroll';

import styles from './Backdrop.module.scss';

const VARIANTS = {
  default: 'default',
  header: 'header',
};

const Backdrop = ({
  variant = VARIANTS.default,
  onClick,
  onTouchEnd,
  topPosition = 0,
  children,
}) => {
  useLockBodyScroll();

  const classes = classNames([
    {
      [styles['backdrop-header']]: variant === VARIANTS.header,
      [styles.backdrop]: variant === VARIANTS.default,
      [styles['backdrop-click']]: onClick !== undefined,
    },
  ]);

  return (
    <div
      className={classes}
      onClick={onClick}
      onTouchEnd={onTouchEnd}
      style={{ top: topPosition }}
    >
      {children}
    </div>
  );
};

Backdrop.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  onClick: PropTypes.func,
  topPosition: PropTypes.number,
  children: PropTypes.node,
  onTouchEnd: PropTypes.func,
};

export default memo(Backdrop);
