import React from 'react';
import PropTypes from 'prop-types';

import { PAYLOAD, PerformerClickTracker } from 'analytics';
import { EVENT_CELL_TYPES } from 'components/Event/EventCell/EventCell';
import EventContainer from 'components/Event/EventContainer';
import { getCategoryName } from 'store/modules/categories/category.helpers';

import MiniCard from '../SearchResults/MiniCard/MiniCard';

import styles from '../SearchResults/SearchResults.module.scss';

export const TYPES = {
  teams: 'teams',
  events: 'events',
};

const SearchRecommendedList = React.memo(
  ({ collection = [], type, onClick, isViewedEvents = false }) => {
    if (collection.length === 0) {
      return null;
    }

    if (type === TYPES.teams) {
      return (
        <>
          <div className={styles['section-title']}>Teams Near You</div>
          {collection.map((team) => {
            const track = new PerformerClickTracker(team).payload({
              [PAYLOAD.SECTION_TITLE]: 'Teams Near You',
            });

            return (
              <div key={team.id} className={styles.eventItem}>
                <MiniCard
                  key={team.id}
                  imageProps={team.getImageOptions()}
                  title={team.name}
                  subtitle={getCategoryName(team.category)}
                  to={team.getPath()}
                  clickTracker={track}
                  onClick={onClick}
                  isSearchBar
                />
              </div>
            );
          })}
        </>
      );
    }

    if (type === TYPES.events) {
      const eventType = isViewedEvents
        ? 'Recently Viewed Events'
        : 'Popular Events Near You';

      const validCollection = collection.filter((event) => {
        return event.isValid();
      });

      if (validCollection.length === 0) {
        return null;
      }

      return (
        <>
          <div className={styles['section-title']}>{eventType}</div>
          {validCollection.map((event, index) => {
            const track = new PerformerClickTracker(event).payload({
              [PAYLOAD.SECTION_TITLE]: eventType,
            });

            if (isViewedEvents && index > 2) {
              return null;
            }

            return (
              <div key={event.event.id} className={styles.eventItem}>
                <EventContainer
                  fullEvent={event}
                  type={EVENT_CELL_TYPES.SHORT}
                  clickTracker={track}
                  onClick={onClick}
                  isSearchBar
                />
              </div>
            );
          })}
        </>
      );
    }

    return null;
  }
);

SearchRecommendedList.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf([TYPES.teams, TYPES.events]),
  onClick: PropTypes.func,
  isViewedEvents: PropTypes.bool,
};

export default SearchRecommendedList;
