import React from 'react';

import styles from './Avatar.module.scss';

interface Props {
  letter: string;
}

export default function Avatar({ letter }: Props) {
  return (
    <div className={styles['avatar-circle']}>
      <div className={styles['avatar-inner-circle']}>
        <span className={styles['avatar-circle-letter']}>{letter}</span>
      </div>
    </div>
  );
}
