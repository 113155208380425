export const addQuery = (path, search, queryObj) => {
  const params = new URLSearchParams(search);
  const keys = Object.keys(queryObj);
  keys.forEach((key) => {
    if (queryObj[key]) params.set(key, queryObj[key]);
  });
  return params.toString() ? `${path}?${params.toString()}` : path;
};

export const isInvalidHref = (href) => !href || href.trim() === '#';

export const hasQuery = (search, key, value) => {
  const params = new URLSearchParams(search);
  if (!params.has(key)) return false;
  return params.get(key) === value;
};

const STANDARD_UTM_PARAMS = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
};

/**
 * Returns an object containing standard and custom UTM parameters from a
 * valid URLSearchParams init value. The five standard UTM parameters are
 * prioritized first, then any additional parameters beginning with "utm_"
 * are included in the order they are listed, up to an optionally specified
 * limit. Duplicate parameters will only use the first value,
 * subsequent occurrences will be omitted.
 *
 * Current default limit is 20 as requested by Growth team.
 *
 * see: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export function getUTMSearchParams(init, limit = 20) {
  const searchParams = new URLSearchParams(init);

  const params = {};

  // prioritize standard UTM params and remove from collection
  Object.values(STANDARD_UTM_PARAMS).forEach((key) => {
    if (searchParams.has(key)) {
      params[key] = searchParams.get(key);
      searchParams.delete(key);
    }
  });

  // collect remaining custom UTM params in order received up to limit
  for (const [key, value] of searchParams) {
    if (Object.keys(params).length >= limit) {
      break;
    }

    if (key.startsWith('utm_') && !params[key]) {
      params[key] = value;
    }
  }

  return params;
}

export function secureUrl(url) {
  if (!url || url.trim() === '') {
    return url;
  }
  if (url.startsWith('http://')) {
    return 'https://' + url.slice(7);
  }
  return url;
}

export const queryObjectToSearchString = (query = {}) => {
  const params = new URLSearchParams();
  for (const key in query) {
    if (query[key] !== undefined) {
      params.append(key, query[key]);
    }
  }

  return params.toString();
};

export const searchStringToQueryObject = (search = '') =>
  Object.fromEntries(new URLSearchParams(search));

export const removeQueryParams = (search, keys) => {
  const params = new URLSearchParams(search);

  keys.forEach((key) => {
    params.delete(key);
  });

  const stringParams = params.toString();
  return stringParams !== '' ? `?${stringParams}` : '';
};
