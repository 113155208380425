import React from 'react';

export default (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2348 5H16.755C14.127 5 12 7.04217 12 9.54358V38.4564C12 40.9676 14.1372 43 16.755 43H31.245C33.873 43 36 40.9578 36 38.4564V9.54358C36 7.04217 33.8628 5 31.2348 5ZM34.4661 32.9064V38.4662C34.4661 40.1664 33.0243 41.5441 31.245 41.5441H16.755C14.9757 41.5441 13.5339 40.1664 13.5339 38.4662V30.0532C13.5381 30.0532 13.5423 30.0515 13.5473 30.0496C13.5543 30.0468 13.5628 30.0435 13.5748 30.0435V28.9589C13.5697 28.9589 13.5646 28.9613 13.5594 28.9637C13.5543 28.9662 13.5492 28.9686 13.5441 28.9686V17.5852C13.5492 17.5852 13.5543 17.5877 13.5594 17.5901C13.5646 17.5926 13.5697 17.595 13.5748 17.595V16.5104C13.5705 16.5104 13.5663 16.5087 13.5613 16.5068C13.5543 16.504 13.5459 16.5006 13.5339 16.5006V9.54358C13.5339 7.8434 14.9757 6.46567 16.755 6.46567H18.8513V6.92492C18.8513 7.58935 19.4137 8.12677 20.1091 8.12677H27.8807C28.5761 8.12677 29.1385 7.58935 29.1385 6.92492V6.46567H31.2348C33.0141 6.46567 34.4559 7.8434 34.4559 9.54358V15.0643L34.4661 32.9064Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M31.2348 5H16.755C14.127 5 12 7.04217 12 9.54358V38.4564C12 40.9676 14.1372 43 16.755 43H31.245C33.873 43 36 40.9578 36 38.4564V9.54358C36 7.04217 33.8628 5 31.2348 5ZM34.4661 32.9064V38.4662C34.4661 40.1664 33.0243 41.5441 31.245 41.5441H16.755C14.9757 41.5441 13.5339 40.1664 13.5339 38.4662V30.0532C13.5381 30.0532 13.5423 30.0515 13.5473 30.0496C13.5543 30.0468 13.5628 30.0435 13.5748 30.0435V28.9589C13.5697 28.9589 13.5646 28.9613 13.5594 28.9637C13.5543 28.9662 13.5492 28.9686 13.5441 28.9686V17.5852C13.5492 17.5852 13.5543 17.5877 13.5594 17.5901C13.5646 17.5926 13.5697 17.595 13.5748 17.595V16.5104C13.5705 16.5104 13.5663 16.5087 13.5613 16.5068C13.5543 16.504 13.5459 16.5006 13.5339 16.5006V9.54358C13.5339 7.8434 14.9757 6.46567 16.755 6.46567H18.8513V6.92492C18.8513 7.58935 19.4137 8.12677 20.1091 8.12677H27.8807C28.5761 8.12677 29.1385 7.58935 29.1385 6.92492V6.46567H31.2348C33.0141 6.46567 34.4559 7.8434 34.4559 9.54358V15.0643L34.4661 32.9064Z"
      stroke={props.color || '#fff'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5854 25.4775L28.5677 19.8958C28.7317 19.7427 28.9863 19.7427 29.1504 19.8958L30.2006 20.8757C30.3816 21.0446 30.3816 21.3314 30.2006 21.5003L23.4235 27.8235C22.9607 28.2554 22.2102 28.2554 21.7473 27.8235L18.5261 24.818C18.3451 24.6492 18.3451 24.3623 18.5261 24.1935L19.5763 23.2136C19.7404 23.0605 19.995 23.0605 20.159 23.2136L22.5854 25.4775Z"
      fill={props.color || '#fff'}
    />
  </svg>
);
