import React from 'react';
import PropTypes from 'prop-types';

import { TicketClickTracker } from 'analytics';
import IconCard from 'components/IconCard/IconCard';
import TicketIcon from 'icons/TicketIcon';
import { ACTIVE_RESALE_STATES } from 'pages/MyTickets/resale.constants';
import colors from 'styles/colors.constants';

import TicketCard from '../components/TicketCard/TicketCard';

import styles from './DetailTicketsCard.module.scss';

const DetailTicketsCard = ({
  tickets,
  sectionGroup,
  section,
  row,
  primaryPerformerSlug,
  eventId,
  transactionId,
  deliveryFormat,
  disclosures,
  allDisclosures,
  isHardDeliveryType,
}) => {
  const ownedTickets = tickets.filter(
    (ticket) => !ACTIVE_RESALE_STATES.includes(ticket.resale_info.status)
  );

  return (
    <IconCard
      icon={<TicketIcon fill={colors.gray200} />}
      title={
        ownedTickets.length === 1
          ? '1 Ticket'
          : `${ownedTickets.length} Tickets`
      }
      allDisclosures={allDisclosures}
      disclosures={disclosures}
    >
      {ownedTickets.length !== tickets.length && (
        <div className={styles['resale-disclaimer']}>
          Tickets being resold from this order have been hidden.
        </div>
      )}
      {!isHardDeliveryType &&
        ownedTickets.map((ticket) => {
          const clickTracker = new TicketClickTracker(
            ticket.id,
            transactionId,
            eventId
          );

          return (
            <TicketCard
              key={ticket.id}
              ticket={ticket}
              sectionGroup={sectionGroup}
              section={section}
              row={row}
              primaryPerformerSlug={primaryPerformerSlug}
              clickTracker={clickTracker}
              deliveryFormat={deliveryFormat}
            />
          );
        })}
    </IconCard>
  );
};

DetailTicketsCard.propTypes = {
  tickets: PropTypes.array.isRequired,
  sectionGroup: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  primaryPerformerSlug: PropTypes.string.isRequired,
  eventId: PropTypes.string,
  transactionId: PropTypes.string,
  deliveryFormat: PropTypes.string,
  disclosures: PropTypes.array,
  isHardDeliveryType: PropTypes.bool,
  allDisclosures: PropTypes.object,
};

export default DetailTicketsCard;
