import config from 'config/production.config';
import { FullEvent } from 'models';
import { getCurrentDateInISO } from 'utils/datetime';

import { OFFER_AVAILABILITY, OFFER_TYPES, PRICE_CURRENCIES } from './constants';
import { Offer } from './types';

export function getOfferSchema(fullEvent: FullEvent): Offer {
  const highPrice = fullEvent.highPrice;

  const offerSchema: Offer = {
    '@type': OFFER_TYPES.AGGREGATE_OFFER,
    lowPrice: fullEvent.getPrice(),
    priceCurrency: PRICE_CURRENCIES.USD,
    price: fullEvent.getPrice(),
    url: `https://${config.domain}${fullEvent.getPath()}`,
    validFrom: getCurrentDateInISO(),
    validThrough: fullEvent.event.salesCutOff,
    availability: fullEvent.isValid()
      ? OFFER_AVAILABILITY.IN_STOCK
      : OFFER_AVAILABILITY.SOLD_OUT,
  };

  if (highPrice) {
    offerSchema.highPrice = highPrice;
  }

  return offerSchema;
}
