import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import styles from './DealsSlider.module.scss';

export const navigate = {
  prev: 'prev',
  next: 'next',
};

const DealsSliderNavigation = ({ onClick, disabledPrev, disabledNext }) => (
  <nav
    role="navigation"
    aria-label="deals slider"
    className={styles['slider-navigation']}
  >
    <button
      role="button"
      aria-label="Previous"
      disabled={disabledPrev}
      className={classNames([
        styles['slider-navigation-button'],
        styles['button-prev'],
      ])}
      onClick={() => onClick(navigate.prev)}
    >
      <ChevronIcon color={colors.white} width={16} height={16} />
    </button>
    <button
      role="button"
      aria-label="Next"
      disabled={disabledNext}
      className={classNames([
        styles['slider-navigation-button'],
        styles['button-next'],
      ])}
      onClick={() => onClick(navigate.next)}
    >
      <ChevronIcon
        direction="right"
        color={colors.white}
        width={16}
        height={16}
      />
    </button>
  </nav>
);

DealsSliderNavigation.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabledPrev: PropTypes.bool.isRequired,
  disabledNext: PropTypes.bool.isRequired,
};

export default DealsSliderNavigation;
