import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withRouter, { WithRouterProps } from 'hoc/withRouter';

import { BTN_TYPES, Click, DeepLinkClickTracker, PAYLOAD } from 'analytics';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_CAMPAIGNS } from 'components/DeepLink/DeepLink.constants';
import PreloadedImage from 'components/Head/PreloadedImage';
import { device, useMediaQuery } from 'hooks';
import {
  HERO_IMAGE_BOTTOM_URL,
  HERO_IMAGE_TOP_URL,
  PICKS_VIDEO_SRC,
} from 'pages/constants';
import {
  extendDefaultBranchState,
  generateBranchLink,
  selectDefaultBranchData,
} from 'utils/branchLink';

import PicksBadge from '../PicksBadge/PicksBadge';

import styles from './PicksHero.module.scss';

interface PicksHeroProps {
  branchData: Record<string, unknown>;
}

const PicksHero = ({ branchData }: PicksHeroProps) => {
  const [branchHref, setBranchHref] = useState('');
  const isMobile = useMediaQuery(device.down.md);

  useEffect(() => {
    const branchCallback = (error: unknown, branchHref: string) => {
      if (error) {
        console.error(error);
        return;
      }
      setBranchHref(branchHref);
    };

    generateBranchLink(branchData, branchCallback);
  }, [branchData]);

  return (
    <>
      <PreloadedImage src={HERO_IMAGE_TOP_URL} />
      <div
        style={{ backgroundImage: `url(${HERO_IMAGE_TOP_URL})` }}
        className={styles['hero-top']}
      >
        <PicksBadge />
        <h1 className={styles['hero-title']}>
          Amazing Seats. Dynamic Deals. Less Hassle.
        </h1>
        <p className={styles['hero-description']}>
          Gametime Picks is the easiest way to find and buy tickets to live
          events. Get the best deals on great seats at incredible events near
          you, all with less hassle.
        </p>
        <DeepLink
          href={branchHref}
          preventLinkFetch
          campaign={DEEPLINK_CAMPAIGNS.HOME_PAGE}
          clickTracker={new DeepLinkClickTracker(isMobile).interaction(
            Click.INTERACTIONS.BUTTON(),
            {
              [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
              [PAYLOAD.SECTION]: 'get-app-picks',
            }
          )}
          fireFacebookEvent
        >
          <button className={styles['hero-cta']}>Download the App</button>
        </DeepLink>
      </div>
      <div
        style={{ backgroundImage: `url(${HERO_IMAGE_BOTTOM_URL})` }}
        className={styles['hero-bottom']}
      >
        <div className={styles['hero-video-container']}>
          <iframe
            className={styles['hero-video']}
            title="vimeo-player"
            src={PICKS_VIDEO_SRC}
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: unknown, props: WithRouterProps) => ({
  branchData: extendDefaultBranchState({
    defaultBranchData: selectDefaultBranchData(state),
    campaign: DEEPLINK_CAMPAIGNS.HOME_PAGE,
    location: props.location,
  }),
});

export default withRouter(connect(mapStateToProps)(PicksHero));
