import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TYPES } from 'components/Buttons/SimpleButton';
import Link from 'components/Link/Link';

import styles from './Buttons.module.scss';

const SimpleLinkButton = ({
  text = '',
  icon = null,
  type,
  className,
  ...rest
}) => (
  <Link
    {...rest}
    className={classNames(className, styles[type], styles['simple-button'])}
  >
    {text && <span className={styles.text}>{text}</span>}
    {icon && <span className={styles.icon}>{icon}</span>}
  </Link>
);

SimpleLinkButton.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node,
};

export default SimpleLinkButton;
