import { useContext } from 'react';

import { TransformContext } from '../components/TransformProvider';
import { getContext } from '../utils';

const useTransformContext = () => {
  const libraryContext = useContext(TransformContext);

  if (!libraryContext.contextInstance) {
    throw new Error('Transform context must be placed inside TransformWrapper');
  }

  return getContext(libraryContext.contextInstance);
};

export default useTransformContext;
