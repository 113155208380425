import React from 'react';
import classNames from 'classnames';

import { Performer } from 'models';

import styles from './FullEventsSection.module.scss';

interface EventsSectionHeaderProps {
  performer: Performer;
  isEventsEmpty: boolean;
  renderNoEvents?: boolean;
  eventType: string;
}

const EventsSectionHeader = ({
  performer,
  isEventsEmpty = false,
  renderNoEvents,
  eventType,
}: EventsSectionHeaderProps) => {
  return (
    <>
      <div
        className={classNames(styles['section-title-container'], {
          [styles['section-separator']]: !renderNoEvents && isEventsEmpty,
        })}
      >
        <h2 className={styles['section-title']}>
          {`All ${performer.shortName} ${eventType}`}
        </h2>
      </div>
      {renderNoEvents && (
        <div className={styles['no-upcoming-container']}>
          <span className={styles['no-upcoming']}>
            {`No Upcoming ${eventType}`}
          </span>
        </div>
      )}
    </>
  );
};

export default EventsSectionHeader;
