import React from 'react';
import PropTypes from 'prop-types';

import IconCard from 'components/IconCard/IconCard';
import { useSeatDescription } from 'components/SeatDescription/useSeatDescription';
import SeatsIcon from 'icons/SeatsIcon';
import colors from 'styles/colors.constants';

const DetailListingCard = ({
  sectionGroup,
  section,
  row,
  seatCount,
  disclosures,
  allDisclosures,
  svgFill = colors.gray200,
  uniformPadding,
}) => {
  const { title, description } = useSeatDescription({
    sectionGroup,
    section,
    row,
    seatCount,
  });

  return (
    <IconCard
      icon={<SeatsIcon fill={svgFill} />}
      title={title}
      subtitle={description}
      allDisclosures={allDisclosures}
      disclosures={disclosures}
      uniformPadding={uniformPadding}
    />
  );
};

DetailListingCard.propTypes = {
  sectionGroup: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  seatCount: PropTypes.number.isRequired,
  disclosures: PropTypes.array,
  allDisclosures: PropTypes.object,
  svgFill: PropTypes.string,
  uniformPadding: PropTypes.bool,
};

export default DetailListingCard;
