import React, { Component } from 'react';
import { connect } from 'react-redux';
import { redirect } from 'react-router-dom';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import withNavigationProps from 'hoc/withNavigationProps';
import withRouter from 'hoc/withRouter';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  Hover,
  HoverTracker,
  INTERACTION,
  ListingClickTracker,
  LOCKED_DEAL,
  mapListingTrackingData,
  NoListingsFound,
  PAYLOAD,
  PerformerClickTracker,
  PinsRendered,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
  ZoomEvent,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { UnlockDealEvent } from 'analytics/events/UnlockDeal';
import {
  hasSeenSBModal,
  isListingZoneDeal,
  setSBModalSeen,
} from 'components/Deals/helpers';
import EnsurePath from 'components/EnsurePath/EnsurePath';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import { SUGGESTED_SEAT_COUNT_NOTIFICATION } from 'components/Notifications/constants';
import { showNotification } from 'components/Notifications/Notifications';
import {
  selectIsNoNFLAutozoomDesktopExperiment,
  selectIsWebExclusivesV1Experiment,
  selectIsWebExclusivesV3Experiment,
} from 'experiments';
import { mqSize } from 'hooks/useMediaQuery';
import { FullEvent } from 'models';
import Listing from 'models/Listing';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { GalleryViewCard } from 'pages/Event/components/GalleryView';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import { eventType as mapEventType } from 'pages/Event/components/ListingsMapView/SeatMapInteraction';
import OmnibarOptions from 'pages/Event/components/OmnibarOptions/OmnibarOptions';
import NotFound from 'pages/NotFound/NotFound';
import { PURCHASE_PATH } from 'pages/Purchase/constants';
import { REDIRECT_PERMANENT_STATUS } from 'server/redirects/constants';
import {
  currentLocationSelector,
  setServerRedirectPath,
  showAppSpinner,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import {
  isGTPicksFilterSelector,
  lastZoomLevelSelector,
  setGTPicksFilter,
  updateLastZoomLevel as updateLastZoomLevelDispatch,
} from 'store/modules/app/app.ui';
import { CATEGORIES } from 'store/modules/categories/category.helpers';
import {
  eventsPageDataSelector,
  updateEventsPageData,
} from 'store/modules/data/eventsPageData';
import {
  fetchFullEventById,
  fetchFullEventsByPrimaryPerformerId as fetchFullEventsByPrimaryPerformerIdDispatch,
} from 'store/modules/data/FullEvents/actions';
import {
  selectFullEventById,
  selectFullEventsByPrimaryPerformerId,
} from 'store/modules/data/FullEvents/selectors';
import {
  setHoveredListingId,
  setLockedCarouselHover,
  updateMapHarmony as updateMapHarmonyDispatch,
  updateUnlockedSponsoredDeals,
} from 'store/modules/data/Listings/actions';
import {
  hoveredListingIdSelector,
  isLockedCarouselHoveredSelector,
  isZoneUnlockedSelector,
  listMapHarmonyToggleSelector,
  selectIsVenueAllInPrice,
} from 'store/modules/data/Listings/selectors';
import { makeGetSelectPerformersByCategory } from 'store/modules/data/Performers/selectors';
import { setNewViewedEvent } from 'store/modules/data/Search/actions';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import {
  isBuyRoute,
  isCheckoutPage,
  isEventPage,
  isEventPagePreListings,
  isEventSubRoute,
  isListingDetailsPage,
  isListingPage,
  isPerformerPage,
  shouldShowListingDetailsOverlay,
} from 'store/modules/history/history';
import { fetchListings } from 'store/modules/listings/actions';
import {
  selectAvailableLots,
  selectDisplayedListingIds,
  selectDisplayedListings,
  selectHasInventory,
  selectHighestPriceListing,
  selectIsAllInPricing,
  selectListingById,
  selectListingPriceQuartile,
  selectListingsInDisplayGroups,
  selectListingsQuantity,
} from 'store/modules/listings/selectors';
import { MODALS, showModal } from 'store/modules/modals/modals';
import {
  fetchDeals,
  fetchDisclosures,
  fetchMetros,
} from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  selectAllDeals,
} from 'store/modules/resources/resource.selectors';
import { updateSeatMap } from 'store/modules/seatmap/seatmap';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import {
  SORT_IDS,
  SORT_ORDER,
  userPreferenceSeatCountSelector,
  userPreferenceShowAllInPriceSelector,
  userPreferredSortIdSelector,
} from 'store/modules/userPreference/user.preference.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { MOBILE_VIEW } from 'types/event';
import { formatDate, isPastDate } from 'utils/datetime';
import { isSuperBowl } from 'utils/superBowl';
import { addQuery, searchStringToQueryObject } from 'utils/url';

import { EventProvider } from '../../contexts/EventContext';

import EventBar from './components/EventBar';
import EventHeader from './components/EventHeader';
import EventMeta from './components/EventMeta';
import InvalidEvent from './components/InvalidEvent';
import ListingDetails from './components/ListingDetailContainer/ListingDetails';
import ListingsMapView from './components/ListingsMapView/ListingsMapView';
import MapListSwitch from './components/MapListSwitch';
import NoInventory from './components/NoInventory';
import { EVENTBAR_VIEWS } from './Event.constants';
import { getLastRoute, getPageViewType, isCanadianProvince } from './helpers';

import styles from './Event.module.scss';

const isPurchaseRoute = (pathname = '') => pathname.includes(PURCHASE_PATH);

const isListingRoute = (props) => {
  const currentPath = props.location.pathname;
  return currentPath.includes('listings');
};

const propTypes = {
  children: PropTypes.node,
  fullEvent: PropTypes.instanceOf(FullEvent),
  displayedListings: PropTypes.arrayOf(PropTypes.instanceOf(Listing))
    .isRequired,
  listingsInDisplayGroups: PropTypes.arrayOf(
    PropTypes.shape({
      deal: PropTypes.string,
      listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
      type: PropTypes.oneOf(['carousel', 'list', 'deals_list']).isRequired,
    })
  ).isRequired,
  listing: PropTypes.instanceOf(Listing),
  schedule: PropTypes.array,
  user: PropTypes.object,
  quantity: PropTypes.number.isRequired,
  showFullHeader: PropTypes.bool,
  sortId: PropTypes.string,
  showSuggestedSeatCount: PropTypes.bool,
  updateUserPreference: PropTypes.func,
  availableSeatCounts: PropTypes.array,
  location: PropTypes.object.isRequired,
  performersByCategory: PropTypes.array,
  noInventory: PropTypes.bool,
  query: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  lastZoomLevel: PropTypes.number,
  getListings: PropTypes.func.isRequired,
  updateLastZoomLevel: PropTypes.func.isRequired,
  isAllInPriceActive: PropTypes.bool,
  showAllInPrice: PropTypes.bool,
  setLockedCarouselHover: PropTypes.func,
  priceQuartile: PropTypes.number,
  showSuperBowlModal: PropTypes.bool.isRequired,
  isLockedCarouselHovered: PropTypes.bool,
  fetchFullEventsByPrimaryPerformerId: PropTypes.func,
  updateMapHarmony: PropTypes.func,
  isListMapHarmonyToggleIsOn: PropTypes.bool,
  updateUnlockedSponsoredDeals: PropTypes.func,
  setHoveredListingId: PropTypes.func,
  hoveredListingId: PropTypes.string,
  dealsTracking: PropTypes.shape({
    hasFlashDealsInEvent: PropTypes.bool.isRequired,
    hasZoneDealsInEvent: PropTypes.bool.isRequired,
    hasFeaturedDealsInEvent: PropTypes.bool.isRequired,
    zoneListingsIds: PropTypes.string,
    zoneListingMode: PropTypes.oneOf([LOCKED_DEAL.LIST, LOCKED_DEAL.CAROUSEL]),
  }).isRequired,
  eventPageData: PropTypes.shape({
    eventPageRequestsStartTime: PropTypes.number,
    eventPageRequestsFinishTime: PropTypes.number,
  }),
  appContext: PropTypes.shape({
    state: PropTypes.shape({
      isMobile: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  searchTestData: PropTypes.shape({
    testId: PropTypes.string,
    variantId: PropTypes.string,
  }),
  router: PropTypes.object.isRequired,
  lastRouteLocation: PropTypes.object,
  isExclusivesV1: PropTypes.bool.isRequired,
  setServerRedirectPath: PropTypes.func.isRequired,
  isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
  isGTPicksFilterActive: PropTypes.bool.isRequired,
  setGTPicksFilter: PropTypes.func.isRequired,
};

const mapPropsToPathname = (props) => {
  const { fullEvent } = props;

  if (!fullEvent || !fullEvent.isValid()) {
    return null;
  }

  if (isListingRoute(props)) {
    return location.pathname;
  }

  return fullEvent.getPath();
};

@withAppContext
@EnsurePath(mapPropsToPathname)
@TrackPageView(
  ({
    fullEvent,
    listing,
    quantity,
    sortId,
    query,
    isListMapHarmonyToggleIsOn,
    algoliaFields,
    dealsTracking,
    searchTestData,
    ...props
  }) => {
    const { isMobile } = props.appContext.state;
    const mode = getPageViewType(query, isMobile);

    return View.PAGE_TYPES.EVENT({
      fullEvent,
      listing,
      quantity,
      sort: sortId,
      isListingRoute: isListingRoute(props),
      mode,
      dealsTracking,
      payload: {
        harmony_enabled: isListMapHarmonyToggleIsOn,
        [PAYLOAD.QUERY_ID]: algoliaFields?.queryId,
        [PAYLOAD.RESULT_POSITION]: algoliaFields?.resultPosition,
        [PAYLOAD.SEARCH_INDEX]: algoliaFields?.searchIndex,
        [PAYLOAD.SEARCH_SESSION_ID]: algoliaFields?.searchSessionId,
        [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
        [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
      },
    });
  }
)
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.EVENT(),
}))
@withAnalyticsContext
class EventPage extends Component {
  isFirstUpdate = true;
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    const { fullEvent } = props;
    this.goBackEventRoutes = this.goBackEventRoutes.bind(this);
    this.goToPerformer = this.goToPerformer.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleListingSelection = this.handleListingSelection.bind(this);
    this.handlePinHover = this.handlePinHover.bind(this);
    this.handleListingHover = this.handleListingHover.bind(this);
    this.handleSeatsChange = this.handleSeatsChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleOmnibarOptionsClose = this.handleOmnibarOptionsClose.bind(this);
    this.openSuperBowlModal = this.openSuperBowlModal.bind(this);
    this.handleOmnibarControls = this.handleOmnibarControls.bind(this);
    this.handleListingsTouchStart = this.handleListingsTouchStart.bind(this);
    this.handleListingsTouchMove = this.handleListingsTouchMove.bind(this);
    this.handleZoomLevelChange = this.handleZoomLevelChange.bind(this);
    this.handleEventUpdate = this.handleEventUpdate.bind(this);
    this.throttledPusherFetch = _throttle(this.handleEventUpdate, 10000);
    this.handleDealUnlock = this.handleDealUnlock.bind(this);
    this.handleMobileViewChange = this.handleMobileViewChange.bind(this);
    this.handleWindowFocus = this.handleWindowFocus.bind(this);
    this.handleWindowBlur = this.handleWindowBlur.bind(this);
    this.initiateCheckoutFlow = this.initiateCheckoutFlow.bind(this);
    this.navigateToNextPath = this.navigateToNextPath.bind(this);
    this.handleMapInteraction = this.handleMapInteraction.bind(this);
    this.handleListMapHarmonyToggleTracking =
      this.handleListMapHarmonyToggleTracking.bind(this);
    this.handleListingClose = this.handleListingClose.bind(this);
    this.handleClearGalleryViewMobile =
      this.handleClearGalleryViewMobile.bind(this);
    this.handleTouchInteractionStart =
      this.handleTouchInteractionStart.bind(this);

    let sidebarView = isListingRoute(props)
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (fullEvent && !fullEvent.isValid()) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    this.state = {
      showOmnibarModal: false,
      sidebarView,
      displayKey: null,
      isScrollingToTop: true,
      galleryViewListing: null,
      isMobileMapAnimating: false,
    };
  }

  shouldSetViewedEvent() {
    const id = this.props.fullEvent?.event?.id || null;
    if (id) {
      setNewViewedEvent(id);
    }
  }

  componentDidMount() {
    const {
      showSuggestedSeatCount,
      noInventory,
      fullEvent,
      lastRouteLocation,
      location,
      lastZoomLevel,
      showSuperBowlModal,
      fetchFullEventsByPrimaryPerformerId,
    } = this.props;

    if (noInventory) {
      this.props.getListings({ eventId: fullEvent?.id, zoom: lastZoomLevel });
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(location.pathname) &&
      !noInventory &&
      !fullEvent?.isParkingEvent()
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
      this.props.updateUserPreference({ seatCount: this.props.quantity });
    }

    this.shouldSetViewedEvent();
    this.prevListingsTouchPageY = null;
    if (typeof window !== 'undefined' && fullEvent) {
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);

      window.addEventListener('focus', this.handleWindowFocus);
      window.addEventListener('blur', this.handleWindowBlur);
    }
    const lastRoute = getLastRoute(lastRouteLocation);

    if (
      isEventPage(location.pathname) &&
      lastRoute &&
      typeof window !== 'undefined'
    ) {
      window.sessionStorage.setItem('GTRootRoute', JSON.stringify(lastRoute));
    }

    if (typeof window !== 'undefined' && showSuperBowlModal) {
      // requires localstorage
      setSBModalSeen(fullEvent?.id);
      this.props.showModal(MODALS.SUPER_BOWL);
    }

    if (fullEvent) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }
  }

  // Attempts to get user's root route due to issues w/ deep linking and router implementation
  getRootRoute() {
    if (typeof window === 'undefined') return '/';
    const stored = window.sessionStorage.getItem('GTRootRoute');
    if (!stored) {
      return '/';
    }
    return JSON.parse(stored);
  }

  handleEventUpdate() {
    const { fullEvent, location, getListings } = this.props;

    if (isEventPage(location.pathname)) {
      getListings({ eventId: fullEvent.id });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window.pusher) {
      window.pusher.allChannels().forEach((channel) => {
        window.pusher.unsubscribe(channel.name);
      });
    }

    this.throttledPusherFetch.cancel();

    this.props.setGTPicksFilter(false);

    if (typeof window !== 'undefined') {
      window.removeEventListener('focus', this.handleWindowFocus);
      window.removeEventListener('blur', this.handleWindowBlur);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      noInventory,
      sortId,
      fullEvent,
      fetchFullEventsByPrimaryPerformerId,
      showSuggestedSeatCount,
      analyticsContext,
      location,
      listing,
      isGTPicksFilterActive,
      appContext,
    } = this.props;
    const {
      location: { pathname },
    } = prevProps;
    if (!fullEvent || !isEventSubRoute(location.pathname)) return;

    const isDifferentEventId =
      prevProps.fullEvent && fullEvent.id !== prevProps.fullEvent.id;

    // TODO: handle in the loader
    if (isDifferentEventId) {
      if (isGTPicksFilterActive) {
        this.props.setGTPicksFilter(false);
      }
    }

    if (
      prevProps.fullEvent?.getPrimaryPerformer().id !==
      fullEvent.getPrimaryPerformer().id
    ) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(pathname) &&
      !noInventory &&
      !fullEvent.isParkingEvent() &&
      isDifferentEventId
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
      this.props.updateUserPreference({ seatCount: this.props.quantity });
    }

    if (isDifferentEventId && typeof window !== 'undefined' && window.pusher) {
      window.pusher.unsubscribe(prevProps.fullEvent.id);
      const channel = window.pusher.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);
    }

    const isListing = isListingRoute(this.props);
    const isInValid = !fullEvent.isValid();

    let sidebarView = isListing
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (isInValid) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    if (
      sidebarView !== this.state.sidebarView &&
      (sidebarView !== EVENTBAR_VIEWS.LISTING_PURCHASE ||
        (sidebarView === EVENTBAR_VIEWS.LISTING_PURCHASE && listing))
    ) {
      this.setState({ sidebarView });
    }

    if (
      isEventPage(pathname) &&
      noInventory &&
      (this.isFirstUpdate || isDifferentEventId)
    ) {
      const event = new Date(fullEvent.event.datetimeLocal);

      if (!isPastDate(event)) {
        const formattedDate = formatDate(event, 'M/d/yy');
        analyticsContext.track(
          new NoListingsFound({
            performer_name: fullEvent.getPrimaryPerformer().name,
            event_date: formattedDate,
          })
        );
      }
    }

    if (sortId !== prevProps.sortId || isDifferentEventId) {
      this.shouldSetViewedEvent();
    }

    if (appContext.state.isMobile && isDifferentEventId) {
      this.handleClearGalleryViewMobile();
    }

    if (this.isFirstUpdate) {
      this.isFirstUpdate = false;
    }
  }

  getBackClickTracker() {
    const { fullEvent, query } = this.props;

    if (query && query.selector) {
      return;
    }

    if (isListingRoute(this.props)) {
      return new FullEventClickTracker(fullEvent);
    }

    return new PerformerClickTracker(fullEvent.getPrimaryPerformer());
  }

  openSuperBowlModal() {
    this.props.showModal(MODALS.SUPER_BOWL);
  }

  goToListingDetails(listing) {
    const { fullEvent, lastZoomLevel, location } = this.props;

    const searchParams = new URLSearchParams(location.search);

    searchParams.set('zoom', lastZoomLevel);
    if (isListingPage(location.pathname)) {
      this.props.router.navigate(
        {
          pathname: listing.getPath(fullEvent),
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else {
      this.props.router.navigate({
        pathname: listing.getPath(fullEvent),
        search: searchParams.toString(),
      });
    }
  }

  goToPerformer() {
    const { fullEvent, lastRouteLocation } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);
    const canGoBack = isPerformerPage(lastRoute);
    if (canGoBack) {
      this.props.router.navigate(-1);
    } else {
      this.props.router.navigate(fullEvent.getPrimaryPerformer().getPath());
    }
  }

  handleMapHarmony() {
    const {
      updateMapHarmony,
      isListMapHarmonyToggleIsOn,
      appContext: {
        state: { isMobile },
      },
    } = this.props;
    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    if (!isMobile && isListMapHarmonyToggleIsOn && isDeviceLargeUp) {
      updateMapHarmony();
    }
  }

  goBackEventRoutes(steps = 1) {
    const { analyticsContext } = this.props;
    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    this.props.router.navigate(-steps);
    setTimeout(() => this.handleMapHarmony(), 500);
  }

  handleListingSelectionTracking(listing, tracking) {
    const { lastZoomLevel, analyticsContext } = this.props;
    const { isAllInPriceActive, price, listingIndex, isListingOverlay } =
      tracking;
    const listingSelectionTracker = new ListingClickTracker(
      listing,
      isAllInPriceActive,
      price,
      isListingOverlay
    )
      .interaction(Click.INTERACTIONS.SEAT_MAP_PIN())
      .payload({
        item_index: listingIndex,
        zoom_level: lastZoomLevel,
        ...mapListingTrackingData(listing.listingTrackingData),
      });

    analyticsContext.track(new Click(listingSelectionTracker.json()));
  }

  handleUnlockZoneDealTracking(seatMapPin) {
    const { fullEvent, dealsTracking, analyticsContext } = this.props;
    analyticsContext.track(
      new UnlockDealEvent(
        View.PAGE_TYPES.LISTING({
          [PAYLOAD.FULLEVENT]: fullEvent,
          [PAYLOAD.LISTING]: seatMapPin.listing,
          payload: {
            [PAYLOAD.MODE]: dealsTracking.zoneListingMode,
            [PAYLOAD.DEAL]: seatMapPin.deal.dealType,
            [PAYLOAD.LISTINGS_IDS]: dealsTracking.zoneListingsIds,
          },
          [PAYLOAD.INTERACTION]: INTERACTION.SEAT_MAP_PIN,
          [PAYLOAD.PAGE_TYPE]: undefined,
        })
      )
    );
  }

  handleListingSelection(seatMapPin) {
    const {
      setHoveredListingId,
      appContext: {
        state: { isMobile },
      },
    } = this.props;
    const { listing, deal, tracking } = seatMapPin;
    this.handleListingSelectionTracking(listing, tracking);
    const isGalleryListingFirstClick =
      this.state.galleryViewListing?.id !== listing.id;

    if (deal.isLockedDeal) {
      this.handleDealUnlock(deal.dealType);
      this.handleUnlockZoneDealTracking(seatMapPin);

      if (deal.singleClickUnlock) {
        setHoveredListingId(listing.id);
        this.goToListingDetails(listing);
      }

      if (isMobile && isGalleryListingFirstClick) {
        this.setState({
          galleryViewListing: listing,
          isMobileMapAnimating: false,
        });
      }

      // don't go to listing details if not singleClickUnlock
      return;
    }

    if (isMobile) {
      if (isGalleryListingFirstClick) {
        this.setState({
          galleryViewListing: listing,
          isMobileMapAnimating: false,
        });
        return;
      }
      this.setState({
        isMobileMapAnimating: false,
      });
    }

    setHoveredListingId(listing.id);
    this.goToListingDetails(listing);
  }

  handleBack() {
    const {
      query,
      location: { pathname },
      appContext: {
        state: { isMobile },
      },
      lastRouteLocation,
      analyticsContext,
    } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);

    const { view = MOBILE_VIEW.LIST } = query;

    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    if (isMobile) {
      this.handleClearGalleryViewMobile();
    }

    if (isEventPage(pathname) && isListingPage(lastRoute)) {
      return this.props.router.navigate(this.getRootRoute());
    }

    if (query && query.selector) {
      return this.props.router.navigate({
        pathname,
      });
    }

    if (view === MOBILE_VIEW.MAP) {
      return this.props.router.navigate(pathname, { replace: true });
    }

    return this.props.router.navigate(-1);
  }

  handleSeatsChange(quantity) {
    const { updateUserPreference, fullEvent, appContext } = this.props;

    updateUserPreference({ seatCount: quantity });
    this.props.getListings({ eventId: fullEvent.id, quantity });

    this.handleOmnibarOptionsClose();
    this.handleMapHarmony();

    if (appContext.state.isMobile) {
      setTimeout(() => this.validateGalleryViewListing(), 500);
    }
  }

  handleSortChange(sortId) {
    const { fullEvent, getListings, updateUserPreference } = this.props;
    updateUserPreference({ sortId });
    // TODO: fix sort order options, this function is receiving a label but
    // should be receiving a sort order key
    const sortIdToSortOrder = {
      [SORT_IDS.DISCOUNT]: [SORT_ORDER.DISCOUNT],
      [SORT_IDS.PRICEL]: [SORT_ORDER.PRICEL],
      [SORT_IDS.PRICEH]: [SORT_ORDER.PRICEH],
    };

    getListings({
      eventId: fullEvent.id,
      sort_order: sortIdToSortOrder[sortId],
    });
    this.handleOmnibarOptionsClose();
  }

  handleAllInPrice(showAllInPrice) {
    this.props.updateUserPreference({ showAllInPrice });
    this.props.getListings({
      eventId: this.props.fullEvent.id,
      all_in_pricing: showAllInPrice,
    });
  }

  handleGTPicksFilter() {
    this.props.setGTPicksFilter(!this.props.isGTPicksFilterActive);
  }

  validateGalleryViewListing() {
    const { displayedListings } = this.props;
    const { galleryViewListing } = this.state;
    if (!galleryViewListing) return;

    if (
      !displayedListings.some((listing) => listing.id === galleryViewListing.id)
    ) {
      this.handleClearGalleryViewMobile();
    }
  }

  handleRecuration(quartile) {
    const { fullEvent, getListings, appContext } = this.props;

    getListings({
      eventId: fullEvent.id,
      quartile,
    }).then(() => {
      if (appContext.state.isMobile) {
        this.validateGalleryViewListing();
      }
    });
  }

  handleOmnibarControls(id, params) {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
    } = this.props;
    const { displayKey } = this.state;
    const isActive = displayKey !== id;
    const pageViewType = getPageViewType(query, isMobile);

    if (id === 'price_bucket') {
      const priceId = this.props.priceQuartile === params.id ? -1 : params.id;
      const recurationTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.SETTINGS_TOGGLE(), {
          event_id: fullEvent.id,
          setting: 'price_bucket',
          action: priceId === -1 ? 'disable' : 'enable',
          price_bucket: params?.label,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(recurationTracker.json()));

      return this.handleRecuration(priceId);
    }

    if (id === 'allInPricing') {
      const allInTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.ALL_IN_FILTER(), {
          event_id: fullEvent.id,
          action: params.isAllInPriceActive ? 'enable' : 'disable',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(allInTracker.json()));

      return this.handleAllInPrice(params.isAllInPriceActive);
    }

    if (id === 'gtPicksFilter') {
      const gtPicksTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.GT_PICKS_FILTER(), {
          event_id: fullEvent.id,
          action: params.isGTPicksFilterActive ? 'enable' : 'disable',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(gtPicksTracker.json()));

      return this.handleGTPicksFilter();
    }

    if (isActive) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.OMNIBAR(false), {
          event_id: fullEvent.id,
          quantity: this.props.quantity,
          sort: this.props.sortId,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({
      showOmnibarModal: isActive,
      displayKey: isActive ? id : null,
    });
  }

  handleOmnibarOptionsClose(isCancel = false) {
    const { displayKey } = this.state;
    const { fullEvent, analyticsContext } = this.props;

    if (isCancel) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.CANCEL())
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey))
        .targetPageType(Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id));

      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({ showOmnibarModal: false, displayKey: null });
  }

  handlePinHover({ listing, tracking }) {
    const {
      query,
      fullEvent,
      isAllInPriceActive,
      lastZoomLevel,
      analyticsContext,
    } = this.props;
    const pageViewType = getPageViewType(query);
    const tracker = new HoverTracker()
      .interaction(
        Hover.INTERACTIONS.HOVER_CARDS({
          eventId: fullEvent.id,
          listingId: listing.id,
          allInPricing: isAllInPriceActive,
          displayedPrice: tracking.price,
        })
      )
      .payload({
        [PAYLOAD.ITEM_INDEX]: tracking.listingIndex,
        [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
        [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
        [PAYLOAD.ZOOM_LEVEL]: lastZoomLevel,
        [PAYLOAD.SEAT_ATTRIBUTES]: listing.listingTrackingData.seatAttributes,
      })
      .sourcePageType(
        Hover.SOURCE_PAGE_TYPES.EVENT({
          pageViewType,
          isListingDetails: tracking.isListingDetails,
        })
      );
    analyticsContext.track(new Hover(tracker.json()));
  }

  handleListingHover(hoveredListing) {
    const {
      appContext: {
        state: { isMobile },
      },
      isLockedCarouselHovered,
      setHoveredListingId,
      hoveredListingId,
    } = this.props;

    if (!isMobile && isLockedCarouselHovered) {
      this.props.setLockedCarouselHover(false);
    }

    // Don't update the state if the user hightlights over the same listing
    if (hoveredListingId === hoveredListing.id) {
      return;
    }

    if (!isMobile) {
      setHoveredListingId(hoveredListing?.id);
    }
  }

  handleListingsTouchStart(e) {
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  handleListingsTouchMove(e) {
    const { isScrollingToTop: prevIsScrollingToTop } = this.state;
    const nextIsScrollingToTop =
      this.prevListingsTouchPageY < e.touches[0].pageY;
    if (prevIsScrollingToTop !== nextIsScrollingToTop) {
      this.setState({
        isScrollingToTop: nextIsScrollingToTop,
      });
    }
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  isCheckout(pathname) {
    return pathname.endsWith('checkout') || pathname.endsWith('checkout/');
  }

  getMobileEventView() {
    const view = this.props.query?.view?.toLowerCase() ?? MOBILE_VIEW.LIST;
    return {
      type: view,
      isList: view === MOBILE_VIEW.LIST,
      isMap: view === MOBILE_VIEW.MAP,
    };
  }

  updateMobileEventView({ view, withoutHistory = false }) {
    const { fullEvent, location } = this.props;
    const newRoute = addQuery(fullEvent.getPath(), location.search, {
      view,
    });
    if (withoutHistory) {
      this.props.router.navigate(newRoute, { replace: true });
    } else {
      this.props.router.navigate(newRoute);
    }
  }

  handleMobileViewChange() {
    const mobileEventView = this.getMobileEventView();
    if (mobileEventView.isList) {
      this.handleZoomLevelChange(5);
      if (
        this.props.appContext.state.isMobile &&
        this.state.galleryViewListing
      ) {
        this.handleClearGalleryViewMobile();
      }
      this.updateMobileEventView({
        view: MOBILE_VIEW.MAP,
        withoutHistory: true,
      });
    } else {
      this.updateMobileEventView({
        view: MOBILE_VIEW.LIST,
        withoutHistory: true,
      });
    }
  }

  handleTrackingClearGalleryViewMobile() {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
    } = this.props;

    const pageViewType = getPageViewType(query, isMobile);
    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.FOCUSED_CARD_CANCEL(), {
        event_id: fullEvent.id,
      })
      .payload({
        listing_id: this.state.galleryViewListing?.id,
      })
      .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType));
    analyticsContext.track(new Click(tracker.json()));
  }

  handleClearGalleryViewMobile(track = false) {
    if (track) {
      this.handleTrackingClearGalleryViewMobile();
    }
    this.setState({ galleryViewListing: null, isMobileMapAnimating: false });
  }

  shouldPersistListings() {
    const {
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
    } = this.props;
    if (isMobile) return false;

    return (
      (isEventPagePreListings(location.pathname) ||
        isListingDetailsPage(location.pathname)) &&
      shouldShowListingDetailsOverlay(location, lastRouteLocation)
    );
  }

  renderInlineChildren() {
    const { children, listing, fullEvent, location, user, lastRouteLocation } =
      this.props;

    const childProps = {
      event: fullEvent,
    };

    if (!isListingRoute(this.props) || !listing) {
      return null;
    }

    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    return (
      <ListingDetails
        shouldAnimate={isDeviceLargeUp}
        handleTracking={this.getBackClickTracker()}
        onBack={this.goBackEventRoutes}
        listing={listing}
        user={user}
        fullEvent={fullEvent}
        childProps={childProps}
        isZoneDeal={isListingZoneDeal(listing)}
        isPurchaseRoute={isPurchaseRoute(location.pathname)}
        isCheckout={this.isCheckout(location.pathname)}
        isHarmonyPlusOverlay={
          isDeviceLargeUp &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
      >
        {children}
      </ListingDetails>
    );
  }

  renderSidebar(isCheckout) {
    const {
      fullEvent,
      listingsInDisplayGroups,
      schedule,
      sortId,
      noInventory,
      performersByCategory,
      allDisclosures,
      allDeals,
      appContext: {
        state: { isMobile },
      },
      lastZoomLevel,
      location,
      lastRouteLocation,
      isAllInPriceActive,
      isWebExclusivesV3Experiment,
    } = this.props;
    const { sidebarView } = this.state;
    const primaryPerformer = fullEvent.getPrimaryPerformer();

    if (noInventory && sidebarView === EVENTBAR_VIEWS.LISTINGS) {
      return <NoInventory primaryPerformer={primaryPerformer} />;
    }

    return (
      <EventBar
        key={fullEvent.id}
        onListingHover={this.handleListingHover}
        onListingsTouchStart={
          isMobile ? this.handleListingsTouchStart : () => {}
        }
        onListingsTouchMove={isMobile ? this.handleListingsTouchMove : () => {}}
        isCheckout={isCheckout}
        schedule={schedule}
        relatedPerformers={performersByCategory.slice(0, 10)}
        sortId={sortId}
        fullEvent={fullEvent}
        listingsInDisplayGroups={listingsInDisplayGroups}
        sidebarView={sidebarView}
        allDisclosures={allDisclosures}
        allDeals={allDeals}
        zoomLevel={lastZoomLevel}
        handleDealUnlock={this.handleDealUnlock}
        isHarmonyPlusOverlay={
          !isMobile &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
        isAllInPriceActive={isAllInPriceActive}
        isWebExclusivesV3Experiment={isWebExclusivesV3Experiment}
      >
        {this.renderInlineChildren()}
      </EventBar>
    );
  }

  handleZoomLevelChange(nextZoomLevel) {
    const {
      fullEvent,
      lastZoomLevel,
      updateLastZoomLevel,
      getListings,
      appContext,
    } = this.props;

    if (lastZoomLevel !== nextZoomLevel) {
      getListings({ eventId: fullEvent.id, zoom: nextZoomLevel }).then(() => {
        if (appContext.state.isMobile) {
          this.validateGalleryViewListing();
        }
      });
      updateLastZoomLevel(nextZoomLevel);
    }
  }

  handleDealUnlock() {
    const { updateUnlockedSponsoredDeals, fullEvent } = this.props;
    updateUnlockedSponsoredDeals(fullEvent.id);
  }

  mapTracking(seatMap) {
    const {
      fullEvent,
      isAllInPriceActive,
      quantity,
      displayedListings = [],
      isListMapHarmonyToggleIsOn,
      eventPageData = {
        eventPageRequestsFinishTime: 0,
        eventPageRequestsStartTime: 0,
      },
      analyticsContext,
    } = this.props;

    // Exit early if there are no listings
    if (displayedListings.length < 1) return;

    // Exit early if the first listing is not the current event
    if (displayedListings[0].eventId !== fullEvent.id) return;

    const { zoomType, zoomLevel, interaction } = seatMap;
    const map_type = 'listing_pins';

    const baseTracking = {
      event_id: fullEvent.id,
      metro: fullEvent.venue.metro,
      performer_id: fullEvent.getPrimaryPerformer().id,
      venue_id: fullEvent.venue.id,
    };

    const commonTracking = {
      all_in_pricing: isAllInPriceActive,
      quantity,
      map_type,
    };

    const getListingsRendered = () =>
      displayedListings.map((listing) => ({
        price: isAllInPriceActive
          ? listing.getTotalPrice()
          : listing.getPrice(),
        id: listing.id,
      }));

    /**
     * @param page_load - initial map load tracking
     */
    if (interaction === 'page_load') {
      const renderedListings = getListingsRendered();
      const backendLatencyMs = Math.round(
        eventPageData.eventPageRequestsFinishTime -
          eventPageData.eventPageRequestsStartTime
      );
      const firstRenderMs = Math.round(
        seatMap.lastPinRenderMs - eventPageData.eventPageRequestsStartTime
      );
      const areaZoomTracking = {
        ...baseTracking,
        payload: {
          ...commonTracking,
          backend_latency_ms: backendLatencyMs,
          map_image_render_ms: seatMap.mapImageRenderMs,
          first_pin_render_ms: firstRenderMs,
          harmony_enabled: isListMapHarmonyToggleIsOn,
          listings: renderedListings,
          listings_length: renderedListings.length,
        },
      };

      setTimeout(() => this.handleMapHarmony(), 500);
      return analyticsContext.track(new PinsRendered(areaZoomTracking));
    }

    /**
     * @param seatMap - tracking: [click, double_click, wheel, drag, touch, reset]
     */
    const mapTracking = {
      ...baseTracking,
      payload: {
        timestamp: Date.now(),
        interaction,
        map_type,
        zoom_type: zoomType,
        zoom_level: zoomLevel,
        harmony_enabled: isListMapHarmonyToggleIsOn,
      },
    };

    /**
     * @param pan - zoom_type: [drag]
     */
    if (interaction === mapEventType.drag) {
      mapTracking.payload.zoom_type = mapEventType.drag;
    }

    analyticsContext.track(new ZoomEvent(mapTracking));
  }

  handleMapInteraction(seatMap) {
    const { zoomLevel, zoomType } = seatMap;
    this.mapTracking(seatMap);

    if (zoomType) {
      this.handleZoomLevelChange(zoomLevel);
    }

    if (
      this.props.appContext.state.isMobile &&
      this.state.isMobileMapAnimating
    ) {
      setTimeout(() => this.setState({ isMobileMapAnimating: false }), 500);
    }
  }

  handleWindowFocus() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (!channelExist) {
      this.handleEventUpdate();
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.throttledPusherFetch);
    }
  }

  handleWindowBlur() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (channelExist) {
      window.pusher.unsubscribe(fullEvent.id);
    }
  }

  initiateCheckoutFlow() {
    const { location } = this.props;
    let { pathname } = location;

    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    setTimeout(() => {
      this.navigateToNextPath(`${pathname}/checkout`);
    }, 200);
  }

  navigateToNextPath(nextPath) {
    const { location } = this.props;

    this.props.router.navigate({
      pathname: nextPath,
      search: location.search,
    });
  }

  handleListMapHarmonyToggleTracking(listMapHarmonyToggleIsOn) {
    const { fullEvent, analyticsContext } = this.props;

    analyticsContext.track(
      new Click({
        interaction: listMapHarmonyToggleIsOn
          ? Click.INTERACTIONS.HARMONY_TOGGLE_ENABLED()
          : Click.INTERACTIONS.HARMONY_TOGGLE_DISABLED(),
        sourcePageType: Click.SOURCE_PAGE_TYPES.EVENT(),
        targetPageType: Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id),
        metro: fullEvent.venue.metro,
      })
    );
  }

  handleTouchInteractionStart() {
    this.setState({ isMobileMapAnimating: true });
  }

  handleListingClose() {
    this.goBackEventRoutes(2);
  }

  render() {
    const {
      displayedListings = [],
      listing,
      fullEvent,
      appContext: {
        state: { isMobile },
      },
      location: { pathname },
      showFullHeader,
      schedule,
      quantity,
      sortId,
      availableSeatCounts,
      performersByCategory,
      priceQuartile,
      isAllInPriceActive,
      noInventory,
      hoveredListingId,
      setHoveredListingId,
      lastZoomLevel,
      allDisclosures,
      allDeals,
      isGTPicksFilterActive,
    } = this.props;
    const {
      isScrollingToTop,
      sidebarView,
      displayKey,
      showOmnibarModal,
      galleryViewListing,
      isMobileMapAnimating,
    } = this.state;

    if (!fullEvent) {
      return <NotFound />;
    }

    const mobileEventView = this.getMobileEventView();
    const isListingDetails = isListingRoute(this.props);
    const isCheckout = isCheckoutPage(pathname);
    const showSidebarControls = sidebarView === EVENTBAR_VIEWS.LISTINGS;
    const shouldRenderMap = !isMobile || mobileEventView.isMap;

    const isListingFlow = isListingDetailsPage(pathname);
    const isPostListingFlow = isCheckoutPage(pathname) || isBuyRoute(pathname);

    const persistListings = this.shouldPersistListings();
    const isValidMobileEventView =
      isMobile && fullEvent.isValid() && !noInventory && !isListingDetails;

    const isGalleryViewMobileListingPresent =
      !!galleryViewListing &&
      !(isGTPicksFilterActive && !galleryViewListing.isExclusivesDeal);

    const eventHeaderProps = {
      fullEvent,
      quantity,
      sortId,
      isAllInPriceActive,
      priceQuartile,
      noInventory,
      view: mobileEventView.type,
      isListing: isListingDetails,
      persistListings,
      showSidebarControls,
      isPurchaseRoute: isPurchaseRoute(pathname),
      onBack: this.handleBack,
      handleBackTracking: this.getBackClickTracker(),
      handleOpenSBModal: this.openSuperBowlModal,
      handleOmnibarControls: this.handleOmnibarControls,
      isGTPicksFilterActive,
    };

    const minimalHeaderProps =
      mobileEventView.isList && showFullHeader
        ? {
            search: true,
            showCategories: true,
            showAccount: true,
            showHamburger: true,
            hideMobileHeader: isListingFlow && isMobile,
          }
        : { hideMobileHeader: true };

    const isAllInEvent = isCanadianProvince(fullEvent.venueState);
    const showRegulatoryAllInPricing = isAllInEvent && isListingDetails;
    const showSinglePin = (isListingDetails && isMobile) || isPostListingFlow;

    if (!fullEvent.isValid() && fullEvent.daysSinceExpiration >= 30) {
      const redirectPath = getPerformerPath(fullEvent.getPrimaryPerformer());
      this.props.setServerRedirectPath(redirectPath, REDIRECT_PERMANENT_STATUS);
    }

    return (
      <EventProvider
        fullEvent={fullEvent}
        activeListing={listing}
        displayedListings={displayedListings}
      >
        <ContainerTemplate
          header={
            <MinimalHeader
              {...minimalHeaderProps}
              isEventPage
              hiddenByScroll={!isScrollingToTop}
            />
          }
          showFooter={false}
        >
          <EventMeta />
          <div
            className={classNames(
              styles['event-page'],
              styles[mobileEventView.type],
              {
                [styles.listing]: isListingDetails,
              }
            )}
          >
            {!fullEvent.isValid() && (
              <div className={styles['fade-map-image']} />
            )}

            <EventHeader {...eventHeaderProps} variant="top" />

            <main className={styles.main}>
              <section
                className={classNames(styles['map-section'], {
                  [styles.listing]: isListingDetails,
                })}
              >
                {shouldRenderMap && (
                  <div className={styles['map-view']}>
                    {!fullEvent.isValid() && (
                      <InvalidEvent
                        schedule={schedule}
                        eventId={fullEvent.id}
                        performersByCategory={performersByCategory}
                      />
                    )}
                    <ListingsMapView
                      key={fullEvent.id}
                      showSinglePin={showSinglePin}
                      onPinHover={this.handlePinHover}
                      handleMapInteraction={this.handleMapInteraction}
                      handleListingSelection={this.handleListingSelection}
                      handleDealUnlock={this.handleDealUnlock}
                      onListingClose={this.handleListingClose}
                      highlightedListingId={
                        isMobile ? galleryViewListing?.id : hoveredListingId
                      }
                      isEventPage={isEventPage(pathname)}
                      isListingDetailsPage={isListingDetails}
                      clearHoveredListing={() => {
                        setHoveredListingId();
                      }}
                      isAllInPrice={
                        isAllInPriceActive || showRegulatoryAllInPricing
                      }
                      isListingFlow={isListingFlow}
                      initiateCheckoutFlow={this.initiateCheckoutFlow}
                      handleListMapHarmonyToggleTracking={
                        this.handleListMapHarmonyToggleTracking
                      }
                      isCheckout={isCheckout}
                      zoomLevel={lastZoomLevel}
                      allDisclosures={allDisclosures}
                      onListingHover={this.handleListingHover}
                      isHarmonyPlusOverlay={!isMobile}
                      onTouchInteractionStart={this.handleTouchInteractionStart}
                      hasGalleryViewMobileListing={
                        isGalleryViewMobileListingPresent
                      }
                    />
                  </div>
                )}
              </section>
              <aside className={styles['event-sidebar']}>
                <EventHeader {...eventHeaderProps} variant="sidebar" />

                <div
                  className={classNames(styles['event-sidebar-content'], {
                    [styles['event-sidebar-checkout']]: isCheckout,
                  })}
                >
                  {this.renderSidebar(isCheckout)}
                </div>
              </aside>
            </main>

            {isValidMobileEventView && (
              <>
                {isMobile && mobileEventView.isMap && (
                  <GalleryViewCard
                    onClose={() => this.handleClearGalleryViewMobile(true)}
                    isVisible={isGalleryViewMobileListingPresent}
                    isAnimating={isMobileMapAnimating}
                  >
                    <ListingCard
                      isExclusivesV1
                      isGalleryView
                      listingIndex={1}
                      fullEvent={fullEvent}
                      listing={galleryViewListing}
                      allDisclosures={allDisclosures}
                      allDeals={allDeals}
                      zoomLevel={lastZoomLevel}
                      className={styles['gallery-view-card']}
                      isFocusedCard
                    />
                  </GalleryViewCard>
                )}

                {!isMobileMapAnimating && (
                  <MapListSwitch
                    onClick={this.handleMobileViewChange}
                    view={mobileEventView}
                  />
                )}
              </>
            )}
          </div>

          <OmnibarOptions
            schedule={schedule}
            fullEvent={fullEvent}
            seatCount={quantity}
            sortId={sortId}
            availableSeatCounts={availableSeatCounts}
            handleSeatsChange={this.handleSeatsChange}
            handleSortChange={this.handleSortChange}
            displayKey={displayKey}
            showOmnibarOptions={showOmnibarModal}
            onClose={this.handleOmnibarOptionsClose}
          />
        </ContainerTemplate>
      </EventProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    params: { eventId, listingId },
    location,
    query,
  } = props;
  // allDisclosures is a dictionary of every disclosure we support so that we can enrich the disclosures coming with listings
  const allDisclosures = allDisclosuresSelector(state);
  // allDeals is a dict with an up-to-date list of deals we support
  const allDeals = selectAllDeals(state);
  const fullEvent = selectFullEventById(state, eventId);
  if (!fullEvent) {
    return {};
  }

  const schedule =
    selectFullEventsByPrimaryPerformerId(
      state,
      fullEvent.getPrimaryPerformer().id
    ) || [];
  const preferredSeatCount = userPreferenceSeatCountSelector(state);
  const sortId = userPreferredSortIdSelector(state);
  const selectPerformersByCategory = makeGetSelectPerformersByCategory();
  const performersByCategory = selectPerformersByCategory(
    state,
    fullEvent.getPrimaryPerformer().category
  );

  const lastZoomLevel = lastZoomLevelSelector(state);
  const isGTPicksFilterActive = isGTPicksFilterSelector(state);

  const quantity = selectListingsQuantity(state);

  const showSuperBowlModal = isSuperBowl(eventId) && !hasSeenSBModal(eventId);
  const showFullHeader =
    isEventPagePreListings(location.pathname) ||
    isListingDetailsPage(location.pathname);

  const algoliaFields = {
    queryId: query?.queryId,
    resultPosition: query?.resultPosition,
    searchIndex: query?.searchIndex,
    searchSessionId: query?.searchSessionId,
  };

  const listingsInDisplayGroups = selectListingsInDisplayGroups(state);
  const displayedListings = selectDisplayedListings(state);
  const listing = displayedListings.find((listing) => listing.id === listingId);

  const hasFeaturedDeals = displayedListings.some(
    (listing) => listing.isFeaturedDeal
  );
  const hasFlashDealsInEvent = displayedListings.some(
    (listing) => listing.isFlashDeal
  );

  const zoneListingsIds = displayedListings
    .filter((listing) => listing.isZoneDeal)
    .map((listing) => listing.id);
  const hasZoneDealsInEvent = zoneListingsIds.length > 0;

  let zoneListingMode = null;
  if (hasZoneDealsInEvent) {
    // I don't think we have any locked zone deals in the list anymore, we
    // should remove this if that's the case.
    const listDisplayGroup = listingsInDisplayGroups.find(
      (group) => group.type === 'list'
    );
    const listHasZoneDeal = listDisplayGroup?.listings.some(
      (listing) => listing.isZoneDeal
    );
    zoneListingMode = listHasZoneDeal ? LOCKED_DEAL.LIST : LOCKED_DEAL.CAROUSEL;
  }

  const highestPriceListing = selectHighestPriceListing(state);
  fullEvent.highPrice = highestPriceListing?.price;

  return {
    eventPageData: eventsPageDataSelector(state),
    fullEvent,
    listingsInDisplayGroups,
    displayedListings,
    listing,
    quantity,
    schedule,
    user: selectUserDetails(state),
    showFullHeader,
    sortId,
    showSuggestedSeatCount: quantity !== preferredSeatCount,
    availableSeatCounts: selectAvailableLots(state),
    noInventory: !selectHasInventory(state),
    performersByCategory: performersByCategory || [],
    query,
    allDisclosures,
    allDeals,
    lastZoomLevel,
    isAllInPriceActive: selectIsAllInPricing(state),
    priceQuartile: selectListingPriceQuartile(state),
    showSuperBowlModal,
    isLockedCarouselHovered: isLockedCarouselHoveredSelector(state),
    listingId,
    isListMapHarmonyToggleIsOn: listMapHarmonyToggleSelector(state),
    algoliaFields,
    isZoneDealUnlocked: isZoneUnlockedSelector(state, eventId),
    hoveredListingId: hoveredListingIdSelector(state),
    dealsTracking: {
      hasFlashDealsInEvent,
      hasZoneDealsInEvent,
      hasFeaturedDealsInEvent: hasFeaturedDeals,
      zoneListingsIds: zoneListingsIds.join(),
      zoneListingMode: zoneListingMode,
    },
    searchTestData: selectSearchTestData(state),
    isExclusivesV1: selectIsWebExclusivesV1Experiment(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
    isGTPicksFilterActive,
  };
};

const mapDispatchToProps = {
  updateUserPreference,
  showModal,
  updateLastZoomLevel: updateLastZoomLevelDispatch,
  setLockedCarouselHover,
  showAppSpinner,
  fetchFullEventsByPrimaryPerformerId:
    fetchFullEventsByPrimaryPerformerIdDispatch,
  updateMapHarmony: updateMapHarmonyDispatch,
  updateUnlockedSponsoredDeals,
  setHoveredListingId,
  setServerRedirectPath,
  setGTPicksFilter,
  getListings: fetchListings,
};

const loader =
  (_context) =>
  async ({
    context: { store: { dispatch, getState } } = _context,
    params: { eventId, listingId },
    request,
  }) => {
    const location = new URL(request.url);
    const requestStartTime = Date.now();
    const listing = listingId && selectListingById(getState(), listingId);

    if (listing && isListingDetailsPage(location.pathname)) {
      dispatch(updateSeatMap({ listingId }));
      return null;
    }

    const preListingsPromises = [
      dispatch(fetchMetros()),
      dispatch(fetchDisclosures()),
      dispatch(fetchDeals()),
    ];

    if (!selectFullEventById(getState(), eventId)) {
      preListingsPromises.push(dispatch(fetchFullEventById(eventId)));
    }

    // ensure that the event exists before fetching listings, we need the event
    // category to set the default zoom level
    await Promise.all(preListingsPromises);

    const fullEvent = selectFullEventById(getState(), eventId);
    if (fullEvent.isValid()) {
      const currentLocation = currentLocationSelector(getState());
      if (!currentLocation) {
        dispatch(updateCurrentLocation(fullEvent.venue.metro));
      }
    }

    // always fetch listings if no listingId (Event page), and ensure listing
    // exists if listingId is present
    if (!listingId || !listing) {
      /**
       * if zoom is present in query param, it means that user is in listing detail page
       * and we set zoom level using zoom in query param.
       * if user enters listings page, we initialize the zoom level with default values.
       * otherwise, we fetch listings using last used zoom level
       */
      const { isMobile } = appConfigSelector(getState());
      const { zoom: zoomQueryParam } = searchStringToQueryObject(
        location.search
      );
      let zoomLevel = lastZoomLevelSelector(getState());

      if (zoomQueryParam && Number.isFinite(parseInt(zoomQueryParam, 10))) {
        zoomLevel = parseInt(zoomQueryParam, 10);
      } else if (!listingId && zoomLevel === undefined) {
        if (isMobile) {
          zoomLevel = 5;
        } else {
          // revert to old default zoom level for NFL events on desktop only
          const isNoNFLAutozoomDesktop =
            selectIsNoNFLAutozoomDesktopExperiment(getState());
          const fullEvent = selectFullEventById(getState(), eventId);
          zoomLevel =
            isNoNFLAutozoomDesktop && fullEvent.category === CATEGORIES.NFL
              ? 9
              : 10;
        }
      } else if (zoomLevel === undefined) {
        // if listingId is present, we are on Listing Details or Checkout page.
        // if zoom level is still undefined by this point, we should set to 10
        // so we get all curated listings for the event
        // Listings V3 will return curation for all zoom levels so we will not
        // have to worry about this soon.
        zoomLevel = 10;
      }

      const state = getState();
      // check if user preferences are set to show all in pricing. if not, set
      // whether to show all in pricing based on state/province regulations.
      // from here, the user is allowed to change the all in pricing setting on
      // the event page.
      // TODO: get locale compliance config from the backend
      const isAllInPricing =
        userPreferenceShowAllInPriceSelector(state) ||
        isCanadianProvince(fullEvent.venueState) ||
        selectIsVenueAllInPrice(state, fullEvent.venueState);
      await Promise.all([
        dispatch(
          fetchListings({
            eventId,
            zoom: zoomLevel,
            all_in_pricing: isAllInPricing,
          })
        ),
        dispatch(updateLastZoomLevelDispatch(zoomLevel)),
      ]);
    }

    // if listingId is present but not in the curated displayed listings, redirect back
    // to view all listings for the event
    const displayedListingIds = selectDisplayedListingIds(getState());
    if (listingId && !displayedListingIds.includes(listingId)) {
      return redirect(`${fullEvent.getPath()}${location.search}`);
    }

    dispatch(
      updateEventsPageData({
        eventPageRequestsStartTime: requestStartTime,
        eventPageRequestsFinishTime: Date.now(),
      })
    );

    return null;
  };

const EventWrapper = withRouter(
  withNavigationProps(connect(mapStateToProps, mapDispatchToProps)(EventPage))
);

EventWrapper.loader = loader;

export default EventWrapper;
