import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Logout as LogoutEvent,
  LOGOUT_PAGE_TYPES,
  useAnalyticsContext,
} from 'analytics';
import LogOutIcon from 'icons/LogOutIcon';
import { logout as logoutDispatch } from 'store/modules/user/actions';

import styles from './LogOutButton.module.scss';

const LogOutButton = ({ logout, isMobile }) => {
  const analytics = useAnalyticsContext();
  const navigate = useNavigate();

  const handleLogout = async () => {
    analytics.track(
      new LogoutEvent({ pageType: LOGOUT_PAGE_TYPES.MY_ACCOUNT })
    );
    await logout();
    navigate('/');
  };

  return (
    <button
      type="button"
      onClick={handleLogout}
      className={classNames(styles['log-out-button'], {
        [styles.mobile]: isMobile,
      })}
    >
      <LogOutIcon width={isMobile ? 24 : 18} height={isMobile ? 24 : 18} />
      <span className={styles.text}>Log Out</span>
    </button>
  );
};

LogOutButton.propTypes = {
  logout: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutDispatch()),
});

export default connect(null, mapDispatchToProps)(LogOutButton);
