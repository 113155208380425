import { JsonObject } from 'type-fest';

import { PerformerInContext } from 'models';
import { REDUCERS } from 'store/modules/reducer.constants';

export interface GenrePerformers {
  performers?: JsonObject[];
  trending?: JsonObject[];
}

export const selectGenrePerformers = (globalState: JsonObject) =>
  (globalState[REDUCERS.GENRE_PERFORMERS] as GenrePerformers).performers?.map(
    (performer: JsonObject) => new PerformerInContext(performer)
  ) ?? [];

export const GENRE_PERFORMERS_ACTION_TYPES = {
  UPDATE: 'GENRE_PERFORMERS/UPDATE',
};

const initialState = {};

export default function reducer(
  state = initialState,
  action: { type: string; genrePerformers: JsonObject }
) {
  switch (action.type) {
    case GENRE_PERFORMERS_ACTION_TYPES.UPDATE:
      return {
        ...state,
        ...action.genrePerformers,
      };
    default:
      return state;
  }
}

export const updateGenrePerformers = (genrePerformers: JsonObject) => ({
  type: GENRE_PERFORMERS_ACTION_TYPES.UPDATE,
  genrePerformers,
});
