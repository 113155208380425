import React from 'react';
import PropTypes from 'prop-types';

import { PerformerClickTracker } from 'analytics';
import GTFullWidthList from 'components/GTFullWidthList/GTFullWidthList';
import {
  CATEGORIES,
  getCategoryPath,
} from 'store/modules/categories/category.helpers';
import { getPerformerPathByPerformer } from 'store/modules/resources/resource.paths';
import { ascSort } from 'utils/sorts';

import { OTHER_CATEGORY } from './constants';

export const OTHER_CATEGORIES = [
  {
    id: CATEGORIES.AFL,
    text: 'Indoor Football League',
    link: getCategoryPath(CATEGORIES.AFL),
    clickTracker: new PerformerClickTracker(CATEGORIES.AFL),
  },
  {
    id: CATEGORIES.AHL,
    text: 'American Hockey League',
    link: getCategoryPath(CATEGORIES.AHL),
    clickTracker: new PerformerClickTracker(CATEGORIES.AHL),
  },
  {
    id: CATEGORIES.ATP,
    text: 'Tennis',
    link: getCategoryPath(CATEGORIES.ATP),
    clickTracker: new PerformerClickTracker(CATEGORIES.ATP),
  },
  {
    id: CATEGORIES.AUTO,
    text: 'Auto',
    link: getCategoryPath(CATEGORIES.AUTO),
    clickTracker: new PerformerClickTracker(CATEGORIES.AUTO),
  },
  {
    id: CATEGORIES.BOXING,
    text: 'Boxing',
    link: getCategoryPath(CATEGORIES.BOXING),
    clickTracker: new PerformerClickTracker(CATEGORIES.BOXING),
  },
  {
    id: CATEGORIES.CFL,
    text: 'Canadian Football League',
    link: getCategoryPath(CATEGORIES.CFL),
    clickTracker: new PerformerClickTracker(CATEGORIES.CFL),
  },
  {
    id: CATEGORIES.CL,
    text: 'Cactus League',
    link: getCategoryPath(CATEGORIES.CL),
    clickTracker: new PerformerClickTracker(CATEGORIES.CL),
  },
  {
    id: CATEGORIES.CPL,
    text: 'Canadian Premier League',
    link: getCategoryPath(CATEGORIES.CPL),
    clickTracker: new PerformerClickTracker(CATEGORIES.CPL),
  },
  {
    id: CATEGORIES.GL,
    text: 'Grapefruit League',
    link: getCategoryPath(CATEGORIES.GL),
    clickTracker: new PerformerClickTracker(CATEGORIES.GL),
  },
  {
    id: CATEGORIES.GYMNASTICS,
    text: 'Gymnastics',
    link: getCategoryPath(CATEGORIES.GYMNASTICS),
    clickTracker: new PerformerClickTracker(CATEGORIES.GYMNASTICS),
  },
  {
    id: CATEGORIES.HORSES,
    text: 'Horse Racing',
    link: getCategoryPath(CATEGORIES.HORSES),
    clickTracker: new PerformerClickTracker(CATEGORIES.HORSES),
  },
  {
    id: CATEGORIES.SOCCER,
    text: 'Soccer',
    link: getCategoryPath(CATEGORIES.SOCCER),
    clickTracker: new PerformerClickTracker(CATEGORIES.SOCCER),
  },
  {
    id: CATEGORIES.MILB,
    text: 'Minor League Baseball',
    link: getCategoryPath(CATEGORIES.MILB),
    clickTracker: new PerformerClickTracker(CATEGORIES.MILB),
  },
  {
    id: CATEGORIES.MILS,
    text: 'American Minor League Professional Soccer',
    link: getCategoryPath(CATEGORIES.MILS),
    clickTracker: new PerformerClickTracker(CATEGORIES.MILS),
  },
  {
    id: CATEGORIES.MLG,
    text: 'Gaming',
    link: getCategoryPath(CATEGORIES.MLG),
    clickTracker: new PerformerClickTracker(CATEGORIES.MLG),
  },
  {
    id: CATEGORIES.NASCAR,
    text: 'NASCAR',
    link: getCategoryPath(CATEGORIES.NASCAR),
    clickTracker: new PerformerClickTracker(CATEGORIES.NASCAR),
  },
  {
    id: CATEGORIES.NWSL,
    text: `National Women's Soccer League`,
    link: getCategoryPath(CATEGORIES.NWSL),
    clickTracker: new PerformerClickTracker(CATEGORIES.NWSL),
  },
  {
    id: CATEGORIES.PBR,
    text: 'Professional Bull Riders',
    link: getCategoryPath(CATEGORIES.PBR),
    clickTracker: new PerformerClickTracker(CATEGORIES.PBR),
  },
  {
    id: CATEGORIES.PGA,
    text: 'PGA',
    link: getCategoryPath(CATEGORIES.PGA),
    clickTracker: new PerformerClickTracker(CATEGORIES.PGA),
  },
  {
    id: CATEGORIES.RUGBY,
    text: 'Rugby',
    link: getCategoryPath(CATEGORIES.RUGBY),
    clickTracker: new PerformerClickTracker(CATEGORIES.RUGBY),
  },
  {
    id: CATEGORIES.UFC,
    text: 'UFC',
    link: getCategoryPath(CATEGORIES.UFC),
    clickTracker: new PerformerClickTracker(CATEGORIES.UFC),
  },
  {
    id: CATEGORIES.WHL,
    text: 'Junior Hockey',
    link: getCategoryPath(CATEGORIES.WHL),
    clickTracker: new PerformerClickTracker(CATEGORIES.WHL),
  },
  {
    id: CATEGORIES.WNBA,
    text: 'WNBA',
    link: getCategoryPath(CATEGORIES.WNBA),
    clickTracker: new PerformerClickTracker(CATEGORIES.WNBA),
  },
  {
    id: CATEGORIES.WRES,
    text: 'Wrestling',
    link: getCategoryPath(CATEGORIES.WRES),
    clickTracker: new PerformerClickTracker(CATEGORIES.WRES),
  },
  {
    id: CATEGORIES.XFL,
    text: 'XFL',
    link: getCategoryPath(CATEGORIES.XFL),
    clickTracker: new PerformerClickTracker(CATEGORIES.XFL),
  },
  {
    id: CATEGORIES.CA,
    text: 'Copa America',
    link: getPerformerPathByPerformer({
      name: 'Copa America',
      slug: 'soccercopa',
    }),
    clickTracker: new PerformerClickTracker(CATEGORIES.CA),
  },
];

ascSort(OTHER_CATEGORIES, 'text');

function CategoryPerformersOther(props) {
  return (
    <GTFullWidthList
      key={OTHER_CATEGORY}
      items={OTHER_CATEGORIES}
      vertical
      onClick={props.onClick}
      listClassName="categoryPerformerList"
    />
  );
}

CategoryPerformersOther.propTypes = {
  onClick: PropTypes.func,
};

export default CategoryPerformersOther;
