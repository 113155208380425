import { createSelector } from 'reselect';

import { BaseContent } from 'models';

const _selectPerformersContentByCategory = (state) => state.content.categories;

export const makeGetPerformerStaticContentByCategory = () => {
  return createSelector(
    _selectPerformersContentByCategory,
    (state, category) => category,
    (performersCategoryState, category) => {
      const content = performersCategoryState[category];
      return content
        ? new BaseContent(performersCategoryState[category])
        : null;
    }
  );
};
