import React from 'react';
import PropTypes from 'prop-types';

import { TRACK, TrackPageView, View } from 'analytics';
import EmailOpenIcon from 'icons/EmailOpenIcon';

import styles from './MagicLinkSuccess.module.scss';

function MagicLinkSuccess({ isCheckout = false, email }) {
  return (
    <div className={styles.container}>
      <EmailOpenIcon width="80" height="80" />
      <div className={styles.title}>Check Your Email</div>
      <div className={styles.description}>
        We've sent an email to <span className={styles.email}>{email}</span>{' '}
        with a link to log in{isCheckout && ' and complete checkout'}.
      </div>
    </div>
  );
}
MagicLinkSuccess.propTypes = {
  isCheckout: PropTypes.bool,
  email: PropTypes.string.isRequired,
};

const getEventState = () => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.MAGIC_LINK(),
});

export default TrackPageView(getEventState)(MagicLinkSuccess);
