export function profileFormHasChanges(initialValues, currentValues) {
  if (currentValues.email !== initialValues.email) return true;
  if (
    currentValues.phone?.replace(/\(|\)|\s|-/g, '') !== initialValues.phone &&
    (currentValues.phone || initialValues.phone)
  )
    return true;
  if (
    currentValues.firstName !== initialValues.first_name &&
    (currentValues.firstName || initialValues.first_name)
  )
    return true;
  if (
    currentValues.lastName !== initialValues.last_name &&
    (currentValues.lastName || initialValues.last_name)
  )
    return true;

  return false;
}
