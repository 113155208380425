const getItem = (name) => {
  if (
    typeof window === 'undefined' ||
    !window.isSecureContext ||
    typeof localStorage === 'undefined'
  )
    return;

  const result = localStorage.getItem(name);

  if (result) {
    const data = JSON.parse(result);
    return data;
  }
};

const setItem = (name, value) => {
  if (
    typeof window === 'undefined' ||
    !window.isSecureContext ||
    typeof localStorage === 'undefined'
  )
    return;

  const stringy = JSON.stringify(value);
  localStorage.setItem(name, stringy);
};

const clearItem = (name) => {
  if (
    typeof window === 'undefined' ||
    !window.isSecureContext ||
    typeof localStorage === 'undefined'
  )
    return;

  localStorage.removeItem(name);
};

const appendItem = (key, value) => {
  let results = getItem(key);

  if (typeof results === 'undefined') {
    results = [];
  }

  results.push(value);
  setItem(key, results);
  return getItem(key);
};

const removeItem = (key, filterFn) => {
  const results = getItem(key);
  const _results = (results || []).filter(filterFn);
  setItem(key, _results);
  return getItem(key);
};

const updateItem = (key, reducerFn) => {
  let results = getItem(key);

  if (typeof results === 'undefined') {
    results = [];
  }

  const _results = results.reduce(reducerFn, []);
  setItem(key, _results);
  return getItem(key);
};

export const storage = (key) => ({
  getItem: () => getItem(key), // returns null
  getItems: () => getItem(key),
  setItem: (value) => setItem(key, value),
  setItems: (value) => setItem(key, value),
  appendItem: (value) => appendItem(key, value),
  removeItem: (filterFn) => removeItem(key, filterFn),
  updateItem: (reducerFn) => updateItem(key, reducerFn),
  clear: () => clearItem(key),
});
