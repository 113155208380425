import { getExternalAccountType } from 'store/modules/user/user.constants';

import { DELIVERY_TYPES, deliveryTypeFor } from './DELIVERY_TYPES';

export const DELIVERY_FORMATS = {
  PRINT_ONLY: 'PRINT_ONLY',
  DELIVERY_ONLY: 'DELIVERY_ONLY',
  DELIVERY_THIRD_PARTY: 'DELIVERY_THIRD_PARTY',
  MLB_BALLPARK: 'MLB_BALLPARK',
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  PACIOLAN: 'PACIOLAN',
  MOBILE: 'DELIVERY_MOBILE',
  LOCAL_PICKUP_ONLY: 'LOCAL_PICKUP_ONLY', // currently unused
  APP_REQUIRED: 'APP_REQUIRED', // currently unused
  LOCAL_PICKUP: 'LOCAL_PICKUP',
  MLB_ORDER: 'MLB_ORDER',
};

const TRANSFER_FORMATS = {
  MLB: 'mlb',
  TEXT: 'text',
  PACIOLAN: 'paciolan',
};

const PRINT_ONLY_DISCLOSURE = 'print_only';

const deliveryFormatFor = (deliveryType, disclosures, ticketType = null) => {
  if (disclosures.some((disclosure) => disclosure === PRINT_ONLY_DISCLOSURE)) {
    return DELIVERY_FORMATS.PRINT_ONLY;
  }

  if (deliveryType === DELIVERY_TYPES.hard) {
    return DELIVERY_FORMATS.DELIVERY_ONLY;
  }

  if (deliveryType === DELIVERY_TYPES.mobile) {
    return DELIVERY_FORMATS.DELIVERY_THIRD_PARTY;
  }

  if (deliveryType === DELIVERY_TYPES.local_pickup) {
    return DELIVERY_FORMATS.LOCAL_PICKUP_ONLY;
  }

  if (getExternalAccountType(ticketType)) {
    return DELIVERY_FORMATS.MLB_ORDER;
  }

  return DELIVERY_FORMATS.MOBILE;
};

export function deliveryFormatForListing(listing) {
  const deliveryFormat = deliveryFormatFor(
    deliveryTypeFor(listing),
    listing.disclosures
  );
  const isThirdPartyDelivery =
    deliveryFormat === DELIVERY_FORMATS.DELIVERY_THIRD_PARTY;
  const transferType = listing.transferType;

  // Certain third party delivery types have specific copy to display
  if (isThirdPartyDelivery) {
    if (transferType === TRANSFER_FORMATS.MLB) {
      return DELIVERY_FORMATS.MLB_BALLPARK;
    }

    if (transferType === TRANSFER_FORMATS.TEXT) {
      return DELIVERY_FORMATS.TEXT_MESSAGE;
    }

    if (transferType === TRANSFER_FORMATS.PACIOLAN) {
      return DELIVERY_FORMATS.PACIOLAN;
    }
  }

  return deliveryFormat;
}

export function deliveryFormatForPurchase(completePurchase) {
  const deliveryFormat = deliveryFormatFor(
    completePurchase.delivery_info?.delivery_type,
    completePurchase.spot.disclosures,
    completePurchase.tickets[0]?.type
  );
  const isThirdPartyDelivery =
    deliveryFormat === DELIVERY_FORMATS.DELIVERY_THIRD_PARTY;

  // Certain third party delivery types have specific copy to display
  if (isThirdPartyDelivery) {
    const transferType = completePurchase.delivery_info?.transfer_type;

    if (transferType === TRANSFER_FORMATS.MLB) {
      return DELIVERY_FORMATS.MLB_BALLPARK;
    }

    if (transferType === TRANSFER_FORMATS.TEXT) {
      return DELIVERY_FORMATS.TEXT_MESSAGE;
    }

    if (transferType === TRANSFER_FORMATS.PACIOLAN) {
      return DELIVERY_FORMATS.PACIOLAN;
    }
  }

  return deliveryFormat;
}
