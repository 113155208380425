import React from 'react';
import classNames from 'classnames';

import Emoji from 'components/Emoji';
import { GTLogoIcon } from 'icons';
import colors from 'styles/colors.constants';

import style from './UrgencyBadge.module.scss';

export const BADGES = {
  LAST_CALL: {
    icon: <Emoji emoji="0x23F1" variant="0xFE0F" />,
    name: 'LAST CALL',
    className: 'last-call',
    payload: 'last_call',
  },
  TOP_EVENT: {
    icon: <Emoji emoji="0x1F525" />,
    name: 'TOP EVENT',
    className: 'top-event',
    payload: 'hot_event',
  },
  GT_DEALS: {
    icon: <GTLogoIcon fill={colors.gametimeGreenLight} />,
    name: 'DEALS AVAILABLE',
    className: 'gt-deals',
    payload: 'gt_deals',
  },
};

export interface Badge {
  icon: React.ReactNode;
  name: string;
  className: string;
}

interface Props {
  badge: Badge;
}

export default function UrgencyBadge({ badge }: Props) {
  return (
    <div className={classNames(style['urgency-badge'], style[badge.className])}>
      <div className={style['badge-icon']}>{badge.icon}</div>
      <span>{badge.name}</span>
    </div>
  );
}
