import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { GetReviewsResponse } from 'types';

const httpClient = new HttpClient(config.MOBILE_API_URL);

export interface GetReviewsParams {
  slug?: string;
  category?: string;
}

export const getReviews = ({ slug, category }: GetReviewsParams) => {
  const searchParams = new URLSearchParams();
  if (slug) searchParams.append('slug', slug);
  if (category) searchParams.append('category', category);

  return httpClient.request<GetReviewsResponse>({
    path: '/v1/reviews',
    searchParams,
  });
};
