import { useMemo } from 'react';

import { dealsData } from 'components/Deals/helpers';
import { Deal, Listing } from 'models';
import colors from 'styles/colors.constants';
import { ListingDeal } from 'types';

interface UsePinDealParams {
  listing: Listing;
  allDeals: Record<string, Deal>;
  isZoneDeal: boolean;
  isFlashDeal: boolean;
  isHighlighted: boolean;
}

const usePinDeal = ({
  listing,
  allDeals,
  isZoneDeal,
  isFlashDeal,
  isHighlighted,
}: UsePinDealParams): ListingDeal | undefined => {
  const getDeal = () => {
    if (isZoneDeal) {
      const dealData: ListingDeal = {
        slug: 'zone',
        title: dealsData.zone_deal.tagTitle,
        color: dealsData.zone_deal.color,
        icon: dealsData.zone_deal.tagIcon,
        styles: {
          color: dealsData.zone_deal.color,
          borderColor: dealsData.zone_deal.color,
          fontWeight: 600,
        },
      };

      if (isHighlighted) {
        dealData.styles.color = colors.black;
        dealData.styles.background = dealsData.zone_deal.color;
      }

      return dealData;
    }

    if (isFlashDeal) {
      const dealData: ListingDeal = {
        slug: 'flash',
        title: dealsData.flash_deal.tagTitle,
        color: dealsData.flash_deal.color,
        icon: dealsData.flash_deal.tagIcon,
        styles: {
          color: dealsData.flash_deal.color,
          borderColor: dealsData.flash_deal.color,
          fontWeight: 600,
        },
      };

      if (isHighlighted) {
        dealData.styles.color = colors.black;
        dealData.styles.background = dealsData.flash_deal.color;
      }

      return dealData;
    }

    const dealType = listing.dealType;

    if (typeof dealType === 'string' && dealType in allDeals) {
      const { slug, title, color, iconURL } = allDeals[dealType];

      const dealData: ListingDeal = {
        slug,
        title,
        color,
        iconURL,
        styles: {
          borderColor: color,
        },
      };

      if (isHighlighted) {
        dealData.styles.color = colors.white;
        dealData.styles.background = color;
      }

      return dealData;
    }
  };

  return useMemo(getDeal, [
    allDeals,
    isFlashDeal,
    isHighlighted,
    isZoneDeal,
    listing,
  ]);
};

export default usePinDeal;
