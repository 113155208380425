import React from 'react';
import { useMeasure } from 'react-use';
import { useAnimation, useMotionValue, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';

import LockIcon from 'icons/LockIcon';

import { ButtonContainer, Container, SwitchKnot } from './SlidingButton.style';

import styles from './SlidingButton.module.scss';

const SlidingButton = ({ onSwipe, color }) => {
  const [containerRef, containerSize] = useMeasure();
  const x = useMotionValue(0);
  const animate = useAnimation();
  const lockedCircleWidth = 40;
  const max = containerSize.width ? containerSize.width - lockedCircleWidth : 0;
  const opacity = useTransform(x, [0, max], [1, 0]);

  return (
    <Container ref={containerRef} color={color}>
      <ButtonContainer style={{ opacity }} color={color}>
        <span className={styles.subtitle}>Slide to Unlock Deals</span>
      </ButtonContainer>

      <SwitchKnot
        drag="x"
        dragConstraints={{ left: 0, right: max }}
        style={{ x }}
        dragElastic={0}
        animate={animate}
        initial="off"
        color={color}
        variants={{
          on: {
            x: max,
          },
          off: {
            x: 0,
          },
        }}
        onDragEnd={(event, info) => {
          if (
            info.velocity.x > 20 ||
            (info.velocity.x >= 0 && info.point.x > 100)
          ) {
            animate.start('on');
            onSwipe();
          } else {
            animate.start('off');
          }
        }}
      >
        <LockIcon />
      </SwitchKnot>
    </Container>
  );
};

export default SlidingButton;

SlidingButton.propTypes = {
  onSwipe: PropTypes.func.isRequired,
  color: PropTypes.string,
};
