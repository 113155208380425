export const APP_STORE_LINKS = {
  iOS: {
    url: 'https://itunes.apple.com/us/app/gametime-making-your-mobile/id630687854',
    pageName: 'ios_download',
  },
  android: {
    url: 'https://play.google.com/store/apps/details?id=com.gametime.gametime',
    pageName: 'android_download',
  },
};
