import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const keys = ['noindex', 'nofollow', 'noarchive', 'index', 'follow'];

export const Robots = (props) => {
  const content = keys.filter((key) => props[key]).join(', ');
  return (
    <Helmet>
      <meta name="robots" content={content} />
    </Helmet>
  );
};

const propTypes = {};
keys.forEach((key) => {
  propTypes[key] = PropTypes.bool;
});
Robots.propTypes = propTypes;

export default Robots;
