import React from 'react';

import { CheckCircleSuccessIcon, WarningCircleFillIcon } from 'icons';
import colors from 'styles/colors.constants';
import { Step } from 'types';

import styles from './ConfirmationHeader.module.scss';

interface ConfirmationHeaderProps {
  orderStatus: Extract<Step['status'], 'pending' | 'cancelled' | 'confirmed'>;
}

const ConfirmationHeader = ({ orderStatus }: ConfirmationHeaderProps) => {
  const headerContent: Record<
    ConfirmationHeaderProps['orderStatus'],
    { message: string; icon: React.ReactNode }
  > = {
    pending: {
      message: 'Order Pending',
      icon: (
        <WarningCircleFillIcon
          fill={colors.softYellow}
          height={20}
          width={20}
        />
      ),
    },
    cancelled: {
      message: 'Order Canceled',
      icon: (
        <WarningCircleFillIcon fill={colors.softRed} height={20} width={20} />
      ),
    },
    confirmed: {
      message: 'Order Confirmed',
      icon: <CheckCircleSuccessIcon />,
    },
  };

  const { message, icon } = headerContent[orderStatus];

  return (
    <div className={styles['confirmation-header']}>
      <div className={styles['status-container']}>
        <span className={styles.status}>{message}</span>
        {icon}
      </div>
    </div>
  );
};

export default ConfirmationHeader;
