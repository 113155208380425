import { REDUCERS } from 'store/modules/reducer.constants';

export const userPurchasesSelector = (globalState) =>
  globalState[REDUCERS.PURCHASE];

export const userPurchaseBraintreeDataSelector = (globalState) =>
  globalState[REDUCERS.PURCHASE].braintreeDeviceData;

export const selectCompleteUserPurchases = (globalState) =>
  userPurchasesSelector(globalState).completePurchases;

export const selectCompleteUserPurchasesStatus = (globalState) =>
  userPurchasesSelector(globalState).completePurchasesStatus;

export const userCompletePurchaseSelector = (globalState, purchaseId) =>
  globalState[REDUCERS.PURCHASE].completePurchases[purchaseId];

export const isFirstPurchaseSelector = (globalState) => {
  const completePurchases = Object.values(
    userPurchasesSelector(globalState).completePurchases || {}
  );
  return completePurchases.length === 1;
};

export const selectIsInitialUserPurchase = (globalState) => {
  const completePurchases = Object.values(
    userPurchasesSelector(globalState).completePurchases || {}
  );
  return completePurchases.length < 1;
};

export const defaultCardTokenSelector = (globalState) =>
  userPurchasesSelector(globalState).defaultCardToken;

/**
 * Apple Pay
 */
export const selectApplePay = (globalState) =>
  userPurchasesSelector(globalState).applePay;

export const selectIsApplePayCapable = (state) => {
  const applePay = selectApplePay(state);
  return applePay.statusChecked && applePay.isCapable;
};

// TODO: figure out what to do without apple pay session
export const selectIsApplePayEnabled = (state) => {
  const applePay = selectApplePay(state);
  return (
    applePay.statusChecked && applePay.available && !!applePay.applePaySession
  );
};
