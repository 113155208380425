import { ACTIONS } from './constants';

const initialState = {};

const toObject = (performersInContext) => {
  const performersInContextObject = {};
  performersInContext.forEach((performerInContext) => {
    performersInContextObject[performerInContext.performer.id] =
      performerInContext;
  });

  return performersInContextObject;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESS:
      return {
        ...state,
        ...toObject(action.result.performers),
      };
    default:
      return state;
  }
}
