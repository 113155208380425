import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function GTLogoIcon({
  width = '16',
  height = '16',
  fill = '#65FFB5',
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51447 6.79167L3.04755 2.5C2.91598 2.275 3.07222 2 3.33535 2H9.66713L5.51447 6.79167ZM13.121 8.00014L9.66735 2.00014L3.04776 13.5001C2.92442 13.7251 3.08066 14.0001 3.33557 14.0001H9.66735L13.121 8.00014Z"
        fill={fill}
      />
    </svg>
  );
}
