import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { getCategoryPath } from 'store/modules/categories/category.helpers';
import { fetchAllPerformers } from 'store/modules/data/Performers/actions';
import { selectAllPerformersInCategories } from 'store/modules/data/Performers/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';

import styles from './Sitemap.module.scss';

class Sitemap extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, performers: PropTypes.array })
    ),
  };

  render() {
    const { categories } = this.props;

    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader search showAccount showHamburger showCategories />
        }
        footer={<GTFooter />}
      >
        <HeadTitle title="Sitemap" />
        <div className={styles.container}>
          <h1 className={styles.title}>Sitemap</h1>
          <div>
            {categories.map((category) => (
              <div key={category.name}>
                <a
                  className={styles.category}
                  href={getCategoryPath(category.name)}
                >
                  {category.name}
                </a>
                <div className={styles['performers-container']}>
                  {category.performers?.slice(0, 40).map((performer) => (
                    <a
                      key={`${performer.slug}`}
                      className={styles.performer}
                      href={performer.getPath()}
                    >
                      {performer.name}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: selectAllPerformersInCategories(state),
});

const SitemapWrapper = connect(mapStateToProps)(Sitemap);

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    await store.dispatch(fetchMetros());
    const state = store.getState();
    const metro = selectUserMetro(state) || selectClosestMetro(state);
    await store.dispatch(fetchAllPerformers(metro));

    return null;
  };

SitemapWrapper.loader = loader;

export default SitemapWrapper;
