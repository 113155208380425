import { LISTING } from './listing.actionTypes';

const initialState = {
  isLockedCarouselHovered: false,
  isUpdatingMapHarmony: false,
  listMapHarmonyToggleIsOn: false,
  unlockedZoneDealEvents: [],
  hoveredListingId: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LISTING.SET_LOCKED_CAROUSEL_HOVER:
      return {
        ...state,
        isLockedCarouselHovered: action.isHovered,
      };
    case LISTING.UPDATE_MAP_HARMONY:
      return {
        ...state,
        isUpdatingMapHarmony: action.updating,
      };
    case LISTING.UPDATE_LIST_MAP_HARMONY_TOGGLE:
      return {
        ...state,
        listMapHarmonyToggleIsOn: action.toggleIsOn,
        isUpdatingMapHarmony: true,
      };
    case LISTING.UPDATE_UNLOCKED_ZONE_DEAL_EVENTS:
      if (state.unlockedZoneDealEvents.includes(action.eventId)) return state;
      return {
        ...state,
        unlockedZoneDealEvents: [
          ...state.unlockedZoneDealEvents,
          action.eventId,
        ],
      };
    case LISTING.SET_HOVERED_LISTING_ID:
      return {
        ...state,
        hoveredListingId: action.hoveredListingId,
      };
    default:
      return state;
  }
}
