import React from 'react';
import classNames from 'classnames';

import { device, useMediaQuery } from 'hooks';

import LogOutButton from './LogOutButton';

import styles from './Sidebar.module.scss';

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  const isMobile = useMediaQuery(device.down.md);
  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.mobile]: isMobile,
      })}
    >
      <ul className={styles['sidebar-list']}>{children}</ul>
      <hr className={styles.divider} />
      <LogOutButton isMobile={isMobile} />
    </div>
  );
};

export default Sidebar;
