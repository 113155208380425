import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { Click, ClickTracker, LoginEvent, SMSAutoFilledEvent } from 'analytics';
import {
  createUser,
  fetchUser,
  fetchUserLoginOptions,
  tokenGenerate,
  tokenLogin,
} from 'store/modules/user/actions';
import { HttpError } from 'utils/errors';

import { LOGIN_STEPS, TOKEN_METHOD } from './constants';
import { ACTION, initialState, reducer } from './reducer';

export const LoginContext = createContext();

export const loginPropTypes = {
  magicLinkParams: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    ),
  }).isRequired,
  isSMSLoginEnabled: PropTypes.bool.isRequired,
};

export function useLogin({
  dispatch: reduxDispatch,
  magicLinkParams,
  onLoginSuccess,
  sourcePageTypeCallback,
  analytics,
  isSMSLoginEnabled,
}) {
  const [state, loginDispatch] = useReducer(reducer, initialState);

  const handleLoginSuccess = () => {
    if (onLoginSuccess) {
      onLoginSuccess();
    }
  };

  const reset = () => {
    loginDispatch({ type: ACTION.SET_STEP, step: LOGIN_STEPS.USER_EMAIL });
  };

  const clearErrors = () => {
    loginDispatch({ type: ACTION.CLEAR_ERRORS });
  };

  /**
   * Generate magic link and move to next login step
   * @param {string} email
   * @param {boolean} [isClickEvent = true]
   * @returns Promise
   */
  const handleGenerateMagicLink = async (email, isClickEvent = true) => {
    if (isClickEvent) {
      loginDispatch({ type: ACTION.SUBMIT });

      analytics.track(
        new Click(
          new ClickTracker()
            .interaction(Click.INTERACTIONS.SEND_LOGIN_LINK())
            .sourcePageType(Click.SOURCE_PAGE_TYPES.ACCOUNT_VERIFICATION())
            .targetPageType(Click.TARGET_PAGE_TYPES.MAGIC_LINK())
            .json()
        )
      );
    }

    try {
      await reduxDispatch(
        tokenGenerate({
          username: email,
          method: TOKEN_METHOD.EMAIL,
          extension: magicLinkParams.pathname,
          query_parameters: magicLinkParams.search,
        })
      );
      loginDispatch({
        type: ACTION.SET_STEP,
        step: LOGIN_STEPS.MAGIC_LINK_SUCCESS,
      });
    } catch {
      analytics.track(
        new LoginEvent({ status: 'failed', provider: 'magic_link' })
      );
      loginDispatch({ type: ACTION.SET_ERROR });
    }
  };

  /**
   * Generate SMS code and move to next login step
   * @param {string} email
   * @returns Promise
   */
  const handleGenerateSMSCode = async (email) => {
    if (!isSMSLoginEnabled) {
      // this function should not be called if the SMS feature flag is off, so
      // this is just here as a fail-safe
      loginDispatch({ type: ACTION.SET_ERROR });
      return;
    }

    loginDispatch({ type: ACTION.SUBMIT });

    analytics.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.SEND_CODE())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ACCOUNT_VERIFICATION())
          .targetPageType(Click.TARGET_PAGE_TYPES.SMS_VERIFICATION())
          .json()
      )
    );

    try {
      const response = await reduxDispatch(
        tokenGenerate({
          username: email,
          method: TOKEN_METHOD.SMS,
        })
      );

      loginDispatch({
        type: ACTION.SET_NEXT_ALLOWED_TOKEN_REQUEST_TIME,
        nextAllowedTokenRequestTime: response.next_allowed_time,
      });

      loginDispatch({ type: ACTION.SET_STEP, step: LOGIN_STEPS.SMS_CODE });
    } catch (error) {
      if (error instanceof HttpError && error.body?.next_allowed_time) {
        loginDispatch({
          type: ACTION.SET_NEXT_ALLOWED_TOKEN_REQUEST_TIME,
          nextAllowedTokenRequestTime: error.body.next_allowed_time,
        });

        loginDispatch({ type: ACTION.SET_STEP, step: LOGIN_STEPS.SMS_CODE });
        return;
      }
      loginDispatch({ type: ACTION.SET_ERROR });
    }
  };

  const handleSMSCodeLogin = async (
    email,
    code,
    isCodePasted,
    isCodeResent
  ) => {
    if (!isSMSLoginEnabled) {
      // this function should not be called if the SMS feature flag is off, so
      // this is just here as a fail-safe
      loginDispatch({ type: ACTION.SET_ERROR });
      return;
    }

    loginDispatch({ type: ACTION.SUBMIT });

    if (isCodePasted) {
      analytics.track(new SMSAutoFilledEvent());
    }

    const trackSMSSubmitResult = (status) => {
      const interaction = isCodeResent
        ? Click.INTERACTIONS.RESEND()
        : Click.INTERACTIONS.VERIFY();
      analytics.track(
        new Click(
          new ClickTracker()
            .interaction(interaction)
            .sourcePageType(Click.SOURCE_PAGE_TYPES.SMS_VERIFICATION())
            .payload({ status })
            .json()
        )
      );
      analytics.track(new LoginEvent({ status, provider: 'sms' }));
    };

    try {
      const { id, session_token } = await reduxDispatch(
        tokenLogin({
          username: email,
          server_token: code,
          method: TOKEN_METHOD.SMS,
        })
      );

      trackSMSSubmitResult('succeeded');

      try {
        await reduxDispatch(fetchUser(id, session_token));
        handleLoginSuccess();
      } catch {
        loginDispatch({ type: ACTION.SET_ERROR });
      }
    } catch (smsCodeError) {
      trackSMSSubmitResult('failed');
      if (smsCodeError.status === 401) {
        loginDispatch({ type: ACTION.SET_INVALID_CODE_ERROR });
      } else {
        loginDispatch({ type: ACTION.SET_ERROR });
      }
    }
  };

  /**
   * Handle login with email
   *   - if user exists, advance to next step (login options or magic link)
   *   - otherwise new user is created and handleLoginSuccess is called
   * @param {string} email
   * @returns
   */
  const handleEmailLogin = async (email) => {
    loginDispatch({ type: ACTION.SUBMIT_EMAIL, email });
    analytics.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.CONTINUE_BUTTON())
          .sourcePageType(sourcePageTypeCallback())
          .json()
      )
    );

    try {
      await reduxDispatch(createUser({ email }));
      analytics.track(
        new LoginEvent({ status: 'succeeded', provider: 'email' })
      );
      handleLoginSuccess();
    } catch (createUserError) {
      if (createUserError.status !== 500) {
        return loginDispatch({ type: ACTION.SET_ERROR });
      }

      // email in use, user already exists
      try {
        // any errors thrown from this try block will be caught and handled
        // below by calling handleGenerateMagicLink
        if (!isSMSLoginEnabled) {
          throw new Error('SMS login disabled');
        }

        const loginOptions = await reduxDispatch(fetchUserLoginOptions(email));
        if (loginOptions.phone) {
          loginDispatch({
            type: ACTION.SET_PHONE,
            phone: loginOptions.phone,
          });
          handleGenerateSMSCode(email);
        } else {
          throw new Error('SMS login not available');
        }
      } catch {
        await handleGenerateMagicLink(email, false);
      }
    }
  };

  return {
    state,
    reset,
    clearErrors,
    handleGenerateMagicLink,
    handleGenerateSMSCode,
    handleSMSCodeLogin,
    handleEmailLogin,
    nextAllowedTokenRequestTime: state.nextAllowedTokenRequestTime,
  };
}
