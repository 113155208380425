import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from 'ui/IconButton';

import {
  Click,
  ClickTracker,
  TrackPageView,
  useAnalyticsContext,
  View,
} from 'analytics';
import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import Spinner from 'components/Spinner/Spinner';
import { ChevronIcon } from 'icons';
import { profileFormHasChanges } from 'pages/MyAccount/utils';
import {
  logout as logoutDispatch,
  requestAccountDeletion,
} from 'store/modules/user/actions';
import colors from 'styles/colors.constants';

import AccountDeletionModal from './components/AccountDeletionModal/AccountDeletionModal';
import { MODAL_CONTENT_TYPE } from './components/AccountDeletionModal/constants';
import Avatar from './components/Avatar/Avatar';
import ProfileForm from './components/Form/ProfileForm';

import styles from './Profile.module.scss';

const Profile = ({ goBack, user, requestAccountDeletion, logout }) => {
  const analytics = useAnalyticsContext();
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const [accountDeletionModalType, setAccountDeletionModalType] =
    useState(null);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);
  const {
    pending_deletion: isPendingDeletion,
    email,
    first_name: firstName,
  } = user;

  /**
   * Fires when a user clicks the "Confirm" button of the "Confirm Account Deletion" modal
   */
  const handleConfirmDelete = useCallback(async () => {
    setAccountDeletionModalType(null);
    setIsDeletionLoading(true);

    analytics?.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.CONFIRM_ACCOUNT_DELETION())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ACCOUNT())
          .targetPageType(Click.TARGET_PAGE_TYPES.ACCOUNT())
          .json()
      )
    );

    try {
      await requestAccountDeletion();
      setIsDeletionLoading(false);
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      setIsDeletionLoading(false);
      setAccountDeletionModalType(MODAL_CONTENT_TYPE.ERROR);

      analytics?.track(new View(View.PAGE_TYPES.REQUEST_PROCESSING_FAILURE()));
    }
  }, [logout, requestAccountDeletion, analytics]);

  /**
   * Fires when a user clicks the "Close" button of the "Confirm Account Deletion" modal
   */
  const handleClose = useCallback(() => {
    analytics?.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.CANCEL_ACCCOUNT_DELETION())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ACCOUNT())
          .targetPageType(Click.TARGET_PAGE_TYPES.ACCOUNT())
          .json()
      )
    );

    setAccountDeletionModalType(null);
  }, [analytics]);

  /**
   * Fires when a user clicks the "Request Account Deletion" button
   */
  const handleRequestAccountDeletionClick = useCallback(() => {
    analytics?.track(
      new Click(
        new ClickTracker()
          .interaction(Click.INTERACTIONS.REQUEST_ACCOUNT_DELETION())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ACCOUNT())
          .targetPageType(Click.TARGET_PAGE_TYPES.ACCOUNT())
          .json()
      )
    );

    setAccountDeletionModalType(MODAL_CONTENT_TYPE.CONFIRM);

    analytics?.track(new View(View.PAGE_TYPES.ACCOUNT_DELETION()));
  }, [analytics]);

  const formCallback = useCallback(
    (newForm) => {
      if (!newForm) return;

      if (!profileFormHasChanges(user, newForm.values))
        newForm.hasNoChanges = true;
      setForm(newForm);
    },
    [user]
  );

  return (
    <div className={styles.container}>
      <div className={styles['profile-header']}>
        <div className={styles['header-container']}>
          <IconButton
            onClick={goBack}
            className={styles['header-left']}
            icon={<ChevronIcon color={colors.white} />}
          />
          <span className={styles['header-title']}>Profile</span>
          <button
            className={styles['header-right']}
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
            disabled={form?.isSubmitting || form?.hasNoChanges}
          >
            save
          </button>
        </div>
        <div className={styles['avatar-container']}>
          <Avatar letter={firstName ? firstName[0] : email[0]} />
        </div>
      </div>
      <h2 className={styles['profile-heading']}>Profile</h2>
      <ProfileForm formCallback={formCallback} />
      <div className={styles['account-deletion-container']}>
        <button
          disabled={isPendingDeletion}
          className={styles['account-deletion-button']}
          onClick={handleRequestAccountDeletionClick}
        >
          {isPendingDeletion ? 'pending deletion' : 'request account deletion'}
        </button>
      </div>
      {!!accountDeletionModalType && (
        <GTUniversalModal
          show={Boolean(accountDeletionModalType)}
          onHide={() => setAccountDeletionModalType(null)}
        >
          <AccountDeletionModal
            contentType={accountDeletionModalType}
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
          />
        </GTUniversalModal>
      )}
      {isDeletionLoading && <Spinner isFullscreen />}
    </div>
  );
};

Profile.propTypes = {
  goBack: PropTypes.func,
  user: PropTypes.shape({
    pending_deletion: PropTypes.bool,
    first_name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  requestAccountDeletion: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  requestAccountDeletion: requestAccountDeletion,
  logout: logoutDispatch,
};

const getEventState = () => View.PAGE_TYPES.PROFILE();

export default connect(
  null,
  mapDispatchToProps
)(TrackPageView(getEventState)(Profile));
