/**
 * Adds parentheses, spaces, and dashes to a string of numbers to format them as a
 * (XXX) XXX-XXXX phone number. If a falsy value is passed in, then that falsy value is
 * returned for use in validation in the function that called this function. Note that
 * this is only for formatting and does not check for the length or content of the parameter
 * as that should be handled with utils in validation.js
 *
 * @param {String} value the unmasked number to transform
 * @returns value if falsy value passed in, otherwise phone number in (XXX) XXX-XXXX format
 */
export const normalizePhoneNumber = (value) => {
  if (!value) {
    return value;
  }

  const cleanPhoneNumber = value.replace(/\D/g, '');

  return cleanPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

/**
 * Replaces all parentheses, spaces, and dashes in a phone number. For example,
 * (888) 355-0132 returns 8883550132. If a falsy value is passed in, then that
 * falsy value is returned for use in validation in the function that called
 * this function.
 *
 * @param {String} value the phone number to transform
 * @returns value if falsy value passed in, otherwise phone number digits only
 */
export const denormalizePhoneNumber = (value) => {
  if (!value) return value;

  return value.replace(/[^\d]/g, '');
};

export const normalizePhoneForMaskito = (value) => {
  if (!/^\d+$/.test(value)) return value;
  return normalizePhoneNumber(value);
};

export const maskitoPhoneOptions = {
  mask: [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
