import React from 'react';
import PropTypes from 'prop-types';

import Content from 'components/Content/Content';
import Section from 'components/Section/Section';
import { BaseContent } from 'models';

import styles from './ContentSection.module.scss';

function _ContentSection({ headline, contentBody, content }) {
  if (!headline || !content) {
    return null;
  }

  return (
    <Section className={styles['content-section']} headline={headline}>
      <Content headline={headline} contentBody={contentBody} {...content} />
    </Section>
  );
}

_ContentSection.propTypes = {
  headline: PropTypes.string.isRequired,
  content: PropTypes.instanceOf(BaseContent).isRequired,
  contentBody: PropTypes.string,
};

export default _ContentSection;
