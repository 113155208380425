import { ACTIONS } from './constants';

export const fetchFullEvents = (params) => (dispatch) => {
  const newParams = { page: 1, per_page: 1000, ...params };

  if (params.venueSlug) {
    newParams.venue_slug = params.venueSlug;
  }

  if (params.performerSlug && !params.secondaryPerformerId) {
    newParams.performer_slug = params.performerSlug;
  }

  if (params.primaryPerformerId) {
    newParams.primary_performer_id = params.primaryPerformerId;
  }

  if (params.secondaryPerformerId) {
    newParams.secondary_performer_id = params.secondaryPerformerId;
  }

  if (params.id) {
    newParams.id = params.id;
  }

  return dispatch({
    types: [
      ACTIONS.GET_EVENTS_REQUEST,
      ACTIONS.GET_EVENTS_REQUEST_SUCCESS,
      ACTIONS.GET_EVENTS_REQUEST_FAILURE,
    ],
    params: newParams,
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/events',
        searchParams: newParams,
      }),
  });
};

export const fetchFullEventById = (id) => fetchFullEvents({ id });

export const fetchFullEventsByVenueSlug = (venueSlug) =>
  fetchFullEvents({ venueSlug });

export const fetchFullEventsByPerformerSlug = (performerSlug) =>
  fetchFullEvents({ performerSlug });

export const fetchFullEventsByPrimaryPerformerId = (primaryPerformerId) =>
  fetchFullEvents({ primaryPerformerId });

export const fetchParkingFullEvents = (params) =>
  fetchFullEvents({ ...params, isParking: true });
