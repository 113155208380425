import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

export const USER_PURCHASE_ACTION_TYPES = Object.assign(
  generateActionTypes(
    [
      // promise actions
      'CREATE_USER_CARD',
      'REMOVE_USER_CARD',
      'CREATE_USER_PURCHASE',
      'UPDATE_USER_PAYMENT_ADDRESS',
      'UPDATE_USER_DELIVERY_ADDRESS',
      'ASSOCIATE_USER_DELIVERY_ADDRESS',
      'FETCH_USER_PURCHASE_RESULT',
      'CREATE_BRAINTREE_NONCE',
      'FETCH_USER_CARDS',
      'INIT_APPLE_PAY',
      'INIT_GOOGLE_PAY',
      'FETCH_COMPLETE_USER_PURCHASE',
      'FETCH_COMPLETE_USER_PURCHASES',
      'FETCH_USER_DEVICE_VERIFIED',
      'VERIFY_CVV',
      'UPDATE_USER_DEFAULT_PAYMENT_TOKEN',
    ],
    REDUCERS.PURCHASE,
    ['', '_SUCCESS', '_FAIL']
  ),
  generateActionTypes(
    [
      // solo actions
      'SAVE_PURCHASE_ZIP',
      'SAVE_BRAINTREE_DEVICE_DATA',
    ],
    REDUCERS.PURCHASE
  )
);
