import React from 'react';

import colors from 'styles/colors.constants';

export default function CaretUpIcon({
  width = '16',
  height = '16',
  fill = colors.white,
}) {
  return (
    <svg
      data-testid="caret-up-icon"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5732 8.57322C11.7307 8.73072 11.6192 9 11.3964 9L4.60355 9C4.38083 9 4.26929 8.73071 4.42678 8.57322L7.64645 5.35355C7.84171 5.15829 8.15829 5.15829 8.35355 5.35355L11.5732 8.57322Z"
        fill={fill}
      />
    </svg>
  );
}
