import React from 'react';
import classNames from 'classnames';

import { ExpiredClockIcon } from 'icons';
import colors from 'styles/colors.constants';

import { SIDEBAR_VIEWS, SidebarView } from './sidebar.constants';

import styles from './Sidebar.module.scss';

interface SidebarPastEventsProps {
  onSetActiveView: (view: SidebarView) => void;
  isActive: boolean;
}

const SidebarPastEvents = ({
  onSetActiveView,
  isActive,
}: SidebarPastEventsProps) => {
  return (
    <li>
      <button
        type="button"
        onClick={() => onSetActiveView(SIDEBAR_VIEWS.PAST)}
        className={classNames(styles['sidebar-button'], {
          [styles.active]: isActive,
        })}
      >
        <div className={styles['icon-text-container']}>
          <ExpiredClockIcon
            fill={isActive ? colors.gametimeGreenLight : colors.white}
          />
          <span className={styles.text}>Past</span>
        </div>
      </button>
    </li>
  );
};

export default SidebarPastEvents;
