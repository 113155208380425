/* eslint-disable react/prop-types */
import React from 'react';
import { useMaskito } from '@maskito/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import PhoneFillIcon from 'icons/PhoneFillIcon';
import { maskitoPhoneOptions, normalizePhoneForMaskito } from 'utils/phone';

const UserPhoneFormValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter a valid phone number'
    )
    .trim()
    .required('Please enter a valid phone number'),
});

const UserPhoneForm = ({
  clickTracker,
  ctaText = 'CONFIRM',
  mpActions = {},
  handleUserPhoneFormSubmit,
}) => {
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      await handleUserPhoneFormSubmit(values);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  const phoneInputRef = useMaskito({
    options: maskitoPhoneOptions,
  });

  return (
    <Formik
      initialValues={{ phone: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserPhoneFormValidationSchema}
    >
      {(props) => {
        const {
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        } = props;
        return (
          <Form onSubmit={handleSubmit}>
            <TextInput
              id="user-phone"
              name="phone"
              label="Phone Number"
              placeholder="(415) 123-4567"
              type="tel"
              ref={phoneInputRef}
              startIcon={<PhoneFillIcon />}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.phone && touched.phone,
                message: errors.phone,
              }}
              value={normalizePhoneForMaskito(values.phone)}
            />

            <DarkThemedFormButtonLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              mpActions={mpActions}
              clickTracker={clickTracker}
              showBackground
            />
          </Form>
        );
      }}
    </Formik>
  );
};

UserPhoneForm.propTypes = {
  handleUserPhoneFormSubmit: PropTypes.func.isRequired,
  clickTracker: PropTypes.object,
  ctaText: PropTypes.string,
  mpActions: PropTypes.object,
};

export default UserPhoneForm;
