import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ThemedCard.module.scss';

function ThemedCard({
  title,
  desc,
  icon,
  children,
  descriptionClassName = 'themed-form-desc',
  styleClass,
}) {
  return (
    <div className={classNames(styles['themed-form-container'], styleClass)}>
      {icon && <div className={styles['themed-form-icon']}>{icon}</div>}
      {title && <div className={styles['themed-form-title']}>{title}</div>}
      {desc && <div className={styles[descriptionClassName]}>{desc}</div>}
      {children}
    </div>
  );
}

ThemedCard.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
  descriptionClassName: PropTypes.string,
  styleClass: PropTypes.string,
};

export default ThemedCard;
