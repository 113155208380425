import React from 'react';
import PropTypes from 'prop-types';

import IconCard from 'components/IconCard/IconCard';
import DiscountIcon from 'icons/DiscountIcon';

const FaceValueCard = ({ faceValue, currencyPrefix }) => {
  let description =
    'Prices are set by sellers and may vary from face value. Face value: ';
  if (faceValue) {
    description += `${currencyPrefix}${faceValue} per ticket.`;
  } else {
    description += 'Not available from supplier.';
  }

  return (
    <IconCard icon={<DiscountIcon />} subtitle={description} uniformPadding />
  );
};

FaceValueCard.propTypes = {
  faceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencyPrefix: PropTypes.string.isRequired,
};

export default FaceValueCard;
