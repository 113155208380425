import { CATEGORIES } from 'store/modules/categories/category.helpers';
import colors from 'styles/colors.constants';

export const SPORT_CATEGORY_GROUP = 'SPORT';
const CONCERT_CATEGORY_GROUP = 'CONCERT';
const THEATER_CATEGORY_GROUP = 'THEATER';

type Category = keyof typeof CATEGORIES;

type EventTypeString = 'show' | 'team' | 'tournament' | 'event';

export const CATEGORY_GROUPS = {
  SPORT: 'sport',
  CONCERT: 'concert',
  THEATER: 'theater',
} as const;

export type CategoryGroup =
  (typeof CATEGORY_GROUPS)[keyof typeof CATEGORY_GROUPS];

interface CategoryGroupConfig {
  id: string;
  name: string;
  title: string;
  titleEventType: string;
  color: string;
}

export const CATEGORY_GROUP_CONFIG: Record<string, CategoryGroupConfig> = {
  [SPORT_CATEGORY_GROUP]: {
    id: 'sport',
    name: 'sports',
    title: 'Sports Tickets',
    titleEventType: 'Sports',
    color: colors.sportColor,
  },
  [CONCERT_CATEGORY_GROUP]: {
    id: 'concert',
    name: 'music',
    title: 'Concert Tickets',
    titleEventType: 'Concert',
    color: colors.concertColor,
  },
  [THEATER_CATEGORY_GROUP]: {
    id: 'theater',
    name: 'shows',
    title: 'Theater Tickets',
    titleEventType: 'Show',
    color: colors.theaterColor,
  },
};

export const getCategoryGroupConfigById = (
  categoryGroupId: string
): CategoryGroupConfig => {
  const categoryGroupConfig = Object.values(CATEGORY_GROUP_CONFIG).find(
    (config) => config.id === categoryGroupId
  );

  return categoryGroupConfig || CATEGORY_GROUP_CONFIG[SPORT_CATEGORY_GROUP];
};

export const getCategoryGroupColor = (categoryGroup: string): string =>
  getCategoryGroupConfigById(categoryGroup).color;

export const getEventType = (categoryGroup: string): string => {
  switch (categoryGroup) {
    case CATEGORY_GROUP_CONFIG.SPORT.id:
      return 'Games';
    case CATEGORY_GROUP_CONFIG.CONCERT.id:
      return 'Concerts';
    case CATEGORY_GROUP_CONFIG.THEATER.id:
      return 'Shows';
    default:
      return 'Events';
  }
};

export const getEventTypeString = (
  categoryGroup: Category
): EventTypeString => {
  switch (categoryGroup) {
    case CATEGORIES.THEATER:
      return 'show';
    case CATEGORIES.AHL:
    case CATEGORIES.MILS:
    case CATEGORIES.AFL:
    case CATEGORIES.CFL:
    case CATEGORIES.CPL:
    case CATEGORIES.WHL:
    case CATEGORIES.MILB:
    case CATEGORIES.NWSL:
      return 'team';
    case CATEGORIES.ATP:
      return 'tournament';
    default:
      return 'event';
  }
};
