export const FETCH_PROMO_CODES_CONTENT = 'FETCH_PROMO_CODES_CONTENT';
export const FETCH_PROMO_CODES_CONTENT_FAIL = 'FETCH_PROMO_CODES_CONTENT_FAIL';
export const FETCH_PROMO_CODES_CONTENT_SUCCESS =
  'FETCH_PROMO_CODES_CONTENT_SUCCESS';

export const fetchPromoCodesContent = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_PROMO_CODES_CONTENT,
      FETCH_PROMO_CODES_CONTENT_SUCCESS,
      FETCH_PROMO_CODES_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('*', 'promo-codes'),
  });
