/* eslint-disable max-len */

import bloombergImage from 'assets/home/media/bloomberg.svg';
import cnbcImage from 'assets/home/media/cnbc.svg';
import forbesImage from 'assets/home/media/forbes.svg';
import mashableImage from 'assets/home/media/mashable.svg';
import sportsIllustratedImage from 'assets/home/media/sports-illustrated.svg';
import techcrunchImage from 'assets/home/media/techcrunch.svg';
import wallstreetImage from 'assets/home/media/wallstreet-journal.svg';

export default [
  {
    name: 'techcrunch',
    href: 'https://techcrunch.com/2016/09/21/gametime-raises-20-million-to-sell-last-minute-textable-tickets-to-sports-and-concerts/',
    imgProps: {
      src: techcrunchImage,
      width: '912',
      height: '118',
    },
  },
  {
    name: 'cnbc',
    href: 'https://www.cnbc.com/video/2015/05/15/app-scores-last-minute-sports-tickets.html',
    imgProps: {
      src: cnbcImage,
      width: '267',
      height: '197',
    },
  },
  {
    name: 'mashable',
    href: 'https://mashable.com/article/gametime-mobile-ticket-app',
    imgProps: {
      src: mashableImage,
      width: '760',
      height: '130',
    },
  },
  {
    name: 'sportsillustrated',
    href: 'https://www.si.com/media/2016/09/23/gametime-ticketing-app-raises-20-million-series-b-funding',
    imgProps: {
      src: sportsIllustratedImage,
      width: '482',
      height: '126',
    },
  },
  {
    name: 'wsj',
    href: 'https://www.wsj.com/articles/last-minute-ticket-marketplace-gametime-raises-20m-series-b-led-by-gv-1474457401',
    imgProps: {
      src: wallstreetImage,
      width: '468',
      height: '253',
    },
  },
  {
    name: 'bloomberg',
    href: 'https://www.bloomberg.com/news/articles/2017-05-24/to-woo-young-sports-fans-ticketmaster-embraces-a-competitor',
    imgProps: {
      src: bloombergImage,
      width: '500',
      height: '93',
    },
  },
  {
    name: 'forbes',
    href: 'https://www.forbes.com/sites/cheriehu/2016/12/18/how-mobile-millennials-are-transforming-the-music-ticketing-industry',
    imgProps: {
      src: forbesImage,
      width: '460',
      height: '120',
    },
  },
];
