import React from 'react';

import { CaretDownIcon } from 'icons';
import colors from 'styles/colors.constants';
import { formatTickets } from 'utils/format';

import styles from './QuantityPicker.module.scss';

interface QuantityPickerProps {
  quantity: number;
  quantities: number[];
  onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onChangeQuantity: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const QuantityPicker = ({
  quantity,
  quantities = [],
  onChangeQuantity,
  onFocus,
}: QuantityPickerProps) => {
  return (
    <div className={styles['quantity-picker']}>
      <CaretDownIcon fill={colors.white} />
      <select value={quantity} onChange={onChangeQuantity} onFocus={onFocus}>
        {quantities.map((quantity) => (
          <option value={quantity} key={quantity}>
            {formatTickets(quantity)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QuantityPicker;
