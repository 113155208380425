import React from 'react';
import { ApplePay } from 'braintree-web';
import { ValueOf } from 'type-fest';

import ApplePayButtonImage from 'assets/ApplePayButton.png';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import colors from 'styles/colors.constants';
import { Payment } from 'types';

/* https://developers.braintreepayments.com/guides/apple-pay/client-side/javascript/v3 */
const APPLE_PAY_API_VERSION = 1;
const APPLE_PAY_MERCHANT_LABEL = 'GAMETIME';

const APPLE_PAY_ERRORS = {
  SESSION_CREATION_FAILED: 'APPLE_PAY_ERROR/SESSION_CREATION_FAILED',
  MERCHANT_VALIDATION_FAILED: 'APPLE_PAY_ERROR/MERCHANT_VALIDATION_FAILED',
  BRAINTREE_TOKENIZE_FAIL: 'APPLE_PAY_ERROR/BRAINTREE_TOKENIZE_FAIL',
} as const;

type CreateApplePaySession = (params: {
  amount: string;
  applePayInstance: ApplePay;
  onCancel: VoidFunction;
  onError: (error: ValueOf<typeof APPLE_PAY_ERRORS>) => void;
  onPaymentAuthorized: (payment: Partial<Payment>) => void;
}) => void;

interface ApplePayButtonProps {
  onClick: (createApplePaySession: CreateApplePaySession) => void;
}

export default function ApplePayButton({ onClick }: ApplePayButtonProps) {
  const handleClick = () => {
    onClick(
      ({
        amount,
        applePayInstance,
        onCancel,
        onError,
        onPaymentAuthorized,
      }) => {
        if (!window.ApplePaySession) {
          return;
        }

        const paymentRequest = applePayInstance.createPaymentRequest({
          requiredShippingContactFields: ['phone', 'email'],
          requiredBillingContactFields: ['postalAddress'],
          total: {
            label: APPLE_PAY_MERCHANT_LABEL,
            amount,
          },
        });

        /**
         * This type casting is a targeted workaround to avoid a TS error
         * between two libraries that are known to work together, but have
         * incompatible @types declarations.
         *
         * The ApplePayPaymentRequest interface from @types/braintree-web has
         * merchantCapabilities declared as string[], whereas the same
         * interface from @types/applepayjs is expecting the type more
         * correctly as ApplePayMerchantCapability[].
         */
        const merchantCapabilities =
          paymentRequest.merchantCapabilities as ApplePayJS.ApplePayMerchantCapability[];

        const session = new window.ApplePaySession(APPLE_PAY_API_VERSION, {
          ...paymentRequest,
          merchantCapabilities,
        });

        // it's unclear if or why this check is necessary, but it was in the
        // original code. The TS types don't have this instance creation as
        // optional.
        if (!(session as typeof session | undefined)) {
          onError(APPLE_PAY_ERRORS.SESSION_CREATION_FAILED);
          return;
        }

        session.oncancel = () => onCancel();

        session.onvalidatemerchant = (validateMerchantEvent) => {
          applePayInstance
            .performValidation({
              validationURL: validateMerchantEvent.validationURL,
              displayName: APPLE_PAY_MERCHANT_LABEL,
            })
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((validationError) => {
              console.error('Error validating merchant: ', validationError);
              session.abort();
              onError(APPLE_PAY_ERRORS.MERCHANT_VALIDATION_FAILED);
            });
        };

        session.onpaymentauthorized = ({ payment }) => {
          applePayInstance
            .tokenize({ token: payment.token })
            .then((payload) => {
              session.completePayment(ApplePaySession.STATUS_SUCCESS);

              // we can be mostly sure these fields will exist since we are
              // requesting them, but according to the TS types they are
              // optional and still need to be checked...
              const billingContact = payment.billingContact || {};

              onPaymentAuthorized({
                nonce: payload.nonce,
                first_name: billingContact.givenName,
                last_name: billingContact.familyName,
                street_address: billingContact.addressLines?.[0],
                extended_address: billingContact.addressLines?.[1],
                locality: billingContact.locality,
                region: billingContact.administrativeArea,
                postal_code: billingContact.postalCode,
              });
            })
            .catch((tokenizeError) => {
              console.error('Error tokenizing Apple Pay: ', tokenizeError);
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              onError(APPLE_PAY_ERRORS.BRAINTREE_TOKENIZE_FAIL);
            });
        };

        return session;
      }
    );
  };

  return (
    <ThemedButtonLoader backgroundColor={colors.white} onClick={handleClick}>
      <img
        src={ApplePayButtonImage}
        style={{ height: '46px' }}
        alt="Buy with Apple Pay"
      />
    </ThemedButtonLoader>
  );
}
