import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './GTGrid.module.scss';

const GTGrid = ({ children, evenSpacing = false }) => {
  return (
    <div
      className={classNames(styles['gt-grid'], {
        [styles['even-spacing']]: evenSpacing,
      })}
    >
      {children}
    </div>
  );
};

GTGrid.propTypes = {
  children: PropTypes.node,
  evenSpacing: PropTypes.bool,
};

export default GTGrid;
