import React from 'react';
import { useLocation, useNavigation } from 'react-router-dom';
import { usePrevious } from 'react-use';

/**
 * Function to track page loading spinner state across routes & route changes and decide to show/hide page loading spinner
 * @param toPathname path of the upcoming next location
 * @param fromPathname path of the current location
 * @returns true if we need to hide AppSpinner for that particular route or route change, else returns false
 */
function shouldHideAppSpinner(
  toPathname: string = '',
  fromPathname: string = ''
) {
  return (
    toPathname.includes('/listings/') ||
    (toPathname.includes('/events/') &&
      !toPathname.includes('/listings/') &&
      fromPathname.includes('/events/') &&
      fromPathname.includes('/listings/'))
  );
}

export default function withNavigationProps<P>(
  Component: React.ComponentType<P>
) {
  function ComponentWithNavigationProp(props: P) {
    const navigation = useNavigation();
    const location = useLocation();
    const lastRouteLocation = usePrevious(location);
    const hideLoaderBasedOnRouteChange = shouldHideAppSpinner(
      navigation.location?.pathname,
      location.pathname
    );

    return (
      <Component
        {...props}
        navigation={navigation}
        lastRouteLocation={lastRouteLocation}
        showRouteChangeLoader={
          !hideLoaderBasedOnRouteChange && navigation.state === 'loading'
        }
      />
    );
  }

  return ComponentWithNavigationProp;
}
