import React from 'react';
import { Helmet } from 'react-helmet-async';

import { OGType } from './types';

interface HeadOGTypeProps {
  type: OGType;
  helmetProps?: object;
}

const HeadOGType = ({ type, helmetProps = {} }: HeadOGTypeProps) => {
  return (
    <Helmet {...helmetProps}>
      <meta property="og:type" content={type} />
    </Helmet>
  );
};

export default HeadOGType;
