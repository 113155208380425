import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import CategoryPerformersList from './CategoryPerformersList';
import CategoryPerformersNCAA from './CategoryPerformersNCAA';
import CategoryPerformersOther from './CategoryPerformersOther';
import {
  NCAA_CATEGORY,
  OTHER_CATEGORY,
  SPORTS_SUB_CATEGORIES,
} from './constants';

import styles from './CategoriesDropdown.module.scss';

class SportsCategories extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    limit: PropTypes.number,
    currentMetro: PropTypes.object.isRequired,
    fetchLocalSportsTeams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedSubcategory: 'mlb',
    };

    this.setSelectedSubcategory = this.setSelectedSubcategory.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentDidMount() {
    this.fetchLocalSportsTeams();
  }

  fetchLocalSportsTeams() {
    const { currentMetro } = this.props;
    const { selectedSubcategory } = this.state;
    this.props.fetchLocalSportsTeams(currentMetro.id, selectedSubcategory);
  }

  setSelectedSubcategory(selectedSubcategory) {
    this.setState((prevState) => {
      if (prevState.selectedSubcategory !== selectedSubcategory) {
        return { selectedSubcategory };
      }
    }, this.fetchLocalSportsTeams);
  }

  handleMouseEnter = (event) => {
    const category = event.target.getAttribute('data-category');
    if (this.selectSubCategoryInterval) {
      clearTimeout(this.selectSubCategoryInterval);
      this.selectSubCategoryInterval = null;
    }

    this.selectSubCategoryInterval = setTimeout(() => {
      this.setSelectedSubcategory(category);
    }, 200);
  };

  handleMouseLeave() {
    if (this.selectSubCategoryInterval) {
      clearTimeout(this.selectSubCategoryInterval);
      this.selectSubCategoryInterval = null;
    }
  }

  renderPerformersList() {
    const { limit, onClick, currentMetro } = this.props;
    const { selectedSubcategory } = this.state;
    return (
      <>
        {SPORTS_SUB_CATEGORIES.map((category) => {
          if (category === OTHER_CATEGORY)
            return (
              <div
                key={category}
                className={classNames({
                  [styles.hide]: selectedSubcategory !== OTHER_CATEGORY,
                })}
              >
                <CategoryPerformersOther onClick={onClick} />
              </div>
            );
          if (category === NCAA_CATEGORY)
            return (
              <div
                key={category}
                className={classNames({
                  [styles.hide]: selectedSubcategory !== NCAA_CATEGORY,
                })}
              >
                <CategoryPerformersNCAA onClick={onClick} />
              </div>
            );
          return (
            <div
              key={category}
              className={classNames({
                [styles.hide]: selectedSubcategory !== category,
              })}
            >
              <CategoryPerformersList
                category={category}
                limit={limit}
                onClick={onClick}
                metro={currentMetro.id}
              />
            </div>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <>
        <div className={styles['subcategories-list-container']}>
          <Container disableGutters>
            <div className={styles['subcategories-list']}>
              {SPORTS_SUB_CATEGORIES.map((category) => (
                <span
                  key={category}
                  className={classNames(styles.subcategory, {
                    [styles.selected]:
                      this.state.selectedSubcategory === category,
                  })}
                  data-category={category}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                >
                  {category}
                </span>
              ))}
            </div>
          </Container>
        </div>
        <div className={styles['subcategory-performers']}>
          {this.renderPerformersList()}
        </div>
      </>
    );
  }
}

export default SportsCategories;
