import { useEffect, useState } from 'react';

export * from 'styles/breakpoints';

/**
 * Given the media query to match, this uses matchMedia to see if the page matches the
 * query. If it does, the we set the state to true which allows us to re-render the
 * component that uses this hook. If the query is changed or the component is unmounted,
 * the listener is removed to prevent infinite callbacks.
 *
 * @param {string} query the media query to match, formatted as '(min-width: 768px)'
 * @returns true if the page matches the query, false otherwise
 */
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    if (media.addEventListener !== undefined) {
      media.addEventListener('change', listener);
    } else {
      // For browsers that don't support addEventListener (i.e. Safari 14 and below)
      media.addListener(listener);
    }

    return () => {
      if (media.addEventListener !== undefined) {
        media.removeEventListener('change', listener);
      } else {
        // For browsers that don't support removeEventListener (i.e. Safari 14 and below)
        media.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
}
