export const RESALE_STATES = {
  UNKNOWN: 'unknown',
  INELIGIBLE: 'ineligible',
  ELIGIBLE: 'eligible',
  PENDING: 'pending',
  LISTED: 'listed',
  SOLD: 'sold',
  UNSOLD: 'unsold',
  CANCELED: 'canceled',
};

export const ACTIVE_RESALE_STATES = [
  RESALE_STATES.PENDING,
  RESALE_STATES.LISTED,
  RESALE_STATES.SOLD,
  RESALE_STATES.CANCELED,
];
