import React from 'react';

import styles from './UrgencyMessageBlock.module.scss';

type Props = {
  quantity: number;
};

const UrgencyMessage = ({ quantity }: Props) => {
  if (!Number.isInteger(quantity) || quantity < 1) {
    return null;
  }

  const message =
    quantity === 1
      ? 'Only 1 ticket left at this price!'
      : `Only ${quantity} tickets left at this price!`;

  return (
    <div className={styles.container} data-testid="urgency-message">
      <span className={styles.text}>{`⚡️ ${message} ⚡️`}</span>
    </div>
  );
};

export default UrgencyMessage;
