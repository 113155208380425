import React from 'react';
import colors from 'styles/colors.constants';

export default ({ width = '24', height = '24', stroke = '' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5705 1.15058L14.3299 2.16988C14.4997 2.27799 14.7003 2.32432 14.9009 2.32432H16.9227C17.324 2.32432 17.7098 2.54054 17.9104 2.89575L18.929 4.65637C19.037 4.82626 19.1759 4.9807 19.3457 5.07336L21.1051 6.09266C21.4601 6.29344 21.6761 6.67954 21.6761 7.08108V9.10425C21.6761 9.30502 21.7224 9.50579 21.8304 9.67568L22.849 11.4363C23.0497 11.7915 23.0497 12.2239 22.849 12.5792L21.8304 14.3398C21.7224 14.5097 21.6761 14.7104 21.6761 14.9112V16.9344C21.6761 17.3359 21.4601 17.722 21.1051 17.9228L19.3457 18.9421C19.1759 19.0502 19.0216 19.1892 18.929 19.3591L17.9104 21.1197C17.7098 21.4749 17.324 21.6911 16.9227 21.6911H14.8855C14.6849 21.6911 14.4843 21.7375 14.3145 21.8456L12.5705 22.8494C12.2156 23.0502 11.7834 23.0502 11.4285 22.8494L9.6691 21.8301C9.49934 21.722 9.2987 21.6757 9.09807 21.6757H7.06089C6.65963 21.6757 6.2738 21.4595 6.07317 21.1042L5.05458 19.3436C4.94655 19.1737 4.80765 19.0193 4.63788 18.9266L2.8785 17.9073C2.52354 17.7066 2.30747 17.3205 2.30747 16.9189V14.8958C2.30747 14.695 2.26117 14.4942 2.15314 14.3243L1.14999 12.5792C0.949354 12.2239 0.949354 11.7915 1.14999 11.4363L2.16858 9.67568C2.27661 9.50579 2.32291 9.30502 2.32291 9.10425V7.06564C2.32291 6.66409 2.53897 6.27799 2.89393 6.07722L4.65332 5.05792C4.82308 4.94981 4.97741 4.81081 5.07001 4.64093L6.0886 2.88031C6.28923 2.5251 6.67506 2.30888 7.07633 2.30888H9.09807C9.2987 2.30888 9.49934 2.26255 9.6691 2.15444L11.413 1.15058C11.768 0.949807 12.2156 0.949807 12.5705 1.15058Z"
      fill="#19CE85"
    />
    <path
      d="M7 12L10.3333 15.5L17 8.5"
      stroke={stroke || colors.white}
      strokeWidth="2"
    />
  </svg>
);
