import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Switch from 'ui/Switch/Switch';

import { updateListMapHarmonyToggle } from 'store/modules/data/Listings/actions';
import { listMapHarmonyToggleSelector } from 'store/modules/data/Listings/selectors';

import styles from './ListMapHarmonyControl.module.scss';

const ListMapHarmonyControl = ({
  listMapHarmonyToggleIsOn,
  handleListMapHarmonyToggleTracking,
  dispatch,
}) => {
  const handleToggle = () => {
    handleListMapHarmonyToggleTracking(!listMapHarmonyToggleIsOn);
    dispatch(updateListMapHarmonyToggle(!listMapHarmonyToggleIsOn));
  };

  return (
    <div className={styles.container}>
      <span className={styles.text}>Update as I move the map</span>
      <Switch checked={listMapHarmonyToggleIsOn} onChange={handleToggle} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  listMapHarmonyToggleIsOn: listMapHarmonyToggleSelector(state),
});

ListMapHarmonyControl.propTypes = {
  listMapHarmonyToggleIsOn: PropTypes.bool,
  handleListMapHarmonyToggleTracking: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(memo(ListMapHarmonyControl));
