import React from 'react';

import colors from 'styles/colors.constants';

interface StarSmallIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

export default function StarSmallIcon({
  width = '16',
  height = '16',
  fill = colors.black,
}: StarSmallIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="star-small-icon"
    >
      <path
        d="M7.40509 2.98833C7.64478 2.4894 8.35522 2.4894 8.59491 2.98833L9.581 5.04092C9.67721 5.24119 9.86773 5.37961 10.0879 5.40923L12.3448 5.71277C12.8934 5.78655 13.1129 6.46222 12.7125 6.84436L11.065 8.41647C10.9043 8.56986 10.8315 8.79383 10.8714 9.0124L11.2801 11.2526C11.3795 11.7971 10.8047 12.2147 10.3175 11.9519L8.3133 10.871C8.11775 10.7655 7.88225 10.7655 7.6867 10.871L5.68245 11.9519C5.19527 12.2147 4.62052 11.7971 4.71987 11.2526L5.12859 9.0124C5.16847 8.79383 5.0957 8.56986 4.93496 8.41647L3.28755 6.84436C2.88711 6.46222 3.10664 5.78655 3.65522 5.71277L5.91207 5.40923C6.13227 5.37961 6.32279 5.24119 6.419 5.04092L7.40509 2.98833Z"
        fill={fill}
      />
    </svg>
  );
}
