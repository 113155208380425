import React, { Component } from 'react';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import {
  BTN_TYPES,
  Click,
  DeepLinkClickTracker,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import DeepLink from 'components/DeepLink/DeepLink';
import {
  DEEPLINK_ACTIONS,
  DEEPLINK_CAMPAIGNS,
} from 'components/DeepLink/DeepLink.constants';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import homeFeatures from 'pages/Home/home.features';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import styles from './WhyGametime.module.scss';

@withAppContext
@TrackPageView(() => ({
  pageType: View.PAGE_TYPES.STATIC_PAGE('why-gametime'),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.STATIC_PAGE('why-gametime'),
}))
class WhyGametime extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  };

  renderMeta() {
    return (
      <div>
        <HeadTitle
          title="Gametime vs. StubHub & SeatGeek | Why Gametime"
          helmetProps={{ titleTemplate: '%s' }}
        />
        <HeadDescription description="Is Gametime legit? Why use Gametime vs. StubHub? Learn more about the most reliable ticket marketplaces. (Gametime App Reviews)" />
      </div>
    );
  }

  renderContent() {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    return (
      <>
        <div className={classNames(styles.module, styles['sizzle-module'])}>
          <div className={styles['sizzle-image-container']}>
            <img
              src="https://assets.gametime.co/static_pages/competition/splash-large.jpg"
              className={styles['sizzle-image']}
              alt="Gametime"
            />
          </div>
          <div className={styles['sizzle-information-container']}>
            <div className={styles['sizzle-information']}>
              <h1>Why Choose Gametime vs. StubHub, SeatGeek or Vivid Seats?</h1>
            </div>
            <div className={styles['sizzle-information-image']} />
          </div>
        </div>

        <div className={styles.module}>
          <div className={`container ${styles['container-custom']}`}>
            <div className={`row ${styles['highlight-shell']}`}>
              <div className="col-12 col-md-4">
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/competition/icon-lower-fees.svg"
                    className={styles['highlight-img']}
                    alt="Lower Fees"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Lower Fees &#8594; Better Prices</h4>
                    <p>Gametime fees are some of the lowest in the industry.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/competition/icon-curated-list.svg"
                    className={styles['highlight-img']}
                    alt="Curated listings"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Curated Listings</h4>
                    <p>
                      We save you time by only showing the very best options.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/competition/icon-360-view.svg"
                    className={styles['highlight-img']}
                    alt="Panoramic Seat View Photos"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Panoramic Seat View Photos</h4>
                    <p>
                      See the view from your seat before you buy &mdash; no
                      surprises.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles['btn-container'],
                styles['highlight-btn']
              )}
              alt="Download Gametime"
            >
              <DeepLink
                campaign={DEEPLINK_CAMPAIGNS.WHY_GAMETIME}
                fireFacebookEvent
                clickTracker={new DeepLinkClickTracker(isMobile).interaction(
                  Click.INTERACTIONS.BUTTON(),
                  {
                    [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
                    [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.DOWNLOAD,
                  }
                )}
              >
                <div className={styles['gametime-btn']}>DOWNLOAD GAMETIME</div>
              </DeepLink>
            </div>
          </div>
        </div>

        <div className={styles.module}>
          <div className="container">
            <h2>Why buy from Gametime?</h2>
            <p>
              From the beginning, we built{' '}
              <a
                href="https://gametime.co/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Gametime
              </a>{' '}
              to be different from the rest. With a passion for enabling
              incredible live experiences, we&rsquo;re making it easier for
              people to access their favorite events at a moment&rsquo;s notice.
              Our team is focused on delivering the best possible ticket buying
              experience &mdash; especially at the last minute &mdash; and that
              means making it fast and easy to get tickets on your phone.
              <br />
              <br />
              We know comparing prices across apps can be a pain, so Gametime
              prices are based in transparency. We display only the best deals,
              so you can skip the endless scrolling, and with All-In Pricing
              toggled on, you’ll see the full ticket price up front &mdash; no
              hidden fees!
              <br />
              <br />
              Unlike other ticket marketplaces, our purchase process takes just
              two taps and ten seconds. And once you&rsquo;ve bought your
              tickets, we deliver them directly to your phone &mdash; no printer
              needed. The Gametime app allows you to easily share tickets with
              friends via text, so you can get into the game seamlessly.
            </p>
          </div>
        </div>

        <div
          className={classNames(styles.module, styles['app-reviews-module'])}
        >
          <div className="container">
            <h3 className={styles['h3-custom']}>Gametime App Reviews</h3>
          </div>
          <div className={classNames(styles['container-custom'], 'container')}>
            <div className={styles['review-custom']}>
              <div className="row">
                <div className={`col-12 col-lg-4 ${styles.review}`}>
                  <div className={styles['review-block']}>
                    <div className={styles['review-star-container']}>
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                    </div>
                    <h3>Better than StubHub</h3>
                    <p className={styles['review-description']}>
                      Simply the best for last-minute ticket purchases. You can
                      even buy tickets after games have started, which can make
                      for some killer deals!
                    </p>
                  </div>
                </div>
                <div className={`col-12 col-lg-4 ${styles.review}`}>
                  <div className={styles['review-block']}>
                    <div className={styles['review-star-container']}>
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                    </div>
                    <h3>Boom!</h3>
                    <p className={styles['review-description']}>
                      What more could you ask for from a mobile ticketing
                      service? Purchased my tix, scanned the phone... walked
                      right to my seat and enjoyed the game!
                    </p>
                  </div>
                </div>
                <div
                  className={`col-12 col-lg-4 d-lg-block d-none ${styles.review}`}
                >
                  <div className={styles['review-block']}>
                    <div className={styles['review-star-container']}>
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                      <div
                        className={classNames(
                          styles.full,
                          styles['review-star']
                        )}
                      />
                    </div>
                    <h3>Quick and easy!</h3>
                    <p className={styles['review-description']}>
                      Super easy to buy last-minute tickets and always have
                      decent sections available! I&rsquo;ve had great
                      experiences every time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.module,
            styles['prices-and-guarantees-module']
          )}
        >
          <div className="container">
            <h2>Gametime Prices &amp; Guarantees</h2>
            <p>
              We work hard to ensure that the Gametime experience is positive,
              safe, and secure for all our users. From vigorous buyer
              protections to our low last-minute prices, check out some of the
              ways we&rsquo;re improving the ticket buying process:
            </p>
          </div>
          <div className={`container ${styles['prices-protection-container']}`}>
            <div className="row">
              <div className="col-12 col-md-7 col-lg-6">
                <div className={styles['prices-protection-block']}>
                  <h3>Transparent Pricing</h3>
                  <p>
                    We&rsquo;re making it easier to get into events with clear,
                    competitive pricing. Gametime fees are some of the lowest,
                    which means Gametime prices are more affordable and allow
                    fans to go to more of the events they love. In fact,
                    we&rsquo;re so confident in our prices that we created our{' '}
                    <a
                      href="https://gametime.co/price-guarantee"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Gametime Price Guarantee
                    </a>
                    . If you find a lower price elsewhere, we&rsquo;ll add 110%
                    of the difference in Gametime credit to your account.
                  </p>
                </div>
                <div className={styles['prices-protection-block']}>
                  <h3>Gametime Guarantee</h3>
                  <p>
                    Most reliable ticket marketplaces will offer protections for
                    buyers, but our{' '}
                    <a
                      href="https://gametime.co/gametime-guarantee"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Gametime Guarantee
                    </a>{' '}
                    takes your security a step further. We guarantee
                    you&rsquo;ll have your tickets in time for the event and
                    that they&rsquo;ll be valid for entry, so you can buy with
                    confidence every time.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-6">
                <img
                  src="https://assets.gametime.co/static_pages/competition/phone-demo-single.png"
                  width="100%"
                  className={styles['prices-and-protection-image']}
                  alt="Prices and Protection"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.module}>
          <div className="container">
            <h2>Gametime vs. SeatGeek, StubHub &amp; Vivid Seats</h2>
            <p>
              Gametime is one of the fastest growing ticket marketplaces in the
              industry &mdash; and for good reason! We&rsquo;re dedicated to
              upgrading the ticket buying experience, which means we&rsquo;re
              always monitoring Gametime reviews and making improvements for our
              users. Check out some of the unique features that make Gametime
              stand apart from the competition:{' '}
            </p>
          </div>
          <div className={`container ${styles['vs-container']}`}>
            <div className="row">
              <div className={`col-12 col-md-6 ${styles['vs-bullet']}`}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/icon-logo-arrow.svg"
                  className={styles['vs-container-bullet']}
                  alt="Gametime"
                />
                <div className={styles['vs-container-copy']}>
                  <p>
                    Our panoramic seat view photos show you the view from any
                    section, so you know exactly what you&rsquo;re getting
                    before you buy.
                  </p>
                </div>
              </div>
              <div className={`col-12 col-md-6 ${styles['vs-bullet']}`}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/icon-logo-arrow.svg"
                  className={styles['vs-container-bullet']}
                  alt="Gametime"
                />
                <div className={styles['vs-container-copy']}>
                  <p>
                    With All-In Pricing, Gametime prices are displayed including
                    all Gametime fees, so you&rsquo;re never surprised by hidden
                    add-ons.
                  </p>
                </div>
              </div>
              <div className={`col-12 col-md-6 ${styles['vs-bullet']}`}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/icon-logo-arrow.svg"
                  className={styles['vs-container-bullet']}
                  alt="Gametime"
                />
                <div className={styles['vs-container-copy']}>
                  <p>
                    Share tickets with friends via text or email directly from
                    your phone, making scanning in at the venue fast and simple.
                  </p>
                </div>
              </div>
              <div className={`col-12 col-md-6 ${styles['vs-bullet']}`}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/icon-logo-arrow.svg"
                  className={styles['vs-container-bullet']}
                  alt="Gametime"
                />
                <div className={styles['vs-container-copy']}>
                  <p>
                    We make it easier than ever to get into an event at the last
                    minute. Grab tickets up to 90 minutes after start time with
                    LastCall.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.module, styles['download-module'])}>
          <div className={styles['download-image-container']}>
            <img
              className={styles['download-image-lg']}
              alt="Download"
              src="https://assets.gametime.co/static_pages/competition/download-gametime-large.jpg"
            />
            <img
              className={styles['download-image-md']}
              alt="Download"
              src="https://assets.gametime.co/static_pages/competition/download-gametime-medium.jpg"
            />
            <img
              className={styles['download-image-sm']}
              alt="Download"
              src="https://assets.gametime.co/static_pages/competition/download-gametime-small.jpg"
            />
          </div>
          <div className="container">
            <div className={styles['download-information-container']}>
              <div className="row justify-content-end">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className={styles['download-information-content']}>
                    <h5 className={styles['download-information-heading']}>
                      Download Gametime
                    </h5>
                    <p>
                      Get the Gametime mobile app to find events near you,
                      compare prices easily, and buy tickets in just two taps.
                    </p>
                    <DeepLink
                      campaign={DEEPLINK_CAMPAIGNS.WHY_GAMETIME}
                      fireFacebookEvent
                      clickTracker={new DeepLinkClickTracker(
                        isMobile
                      ).interaction(Click.INTERACTIONS.BUTTON(), {
                        [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
                        [PAYLOAD.ACTION]: DEEPLINK_ACTIONS.DOWNLOAD,
                      })}
                    >
                      <div
                        className={classNames(
                          styles['btn-container'],
                          styles['download-information-btn-container']
                        )}
                      >
                        <div className={styles['gametime-btn']}>
                          DOWNLOAD THE APP
                        </div>
                      </div>
                    </DeepLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.module, styles['reputable-module'])}>
          <div className="container">
            <h2>Is Gametime legit and reputable?</h2>
            <p>
              <span className={styles.bold}>
                In a word: yes! Gametime was founded in 2012 and today is one of
                the most reliable ticket marketplaces across 50 cities in the
                U.S. and Canada.
              </span>
            </p>
            <p>
              One of the main differences between Gametime vs. SeatGeek,
              StubHub, or other competitors is that we&rsquo;re focused on
              last-minute mobile tickets, giving you access to the most popular
              events at the drop of a hat.
              <br />
              <br />
              When you&rsquo;re buying tickets to see your favorite team or
              performer, you shouldn&rsquo;t have to worry about your tickets
              being authentic or getting them on time. We stand by every
              purchase, ensuring your tickets will be legitimate and
              you&rsquo;ll have them in time for your event. And our friendly,
              US-based customer support team is always here to help. We&rsquo;re
              available by email, chat, and phone, so you can get the answers
              you need quickly.
              <br />
              <br />
              Check out more Gametime app reviews from the press, or download
              the app to find events near you.{' '}
            </p>
          </div>
        </div>

        <div className={classNames(styles.module, styles['awards-module'])}>
          <div className="container">
            <div className="row">
              <div className={styles['award-block']}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/award-webby.png"
                  width="100%"
                  alt="Webby Award"
                />
                <p>
                  <span className={styles.bold}>
                    &ldquo;Best User Experience-Mobile Sites & Apps&rdquo;
                  </span>
                </p>
                <p>The Webby Awards (2018)</p>
              </div>
              <div
                className={classNames(
                  styles['award-block'],
                  styles['award-block-business-insider']
                )}
              >
                <img
                  src="https://assets.gametime.co/static_pages/competition/award-business-insider.png"
                  width="100%"
                  alt="Business Insider Award"
                />
                <p>
                  <span className={styles.bold}>
                    &ldquo;Fastest Growing Ticket Company Ever&rdquo;
                  </span>
                </p>
                <p>Business Insider (2017)</p>
              </div>
              <div className={styles['award-block']}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/award-appy.png"
                  width="100%"
                  alt="Appy Award"
                />
                <p>
                  <span className={styles.bold}>
                    &ldquo;Best Event App&rdquo;
                  </span>
                </p>
                <p>2017 Appy Award</p>
              </div>
              <div className={styles['award-block']}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/award-sports-business-journal.png"
                  width="100%"
                  alt="Sports Business Journal Award"
                />
                <p>
                  <span className={styles.bold}>
                    &ldquo;Best in Mobile Fan Experience&rdquo;
                  </span>
                </p>
                <p>Sports Business Journal (2017)</p>
              </div>
              <div className={styles['award-block']}>
                <img
                  src="https://assets.gametime.co/static_pages/competition/award-inc-5000.png"
                  width="100%"
                  alt="Inc Award"
                />
                <p>
                  <span className={styles.bold}>
                    &ldquo;The Fastest Growing Consumer Company in the
                    USA&rdquo;
                  </span>
                </p>
                <p>Inc Magazine, Top 5000 (2019)</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderFeatures() {
    return (
      <div className={styles['features-section']}>
        <div className={classNames(styles.module, styles['press-module'])}>
          <div className="container">
            <div className={styles['press-header']}>
              Gametime App Reviews from the Press
            </div>
            <div className={styles['press-subheader']}>
              Since we launched in 2012, we&rsquo;ve made a name for ourselves
              as one of the most reliable ticket marketplaces in the industry.
            </div>
            <div className={styles.features}>
              {homeFeatures.map((feature) => (
                <a
                  href={feature.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={feature.name}
                  className={classNames(styles['feature-image'], feature.name)}
                >
                  <img
                    loading="lazy"
                    alt={feature.name}
                    {...feature.imgProps}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <GTContainer
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
        className={styles['why-gametime-container']}
      >
        {this.renderMeta()}
        {this.renderContent()}
        {this.renderFeatures()}
      </GTContainer>
    );
  }
}

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    await store.dispatch(fetchMetros());

    return null;
  };

WhyGametime.loader = loader;

export default WhyGametime;
