import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { framer } from './Drawer.constants';
import { DrawerProps } from './Drawer.types';
import Header from './DrawerHeader';

import styles from './Drawer.module.scss';

/**
 * @deprecated this drawer should only be used for the Listing Details overlay,
 * if you need a drawer for a different use case we should refactor this or
 * write a new one.
 */
const Drawer = ({
  children,
  anchor = 'left',
  isOpen = false,
  className,
  drawerKey = 'drawer',
  useDefaultStyles = true,
  shouldAnimate = true,
  initialStyle,
  exitStyle,
}: DrawerProps) => {
  const { transition, initial, exit, animate } = framer;

  const motionStyles = {
    transition,
    animate,
    initial: initialStyle || initial[anchor],
    exit: exitStyle || exit[anchor],
  };

  const motionClassNames = classNames(
    useDefaultStyles && styles['drawer-container'],
    useDefaultStyles && styles[`drawer-container-${anchor}`],
    className
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key={drawerKey}
          {...(shouldAnimate ? motionStyles : {})}
          className={motionClassNames}
          style={{ height: '100%' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Drawer.Header = Header;
export default Drawer;
