import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { useSeatDescription } from 'components/SeatDescription/useSeatDescription';
import config from 'config/config';
import { ENV } from 'config/environments';
import ChevronIcon from 'icons/ChevronIcon';
import { DELIVERY_FORMATS } from 'store/datatypes/DELIVERY_FORMATS';
import colors from 'styles/colors.constants';

import { MLB_BALLPARK_PERFORMER_SLUGS } from './constants';

import styles from './TicketCard.module.scss';

const getTicketURL = (id, performerSlug, deliveryFormat, displayPDF) => {
  const urlEnvMap = {
    [ENV.PRODUCTION]: 'shared-tickets',
    [ENV.PRE_PRODUCTION]: 'shared-tickets',
    [ENV.TESTING]: 'shared-tickets-testing',
    [ENV.QA]: 'shared-tickets-staging',
    [ENV.STAGING]: 'shared-tickets-staging',
    [ENV.DEVELOPMENT]: 'shared-tickets-staging',
  };

  const sharedTicketsSubDomain =
    urlEnvMap[config.env] || urlEnvMap[ENV.PRODUCTION];

  if (
    (deliveryFormat && deliveryFormat === DELIVERY_FORMATS.PRINT_ONLY) ||
    displayPDF
  ) {
    return `https://${sharedTicketsSubDomain}.gametime.co/ticket/original/${id}`;
  }

  return MLB_BALLPARK_PERFORMER_SLUGS.includes(performerSlug)
    ? `https://${sharedTicketsSubDomain}.gametime.co/tickets?ticket_id=${id}`
    : `https://${sharedTicketsSubDomain}.gametime.co/ticket/${id}`;
};

const TicketCard = ({
  ticket,
  sectionGroup,
  section,
  row,
  primaryPerformerSlug,
  clickTracker,
  deliveryFormat,
}) => {
  const { title, seat } = useSeatDescription({
    sectionGroup,
    section,
    row,
    ticket,
  });
  return (
    <Link
      className={styles['ticket-card']}
      to={getTicketURL(
        ticket.id,
        primaryPerformerSlug,
        deliveryFormat,
        ticket.type === 'url' ||
          (ticket.type_info?.display_pdf_first && ticket.url)
      )}
      target="_blank"
      rel="noopener noreferrer"
      clickTracker={clickTracker}
    >
      <div className={styles['ticket-card-description']}>
        <span className={styles['ticket-card-title']}>{title}</span>
        {!!ticket.seat && ticket.seat !== '*' && (
          <span className={styles['ticket-card-subtitle']}>{seat}</span>
        )}
      </div>
      <ChevronIcon
        width={12}
        height={12}
        color={colors.gray200}
        direction="right"
      />
    </Link>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.object.isRequired,
  sectionGroup: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  primaryPerformerSlug: PropTypes.string.isRequired,
  clickTracker: PropTypes.object,
  deliveryFormat: PropTypes.string,
};

export default TicketCard;
