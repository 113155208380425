import React from 'react';

type SVGProps = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'className'
>;
type Props = SVGProps & {
  primaryColor?: string;
};

export default function LogoSmall({
  width = '40',
  height = '40',
  className,
  primaryColor = '#fff',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={primaryColor}
        d="M25.0749 1H5.04239C4.2388 1 3.73948 1.88403 4.14258 2.58861L12.4706 16.2L20.8385 7.16444C20.9165 7.05361 21.0647 7.02194 21.1791 7.09056L21.2104 7.10903C21.3352 7.18292 21.3768 7.34389 21.304 7.47056L14.1263 19.9551L4.14258 37.4114C3.73948 38.116 4.2388 39 5.04239 39H25.0775L36 20L25.0749 1Z"
      />
    </svg>
  );
}
