import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function GemIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.58592 3.00063C5.48063 3.00882 5.37836 3.03955 5.28611 3.09076C5.19386 3.14197 5.1138 3.21244 5.05141 3.29733L1.14185 8.65277C1.0412 8.78958 0.991484 8.95703 1.00123 9.12637C1.01099 9.29571 1.07959 9.45638 1.19529 9.58082L11.4579 20.771C11.5293 20.8436 11.6151 20.9006 11.7099 20.9383C11.7274 20.9467 11.7452 20.9543 11.7633 20.9612C11.7684 20.9613 11.7736 20.9613 11.7787 20.9612C11.7962 20.9695 11.814 20.9772 11.8322 20.9841C11.8372 20.9841 11.8423 20.9841 11.8473 20.9841C11.89 20.993 11.9335 20.998 11.9771 20.9992C11.9822 20.9992 11.9874 20.9992 11.9925 20.9992C12.041 21.0015 12.0896 20.9989 12.1376 20.9916C12.1822 20.9805 12.2256 20.9653 12.2674 20.946C12.2699 20.946 12.2724 20.946 12.275 20.946C12.3409 20.9196 12.4026 20.8837 12.4582 20.8395C12.4607 20.8395 12.4633 20.8395 12.4658 20.8395C12.4928 20.8185 12.5184 20.7956 12.5422 20.7711L22.8048 9.58089C22.9205 9.45645 22.9891 9.29576 22.9988 9.12642C23.0086 8.95708 22.9589 8.78964 22.8582 8.65283L18.9487 3.29739C18.8799 3.2046 18.7902 3.12934 18.6866 3.07776C18.5831 3.02618 18.4688 2.99975 18.3531 3.00063H5.73864H5.66228C5.65723 3.00058 5.65218 3.00058 5.64713 3.00063C5.62677 2.99979 5.6064 2.99979 5.58604 3.00063H5.58592ZM6.69311 4.46121H10.45L7.84613 7.63338L6.69311 4.46121ZM13.5501 4.46121H17.307L16.154 7.63338L13.5501 4.46121ZM12 4.87962L14.8559 8.35608H9.14423L12 4.87962ZM5.42558 5.26757L6.55569 8.35608H3.18061L5.42558 5.26757ZM18.5745 5.26757L20.8195 8.35608H17.4444L18.5745 5.26757ZM3.40206 9.81666H7.10547L9.86963 16.8761L3.40206 9.81666ZM8.67843 9.81666H15.3217L12 18.2834L8.67843 9.81666ZM16.8946 9.81666H20.598L14.1305 16.8761L16.8946 9.81666Z"
        fill={fill}
      />
    </svg>
  );
}
