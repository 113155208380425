import { LOGIN_STEPS } from './constants';

export const initialState = {
  step: LOGIN_STEPS.USER_EMAIL,
  hasError: false,
  hasInvalidCodeError: false,
  isSubmitting: false,
  email: null,
  phone: null,
  nextAllowedTokenRequestTime: null,
};

export const ACTION = {
  SET_ERROR: 'SET_ERROR',
  SET_INVALID_CODE_ERROR: 'SET_INVALID_CODE_ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  SET_STEP: 'SET_STEP',
  SUBMIT: 'SUBMIT',
  SUBMIT_EMAIL: 'SUBMIT_EMAIL',
  SET_NEXT_ALLOWED_TOKEN_REQUEST_TIME: 'SET_NEXT_ALLOWED_TOKEN_REQUEST_TIME',
  SET_PHONE: 'SET_PHONE',
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTION.SET_ERROR: {
      return {
        ...state,
        isSubmitting: false,
        hasError: true,
        step: LOGIN_STEPS.USER_EMAIL,
      };
    }
    case ACTION.SET_INVALID_CODE_ERROR: {
      return {
        ...state,
        isSubmitting: false,
        hasInvalidCodeError: true,
      };
    }
    case ACTION.CLEAR_ERRORS: {
      return {
        ...state,
        isSubmitting: false,
        hasError: false,
        hasInvalidCodeError: false,
      };
    }
    case ACTION.SET_STEP: {
      return {
        ...state,
        isSubmitting: false,
        step: action.step,
      };
    }
    case ACTION.SUBMIT: {
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    }
    case ACTION.SUBMIT_EMAIL: {
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
        email: action.email,
      };
    }
    case ACTION.SET_PHONE: {
      return {
        ...state,
        phone: action.phone,
      };
    }
    case ACTION.SET_NEXT_ALLOWED_TOKEN_REQUEST_TIME: {
      return {
        ...state,
        nextAllowedTokenRequestTime: action.nextAllowedTokenRequestTime,
      };
    }
    default: {
      throw new Error('Unrecognized Action', action.type);
    }
  }
}
