import { normalizedHtmlText, removeLineBreaks } from 'utils/strings/textUtils';

export interface FAQ {
  question: string;
  answer: string;
}

const FAQ_HEADING: string = 'Frequently Asked Questions';

export const hasFAQs = (htmlString: string): boolean =>
  htmlString.includes(FAQ_HEADING);

const getFAQText = (text: string): string => {
  const parts = text.split(FAQ_HEADING);
  return parts.length > 1 ? parts[1] : '';
};

const extractQuestionsAndAnswers = (faqText: string): FAQ[] => {
  if (!faqText) {
    return [];
  }

  const faqs: FAQ[] = [];
  const lines = faqText.split('\n');
  let currentQuestion = '';
  let currentAnswer = '';

  const addFAQ = (question: string, answer: string) => {
    if (question && answer) {
      faqs.push({
        question: removeLineBreaks(question),
        answer: removeLineBreaks(answer.trim()),
      });
    }
  };

  // Regular expression to detect questions ending with a question mark
  const questionRegex = /^.*\?$/;

  for (const line of lines) {
    const trimmedLine = line.trim();

    // Check if the line is a question using the regular expression
    if (questionRegex.test(trimmedLine)) {
      // If there's a current question, store it along with its answer
      if (currentQuestion) {
        addFAQ(currentQuestion, currentAnswer);
      }
      // Set the current line as the new question
      currentQuestion = trimmedLine;
      currentAnswer = '';
    } else if (trimmedLine) {
      // If the line is not empty, append it to the current answer
      currentAnswer += `${trimmedLine} `;
    }
  }

  // Push the last question and answer if present
  if (currentQuestion) {
    addFAQ(currentQuestion, currentAnswer);
  }

  return faqs;
};

const extractFAQs = (htmlString: string): FAQ[] | null => {
  if (!hasFAQs(htmlString)) {
    return null;
  }

  const normalizedText = normalizedHtmlText(htmlString);

  const faqText = getFAQText(normalizedText);
  if (!faqText) {
    return null;
  }

  const faqs = extractQuestionsAndAnswers(faqText);

  return faqs.length ? faqs : null;
};

export default extractFAQs;
