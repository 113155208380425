import { ACTIONS } from './actions';

const initialState = {};

export const getFilterKey = (filters) => {
  const filterKeys = [];

  if (filters.metro) {
    filterKeys.push(filters.metro);
  }

  if (filters.slug) {
    filterKeys.push(`slug_${filters.slug}`);
  }

  if (filters.view) {
    filterKeys.push(`view_${filters.view}`);
  }

  return filterKeys.join('+');
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_COLLECTIONS_REQUEST_SUCCESS:
      const {
        params,
        result: { collections },
      } = action;
      const filterKey = getFilterKey(params);

      if (filterKey && collections && collections.length) {
        return {
          ...state,
          [filterKey]: collections.map((c) => c.id),
        };
      }
      return state;
    default:
      return state;
  }
}
