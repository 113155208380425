import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export const Title = ({ title, helmetProps }) => {
  if (!title) {
    return null;
  }
  return (
    <Helmet {...helmetProps}>
      <title>{title}</title>
      <meta property="og:title" content={title} />
    </Helmet>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  helmetProps: PropTypes.object,
};

export default Title;
