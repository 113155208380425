import { create as braintreeClientCreate } from 'braintree-web/client';

import config from 'config/config';

function normalizeExpiration(exp = '') {
  if (exp.length < 4) {
    return '';
  }
  return `${exp.slice(0, 2)}/${exp.slice(2)}`;
}

export const processAffirmPayVcn = (vcn) => {
  return new Promise((resolve, reject) =>
    braintreeClientCreate(
      {
        authorization: config.BRAINTREE_CLIENT_TOKEN,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error(
            'Error creating Braintree client for Affirm: ',
            clientErr
          );
          reject(clientErr);
          return;
        }

        const values = {
          cardholderName: vcn.cardholder_name,
          number: vcn.number,
          cvv: vcn.cvv,
          expirationDate: normalizeExpiration(vcn.expiration),
          billingAddress: {
            postalCode: vcn.billing_address?.zipcode,
          },
        };

        clientInstance.request(
          {
            endpoint: 'payment_methods/credit_cards', // Used in Braintree
            method: 'post',
            data: {
              creditCard: values,
            },
          },
          (err, result) => {
            if (err) {
              console.error(err);
              reject(err);
            }

            resolve(result.creditCards[0].nonce);
          }
        );
      }
    )
  );
};
