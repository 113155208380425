const poller = (timeout, interval, pollFn, checkFn, errorFn) => {
  const endTime = Number(new Date()) + timeout;

  const checkCondition = (resolve, reject) => {
    pollFn()
      .then((result) => {
        if (checkFn(result)) {
          resolve(result);
        } else if (Number(new Date()) < endTime) {
          setTimeout(checkCondition, interval, resolve, reject);
        } else {
          reject(new Error('timed out'));
        }
      })
      .catch((error) => {
        reject(errorFn(error));
      });
  };

  return new Promise(checkCondition);
};

export default poller;
