import { createSelector } from 'reselect';

import Venue from 'models/Venue';

import { getFilterKey } from './filterReducer';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _selectVenues = (state) => state.data.venues;
const _selectVenueBySlug = (state, slug) => _selectVenues(state)[slug];
const _selectVenuesFilters = (state) => state.dataFilters.venues;
const _selectVenuesSlugsByFilters = (state, filters) => {
  const filterKey = getFilterKey(filters);
  return _selectVenuesFilters(state)[filterKey];
};

export const selectVenueBySlug = createSelector(
  _selectVenueBySlug,
  (venueObject) => (venueObject ? new Venue(venueObject) : null)
);

/* Returns Models */
export const selectVenuesByFilters = createSelector(
  _selectVenues,
  _selectVenuesSlugsByFilters,
  (venuesObjects, venueSlugs) => {
    if (!venueSlugs) {
      return null;
    }

    return venueSlugs.map((venueSlug) => new Venue(venuesObjects[venueSlug]));
  }
);
