import React from 'react';

import { ClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import MatchupImage from 'components/MatchupImage/MatchupImage';
import { FullEvent } from 'models';
import { formatPriceWithComma } from 'utils/number';

import styles from './MetroPickerCollectionCard.module.scss';

interface MetroPickerCollectionCardProps {
  source: FullEvent;
  clickTracker: ClickTracker;
  lazyLoad: boolean;
  showPriceInImage?: boolean;
}

const MetroPickerCollectionCard = ({
  source,
  clickTracker,
  lazyLoad,
  showPriceInImage = true,
}: MetroPickerCollectionCardProps) => {
  const link = source.getPath();
  const imageSrc = source.getPrimaryPerformer().heroImageUrl;
  const altTextForImage = source.getAltText();
  const price = source.getPrice();
  const title = source.getShortName('@');
  const subtitle = <BulletList listItems={source.dateTimeVenue} />;
  const formattedPrice = `From $${formatPriceWithComma(price)}`;

  const showMLBMatchupLogos =
    source instanceof FullEvent && source.hasMatchupLogos;

  return (
    <Link
      className={styles['collection-card']}
      to={link}
      clickTracker={clickTracker}
    >
      <div className={styles['image-container']}>
        {showMLBMatchupLogos ? (
          <MatchupImage
            lazyLoad={lazyLoad}
            homePerformer={source.getPrimaryPerformer()}
            awayPerformer={source.getSecondaryPerformer()}
          />
        ) : (
          <Image src={imageSrc} alt={altTextForImage} lazyLoad={lazyLoad} />
        )}
        {showPriceInImage && (
          <span
            className={styles['card-price']}
            aria-label={`Starting price $${formatPriceWithComma(price)}`}
            role="text"
          >
            {formattedPrice}
          </span>
        )}
      </div>
      <span className={styles['body']}>
        <h3 className={styles.title}>{title}</h3>
        <span className={styles.subtitle}>{subtitle}</span>
        {!showPriceInImage && (
          <span className={styles['price-detail']}>{formattedPrice}</span>
        )}
      </span>
    </Link>
  );
};

export default MetroPickerCollectionCard;
