import React, { createContext, PropsWithChildren, useContext } from 'react';
import _once from 'lodash/once';

export interface RadioContextValue<T> {
  /**
   * The name of the radio group. Applied as the `name` attribute of the
   * individual radio inputs within the radio group.
   */
  name: string;
  /**
   * The selected value of the radio group.
   */
  value?: T;
  /**
   * The callback function to call when the value changes, receives the value
   * passed to the Radio element as an argument.
   */
  onChange: (item: T) => void;
}

// In order to apply generic type parameters to a React Context, we have to
// create a wrapper function where the generic type parameters are defined.
// The inner function is called once, the result is cached and returned on
// subsequent calls to ensure that the context is created only once, and that
// the same context is used throughout the application.
const getRadioContext = _once(function createRadioContext<T>() {
  const Context = createContext<RadioContextValue<T> | null>(null);
  Context.displayName = 'RadioContext';

  return Context;
});

export type RadioProviderProps<T> = PropsWithChildren<RadioContextValue<T>>;

export function RadioProvider<T>({
  children,
  onChange,
  name,
  value,
}: RadioProviderProps<T>) {
  const Context = getRadioContext<T>();
  return (
    <Context.Provider
      value={{
        name,
        onChange,
        value,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useRadio<T>() {
  const context = useContext<RadioContextValue<T> | null>(getRadioContext<T>());

  if (!context) {
    throw new Error(
      'Radio compound components cannot be rendered outside the RadioGroup component'
    );
  }

  return context;
}
