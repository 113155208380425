import React from 'react';

import styles from './Notifications.module.scss';

export type ToastNotificationProps = {
  title: string;
  message: string;
};

const ToastNotification = ({ title, message }: ToastNotificationProps) => {
  return (
    <div className={styles['notification-container']}>
      <span className={styles['notification-title']}>{title}</span>
      <span className={styles['notification-message']}>{message}</span>
    </div>
  );
};

export default ToastNotification;
