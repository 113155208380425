export const SUBSCRIBE_PAGE_TYPE_NAMES = {
  METRO: 'metro',
  PERFORMER: 'performer',
};

export class Subscribe {
  constructor({
    pageType,
    eventId,
    venueId,
    metro,
    performerId,
    dev,
    payload,
  }) {
    this.pageType = pageType;
    this.eventId = eventId;
    this.venueId = venueId;
    this.metro = metro;
    this.performerId = performerId;
    this.dev = dev;
    this.payload = payload;
  }

  eventName() {
    return 'subscribe';
  }

  toParams() {
    const params = {
      pageType: this.pageType,
      event_id: this.eventId,
      venue_id: this.venueId,
      metro: this.metro,
      performer_id: this.performerId,
    };

    for (const key in this.dev) {
      if (Object.prototype.hasOwnProperty.call(this.dev, key)) {
        params[`dev_${key}`] = this.dev[key];
      }
    }
    for (const key in this.payload) {
      if (Object.prototype.hasOwnProperty.call(this.payload, key)) {
        params[`payload_${key}`] = this.payload[key];
      }
    }
    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }
    return params;
  }
}
