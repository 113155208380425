import React from 'react';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#FFFFFF'}
      d="M1 17C1 18.6569 2.34315 20 4 20H20C21.6569 20 23 18.6569 23 17V7C23 5.34315 21.6569 4 20 4H4C2.34315 4 1 5.34315 1 7V17ZM4.29286 11.2929C3.90234 11.6834 3.90234 12.3166 4.29286 12.7071L8.29286 16.7071L9.70708 15.2929L7.41418 13H16.5858L14.2929 15.2929L15.7071 16.7071L19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L15.7071 7.29288L14.2929 8.70709L16.5858 11H7.41418L9.70708 8.70709L8.29286 7.29288L4.29286 11.2929Z"
    />
  </svg>
);
