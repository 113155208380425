export const initialState = {
  previousScale: 1,
  scale: 1,
  positionX: 0,
  positionY: 0,
  interaction: {
    eventType: null,
    zoomType: null,
  },
};

export const eventType = {
  drag: 'drag',
  wheel: 'wheel',
  touch: 'touch',
  click: 'click',
  areaZoom: 'area_zoom',
  reset: 'reset',
  doubleClick: 'double_click',
};

export const zoomType = {
  zoomIn: 'zoom_in',
  zoomOut: 'zoom_out',
};

export const deltaAreaZoom = 1;

export const contextInitialState = {
  ...initialState,
  setComponents: () => undefined,
  contextInstance: null,
};

export const initialSetup = {
  disabled: false,
  minPositionX: null,
  maxPositionX: null,
  minPositionY: null,
  maxPositionY: null,
  minScale: 1,
  maxScale: 5,
  limitToBounds: true,
  centerZoomedOut: false,
  centerOnInit: false,
  wheel: {
    step: 0.04,
    disabled: false,
    wheelDisabled: false,
    touchPadDisabled: false,
    activationKeys: [],
    excluded: [],
  },
  panning: {
    disabled: false,
    velocityDisabled: false,
    lockAxisX: false,
    lockAxisY: false,
    activationKeys: [],
    excluded: [],
  },
  pinch: {
    step: 5,
    disabled: false,
    excluded: [],
  },
  doubleClick: {
    disabled: false,
    step: 3,
    mode: 'zoomIn',
    animationType: 'easeOut',
    animationTime: 200,
    excluded: [],
  },
  areaZoom: {
    step: 2.5,
    mode: 'zoomIn',
    animationType: 'easeOut',
    animationTime: 200,
    excluded: [],
  },
  zoomAnimation: {
    disabled: false,
    size: 0.4,
    animationTime: 200,
    animationType: 'easeOut',
  },
  alignmentAnimation: {
    disabled: false,
    sizeX: 100,
    sizeY: 100,
    animationTime: 200,
    velocityAlignmentTime: 400,
    animationType: 'easeOut',
  },
  velocityAnimation: {
    disabled: false,
    sensitivity: 1,
    animationTime: 400,
    animationType: 'easeOut',
    equalToMove: true,
  },
};
