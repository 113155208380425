import React from 'react';

const LoadingIndicatorIcon = ({
  width = 40,
  height = 40,
  fill = '#19CE85',
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.16"
        x="18"
        y="2"
        width="3"
        height="10"
        rx="1.5"
        fill={fill}
      />
      <rect
        opacity="0.24"
        x="26.9512"
        y="3.59448"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(30 26.9512 3.59448)"
        fill={fill}
      />
      <rect
        opacity="0.64"
        x="18"
        y="27"
        width="3"
        height="10"
        rx="1.5"
        fill={fill}
      />
      <rect
        opacity="0.72"
        x="14.4512"
        y="25.2451"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(30 14.4512 25.2451)"
        fill={fill}
      />
      <rect
        opacity="0.4"
        x="37"
        y="18"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(90 37 18)"
        fill={fill}
      />
      <rect
        opacity="0.48"
        x="35.4062"
        y="26.9509"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(120 35.4062 26.9509)"
        fill={fill}
      />
      <rect
        opacity="0.88"
        x="12"
        y="18"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(90 12 18)"
        fill={fill}
      />
      <rect
        x="13.7539"
        y="14.4509"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(120 13.7539 14.4509)"
        fill={fill}
      />
      <rect
        opacity="0.32"
        x="33.9062"
        y="9.45117"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(60 33.9062 9.45117)"
        fill={fill}
      />
      <rect
        opacity="0.8"
        x="12.2559"
        y="21.9512"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(60 12.2559 21.9512)"
        fill={fill}
      />
      <rect
        opacity="0.56"
        x="29.5488"
        y="33.9058"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(150 29.5488 33.9058)"
        fill={fill}
      />
      <rect
        opacity="0.08"
        x="17.0488"
        y="12.2544"
        width="3"
        height="10"
        rx="1.5"
        transform="rotate(150 17.0488 12.2544)"
        fill={fill}
      />
    </svg>
  );
};

export default LoadingIndicatorIcon;
