export const WEB_SITE_TYPES = {
  WEB_SITE: 'WebSite',
} as const;

export const POTENTIAL_ACTION_TYPES = {
  SEARCH_ACTION: 'SearchAction',
} as const;

export const WEB_SITE_NAME = 'Gametime';

export const WEB_SITE_URL = 'https://gametime.co';

export const GAMETIME_URLS = {
  PRODUCTION: 'https://gametime.co',
  QA: 'https://web-qa.gametime.co',
  STAGING: 'https://web-staging.gametime.co',
  TESTING: 'https://web-testing.gametime.co',
};
