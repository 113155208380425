import './GoogleReCaptcha.scss';

const RE_CAPTCHA_BASE_URL = 'https://www.google.com/recaptcha/enterprise.js';
const RE_CAPTCHA_BADGE_CLASSNAME = 'grecaptcha-badge';
const RE_CAPTCHA_SCRIPT_ID = 'google-recaptcha-v3-script';

export const RE_CAPTCHA_ACTIONS = {
  ADD_PAYMENT_METHOD: 'add_payment_method',
};

/**
 * Asynchronous function to inject the Google reCAPTCHA Enterprise script
 * into the document <head> and resolve with a reCAPTCHA Enterprise instance
 * when ready.
 *
 * @param {string} reCaptchaKey
 * @returns Promise resolving to the reCAPTCHA Enterprise instance
 */
export function injectReCaptchaScript(reCaptchaKey) {
  if (!reCaptchaKey) {
    throw new Error('reCaptchaKey must be provided to injectReCaptchaScript');
  }

  if (typeof window === 'undefined') {
    throw new Error(
      'Google reCAPTCHA cannot be configured outside a browser environment'
    );
  }

  const RE_CAPTCHA_SRC = `${RE_CAPTCHA_BASE_URL}?render=${reCaptchaKey}`;

  const script = document.createElement('script');
  script.id = RE_CAPTCHA_SCRIPT_ID;
  script.src = RE_CAPTCHA_SRC;
  script.async = true;
  script.defer = true;

  return new Promise((resolve, reject) => {
    script.onerror = () => {
      reject(
        new Error(`Error loading Google reCAPTCHA from ${RE_CAPTCHA_SRC}`)
      );
    };
    script.onload = () => {
      const reCaptchaInstance = window.grecaptcha?.enterprise;

      if (!reCaptchaInstance) {
        console.error('Google reCAPTCHA enterprise not available');
        return;
      }

      reCaptchaInstance.ready(() => {
        resolve(reCaptchaInstance);
      });
    };

    document.head.append(script);
  });
}

/**
 * Cleanup Google reCAPTCHA script, default badge, and configuration.
 */
export function cleanupReCaptchaScript() {
  if (typeof document !== 'undefined') {
    // remove our injected script to load recaptcha
    const script = document.querySelector(`#${RE_CAPTCHA_SCRIPT_ID}`);
    if (script) {
      script.remove();
    }

    // remove google's injected script that runs recaptcha
    const googleScript = document.querySelector(
      'script[src^="https://www.gstatic.com/recaptcha/releases"]'
    );
    if (googleScript) {
      script.remove();
    }

    // badgeElement.parentNode is a plain div containing the badge element
    // and an iFrame element where the Google reCAPTCHA code runs in the
    // background.
    const badgeElement = document.querySelector(
      `.${RE_CAPTCHA_BADGE_CLASSNAME}`
    );
    if (badgeElement?.parentNode) {
      document.body.removeChild(badgeElement.parentNode);
    }
  }

  if (typeof window !== 'undefined') {
    delete window.___grecaptcha_cfg;
    delete window.grecaptcha;
  }
}
