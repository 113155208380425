import url from 'url';

import React, { Children, Component, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import _isEqual from 'lodash/isEqual';
import _snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

import config from 'config/config';
import { CARD_TYPES } from 'store/datatypes/PAYMENT_TYPES';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import {
  experimentsSelector,
  featureFlagsSelector,
  selectUserDetails,
  selectUserIsBot,
  userActiveExperimentsSelector,
  userActiveFeatureFlagsSelector,
  userActiveZListingsSelector,
  userActiveZSearchSelector,
  zListingsSelector,
  zSearchSelector,
} from 'store/modules/user/user.selectors';
import {
  selectIsApplePayCapable,
  selectIsApplePayEnabled,
} from 'store/modules/userPurchases/userPurchases.selectors';
import { mParticleFireIdentityRequest, mParticleInit } from 'utils/mParticle';
import { getUTMSearchParams } from 'utils/url';

export const TARGET_ID_URL_PARAMETER = 'targetid';

const MPARTICLE_EVENT_TYPES = {
  NAVIGATION: 1,
  LOCATION: 2,
  SEARCH: 3,
  TRANSACTION: 4,
  USER_CONTENT: 5,
  USER_PREFERENCE: 6,
  SOCIAL: 7,
  OTHER: 8,
};

const DEFAULT_PAYMENT_METHODS = {
  NONE: 'none',
  APPLE_PAY: 'apple_pay',
  PAY_WITH_GOOGLE: 'pay_with_google',
  VENMO: 'venmo',
  PAY_WITH_PAYPAL: 'pay_with_paypal',
  CREDIT_CARD_ON_FILE: 'credit_card_on_file',
};

const sessionIdCookieConfig = config.cookiesConfig.WEB_SESSION_ID;

function getDefaultPaymentMethod(default_card_token = '') {
  switch (default_card_token) {
    case '':
      return DEFAULT_PAYMENT_METHODS.NONE;
    case CARD_TYPES.APPLEPAY:
      return DEFAULT_PAYMENT_METHODS.APPLE_PAY;
    case CARD_TYPES.GOOGLEPAY:
      return DEFAULT_PAYMENT_METHODS.PAY_WITH_GOOGLE;
    case CARD_TYPES.VENMO:
      return DEFAULT_PAYMENT_METHODS.VENMO;
    case CARD_TYPES.PAYPAL:
      return DEFAULT_PAYMENT_METHODS.PAY_WITH_PAYPAL;
    default:
      return DEFAULT_PAYMENT_METHODS.CREDIT_CARD_ON_FILE;
  }
}

const _getDefaultProperties = ({
  userAgent,
  ip,
  isInternal,
  webUserId,
  webSessionId,
  acceptedLanguages,
  isMobile,
}) => {
  const parser = new UAParser(userAgent);
  const {
    browser = {},
    os = {},
    device = {},
    engine = {},
  } = parser.getResult();

  const locale = acceptedLanguages.length ? acceptedLanguages[0] : {};
  const vendor = device && device.vendor ? device.vendor : null;
  const model = device && device.model ? device.model : null;

  return {
    ip_address: ip,
    is_mobile: isMobile.toString(),
    device_manufacturer: vendor,
    device_model: model,
    client_os_type: os.name,
    client_os_version: os.version,
    browser_type: browser.name,
    browser_version: browser.version,
    engine_type: engine.name,
    screen_height: window.innerHeight,
    screen_width: window.innerWidth,
    screen_dpi: window.devicePixelRatio,
    locale_country: locale.region,
    locale_language: locale.code,
    timezone_offset: new Date().getTimezoneOffset() / 60,
    http_header_user_agent: userAgent,
    traffic_type: isInternal ? 'internal' : 'external',
    web_device_id: webUserId,
    web_session_id: webSessionId,
  };
};

const _getURLProperties = () => {
  const currentUrl = window.location.href;
  const urlProperties = {};
  const parseQueryParameters = true;
  const currentQueryParameters = url.parse(
    currentUrl,
    parseQueryParameters
  ).query;

  Object.keys(currentQueryParameters).forEach((key) => {
    urlProperties[_snakeCase(key)] = currentQueryParameters[key];
  });

  return urlProperties;
};

export const AnalyticsContext = createContext();

@connect((state) => ({
  appConfig: appConfigSelector(state),
  user: selectUserDetails(state),
  experiments: experimentsSelector(state),
  zListings: zListingsSelector(state),
  isBot: selectUserIsBot(state),
  zSearch: zSearchSelector(state),
  isApplePayCapable: selectIsApplePayCapable(state),
  isApplePayEnabled: selectIsApplePayEnabled(state),
  featureFlags: featureFlagsSelector(state),
}))
export class AnalyticsProvider extends Component {
  constructor(props) {
    super(props);
    this.retryCount = 0;
    this.initialized = false;
    this.queue = [];
    this.urlProperties = {};
    this.UTMSearchParameters = {};
    let defaultProperties = {};

    if (typeof window !== 'undefined') {
      this.urlProperties = _getURLProperties;
      this.UTMSearchParameters = getUTMSearchParams(window.location.search);
      defaultProperties = _getDefaultProperties(this.props.appConfig);
    }

    const activeExperiments = userActiveExperimentsSelector(
      this.props.experiments
    );
    const activeZListings = userActiveZListingsSelector(this.props.zListings);
    const activeZSearch = userActiveZSearchSelector(this.props.zSearch);
    const activeFeatureFlags = userActiveFeatureFlagsSelector(
      this.props.featureFlags
    );

    this.staticProperties = {
      ...defaultProperties,
      ...activeExperiments,
      ...activeZListings,
      ...activeZSearch,
      ...activeFeatureFlags,
    };

    if (Object.keys(this.urlProperties).length) {
      this.staticProperties.url_query = this.urlProperties;
    }

    this.initialize = this.initialize.bind(this);
    this.track = this.track.bind(this);

    if (typeof window !== 'undefined') {
      window.track = this.track;
      mParticleInit(this.initialize);
    }

    this.analytics = {
      track: this.track.bind(this),
    };
  }

  getDynamicProperties() {
    const { user, isBot, isApplePayCapable, isApplePayEnabled } = this.props;

    return {
      id: uuidv4(),
      url: window.location.href,
      is_user_logged_in: user !== null,
      customer_user_id: user?.id ?? null,
      email: user?.email ?? null,
      phone: user?.phone ?? null,
      target_id: this.urlProperties[TARGET_ID_URL_PARAMETER] || null,
      default_payment_method: getDefaultPaymentMethod(user?.default_card_token),
      is_bot: isBot,
      pay_with_apple_enabled: isApplePayEnabled,
      pay_with_apple_capable: isApplePayCapable,
      referrer_url: document.referrer || null,
      ...this.UTMSearchParameters,
    };
  }

  initialize() {
    const {
      user,
      appConfig: { webUserId },
    } = this.props;
    this.initialized = true;

    this.queue.forEach((eventArgs) => {
      this._track(...eventArgs);
    });

    // Set the user's identity in mParticle to be sent to other providers
    mParticleFireIdentityRequest(user, webUserId);
  }

  track(...args) {
    const { appConfig } = this.props;
    const eventArgs = this._buildEvent(...args);

    if (!eventArgs) {
      return;
    }

    const newProps = eventArgs[2];
    delete newProps.id; // removing the unique id, to compare the rest

    if (_isEqual(newProps, this.lastEvent)) {
      // same event is trying to fire twice
      return;
    }

    // Only log events to the console in development environments
    if (__DEVELOPMENT__) {
      console.info('Track Event', eventArgs);
    }

    if (!Cookies.get(sessionIdCookieConfig.name)) {
      const sessionId = uuidv4();

      // numeric maxAge attribute not supported by js-cookie
      const { maxAge, ...cookieAttributes } = sessionIdCookieConfig.attributes;
      if (maxAge) {
        cookieAttributes.expires = new Date(Date.now() + maxAge);
      }

      Cookies.set(sessionIdCookieConfig.name, sessionId, cookieAttributes);

      appConfig.webSessionId = sessionId;
    }

    this.lastEvent = newProps;

    if (!this.initialized) {
      this.queue.push(eventArgs);
      return;
    }

    this._track(...eventArgs);
  }

  /* Private: Fires event to mParticle */
  _track(name, type, properties) {
    if (
      typeof window !== 'undefined' &&
      window.mParticle &&
      window.mParticle.logEvent
    ) {
      try {
        window.mParticle.logEvent(name, type, properties);
      } catch (error) {
        console.error('mParticle logEvent failed:', error);
      }
    }
  }

  _buildEvent(...args) {
    if (!args || !args.length) {
      return null;
    }

    /* Two cases here
     * 1. eventInstance from our event generator class.
     * 2. eventName, Properties
     */
    if (typeof args[0] === 'object') {
      const eventInstance = args[0];

      if (!eventInstance.toParams || !eventInstance.eventName) {
        return null;
      }

      const eventArgs = [];
      const properties = {
        ...this.staticProperties,
        ...this.getDynamicProperties(),
        ...eventInstance.toParams(),
      };

      eventArgs.push(eventInstance.eventName());
      eventArgs.push(MPARTICLE_EVENT_TYPES.OTHER);
      eventArgs.push(properties);

      return eventArgs;
    }
    if (args.length <= 2) {
      const eventArgs = [];
      const properties = {
        ...this.staticProperties,
        ...this.getDynamicProperties(),
        ...args[1],
      };
      eventArgs.push(args[0]);
      eventArgs.push(MPARTICLE_EVENT_TYPES.OTHER);
      eventArgs.push(properties);
      return eventArgs;
    }
  }

  render() {
    return (
      <AnalyticsContext.Provider value={this.analytics}>
        {Children.only(this.props.children)}
      </AnalyticsContext.Provider>
    );
  }
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      'useAnalyticsContext may only be used within AnalyticsProvider'
    );
  }
  return context;
}

export function withAnalyticsContext(Component) {
  const WithAnalyticsContextComponent = (props) => {
    const context = useAnalyticsContext();

    return <Component {...props} analyticsContext={context} />;
  };

  const displayName = Component.displayName || Component.name || 'Component';
  WithAnalyticsContextComponent.displayName = `withAnalyticsContext(${displayName})`;

  return WithAnalyticsContextComponent;
}

AnalyticsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  appConfig: PropTypes.object,
  user: PropTypes.object,
  experiments: PropTypes.object,
  zListings: PropTypes.object,
  featureFlags: PropTypes.object,
  zSearch: PropTypes.object,
  isBot: PropTypes.bool,
  isApplePayCapable: PropTypes.bool,
  isApplePayEnabled: PropTypes.bool,
};
