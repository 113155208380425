import _get from 'lodash/get';
import { schema } from 'normalizr';

import { getEventPath } from 'store/modules/resources/resource.paths';

import { ListingSchema } from './listing.schema';
import { PerformerSchema } from './performer.schema';
import { SCHEMA_ENTITIES } from './schemaEntities';
import { VenueSchema } from './venue.schema';

export const EventSchema = new schema.Entity(
  SCHEMA_ENTITIES.EVENTS,
  {
    venue: VenueSchema,
    performer: PerformerSchema,
    ticket_data: [ListingSchema],
  },
  {
    processStrategy: (input) => {
      const category = _get(input, 'display_data.event_category', 'sport');
      const path = getEventPath(input);
      const max_prices = {
        bid_price: Math.max(
          ...input.ticket_data.map((listing) => listing.bidPr)
        ),
        prefee_price: Math.max(
          ...input.ticket_data.map((listing) => listing.prefee_price)
        ),
      };
      return {
        ...input,
        max_prices,
        category,
        path,
        performerCategory: input.category,
      };
    },
  }
);
