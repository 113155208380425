import { hash } from 'utils/hash';

export function radiusSaleConfirmation({
  category,
  orderId,
  charge,
  tickets,
  isNewUser,
  user,
}) {
  const preFeePrice =
    (charge.total_amount - charge.fees - charge.sales_tax) / 100;
  const preFeePricePerTicket = preFeePrice / tickets.length;

  const items = tickets.map((ticket) => ({
    subTotal: preFeePricePerTicket.toFixed(2),
    category,
    sku: ticket.id,
    quantity: 1,
    name: 'ticket',
  }));

  let customerEmail = user?.email || '';

  if (customerEmail !== '') {
    hash(customerEmail).then((hashedEmail) => {
      customerEmail = hashedEmail;
    });
  }

  const actionTracker = {
    customerId: user?.id || '',
    customerEmail,
    customerStatus: isNewUser ? 'New' : 'Returning',
    currencyCode: 'USD',
    items,
    orderId,
    orderPromoCode: charge.promo_code || '',
  };

  const options = {
    verifySiteDefinitionMatch: true,
  };

  if (typeof window !== 'undefined' && window.ire) {
    window.ire('trackConversion', 19692, actionTracker, options);
  }
}
