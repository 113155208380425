import React from 'react';
import PropTypes from 'prop-types';

import GametimeGuaranteeIcon from 'icons/GametimeGuaranteeIcon';
import GametimeLowFeeIcon from 'icons/GametimeLowFeeIcon';
import GametimeMobileMadeIcon from 'icons/GametimeMobileMadeIcon';
import colors from 'styles/colors.constants';

import styles from './GametimeGuarantee.module.scss';

const GT_GUARANTEE_INFO = [
  {
    icon: <GametimeGuaranteeIcon color={colors.gametimeGreen} />,
    header: 'Gametime Guarantee',
    text: `We've got your back. When you buy on Gametime, your tickets will be on time and authentic - guaranteed.`,
  },
  {
    icon: <GametimeLowFeeIcon color={colors.gametimeGreen} />,
    header: 'Lower Fees, Better Prices',
    text: `We're committed to clear, competitive pricing. If you find a lower price elsewhere, we'll make up 110% of the difference.`,
  },
  {
    icon: <GametimeMobileMadeIcon color={colors.gametimeGreen} />,
    header: 'Made for Mobile',
    text: `Last-minute plans have never been easier. Buy tickets, send them to friends, and get into the venue - all from your phone.`,
  },
];

function GametimeGuaranteePanel({ icon, header, text }) {
  return (
    <div
      key={`GametimeGuaranteePanel_${header}`}
      className={styles['gt-guarantee-row']}
    >
      <div className={styles['gt-guarantee-icon']}>{icon}</div>
      <div className={styles['gt-guarantee-text-container']}>
        <div className={styles['gt-guarantee-header']}>{header}</div>
        <div className={styles['gt-guarantee-text']}>{text}</div>
      </div>
    </div>
  );
}

GametimeGuaranteePanel.propTypes = {
  icon: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function _GametimeGuarantee(props) {
  return (
    <div ref={props.guaranteeRef} className={styles['gt-guarantee-container']}>
      <div className={styles['gt-guarantee-container-header']}>
        <div>Your go-to app for last-minute tickets.</div>
        <div className={styles.subtitle}>
          Get the best deals on tickets to sports, music, and theater events.
        </div>
      </div>
      <div className={styles['gt-guarantee-container-body']}>
        {GT_GUARANTEE_INFO.map(GametimeGuaranteePanel)}
      </div>
    </div>
  );
}

_GametimeGuarantee.propTypes = {
  guaranteeRef: PropTypes.object.isRequired,
};

export default _GametimeGuarantee;
