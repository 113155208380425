import React from 'react';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import { CTA_STATES } from 'utils/survey';

import { SurveySelectionGrid } from './SurveySelectionGrid';

import styles from './PostPurchaseSurveyModal.module.scss';

export const SurveyForm = ({
  title,
  subtitle,
  options,
  selectedOptions,
  onOptionClick,
  textInputProps,
  onTextInput,
  currentInputText,
  onClose,
  onDone,
  isLastStep = false,
}) => {
  const onTextChange = (e) => {
    onTextInput(e.target.value);
  };

  const hasOptions = options.length !== 0;

  return (
    <>
      <div className={styles['survey-info']}>
        {title && <h2 className={styles['survey-title']}>{title}</h2>}
        {subtitle && (
          <span className={styles['survey-subtitle']}>{subtitle}</span>
        )}
      </div>
      {hasOptions && (
        <SurveySelectionGrid
          options={options}
          selectedOptions={selectedOptions}
          onOptionClick={onOptionClick}
        />
      )}
      {textInputProps && (
        <TextInput
          id={title}
          label=""
          placeholder={textInputProps.placeholder}
          maxLength={textInputProps.maxLength}
          light
          info={{
            hasInfo: true,
            infoMessage: `${currentInputText.length}/${textInputProps.maxLength}`,
          }}
          onChange={onTextChange}
          value={currentInputText}
        />
      )}

      <div className={styles['survey-buttons']}>
        <ButtonLoader onClick={onClose} className={styles['close-button']}>
          <span className={styles.text}>close</span>
        </ButtonLoader>
        <ButtonLoader onClick={onDone} className={styles['action-button']}>
          <span className={styles.text}>
            {isLastStep ? CTA_STATES.DONE : CTA_STATES.NEXT}
          </span>
        </ButtonLoader>
      </div>
    </>
  );
};

SurveyForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  textInputProps: PropTypes.object,
  onTextInput: PropTypes.func.isRequired,
  currentInputText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool,
};
