import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { OrderConfirmationClickTracker, PAYLOAD } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import TinyTicketIcon from 'icons/TinyTicketIcon';
import FullEvent from 'models/FullEvent';
import { ACTIVE_RESALE_STATES } from 'pages/MyTickets/resale.constants';

import styles from './PurchaseCard.module.scss';

const PurchaseCard = ({ fullEvent, completePurchase, isPastEvent = false }) => {
  const imageSrc = fullEvent.getPrimaryPerformer().heroImageUrl;
  const orderLink = `/order/${completePurchase.id}`;
  const title = fullEvent.getName('@');
  const subTitle = <BulletList listItems={fullEvent.getDateTime()} />;
  const shortTitle = fullEvent.getShortName('@');
  const venueName = fullEvent.venueName;
  const ownedTicketCount = completePurchase.tickets.filter(
    (ticket) => !ACTIVE_RESALE_STATES.includes(ticket.resale_info.status)
  ).length;
  const ticketsLabel =
    ownedTicketCount === 1 ? '1 ticket' : `${ownedTicketCount} tickets`;
  const eventPayload = isPastEvent ? 'past events' : 'upcoming';
  const clickTracker = new OrderConfirmationClickTracker(
    completePurchase
  ).payload({
    [PAYLOAD.SECTION]: eventPayload,
  });

  return (
    <div className={styles['purchase-card-container']}>
      <Link to={orderLink} clickTracker={clickTracker}>
        <div
          className={classNames(styles['purchase-card'], {
            [styles.past]: isPastEvent,
          })}
        >
          <div className={styles['image-container']}>
            <Image src={imageSrc} />
          </div>
          <div className={styles['info-container']}>
            <div className={styles['title-container']}>
              <span className={styles.venue}>{venueName}</span>
              <h4 className={styles.title}>{title}</h4>
              <h4 className={styles['short-title']}>{shortTitle}</h4>
              <h5 className={styles.subtitle}>{subTitle}</h5>
            </div>
            <div className={styles['tickets-label']}>
              <TinyTicketIcon />
              <span className={styles['tickets-text']}>{ticketsLabel}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

PurchaseCard.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  completePurchase: PropTypes.object.isRequired,
  isPastEvent: PropTypes.bool,
};

export default PurchaseCard;
