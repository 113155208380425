import React from 'react';
import colors from 'styles/colors.constants';

export default (props) => {
  return (
    <svg
      width={props.width || '40'}
      height={props.height || '40'}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4998 2.66666C7.24074 2.66666 2.1665 7.7409 2.1665 14C2.1665 20.2591 7.24074 25.3333 13.4998 25.3333C19.7589 25.3333 24.8332 20.2591 24.8332 14C24.8332 7.7409 19.7589 2.66666 13.4998 2.66666ZM13.4998 0.666656C6.13617 0.666656 0.166504 6.63633 0.166504 14C0.166504 21.3637 6.13617 27.3333 13.4998 27.3333C20.8635 27.3333 26.8332 21.3637 26.8332 14C26.8332 6.63633 20.8635 0.666656 13.4998 0.666656ZM11.1665 15.7811L17.8701 9.07742C18.0654 8.88216 18.382 8.88216 18.5772 9.07742L19.7557 10.2559C19.951 10.4512 19.951 10.7678 19.7557 10.963L12.1093 18.6095C11.5886 19.1302 10.7444 19.1302 10.2237 18.6095L6.57723 14.963C6.38196 14.7678 6.38196 14.4512 6.57723 14.2559L7.75574 13.0774C7.951 12.8822 8.26758 12.8822 8.46284 13.0774L11.1665 15.7811Z"
        fill={props.fill || colors.gametimeGreen}
      />
    </svg>
  );
};
