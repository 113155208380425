import React from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/colors.constants';

const ResetIcon = ({ width = '16', height = '16', color = colors.gray500 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 16C5.76667 16 3.875 15.225 2.325 13.675C0.775 12.125 0 10.2333 0 8C0 5.76667 0.775 3.875 2.325 2.325C3.875 0.775 5.76667 0 8 0C9.15 0 10.25 0.237666 11.3 0.713C12.35 1.18767 13.25 1.86667 14 2.75V0.5C14 0.223858 14.2239 0 14.5 0H15.5C15.7761 0 16 0.223858 16 0.5V6.5C16 6.77614 15.7761 7 15.5 7H9.5C9.22386 7 9 6.77614 9 6.5V5.5C9 5.22386 9.22386 5 9.5 5H13.2C12.6667 4.06667 11.9377 3.33333 11.013 2.8C10.0877 2.26667 9.08333 2 8 2C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8C2 9.66667 2.58333 11.0833 3.75 12.25C4.91667 13.4167 6.33333 14 8 14C9.28333 14 10.4417 13.6333 11.475 12.9C12.5083 12.1667 13.2333 11.2 13.65 10H15.75C15.2833 11.7667 14.3333 13.2083 12.9 14.325C11.4667 15.4417 9.83333 16 8 16Z" />
    </svg>
  );
};

ResetIcon.propTypes = {
  width: PropTypes.oneOf(['16', '24']),
  height: PropTypes.oneOf(['16', '24']),
  color: PropTypes.string,
};

export default ResetIcon;
