import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import GTLogin from 'components/Login/GTLogin';
import CloseLineIcon from 'icons/CloseLineIcon';

import styles from './LoginModal.module.scss';

export default class LoginModal extends Component {
  static propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool.isRequired,
  };

  render() {
    const { onHide, show } = this.props;
    return (
      <GTUniversalModal show={show} onHide={onHide}>
        <div className={styles.modal}>
          <GTLogin onLoginSuccess={onHide} />
          <button type="button" className={styles.close} onClick={onHide}>
            <CloseLineIcon />
          </button>
        </div>
      </GTUniversalModal>
    );
  }
}
