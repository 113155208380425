import { create as braintreeClientCreate } from 'braintree-web/client';
import { create as paypalCreate } from 'braintree-web/paypal-checkout';

import config from 'config/config';

const PayPal = (ref, total, onClick) => {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject(new Error('No window object found'));
    }

    braintreeClientCreate(
      {
        authorization: config.BRAINTREE_CLIENT_TOKEN,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          reject(new Error('client creation failed', { cause: clientErr }));
        }

        paypalCreate(
          {
            client: clientInstance,
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            if (paypalCheckoutErr) {
              reject(
                new Error('PayPalInstance error', { cause: paypalCheckoutErr })
              );
            }
            paypalCheckoutInstance.loadPayPalSDK(
              {
                currency: 'USD',
                intent: 'authorize',
              },
              () => {
                if (!window.paypal) {
                  reject(new Error('PayPal SDK initialized incorrectly.'));
                }

                window.paypal
                  .Buttons({
                    style: {
                      shape: 'pill',
                      color: 'blue',
                      layout: 'vertical',
                      height: 48,
                      size: 'responsive',
                      label: 'pay',
                    },
                    fundingSource: window.paypal.FUNDING.PAYPAL,

                    createOrder() {
                      return paypalCheckoutInstance.createPayment({
                        flow: 'checkout', // Required
                        amount: total, // Required
                        currency: 'USD', // Required, must match the currency passed in with loadPayPalSDK
                        intent: 'authorize', // Must match the intent passed in with loadPayPalSDK
                        enableShippingAddress: false,
                        requestBillingAgreement: true,
                        shippingAddressEditable: false,
                      });
                    },

                    onApprove(data) {
                      return paypalCheckoutInstance.tokenizePayment(
                        data,
                        (err, payload) => {
                          if (err) {
                            reject(
                              new Error('tokenize payment error ', {
                                cause: err,
                              })
                            );
                          }
                          resolve(payload);
                        }
                      );
                    },

                    onClick() {
                      onClick();
                    },

                    onCancel() {
                      console.info('PayPal payment cancelled');
                    },

                    onError(err) {
                      console.error('PayPal error: ', err);
                    },
                  })
                  .render(ref.current);
              }
            );
          }
        );
      }
    );
  });
};

export default PayPal;
