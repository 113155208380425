import config from 'config/config';
import { ENV } from 'config/environments';

export const mParticleInit = (callback) =>
  ((apiKey) => {
    window.mParticle = window.mParticle || {};
    window.mParticle.config = window.mParticle.config || {
      isDevelopmentMode: config.env !== ENV.PRODUCTION,
      useNativeSdk: false,
    };
    window.mParticle.config.rq = [];
    window.mParticle.Identity = {};
    window.mParticle.config.snippetVersion = 2.2;
    window.mParticle.ready = (f) => {
      window.mParticle.config.rq.push(f);
    };

    window.mParticle.ready(callback);

    const mp = document.createElement('script');
    mp.type = 'text/javascript';
    mp.async = true;
    mp.src = `${
      document.location.protocol === 'https:'
        ? 'https://jssdkcdns'
        : 'http://jssdkcdn'
    }.mparticle.com/js/v2/${apiKey}/mparticle.js`;

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(mp, s);
  })(config.MPARTICLE_API_KEY);

export const mParticleIdentityCallback = (result, mParticleIdentityRetry) => {
  if (result.getUser()) {
    // SetIdentity Call Successful
  } else {
    const codes = window.mParticle.Identity.HTTPCodes;
    switch (result.httpCode) {
      case codes.noHttpCoverage:
        // retry the IDSync request

        mParticleIdentityRetry();

        break;
      case codes.activeIdentityRequest:
      case 429:
        // inspect your implementation if this occurs frequency
        // otherwise retry the IDSync request
        mParticleIdentityRetry();
        break;
      case codes.validationIssue:
      case 400:
        // inspect result.body to determine why the request failed
        // this typically means an implementation issue
        break;
      default:
      // Handle if needed later
    }
  }
};

export const mParticleFireIdentityRequest = (
  user,
  webUserId,
  retryCount = 0
) => {
  const identityRequest = {
    userIdentities: {
      email: user?.email ?? null,
      customerid: user?.id ?? webUserId ?? null,
      other: user?.web_id ?? webUserId ?? null,
    },
  };

  const identityCallback = (result) => {
    if (retryCount < 3) {
      retryCount += 1;
      mParticleIdentityCallback(result, () => {
        mParticleFireIdentityRequest(user, webUserId, retryCount);
      });
    }
  };

  if (typeof window !== 'undefined') {
    try {
      window.mParticle?.Identity?.login?.(identityRequest, identityCallback);
    } catch (err) {
      console.error(err);
    }
  }
};
