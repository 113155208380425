import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function CheckCircleFilledIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-circle-filled-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92497 1 1 5.92498 1 12C1 18.075 5.92497 23 12 23C18.075 23 23 18.075 23 12C23 5.92498 18.075 1 12 1ZM15.1893 8.39646L10.25 13.3358L8.31061 11.3965C8.11535 11.2012 7.79877 11.2012 7.60351 11.3965L6.8964 12.1036C6.70114 12.2988 6.70114 12.6154 6.8964 12.8107L9.54285 15.4571C9.93337 15.8476 10.5665 15.8476 10.9571 15.4571L16.6035 9.81067C16.7988 9.61541 16.7988 9.29883 16.6035 9.10357L15.8964 8.39646C15.7011 8.2012 15.3846 8.2012 15.1893 8.39646Z"
        fill={fill}
      />
    </svg>
  );
}
