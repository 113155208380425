import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function LogOutIcon({
  width = 24,
  height = 24,
  fill = '#ffffff',
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3768 7.37684C16.1727 7.17272 15.8386 7.18337 15.6479 7.40008L14.9893 8.14855C14.8153 8.34628 14.8245 8.64508 15.0104 8.8317L17.17 11H9.5C9.22386 11 9 11.2239 9 11.5V12.5C9 12.7761 9.22386 13 9.5 13H17.17L15.0106 15.1594C14.8245 15.3455 14.8145 15.6439 14.9877 15.842L15.6481 16.5974C15.8385 16.8153 16.1735 16.8265 16.3781 16.6219L20.2929 12.7071C20.6834 12.3166 20.6834 11.6834 20.2929 11.2929L16.3768 7.37684ZM5 5H11.5C11.7761 5 12 4.77614 12 4.5V3.5C12 3.22386 11.7761 3 11.5 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11.5C11.7761 21 12 20.7761 12 20.5V19.5C12 19.2239 11.7761 19 11.5 19H5V5Z"
        fill={fill}
      />
    </svg>
  );
}
