import React from 'react';
import colors from 'styles/colors.constants';

export default function CircleExclamationFillIcon({
  width = '24',
  height = '24',
  fill = colors.black,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47725 2 2 6.47726 2 12C2 17.5227 6.47725 22 12 22C17.5228 22 22 17.5227 22 12C22 6.47726 17.5228 2 12 2ZM11.0709 13.6996L10.8906 6.98789H13.1094L12.9222 13.6996H11.0709ZM13.2619 16.0432C13.2619 16.7573 12.7835 17.2011 12 17.2011C11.2234 17.2011 10.7381 16.7573 10.7381 16.0432C10.7381 15.3221 11.2234 14.8783 12 14.8783C12.7835 14.8783 13.2619 15.3221 13.2619 16.0432Z"
        fill={fill}
      />
    </svg>
  );
}
