import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './GTInfoModal.module.scss';

const GTInfoModal = (props) => {
  const {
    icon,
    title,
    children,
    show,
    onHide,
    buttonText = 'CLOSE',
    buttonClassName,
    styleName,
    ...otherProps
  } = props;

  return (
    <Modal
      className={classNames(styles['gt-info-modal'], styleName)}
      overlayClassName={styles['gt-info-modal-overlay']}
      isOpen={show}
      onRequestClose={onHide}
      ariaHideApp={false}
      {...otherProps}
    >
      <div className={styles['modal-icon']}>{icon}</div>
      {title && <h4 className={styles['modal-title']}>{title}</h4>}
      {children}
      <div className={styles['modal-buttons']}>
        <ButtonLoader
          onClick={onHide}
          className={classNames(buttonClassName, styles['action-button'])}
        >
          {buttonText}
        </ButtonLoader>
      </div>
    </Modal>
  );
};

GTInfoModal.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  buttonClassName: PropTypes.string,
  styleName: PropTypes.string,
};

export default GTInfoModal;
