import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function SearchIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 24 24"
      data-testid="search-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10C15 12.7614 12.7615 15 10 15C7.23853 15 5 12.7614 5 10C5 7.23859 7.23853 5 10 5C12.7615 5 15 7.23859 15 10ZM14.1921 15.6064C13.0236 16.4816 11.5723 17 10 17C6.13403 17 3 13.866 3 10C3 6.13403 6.13403 3 10 3C13.866 3 17 6.13403 17 10C17 11.5723 16.4816 13.0236 15.6063 14.1922L20.3535 18.9393C20.5488 19.1346 20.5488 19.4512 20.3535 19.6464L19.6464 20.3535C19.4512 20.5488 19.1345 20.5488 18.9393 20.3535L14.1921 15.6064Z"
      />
    </svg>
  );
}
