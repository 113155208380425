import React from 'react';
import colors from 'styles/colors.constants';

export default ({width = "24", height = "24", viewBox = "0 0 24 24", style = {}, fill = '#FFFFFF'}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.5859 11L11.6467 6.06081C11.4514 5.86555 11.4514 5.54897 11.6467 5.35371L12.3538 4.6466C12.5491 4.45134 12.8656 4.45134 13.0609 4.6466L19.7074 11.293C20.0979 11.6836 20.0979 12.3167 19.7074 12.7073L13.0609 19.3537C12.8656 19.549 12.5491 19.549 12.3538 19.3537L11.6467 18.6466C11.4514 18.4513 11.4514 18.1348 11.6467 17.9395L16.5862 13L4.49979 13C4.22365 13 3.99979 12.7762 3.99979 12.5V11.5C3.99979 11.2239 4.22365 11 4.49979 11L16.5859 11Z"
        fill={fill}
      />
    </svg>
  );
};
