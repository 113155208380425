import React from 'react';
import IconButton from 'ui/IconButton';

import { CloseLineIcon } from 'icons';
import colors from 'styles/colors.constants';

import style from './Banner.module.scss';

interface Props {
  message: string | React.ReactElement;
  onClose: VoidFunction;
}

export default function Banner({ message, onClose }: Props) {
  return (
    <div className={style['banner']}>
      <span className={style['message']}>{message}</span>
      <IconButton
        className={style['close-button']}
        onClick={onClose}
        icon={
          <CloseLineIcon fill={colors.gray243} width="100%" height="100%" />
        }
      />
    </div>
  );
}
