const htmlEntities = [
  { html: '&amp;', text: '&' },
  { html: '&lt;', text: '<' },
  { html: '&gt;', text: '>' },
  { html: '&quot;', text: '"' },
  { html: '&apos;', text: "'" },
  { html: '&nbsp;', text: ' ' },
  { html: '&copy;', text: '©' },
  { html: '&reg;', text: '®' },
  { html: '&rsquo;', text: '’' },
  { html: '&ndash;', text: '–' },
];

export const normalizedHtmlText = (htmlString: string = ''): string => {
  if (typeof htmlString !== 'string') {
    return '';
  }

  let text = htmlString.replace(/<\/?[^>]+(>|$)/g, '');
  for (const entity of htmlEntities) {
    text = text.replace(new RegExp(entity.html, 'g'), entity.text);
  }
  return text;
};

export function removeLineBreaks(text: string = ''): string {
  if (typeof text !== 'string') {
    return '';
  }
  return text.replace(/\n|\r/g, '');
}
