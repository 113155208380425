import colors from 'styles/colors.constants';

export const hexToRGB = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getContrastColor = (primaryHex) => {
  const rgb = hexToRGB(primaryHex);

  if (!rgb) {
    return colors.baseLight;
  }

  rgb.r /= 255;
  rgb.g /= 255;
  rgb.b /= 255;

  return rgb.r * 0.299 + rgb.g * 0.787 + rgb.b * 0.114 > 0.72
    ? colors.base
    : colors.baseLight;
};
