import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '../Chip';

import styles from './FilterChips.module.scss';

const FilterChips = ({
  list = [],
  activeId = null,
  onClick,
  testId = '',
  disabled,
}) => {
  if (!list.length) return null;

  return (
    <div data-testid={testId} className={styles.container}>
      {list.map((item) => (
        <Chip
          key={item.id}
          alignCenter
          disabled={disabled}
          label={item.label}
          color={activeId === item.id ? 'secondary' : 'primary'}
          onClick={() => onClick(item)}
        />
      ))}
    </div>
  );
};

FilterChips.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  activeId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default FilterChips;
