import { ABAssignmentSelector } from 'store/modules/user/user.selectors';

import { experiments } from './experiments';

const {
  INSURANCE_WEB_PILOT,
  PERCENTAGE_VS_DOLLARS,
  PERFORMER_INLINE_PRICING_MWEB,
  NEW_MLB_INFO_COLLECTION,
  WEB_EXCLUSIVES_V1,
  APP_REVIEWS,
  NO_NFL_AUTOZOOM_DESKTOP,
  BILLING_WEB_MVP,
  WEB_DETAILS_STACKED,
  MLB_TEAM_LOGOS_PERFORMER,
  WEB_EXCLUSIVES_V3,
  HOMEPAGE_REDESIGN_V1,
  GALLERY_VIEW_V3,
} = experiments;

export const selectIsInsuranceWebPilotExperiment = (state) =>
  ABAssignmentSelector(state, INSURANCE_WEB_PILOT.name) ===
  INSURANCE_WEB_PILOT.variant;

export const selectIsPercentageVsDollarsExperiment = (state) =>
  ABAssignmentSelector(state, PERCENTAGE_VS_DOLLARS.name) ===
  PERCENTAGE_VS_DOLLARS.variant;

/**
 * Used in OmnibarControls and ListingCard
 */
export const selectIsPerformerInlinePricingMobileExperiment = (state) => {
  return (
    ABAssignmentSelector(state, PERFORMER_INLINE_PRICING_MWEB.name) ===
    PERFORMER_INLINE_PRICING_MWEB.variant
  );
};

export const selectIsNewMLBInfoCollectionExperiment = (state) => {
  return (
    ABAssignmentSelector(state, NEW_MLB_INFO_COLLECTION.name) ===
    NEW_MLB_INFO_COLLECTION.variant
  );
};

export const selectIsWebExclusivesV1Experiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_EXCLUSIVES_V1.name) ===
    WEB_EXCLUSIVES_V1.variant
  );
};

export const selectIsAppReviewsExperiment = (state) => {
  return ABAssignmentSelector(state, APP_REVIEWS.name) === APP_REVIEWS.variant;
};

export const selectIsNoNFLAutozoomDesktopExperiment = (state) => {
  return (
    ABAssignmentSelector(state, NO_NFL_AUTOZOOM_DESKTOP.name) ===
    NO_NFL_AUTOZOOM_DESKTOP.variant
  );
};

export const selectIsBillingWebMVPExperiment = (state) => {
  return (
    ABAssignmentSelector(state, BILLING_WEB_MVP.name) ===
    BILLING_WEB_MVP.variant
  );
};

export const selectIsWebDetailsStackedExperiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_DETAILS_STACKED.name) ===
    WEB_DETAILS_STACKED.variant
  );
};

export const selectIsMLBTeamLogosPerformerExperiment = (state) => {
  return (
    ABAssignmentSelector(state, MLB_TEAM_LOGOS_PERFORMER.name) ===
    MLB_TEAM_LOGOS_PERFORMER.variant
  );
};

export const selectIsWebExclusivesV3Experiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_EXCLUSIVES_V3.name) ===
    WEB_EXCLUSIVES_V3.variant
  );
};

export const selectIsHomepageRedesignV1Experiment = (state) => {
  return (
    ABAssignmentSelector(state, HOMEPAGE_REDESIGN_V1.name) ===
    HOMEPAGE_REDESIGN_V1.variant
  );
};

export const selectIsGalleryViewV3Experiment = (state) => {
  return (
    ABAssignmentSelector(state, GALLERY_VIEW_V3.name) ===
    GALLERY_VIEW_V3.variant
  );
};
