import React from 'react';
import PropTypes from 'prop-types';
import ExternalIcon from 'ui/ExternalIcon/ExternalIcon';

import 'external-svg-loader';

import styles from './FeaturedListingsTag.module.scss';

/**
 * @deprecated use ui/Tag instead
 */
const FeaturedListingsTag = ({ title = '', color = '', icon = '' }) => {
  return (
    <div
      className={styles['featured-listings-tag']}
      style={{ borderColor: color }}
    >
      <ExternalIcon src={icon} color={color} title="featured listing tag" />
      <div className={styles['featured-listings-text']}>{title}</div>
    </div>
  );
};

FeaturedListingsTag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
};

export default FeaturedListingsTag;
