import { Venue } from 'models';

import { EventType } from '../event/types';

import {
  ADDRESS_COUNTRIES,
  ADDRESS_TYPES,
  EVENT_TYPE_TO_LOCATION_TYPE_MAP,
  LOCATION_TYPES,
} from './constants';
import { Location, LocationType } from './types';

interface LocationSchemaProps {
  venue: Venue;
  eventType?: EventType;
  locationType?: LocationType;
}

export function getLocationSchema({
  venue,
  eventType,
  locationType = LOCATION_TYPES.PLACE,
}: LocationSchemaProps): Location {
  const type: LocationType = eventType
    ? EVENT_TYPE_TO_LOCATION_TYPE_MAP[eventType]
    : locationType;

  return {
    '@type': type,
    name: venue.name,
    address: {
      '@type': ADDRESS_TYPES.POSTAL_ADDRESS,
      addressLocality: venue.city,
      addressRegion: venue.state,
      addressCountry: ADDRESS_COUNTRIES.US,
    },
    geo: {
      '@type': ADDRESS_TYPES.GEO_COORDINATES,
      longitude: venue.location.lon,
      latitude: venue.location.lat,
    },
  };
}

export default {
  getLocationSchema,
};
