import React from 'react';
import classNames from 'classnames';

import { CaretDownIcon, WarningCircleFillIcon } from 'icons';
import colors from 'styles/colors.constants';

import { PickerOptions } from './constants';

import styles from './PickerInput.module.scss';

type SelectElementProps = React.SelectHTMLAttributes<HTMLSelectElement>;
type PickerInputError = { hasError: boolean; message: string };

interface PickerInputProps extends SelectElementProps {
  /**
   * Input element ID, also used for attributing label element, hints, and
   * errors to the input element
   */
  id: string;
  /**
   * The label text
   */
  label: string | React.ReactNode;
  /**
   * Whether or not the field is showing an error and the error message
   */
  error?: PickerInputError;
  /**
   * The options to display in the picker
   */
  options: PickerOptions;
  /**
   * The value of the picker
   */
  value: string;
}

const PickerInput: React.FC<PickerInputProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  onBlur,
  disabled,
  error = { hasError: false, message: '' },
  ...rest
}: PickerInputProps) => {
  const { hasError, message } = error;
  const errorId = `${id}-error`; // used for a11y to associate input with error message

  return (
    <div className={styles['picker-input']}>
      <div className={styles['label-container']}>
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      </div>
      <div
        className={classNames(styles['input-container'], {
          [styles.error]: hasError,
        })}
      >
        <select
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          className={styles.picker}
          aria-invalid={hasError}
          aria-describedby={hasError ? errorId : undefined}
          {...rest}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={styles['picker-arrow']}>
          <CaretDownIcon fill={colors.gray300} />
        </div>
      </div>
      <div
        className={classNames(styles['error-container'], {
          [styles.hidden]: !hasError,
        })}
      >
        <WarningCircleFillIcon fill={colors.softRed} width="16" height="16" />
        <span id={errorId} className={styles.error} aria-live="polite">
          {message}
        </span>
      </div>
    </div>
  );
};

export default PickerInput;
