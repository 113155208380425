import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import _merge from 'lodash/merge';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  useAnalyticsContext,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import FilterDropdown from 'components/FilterControl/FilterDropdown';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import Spinner from 'components/Spinner';
import TopArrowsCarousel from 'components/TopArrowsCarousel/TopArrowsCarousel';
import { device, useMediaQuery, useOnClickOutside } from 'hooks';
import { CaretDownIcon, LocationFillIcon } from 'icons';
import { Collection as CollectionModel, FullEvent } from 'models';
import { updateCurrentLocation as updateCurrentLocationDispatch } from 'store/modules/app/app';
import { updateUserPreference as updateUserPreferenceDispatch } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import MetroPickerCollectionCard from './MetroPickerCollectionCard';

import styles from './MetroPickerCollection.module.scss';

interface MetroPickerCollectionProps {
  collection?: CollectionModel | null;
  collectionTitle?: string;
  currentMetro: Metro;
  closestMetro: Metro;
  metros: Metro[];
  isLoading: boolean;
  updateCurrentLocation: (metro: string) => void;
  updateUserPreference: (preferences: { lastVisitedMetro: string }) => void;
  showPriceInImage?: boolean;
  handleMetroChange: (metro: string) => void;
}

const MetroPickerCollection = ({
  collection,
  collectionTitle = 'Events Near',
  currentMetro,
  closestMetro,
  metros,
  isLoading,
  updateCurrentLocation,
  updateUserPreference,
  showPriceInImage = true,
  handleMetroChange,
}: MetroPickerCollectionProps) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [isMetroModalOpen, setIsMetroModalOpen] = useState(false);
  const isMobile = useMediaQuery(device.down.md);
  const closeModal = () => setIsMetroModalOpen(false);
  const openModal = () => {
    const tracker = new ClickTracker().interaction(
      Click.INTERACTIONS.CHANGE_LOCATION()
    );
    analytics.track(
      new Click(_merge({}, clickContext, tracker.json()) as Click)
    );
    setIsMetroModalOpen(true);
  };

  const handleMetroSelect = (selectedMetro: Metro) => {
    handleMetroChange(selectedMetro.id);
    updateCurrentLocation(selectedMetro.id);
    updateUserPreference({ lastVisitedMetro: selectedMetro.id });
  };

  const metroFilterOptions = [
    {
      name: 'Use My Location',
      onClick: () => handleMetroSelect(closestMetro),
      icon: <LocationFillIcon fill={colors.gametimeGreen} />,
    },
    ...metros.map((metro) => {
      return {
        name: metro.name,
        onClick: () => {
          handleMetroSelect(metro);
        },
      };
    }),
  ];

  const metroFilterModal = isMobile ? (
    <MetroSelectorModal
      show={isMetroModalOpen}
      onHide={() => {
        setIsMetroModalOpen(false);
      }}
    />
  ) : (
    <FilterDropdown show={isMetroModalOpen} options={metroFilterOptions} />
  );

  const handleMetroFilterClickOutside = () => {
    if (!isMobile) {
      setIsMetroModalOpen(false);
    }
  };

  const renderHeader = () => {
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, handleMetroFilterClickOutside);

    return (
      <div className={styles['picker-header']}>
        <span ref={ref} className={styles['metro-picker']}>
          {collectionTitle}
          <button
            className={styles['inline-metro-picker']}
            onClick={() => (isMetroModalOpen ? closeModal() : openModal())}
            aria-expanded={isMetroModalOpen}
            aria-haspopup="true"
            aria-label="Select metro area"
          >
            <div className={styles['metro-icon-container']}>
              <span className={styles['inline-metro']}>
                {currentMetro.name}
              </span>
              <CaretDownIcon
                width="24"
                height="24"
                fill={colors.gametimeGreenLight}
              />
            </div>
            {metroFilterModal}
          </button>
        </span>
      </div>
    );
  };

  const renderItem = (item: FullEvent, index: number) => {
    const clickTracker = new FullEventClickTracker(item).interaction(
      Click.INTERACTIONS.TILE(),
      {
        section_index: 0,
        item_index: index,
        collection: collectionTitle,
        get_in_price: item.getPrice(),
      }
    );

    return (
      <MetroPickerCollectionCard
        key={item.id}
        source={item}
        clickTracker={clickTracker}
        lazyLoad={false}
        showPriceInImage={showPriceInImage}
      />
    );
  };

  const onTopForwardClick = () => {
    const topForwardTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.FORWARD_ARROW())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.GAMETIME_PICKS())
      .payload({
        collection: 'metro_picker_collection',
      });

    analytics.track(new Click(topForwardTracker.json()));
  };

  const onTopBackClick = () => {
    const topBackTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.BACK_ARROW())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.GAMETIME_PICKS())
      .payload({
        collection: 'metro_picker_collection',
      });

    analytics.track(new Click(topBackTracker.json()));
  };

  return (
    <div className={styles['metro-picker-collection-container']}>
      <div className={styles['metro-picker-collection']}>
        <TopArrowsCarousel
          header={renderHeader()}
          items={collection?.eventsList}
          renderItem={renderItem}
          onTopForwardClick={onTopForwardClick}
          onTopBackClick={onTopBackClick}
          name="metro-picker-carousel"
          className={styles['metro-picker-carousel']}
        />
        {isLoading && (
          <div className={styles['metro-picker-spinner']}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateCurrentLocation: updateCurrentLocationDispatch,
  updateUserPreference: updateUserPreferenceDispatch,
};

export default connect<MetroPickerCollectionProps>(
  null,
  mapDispatchToProps
)(MetroPickerCollection);
