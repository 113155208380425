import FullEvent from './FullEvent';
import Performer from './Performer';
import PerformerStats from './PerformerStats';

export default class PerformerInContext {
  constructor(performerInContext = {}) {
    this.performer = new Performer(performerInContext.performer);
    this.nextEventsList = performerInContext.next_events.map(
      (data) => new FullEvent(data)
    );
    this.stats = new PerformerStats(performerInContext.stats);

    /* Custom Properties */
    this.nextFullEvent = this.nextEventsList[0];
    this.secondFullEvent = this.nextEventsList[1];
  }

  hasEventsNearbyLatLong(latLong) {
    return this.nextEventsList.some((fullEvent) =>
      fullEvent.isNearbyLatLong(latLong)
    );
  }

  id() {
    return this.performer.id;
  }

  name() {
    return this.performer.name;
  }

  shortName() {
    return this.performer.shortName;
  }

  heroImageUrl() {
    return this.performer.heroImageUrl;
  }

  getPath(toListingPage = false) {
    return toListingPage
      ? this.nextFullEvent.getPath()
      : this.performer.getPath();
  }

  getAltText() {
    const fullEvent =
      this.nextEventsList &&
      this.nextEventsList.length > 0 &&
      this.nextEventsList[0];
    return fullEvent
      ? fullEvent.getAltText()
      : `${this.name()} will be playing.`;
  }
}
