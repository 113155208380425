import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Click, useAnalyticsContext } from 'analytics';
import {
  dealsData,
  dealTypes,
  getUnlockedZoneDealInfo,
  updateDealInfo,
} from 'components/Deals/helpers';
import InfoSmallIcon from 'icons/InfoSmallIcon';
import { FullEvent, Listing } from 'models';
import {
  MODALS,
  showModal as showModalDispatch,
} from 'store/modules/modals/modals';
import colors from 'styles/colors.constants';

import styles from './DealsTag.module.scss';

const DealsTag = ({
  listing,
  fullEvent,
  showModal,
  isListingPage = false,
  dealType = dealTypes.zoneDeal,
  showIconOnly = false,
  showInfoTagOnly = false,
}) => {
  const analytics = useAnalyticsContext();

  const onClick = useCallback(() => {
    showModal(dealsData[dealType].modal, {
      listing,
      fullEvent,
    });

    if (isListingPage) {
      analytics.track(
        new Click({
          interaction: dealsData[dealType].interaction,
          sourcePageType: Click.SOURCE_PAGE_TYPES.LISTING(),
        })
      );
    }
  }, [isListingPage, showModal, dealType, listing, fullEvent, analytics]);

  const activeZoneDeal = getUnlockedZoneDealInfo(fullEvent.id);

  useEffect(() => {
    if (activeZoneDeal && activeZoneDeal.showInfoModal && isListingPage) {
      // show zone deal info modal when user first access the zone deal listing
      updateDealInfo(fullEvent.id, { showInfoModal: false });

      if (dealType === dealTypes.zoneDeal) {
        showModal(MODALS.ZONE_DEAL_INFO, {
          listing,
          fullEvent,
        });
      }
    }
  }, [activeZoneDeal, fullEvent, isListingPage, listing, showModal, dealType]);

  if (showIconOnly) {
    return (
      <div
        className={classNames(styles.tag, styles['show-icon-only'])}
        style={{ background: dealsData[dealType].color }}
      >
        {dealsData[dealType].tagIcon()}
      </div>
    );
  }
  // tech debt to create its own component - MWEB-4202
  if (showInfoTagOnly) {
    return (
      <div className={styles['info-tag']}>
        <button
          type="button"
          onClick={onClick}
          className={styles['info-button']}
        >
          <InfoSmallIcon fill={colors.white} />
        </button>
      </div>
    );
  }

  return (
    <div
      className={styles.tag}
      style={{ background: dealsData[dealType].color }}
    >
      {!isListingPage && dealsData[dealType].tagIcon()}
      <span className={styles.title}>{dealsData[dealType].tagTitle}</span>
      {isListingPage && (
        <button
          type="button"
          onClick={onClick}
          className={styles['info-button']}
        >
          <InfoSmallIcon />
        </button>
      )}
    </div>
  );
};

DealsTag.propTypes = {
  listing: PropTypes.instanceOf(Listing).isRequired,
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  isListingPage: PropTypes.bool,
  dealType: PropTypes.oneOf([dealTypes.flashDeal, dealTypes.zoneDeal]),
  showModal: PropTypes.func,
  showIconOnly: PropTypes.bool,
  showInfoTagOnly: PropTypes.bool,
};

export default connect(null, { showModal: showModalDispatch })(DealsTag);
