import React from 'react';
import classNames from 'classnames';
import ExternalIcon from 'ui/ExternalIcon/ExternalIcon';

import { Disclosure as DisclosureModel } from 'models';

import DisclosureDetail from './DisclosureDetail';

import styles from './Disclosures.module.scss';

interface DisclosuresProps {
  allDisclosures?: Partial<Record<string, DisclosureModel>>;
  disclosures?: string[];
  onlyIcon?: boolean;
}

export default function Disclosures({
  allDisclosures = {},
  disclosures = [],
  onlyIcon = false,
}: DisclosuresProps) {
  if (!disclosures.length) {
    return null;
  }

  const listItems = disclosures.map((disclosureKey) => {
    const disclosure = allDisclosures[disclosureKey];

    // This component only cares about "default" disclosures and not the
    // "special_treatment" cases such as zone_deals or print_only since these
    // are not shown to our users in the same way (and likely have a
    // completely different component for them)
    if (!disclosure) {
      return null;
    } else if (
      disclosure.bestDisplayType !== 'default' &&
      !disclosure.isHighlightedDisplay
    ) {
      return null;
    } else if (disclosure.isHighlightedDisplay && !onlyIcon) {
      return null;
    }

    // In certain screens, we want to only display disclosure icons instead of
    // the clickable "i" icon
    if (onlyIcon) {
      if (disclosure.iconURL) {
        return (
          <ExternalIcon
            key={disclosureKey}
            testId={`disclosure-icon-${disclosureKey}`}
            src={disclosure.iconURL}
            color={disclosure.color}
            title={disclosure.title}
          />
        );
      } else {
        return null;
      }
    }

    // disclosures with title and description, should pop a modal
    if (disclosure.title && disclosure.description) {
      return (
        <DisclosureDetail
          key={disclosureKey}
          disclosure={disclosure}
          displayText
        />
      );
    }

    return (
      <span key={disclosureKey} className={styles['disclosure-text']}>
        {disclosure.title ?? disclosureKey.replace('_', ' ').toUpperCase()}
      </span>
    );
  });

  return (
    <div
      className={classNames(styles['disclosures-container'], {
        [styles['only-icon']]: onlyIcon,
      })}
    >
      {listItems}
    </div>
  );
}
