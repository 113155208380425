import React from 'react';
import colors from 'styles/colors.constants';

export default (props) => (
  <svg
    width={16}
    height={16}
    fill={props.color || colors.gray500}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
  </svg>
);
