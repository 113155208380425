export const GAMETIME_VALUES = [
  {
    name: 'Above & Beyond',
    description:
      "We don't settle. You'll find us going the extra mile for our users, our partners, and each other.",
  },
  {
    name: 'One Team, One Dream',
    description:
      "We're in it together. We celebrate wins and learn from our failures as a team.",
  },
  {
    name: 'Always Be Curious',
    description:
      'We challenge the status quo every day and expect that you will, too.',
  },
];

export const BENEFITS_AND_PERKS = [
  {
    name: 'Health Benefits',
    description:
      'Medical, dental, and vision coverage for you and your dependents',
    src: 'https://assets.gametime.co/static_pages/careers/icon-health-benefits.svg',
  },
  {
    name: 'Equity',
    description: "An ownership stake in the company you'll be helping to build",
    src: 'https://assets.gametime.co/static_pages/careers/icon-equity.svg',
  },
  {
    name: 'Flexible PTO',
    description: "'Take what you need' vacation policy (we actually mean it!)",
    src: 'https://assets.gametime.co/static_pages/careers/icon-flexible-pto.svg',
  },
  {
    name: 'Remote First',
    description: 'Fully remote company',
    src: 'https://assets.gametime.co/static_pages/careers/icon-remote-first.svg',
  },
  {
    name: 'Remote Work Stipend',
    description:
      'Monthly stipend to offset the cost of internet and any home office updates',
    src: 'https://assets.gametime.co/static_pages/careers/icon-remote-work-stipend.svg',
  },
  {
    name: 'Paid Parental Leave',
    description:
      'Peace of mind for parents to dedicate their time to the care of their new child',
    src: 'https://assets.gametime.co/static_pages/careers/icon-paid-parental-leave.svg',
  },
  {
    name: 'Gametime Credit',
    description:
      'Monthly Gametime credit to experience the product for yourself',
    src: 'https://assets.gametime.co/static_pages/careers/icon-gametime-credit.svg',
  },
  {
    name: 'Team Celebrations',
    description: 'Company meetups and events in different cities across the US',
    src: 'https://assets.gametime.co/static_pages/careers/icon-team-celebrations.svg',
  },
];

export const NAVIGATION_SECTIONS = {
  ourCompany: 'our-company',
  benefitsAndPerks: 'benefits-and-perks',
  openPositions: 'open-positions',
};
