import { FETCH_PROMO_CODES_CONTENT_SUCCESS } from './actions';

const initialState = {
  promoCodes: {
    content: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_PROMO_CODES_CONTENT_SUCCESS:
      const { slug, fields } = action.result || {};

      return {
        ...state,
        [slug]: fields,
      };
    default:
      return state;
  }
}
