import React, { MouseEvent, useMemo } from 'react';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ChevronIcon from 'icons/ChevronIcon';
import CloseLineIcon from 'icons/CloseLineIcon';
import colors from 'styles/colors.constants';
import { ListingDeal, ListingImg } from 'types';

import IconSprite from '../../IconSprite';
import SeatMapPinIcon from '../SeatMapPinIcon';

import styles from './SeatMapPinLarge.module.scss';

type SeatMapPinLargeProps = {
  price?: number;
  imgProps: ListingImg;
  onClose?: VoidFunction;
  onAdvance?: VoidFunction;
  deal?: ListingDeal;
  isPriceHidden?: boolean;
  showSprite?: boolean;
};

const SeatMapPinLarge = ({
  price,
  imgProps,
  onClose,
  onAdvance,
  deal,
  isPriceHidden = false,
  showSprite = false,
}: SeatMapPinLargeProps) => {
  const handlePinClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClose?.();
  };

  const pinStyles = useMemo(() => {
    if (!deal) return {};

    return {
      borderColor: deal.styles.borderColor,
    };
  }, [deal]);

  return (
    <div style={pinStyles} className={styles['pin-head']}>
      <div
        className={classNames(styles['pin-container'], {
          [styles['price-hidden']]: isPriceHidden,
        })}
      >
        <Image className={styles['pin-image']} {...imgProps} />

        <div className={styles['pin-body']}>
          <div className={styles['pin-deal-icon']}>
            {showSprite && deal ? (
              <IconSprite iconType={deal.slug} variant="color" />
            ) : (
              <SeatMapPinIcon deal={deal} />
            )}
          </div>

          {!isPriceHidden && price && (
            <TicketPrice price={price} showEachSuffix />
          )}

          {onAdvance && (
            <button
              data-testid="advance"
              className={styles['pin-button']}
              onClick={onAdvance}
            >
              <ChevronIcon
                direction="right"
                color={colors.white}
                width="12"
                height="12"
              />
            </button>
          )}

          {onClose && (
            <button
              data-testid="close"
              className={styles['pin-button']}
              onClick={handlePinClose}
            >
              <CloseLineIcon width="16" height="16" fill={colors.white} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeatMapPinLarge;
