import React from 'react';
import PropTypes from 'prop-types';

const HorizontalScrollCard = ({ children, itemId, className }) => {
  return (
    <div id={itemId} className={className}>
      {children}
    </div>
  );
};

HorizontalScrollCard.propTypes = {
  children: PropTypes.node.isRequired,
  itemId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HorizontalScrollCard;

/*
   NOTE: itemId is required for track items
*/
