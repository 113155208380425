import { getMouseBoundedPosition } from '../bounds/bounds.utils';

export function handleCalculateZoomPositions(
  contextInstance,
  mouseX,
  mouseY,
  newScale,
  bounds,
  limitToBounds
) {
  const { scale, positionX, positionY } = contextInstance.transformState;

  const scaleDifference = newScale - scale;

  if (typeof mouseX !== 'number' || typeof mouseY !== 'number') {
    console.warn('Mouse X and Y position were not provided!');
    return { x: positionX, y: positionY };
  }

  const calculatedPositionX = positionX - mouseX * scaleDifference;
  const calculatedPositionY = positionY - mouseY * scaleDifference;

  // do not limit to bounds when there is padding animation,
  // it causes animation strange behaviour

  const newPositions = getMouseBoundedPosition(
    calculatedPositionX,
    calculatedPositionY,
    bounds,
    limitToBounds,
    0,
    0,
    null
  );

  return newPositions;
}

export function checkZoomBounds(
  zoom,
  minScale,
  maxScale,
  zoomPadding,
  enablePadding
) {
  const scalePadding = enablePadding ? zoomPadding : 0;
  const minScaleWithPadding = minScale - scalePadding;

  if (!isNaN(maxScale) && zoom >= maxScale) return maxScale;
  if (!isNaN(minScale) && zoom <= minScaleWithPadding)
    return minScaleWithPadding;
  return zoom;
}
