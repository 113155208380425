import { PAYMENT_METHOD_NAMES } from 'analytics/constants';

export class Purchase {
  static PAYMENT_METHODS = Object.entries(PAYMENT_METHOD_NAMES).reduce(
    (paymentMethods, [purchaseType, paymentMethodName]) =>
      Object.assign(paymentMethods, {
        [purchaseType]: () => ({
          payment_method: paymentMethodName,
        }),
      }),
    {}
  );

  constructor({
    paymentMethod,
    transactionId,
    logStreamId,
    supplierLogId,
    orientationZ,
    orientationX,
    orientationY,
    listingId,
    performerId,
    venueId,
    eventId,
    dev,
    payload,
  }) {
    this.paymentMethod = paymentMethod;
    this.transactionId = transactionId;
    this.logStreamId = logStreamId;
    this.supplierLogId = supplierLogId;
    this.performerId = performerId;
    this.venueId = venueId;
    this.eventId = eventId;
    this.listingId = listingId;
    this.orientationX = orientationX;
    this.orientationY = orientationY;
    this.orientationZ = orientationZ;
    this.dev = dev;
    this.payload = payload;
  }

  eventName() {
    return 'purchase';
  }

  toParams() {
    const params = {
      transaction_id: this.transactionId,
      log_stream_id: this.logStreamId,
      supplier_log_id: this.supplierLogId,
      performer_id: this.performerId,
      venue_id: this.venueId,
      event_id: this.eventId,
      listing_id: this.listingId,
      orientation_x: this.orientationX,
      orientation_y: this.orientationY,
      orientation_z: this.orientationZ,
    };

    for (const key in this.paymentMethod) {
      if (Object.prototype.hasOwnProperty.call(this.paymentMethod, key)) {
        params[key] = this.paymentMethod[key];
      }
    }

    for (const key in this.payload) {
      if (Object.prototype.hasOwnProperty.call(this.payload, key)) {
        params[`payload_${key}`] = this.payload[key];
      }
    }

    for (const key in this.dev) {
      if (Object.prototype.hasOwnProperty.call(this.dev, key)) {
        params[`dev_${key}`] = this.dev[key];
      }
    }

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }

    return params;
  }
}
