import React from 'react';

import Image from 'components/Image/Image';
import { Performer } from 'models';

import styles from './PerformerLogo.module.scss';

interface PerformerLogoProps {
  performer: Performer;
}

const PerformerLogo = ({ performer }: PerformerLogoProps) => {
  return (
    <div
      className={styles['performer-logo-container']}
      style={{
        backgroundColor: performer.logoBgColor,
      }}
    >
      <Image
        className={styles['performer-logo']}
        alt={performer.shortName}
        src={performer.logoImageUrl}
        lazyLoad
      />
    </div>
  );
};

export default PerformerLogo;
