import React from 'react';
import { useEvent } from 'contexts/EventContext';

import HeadCanonicalPath from 'components/Head/CanonicalPath';
import HeadDescription from 'components/Head/Description';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import { getEventJsonLD } from 'components/JsonLD/helpers/event/event';
import JsonLD from 'components/JsonLD/JsonLD';
import { getEventPageDescription } from 'modules/pageDescriptions';
import { getEventPageTitle } from 'modules/pageTitles';

const EventMeta = () => {
  const { fullEvent } = useEvent();

  const path = fullEvent.getPath();
  const price = fullEvent.getPrice();
  const primaryPerformer = fullEvent.getPrimaryPerformer();
  const title = getEventPageTitle(fullEvent);
  const description = getEventPageDescription(fullEvent);

  return (
    <div>
      <HeadTitle title={title} />
      <HeadDescription description={description} />
      <HeadCanonicalPath path={path} />
      <HeadImage src={primaryPerformer.heroImageUrl} />
      {!!price && <JsonLD json={getEventJsonLD(fullEvent)} />}
    </div>
  );
};

export default EventMeta;
