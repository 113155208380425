import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAnalyticsContext } from 'analytics';
import { MarketingSurveyEvent } from 'analytics/events/MarketingSurvey';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import {
  CTA_STATES,
  getNextQuestion,
  getNextStep,
  getQuestionStepFormValues,
  getSurveyResponsePayload,
  isLastStep,
} from 'utils/survey';

import { SurveyForm } from './SurveyForm';

const SurveyManager = ({
  surveyQuestions,
  onHide,
  updateUserPreferences,
  surveyData,
  transactionId,
}) => {
  const analytics = useAnalyticsContext();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentInputs] = useState({});
  const [currentInputText, setCurrentInputText] = useState('');

  useEffect(() => {
    if (surveyQuestions?.length) {
      setCurrentQuestion(surveyQuestions[0]);
      setCurrentStep(surveyQuestions[0].id);
    }
  }, [surveyQuestions]);

  const onSurveyAction = () => {
    const nextStep = getNextStep(
      surveyData,
      currentStep,
      currentQuestion,
      selectedOptions
    );
    const nextQuestion = getNextQuestion(surveyQuestions, currentQuestion);

    if (nextStep) {
      setCurrentStep(nextStep);
    } else if (nextQuestion) {
      trackSurveyResponse(CTA_STATES.DONE);
      setCurrentStep(nextQuestion.id);
      setCurrentQuestion(nextQuestion);
      setSelectedOptions([]);
    } else {
      onSurveyResolved(CTA_STATES.DONE);
    }
    setCurrentInputText('');
  };

  const trackSurveyResponse = (interaction) => {
    const payload = getSurveyResponsePayload(
      surveyData,
      currentQuestion,
      selectedOptions,
      currentInputs
    );
    analytics.track(
      new MarketingSurveyEvent({
        interaction,
        transaction_id: transactionId,
        payload,
      })
    );
  };

  const onSurveyResolved = (interaction = CTA_STATES.CLOSE) => {
    trackSurveyResponse(interaction);
    updateUserPreferences({ surveyResolved: true });
    onHide();
  };

  const onOptionClick = (option) => {
    let filteredOptions = selectedOptions;

    if (selectedOptions.includes(option)) {
      filteredOptions = selectedOptions.filter(
        (selectedOption) => selectedOption !== option
      );
    } else {
      if (currentQuestion.has_one_answer) {
        filteredOptions = [option];
      } else {
        filteredOptions = [...selectedOptions, option];
      }
    }
    setSelectedOptions(filteredOptions);
  };

  const onTextInput = (text) => {
    currentInputs[currentStep] = text;
    setCurrentInputText(text);
  };

  if (!surveyQuestions?.length || !currentQuestion) {
    return null;
  }

  const { options, title, subtitle, textInputProps } =
    getQuestionStepFormValues(surveyData, currentQuestion, currentStep);

  const lastStep = isLastStep(
    surveyData,
    surveyQuestions,
    currentStep,
    currentQuestion,
    selectedOptions
  );

  return (
    <SurveyForm
      title={title}
      subtitle={subtitle}
      options={options}
      selectedOptions={selectedOptions}
      currentInputText={currentInputText}
      textInputProps={textInputProps}
      onOptionClick={onOptionClick}
      onTextInput={onTextInput}
      onDone={onSurveyAction}
      onClose={() => onSurveyResolved()}
      isLastStep={lastStep}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateUserPreferences: (pref) => dispatch(updateUserPreference(pref)),
});

SurveyManager.propTypes = {
  surveyQuestions: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  updateUserPreferences: PropTypes.func,
  transactionId: PropTypes.string.isRequired,
  surveyData: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(SurveyManager);
