import { Component, createElement } from 'react';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { View } from './events/View';
import { withAnalyticsContext } from './AnalyticsProvider';

export const TrackPageView = (getEventState) => (wrappedComponent) => {
  class TrackPageViewWrapper extends Component {
    static propTypes = {
      analyticsContext: PropTypes.shape({
        track: PropTypes.func.isRequired,
      }),
    };

    componentDidMount() {
      const eventState = getEventState(this.props, this.state);
      this.eventState = eventState;
      this.sendEvent(eventState);
    }

    componentDidUpdate() {
      const eventState = getEventState(this.props, this.state);

      if (!_isEqual(eventState, this.eventState)) {
        this.eventState = eventState;
        this.sendEvent(eventState);
      }
    }

    sendEvent = (eventState) => {
      if (!eventState) return;

      const { active = true, ...eventParams } = eventState;

      /* active flag is primarily used in modals, where it is already mounted but not yet shown */
      if ((eventParams.pageType || eventParams.page_type) && active) {
        this.props.analyticsContext.track(new View(eventParams));
      }
    };

    render() {
      return createElement(wrappedComponent, this.props);
    }
  }
  return withAnalyticsContext(TrackPageViewWrapper);
};
