import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InfoSmallIcon from 'icons/InfoSmallIcon';
import colors from 'styles/colors.constants';
import { isObjectEmpty } from 'utils/objects';

import DisclosuresModal from './components/DisclosuresModal';

import styles from './DisclosureDetail.module.scss';

function isValid(disclosure) {
  return !isObjectEmpty(disclosure) && !isObjectEmpty(disclosure.slug);
}

function _DisclosureDetailModal({
  disclosure = { title: '', slug: '' },
  displayText = false,
}) {
  const [show, setShow] = useState(false);

  if (!isValid(disclosure)) {
    // no "details" to show without required fields
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShow(!show);
  };

  return (
    <div className={styles['disclosure-detail-container']}>
      {displayText && (
        <span className={styles['disclosure-detail-text']}>
          {disclosure.title}
        </span>
      )}
      <span
        className={styles['disclosure-detail-icon']}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <InfoSmallIcon fill={colors.softBlue} height="16" width="16" />
        <DisclosuresModal
          show={show}
          disclosure={disclosure}
          disclosureType={disclosure.slug}
          onHide={handleClick}
        />
      </span>
    </div>
  );
}

_DisclosureDetailModal.propTypes = {
  disclosure: PropTypes.object,
  displayText: PropTypes.bool,
};

export default _DisclosureDetailModal;
