import React from 'react';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#FFFFFF'}
      d="M5 17V20C5 21.1046 5.89539 22 7 22H17C18.1046 22 19 21.1046 19 20V17H21.5C21.7761 17 22 16.7761 22 16.5V10C22 8.34315 20.6569 7 19 7V4C19 2.89545 18.1046 2 17 2H7C5.89539 2 5 2.89545 5 4V7C3.34315 7 2 8.34315 2 10V16.5C2 16.7761 2.22386 17 2.5 17H5ZM17 4.5V7H7V4.5C7 4.22386 7.22386 4 7.5 4H16.5C16.7761 4 17 4.22386 17 4.5ZM17 12V19.5C17 19.7761 16.7761 20 16.5 20H7.5C7.22386 20 7 19.7761 7 19.5V12H17ZM9.5 16C9.22386 16 9 16.2239 9 16.5V17.5C9 17.7761 9.22386 18 9.5 18H14.5C14.7761 18 15 17.7761 15 17.5V16.5C15 16.2239 14.7761 16 14.5 16H9.5Z"
    />
  </svg>
);
