import React from 'react';
import PropTypes from 'prop-types';

import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import PurchasePrice from 'pages/Listing/components/PurchasePrice/PurchasePrice';

import styles from './PriceBreakdownModal.module.scss';

const PriceBreakdownModal = ({
  prefeeSeatPrice,
  currencyPrefix = '$',
  seatCount,
  discount,
  seatFee,
  salesTax = 0,
  totalPrice,
  show = false,
  onHide,
  promoAmount = 0,
}) => {
  const adjustedTotal = totalPrice - promoAmount;
  return (
    <GTInfoModal show={show} onHide={onHide}>
      <div className={styles['breakdown-line']}>
        <div className={styles['breakdown-label']}>Tickets</div>
        <div className={styles['breakdown-amount']}>
          <PurchasePrice
            prefeeSeatPrice={prefeeSeatPrice}
            currencyPrefix={currencyPrefix}
            seatCount={seatCount}
            discount={discount}
          />
        </div>
      </div>
      <div className={styles['breakdown-line']}>
        <div className={styles['breakdown-label']}>Fees</div>
        <div className={styles['breakdown-amount']}>
          {seatCount}
          <span className={styles['multiply-symbol']}>&#x2715;</span>
          <TicketPrice currencyPrefix={currencyPrefix} price={seatFee} />
        </div>
      </div>
      {salesTax > 0 && (
        <div className={styles['breakdown-line']}>
          <div className={styles['breakdown-label']}>Sales Tax</div>
          <div
            className={styles['breakdown-amount']}
            data-testid="sales-tax-amount"
          >
            <TicketPrice
              currencyPrefix={currencyPrefix}
              price={salesTax * seatCount}
            />
          </div>
        </div>
      )}
      {promoAmount > 0 && (
        <div className={styles['breakdown-line']}>
          <div className={styles['breakdown-label']}>Promo Code</div>
          <div
            className={styles['breakdown-amount']}
            data-testid="promo-code-amount"
          >
            {`- ${currencyPrefix}${promoAmount}`}
          </div>
        </div>
      )}
      <div className={styles['total-line']}>
        <div>Total</div>
        <div className={styles['total-amount']} data-testid="total-amount">
          <TicketPrice currencyPrefix={currencyPrefix} price={adjustedTotal} />
        </div>
      </div>
    </GTInfoModal>
  );
};

PriceBreakdownModal.propTypes = {
  prefeeSeatPrice: PropTypes.number.isRequired,
  currencyPrefix: PropTypes.string,
  seatCount: PropTypes.number.isRequired,
  discount: PropTypes.shape({
    available: PropTypes.bool,
    prefeeDiscount: PropTypes.number,
    percentDiscountPercentage: PropTypes.number,
    showLabel: PropTypes.bool,
  }).isRequired,
  seatFee: PropTypes.number.isRequired,
  salesTax: PropTypes.number,
  totalPrice: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  promoAmount: PropTypes.number,
};

export default PriceBreakdownModal;
