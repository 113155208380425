import _flatMap from 'lodash/flatMap';
import _flatten from 'lodash/flatten';

import { FullEvent, Performer, Venue } from 'models';

const _selectSearch = (state) => state?.data?.search;

export const selectSearchResults = (
  state,
  searchSessionId,
  limitEvents = 20
) => {
  const searchFilter = _selectSearch(state);
  let hasMoreResults = false;

  const searchResults = (searchFilter.display_groups ?? [])
    .map((info) => {
      let matchingEvents = (searchFilter.events ?? [])
        .filter((event) => {
          return event.meta?.display_group === info.slug;
        })
        .map((event) => {
          return {
            event: new FullEvent(event, searchSessionId),
            meta: event.meta,
          };
        });

      if (matchingEvents.length > limitEvents) {
        hasMoreResults = true;
        matchingEvents = matchingEvents.slice(0, limitEvents);
      }

      const matchingVenues = (searchFilter.venues ?? [])
        .filter((venue) => {
          return venue.meta?.display_group === info.slug;
        })
        .map((venue) => ({
          venue: new Venue(venue, searchSessionId),
          meta: venue.meta,
        }));

      const matchingPerformers = (searchFilter.performers ?? [])
        .filter((performer) => {
          return performer.meta?.display_group === info.slug;
        })
        .map((performer) => ({
          performer: new Performer(performer, searchSessionId),
          meta: performer.meta,
        }));

      const list = matchingEvents
        .concat(matchingVenues)
        .concat(matchingPerformers)
        .sort((a, b) => (a.meta.sort_order || 0) - (b.meta.sort_order || 0))
        .map((cur) =>
          Object.keys(cur)
            .filter((key) => key !== 'meta')
            .map((k) => ({ [k]: cur[k] }))
        );

      return { info, items: _flatten(list) };
    })
    .sort((a, b) => (a.info.sort_order || 0) - (b.info.sort_order || 0));

  const flattenedResults = _flatMap(searchResults, (el) => el.items).reduce(
    (acc, cur) => {
      const node = cur.event ?? cur.venue ?? cur.performer;
      if (node) return [...acc, node];
      return acc;
    },
    []
  );

  return {
    results: searchResults,
    flattenedResults,
    hasMoreResults,
    recommended: searchFilter.recommended,
  };
};

export const selectSearchTestData = (state) => {
  const searchFilter = _selectSearch(state);
  return {
    testId: searchFilter.event_ab_test_data?.test_id,
    variantId: searchFilter.event_ab_test_data?.test_variant_id,
  };
};
