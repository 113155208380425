import React from 'react';

import SimpleButton, { TYPES } from 'components/Buttons/SimpleButton';
import { WarningCircleFillIcon } from 'icons';
import TrashBinIcon from 'icons/TrashBinIcon';

import { MODAL_CONTENT_TYPE } from './constants';

import styles from './AccountDeletionModal.module.scss';

type ModalContentType =
  (typeof MODAL_CONTENT_TYPE)[keyof typeof MODAL_CONTENT_TYPE];

type ModalContent = {
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  buttons: React.ReactElement;
};

type Props = {
  onClose: VoidFunction;
  contentType: ModalContentType;
  onConfirm: VoidFunction;
};

function AccountDeletionModal({ onClose, onConfirm, contentType }: Props) {
  const modalContentByType: Record<ModalContentType, ModalContent> = {
    error: {
      title: 'We were unable to process your request.',
      subtitle: 'Please check your internet connection and try again later.',
      icon: <WarningCircleFillIcon fill="black" />,
      buttons: (
        <SimpleButton
          text="okay"
          className={styles['button-left']}
          type={TYPES.GREEN_MEDIUM}
          onClick={onClose}
        />
      ),
    },
    confirm: {
      title: 'Confirm Your Request',
      subtitle:
        "Choose 'Confirm' to sign out and delete your account and all the associated data.",
      icon: <TrashBinIcon />,
      buttons: (
        <>
          <SimpleButton
            text="cancel"
            className={styles['button-left']}
            type={TYPES.GREEN_TRANSPARENT_NO_BORDER_MEDIUM}
            onClick={onClose}
          />
          <SimpleButton
            text="confirm"
            className={styles['button-right']}
            type={TYPES.GREEN_MEDIUM}
            onClick={onConfirm}
          />
        </>
      ),
    },
  };

  const content = modalContentByType[contentType];

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>{content.icon}</div>
        <div className={styles.body}>
          <span className={styles.title}>{content.title}</span>
          <br />
          <span className={styles.subtitle}>{content.subtitle}</span>
        </div>
        <div className={styles.footer}>{content.buttons}</div>
      </div>
    </div>
  );
}

export default AccountDeletionModal;
