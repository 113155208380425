import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { MetrosResponse } from 'types';

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const getMetros = () => {
  return httpClient.request<MetrosResponse>({
    path: '/v1/metros',
  });
};
