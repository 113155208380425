import {
  GAMETIME_URLS,
  POTENTIAL_ACTION_TYPES,
  WEB_SITE_NAME,
  WEB_SITE_TYPES,
} from './constants';
import { WebSiteSchema } from './type';

export function getWebSiteJsonLD(path: string): WebSiteSchema {
  const webSiteSchema: WebSiteSchema = {
    '@context': 'https://schema.org',
    '@type': WEB_SITE_TYPES.WEB_SITE,
    name: WEB_SITE_NAME,
    url: GAMETIME_URLS.PRODUCTION,
    sameAs: [
      'https://www.facebook.com/Gametime',
      'https://x.com/gametime',
      'https://www.instagram.com/gametimeapp',
      'https://www.linkedin.com/company/gametime-united',
      'https://www.tiktok.com/@gametime',
    ],
  };

  if (path === '/') {
    webSiteSchema.potentialAction = {
      '@type': POTENTIAL_ACTION_TYPES.SEARCH_ACTION,
      target: 'https://gametime.co/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    };
  }

  return webSiteSchema;
}
