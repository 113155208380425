import React from 'react';
import PropTypes from 'prop-types';

import { SurveySelectionButton } from './SurveySelectionButton';

import styles from './PostPurchaseSurveyModal.module.scss';

export const SurveySelectionGrid = ({
  options,
  selectedOptions,
  onOptionClick,
}) => {
  const renderOptions = () => {
    return options.map((option) => {
      return (
        <SurveySelectionButton
          key={option.option}
          title={option.option}
          onClick={() => onOptionClick(option)}
          isSelected={selectedOptions.includes(option)}
        />
      );
    });
  };

  return <div className={styles['survey-options-grid']}>{renderOptions()}</div>;
};

SurveySelectionGrid.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};
