module.exports = {
  domain: 'web-qa.gametime.co',
  API_URL: 'https://staging.gametime.co',
  MOBILE_API_URL: 'https://mobile-staging.gametime.co',
  appleAppAssociationConstants: {
    universalLinkSuffix: '.beta',
  },
  PUSHER_KEY: '5afe9ecbc1d262389a28',
  BRAZE: {
    API_KEY: '5d58f8d7-3186-4377-b3de-2a8d8c0e00b9',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
};
