import { Component, createElement } from 'react';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import PropTypes from 'prop-types';

import { withAnalyticsContext } from 'analytics';

const primitiveProperties = (event, prefix) => {
  const properties = {};
  Object.keys(event).forEach((key) => {
    const eventProperty = event[key];
    if (!_isObject(eventProperty) && !Array.isArray(eventProperty)) {
      properties[`${prefix}.${key}`] = event[key];
    }
  });
  return properties;
};

/**
 * @param event: Event name for analytics tracking.
 * @param triggerStart: whether to fire a start action for this trackable event
 * @param contextProps: super properties for this event to be included in all subsequent actions
 * @constructor: Creates a new Analytics for the wrapped component.
 *  - If trigger started is true, it will also triggers start action for the component.
 *  - If contextProps passed, Trackabke will find all of them in props and try to add them
 *    as super props for all subsequent actions
 */
const AnalyticsContext =
  ({ event, triggerStart = false, contextProps = {} }) =>
  (wrappedComponent) => {
    @withAnalyticsContext
    class TrackableComponent extends Component {
      static propTypes = {
        analyticsContext: PropTypes.shape({
          track: PropTypes.func.isRequired,
        }),
      };

      componentDidMount() {
        if (triggerStart && event) {
          const eventProperties = {
            action: 'start',
            ...this.getEventSuperProps(),
          };

          const mParticleEventProperties = {
            mParticle: true,
            ...eventProperties,
          };
          this.props.analyticsContext.track(event, mParticleEventProperties);
        }
      }

      getEventSuperProps() {
        const actionProps = {};

        Object.keys(contextProps).forEach((key) => {
          let contextProp = contextProps[key];
          const isOptional = contextProps[key].startsWith('$');

          if (isOptional) {
            contextProp = contextProp.replace('$', '');
          }

          const tmp = _get(this.props, contextProp);

          if (tmp !== undefined) {
            if (_isObject(tmp)) {
              Object.assign(actionProps, primitiveProperties(tmp, key));
            } else {
              actionProps[key] = tmp;
            }
          } else if (!isOptional) {
            // optional prop
            console.warn(
              'Trackable',
              `Could not find tracking key ${key} in props`
            );
          }
        });

        return actionProps;
      }

      render() {
        return createElement(wrappedComponent, this.props);
      }
    }

    return TrackableComponent;
  };

export default AnalyticsContext;
