import { selectUpsellEventsByEventId } from './selectors';

export const ACTIONS = {
  GET_UPSELL_EVENTS_REQUEST: 'GET_UPSELL_EVENTS_REQUEST',
  GET_UPSELL_EVENTS_REQUEST_SUCCESS: 'GET_UPSELL_EVENTS_REQUEST_SUCCESS',
  GET_UPSELL_EVENTS_REQUEST_FAILURE: 'GET_UPSELL_EVENTS_REQUEST_FAILURE',
};

export const fetchUpsellEvents = (params) => (dispatch, getState) => {
  const state = getState();

  if (!params.event_id || selectUpsellEventsByEventId(state, params.event_id)) {
    return Promise.resolve();
  }

  return dispatch({
    types: [
      ACTIONS.GET_UPSELL_EVENTS_REQUEST,
      ACTIONS.GET_UPSELL_EVENTS_REQUEST_SUCCESS,
      ACTIONS.GET_UPSELL_EVENTS_REQUEST_FAILURE,
    ],
    params,
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/events/upsell',
        searchParams: params,
      }),
  });
};

export const fetchUpsellEventsById = (eventId) =>
  fetchUpsellEvents({ event_id: eventId });
