import { useEffect, useRef, useState } from 'react';

const useLoadImage = (src) => {
  const ready = useRef(true);
  const [loaded, setLoaded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!src) {
      return;
    }
    const image = typeof window !== 'undefined' ? new window.Image() : {};

    image.onload = (event) => {
      if (ready.current) {
        const { width, height } = event.target;
        setLoaded(true);
        setDimensions({ width, height });
      }
    };

    image.onerror = () => {
      if (ready.current) {
        setLoaded(false);
        setFailed(true);
      }
    };

    image.src = src;
  }, [src]);

  useEffect(() => {
    return () => {
      ready.current = false;
    };
  }, []);

  return { loaded, failed, dimensions };
};

export default useLoadImage;
