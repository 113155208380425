import React from 'react';

export default ({ width = '24', height = '24', fill = '#fff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 2.91669C8.96683 2.91669 9.75016 3.70003 9.75016 4.66669C9.75016 5.63336 8.96683 6.41669 8.00016 6.41669C7.03349 6.41669 6.25016 5.63336 6.25016 4.66669C6.25016 3.70003 7.03349 2.91669 8.00016 2.91669ZM8.00016 10.4167C10.4752 10.4167 13.0835 11.6334 13.0835 12.1667V13.0834H2.91683V12.1667C2.91683 11.6334 5.52516 10.4167 8.00016 10.4167ZM8.00016 1.33336C6.1585 1.33336 4.66683 2.82503 4.66683 4.66669C4.66683 6.50836 6.1585 8.00002 8.00016 8.00002C9.84183 8.00002 11.3335 6.50836 11.3335 4.66669C11.3335 2.82503 9.84183 1.33336 8.00016 1.33336ZM8.00016 8.83336C5.77516 8.83336 1.3335 9.95002 1.3335 12.1667V14.6667H14.6668V12.1667C14.6668 9.95002 10.2252 8.83336 8.00016 8.83336Z"
        fill={fill}
      />
    </svg>
  );
};
