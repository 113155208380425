import React from 'react';
import PropTypes from 'prop-types';

import GTFooter from 'components/Footers/GTFooter/GTFooter';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';

const GTContainer = ({ headerProps, ...otherProps }) => (
  <ContainerTemplate
    header={<MinimalHeader {...headerProps} />}
    footer={<GTFooter />}
    {...otherProps}
  />
);

GTContainer.propTypes = {
  headerProps: PropTypes.object,
};

export default GTContainer;
