import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { setServerResponseStatus } from 'store/modules/app/app';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import styles from './NotFound.module.scss';

@TrackPageView(() => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.NOT_FOUND(),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.NOT_FOUND(),
}))
class NotFound extends Component {
  static propTypes = {
    setResponseStatus: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setResponseStatus(404);
  }

  render() {
    return (
      <ContainerTemplate
        header={
          <MinimalHeader search showAccount showCategories showHamburger />
        }
        footer={<GTFooter />}
        className={styles['page-container']}
      >
        <div className={styles['hero-container']}>
          <div className={styles.title}>Womp womp.</div>
          <div className={styles.subtitle}>
            The page you are looking for doesn't exist.
          </div>
          <div className={styles.img}>
            <Image
              lazyLoad={false}
              preserveFormat
              src="https://hassets.gametime.co/production-assets/images/404.png"
            />
          </div>
          <h1>Page not found</h1>
          <div className={styles.bottom}>
            try again or
            <Link className={styles.link} to="/">
              &nbsp;return to the home page
            </Link>
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setResponseStatus: (statusCode) =>
    dispatch(setServerResponseStatus(statusCode)),
});

const NotFoundWrapper = connect(null, mapDispatchToProps)(NotFound);

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    await store.dispatch(fetchMetros());

    return null;
  };

NotFoundWrapper.loader = loader;

export default NotFoundWrapper;
