import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Riskified beacon snippet to track user actions across the site used for fraud detection/prevention
// Ref: https://apiref.riskified.com/curl/#beacon
export default class Riskified extends Component {
  render() {
    const { webSessionId } = this.props;

    if (!webSessionId) return null;

    return (
      /* eslint-disable */
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `//<![CDATA[
            (function() {
              function riskifiedBeaconLoad() {
                var store_domain = 'gametime.co';
                var session_id = '${webSessionId}';
                var url = ('https:' == document.location.protocol ? 'https://' : 'http://')
                  + "beacon.riskified.com?shop=" + store_domain + "&sid=" + session_id;
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = url;
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
              }
              if (window.attachEvent)
                window.attachEvent('onload', riskifiedBeaconLoad)
              else
                window.addEventListener('load', riskifiedBeaconLoad, false);
            })();
            //]]>`,
        }}
      />
      /* eslint-enable */
    );
  }
}

Riskified.propTypes = {
  webSessionId: PropTypes.string,
};
