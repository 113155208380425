import React from 'react';
import classNames from 'classnames';

import ProfileFillIcon from 'icons/ProfileFillIcon';
import colors from 'styles/colors.constants';

import { SIDEBAR_VIEWS, SidebarView } from './sidebar.constants';

import styles from './Sidebar.module.scss';

interface SidebarProfileProps {
  onSetActiveView: (view: SidebarView) => void;
  isActive: boolean;
  icon?: React.ReactNode;
}

const SidebarProfile = ({
  onSetActiveView,
  isActive,
  icon,
}: SidebarProfileProps) => {
  return (
    <li>
      <button
        type="button"
        onClick={() => onSetActiveView(SIDEBAR_VIEWS.PROFILE)}
        className={classNames(styles['sidebar-button'], {
          [styles.active]: isActive,
        })}
      >
        <div className={styles['icon-text-container']}>
          <ProfileFillIcon
            fill={isActive ? colors.gametimeGreenLight : colors.white}
          />
          <span className={styles.text}>Profile</span>
        </div>
        {icon}
      </button>
    </li>
  );
};

export default SidebarProfile;
