import { ValueOf } from 'type-fest';

import { COLLECTION_SLUGS, COLLECTION_VIEWS } from 'pages/Collection/constants';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { Collection } from 'types';

type CollectionView = ValueOf<typeof COLLECTION_VIEWS>;

interface GetCollectionsParams {
  metro: string;
  view: CollectionView;
  with_results: boolean;
  slug?: string;
}

interface CollectionsResult {
  collections: Collection[];
}

const COLLECTIONS_API_PATH = '/v2/collections';
const COLLECTIONS_VIEW_API_PATH = '/v2/collections/view';

const paramsToString = (params: GetCollectionsParams) =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

const getCollectionsResult = async (
  httpClient: HttpClient,
  path: `/${string}`,
  params: GetCollectionsParams
): Promise<CollectionsResult | null> => {
  const result: CollectionsResult | null = await httpClient.request({
    path,
    searchParams: paramsToString(params),
  });

  return result;
};

export const getCollections = async (
  params: GetCollectionsParams,
  httpClient: HttpClient
): Promise<CollectionsResult | null> => {
  // If we have a slug, we can fetch the collection directly
  if (params.slug) {
    const detailParams = {
      ...params,
      collection_slugs: [params.slug],
      recently_viewed: params.slug === COLLECTION_SLUGS.RECENTLY_VIEWED,
    };
    return getCollectionsResult(httpClient, COLLECTIONS_API_PATH, detailParams);
  }

  const result = await getCollectionsResult(
    httpClient,
    COLLECTIONS_VIEW_API_PATH,
    params
  );

  if (!result) {
    return null;
  }

  const nonCachedCollections = result.collections
    .filter(
      (collection: Collection) =>
        collection.resource === 'events' && !collection.results?.events
    )
    .map((collection: Collection) => collection.slug);

  if (!nonCachedCollections.length) {
    return result;
  }

  const detailParams = {
    ...params,
    collection_slugs: nonCachedCollections,
    recently_viewed: nonCachedCollections.includes(
      COLLECTION_SLUGS.RECENTLY_VIEWED
    ),
  };

  const detailResult = await getCollectionsResult(
    httpClient,
    COLLECTIONS_API_PATH,
    detailParams
  );

  if (!detailResult) {
    return result;
  }

  const mergedCollections = result.collections.map((collection: Collection) => {
    const detailCollection = detailResult.collections.find(
      (c: Collection) => c.id === collection.id
    );
    return detailCollection || collection;
  });

  result.collections = mergedCollections;
  return result;
};
