import React from 'react';

export default (props) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10.6667C4.73333 10.6667 5.33333 11.2667 5.33333 12L8 12L8 0L5.33333 -1.16376e-07C5.33333 0.733333 4.73333 1.33333 4 1.33333C3.26667 1.33333 2.66667 0.733333 2.66667 -2.32753e-07L0 -3.49129e-07L-5.23735e-07 12L2.66667 12C2.66667 11.2667 3.26667 10.6667 4 10.6667Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);
