import { SOURCE_PAGE_TYPE_NAMES } from '../constants';

import { TrackEvent } from './TrackEvent';

interface EventPayload {
  source_page_type: string;
  mode?: string;
}

const INTERACTION_NAMES = {
  HOVER_CARDS: 'hover_cards',
};

export class Hover extends TrackEvent {
  _eventName = 'hover';

  static INTERACTIONS = {
    HOVER_CARDS: ({
      eventId,
      listingId,
      allInPricing,
      displayedPrice,
    }: {
      eventId: string;
      listingId: string;
      allInPricing: boolean;
      displayedPrice: string;
    }) => ({
      interaction: INTERACTION_NAMES.HOVER_CARDS,
      event_id: eventId,
      listing_id: listingId,
      all_in_pricing: allInPricing,
      displayed_price: displayedPrice,
    }),
  };

  static SOURCE_PAGE_TYPES = {
    EVENT: ({
      pageViewType,
      isListingDetails,
    }: {
      pageViewType?: string;
      isListingDetails: boolean;
    }) => {
      const payload: EventPayload = {
        source_page_type: isListingDetails
          ? SOURCE_PAGE_TYPE_NAMES.LISTING
          : SOURCE_PAGE_TYPE_NAMES.EVENT,
      };

      if (pageViewType) {
        payload.mode = pageViewType;
      }

      return payload;
    },
  };
}
