import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'className'
> & {
  highlightFill?: string;
  shadowFill?: string;
  checkColor?: string;
};

export default function CheckShieldIcon({
  width = '20',
  height = '20',
  highlightFill = colors.yellowTernary,
  shadowFill = colors.yellowTernaryShadow,
  checkColor = colors.gray800,
  className,
}: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99548 18.7499C10.1124 18.7496 10.2292 18.7109 10.3259 18.634L15.445 14.562C16.4624 13.7527 17.0552 12.5237 17.0552 11.2237V3.42669C17.0552 3.18202 16.8886 2.96875 16.6513 2.90941L10.0586 1.26125C10.0379 1.25607 10.0167 1.25342 9.99548 1.2533V18.7499Z"
        fill={shadowFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0105 18.7466C9.89361 18.7463 9.7768 18.7076 9.68007 18.6307L4.56098 14.5587C3.54362 13.7494 2.95082 12.5204 2.95082 11.2204V3.42339C2.95082 3.17872 3.11734 2.96546 3.3547 2.90611L9.94734 1.25796C9.96807 1.25277 9.98928 1.25012 10.0105 1.25V18.7466Z"
        fill={highlightFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.13111 10.7322L12.9664 6.84534C13.2256 6.58264 13.6496 6.58264 13.9088 6.84534L14.1295 7.06896C14.3839 7.32675 14.3839 7.74109 14.1295 7.99888L9.70644 12.4813C9.3887 12.8034 8.87353 12.8034 8.55578 12.4813L6.57366 10.4726C6.31929 10.2148 6.31929 9.80046 6.57366 9.54267L6.79432 9.31905C7.05354 9.05635 7.47752 9.05635 7.73673 9.31905L9.13111 10.7322Z"
        fill={checkColor}
      />
    </svg>
  );
}
