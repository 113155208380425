export const OFFER_AVAILABILITY = {
  IN_STOCK: 'https://schema.org/InStock',
  SOLD_OUT: 'https://schema.org/SoldOut',
} as const;

export const PRICE_CURRENCIES = {
  USD: 'USD',
} as const;

export const OFFER_TYPES = {
  OFFER: 'Offer',
  AGGREGATE_OFFER: 'AggregateOffer',
} as const;
