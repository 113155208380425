import React from 'react';
import PropTypes from 'prop-types';

import NoEvents from '../NoEvents/NoEvents';
import PurchaseCard from '../PurchaseCard/PurchaseCard';

import styles from './UpcomingEvents.module.scss';

const UpcomingEvents = ({ tickets }) => {
  if (!tickets.length) {
    return <NoEvents eventType="upcoming" />;
  }

  const renderTickets = () =>
    tickets.map(({ fullEvent, purchase }) => {
      return (
        <PurchaseCard
          key={purchase.id}
          fullEvent={fullEvent}
          completePurchase={purchase}
        />
      );
    });

  return <section className={styles.events}>{renderTickets()}</section>;
};

UpcomingEvents.propTypes = {
  tickets: PropTypes.array,
};

export default UpcomingEvents;
