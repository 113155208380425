import Cookies from 'js-cookie';

import config from 'config/config';
import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import {
  SORT_IDS,
  userPreferenceSiteVisitSelector,
} from 'store/modules/userPreference/user.preference.selectors';

export const USER_PREFERENCE_REDUCER_NAME = 'userPreference';

const USER_PREFERENCE_ACTION_TYPES = generateActionTypes(
  ['UPDATE_USER_PREFERENCE'],
  USER_PREFERENCE_REDUCER_NAME
);

const userExtrasParams = Object.values(
  config.cookiesConfig.USER_EXTRAS.parameters
);
const defaultUserPreferenceValues = userExtrasParams.reduce(
  (defaultValues, parameter) => {
    defaultValues[parameter.name] = parameter.defaultValue;
    return defaultValues;
  },
  {}
);

const initialState = {
  sortId: SORT_IDS.PRICEL,
  ...defaultUserPreferenceValues,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USER_PREFERENCE_ACTION_TYPES.UPDATE_USER_PREFERENCE:
      return {
        ...state,
        ...action.result,
      };
    default:
      return state;
  }
}

/**
 * Merges updated user preferences with user preferences set in the
 * USER_EXTRAS cookie. User preference parameters that are not included
 * in the update or set in the cookie will be set to the default value
 * defined in the cookie configuration.
 *
 * @param {Object} updatedPreference - object containing key/value pairs of
 *  updated user preference parameters to set in the USER_EXTRAS cookie.
 * @returns {Object} - updated user preferences
 */
export const saveUserPreferencesInCookie = (preferencesUpdate) => {
  const cookieValue = Cookies.get(config.cookiesConfig.USER_EXTRAS.name);
  const parsedCookieValue = cookieValue ? JSON.parse(cookieValue) : {};

  const userPreferences = userExtrasParams.reduce((preferences, param) => {
    if (Object.prototype.hasOwnProperty.call(preferencesUpdate, param.name)) {
      preferences[param.name] = preferencesUpdate[param.name];
    } else if (
      Object.prototype.hasOwnProperty.call(parsedCookieValue, param.name)
    ) {
      preferences[param.name] = parsedCookieValue[param.name];
    } else {
      preferences[param.name] = param.defaultValue;
    }

    return preferences;
  }, {});

  // numeric maxAge attribute not supported by js-cookie, must convert
  // to string and set on attribute name "Max-Age"
  const { maxAge, ...cookieAttributes } =
    config.cookiesConfig.USER_EXTRAS.attributes;
  if (Number.isFinite(maxAge)) {
    cookieAttributes['Max-Age'] = maxAge.toString();
  }

  Cookies.set(
    config.cookiesConfig.USER_EXTRAS.name,
    JSON.stringify(userPreferences),
    cookieAttributes
  );

  return userPreferences;
};

/**
 * Initializes or updates the user preference
 * @param init indicates if the func call is to hydrate the redux state only.
 * @param preferences
 * @returns {{type: string, result: *}}
 */
export const updateUserPreference = (preferences, init = false) => {
  if (!init) {
    saveUserPreferencesInCookie(preferences);
  }

  return {
    type: USER_PREFERENCE_ACTION_TYPES.UPDATE_USER_PREFERENCE,
    result: preferences,
  };
};

export const incrementSiteVisitCount = () => (dispatch, getState) => {
  const visits = userPreferenceSiteVisitSelector(getState());
  return dispatch(
    updateUserPreference({
      [config.cookiesConfig.USER_EXTRAS.parameters.SITE_VISIT_COUNT.name]:
        visits ? visits + 1 : 1,
    })
  );
};
