import React, { Component } from 'react';
import classNames from 'classnames';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';

import { Click, ClickTracker, TRACK } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import JsonLD from 'components/JsonLD/JsonLD';
import Link from 'components/Link/Link';

import { generateBreadcrumbSchema } from './breadcrumb.helpers';

import styles from './Breadcrumbs.module.scss';

@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.BREADCRUMB(),
}))
export default class BreadCrumbs extends Component {
  static propTypes = {
    className: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        hidden: PropTypes.bool,
        isMetro: PropTypes.bool,
      })
    ),
    onMetroBreadcrumbClick: PropTypes.func,
  };

  breadcrumbClick(breadcrumb = {}) {
    if (breadcrumb.isMetro) {
      this.props.onMetroBreadcrumbClick();
    }
  }

  breadCrumbLink(breadcrumb = {}) {
    const { name, url, hidden } = breadcrumb;
    if (!name || !url || hidden) {
      return null;
    }
    const displayName = _startCase(name);
    return (
      <li key={name}>
        <Link
          to={url}
          clickTracker={
            new ClickTracker() /* Change this to target specific ClickTracker */
          }
          onClick={() => this.breadcrumbClick(breadcrumb)}
        >
          <h4 className={styles['bc-link-name']}>{displayName}</h4>
        </Link>
        <span className={styles['bc-delimiter']}>/</span>
      </li>
    );
  }

  renderBreadcrumbLinks() {
    const { breadcrumbs } = this.props;
    return (
      <ol className={styles['bc-link-list']}>
        {breadcrumbs.map((breadcrumb) => this.breadCrumbLink(breadcrumb))}
      </ol>
    );
  }

  render() {
    const { breadcrumbs, className } = this.props;

    if (!Array.isArray(breadcrumbs)) {
      return null;
    }

    return (
      <div className={classNames(className, styles['breadcrumbs-container'])}>
        <JsonLD json={generateBreadcrumbSchema(breadcrumbs)} />
        {this.renderBreadcrumbLinks()}
      </div>
    );
  }
}
