import { createSelector } from 'reselect';

import { PerformerInContext } from 'models';

import { getFilterKey } from './filterReducer';
import { sortPerformersInContextByDateAndName } from './utils';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _selectPerformersInContext = (state) => state.data.performersInContext;

const _selectPerformersInContextFilters = (state) =>
  state.dataFilters.performersInContext;
const _selectPerformersInContextIdsByFilters = (state, filters) => {
  const filterKey = getFilterKey(filters);
  return _selectPerformersInContextFilters(state)[filterKey];
};

export const selectPerformersInContextByFilters = createSelector(
  _selectPerformersInContext,
  _selectPerformersInContextIdsByFilters,
  (performersInContextObjects, performersInContextIds) => {
    if (!performersInContextIds) {
      return null;
    }

    return performersInContextIds
      .map(
        (performerId) =>
          new PerformerInContext(performersInContextObjects[performerId])
      )
      .sort(sortPerformersInContextByDateAndName);
  }
);

export const selectPerformersInContextByCategory = (state, category) =>
  selectPerformersInContextByFilters(state, { category });
