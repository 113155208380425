import React from 'react';
import classNames from 'classnames';

import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import { Review } from 'types';

import styles from './ReviewCardModal.module.scss';

interface ReviewCardModalProps {
  review: Review;
  onHide: VoidFunction;
  show: boolean;
}

const ReviewCardModal = ({ review, onHide, show }: ReviewCardModalProps) => {
  const reviewTitle = review.reviewer_name
    ? `${review.reviewer_name} Says:`
    : review.title;
  const starsClass = review.stars === 5 ? 'five-stars' : 'four-stars';

  return (
    <GTUniversalModal show={show} onHide={onHide}>
      <div className={styles['review-modal-container']}>
        <div className={styles['review-modal-body']}>
          <span className={styles['review-modal-detail']}>
            <div
              className={classNames(
                styles['review-modal-stars'],
                styles[starsClass]
              )}
            />
            <span className={styles['review-modal-date']}>{review.date}</span>
          </span>
          <span className={styles['review-modal-title']}>{reviewTitle}</span>
          <span className={styles['modal-review']}>{review.review}</span>
          <div className={styles['modal-button-container']}>
            <button className={styles['modal-close-button']} onClick={onHide}>
              Close
            </button>
          </div>
        </div>
      </div>
    </GTUniversalModal>
  );
};

export default ReviewCardModal;
