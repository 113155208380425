import React from 'react';
import classNames from 'classnames';

import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import IconButton from '../../IconButton';
import { DrawerHeaderProps } from '../Drawer.types';

import styles from './DrawerHeader.module.scss';

const DrawerHeader = ({
  title,
  subtitle,
  onClose,
  anchor = 'left',
  sectionName,
  children,
}: DrawerHeaderProps) => {
  return (
    <>
      <header className={styles['header']}>
        {onClose && (
          <IconButton
            icon={<ChevronIcon color={colors.white} direction={anchor} />}
            className={classNames(
              styles['header-button'],
              styles[`header-button-${anchor}`]
            )}
            onClick={onClose}
          />
        )}
        {title && <h2 className={styles['header-title']}>{title}</h2>}
        {subtitle && <p className={styles['header-subtitle']}>{subtitle}</p>}
        {children}
      </header>
      {sectionName && (
        <h3 className={styles['header-section-name']}>{sectionName}</h3>
      )}
    </>
  );
};

export default DrawerHeader;
