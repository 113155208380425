import { Review } from 'types';

import { ACTIONS } from './constants';

export interface ReviewsState {
  reviews: Review[];
}

const initialState: ReviewsState = {
  reviews: [],
};

interface GetReviewsRequestSuccessAction {
  type: typeof ACTIONS.GET_REVIEWS_REQUEST_SUCCESS;
  result: {
    reviews: Review[];
  };
}

type ActionTypes = GetReviewsRequestSuccessAction;

export default function reducer(
  state: ReviewsState = initialState,
  action: ActionTypes
): ReviewsState {
  switch (action.type) {
    case ACTIONS.GET_REVIEWS_REQUEST_SUCCESS:
      return {
        ...state,
        reviews: action.result.reviews,
      };
    default:
      return state;
  }
}
