import React from 'react';

import { PerformerClickTracker } from 'analytics';
import InfoCard from 'components/InfoCard/InfoCard';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import { Performer } from 'models';
import colors from 'styles/colors.constants';

import styles from './NoInventory.module.scss';

type NoInventoryProps = {
  primaryPerformer: Performer;
};

const NoInventory = ({ primaryPerformer }: NoInventoryProps) => {
  return (
    <InfoCard
      headline="Whoa! This Event is Popular!"
      subtext="No tickets available right now but check back soon."
      className={styles['no-inventory-card']}
    >
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        to={primaryPerformer.getPath()}
        clickTracker={new PerformerClickTracker(primaryPerformer)}
      >
        {`View All ${primaryPerformer.name} Events`}
      </ThemedButtonLoader>
    </InfoCard>
  );
};

export default NoInventory;
