export const isExcludedNode = (node, excluded) => {
  const targetTagName = node.tagName.toUpperCase();
  const isExcludedTag = excluded.find(
    (tag) => tag.toUpperCase() === targetTagName
  );

  if (isExcludedTag) return true;

  const isExcludedClassName = excluded.find((className) =>
    node.classList.contains(className)
  );

  if (isExcludedClassName) return true;

  return false;
};

export function getMousePosition(
  event,
  contentComponent,
  scale,
  isAreaZoom = false
) {
  const contentRect = contentComponent.getBoundingClientRect();

  let mouseX = 0;
  let mouseY = 0;

  if ('clientX' in event) {
    // mouse position x, y over wrapper component
    mouseX = (event.clientX - contentRect.left) / scale;
    mouseY = (event.clientY - contentRect.top) / scale;
  } else {
    if (isAreaZoom) {
      /* Released touches are found in changedTouches
           https://www.w3.org/TR/touch-events/#changedtouches-of-a-touchevent
        */
      const touch = event.changedTouches[0];
      mouseX = (touch.clientX - contentRect.left) / scale;
      mouseY = (touch.clientY - contentRect.top) / scale;
    } else {
      const touch = event.touches[0];
      mouseX = (touch.clientX - contentRect.left) / scale;
      mouseY = (touch.clientY - contentRect.top) / scale;
    }
  }

  if (isNaN(mouseX) || isNaN(mouseY))
    console.error('No mouse or touch offset found');

  return {
    x: mouseX,
    y: mouseY,
  };
}
