import * as React from 'react';

import { appContextReducer, initialState } from './AppReducer';
import { AppContextProps, AppContextType, WithAppContextProps } from './types';

const AppContext = React.createContext<AppContextType | undefined>(undefined);

function AppProvider({ children, store }: AppContextProps) {
  const [state, dispatch] = React.useReducer(appContextReducer, {
    ...initialState,
    ...store,
  });

  const contextValue = React.useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within the AppProvider');
  }
  return context;
}

function withAppContext<P extends WithAppContextProps>(
  Component: React.ComponentType<P>
) {
  const WithAppContextComponent = (
    props: Omit<P, keyof WithAppContextProps>
  ) => (
    <AppContext.Consumer>
      {(AppContext) => <Component {...(props as P)} appContext={AppContext} />}
    </AppContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithAppContextComponent.displayName = `withAppContext(${displayName})`;

  return WithAppContextComponent;
}

export { AppProvider, useAppContext, withAppContext };
