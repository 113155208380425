import React from 'react';
import classNames from 'classnames';

import {
  FireIcon,
  FlashIcon,
  SparklesIcon,
  StarLargeIcon,
  StarMediumIcon,
  StarSmallIcon,
} from 'icons';
import { LotDeal } from 'types';

import styles from './SponsorDealBadge.module.scss';

type SponsorDealVariant = Extract<
  LotDeal['deal'],
  'super' | 'flash' | 'best' | 'cheapest' | 'zone'
>;

interface SponsorDealBadgeProps {
  variant: SponsorDealVariant;
}

interface VariantProps {
  text: string;
  Icon: typeof FlashIcon;
}

export const variants: Record<SponsorDealVariant, VariantProps> = {
  super: {
    text: 'Super Deal',
    Icon: StarLargeIcon,
  },
  flash: {
    // "flash" --> "amazing"
    text: 'Amazing Deal',
    Icon: StarMediumIcon,
  },
  best: {
    // "best" --> "great"
    text: 'Great Deal',
    Icon: StarSmallIcon,
  },
  cheapest: {
    text: 'Cheapest',
    Icon: FireIcon,
  },
  zone: {
    text: 'Zone Deal',
    Icon: SparklesIcon,
  },
};

/**
 * @deprecated use ui/Tag and deals from `/v1/deals` instead
 */
const SponsorDealBadge = ({ variant }: SponsorDealBadgeProps) => {
  if (!(variant in variants)) {
    return null;
  }

  const variantProps = variants[variant];

  return (
    <div
      className={classNames(
        styles['sponsor-deal-badge'],
        styles[`deal-${variant}`]
      )}
    >
      <variantProps.Icon width="16" height="16" />
      {variantProps.text}
    </div>
  );
};

export default SponsorDealBadge;
