import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import CategoryPerformersList from './CategoryPerformersList';
import { SHOWS_SUB_CATEGORIES } from './constants';

import styles from './CategoriesDropdown.module.scss';

const ShowsCategories = ({ limit, onClick, currentMetro }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState('comedy');
  const [selectSubCategoryInterval, setSelectSubCategoryInterval] =
    useState(null);

  const handleMouseEnter = (event) => {
    const category = event.target.getAttribute('data-category');
    if (selectSubCategoryInterval) {
      clearTimeout(selectSubCategoryInterval);
      setSelectSubCategoryInterval(null);
    }

    setSelectSubCategoryInterval(
      setTimeout(() => {
        setSelectedSubcategory(category);
      }, 200)
    );
  };

  const handleMouseLeave = () => {
    if (selectSubCategoryInterval) {
      clearTimeout(selectSubCategoryInterval);
      setSelectSubCategoryInterval(null);
    }
  };

  const renderPerformersList = () => {
    return SHOWS_SUB_CATEGORIES.map((category) => (
      <div
        key={category}
        className={classNames({
          [styles.hide]: category !== selectedSubcategory,
        })}
      >
        <CategoryPerformersList
          category={category}
          limit={limit}
          onClick={onClick}
          metro={currentMetro.id}
        />
      </div>
    ));
  };

  return (
    <>
      <div className={styles['subcategories-list-container']}>
        <Container disableGutters>
          <div className={styles['subcategories-list']}>
            {SHOWS_SUB_CATEGORIES.map((category) => (
              <span
                key={category}
                className={classNames(styles.subcategory, {
                  [styles.selected]: selectedSubcategory === category,
                })}
                data-category={category}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {category}
              </span>
            ))}
          </div>
        </Container>
      </div>
      <div className={styles['subcategory-performers']}>
        {renderPerformersList()}
      </div>
    </>
  );
};

ShowsCategories.propTypes = {
  onClick: PropTypes.func,
  limit: PropTypes.number,
  currentMetro: PropTypes.object.isRequired,
};

export default ShowsCategories;
