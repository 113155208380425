import React from 'react';

export default ({fill = '#F0F0F0'}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12 0C10.8181 -7.00383e-07 9.64778 0.236059 8.55585 0.694701C7.46392 1.15334 6.47176 1.82558 5.63604 2.67305C4.80031 3.52051 4.13737 4.52659 3.68508 5.63385C3.23279 6.74111 3 7.92786 3 9.12635C3 15.7383 11.1648 24 12 24C12.8352 24 21 15.7383 21 9.12635C21 7.92786 20.7672 6.74111 20.3149 5.63385C19.8626 4.52659 19.1997 3.52051 18.364 2.67305C17.5282 1.82558 16.5361 1.15334 15.4442 0.694701C14.3522 0.236059 13.1819 -7.00383e-07 12 0ZM12 13.1003C11.2249 13.1003 10.4672 12.8672 9.82275 12.4306C9.17828 11.9939 8.67598 11.3733 8.37937 10.6471C8.08275 9.92097 8.00514 9.12194 8.15636 8.35107C8.30757 7.5802 8.68081 6.8721 9.22889 6.31634C9.77696 5.76057 10.4752 5.38208 11.2354 5.22875C11.9957 5.07541 12.7836 5.15411 13.4997 5.45489C14.2158 5.75567 14.8279 6.26502 15.2585 6.91854C15.6891 7.57205 15.9189 8.34038 15.9189 9.12635C15.9189 9.64822 15.8176 10.165 15.6206 10.6471C15.4237 11.1293 15.135 11.5673 14.7711 11.9364C14.4072 12.3054 13.9752 12.5981 13.4997 12.7978C13.0242 12.9975 12.5146 13.1003 12 13.1003Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
