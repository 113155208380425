import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HorizontalScroll from 'ui/HorizontalScroll/HorizontalScroll';

import { Click, PerformerClickTracker } from 'analytics';
import { getPillDisplayName } from 'store/modules/categories/category.helpers';
import { makeGetSelectPerformersByCategoryGroup } from 'store/modules/data/Performers/selectors';

import MetroPill from './MetroPill';

import styles from './MetroEvents.module.scss';

const MIN_PIXELS = 8.5;

function mapStateToProps(state) {
  const selectTrendingPerformersByCategoryGroup =
    makeGetSelectPerformersByCategoryGroup();
  return {
    performers: selectTrendingPerformersByCategoryGroup(state, 'sport'),
  };
}

function MetroEvents({ performers, currentMetro }) {
  return (
    <div className={styles['metro-events-container']}>
      <HorizontalScroll key={currentMetro.id}>
        {performers.map((p, i) => {
          const pillTracker = new PerformerClickTracker(p).interaction(
            Click.INTERACTIONS.CAROUSEL(),
            { collection: 'Pill', item_index: i }
          );
          const { name, abbrev, category } = p;

          const displayName = getPillDisplayName(name, abbrev, category);
          let width = displayName.length * MIN_PIXELS + 32;
          if (displayName && displayName.length <= 6) {
            width = MIN_PIXELS * 10;
          }

          return (
            <HorizontalScroll.Card itemId={p.id} key={p.id}>
              <MetroPill
                key={p.id}
                style={{ width }}
                clickTracker={pillTracker}
                name={name}
                displayName={displayName}
                to={p.getPath()}
              />
            </HorizontalScroll.Card>
          );
        })}
      </HorizontalScroll>
    </div>
  );
}

MetroEvents.propTypes = {
  performers: PropTypes.array.isRequired,
  currentMetro: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(MetroEvents);
