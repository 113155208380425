export const surveySelector = (state) => state.content.survey;

export const surveyQuestionsSelector = (state) => {
  const survey = surveySelector(state);
  const postPurchaseSurveyFields = survey?.['post-purchase-survey-v2'];

  return postPurchaseSurveyFields?.questions;
};

export const surveyOptionsSelector = (state) => {
  const survey = surveySelector(state);
  const postPurchaseSurveyFields = survey?.['post-purchase-survey-v2'];

  return postPurchaseSurveyFields?.options;
};
