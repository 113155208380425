import React from 'react';

import styles from './PicksCard.module.scss';

interface PicksCardProps {
  number: string;
  message: string;
  emoji: string;
  image: string;
}

const PicksCard = ({ number, message, emoji, image }: PicksCardProps) => {
  return (
    <div
      className={styles['picks-card']}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <span className={styles['card-top']}>
        <span className={styles['card-emoji']}>{emoji}</span>
        <span className={styles['card-top-number']}>{number}</span>
      </span>
      <p className={styles['card-message']}>{message}</p>
    </div>
  );
};

export default PicksCard;
