import React from 'react';
import classNames from 'classnames';
import IconButton from 'ui/IconButton';

import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import styles from './InfoHeader.module.scss';

interface Props {
  headerContent: React.ReactNode;
  showBack?: boolean;
  onBack: () => void;
  backIconColor?: string;
  bold?: boolean;
  isDarkTheme?: boolean;
}

const InfoHeader = ({
  headerContent,
  showBack = false,
  onBack,
  backIconColor = colors.white,
  bold = false,
  isDarkTheme = false,
}: Props) => {
  return (
    <div
      className={classNames(styles['header-section'], {
        [styles.dark]: isDarkTheme,
        [styles.bold]: bold,
      })}
    >
      <div className={styles['content-wrapper']}>
        {showBack && (
          <IconButton
            className={styles['back-button']}
            onClick={onBack}
            icon={<ChevronIcon color={backIconColor} direction="left" />}
          />
        )}
        <div className={styles.content}>{headerContent}</div>
      </div>
    </div>
  );
};

export default InfoHeader;
