import React from 'react';

import colors from 'styles/colors.constants';

export default function CaretDownIcon({
  width = '16',
  height = '16',
  fill = colors.white,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42678 7.42678C4.26928 7.26928 4.38083 7 4.60355 7H11.3964C11.6192 7 11.7307 7.26929 11.5732 7.42678L8.35355 10.6464C8.15829 10.8417 7.84171 10.8417 7.64645 10.6464L4.42678 7.42678Z"
        fill={fill}
      />
    </svg>
  );
}
