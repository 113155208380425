import React from 'react';

import colors from 'styles/colors.constants';

interface StarLargeIconProps {
  width?: React.SVGAttributes<SVGSVGElement>['width'];
  height?: React.SVGAttributes<SVGSVGElement>['height'];
  fill?: React.SVGProps<SVGPathElement>['fill'];
}

export default function StarLargeIcon({
  width = '16',
  height = '16',
  fill = colors.black,
}: StarLargeIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="star-large-icon"
    >
      <path
        d="M6.99858 0.684526C7.40206 -0.155342 8.59795 -0.155339 9.00144 0.684528L10.5708 3.95125C10.7328 4.28837 11.0535 4.52138 11.4241 4.57123L15.0159 5.05432C15.9394 5.17852 16.3089 6.31589 15.6349 6.95915L13.013 9.46118C12.7424 9.71939 12.6199 10.0964 12.687 10.4643L13.3375 14.0296C13.5048 14.9463 12.5373 15.6492 11.7172 15.2069L8.5274 13.4865C8.19822 13.309 7.8018 13.309 7.47261 13.4865L4.28284 15.2069C3.46275 15.6492 2.49525 14.9463 2.66249 14.0296L3.31297 10.4643C3.3801 10.0964 3.2576 9.71939 2.98703 9.46118L0.365153 6.95915C-0.308925 6.31588 0.0606294 5.17852 0.984074 5.05432L4.57587 4.57123C4.94654 4.52138 5.26725 4.28837 5.42921 3.95124L6.99858 0.684526Z"
        fill={fill}
      />
    </svg>
  );
}
