import React from 'react';
export default function EmailFillIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      fill="#969696"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M11.43 11.593L.01 5.523a2.763 2.763 0 0 1 2.75-2.52h18.49a2.74 2.74 0 0 1 2.74 2.52l-11.43 6.08c-.35.18-.77.19-1.13-.01" />
        <path d="M24 7.213v11.07c0 .72-.28 1.4-.8 1.92-.51.51-1.2.8-1.92.8H2.74c-.73 0-1.42-.29-1.94-.81-.51-.51-.8-1.2-.8-1.93V7.213l10.73 5.71c.4.21.83.32 1.27.32.44 0 .87-.11 1.27-.32L24 7.213z" />
      </g>
    </svg>
  );
}
