import React from 'react';

import {
  DeliveryFormat,
  getDeliveryDisplayProps,
} from 'components/DeliveryFormat/delivery.format.constants';
import DeliveryIcon from 'components/DeliveryFormat/DeliveryIcon/DeliveryIcon';
import { device, useMediaQuery } from 'hooks';
import colors from 'styles/colors.constants';

import styles from './DeliveryBadge.module.scss';

interface DeliveryBadgeProps {
  deliveryFormat: DeliveryFormat;
}

const DeliveryBadge = ({ deliveryFormat }: DeliveryBadgeProps) => {
  const isMobile = useMediaQuery(device.down.md);
  const deliveryDisplayProps = getDeliveryDisplayProps(deliveryFormat);

  return (
    <div className={styles['ticket-type']}>
      <DeliveryIcon
        deliveryFormat={deliveryFormat}
        iconProps={{ fill: colors.white }}
      />
      <span className={styles.text}>
        {deliveryDisplayProps.title.join(isMobile ? '\n' : ' ')}
      </span>
    </div>
  );
};

export default DeliveryBadge;
