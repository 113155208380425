import React from 'react';

import xcoverLogo from 'assets/xcover-logo.png';
import SimpleLinkButton from 'components/Buttons/SimpleLinkButton';
import { InsuranceInfo } from 'types';

import styles from './DetailInsuranceCard.module.scss';

interface DetailInsuranceCardProps {
  insuranceInfo: InsuranceInfo;
}

function DetailInsuranceCard({ insuranceInfo }: DetailInsuranceCardProps) {
  // normally we might want to conditionally render from the parent, but the
  // parent is not written in TypeScript, so to get type safety we will check
  // the status here
  if (insuranceInfo.status !== 'confirmed') {
    return null;
  }

  return (
    <div className={styles['detail-insurance-card']}>
      <div className={styles['text']}>
        <h4 className={styles['heading']}>Your Purchase Protection</h4>
        <span className={styles['subtitle']}>
          Your purchase is protected. Check your email for details about your
          booking from XCover.
        </span>
      </div>
      <div className={styles['cta-container']}>
        <div className={styles['fluid-img-container']}>
          <img
            className={styles['xcover-logo']}
            src={xcoverLogo}
            alt="xcover logo"
          />
        </div>
        <SimpleLinkButton
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.xcover.com/en-us"
          className={styles['cta']}
          type="white_medium"
          text="Learn More"
        />
      </div>
    </div>
  );
}

export default DetailInsuranceCard;
