import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  json: PropTypes.object,
};

const JsonLD = ({ json }) => {
  if (json) {
    return (
      <script
        type="application/ld+json"
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
      />
    );
  }

  return null;
};

JsonLD.propTypes = propTypes;

export default JsonLD;
