import React from 'react';
import PropTypes from 'prop-types';

import { MetroClickTracker } from 'analytics';
import InfoCard from 'components/InfoCard/InfoCard';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import colors from 'styles/colors.constants';

import styles from './NoEventsCard.module.scss';

const headline = {
  nearby: 'No events nearby',
  all: 'Dang! No events currently scheduled.',
  venue: 'Dang! No events currently scheduled.',
};

const subtext = {
  all: 'Check back here for updates or discover other events near you.',
  venue: 'Check back here for updates or discover other events near you.',
};

const NoEventsCard = ({ metro, eventGroup }) => {
  const renderThemedButton = () => {
    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        to={getMetroPerformersPathByMetro(metro) || '/'}
        clickTracker={new MetroClickTracker(metro)}
      >
        {eventGroup ? 'DISCOVER EVENTS NEARBY' : 'Recommended Events'}
      </ThemedButtonLoader>
    );
  };

  return (
    <>
      {eventGroup ? (
        <div className={styles['card-container']}>
          <div className={styles['text-container']}>
            <h3 className={styles.headline}>{headline[eventGroup]}</h3>
            {subtext[eventGroup] && (
              <p className={styles.subtext}>{subtext[eventGroup]}</p>
            )}
          </div>
          <div className={styles['button-container']}>
            {renderThemedButton()}
          </div>
        </div>
      ) : (
        <InfoCard
          headline="Dang! No events are currently scheduled"
          subtext="Check back soon for updates, or find another event"
        >
          {renderThemedButton()}
        </InfoCard>
      )}
    </>
  );
};

NoEventsCard.propTypes = {
  metro: PropTypes.object.isRequired,
  eventGroup: PropTypes.oneOf(['nearby', 'all', 'venue']),
};

export default NoEventsCard;
