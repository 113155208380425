import { useCallback } from 'react';

const useListMapHarmony = (listings) => {
  /*
    Reset all pins to visible on the map.
  */
  const resetPinsToVisible = useCallback(() => {
    if (!listings) return;
    const resetPinObserversToVisible = () => {
      listings.forEach((item) => {
        if (item) {
          item.isVisibleInMap = true;
        }
      });
    };
    resetPinObserversToVisible();
  }, [listings]);

  return {
    resetPinsToVisible,
  };
};

export default useListMapHarmony;
