import { normalize } from 'normalizr';

import normalizerMerge from 'store/modules/helpers/normalizrMerge';

import { RESOURCE_ACTION_TYPES } from './resource.actions';
import { MetrosSchema } from './schemas';

const initialState = {
  performers: {},
  allDeals: {},
  allDisclosures: {},
  events: {},
  listings: {},
  venues: {},
  metros: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESOURCE_ACTION_TYPES.FETCH_METROS_SUCCESS:
      return normalizerMerge(
        state,
        normalize(action.result.metros, MetrosSchema)
      );

    case RESOURCE_ACTION_TYPES.FETCH_DISCLOSURES_SUCCESS:
      return {
        ...state,
        allDisclosures: action.result,
      };

    case RESOURCE_ACTION_TYPES.FETCH_DEALS_SUCCESS:
      return {
        ...state,
        allDeals: action.result,
      };

    default:
      return state;
  }
}
