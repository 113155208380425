import { STATUS_NAMES } from 'analytics';

export const GAMETIME_PHONE = '1-888-355-0132';

// constant -> route
export const ERRORS = {
  CREATE_USER_PURCHASE_FAILED: 'create-user-purchase-failed',
  UPDATE_PHONE_FAILED: 'update-phone-failed',
  UPDATE_PAYMENT_ADDRESS_FAILED: 'update-payment-address-failed',
  UPDATE_DELIVERY_ADDRESS_FAILED: 'update-delivery-address-failed',
  TRANSACTION_INCOMPLETE_CALL: 'transaction-incomplete-call',
  TRANSACTION_UNSUCCESSFUL_CARD: 'transaction-unsuccessful-card',
  TRANSACTION_UNSUCCESSFUL_CALL: 'transaction-unsuccessful-call',
  TRANSACTION_UNSUCCESSFUL_RETURN_BROWSE: 'transaction-unsuccessful-return',
  TRANSACTION_UNSUCCESSFUL_RETURN_LISTING:
    'transaction-unsuccessful-return-listing',
  THIRD_PARTY_COLLECTION_FAILED: 'ticket-master-collection-failed',
  TEXT_MESSAGE_COLLECTION_FAILED: 'text-message-collection-failed',
};

export const EVENT_ROUTE = 'event';
export const CHECKOUT_ROUTE = 'checkout';

const ERROR_PAGE_TYPE = {
  GENERAL_ERROR: {
    title: 'Whoops!',
    subTitle:
      "Something went wrong, and we couldn't process your purchase. Let's give it another shot.",
    buttonText: 'Try again',
    nextPage: CHECKOUT_ROUTE,
    buttonAction: 'purchase_try_again',
    status: STATUS_NAMES.PURCHASE_FLOW_CREATION_FAILURE,
  },
  TRANSACTION_UNSUCCESSFUL_CARD: {
    title: 'Transaction Unsuccessful',
    subTitle:
      "Unfortunately, we weren't able to process your credit card. We recommend that you " +
      'contact your bank to authorize the transaction, or try a different payment method.',
    buttonText: 'Try Again',
    nextPage: CHECKOUT_ROUTE,
    buttonAction: 'purchase_transaction_change_card',
    status: STATUS_NAMES.SHOW_ADD_CARD,
  },
  TRANSACTION_UNSUCCESSFUL_CALL: {
    title: 'Transaction Unsuccessful',
    subTitle:
      'Your purchase cannot be completed at this time. For assistance, please call us at 888-355-0132.',
    buttonText: 'Call Gametime',
    buttonType: 'tel',
    buttonAction: 'purchase_transaction_fail_call',
    status: STATUS_NAMES.CALL_SUPPORT,
  },
  PURCHASE_INFO_FAILURE: {
    title: 'Whoops!',
    subTitle:
      "Something went wrong, and we couldn't process your information. Let's give it another shot.",
    buttonText: 'Try Again',
    stayOnPage: true,
    buttonAction: 'purchase_info_try_again',
    status: STATUS_NAMES.PURCHASE_INFO_SUBMIT_FAILURE,
  },
  CALL_US: {
    title: 'Give us a call',
    subTitle:
      'Looks like the network connection lapsed during your purchase. ' +
      'Please call us at 888-355-0132 to verify that your order has been completed.',
    buttonText: 'Call Gametime',
    buttonType: 'tel',
    buttonAction: 'purchase_transaction_fail_call',
    status: STATUS_NAMES.CALL_SUPPORT,
  },
  RETURN_BROWSE: {
    title: 'Someone beat you to the punch',
    subTitle:
      "This event is popular! Looks like these tickets are taken - let's try again.",
    buttonText: 'Find Another',
    nextPage: EVENT_ROUTE,
    buttonAction: 'purchase_browse_again',
    status: STATUS_NAMES.SHOW_TEAM_LISTINGS,
  },
  RETURN_LISTING: {
    title: 'Whoops!',
    subTitle:
      "Something went wrong, and we couldn't process your purchase. Let's give it another shot.",
    buttonText: 'Try Again',
    buttonAction: 'purchase_listing_again',
    status: STATUS_NAMES.SHOW_LISTING_DETAIL,
  },
};

// route -> page props
export const ERROR_PAGES_MAPPING = {
  [ERRORS.CREATE_USER_PURCHASE_FAILED]: ERROR_PAGE_TYPE.GENERAL_ERROR,
  [ERRORS.UPDATE_PHONE_FAILED]: ERROR_PAGE_TYPE.PURCHASE_INFO_FAILURE,
  [ERRORS.UPDATE_PAYMENT_ADDRESS_FAILED]: ERROR_PAGE_TYPE.PURCHASE_INFO_FAILURE,
  [ERRORS.UPDATE_DELIVERY_ADDRESS_FAILED]:
    ERROR_PAGE_TYPE.PURCHASE_INFO_FAILURE,
  [ERRORS.TRANSACTION_INCOMPLETE_CALL]: ERROR_PAGE_TYPE.CALL_US,
  [ERRORS.TRANSACTION_UNSUCCESSFUL_CARD]:
    ERROR_PAGE_TYPE.TRANSACTION_UNSUCCESSFUL_CARD,
  [ERRORS.TRANSACTION_UNSUCCESSFUL_CALL]:
    ERROR_PAGE_TYPE.TRANSACTION_UNSUCCESSFUL_CALL,
  [ERRORS.TRANSACTION_UNSUCCESSFUL_RETURN_BROWSE]:
    ERROR_PAGE_TYPE.RETURN_BROWSE,
  [ERRORS.TRANSACTION_UNSUCCESSFUL_RETURN_LISTING]:
    ERROR_PAGE_TYPE.RETURN_LISTING,
  [ERRORS.THIRD_PARTY_COLLECTION_FAILED]: ERROR_PAGE_TYPE.PURCHASE_INFO_FAILURE,
  [ERRORS.TEXT_MESSAGE_COLLECTION_FAILED]:
    ERROR_PAGE_TYPE.PURCHASE_INFO_FAILURE,
};
