import config from 'config/production.config';
import { Performer } from 'models';
import { getSportNameFromCategory } from 'store/modules/categories/category.helpers';

import { SportsTeamJsonLD } from './types';

const getSport = (category: string): string | undefined => {
  if (!category.trim()) {
    return;
  }

  const upperCaseCategory = category.toUpperCase();
  const sportName = getSportNameFromCategory(category);
  return category !== sportName
    ? `${upperCaseCategory} ${sportName}`
    : upperCaseCategory;
};

const getSportsTeamJsonLD = (
  performer: Performer
): SportsTeamJsonLD | undefined => {
  const sport = getSport(performer.category);

  if (!sport) {
    return;
  }

  const sportsTeam: SportsTeamJsonLD = {
    '@context': 'https://schema.org',
    '@type': 'SportsTeam',
    name: performer.name,
    sport,
    url: `https://${config.domain}${performer.getPath()}`,
  };

  if (performer.logoImageUrl) {
    sportsTeam.logo = performer.logoImageUrl;
  }

  return sportsTeam;
};

export default getSportsTeamJsonLD;
