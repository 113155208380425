import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height'>;

export default function PayPalCardIcon({ width = '24', height = '24' }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="4.25"
        width="21.5"
        height="15.5"
        rx="0.75"
        fill="white"
        stroke="#C8C8C8"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4057 16.7149L10.5789 15.6079L10.1932 15.5989H8.35132L9.63131 7.43055C9.63528 7.40589 9.6482 7.3829 9.66707 7.36657C9.68594 7.35024 9.71011 7.34125 9.73527 7.34125H12.8409C13.8719 7.34125 14.5834 7.55718 14.9549 7.98337C15.1291 8.18331 15.24 8.39224 15.2936 8.62217C15.3499 8.86342 15.3509 9.15166 15.2959 9.50322L15.292 9.52887V9.75414L15.4661 9.85344C15.6128 9.93175 15.7293 10.0214 15.8187 10.124C15.9677 10.295 16.0641 10.5122 16.1048 10.7698C16.1468 11.0347 16.1329 11.35 16.0641 11.7068C15.9846 12.1174 15.8561 12.4749 15.6826 12.7675C15.5231 13.0371 15.3198 13.2607 15.0784 13.4339C14.848 13.5986 14.5742 13.7235 14.2646 13.8035C13.9646 13.8821 13.6226 13.9218 13.2475 13.9218H13.0058C12.8329 13.9218 12.6651 13.9844 12.5333 14.0967C12.4012 14.2114 12.3138 14.368 12.287 14.5393L12.2688 14.6389L11.9628 16.5899L11.9489 16.6616C11.9453 16.6842 11.939 16.6956 11.9297 16.7032C11.9215 16.7102 11.9095 16.7149 11.898 16.7149H10.4057Z"
        fill="#253B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.631 9.55487C15.6217 9.61452 15.6111 9.6755 15.5992 9.73814C15.1897 11.8545 13.7885 12.5856 11.999 12.5856H11.0878C10.8689 12.5856 10.6845 12.7455 10.6504 12.9628L10.1839 15.9405L10.0518 16.7845C10.0296 16.9272 10.1389 17.0558 10.2819 17.0558H11.898C12.0893 17.0558 12.2519 16.9158 12.282 16.7259L12.2979 16.6432L12.6022 14.6999L12.6217 14.5932C12.6515 14.4026 12.8144 14.2627 13.0058 14.2627H13.2475C14.8132 14.2627 16.0389 13.6229 16.3972 11.7715C16.5468 10.9981 16.4693 10.3523 16.0733 9.89809C15.9535 9.76114 15.8048 9.64751 15.631 9.55487Z"
        fill="#179BD7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2025 9.38288C15.14 9.36455 15.0754 9.34789 15.0092 9.33289C14.9426 9.31823 14.8744 9.30523 14.8042 9.29391C14.5586 9.25392 14.2894 9.23492 14.001 9.23492H11.5668C11.5069 9.23492 11.45 9.24859 11.399 9.27325C11.2867 9.32756 11.2033 9.43453 11.1831 9.56548L10.6653 12.8664L10.6504 12.9627C10.6845 12.7455 10.8689 12.5855 11.0878 12.5855H11.9989C13.7885 12.5855 15.1896 11.8541 15.5992 9.7381C15.6114 9.67545 15.6217 9.61447 15.631 9.55482C15.5273 9.49951 15.4151 9.45219 15.2943 9.41187C15.2645 9.40187 15.2337 9.39221 15.2025 9.38288Z"
        fill="#222D65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1831 9.56551C11.2033 9.43455 11.2867 9.32758 11.399 9.2736C11.4503 9.24894 11.5069 9.23528 11.5668 9.23528H14.001C14.2894 9.23528 14.5586 9.25427 14.8042 9.29426C14.8744 9.30559 14.9426 9.31859 15.0092 9.33325C15.0754 9.34824 15.1399 9.3649 15.2025 9.38323C15.2336 9.39256 15.2644 9.40222 15.2946 9.41189C15.4154 9.45221 15.5277 9.49986 15.6313 9.55484C15.7531 8.77276 15.6303 8.24027 15.2101 7.75809C14.7469 7.22726 13.9109 7 12.8412 7H9.73557C9.51705 7 9.33065 7.15995 9.29687 7.37754L8.00331 15.6299C7.97781 15.7932 8.10297 15.9404 8.26652 15.9404H10.1839L10.6653 12.8664L11.1831 9.56551Z"
        fill="#253B80"
      />
    </svg>
  );
}
