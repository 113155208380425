import { combineReducers } from 'redux';

import categories from './categories/reducer';
import global from './global/reducer';
import mlb from './mlb/reducer';
import performers from './performers/reducer';
import promoCodes from './promoCodes/reducer';
import staticPages from './static/reducer';
import survey from './survey/reducer';
import venues from './venues/reducer';

export default combineReducers({
  performers,
  venues,
  staticPages,
  categories,
  global,
  survey,
  promoCodes,
  mlb,
});
