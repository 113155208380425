import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './InfoCard.module.scss';

const InfoCard = (props) => {
  const { iconPath, headline, subtext, className, mobileColor, children } =
    props;

  const mobileBackground = mobileColor && { background: mobileColor };
  return (
    <div
      className={classNames(styles['info-card-content'], className)}
      style={mobileBackground}
    >
      {iconPath && (
        <div className={styles['info-card-icon']}>
          <img src={iconPath} alt="" />
        </div>
      )}
      <div className={styles['info-card-text']}>
        {headline && <h3>{headline}</h3>}
        {subtext && <p>{subtext}</p>}
        {children}
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  iconPath: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  className: PropTypes.string,
  mobileColor: PropTypes.string,
  children: PropTypes.node,
};

export default InfoCard;
