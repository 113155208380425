import React from 'react';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 1C15.1119 1 15.0011 1.11232 14.9897 1.24992C14.8627 2.78984 13.5727 4 12 4C10.4273 4 9.13726 2.78984 9.01026 1.24992C8.99891 1.11232 8.88807 1 8.75 1H6C5.44772 1 5 1.44772 5 2V22C5 22.5523 5.44772 23 6 23H8.75C8.88807 23 8.99891 22.8877 9.01026 22.7501C9.13726 21.2102 10.4273 20 12 20C13.5727 20 14.8627 21.2102 14.9897 22.7501C15.0011 22.8877 15.1119 23 15.25 23H18C18.5523 23 19 22.5523 19 22V2C19 1.44772 18.5523 1 18 1H15.25ZM8 9.5C8 9.22386 8.22386 9 8.5 9H15.5C15.7761 9 16 9.22386 16 9.5V10.5C16 10.7761 15.7761 11 15.5 11H8.5C8.22386 11 8 10.7761 8 10.5V9.5ZM8.5 13C8.22386 13 8 13.2239 8 13.5V14.5C8 14.7761 8.22386 15 8.5 15H15.5C15.7761 15 16 14.7761 16 14.5V13.5C16 13.2239 15.7761 13 15.5 13H8.5Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);
