import React from 'react';
import classNames from 'classnames';

import Spinner from 'components/Spinner/Spinner';
import { CaretDownIcon, CaretUpIcon } from 'icons';

import styles from './PaginationButton.module.scss';

interface PaginationButtonProps {
  label?: string;
  loading?: boolean;
  isExpanded?: boolean;
  onClick: () => void;
  showSeparator?: boolean;
}

const PaginationButton = ({
  label = 'Show More',
  loading,
  isExpanded,
  onClick,
  showSeparator,
}: PaginationButtonProps) => {
  return (
    <div
      className={classNames(styles['pagination-button'], {
        [styles['show-separator']]: showSeparator,
      })}
    >
      {loading ? (
        <div className={styles['spinner-container']}>
          <Spinner />
        </div>
      ) : (
        <button
          type="button"
          className={styles['show-button']}
          onClick={onClick}
        >
          {label}
          {isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}
        </button>
      )}
    </div>
  );
};

export default PaginationButton;
