import { FullEvent, Performer } from 'models';

import { PERFORMER_TYPES } from './constants';
import EventSchema from './EventSchema';
import { PerformerSchema } from './types';

export class TheaterEventSchema extends EventSchema {
  constructor(fullEvent: FullEvent) {
    super(fullEvent);
  }

  getPerformerSchema(performer: Performer): PerformerSchema {
    const basePerformer = super.getPerformerSchema(performer);
    return {
      ...basePerformer,
      '@type': PERFORMER_TYPES.THEATER_GROUP,
    };
  }
}
