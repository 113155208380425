import { HttpClient } from 'services/HttpClient/HttpClient';
import { SearchResponse } from 'types';

import { paramsToString, SearchRequest } from './search.service.utils';

export const getSearchResults = (
  httpClient: HttpClient,
  { searchParams, headers }: SearchRequest
) => {
  const urlSearchParams = paramsToString(searchParams);

  return httpClient.request<SearchResponse>({
    path: '/v1/search',
    searchParams: urlSearchParams,
    headers,
  });
};
