import React from 'react';
import { format, getYear, isToday, isTomorrow, parseISO } from 'date-fns';

import { FullEvent } from 'models';
import {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_TBD,
  DAY_DATE_FORMAT,
  DAY_DATE_YEAR_FORMAT,
  TIME_FORMAT,
} from 'utils/datetime';

interface EventDateTimeProps {
  fullEvent: FullEvent;
  type?: 'DOTTED' | 'DOTTED_SEMANTIC';
}

const EventDateTime = ({ fullEvent, type }: EventDateTimeProps) => {
  const parsedDate = parseISO(fullEvent.event.datetimeLocal);

  const getDate = (date: Date) => {
    const currentDate = new Date();
    const dateFormat =
      getYear(date) === getYear(currentDate)
        ? DAY_DATE_FORMAT
        : DAY_DATE_YEAR_FORMAT;

    if (fullEvent.event.time_tbd) {
      return format(date, `${dateFormat} ⋅ 'Time TBD'`);
    }

    return format(date, `${dateFormat} ⋅ ${TIME_FORMAT}`);
  };

  const getSemanticDate = (date: Date) => {
    if (isToday(date)) return `Today ⋅ ${format(date, TIME_FORMAT)}`;
    if (isTomorrow(date)) return `Tomorrow ⋅ ${format(date, TIME_FORMAT)}`;

    return getDate(date);
  };

  const getFormattedDate = () => {
    if (type === 'DOTTED') return getDate(parsedDate);
    if (type === 'DOTTED_SEMANTIC') return getSemanticDate(parsedDate);
    if (fullEvent.event.tbd) return format(parsedDate, DATE_FORMAT_TBD);

    return format(parsedDate, DATE_FORMAT_DEFAULT);
  };

  return <span>{getFormattedDate()}</span>;
};

export default EventDateTime;
