import React from 'react';

export default ({ width = 24, height = 24, fill = '#ffffff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H13V3H11V4L7 4ZM11 4V13.9035L8.05983 10.6797C7.86767 10.469 7.53849 10.4614 7.33685 10.6631L6.64639 11.3536C6.45112 11.5488 6.45112 11.8654 6.64639 12.0607L11.2928 16.7071C11.6834 17.0976 12.3165 17.0976 12.707 16.7071L17.3535 12.0607C17.5488 11.8654 17.5488 11.5488 17.3535 11.3536L16.663 10.6631C16.4614 10.4615 16.1322 10.469 15.94 10.6797L13 13.9035V4H11Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0C12.7761 0 13 0.223858 13 0.5V4L11 4V0.5C11 0.223858 11.2239 0 11.5 0H12.5Z"
      fill={fill}
    />
  </svg>
);
