import { createSelector, createStructuredSelector } from 'reselect';

import {
  selectIsCaliforniaVenueAllInPriceEnabled,
  selectIsColoradoVenueAllInPriceEnabled,
  selectIsMarylandVenueAllInPriceEnabled,
} from 'featureFlags';

export const isLockedCarouselHoveredSelector = (state) => {
  return state.data.listings.isLockedCarouselHovered;
};

export const listMapHarmonyToggleSelector = (state) => {
  return state.data.listings.listMapHarmonyToggleIsOn;
};

export const updatingMapHarmonySelector = (state) => {
  return state.data?.listings.isUpdatingMapHarmony;
};
export const isZoneUnlockedSelector = (state, eventId) => {
  const unlockedZoneDealEvents =
    state.data.listings.unlockedZoneDealEvents || [];
  return unlockedZoneDealEvents.includes(eventId);
};

export const hoveredListingIdSelector = (state) => {
  return state.data.listings.hoveredListingId;
};

const selectAllInPriceByState = createStructuredSelector({
  CA: selectIsCaliforniaVenueAllInPriceEnabled,
  MD: selectIsMarylandVenueAllInPriceEnabled,
  CO: selectIsColoradoVenueAllInPriceEnabled,
});

export const selectIsVenueAllInPrice = createSelector(
  selectAllInPriceByState,
  (_, venueState) => venueState,
  (allInPriceByState, venueState) => {
    return allInPriceByState[venueState] ?? false;
  }
);
