import React from 'react';
import IconButton from 'ui/IconButton';

import { InfoIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './IncludesFees.module.scss';

type Props = {
  onClick: () => void;
};

const IncludesFees = ({ onClick }: Props) => (
  <div className={styles['includes-fees-container']}>
    <IconButton
      onClick={onClick}
      icon={<InfoIcon width="16" height="16" fill={colors.gray400} />}
    />
    <span className={styles['includes-fees-text']}>Includes Fees</span>
  </div>
);

export default IncludesFees;
