module.exports = {
  domain: 'localhost',
  port: 3000,
  API_URL: 'https://staging.gametime.co',
  MOBILE_API_URL: 'https://mobile-staging.gametime.co',
  APPTIMIZE_APP_KEY: 'C449rzDcB9a7tFMdQpKtyzqWvXXe984',
  PUSHER_KEY: 'c1e7843da68a3ece6450',
  BRAZE: {
    API_KEY: '5d58f8d7-3186-4377-b3de-2a8d8c0e00b9',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
};
