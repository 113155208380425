import Position from './Position';

export default class Spot {
  constructor(spot = {}) {
    this.position = new Position(spot.position);
    this.disclosures = spot.disclosures;
    this.row = spot.row;
    this.section = spot.section;
    this.sectionGroup = spot.section_group;
    this.viewUrl = spot.view_url;
  }
}
