import React from 'react';

import colors from 'styles/colors.constants';

type SVGProps = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'fill'
>;
type Props = SVGProps & {
  backgroundColor?: string;
  textColor?: string;
};

export default function GTShield({
  width = '37',
  height = '37',
  fill = 'none',
  backgroundColor = colors.black21,
  textColor = colors.black21,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
        fill={backgroundColor}
      />
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9202 29.2009C18.2612 29.4662 18.7388 29.4662 19.0798 29.2009L25.5416 24.1752C26.4618 23.4594 27 22.3589 27 21.1932V12.6151C27 12.0731 26.6532 11.5919 26.139 11.4205L19.2964 9.13962C18.7795 8.9673 18.2205 8.9673 17.7036 9.13962L10.861 11.4205C10.3468 11.5919 10 12.0731 10 12.6151V21.1932C10 22.3589 10.5382 23.4594 11.4584 24.1752L17.9202 29.2009Z"
          fill="#19CE85"
        />
        <path
          d="M18.6277 18.0845V19.1436C18.6277 19.2917 18.7311 19.4102 18.8604 19.4102H19.5522C19.6266 19.4102 19.6847 19.4806 19.6847 19.5621V20.6952C19.6847 21.3432 19.1999 21.8913 18.6342 21.8913H18.5049C17.9392 21.8913 17.4381 21.3432 17.4381 20.6952V19.8916V19.4213V15.5923C17.4381 14.9443 17.9392 14.4148 18.5049 14.4148H18.6342C19.2484 14.4148 19.6233 14.8999 19.6233 15.4961V15.5961C19.6233 15.7442 19.7268 15.8627 19.8561 15.8627H21.1006C21.2299 15.8627 21.3333 15.7442 21.3333 15.5961V15.4109C21.3366 13.8889 20.3151 12.6521 18.6342 12.6521H18.5049C16.8239 12.6521 15.6667 13.9519 15.6667 15.559V20.7211C15.6667 22.369 16.8239 23.7021 18.5049 23.7021H18.6342C20.3151 23.7021 21.3333 22.3801 21.3333 20.747V18.0845C21.3333 17.9364 21.2299 17.8179 21.1006 17.8179H18.8604C18.7311 17.8179 18.6277 17.9364 18.6277 18.0845Z"
          fill={textColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="6"
          y="7"
          width="25"
          height="28.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
