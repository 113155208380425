import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

export const USER_ACTION_TYPES = Object.assign(
  generateActionTypes(
    [
      'CREATE_USER',
      'UPDATE_USER',
      'SEND_APP_DOWNLOAD_SMS',
      'FETCH_USER',
      'TOKEN_GENERATE',
      'TOKEN_LOGIN',
      'LOGOUT',
      'ADD_PROMO_CODE',
      'FETCH_PROMO_CODES',
      'FETCH_PROMOS_FOR_LISTING',
      'FETCH_USER_LOGIN_OPTIONS',
      'REQUEST_ACCOUNT_DELETION',
      'CREATE_USER_EXTERNAL_ACCOUNT',
      'FETCH_USER_EXTERNAL_ACCOUNT',
      'FETCH_USER_IP_GEOLOCATION',
    ],
    REDUCERS.USER,
    ['', '_SUCCESS', '_FAIL']
  ),
  generateActionTypes(
    [
      // solo actions
      'LOAD_AB_EXPERIMENT_ASSIGNMENTS',
      'LOAD_ZLISTINGS_ASSIGNMENTS',
      'LOAD_FEATURE_FLAG_ASSIGNMENTS',
      'LOAD_ZSEARCH_ASSIGNMENTS',
      'SET_IS_BOT',
      'SET_FORTER_TOKEN',
      'REQUEST_ACCOUNT_DELETION',
    ],
    REDUCERS.USER
  )
);
