import React from 'react';

import SimpleReviewCard from 'components/SimpleReviewCard/SimpleReviewCard';
import { Review } from 'types';

import { homepageReviews } from '../constants';

import styles from './Reviews.module.scss';

const Reviews = () => (
  <div className={styles['reviews-container']}>
    <div className={styles['reviews-header']}>
      <span className={styles['reviews-title']}>
        See what fans like you are saying.
      </span>
      <span className={styles['reviews-subtitle']}>
        1,000,000+ tickets sold. 500,000+ happy customers.
      </span>
    </div>
    <div className={styles['review-cards-container']}>
      {homepageReviews.map((review: Review) => (
        <SimpleReviewCard review={review} page="homepage" key={review.id} />
      ))}
    </div>
  </div>
);

export default Reviews;
