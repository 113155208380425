import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './ClickToUnlockButton.module.scss';

const Button = styled.button`
  transition: box-shadow 150ms ease-in-out;
  border-color: ${({ color }) => color};
  color: ${({ color }) => color};
  &:hover {
    box-shadow: ${({ shadow }) => shadow && `0px 0px 2px 4px ${shadow}`};
    color: ${({ color }) => color};
  }
`;

const ClickToUnlockButton = ({ color, shadow, ...props }) => (
  <Button
    {...props}
    type="button"
    shadow={shadow}
    color={color}
    className={styles.button}
  >
    Click to Unlock Deals
  </Button>
);

ClickToUnlockButton.propTypes = {
  color: PropTypes.string.isRequired,
  shadow: PropTypes.string,
};

export default ClickToUnlockButton;
