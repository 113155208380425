import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  BlogPostClickTracker,
  Click,
  TRACK,
  TrackPageView,
  View,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import BlogPost from 'models/Blog/Post';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import { fetchPosts } from 'store/modules/blog/posts/actions';
import { postsSelector } from 'store/modules/blog/posts/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import PostCard from './components/PostCard';

import styles from './Blog.module.scss';

@TrackPageView(() => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.BLOG(),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.BLOG(),
}))
class Blog extends Component {
  renderMeta() {
    return (
      <div>
        <HeadTitle title="Blog" />
        <HeadDescription
          description="Last-minute tickets to sport,
            concert and theater events with Gametime;
            the seats you want, at the price you want,
            right now"
        />
      </div>
    );
  }

  renderPosts() {
    return this.props.posts
      .filter((p) => p.isValid() && !p.isInternal())
      .map((p) => (
        <PostCard
          key={p.slug}
          post={p}
          clickTracker={new BlogPostClickTracker(p.slug)}
        />
      ));
  }

  render() {
    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          className: styles.header,
          showHamburger: true,
        }}
        className={styles['blog-container']}
      >
        {this.renderMeta()}
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles['header-title']}>TICKET NEWS AND ARTICLES</h1>
            <div className={styles['green-divider']} />
          </div>
          <div className={styles.posts}>{this.renderPosts()}</div>
        </div>
      </GTContainer>
    );
  }
}

Blog.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.instanceOf(BlogPost)),
};

const mapStateToProps = (state) => ({
  posts: postsSelector(state),
});

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    // TODO: add infinte scroll see https://gametime.atlassian.net/browse/MWEB-2379
    await Promise.all([
      store.dispatch(fetchPosts({ page: 1, pageSize: 99 })),
      store.dispatch(fetchMetros()),
    ]);
    return null;
  };

const BlogWrapper = connect(mapStateToProps)(Blog);

BlogWrapper.loader = loader;

export default BlogWrapper;
