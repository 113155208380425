export const getTransformStyles = (x, y, scale) => {
  return `translateX(${x}px) translateY(${y}px) scale(${scale})`;
};

export const getCenterPosition = (
  scale,
  wrapperComponent,
  contentComponent
) => {
  const contentWidth = contentComponent.offsetWidth * scale;
  const contentHeight = contentComponent.offsetHeight * scale;

  const centerPositionX = (wrapperComponent.offsetWidth - contentWidth) / 2;
  const centerPositionY = (wrapperComponent.offsetHeight - contentHeight) / 2;

  return {
    scale,
    positionX: centerPositionX,
    positionY: centerPositionY,
  };
};
