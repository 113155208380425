export const ACTIONS = {
  GET_PERFORMERS_IN_CONTEXT_REQUESTV2: 'GET_PERFORMERS_IN_CONTEXT_REQUESTV2',
  GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESSV2:
    'GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESSV2',
  GET_PERFORMERS_IN_CONTEXT_REQUEST_FAILUREV2:
    'GET_PERFORMERS_IN_CONTEXT_REQUEST_FAILUREV2',
};

export const fetchPerformersInContext = (params) => (dispatch) => {
  return dispatch({
    types: [
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUESTV2,
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESSV2,
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_FAILUREV2,
    ],
    params,
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/performers-in-context',
        searchParams: params,
      }),
  });
};

export const fetchPerformersInContextByMetro = (metro) =>
  fetchPerformersInContext({ metro });
export const fetchPerformersInContextByCategory = (category) =>
  fetchPerformersInContext({ category });
export const fetchPICByMetroCategoryGroup = (
  metro,
  categoryGroup,
  limit = 15
) =>
  fetchPerformersInContext({
    metro,
    category_group: categoryGroup,
    limit,
  });
