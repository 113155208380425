import React from 'react';
import colors from 'styles/colors.constants';

export default (props) => {
  return (
    <svg
      width={props.width || '40'}
      height={props.height || '40'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 8C15.1636 8 8 15.1636 8 24C8 32.8364 15.1636 40 24 40C32.8364 40 40 32.8364 40 24C40 15.1636 32.8364 8 24 8ZM24 4C12.9545 4 4 12.9545 4 24C4 35.0455 12.9545 44 24 44C35.0455 44 44 35.0455 44 24C44 12.9545 35.0455 4 24 4Z"
        fill={colors.gametimeGreen}
      />
      <path
        d="M21.9492 14.2715L22.2773 27.5059H25.7227L26.0508 14.2715H21.9492ZM24 34.3418C25.2441 34.3418 26.2285 33.3984 26.2285 32.1953C26.2285 30.9922 25.2441 30.0488 24 30.0488C22.7559 30.0488 21.7715 30.9922 21.7715 32.1953C21.7715 33.3984 22.7559 34.3418 24 34.3418Z"
        fill={colors.gametimeGreen}
      />
    </svg>
  );
};
