import React from 'react';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import { LotDeal } from 'types';

import IconSprite from '../../IconSprite/IconSprite';

import styles from './SeatMapPinExclusives.module.scss';

export type ExclusivesVariant = Extract<
  LotDeal['deal'],
  'super' | 'flash' | 'best' | 'cheapest' | 'zone'
>;

const exclusiveSpriteIconType: Record<string, string> = {
  super: 'super',
  flash: 'amazing',
  best: 'great',
  cheapest: 'cheapest',
  zone: 'zone_exclusives',
};

interface BigPinConfig {
  button?: React.ReactNode;
  imageProps?: React.ComponentProps<typeof Image>;
}

function SeatMapPinExclusives({
  variant,
  text,
  isSelected = false,
  bigPinConfig,
}: {
  variant?: string;
  text?: string;
  isSelected?: boolean;
  bigPinConfig?: BigPinConfig;
}) {
  const iconType = variant && exclusiveSpriteIconType[variant];
  const isSuperDealSmall = iconType === 'super' && !bigPinConfig;

  return (
    <div
      className={classNames(styles['seat-map-pin'], {
        [styles[`variant-${variant}`]]: !!variant,
        [styles['selected']]: isSelected,
      })}
    >
      <div
        className={classNames(styles['body'], {
          [styles['no-icon']]: !iconType,
          [styles['big-pin']]: !!bigPinConfig,
        })}
      >
        <div
          className={classNames(styles['body-container'], {
            [styles['big-pin']]: !!bigPinConfig,
          })}
        >
          <div className={styles['content']}>
            {iconType && (
              <IconSprite
                iconType={iconType}
                variant={isSuperDealSmall ? 'black' : 'color'}
              />
            )}
            <span className={styles['text']}>{text}</span>
          </div>
          {bigPinConfig?.button && (
            <div className={styles['big-pin-button']}>
              {bigPinConfig.button}
            </div>
          )}
        </div>
        {bigPinConfig && (
          <div className={styles['image-container']}>
            <div className={styles['image-overlay']} />
            <Image
              {...bigPinConfig.imageProps}
              className={classNames(
                styles['big-pin-image'],
                bigPinConfig.imageProps?.className
              )}
            />
          </div>
        )}
      </div>
      <svg
        className={styles['pin-stick']}
        width="32"
        height="25"
        viewBox="0 0 32 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={styles['zone-marker']}>
          <circle opacity="0.2" cx="16" cy="14" r="8" />
          <ellipse opacity="0.2" cx="16" cy="14" rx="16" ry="11" />
        </g>
        <circle cx="16" cy="13" r="3" fill="black" />
        <g filter={variant === 'zone' ? 'url(#filter0_d_47_1936)' : undefined}>
          <path
            className={styles['pin-stick-path']}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 0H13C14.1046 0 15 .8954 15 2V13C15 13.5523 15.4477 14 16 14 16.5523 14 17 13.5523 17 13V2C17 .8954 17.8954 0 19 0Z"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_47_1936"
            x="9"
            y="0"
            width="14"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47_1936"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_47_1936"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default React.memo(SeatMapPinExclusives);
