import { DATE_FORMAT_MMM_DD_YYYY, formatDate } from 'utils/datetime';

import Author from './Author';
import Category from './Category';
import Tag from './Tag';

export default class Post {
  constructor(post = {}) {
    this.slug = post.slug;
    this.url = post.url;
    this.createdAt = post.created;
    this.publishedAt = post.published;
    this.body = post.body;
    this.featuredImage = post.featured_image;
    this.metaDescription = post.meta_description;
    this.seoTitle = post.seo_title;
    this.status = post.status;

    this.title = post.title;
    this.summary = post.summary;
    this.author = post.author || new Author({});
    this.categories = post.categories?.map((c) => new Category(c)) || [];
    this.tags = post.tags?.map((t) => new Tag(t)) || [];
  }

  isValid() {
    const externallyValid =
      this.slug && this.url && this.publishedAt && this.seoTitle && this.title;
    return externallyValid && (this.isInternal() ? true : this.featuredImage);
  }

  getPublishedDate() {
    return formatDate(this.publishedAt, DATE_FORMAT_MMM_DD_YYYY);
  }

  getUrl() {
    return `/blog/${this.slug}`;
  }

  // Internal pages don't show up in a list of blog posts in /blog page,
  // however, there will be routes to this page from elsewhere.
  // Any post with an 'internal' tag won't show up in /blog page
  isInternal() {
    return this.tags.some((tag) => tag.slug === 'internal');
  }

  getTopCategory() {
    return this.categories.length ? this.categories[0].name : 'news';
  }
}
