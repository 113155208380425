import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function CircleLineIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="circle-line-icon"
    >
      <path
        d="M2 12C2 6.47727 6.47726 2 12 2C17.5227 2 22 6.47727 22 12C22 17.5227 17.5227 22 12 22C6.47726 22 2 17.5227 2 12Z"
        stroke={fill}
        strokeWidth="2"
      />
    </svg>
  );
}
