import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';

export const seatMapSelector = (globalState) => globalState[REDUCERS.SEATMAP];

const SEATMAP_ACTION_TYPES = generateActionTypes(['UPDATE'], REDUCERS.SEATMAP);

const initialState = {
  listingId: null,
  scale: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEATMAP_ACTION_TYPES.UPDATE:
      return {
        ...state,
        ...action.seatmapState,
      };
    default:
      return state;
  }
}

export const updateSeatMap = (seatmapState) => ({
  type: SEATMAP_ACTION_TYPES.UPDATE,
  seatmapState,
});
