import React from 'react';

export default ({fill}) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.56108 2.10147C5.34697 0.740597 7.63881 0.000570073 10 0.00388683C12.3612 0.000570073 14.653 0.740597 17.4389 2.10147C17.7823 2.26919 18 2.61789 18 3V12.2059C18.248 12.15 18.5032 12.0906 18.7673 12.0274C19.1454 11.937 19.5415 12.0738 19.7832 12.3782C20.0249 12.6827 20.0683 13.0996 19.8944 13.4472L18 17.2361V21C18 21.5523 17.5523 22 17 22H16L14.5 22C14.2239 22 14 21.7761 14 21.5V20.5C14 20.2239 14.2239 20 14.5 20H16V18.3119C13.8695 18.8553 12.346 18.9996 10.0002 19C7.65437 18.9996 6.13062 18.8553 4 18.3119V20H5.5C5.77614 20 6 20.2239 6 20.5V21.5C6 21.7761 5.77614 22 5.5 22L4 22H3C2.44772 22 2 21.5523 2 21V17.2361L0.105581 13.4472C-0.0682467 13.0996 -0.024874 12.6827 0.216797 12.3782C0.458467 12.0738 0.854647 11.937 1.23267 12.0274C1.49679 12.0906 1.75198 12.15 2 12.2059V3C2 2.61789 2.21775 2.26919 2.56108 2.10147ZM16 11V12.6029C14.1775 12.9065 12.5029 13.0039 10.0015 13H9.99847C7.49712 13.0039 5.82249 12.9065 4 12.6029V11H10H16ZM8.35352 3.64642L9.64648 2.35358C9.84167 2.15826 10.1583 2.15826 10.3535 2.35358L11.6465 3.64642C11.8417 3.84174 11.8417 4.15826 11.6465 4.35358L10.3535 5.64642C10.1583 5.84174 9.84167 5.84174 9.64648 5.64642L8.35352 4.35358C8.15833 4.15826 8.15833 3.84174 8.35352 3.64642Z"
      fill={fill || '#fff'}
    />
  </svg>
);
