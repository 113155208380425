import React from 'react';

import styles from './DetailSubHead.module.scss';

type Props = {
  text: string;
};

const DetailSubHead = ({ text = '' }: Props) => (
  <div className={styles['detail-subhead']}>
    <p className={styles.text}>{text}</p>
  </div>
);

export default DetailSubHead;
