import React from 'react';
export default function MultiTicketFillIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C6 0.895431 6.89543 0 8 0H20C21.1046 0 22 0.895431 22 2V18C22 19.1046 21.1046 20 20 20H8C6.89543 20 6 19.1046 6 18V2ZM18.656 7.52771L14.2164 3.0895C14.097 2.97017 13.9029 2.97017 13.7836 3.0895L11.6846 5.18856C11.6012 5.27194 11.6357 5.41284 11.7464 5.45022L18.5396 7.71605C18.6531 7.75343 18.7408 7.61254 18.656 7.52771ZM11.6903 10.8172L13.7836 12.9105C13.903 13.0298 14.0971 13.0298 14.2164 12.9105L18.6546 8.47229C18.7394 8.38746 18.6517 8.24657 18.5382 8.28539L11.7522 10.5555C11.6415 10.5929 11.6084 10.7352 11.6903 10.8172ZM13.0274 7.99928C13.0274 6.56732 11.9419 5.81108 11.5307 5.57674C11.4416 5.52642 11.3309 5.54079 11.259 5.61268L9.0895 7.78362C8.97017 7.90295 8.97017 8.09704 9.0895 8.21638L11.2619 10.3888C11.3338 10.4606 11.4459 10.475 11.5336 10.4247C11.9448 10.1889 13.0274 9.43124 13.0274 7.99928ZM9.5 15C9.22386 15 9 15.2239 9 15.5V16.5C9 16.7761 9.22386 17 9.5 17H18.5C18.7761 17 19 16.7761 19 16.5V15.5C19 15.2239 18.7761 15 18.5 15H9.5Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 22V6C2 4.89543 2.89543 4 4 4V22H18C18 23.1046 17.1046 24 16 24H4C2.89543 24 2 23.1046 2 22Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
