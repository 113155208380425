import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import { Click, TRACK } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import Link from 'components/Link/Link';

import styles from './GTFullWidthList.module.scss';

// NOTE: Keep in sync with the value in GTFullWidthList.scss.
const MAX_VERTICAL_ITEMS = 42;
const MAX_COLUMNS = 6;
const ITEMS_PER_COLUMN = MAX_VERTICAL_ITEMS / MAX_COLUMNS;

@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.LIST(),
}))
class GTFullWidthList extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { overrideClick, onClick } = this.props;

    if (overrideClick) {
      event.preventDefault();
      overrideClick();
    }

    if (onClick) {
      onClick();
    }
  }

  renderList() {
    const { items } = this.props;

    const numCols = Math.min(
      Math.ceil(items.length / ITEMS_PER_COLUMN),
      MAX_COLUMNS
    );
    const colClass = `header-col-${numCols}`;

    return items.map((item) => (
      <div
        className={classNames(styles['link-container'], styles[colClass])}
        key={item.id || item.text}
      >
        <Link
          className={classNames([
            styles.link,
            { [styles.highlighted]: item.isLocal },
          ])}
          to={item.link}
          onClick={this.handleClick}
          clickTracker={item.clickTracker}
          title={item.text}
        >
          {item.text}
        </Link>
      </div>
    ));
  }

  render() {
    const { listClassName, vertical } = this.props;

    const listContainer = (
      <div
        className={classNames([
          styles.list,
          styles[listClassName],
          { [styles.vertical]: vertical },
        ])}
      >
        {this.renderList()}
      </div>
    );

    if (listClassName === 'categoryPerformerList') {
      return <Container disableGutters>{listContainer}</Container>;
    }

    return listContainer;
  }
}

GTFullWidthList.propTypes = {
  items: PropTypes.array.isRequired,
  overrideClick: PropTypes.func,
  onClick: PropTypes.func,
  listClassName: PropTypes.string,
  vertical: PropTypes.bool,
};

export default GTFullWidthList;
