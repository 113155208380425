import React from 'react';

import styles from './GoogleAdBanner.module.scss';

const GoogleAdBanner = () => {
  return (
    <div className={styles.banner}>
      <span className={styles.text}>
        Prices may be set above or below face value by the seller.
      </span>
    </div>
  );
};

export default GoogleAdBanner;
