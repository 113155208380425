import React from 'react';
export default class ApartmentFillIcon extends React.Component {
  render() {
    return (
      <svg
        width="24"
        height="24"
        fill="#969696"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <title>fill/apartment/ic_24_apartment_fill_SVG</title>
        <path
          d="M22.5 9.5c0-.33-.13-.65-.37-.88a1.2 1.2 0 0 0-.88-.37H16.5V22.5h2.25v-5.25h1.5v5.25h1.03c.67 0 1.22-.54 1.22-1.22V9.5zM11.25 6.75h1.5v-3h-1.5v3zm0 6.75h1.5v-3h-1.5v3zM7.5 6.75H9v-3H7.5v3zm0 6.75H9v-3H7.5v3zm0 6.75H9v-3H7.5v3zM3.75 6.75h1.5v-3h-1.5v3zm0 6.75h1.5v-3h-1.5v3zm0 6.75h1.5v-3h-1.5v3zM24 9.5v11.78c0 1.5-1.22 2.72-2.72 2.72h-8.53v-6.75h-1.5V24H2.74c-.73 0-1.42-.28-1.94-.8S0 22 0 21.26V2.77C0 1.24 1.24 0 2.76 0h10.99c1.52 0 2.75 1.24 2.75 2.75v4h4.75c.74 0 1.43.29 1.95.81.51.52.8 1.21.8 1.94zm-5.25 4.005h1.5v-3h-1.5v3z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}
