import React from 'react';
import colors from 'styles/colors.constants';

export default function CreditCardFrontFillIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      fill={props.fill || colors.gray200}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="credit-card-front-fill-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 4.89543 1.89543 4 3 4H21C22.1046 4 23 4.89543 23 6V18C23 19.1046 22.1046 20 21 20H3C1.89543 20 1 19.1046 1 18V6ZM8 8.5C8 8.77614 7.77614 9 7.5 9H4.5C4.22386 9 4 8.77614 4 8.5V7.5C4 7.22386 4.22386 7 4.5 7H7.5C7.77614 7 8 7.22386 8 7.5V8.5ZM19.5 17C19.7761 17 20 16.7761 20 16.5V15.5C20 15.2239 19.7761 15 19.5 15H4.5C4.22386 15 4 15.2239 4 15.5V16.5C4 16.7761 4.22386 17 4.5 17H19.5Z"
      />
    </svg>
  );
}
