import React, { useState } from 'react';
import classNames from 'classnames';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { Review } from 'types';

import ReviewCardModal from './ReviewCardModal';

import styles from './ReviewCard.module.scss';

export interface ReviewCardProps {
  review: Review;
  clickTracker?: ClickTracker;
}

const mobileCharCount = 180;
const desktopCharCount = 190;

const ReviewCard = ({ review, clickTracker }: ReviewCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery(device.down.md);
  const analytics = useAnalyticsContext();

  const truncateReview = (review: string) => {
    if (isMobile && review.length > mobileCharCount) {
      return `${review.substring(0, mobileCharCount).trim()}...`;
    }
    if (review.length > desktopCharCount) {
      return `${review.substring(0, desktopCharCount).trim()}...`;
    }
    return review;
  };
  const reviewTruncated = isMobile
    ? review.review.length > mobileCharCount
    : review.review.length > desktopCharCount;
  const reviewToDisplay = truncateReview(review.review);

  const reviewTitle = review.reviewer_name
    ? `${review.reviewer_name} Says:`
    : review.title;

  const starsClass = review.stars === 5 ? 'five-stars' : 'four-stars';

  const onReadMoreClick = () => {
    if (clickTracker) analytics.track(new Click(clickTracker.json()));
    setShowModal(true);
  };

  return (
    <div className={styles['review-card-container']}>
      <div className={styles['review-card-body']}>
        <span className={styles['review-card-detail']}>
          <div
            className={classNames(
              styles['review-card-stars'],
              styles[starsClass]
            )}
          />
          <span className={styles['review-card-date']}>{review.date}</span>
        </span>
        <span className={styles['review-card-title']}>{reviewTitle}</span>
        <span className={styles['review']}>{reviewToDisplay}</span>
        {reviewTruncated && (
          <span
            className={styles['review-read-more']}
            onClick={onReadMoreClick}
          >
            READ MORE
          </span>
        )}
      </div>
      {showModal && (
        <ReviewCardModal
          review={review}
          onHide={() => setShowModal(false)}
          show={showModal}
        />
      )}
    </div>
  );
};

export default ReviewCard;
