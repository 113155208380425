import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  dealTypes,
  getUnlockedZoneDealInfo,
  saveUnlockZoneDealInfo,
} from 'components/Deals/helpers';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';

import LockedDeal from '../LockedDeal';

import styles from '../Deals.module.scss';

const ZoneDealLockedCell = (props) => {
  const { fullEvent, listing, handleDealUnlock, zoneDealIsUnlocked } = props;
  const [activeZoneDeal, setActiveZoneDeal] = useState(
    getUnlockedZoneDealInfo(fullEvent.id)
  );

  const onUnlockZoneDeal = useCallback(() => {
    const savedZoneDeal = saveUnlockZoneDealInfo(fullEvent.id);
    setActiveZoneDeal(savedZoneDeal);
    handleDealUnlock(dealTypes.zoneDeal);
  }, [fullEvent, handleDealUnlock]);

  useEffect(() => {
    const zoneDealInfo = getUnlockedZoneDealInfo(fullEvent.id);
    if (zoneDealInfo && !activeZoneDeal) {
      setActiveZoneDeal(zoneDealInfo);
    }
  }, [activeZoneDeal, listing, zoneDealIsUnlocked, fullEvent]);

  if (!activeZoneDeal) {
    return (
      <div
        className={styles.container}
        onMouseOver={() => props.onHover(listing)}
        onFocus={() => props.onHover(listing)}
      >
        <LockedDeal
          dealType={dealTypes.zoneDeal}
          fullEvent={fullEvent}
          listings={[listing]}
          onUnlockDeal={onUnlockZoneDeal}
        />
      </div>
    );
  }

  return <ListingCard {...props} isZoneDeal />;
};

ZoneDealLockedCell.propTypes = {
  ...ListingCard.propTypes,
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  listing: PropTypes.instanceOf(Listing).isRequired,
};

export default ZoneDealLockedCell;
