import { FullEvent, Performer } from 'models';
import { getSportNameFromCategory } from 'store/modules/categories/category.helpers';

import { PERFORMER_TYPES } from './constants';
import EventSchema from './EventSchema';
import { EventJsonLD, PerformerSchema } from './types';

export default class SportEventSchema extends EventSchema {
  sport: string;

  constructor(fullEvent: FullEvent) {
    super(fullEvent);
    this.sport = getSportNameFromCategory(fullEvent.event.category);
  }

  get description() {
    return this.fullEvent.getShortName('vs', true);
  }

  getPerformerSchema(performer: Performer): PerformerSchema {
    const basePerformer = super.getPerformerSchema(performer);
    return {
      ...basePerformer,
      '@type': PERFORMER_TYPES.SPORTS_TEAM,
      sport: this.sport,
    };
  }

  getJsonLD() {
    const baseSchema = super.getJsonLD();

    const sportTeamsSchema = this.fullEvent.uniquePerformersList.map((data) => {
      const performer = new Performer(data);
      return this.getPerformerSchema(performer);
    });

    const eventSchema: EventJsonLD = {
      ...baseSchema,
      sport: this.sport,
    };

    if (this.fullEvent.uniquePerformersList.length > 1) {
      eventSchema.homeTeam =
        this.primaryPerformer && `${this.emoji}${this.primaryPerformer.name}`;
      eventSchema.awayTeam =
        this.secondaryPerformer &&
        `${this.emoji}${this.secondaryPerformer.name}`;
      eventSchema.competitor = sportTeamsSchema;
    }

    return eventSchema;
  }
}
