import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function FireIcon({
  width = '16',
  height = '16',
  fill = colors.black,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="fire-icon"
    >
      <path
        d="M11.5055 7.35282C11.5055 7.35282 11.6447 9.01219 10.3707 9.86865C10.3707 9.86865 12.8651 4.92264 7.53373 2C7.53373 2 8.28312 3.27397 6.5274 4.80488C5.06073 6.07885 1.57069 10.2862 6.69869 12.7056C6.69869 12.7056 4.62179 9.98641 7.20185 8.48762C7.20185 8.48762 6.21693 10.0721 7.98337 11.4103C9.03252 10.3183 8.75417 9.76159 8.75417 9.76159C8.75417 9.76159 10.2744 10.982 9.15028 12.7056C9.15028 12.7056 13.4218 11.6779 11.5055 7.35282Z"
        fill={fill}
      />
    </svg>
  );
}
