export function getPercentage(divident: number, divisor: number) {
  return Number(((divident / divisor) * 100).toFixed(2));
}

export function round(input: number) {
  return Math.round(input * 100) / 100;
}

export const isInteger = (value: unknown) => Number.isInteger(Number(value));

export const formatPriceWithComma = (
  price: number = 0,
  showCents: boolean = false
) => {
  if (showCents) {
    return price.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return price.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};
