import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function ProfileFillIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 5.58182 5.58182 2 10 2C14.4182 2 18 5.58182 18 10C18 12.1528 17.1496 14.107 15.7665 15.545C15.7222 15.5188 15.6759 15.4948 15.6276 15.4734L12.8033 14.2182C12.2957 13.9925 12.173 13.3261 12.5049 12.8806C13.1767 11.979 13.7917 10.7206 13.7917 9.08333C13.7917 6.82817 12.2552 5 10 5C7.74484 5 6.20833 6.82817 6.20833 9.08333C6.20833 10.7206 6.82326 11.979 7.49506 12.8806C7.82702 13.3261 7.70435 13.9925 7.19666 14.2182L4.37243 15.4734C4.32413 15.4948 4.27781 15.5188 4.23354 15.545C2.85038 14.107 2 12.1528 2 10ZM0 10C0 4.47726 4.47725 0 10 0C15.5228 0 20 4.47726 20 10C20 15.5227 15.5228 20 10 20C4.47725 20 0 15.5227 0 10Z"
      />
    </svg>
  );
}
