import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Tag.module.scss';

/**
 * @deprecated use ui/Tag, and position the tag from the parent element.
 */
const Tag = ({ name = '', colorCode, position, icon = null }) => (
  <div
    className={classNames(
      styles.container,
      styles[position],
      styles[colorCode]
    )}
  >
    {icon && <div className={styles['tag-icon']}>{icon}</div>}
    {name}
  </div>
);

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  colorCode: PropTypes.oneOf(['last-call', 'today', 'top-pick']).isRequired,
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]).isRequired,
  icon: PropTypes.node,
};

export default Tag;
