import { ACTIONS } from './actions';

const initialState = { performers: [] };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESSV2:
      return {
        ...state,
        performers: [...action.result.performers],
      };
    default:
      return state;
  }
}
