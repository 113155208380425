import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

const RemoveLineIcon = ({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) => (
  <svg width={width} height={height} fill={fill} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64643 17.6464C5.45117 17.4511 5.45117 17.1346 5.64643 16.9393L16.9393 5.6464C17.1346 5.45114 17.4512 5.45114 17.6464 5.6464L18.3535 6.35351C18.5488 6.54877 18.5488 6.86535 18.3535 7.06061L7.06065 18.3535C6.86538 18.5488 6.5488 18.5488 6.35354 18.3535L5.64643 17.6464Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35354 5.64649C6.5488 5.45123 6.86538 5.45123 7.06065 5.64649L18.3535 16.9394C18.5488 17.1346 18.5488 17.4512 18.3535 17.6465L17.6464 18.3536C17.4512 18.5489 17.1346 18.5489 16.9393 18.3536L5.64643 7.06071C5.45117 6.86544 5.45117 6.54886 5.64643 6.3536L6.35354 5.64649Z"
    />
  </svg>
);

export default RemoveLineIcon;
