import React from 'react';

export default ({ width, height, fill }) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 88 88"
    fill={fill || '#fff'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M65.13,38.78a4.91,4.91,0,0,0-4.19,2.31V41a5,5,0,0,0-5-5,4.87,4.87,0,0,0-4.19,2.31v-.1a5,5,0,0,0-5-5,4.9,4.9,0,0,0-4.2,2.31V18.92a4.73,4.73,0,0,0-1.49-3.52,5,5,0,0,0-3.5-1.4,4.92,4.92,0,0,0-4.89,5V49.7a.7.7,0,0,1-.4.6.59.59,0,0,1-.7-.1l-5-5a5.11,5.11,0,0,0-6.39-.6A4.89,4.89,0,0,0,18,48.11,5,5,0,0,0,19.08,52L31.57,67.18A18.43,18.43,0,0,0,45.85,74h7.79a15.85,15.85,0,0,0,11.49-4.82,16.3,16.3,0,0,0,4.8-11.53V43.9a4.83,4.83,0,0,0-4.54-5.11Z" />
    <path d="M8.07,30.29h3v5.57h5.57v3H8.07Z" />
    <path d="M25.5,28.61h-3V23H16.93V20H25.5Z" />
  </svg>
);
