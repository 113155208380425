import React from 'react';
import PropTypes from 'prop-types';

import {
  Click,
  CollectionClickTracker,
  FullEventClickTracker,
  VenueClickTracker,
} from 'analytics';
import CollectionList from 'components/CollectionList/CollectionList';
import EventCell from 'components/Event/EventCell/EventCell';
import MetroSelector from 'components/MetroSelector/MetroSelector';
import MetroSelectorContainer from 'components/MetroSelector/MetroSelectorContainer';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { device, useMediaQuery } from 'hooks/useMediaQuery';

import AllEventsSection from './components/AllEventsSection';
import SimpleCollectionCard from './components/SimpleCollectionCard';

import styles from './NonSportsPerformers.module.scss';

export const INITIAL_PERFORMER_LIMIT = 15;
export const ALL_EVENTS_TITLE = 'All Events';

export default function NonSportsPerformers({
  clickContext,
  analytics,
  currentMetro,
  performerCollections,
  performersInContext,
  venueCollection,
  showPaginationButton,
  showAllLoading,
  onShowAllClick,
}) {
  const isMobile = useMediaQuery(device.down.md);

  return (
    <>
      <div className={styles['metro-selector-container']}>
        <MetroSelectorContainer>
          <MetroSelector
            titleStyle="metro-title"
            iconStyle="metro-icon"
            currentMetro={currentMetro}
          />
        </MetroSelectorContainer>
      </div>
      <div className={styles['non-sports-performers-container']}>
        {performerCollections.map((collection, analyticsSectionIndex) => {
          if (!collection || collection.performers.length === 0) {
            return null;
          }

          const seeAllLinkPath = collection.pathByMetro(currentMetro.id);
          const seeAllClickTracker = new CollectionClickTracker().interaction(
            Click.INTERACTIONS.SEE_MORE(),
            {
              section_index: analyticsSectionIndex,
              collection: collection.title,
            }
          );

          return (
            <ReactSlickCarousel
              key={`carousel-${analyticsSectionIndex}`}
              analytics={analytics}
              clickContext={clickContext}
              title={collection.title}
              spacing={16}
              slideWidth={isMobile ? 200 : 218}
              items={collection.performers}
              renderItem={(performer) => (
                <SimpleCollectionCard key={performer.id} source={performer} />
              )}
              renderHeader={(slidesToShow) => (
                <SectionHeader
                  title={collection.title}
                  showSeeAll={collection.performers.length > slidesToShow}
                  seeAllLinkPath={seeAllLinkPath}
                  clickTracker={seeAllClickTracker}
                />
              )}
            />
          );
        })}
        {!!performersInContext?.length && (
          <>
            <AllEventsSection performersInContext={performersInContext} />
            {showPaginationButton && (
              <div className={styles['pagination-button-container']}>
                <PaginationButton
                  label="Show All"
                  loading={showAllLoading}
                  onClick={onShowAllClick}
                />
              </div>
            )}
          </>
        )}
        {!!venueCollection?.groupedEvents.length && (
          <ReactSlickCarousel
            title={venueCollection.title}
            analytics={analytics}
            clickContext={clickContext}
            spacing={16}
            slideWidth={isMobile ? 283 : 338}
            renderHeader={() => <SectionHeader title={venueCollection.title} />}
            items={venueCollection.groupedEvents}
            getItemKey={(eventGrouping) => eventGrouping[0].venue.id}
            renderItem={(eventGrouping, venueIndex) => {
              const MAX_EVENT_GROUPING_SIZE = 5;
              const { venue } = eventGrouping[0];

              const venueClickTracker = new VenueClickTracker(
                venue
              ).interaction(Click.INTERACTIONS.CAROUSEL(), {
                collection: 'Venue Schedule',
                section_index: venueIndex,
                item_index: 0,
                name: venue.name,
              });

              const eventCells = eventGrouping
                .slice(0, MAX_EVENT_GROUPING_SIZE)
                .map((fullEvent, eventIndex) => {
                  const eventClickTracker = new FullEventClickTracker(
                    fullEvent
                  ).interaction(Click.INTERACTIONS.CAROUSEL(), {
                    section_index: venueIndex,
                    item_index: eventIndex,
                    collection: venue.name,
                    get_in_price: fullEvent.getPrice(),
                  });

                  return (
                    <EventCell
                      key={`non-sports-performer-${fullEvent.id}`}
                      fullEvent={fullEvent}
                      clickTracker={eventClickTracker}
                      hideLocation
                      isSmall
                      showTooltip
                    />
                  );
                });

              return (
                <CollectionList
                  key={venue.id}
                  imageProps={venue.getImageOptions()}
                  imageLink={venue.getPath()}
                  imageClickTracker={venueClickTracker}
                  imageTitle={venue.name}
                  eventCells={eventCells}
                  eventCellsHeight={MAX_EVENT_GROUPING_SIZE}
                />
              );
            }}
          />
        )}
      </div>
    </>
  );
}
NonSportsPerformers.propTypes = {
  clickContext: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  currentMetro: PropTypes.object,
  performerCollections: PropTypes.array,
  performersInContext: PropTypes.array,
  venueCollection: PropTypes.object,
  showPaginationButton: PropTypes.bool,
  showAllLoading: PropTypes.bool,
  onShowAllClick: PropTypes.func,
};
