import React from 'react';

import MLBIcon from 'icons/MLBIcon';

import styles from './MLBBadge.module.scss';

export default function MLBBadge() {
  return (
    <div className={styles['mlb-badge']}>
      <MLBIcon height="14" />
    </div>
  );
}
