import { CATEGORY_GROUPS } from 'modules/CategoryGroups/CategoryGroups.helpers';

export const EVENT_ATTENDANCE_MODE = {
  OFFLINE: 'http://schema.org/OfflineEventAttendanceMode',
} as const;

export const EVENT_STATUS = {
  SCHEDULED: 'http://schema.org/EventScheduled',
  CANCELLED: 'http://schema.org/EventCancelled',
} as const;

export const EVENT_TYPES = {
  MUSIC: 'MusicEvent',
  SPORT: 'SportsEvent',
  THEATER: 'TheaterEvent',
  EVENT: 'Event',
} as const;

export const CATEGORY_GROUP_TO_EVENT_TYPE_MAP = {
  [CATEGORY_GROUPS.CONCERT]: EVENT_TYPES.MUSIC,
  [CATEGORY_GROUPS.SPORT]: EVENT_TYPES.SPORT,
  [CATEGORY_GROUPS.THEATER]: EVENT_TYPES.THEATER,
};

export const PERFORMER_TYPES = {
  SPORTS_TEAM: 'SportsTeam',
  MUSIC_GROUP: 'MusicGroup',
  THEATER_GROUP: 'TheaterGroup',
  PERFORMIN_GROUP: 'PerformingGroup',
} as const;
