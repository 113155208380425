import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

export default function HamburgerIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <path d="M0 0h24v24H0V0z" />
      <path fill={fill} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
}
