import FullEvent from '../../../../models/FullEvent';

import { ACTIONS } from './actions';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SEARCH_REQUEST_SUCCESS:
      return {
        ...action.result,
      };
    case ACTIONS.SEARCH_REQUEST_FAILURE:
      return {};
    case ACTIONS.CLEAR_SEARCH:
      return initialState;
    case ACTIONS.SEARCH_RECOMMENDATIONS_SUCCESS:
      const { popular, sport } = action.result;
      return {
        recommended: {
          ...(state.recommended ?? {}),
          popular,
          sport,
        },
      };
    case ACTIONS.GET_VIEWED_EVENTS_REQUEST_SUCCESS:
      const { events = [] } = action.result;
      const fullEvents = events.map((item) => new FullEvent(item));
      return {
        recommended: {
          ...(state.recommended ?? {}),
          viewedEvents: fullEvents,
        },
      };
    default:
      return state;
  }
}
