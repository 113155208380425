import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './Buttons.module.scss';

export const TYPES = {
  WHITE: 'white',
  GREEN: 'green',
  GREEN_SOLID: 'green_solid',
  GREEN_LIGHT_SOLID: 'green_light_solid',
  PURPLE: 'purple',
  PURPLE_SOLID: 'purple_solid',
  TRANSPARENT: 'transparent',
  TRANSPARENT_NO_BORDER: 'transparent_no_border',
  AFFIRM: 'affirm',
  GREEN_MEDIUM: 'green_medium',
  WHITE_MEDIUM: 'white_medium',
  GREEN_TRANSPARENT_NO_BORDER_MEDIUM: 'green_transparent_no_border_medium',
};

const SimpleButton = ({
  type = TYPES.WHITE,
  text = '',
  icon = null,
  fullWidth = false,
  className,
  ...rest
}) => (
  <ButtonLoader
    className={classNames(className, styles['simple-button'], [styles[type]], {
      [styles['full-width']]: fullWidth,
    })}
    {...rest}
  >
    {text && <span className={styles.text}>{text}</span>}
    {icon && <span className={styles.icon}>{icon}</span>}
  </ButtonLoader>
);

SimpleButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default SimpleButton;
