import React, { MouseEvent, TouchEvent } from 'react';
import classNames from 'classnames';

import { MetroClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import { Metro } from 'types';

import styles from './SelectorModalOptions.module.scss';

export interface SelectorModalOption extends Metro {
  /** The URL path for this metro option */
  path: string;
}

interface SelectorModalOptionsProps {
  /** Currently selected metro option, null if none selected */
  currentSelection: SelectorModalOption | null;
  /** Array of available metro options */
  options: SelectorModalOption[];
  /** Click event to fire when a user selects on option */
  handleClick: (
    option: SelectorModalOption,
    event: MouseEvent | TouchEvent
  ) => void;
}

function SelectorModalOptions({
  currentSelection,
  options,
  handleClick,
}: SelectorModalOptionsProps) {
  return (
    <div
      className={styles['option-link-container']}
      role="listbox"
      aria-label="metro options"
    >
      {options.map((option) => {
        const isActive = currentSelection && currentSelection.id === option.id;

        return (
          <Link
            className={classNames(
              styles['option-link'],
              isActive ? styles.active : ''
            )}
            key={option.id}
            to={option.path}
            onClick={(event) => handleClick(option, event)}
            clickTracker={new MetroClickTracker(option)}
          >
            <span className={styles.name}>{option.name}</span>
          </Link>
        );
      })}
    </div>
  );
}

export default SelectorModalOptions;
