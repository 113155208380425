import React, { useEffect } from 'react';

import styles from './AffirmBlock.module.scss';

type Props = {
  price: number;
  onClick: () => void;
};

export default function AffirmBlock({ price, onClick }: Props) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.affirm) {
      window.affirm.ui.ready(() => {
        window.affirm?.ui.refresh();
      });
    }
  }, [price]);

  return (
    <div
      className={styles.container}
      onClick={onClick}
      data-testid="affirm-block"
      role="button"
      aria-label="View Affirm payment options"
    >
      <div
        className="affirm-as-low-as"
        data-page-type="product"
        data-amount={`${price.toString()}00`}
        data-affirm-color="white"
        data-learnmore-show={false}
      />
    </div>
  );
}
