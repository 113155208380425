import React from 'react';

import LinksSection, {
  CategoryLinksSection,
} from 'components/LinksSection/LinksSection';

import styles from './TextPerformerGroupings.module.scss';

interface TextPerformerGroupingsProps {
  heading?: string;
  linkGroups: CategoryLinksSection[];
}

const TextPerformerGroupings = ({
  heading,
  linkGroups,
}: TextPerformerGroupingsProps) => {
  return (
    <div className={styles['teams-container']}>
      {heading && <span className={styles['teams-header']}>{heading}</span>}
      <div className={styles['teams-body']}>
        {linkGroups.map(({ title, links }) => (
          <div key={title} className={styles.division}>
            {links.length >= 1 && <LinksSection title={title} links={links} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextPerformerGroupings;
