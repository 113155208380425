import { ACTIONS } from './actions';

const initialState = {};

export const getFilterKey = (filters) => {
  const filterKeys = [];

  if (filters.slug) {
    filterKeys.push(filters.slug);
  }

  if (filters.category) {
    filterKeys.push(filters.category);
  }

  return filterKeys.join('+');
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_PERFORMERS_REQUEST_SUCCESS:
    case ACTIONS.GET_TRENDING_PERFORMERS_REQUEST_SUCCESS:
      const filterKey = getFilterKey(action.params);

      if (filterKey) {
        return {
          ...state,
          [filterKey]: action.result.performers.map(
            (performer) => performer.slug
          ),
        };
      }
      return state;

    default:
      return state;
  }
}
