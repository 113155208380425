import { eventType, zoomType } from '../../constants';
import { isExcludedNode } from '../../utils';
import { animate } from '../animations/animations.utils';
import {
  handleCalculateButtonZoom,
  resetTransformations,
} from '../handlers/handlers.utils';
import { getMousePosition } from '../wheel/wheel.utils';
import { handleZoomToPoint } from '../zoom/zoom.logic';

export function handleDoubleClick(contextInstance, event) {
  const { disabled, mode, step, animationTime, animationType } =
    contextInstance.setup.doubleClick;

  if (disabled) return;

  if (mode === 'reset') {
    return resetTransformations(contextInstance, animationTime, animationType);
  }

  const { scale } = contextInstance.transformState;
  const { contentComponent } = contextInstance;

  if (!contentComponent) return console.error('No ContentComponent found');

  if (scale === 5) {
    // If zoom level 5 exit.
    return;
  }

  const delta = mode === 'zoomOut' ? -1 : 1;
  contextInstance.setInteractionState(eventType.doubleClick, zoomType.zoomIn);
  const newScale = handleCalculateButtonZoom(contextInstance, delta, step);
  const mousePosition = getMousePosition(event, contentComponent, scale);
  const targetState = handleZoomToPoint(
    contextInstance,
    newScale,
    mousePosition.x,
    mousePosition.y
  );

  if (!targetState) {
    return console.error(
      'Error during zoom event. New transformation state was not calculated.'
    );
  }

  animate(contextInstance, targetState, animationTime, animationType);
}

export const isDoubleClickAllowed = (contextInstance, event) => {
  const { isInitialized, setup, wrapperComponent } = contextInstance;
  const { disabled, excluded } = setup.doubleClick;

  const target = event.target;
  const isWrapperChild = wrapperComponent?.contains(target);
  const isAllowed = isInitialized && target && isWrapperChild && !disabled;

  if (!isAllowed) return false;

  const isExcluded = isExcludedNode(target, excluded);

  if (isExcluded) return false;

  if (!isAllowed) return false;

  return true;
};
