import { ACTIONS } from './constants';
import { selectPerformersInContextByFilters } from './selectors';

export const fetchPerformersInContext = (params) => (dispatch, getState) => {
  const state = getState();
  const performersInContext = selectPerformersInContextByFilters(state, params);

  if (!params.skipCache && performersInContext) {
    return Promise.resolve({ performers: performersInContext });
  }

  if ('skipCache' in params) {
    delete params.skipCache;
  }

  return dispatch({
    types: [
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST,
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_SUCCESS,
      ACTIONS.GET_PERFORMERS_IN_CONTEXT_REQUEST_FAILURE,
    ],
    params,
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/performers-in-context',
        searchParams: params,
      }),
  });
};

export const fetchPerformersInContextByMetro = (metro) =>
  fetchPerformersInContext({ metro });
export const fetchPerformersInContextByCategory = (category) =>
  fetchPerformersInContext({ category });
export const fetchPerformersInContextByMetroAndCategoryGroup = (
  metro,
  categoryGroup
) =>
  fetchPerformersInContext({
    metro,
    category_group: categoryGroup,
  });
