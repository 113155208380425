import React from 'react';

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#FFF'}
      d="M5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V7C19 7.55228 18.5523 8 18 8H6C5.44772 8 5 7.55228 5 7V6ZM5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V13C19 13.5523 18.5523 14 18 14H6C5.44772 14 5 13.5523 5 13V12ZM6 17C5.44772 17 5 17.4477 5 18V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V18C19 17.4477 18.5523 17 18 17H6Z"
    />
  </svg>
);
