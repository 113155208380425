import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { titleCase } from 'utils/strings/title-case';

import { HEADER_TYPES } from './constants';

import styles from './SectionHeader.module.scss';

const SectionHeader = ({
  title,
  showSeeAll,
  seeAllLinkPath,
  clickTracker,
  headerType,
}) => {
  const TitleElement = () => {
    const formattedTitle = titleCase(title);
    switch (headerType) {
      case HEADER_TYPES.TITLE:
        return <h1 className={styles.title}>{formattedTitle}</h1>;
      case HEADER_TYPES.SUBTITLE:
        return <h2 className={styles.title}>{formattedTitle}</h2>;
      case HEADER_TYPES.BODY:
      default:
        return <div className={styles.title}>{formattedTitle}</div>;
    }
  };

  return (
    <div className={styles['title-container']}>
      <div className={styles['left-container']}>{TitleElement()}</div>
      {showSeeAll && (
        <div className={styles['right-container']}>
          <Link
            className={styles['see-all']}
            clickTracker={clickTracker}
            to={seeAllLinkPath}
          >
            See All
          </Link>
        </div>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  showSeeAll: PropTypes.bool,
  seeAllLinkPath: PropTypes.string,
  clickTracker: PropTypes.object,
  headerType: PropTypes.oneOf(Object.values(HEADER_TYPES)),
};

export default SectionHeader;
