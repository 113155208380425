import { ACTIONS } from './constants';

const initialState = {
  events: {},
  parkingEvents: [],
  eventsData: {
    events: [],
    more: false,
    params: {},
  },
};

const toObject = (fullEvents = []) => {
  const eventsObject = {};
  (fullEvents || []).forEach((fullEvent) => {
    eventsObject[fullEvent.event.id] = fullEvent;
  });

  return eventsObject;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_EVENTS_REQUEST_SUCCESS:
      if (action.params.isParking) {
        return {
          ...state,
          parkingEvents: action.result.events,
        };
      }

      const { events, more, page, per_page } = action.result;
      const { resetState, venueSlug = '', eventType = '' } = action.params;
      const newFetchedEventsData = {
        events,
        more,
        params: {
          ...action.params,
          venueSlug,
          eventType,
          page,
          per_page,
        },
      };

      let eventsData;

      if (!resetState) {
        eventsData = Array.isArray(state.eventsData)
          ? state.eventsData
          : [state.eventsData];

        eventsData = [...eventsData, newFetchedEventsData];
      } else {
        eventsData = newFetchedEventsData;
      }

      return {
        ...state,
        events: { ...state.events, ...toObject(events) },
        eventsData: eventsData,
      };
    default:
      return state;
  }
}
