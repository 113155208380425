/**
 * Exhaustive type guard function to ensure that all possible values of the
 * union type are handled in a switch statement.
 */
export function exhaustiveSwitchGuard(_value: never): never {
  throw new Error(
    `Reached exhaustive guard function with unexpected value: ${JSON.stringify(
      _value
    )}`
  );
}
