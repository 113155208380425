import React from 'react';
import colors from 'styles/colors.constants';

export default (props) => {
  let rotation;
  switch (props.direction) {
    case 'up':
      rotation = 90;
      break;
    case 'right':
      rotation = 180;
      break;
    case 'down':
      rotation = 270;
      break;
    case 'left':
    default:
      rotation = 0;
  }

  return (
    <svg
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3472 21L31 34.7858L26.8264 39L9 21L26.8264 3L31 7.21423L17.3472 21Z"
          fill={props.color || colors.gray300}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="5"
          y="0"
          width="30"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
