import { ACTIONS } from './constants';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_VENUES_REQUEST_SUCCESS:
      const venueObject =
        action.result.results && action.result.results.length
          ? action.result.results[0]
          : {};

      return {
        ...state,
        [venueObject.slug]: venueObject,
      };
    default:
      return state;
  }
}
