import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';
import Link from 'components/Link/Link';
import ChevronIcon from 'icons/ChevronIcon';
import colors from 'styles/colors.constants';

import styles from './DropdownButton.module.scss';

export const TYPES = {
  DEFAULT: 'default',
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  HIGHLIGHT: 'highlight',
};

const DropdownButton = ({
  text,
  icon,
  active = false,
  onClick,
  clickTracker,
  to,
  href,
  type = TYPES.DEFAULT,
  hideCaret = false,
  textColor,
  capitalize,
  isLocal = false,
  hasTopDivider = false,
  hasBottomDivider = true,
}) => {
  const Element = to || href ? Link : ButtonLoader;

  const elementProps = {
    onClick,
    clickTracker,
  };

  if (to) {
    elementProps.to = to;
  }

  if (href) {
    elementProps.href = href;
  }

  elementProps.alignment = 'left';

  return (
    <>
      {hasTopDivider && <hr className={styles.divider} />}
      <Element
        className={classNames([
          styles['dropdown-button'],
          { [styles[type]]: type },
        ])}
        {...elementProps}
      >
        {icon && (
          <span className={styles['prefix-icon']} aria-hidden="true">
            {icon}
          </span>
        )}
        {text && (
          <span
            style={{ color: textColor }}
            className={classNames([
              styles['dropdown-button-content'],
              {
                [styles.capitalize]: capitalize,
                [styles.highlightLocal]: isLocal,
              },
            ])}
          >
            {text}
          </span>
        )}
        {!hideCaret && (
          <span className={styles.icon} aria-hidden="true">
            <ChevronIcon
              width="12"
              height="12"
              direction={active ? 'up' : 'down'}
              color={colors.gametimeGreen}
            />
          </span>
        )}
      </Element>
      {hasBottomDivider && <hr className={styles.divider} />}
    </>
  );
};

DropdownButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  clickTracker: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  hideCaret: PropTypes.bool,
  textColor: PropTypes.string,
  capitalize: PropTypes.bool,
  isLocal: PropTypes.bool,
  hasTopDivider: PropTypes.bool,
  hasBottomDivider: PropTypes.bool,
};

export default DropdownButton;
