import React from 'react';
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';

export type Router = {
  navigate: NavigateFunction;
};

export type WithRouterProps = {
  router: Router;
  location: Location;
  params: Params;
  query: object;
};

/**
 * @deprecated use hooks from react-router-dom directly wherever possible
 */
export default function withRouter<P>(
  Component: React.ComponentType<P & WithRouterProps>
) {
  function ComponentWithRouterProp(props: Omit<P, keyof WithRouterProps>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [query] = useQueryParams();
    const router = {
      navigate,
    };
    return (
      <Component
        {...(props as P)}
        query={query}
        location={location}
        params={params}
        router={router}
      />
    );
  }

  return ComponentWithRouterProp;
}
