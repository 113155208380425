import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { contextInitialState, eventType } from '../constants/state.constants';
import { handleCancelAnimation } from '../core/animations/animations.utils';
import { handleCalculateBounds } from '../core/bounds/bounds.utils';
import { handleAreaZoom, isAreaZoomAllowed } from '../core/click/click.logic';
import {
  handleDoubleClick,
  isDoubleClickAllowed,
} from '../core/doubleClick/doubleClick.logic';
import {
  handlePanning,
  handlePanningEnd,
  handlePanningStart,
} from '../core/pan/panning.logic';
import {
  isPanningAllowed,
  isPanningStartAllowed,
} from '../core/pan/panning.utils';
import {
  handlePinchStart,
  handlePinchStop,
  handlePinchZoom,
} from '../core/pinch/pinch.logic';
import { isPinchAllowed, isPinchStartAllowed } from '../core/pinch/pinch.utils';
import {
  handleWheelStart,
  handleWheelStop,
  handleWheelZoom,
} from '../core/wheel/wheel.logic';
import { isWheelAllowed } from '../core/wheel/wheel.utils';
import {
  createSetup,
  createState,
  getCenterPosition,
  getContext,
  getTransformStyles,
  handleCallback,
  makePassiveEventOption,
} from '../utils';

const TransformContext = React.createContext(contextInitialState);

class TransformProvider extends Component {
  mounted = true;

  transformState = createState(this.props);

  setup = createSetup(this.props);

  // Components
  wrapperComponent = null;
  contentComponent = null;
  // Initialization
  isInitialized = false;
  bounds = null;
  // wheel helpers
  previousWheelEvent = null;
  wheelStopEventTimer = null;
  wheelAnimationTimer = null;
  // panning helpers
  isPanning = false;
  startCoords = null;
  lastTouch = null;
  // click/Tap helpers
  isPanEvent = false;
  isTouchPanPinch = false;
  // pinch helpers
  distance = null;
  lastDistance = null;
  pinchStartDistance = null;
  pinchStartScale = null;
  pinchMidpoint = null;
  // velocity helpers
  velocity = null;
  velocityTime = null;
  lastMousePosition = null;
  // animations helpers
  animate = false;
  animation = null;
  maxBounds = null;
  // key press
  pressedKeys = {};

  componentDidMount() {
    const passive = makePassiveEventOption();
    // Panning on window to allow panning when mouse is out of component wrapper
    window.addEventListener('mousedown', this.onPanningStart, passive);
    window.addEventListener('mousemove', this.onPanning, passive);
    window.addEventListener('mouseup', this.onPanningStop, passive);
    document.addEventListener('mouseleave', this.clearPanning, passive);
    window.addEventListener('keyup', this.setKeyUnPressed, passive);
    window.addEventListener('keydown', this.setKeyPressed, passive);

    this.handleRef();
  }

  componentWillUnmount() {
    const passive = makePassiveEventOption();

    window.removeEventListener('mousedown', this.onPanningStart, passive);
    window.removeEventListener('mousemove', this.onPanning, passive);
    window.removeEventListener('mouseup', this.onPanningStop, passive);
    window.removeEventListener('keyup', this.setKeyUnPressed, passive);
    window.removeEventListener('keydown', this.setKeyPressed, passive);

    handleCancelAnimation(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps !== this.props) {
      handleCalculateBounds(this, this.transformState.scale);
      this.setup = createSetup(this.props);
    }
  }

  handleInitializeWrapperEvents = (wrapper) => {
    // Zooming events on wrapper
    const passive = makePassiveEventOption();

    wrapper.addEventListener('wheel', this.onWheelZoom, passive);
    wrapper.addEventListener('dblclick', this.onDoubleClick, passive);
    wrapper.addEventListener('click', this.onClick, passive);
    wrapper.addEventListener('touchstart', this.onTouchPanningStart, passive);
    wrapper.addEventListener('touchmove', this.onTouchPanning, passive);
    wrapper.addEventListener('touchend', this.onTouchPanningStop, passive);
  };

  handleInitialize = () => {
    const { centerOnInit } = this.setup;

    this.applyTransformation();
    this.forceUpdate();

    if (centerOnInit) {
      // this has to be redone once the right solution is found
      // problem is - we need to execute it after mounted component specify it's height / width, images are fetched async so it's tricky
      setTimeout(() => {
        if (this.mounted) {
          this.setCenter();
        }
      }, 50);
      setTimeout(() => {
        if (this.mounted) {
          this.setCenter();
        }
      }, 100);
      setTimeout(() => {
        if (this.mounted) {
          this.setCenter();
        }
      }, 200);
    }
  };

  //////////
  // Zoom
  //////////
  onZoomInteractionEnd = () => {
    this.props.zoomInteractionEnd(this.transformState);
  };

  onWheelZoom = (event) => {
    const { disabled } = this.setup;
    if (disabled) return;

    const isAllowed = isWheelAllowed(this, event);
    if (!isAllowed) return;

    const keysPressed = this.isPressingKeys(this.setup.wheel.activationKeys);
    if (!keysPressed) return;

    handleWheelStart(this, event);
    handleWheelZoom(this, event);
    handleWheelStop(this, event, this.onZoomInteractionEnd);
  };

  //////////
  // Pan
  //////////

  onPanningStart = (event) => {
    const { disabled } = this.setup;
    const { onPanningStart } = this.props;
    if (disabled) return;

    this.isPanEvent = false;
    const isAllowed = isPanningStartAllowed(this, event);
    if (!isAllowed) return;

    const keysPressed = this.isPressingKeys(this.setup.panning.activationKeys);
    if (!keysPressed) return;

    event.preventDefault();
    event.stopPropagation();

    /* Panning starts here */
    handleCancelAnimation(this);
    handlePanningStart(this, event);
    handleCallback(getContext(this), event, onPanningStart);
  };

  onPanning = (event) => {
    const { disabled } = this.setup;
    const { onPanning } = this.props;

    if (disabled) return;

    this.isPanEvent = true;
    const isAllowed = isPanningAllowed(this);
    if (!isAllowed) return;

    const keysPressed = this.isPressingKeys(this.setup.panning.activationKeys);
    if (!keysPressed) return;

    event.preventDefault();
    event.stopPropagation();

    handlePanning(this, event.clientX, event.clientY);
    handleCallback(getContext(this), event, onPanning);
    this.props.onPan && this.props.onPan();
  };

  onPanningStop = (event) => {
    const { onPanningStop, isMobile } = this.props;

    if (!isMobile) {
      /* !isMobile prevents from overwriting mWeb touch */
      this.transformState.interaction = {
        eventType: this.isPanEvent ? eventType.drag : null,
        zoomType: null,
      };
    }

    if (this.isPanning) {
      handlePanningEnd(this);
      handleCallback(getContext(this), event, onPanningStop);

      if (this.isPanEvent || this.isTouchPanPinch) {
        this.props.onPanInteractionEnd(this.transformState);
      }
    }
  };

  //////////
  // Pinch
  //////////

  onPinchStart = (event) => {
    const { disabled } = this.setup;
    const { onPinchingStart, onZoomStart } = this.props;

    if (disabled) return;

    const isAllowed = isPinchStartAllowed(this, event);
    if (!isAllowed) return;

    handlePinchStart(this, event);
    handleCancelAnimation(this);
    handleCallback(getContext(this), event, onPinchingStart);
    handleCallback(getContext(this), event, onZoomStart);
  };

  onPinch = (event) => {
    const { disabled } = this.setup;
    const { onPinching, onZoom } = this.props;

    if (disabled) return;

    const isAllowed = isPinchAllowed(this);
    if (!isAllowed) return;

    event.preventDefault();
    event.stopPropagation();

    handlePinchZoom(this, event);
    handleCallback(getContext(this), event, onPinching);
    handleCallback(getContext(this), event, onZoom);
  };

  onPinchStop = (event) => {
    const { onPinchingStop, onZoomStop } = this.props;

    if (this.pinchStartScale) {
      handlePinchStop(this);
      handleCallback(getContext(this), event, onPinchingStop);
      handleCallback(getContext(this), event, onZoomStop);
      this.onZoomInteractionEnd();
    }
  };

  //////////
  // Touch
  //////////

  onTouchPanningStart = (event) => {
    const { disabled } = this.setup;
    const { onPanningStart } = this.props;

    if (disabled) return;

    this.isTouchPanPinch = false;
    const isAllowed = isPanningStartAllowed(this, event);

    if (!isAllowed) return;

    const isDoubleTap = this.lastTouch && +new Date() - this.lastTouch < 200;

    if (isDoubleTap && event.touches.length === 1) {
      this.onDoubleClick(event);
    } else {
      this.lastTouch = +new Date();

      handleCancelAnimation(this);

      const { touches } = event;

      const isPanningAction = touches.length === 1;
      const isPinchAction = touches.length === 2;

      if (isPanningAction) {
        handleCancelAnimation(this);
        handlePanningStart(this, event);
        handleCallback(getContext(this), event, onPanningStart);
      }
      if (isPinchAction) {
        this.onPinchStart(event);
      }
    }
  };

  onTouchPanning = (event) => {
    const { disabled } = this.setup;
    const {
      onPanning,
      onTouchInteractionStart,
      shouldExecuteOnTouchInteractionStart,
    } = this.props;
    this.isTouchPanPinch = true;

    if (this.isPanning && event.touches.length === 1) {
      if (disabled) return;

      const isAllowed = isPanningAllowed(this);
      if (!isAllowed) return;

      event.preventDefault();
      event.stopPropagation();

      const touch = event.touches[0];
      handlePanning(this, touch.clientX, touch.clientY);
      handleCallback(getContext(this), event, onPanning);
    } else if (event.touches.length > 1) {
      this.onPinch(event);
    }

    if (shouldExecuteOnTouchInteractionStart) {
      onTouchInteractionStart();
    }
  };

  onTouchPanningStop = (event) => {
    const { enableAreaZoom } = this.props;
    this.onPanningStop(event);
    this.onPinchStop(event);

    if (!this.isTouchPanPinch) {
      /* Tap event */
      if (enableAreaZoom && isAreaZoomAllowed(this, event)) {
        handleAreaZoom(this, event);
      }
    }
  };

  //////////
  // Click
  //////////

  onClick = (event) => {
    const { disabled } = this.setup;
    const { isMobile, enableAreaZoom } = this.props;

    if (disabled || isMobile || !enableAreaZoom) return;

    if (isAreaZoomAllowed(this, event)) {
      handleAreaZoom(this, event);
    }
  };

  //////////
  // Double Click
  //////////

  onDoubleClick = (event) => {
    const { disabled } = this.setup;
    if (disabled) return;

    const isAllowed = isDoubleClickAllowed(this, event);
    if (!isAllowed) return;

    handleDoubleClick(this, event);

    // Tracking - timeout captures last zoom level
    if (this.transformState.scale === 5) return;
    setTimeout(() => this.onZoomInteractionEnd(), 500);
  };

  //////////
  // Helpers
  //////////

  clearPanning = (event) => {
    if (this.isPanning) {
      this.onPanningStop(event);
    }
  };

  setKeyPressed = (e) => {
    this.pressedKeys[e.key] = true;
  };

  setKeyUnPressed = (e) => {
    this.pressedKeys[e.key] = false;
  };

  isPressingKeys = (keys) => {
    if (!keys.length) {
      return true;
    }
    return Boolean(keys.find((key) => this.pressedKeys[key]));
  };

  setComponents = (wrapperComponent, contentComponent) => {
    this.wrapperComponent = wrapperComponent;
    this.contentComponent = contentComponent;
    handleCalculateBounds(this, this.transformState.scale);
    this.handleInitializeWrapperEvents(wrapperComponent);
    this.handleInitialize();
    this.handleRef();
    this.isInitialized = true;
    handleCallback(getContext(this), undefined, this.props.onInit);
  };

  setTransformState = (scale, positionX, positionY, interaction) => {
    if (!isNaN(scale) && !isNaN(positionX) && !isNaN(positionY)) {
      if (scale !== this.transformState.scale) {
        this.transformState.previousScale = this.transformState.scale;
        this.transformState.scale = scale;
      }
      this.transformState.positionX = positionX;
      this.transformState.positionY = positionY;
      this.transformState.interaction = interaction;

      this.applyTransformation();
    } else {
      console.error('Detected NaN set state values');
    }
  };

  setInteractionState = (interaction, zoomType = null) => {
    this.transformState.interaction.eventType = interaction;
    if (zoomType) {
      this.transformState.interaction.zoomType = zoomType;
    }
  };

  setCenter = () => {
    if (this.wrapperComponent && this.contentComponent) {
      const targetState = getCenterPosition(
        this.transformState.scale,
        this.wrapperComponent,
        this.contentComponent
      );
      this.setTransformState(
        targetState.scale,
        targetState.positionX,
        targetState.positionY
      );
    }
  };

  applyTransformation = () => {
    if (!this.mounted || !this.contentComponent) return;
    const { scale, positionX, positionY } = this.transformState;
    const transform = getTransformStyles(positionX, positionY, scale);
    this.contentComponent.style.transform = transform;

    this.handleRef();
  };

  handleRef = () => {
    this.props.setRef(getContext(this));
  };

  render() {
    const value = getContext(this);
    const { children } = this.props;
    const content = typeof children === 'function' ? children(value) : children;

    return (
      <TransformContext.Provider
        value={{
          ...this.transformState,
          setComponents: this.setComponents,
          contextInstance: this,
        }}
      >
        {content}
      </TransformContext.Provider>
    );
  }
}

TransformProvider.propTypes = {
  setRef: PropTypes.any,
  children: PropTypes.any,
  onPanningStart: PropTypes.func,
  onPanning: PropTypes.func,
  onPanningStop: PropTypes.func,
  onPinchingStart: PropTypes.func,
  onPinching: PropTypes.func,
  onPinchingStop: PropTypes.func,
  onZoomStart: PropTypes.func,
  onZoom: PropTypes.func,
  onZoomStop: PropTypes.func,
  onInit: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  enableAreaZoom: PropTypes.bool,
  onPanInteractionEnd: PropTypes.func,
  zoomInteractionEnd: PropTypes.func,
  onTouchInteractionStart: PropTypes.func,
  shouldExecuteOnTouchInteractionStart: PropTypes.bool,
  onPan: PropTypes.func,
};

export { TransformContext, TransformProvider };
