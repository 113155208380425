import React from 'react';
import PropTypes from 'prop-types';

import styles from './Coupon.module.scss';

const Coupon = ({ coupon }) => {
  const { name, description, disclaimer_text } = coupon;
  return (
    <div className={styles['coupon-container']} key={name}>
      <span className={styles['coupon-title']}>{name}</span>
      <div
        className={styles['coupon-content']}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div
        className={styles['coupon-content']}
        dangerouslySetInnerHTML={{ __html: disclaimer_text }}
      />
    </div>
  );
};

Coupon.propTypes = {
  coupon: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    disclaimer_text: PropTypes.string,
  }).isRequired,
};

export default Coupon;
