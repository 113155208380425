import React, { useImperativeHandle, useState } from 'react';

import { TransformProvider } from './TransformProvider';

export const SeatMapInteraction = React.forwardRef((props, ref) => {
  const [innerRef, setRef] = useState(null);

  useImperativeHandle(ref, () => innerRef, [innerRef]);

  return <TransformProvider {...props} setRef={setRef} />;
});

export default SeatMapInteraction;
