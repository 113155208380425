import { JsonObject } from 'type-fest';

import { REDUCERS } from 'store/modules/reducer.constants';

import { EventPageLoadTime } from './dataReducer';

export const eventsPageDataSelector = (globalState: {
  data: JsonObject;
}): EventPageLoadTime =>
  globalState.data[REDUCERS.EVENTS_PAGE_DATA] as EventPageLoadTime;
