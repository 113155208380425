import { generateActionTypes } from 'store/modules/helpers/generateActionTypes';
import { REDUCERS } from 'store/modules/reducer.constants';
import {
  extendDefaultBranchState,
  generateBranchLink,
  selectDefaultBranchData,
} from 'utils/branchLink';
import { isInteger } from 'utils/number';

import {
  appStatusSelector,
  branchLinkSelectorByCampaign,
} from './app.selectors';

const APP_ACTION_TYPES = generateActionTypes(
  [
    'UPDATE_APP_CONFIG',
    'SHOW_APP_SPINNER',
    'UPDATE_CURRENT_LOCATION',
    'ADD_BRANCH_LINK',
    'SET_ACTIVE_MY_TICKETS_VIEW',
  ],
  REDUCERS.APP
);

const initialState = {
  showAppSpinner: false,
  currentLocation: '',
  config: {},
  branchLinks: {},
  activeMyTicketsView: 'upcoming',
};

export const currentLocationSelector = (globalState) =>
  globalState[REDUCERS.APP].currentLocation;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APP_ACTION_TYPES.SHOW_APP_SPINNER:
      return {
        ...state,
        showAppSpinner: action.visibility,
      };
    case APP_ACTION_TYPES.UPDATE_APP_CONFIG:
      return {
        ...state,
        config: { ...state.config, ...action.config },
      };
    case APP_ACTION_TYPES.UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.location,
      };
    case APP_ACTION_TYPES.ADD_BRANCH_LINK:
      return {
        ...state,
        branchLinks: {
          ...state.branchLinks,
          [action.campaign]: action.branchLink,
        },
      };
    case APP_ACTION_TYPES.SET_ACTIVE_MY_TICKETS_VIEW:
      return {
        ...state,
        activeMyTicketsView: action.initialView,
      };
    default:
      return state;
  }
}

export const showAppSpinner = (visibility = true) => ({
  type: APP_ACTION_TYPES.SHOW_APP_SPINNER,
  visibility,
});

export const updateAppConfig = (config) => ({
  type: APP_ACTION_TYPES.UPDATE_APP_CONFIG,
  config,
});

export const updateCurrentLocation = (location) => ({
  type: APP_ACTION_TYPES.UPDATE_CURRENT_LOCATION,
  location,
});

export const setServerResponseStatus = (status) => (dispatch, getState) => {
  if (isInteger(status) && status !== appStatusSelector(getState())) {
    return dispatch(updateAppConfig({ status }));
  }
};

export const setServerRedirectPath = (redirectPath, status) => ({
  type: APP_ACTION_TYPES.UPDATE_APP_CONFIG,
  config: {
    redirectPath,
    status,
  },
});

export const setActiveMyTicketsView = (initialView) => ({
  type: APP_ACTION_TYPES.SET_ACTIVE_MY_TICKETS_VIEW,
  initialView,
});

export const addBranchLinkByCampaign = (campaign, branchLink) => ({
  type: APP_ACTION_TYPES.ADD_BRANCH_LINK,
  campaign,
  branchLink,
});

export const generateBranchLinkByCampaign =
  (campaign, location) => (dispatch, getState) => {
    const state = getState();

    if (branchLinkSelectorByCampaign(state, campaign)) {
      return;
    }

    const branchCallback = (error, branchLink) => {
      if (error) {
        console.error(error);
        return;
      }
      dispatch(addBranchLinkByCampaign(campaign, branchLink));
    };

    const branchData = extendDefaultBranchState({
      defaultBranchData: selectDefaultBranchData(state),
      campaign,
      location,
    });

    generateBranchLink(branchData, branchCallback.bind(this));
  };
