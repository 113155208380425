export const ORGANIZATION_JSON_LD = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Gametime',
  url: 'https://gametime.co',
  logo: 'https://gametime.co/assets/gametime_logo_stacked.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '548 Market St PMB 70955',
    addressLocality: 'San Francisco, California',
    postalCode: '94104',
  },
  founder: {
    '@type': 'Person',
    givenName: 'Brad',
    familyName: 'Griffith',
  },
};

export const CEO_JSON_LD = {
  '@context': 'https://schema.org',
  '@type': 'Person',
  name: 'Brad Griffith',
  disambiguatingDescription: 'CEO & Founder of Gametime',
};
