import { useCallback } from 'react';

import { device, useMediaQuery } from 'hooks/useMediaQuery';

/**
 * zoom level can be 3, 6 and 10 on mobile
 * zoom level can be 9 and 10 on desktop
 */
const useZoomLevel = () => {
  const isMobile = useMediaQuery(device.down.md);
  const getZoomLevel = useCallback(
    (zoomValue) => {
      if (isMobile) {
        if (zoomValue > 2.5) return 10;
        if (zoomValue > 1.5) return 5;
        return 0;
      }

      if (zoomValue > 1.5) return 10;
      return 9;
    },
    [isMobile]
  );

  return { getZoomLevel };
};

export default useZoomLevel;
