import React from 'react';
import { useMaskito } from '@maskito/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import TextInput from 'components/Inputs/TextInput';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import colors from 'styles/colors.constants';
import { UserModel } from 'types';
import { FormSubmitError } from 'utils/errors';
import { maskitoZipCodeOptions } from 'utils/zipCode';

import { UpdateQuoteParams } from '../InsuranceOptions.utils';

import styles from './InsuranceDataCollectionForm.module.scss';

/**
 * matches US ZIP code in classic 5-digit or ZIP+4 format
 */
const US_ZIP_REGEX = /^\d{5}(?:-\d{4})?$/;

const FormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  zipCode: Yup.string()
    .required('ZIP code is required')
    .matches(US_ZIP_REGEX, 'Please enter a valid US ZIP code'),
});

interface InsuranceDataCollectionFormProps {
  zipCode: string;
  /**
   * user fields required for the form
   */
  user: Pick<UserModel, 'first_name' | 'last_name'>;
  onSubmit: (values: UpdateQuoteParams) => Promise<void>;
}

export default function InsuranceDataCollectionForm({
  zipCode,
  user,
  onSubmit,
}: InsuranceDataCollectionFormProps) {
  const zipCodeInputRef = useMaskito({
    options: maskitoZipCodeOptions,
  });

  return (
    <Formik<UpdateQuoteParams>
      initialValues={{
        firstName: user.first_name ?? '',
        lastName: user.last_name ?? '',
        zipCode,
      }}
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);

        try {
          await onSubmit(values);
        } catch (error) {
          if (error instanceof FormSubmitError) {
            formik.setErrors(error.errors);
          } else {
            formik.setErrors({ firstName: 'An unexpected error occurred' });
          }
        }

        formik.setSubmitting(false);
      }}
      validationSchema={FormValidationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <Form className={styles['form']} onSubmit={handleSubmit}>
            <div className={styles['input-row']}>
              <TextInput
                className={styles['input']}
                labelClassName={styles['input-label']}
                id="firstName"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: !!errors.firstName && !!touched.firstName,
                  message: errors.firstName || '',
                }}
              />
              <TextInput
                className={styles['input']}
                labelClassName={styles['input-label']}
                id="lastName"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: !!errors.lastName && !!touched.lastName,
                  message: errors.lastName || '',
                }}
              />
            </div>
            <TextInput
              className={styles['input']}
              labelClassName={styles['input-label']}
              id="zipCode"
              name="zipCode"
              label="ZIP Code"
              placeholder="ZIP Code"
              value={values.zipCode}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: !!errors.zipCode && !!touched.zipCode,
                message: errors.zipCode || '',
              }}
              ref={zipCodeInputRef}
            />
            <div className={styles['button-container']}>
              <ThemedButtonLoader
                backgroundColor={colors.gametimeGreen}
                color={colors.white}
                type="submit"
                loading={isSubmitting}
              >
                Confirm
              </ThemedButtonLoader>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
