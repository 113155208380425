import React, { ButtonHTMLAttributes } from 'react';

import { PlusIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './RadioCardButton.module.scss';

interface RadioCardButtonProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

/**
 * Button that looks like a radio card, meant to be used with a radio group to
 * add additional options or set some related value.
 */
export default function RadioCardButton({
  icon,
  title,
  onClick,
}: RadioCardButtonProps) {
  return (
    <button className={styles['radio-card-button']} onClick={onClick}>
      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles['add-icon']}>
        <PlusIcon color={colors.black} />
      </div>
    </button>
  );
}
