export const LOGOUT_PAGE_TYPES = {
  MY_TICKETS: 'my_tickets',
  MY_ACCOUNT: 'account',
};

export class Logout {
  constructor({ pageType }) {
    this.pageType = pageType;
  }

  eventName() {
    return 'logout';
  }

  toParams() {
    const params = {
      pageType: this.pageType,
    };

    for (const key in params) {
      if (
        Object.prototype.hasOwnProperty.call(params, key) &&
        params[key] === null
      ) {
        delete params[key];
      }
    }
    return params;
  }
}
