const MIGRATED_BLOG_POSTS = {
  'gametime-creates-partnerships-with-pro-sports-teams-to-increase-millennial-sales-9bdda6747ccf':
    'gametime-creates-partnerships-with-pro-sports-teams-to-increase-millennial-sales',
  'gametime-recognized-as-one-of-fastest-growing-companies-in-north-america-on-deloittes-2017-df8e6e1ac65':
    'gametime-recognized-as-one-of-fastest-growing-companies-in-north-america-on-deloittes-2017-tech',
  'l-a-teams-top-list-of-mlbs-greatest-ticket-values-in-2017-3b7bc478586b':
    'la-teams-top-list-of-mlbs-greatest-ticket-values-in-2017',
  'gametime-partners-with-axs-to-continue-open-platform-expansion-819029821578':
    'gametime-partners-with-axs-to-continue-open-platform-expansion',
  'judging-the-difference-f3b8dd3780d5': 'judging-the-difference',
  'gametime-and-ticketmaster-some-things-you-may-want-to-know-902838f17699':
    'gametime-and-ticketmaster-partnership',
  'standing-together-gametime-to-donate-all-proceeds-from-welcome-benefit-concert-to-aclu-8c953fa7cae6':
    'standing-together-gametime-to-donate-all-proceeds-from-welcome-benefit-concert-to-aclu',
  'last-minute-fans-drive-gametime-to-over-220-growth-in-2016-bcd61215884f':
    'last-minute-fans-drive-gametime-growth-in-2016',
  'gametime-increases-momentum-with-20m-in-new-funding-to-grow-last-minute-ticket-category-for-a1e3c566d348':
    'gametime-increases-momentum-with-20m-funding',
  'gametime-debuts-theater-events-in-time-for-holidays-2b28df782395':
    'gametime-debuts-theater-events-in-time-for-holidays',
};

export const isMigratedPost = (slug) => slug in MIGRATED_BLOG_POSTS;

export const getModifiedPostSlug = (slug) => MIGRATED_BLOG_POSTS[slug] || slug;
