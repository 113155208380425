import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './EventTab.module.scss';

const EventTab = ({ setActiveView, eventType, isActive }) => {
  return (
    <button
      type="button"
      onClick={() => setActiveView(eventType)}
      className={classNames(styles['event-tab'], {
        [styles.active]: isActive,
      })}
    >
      {eventType}
    </button>
  );
};

EventTab.propTypes = {
  setActiveView: PropTypes.func,
  eventType: PropTypes.string,
  isActive: PropTypes.bool,
};

export default EventTab;
