import config from 'config/config';
import { HttpClient } from 'services/HttpClient/HttpClient';
import { Venue } from 'types';

export interface VenuesRequest {
  slug?: string;
}

export interface VenuesResponse {
  results: Venue[];
  more: boolean;
  page: number;
  per_page: number;
  total: number;
}

const httpClient = new HttpClient(config.MOBILE_API_URL);

export const getVenues = ({
  slug,
}: VenuesRequest): Promise<VenuesResponse | null> => {
  const searchParams = slug ? { slug } : undefined;
  return httpClient.request({
    path: '/v1/venues',
    method: 'GET',
    searchParams,
  });
};
