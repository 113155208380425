import React from 'react';

export default (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59513 3.68432C9.42213 3.93881 10.0237 4.70947 10.0237 5.61903C10.0237 6.52907 9.42179 7.29931 8.59513 7.55372V12.7619C8.59513 13.0906 8.32863 13.3571 7.99989 13.3571C7.67115 13.3571 7.40465 13.0906 7.40465 12.7619V7.55374C6.57764 7.29926 5.97607 6.52859 5.97607 5.61903C5.97607 4.70899 6.57798 3.93876 7.40465 3.68434V3.23808C7.40465 2.90934 7.67115 2.64284 7.99989 2.64284C8.32863 2.64284 8.59513 2.90934 8.59513 3.23808V3.68432ZM7.16655 5.61903C7.16655 6.07832 7.54031 6.45237 7.99989 6.45237C8.45918 6.45237 8.83322 6.07861 8.83322 5.61903C8.83322 5.15974 8.45947 4.7857 7.99989 4.7857C7.5406 4.7857 7.16655 5.15945 7.16655 5.61903Z"
      fill={props.fill || '#fff'}
    />
    <path
      d="M3.9523 8.92242C4.77931 9.17691 5.38088 9.94757 5.38088 10.8571C5.38088 11.7675 4.7786 12.5379 3.95155 12.7921C3.93585 13.1068 3.67569 13.3571 3.35706 13.3571C3.03844 13.3571 2.77829 13.1068 2.76258 12.7921C1.93518 12.5378 1.33325 11.767 1.33325 10.8571C1.33325 9.94709 1.93516 9.17686 2.76182 8.92244V3.23808C2.76182 2.90934 3.02832 2.64284 3.35706 2.64284C3.6858 2.64284 3.9523 2.90934 3.9523 3.23808V8.92242ZM2.52373 10.8571C2.52373 11.3164 2.89748 11.6905 3.35706 11.6905C3.81635 11.6905 4.1904 11.3167 4.1904 10.8571C4.1904 10.3978 3.81664 10.0238 3.35706 10.0238C2.89777 10.0238 2.52373 10.3976 2.52373 10.8571Z"
      fill={props.fill || '#fff'}
    />
    <path
      d="M13.2379 7.25575C14.065 7.51024 14.6665 8.2809 14.6665 9.19046C14.6665 10.1005 14.0646 10.8707 13.2379 11.1252V12.7619C13.2379 13.0906 12.9714 13.3571 12.6427 13.3571C12.314 13.3571 12.0475 13.0906 12.0475 12.7619V11.1252C11.2205 10.8707 10.6189 10.1 10.6189 9.19046C10.6189 8.28042 11.2208 7.51019 12.0475 7.25577V3.23808C12.0475 2.90934 12.314 2.64284 12.6427 2.64284C12.9714 2.64284 13.2379 2.90934 13.2379 3.23808V7.25575ZM11.8094 9.19046C11.8094 9.64976 12.1831 10.0238 12.6427 10.0238C13.102 10.0238 13.476 9.65005 13.476 9.19046C13.476 8.73117 13.1023 8.35713 12.6427 8.35713C12.1834 8.35713 11.8094 8.73088 11.8094 9.19046Z"
      fill={props.fill || '#fff'}
    />
  </svg>
);
