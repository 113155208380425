import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Click, ClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import { fetchGlobalHeaderTab } from 'store/modules/content/global/actions';
import { selectHeaderPromoCategories } from 'store/modules/content/global/selectors';
import { isObjectEmpty } from 'utils/objects';

import styles from './HeaderPromoCategories.module.scss';

/**
 * Fetches content from the global "Promo Header Tab" page in ButterCMS.
 *
 * API Slug: `promo-header-tab`
 *
 * To update the content, update the title and URL fields. The active checkbox will show/hide the tab.
 */
const HeaderPromoCategories = ({ tabs, dispatch }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchGlobalHeaderTab());
      } catch (error) {
        console.error(error);
      }
    };

    if (isObjectEmpty(tabs)) {
      fetchData();
    }
  }, [tabs, dispatch]);

  if (!Array.isArray(tabs) || tabs.length === 0) {
    return null;
  }

  return (
    <div className={styles['tabs-container']}>
      {tabs.map((tab) => {
        if (
          isObjectEmpty(tab) ||
          !tab.active ||
          tab.title.length === 0 ||
          tab.url.length === 0
        ) {
          return null;
        }

        const tracker = tab.title.split(' ').join('_').toLowerCase();
        const clickTracker = new ClickTracker()
          .interaction(Click.INTERACTIONS.HEADER())
          .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
          .targetPageType({ target_page_type: tracker })
          .payload({
            section: tracker,
          });

        return (
          <Link
            key={tab.title}
            href={tab.url}
            className={styles['tab-item']}
            clickTracker={clickTracker}
          >
            {tab.title}
          </Link>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tabs: selectHeaderPromoCategories(state),
});

HeaderPromoCategories.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(HeaderPromoCategories);
