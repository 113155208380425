import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import PostPurchaseSurveyModal from 'components/Modals/PostPurchaseSurveyModal';

import styles from './GTSurveyModal.module.scss';

const GTSurveyModal = ({ show, onHide, transactionId }) => {
  return (
    <Modal
      className={styles['gt-survey-modal']}
      overlayClassName={styles['gt-survey-modal-overlay']}
      isOpen={show}
      onRequestClose={onHide}
      ariaHideApp={false}
    >
      <PostPurchaseSurveyModal transactionId={transactionId} onHide={onHide} />
    </Modal>
  );
};

GTSurveyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
};

export default GTSurveyModal;
