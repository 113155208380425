import React from 'react';
import PropTypes from 'prop-types';

import Emoji from 'components/Emoji/Emoji';
import { CATEGORIES } from 'store/modules/categories/category.helpers';

import styles from './CollectionCardPromoBanner.module.scss';

const hasVaxBanner = /proof|vax|test|vaccination|booster|vax+booster|covid/i;

const CollectionCardPromoBanner = ({ banner, category }) => {
  const { emoji = '', headline = '' } = banner;

  if (
    category !== CATEGORIES.MLB ||
    hasVaxBanner.test(headline) ||
    !headline.length
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <Emoji emoji={emoji} />
      </span>
      {headline}
    </div>
  );
};

CollectionCardPromoBanner.propTypes = {
  banner: PropTypes.shape({
    emoji: PropTypes.string,
    headline: PropTypes.string,
  }),
  category: PropTypes.string,
};

export default CollectionCardPromoBanner;
