import React from 'react';

import styles from './DetailsStackItem.module.scss';

interface DetailsStackItemProps {
  icon?: React.ReactNode;
  body: React.ReactNode;
  testId?: string;
}

export default function DetailsStackItem({
  icon,
  body,
  testId,
}: DetailsStackItemProps) {
  return (
    <div data-testid={testId} className={styles['stacked-card-item']}>
      {icon && (
        <div
          data-testid={testId ? testId + '_icon' : undefined}
          className={styles['icon']}
        >
          {icon}
        </div>
      )}
      <div data-testid={testId ? testId + '_body' : undefined}>{body}</div>
    </div>
  );
}
