/**
 * Default - shows an overlay on top of all elements on the page
 * Submitting - shows the overlay with a spinner if loading, check mark if loaded
 * Loading - shows the overlay with a spinner while loading, null if loaded
 */
export const VARIANTS = {
  DEFAULT: 'default',
  LOADING: 'loading',
  SUBMITTING: 'submitting',
};
