import React from 'react';

export default (props) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill || 'black'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1C4.13407 1 1 4.13408 1 8C1 11.8659 4.13407 15 8 15C11.8659 15 15 11.8659 15 8C15 4.13408 11.8659 1 8 1ZM7.64645 3.85354C7.84172 3.65828 8.1583 3.65828 8.35356 3.85354L9.41422 4.9142C9.60948 5.10946 9.60948 5.42604 9.41422 5.62131L8.35356 6.68197C8.1583 6.87723 7.84172 6.87723 7.64645 6.68197L6.58579 5.62131C6.39053 5.42604 6.39053 5.10946 6.58579 4.9142L7.64645 3.85354ZM9.00001 11.5C9.00001 11.7761 8.77615 12 8.50001 12H7.50001C7.22386 12 7.00001 11.7761 7.00001 11.5V8.49999C7.00001 8.22384 7.22386 7.99999 7.50001 7.99999H8.50001C8.77615 7.99999 9.00001 8.22384 9.00001 8.49999V11.5Z"
    />
  </svg>
);
