import React from 'react';
import PropTypes from 'prop-types';

import SimpleCollectionCard from './SimpleCollectionCard';

import styles from './SimplePerformersSection.module.scss';

function _SimplePerformersSection({ performers, title }) {
  if (performers.length === 0) {
    return null;
  }

  return (
    <div className={styles['simple-performers-container']}>
      <div className={styles['simple-performers-header']}>{title}</div>
      <div className={styles['simple-performers-body']}>
        {performers.map((pic) => (
          <div key={pic.id()} className={styles['team-card']}>
            <SimpleCollectionCard source={pic} />
          </div>
        ))}
      </div>
    </div>
  );
}

_SimplePerformersSection.propTypes = {
  performers: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default _SimplePerformersSection;
