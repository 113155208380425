import React from 'react';

import colors from 'styles/colors.constants';

type Props = Pick<
  React.SVGProps<SVGSVGElement>,
  'width' | 'height' | 'fill' | 'className'
>;

export default function GroupIcon({
  width = '16',
  height = '16',
  fill = colors.white,
  className,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.666626 13.3333V11.4666C0.666626 11.0888 0.763848 10.7416 0.958293 10.425C1.15274 10.1083 1.41107 9.86663 1.73329 9.69996C2.42218 9.35552 3.12218 9.09718 3.83329 8.92496C4.5444 8.75274 5.26663 8.66663 5.99996 8.66663C6.73329 8.66663 7.45552 8.75274 8.16663 8.92496C8.87774 9.09718 9.57774 9.35552 10.2666 9.69996C10.5888 9.86663 10.8472 10.1083 11.0416 10.425C11.2361 10.7416 11.3333 11.0888 11.3333 11.4666V13.3333H0.666626ZM12.6666 13.3333V11.3333C12.6666 10.8444 12.5305 10.375 12.2583 9.92496C11.9861 9.47496 11.6 9.08885 11.1 8.76663C11.6666 8.83329 12.2 8.94718 12.7 9.10829C13.2 9.2694 13.6666 9.46663 14.1 9.69996C14.5 9.92218 14.8055 10.1694 15.0166 10.4416C15.2277 10.7138 15.3333 11.0111 15.3333 11.3333V13.3333H12.6666ZM5.99996 7.99996C5.26663 7.99996 4.63885 7.73885 4.11663 7.21663C3.5944 6.6944 3.33329 6.06663 3.33329 5.33329C3.33329 4.59996 3.5944 3.97218 4.11663 3.44996C4.63885 2.92774 5.26663 2.66663 5.99996 2.66663C6.73329 2.66663 7.36107 2.92774 7.88329 3.44996C8.40552 3.97218 8.66663 4.59996 8.66663 5.33329C8.66663 6.06663 8.40552 6.6944 7.88329 7.21663C7.36107 7.73885 6.73329 7.99996 5.99996 7.99996ZM12.6666 5.33329C12.6666 6.06663 12.4055 6.6944 11.8833 7.21663C11.3611 7.73885 10.7333 7.99996 9.99996 7.99996C9.87774 7.99996 9.72218 7.98607 9.53329 7.95829C9.3444 7.93052 9.18885 7.89996 9.06663 7.86663C9.36663 7.51107 9.59718 7.11663 9.75829 6.68329C9.9194 6.24996 9.99996 5.79996 9.99996 5.33329C9.99996 4.86663 9.9194 4.41663 9.75829 3.98329C9.59718 3.54996 9.36663 3.15551 9.06663 2.79996C9.22218 2.7444 9.37774 2.70829 9.53329 2.69163C9.68885 2.67496 9.8444 2.66663 9.99996 2.66663C10.7333 2.66663 11.3611 2.92774 11.8833 3.44996C12.4055 3.97218 12.6666 4.59996 12.6666 5.33329Z"
        fill={fill}
      />
    </svg>
  );
}
