import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './TransferLink.module.scss';

interface TransferLinkProps {
  number: number;
  link: string;
}

const TransferLink = ({ number, link }: TransferLinkProps) => {
  const [clicked, setClicked] = useState(false);
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => setClicked(true)}
    >
      <div className={styles['transfer-link-container']}>
        <span
          className={classNames(styles['transfer'], {
            [styles['clicked']]: clicked,
          })}
        >
          Transfer {number}
        </span>
        <span className={styles.link}>accept</span>
      </div>
    </a>
  );
};

export default TransferLink;
