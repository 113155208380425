import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { MAX_PERFORMERS_MOBILE } from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/constants';
import {
  getLocalPerformers,
  makeSelectFilteredHeaderPerformersByCategory,
} from 'store/modules/data/Performers/selectors';

import DropdownButton from './DropdownButton';

const MobilePerformersDropdown = ({ performers, onClick, localPerformers }) => {
  return performers
    .slice(0, MAX_PERFORMERS_MOBILE)
    .map((performer) => (
      <DropdownButton
        key={performer.id}
        text={performer.name}
        to={performer.getPath()}
        onClick={onClick}
        isLocal={
          localPerformers &&
          !!localPerformers.find((e) => e.id === performer.id)
        }
      />
    ));
};

MobilePerformersDropdown.propTypes = {
  performers: PropTypes.array,
  onClick: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const filterHeaderPerformersByCategory =
    makeSelectFilteredHeaderPerformersByCategory();

  return {
    performers: filterHeaderPerformersByCategory(state, props),
    localPerformers: getLocalPerformers(state, props),
  };
};

export default connect(mapStateToProps)(MobilePerformersDropdown);
