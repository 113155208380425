import url from 'url';

export type FastlyParams = {
  format?: string;
  width?: number;
  height?: number;
  auto: string;
  quality?: string | number;
  disable?: string;
  dpr?: number;
  fit?: string;
};

const DEFAULT_FASTLY_PARAMS = {
  auto: 'webp',
  format: 'pjpg',
  quality: '50,35',
  disable: 'upscale',
  dpr: 1,
};

export const RESPONSIVE_IMAGE_PARAMS = {
  ...DEFAULT_FASTLY_PARAMS,
  fit: 'crop',
};

export const MINIMUM_FASTLY_PARAMS = {
  ...DEFAULT_FASTLY_PARAMS,
  quality: 50,
  width: 150,
};

export const DEFAULT_OG_PARAMS = {
  ...DEFAULT_FASTLY_PARAMS,
  fit: 'crop',
  width: 1200,
  height: 1200,
};

export const PRELOADED_IMAGE_PARAMS = {
  auto: 'webp',
};

const MAX_RESOLUTION = 4800;

export const calcBreakpoint = (
  width: number,
  dpr = 1,
  steps = 40,
  maxRes = MAX_RESOLUTION
) => {
  const nominalBreakpoint = steps * Math.ceil(width / steps);
  if (nominalBreakpoint * dpr > maxRes) {
    return steps * Math.ceil(maxRes / dpr / steps);
  }
  return nominalBreakpoint;
};

const SEO_KEY = 'gametime-images';
const EXTENSION_REGEX = /\.([0-9a-z]+)(?:[?#]|$)/i;

const preparePathname = (
  pathname: string,
  seoPrefix: string | undefined,
  seoPostfix: string | undefined
): string => {
  if (seoPostfix || seoPrefix) {
    pathname = `${seoPrefix ? `/${seoPrefix}` : ''}/${SEO_KEY}${pathname}`;
    if (seoPostfix) {
      pathname = pathname.replace(EXTENSION_REGEX, `/${seoPostfix}.$1`);
    }
  }
  return pathname;
};

export const formatUrl = (
  protocol: string,
  hostname: string,
  pathname: string,
  search: string,
  params: FastlyParams
): string => {
  const searchParams = new URLSearchParams(search);
  return url.format({
    protocol,
    hostname,
    pathname,
    query: {
      ...params,
      ...Object.fromEntries(searchParams),
    },
  });
};

export const generateFastlyImagePathname = (
  src: string,
  params: FastlyParams,
  seoPostfix?: string,
  seoPrefix?: string
) => {
  try {
    const urlFromSrc = new URL(src);
    const { search, protocol, hostname } = urlFromSrc;
    let { pathname } = urlFromSrc;

    pathname = preparePathname(pathname, seoPrefix, seoPostfix);

    return formatUrl(protocol, hostname, pathname, search, params);
  } catch {
    let pathname = src;
    pathname = preparePathname(pathname, seoPrefix, seoPostfix);

    return formatUrl('', '', pathname, '', params);
  }
};

export const getDpr = (steps = 0.5, maxDpr = 2) => {
  if (__CLIENT__ && typeof window !== 'undefined') {
    return Math.min(maxDpr, steps * Math.ceil(window.devicePixelRatio / steps));
  }
  return 1;
};
