/**
 * returns an object with generate action names
 * @param moduleName
 * @param actions
 * @param postFixes
 * @returns {{
 *  CREATE_BRAINTREE_NONCE: 'PURCHASES/CREATE_BRAINTREE_NONCE',
 *  CREATE_BRAINTREE_NONCE_SUCCESS: 'PURCHASES/CREATE_BRAINTREE_NONCE_SUCCESS',
 *  CREATE_BRAINTREE_NONCE_FAIL: 'PURCHASES/CREATE_BRAINTREE_NONCE_FAIL'
 * }}
 */
export const generateActionTypes = (
  actions: string[],
  moduleName = '',
  postFixes: string[] = ['']
) => {
  const promiseActions: Record<string, string> = {};
  const base = moduleName ? `${moduleName.toUpperCase()}/` : '';
  actions.forEach((action) => {
    postFixes.forEach((postFix) => {
      const actionName = `${action}${postFix}`;
      promiseActions[actionName] = `${base}${actionName}`;
    });
  });
  return promiseActions;
};
