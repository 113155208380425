import React from 'react';

interface MLBIconProps {
  width?: string;
  height?: string;
  viewBox?: string;
  fillTrademark?: string;
}

export default function MLBIcon({
  width = '24',
  height = '24',
  viewBox = '0 0 160 84',
  fillTrademark = '#BC0022',
}: MLBIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      data-testid="mlb-icon"
    >
      <path
        d="M142.654 0H12.9712C9.50231 0 6.2421 1.33191 3.77707 3.73731C1.33191 6.17252 0 9.45261 0 12.9911V70.8598C0 74.3983 1.34185 77.6684 3.76713 80.0738C6.23216 82.5289 9.50231 83.8807 12.9812 83.8807H142.654C149.791 83.8807 155.605 78.0362 155.605 70.8498V12.9911C155.605 9.47249 154.253 6.1924 151.808 3.76713C149.353 1.34185 146.103 0.00993965 142.654 0.00993965V0Z"
        fill="white"
      />
      <path
        d="M151.043 12.9911C151.043 10.7447 150.188 8.57791 148.598 6.9975C146.998 5.4171 144.871 4.56229 142.654 4.56229H106.404L127.198 39.3113L129.116 39.5499L130.279 41.1402V42.6212L131.591 42.8896L132.784 44.5396V45.9709L134.146 46.2094L135.507 47.7004V50.9705C135.507 50.9705 138.449 53.6642 141.044 54.688C142.883 55.3937 143.081 58.3159 144.185 59.8963C145.566 62.1725 147.465 63.087 147.057 64.3692C146.133 67.7884 142.634 73.5335 139.384 73.8118H126.512V79.3283H142.634C147.276 79.3283 151.023 75.5314 151.023 70.8498V12.9911"
        fill="#BC0022"
      />
      <path
        d="M62.3811 73.8715H56.139C56.0794 58.306 61.3971 49.6883 67.7088 47.9787C68.5735 47.8196 68.1561 43.5655 67.0627 42.2733C67.0627 42.2733 64.0013 42.323 63.3552 42.2336C62.5601 42.0149 63.1067 41.1402 63.1067 41.1402L66.0588 34.8285L65.6513 33.099H54.8071L63.7727 26.837C64.1902 10.3074 81.1373 9.01525 91.3552 15.8537C97.4184 19.8793 97.8955 27.841 97.4184 33.3475C97.3389 33.7053 95.838 33.4668 95.838 33.4668C95.838 33.4668 94.8043 39.5002 97.4979 39.5002H109.426C114.286 39.3113 118.978 42.5914 118.978 42.5914L120.121 38.4267L94.029 4.56229H12.9711C10.7248 4.56229 8.57781 5.4171 6.96758 6.9975C5.37724 8.57791 4.55225 10.7547 4.55225 12.9911V70.8598C4.55225 73.126 5.37724 75.263 6.96758 76.8335C8.58775 78.4437 10.7347 79.3283 12.9711 79.3283H65.5817C64.2995 77.1118 62.8185 74.5971 62.3712 73.8715"
        fill="#041E42"
      />
      <path
        d="M16.2214 61.5861C16.2214 58.4451 18.7461 55.8608 21.9367 55.8211C25.1472 55.8211 27.7514 58.4153 27.7514 61.5861C27.7514 64.7568 25.1572 67.3511 21.9367 67.3511C18.7461 67.371 16.2214 64.7767 16.2214 61.5861Z"
        fill="white"
      />
      <path
        d="M158.14 81.1871C158.14 80.541 157.752 80.4416 157.196 80.4416H156.003V82.8669H156.52V81.9027H156.937L157.494 82.8669H158.07L157.494 81.9027C157.911 81.8729 158.14 81.6343 158.14 81.1871ZM157.206 81.4654H156.52V80.8789H157.086C157.325 80.8789 157.633 80.8789 157.633 81.1473C157.633 81.4157 157.494 81.4654 157.206 81.4654Z"
        fill={fillTrademark}
      />
      <path
        d="M156.987 79.3284C155.695 79.3284 154.671 80.3621 154.671 81.6741C154.671 82.9862 155.695 84 156.987 84C158.279 84 159.323 82.9563 159.323 81.6741C159.323 80.3919 158.289 79.3284 156.987 79.3284ZM156.987 83.4931C155.993 83.4931 155.188 82.7079 155.188 81.6741C155.188 80.6404 155.993 79.8353 156.987 79.8353C157.981 79.8353 158.816 80.6205 158.816 81.6741C158.816 82.7277 157.991 83.4931 156.987 83.4931Z"
        fill={fillTrademark}
      />
    </svg>
  );
}
