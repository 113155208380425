import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import HeaderPromoCategories from 'components/HeaderPromoCategories/HeaderPromoCategories';
import { NCAA_CATEGORIES } from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/CategoryPerformersNCAA';
import { OTHER_CATEGORIES } from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/CategoryPerformersOther';
import {
  CATEGORIES_DROPDOWN,
  NCAA_CATEGORY,
  OTHER_CATEGORY,
  SHOWS_SUB_CATEGORIES,
  SPORTS_SUB_CATEGORIES,
  STATIC_SPORTS_SUB_CATEGORIES,
} from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/constants';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import DownloadIcon from 'icons/DownloadIcon';
import LogOutIcon from 'icons/LogOutIcon';
import ProfileFillIcon from 'icons/ProfileFillIcon';
import TicketSetIcon from 'icons/TicketSetIcon';
import { setActiveMyTicketsView as setActiveMyTicketsViewDispatch } from 'store/modules/app/app';
import {
  CATEGORY_URLS,
  getCategoryPath,
  MAJOR_LEAGUE_SPORTS_PERFORMERS,
} from 'store/modules/categories/category.helpers';
import { setPerformersCategory as setPerformersCategoryDispatch } from 'store/modules/data/Performers/actions';
import {
  MODALS,
  showModal as showModalDispatch,
} from 'store/modules/modals/modals';
import { logout as logoutDispatch } from 'store/modules/user/actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { APP_STORE_LINKS } from 'utils/appStoreLinks';

import DropdownButton, { TYPES } from './DropdownButton';
import MobilePerformersDropdown from './MobilePerformersDropdown';

const VIEWS = {
  DEFAULT: 'DEFAULT',
  CATEGORY_GROUP: 'CATEGORY_GROUP',
  CATEGORY: 'CATEGORY',
};

const MobileDropdown = ({
  user,
  showModal,
  setPerformersCategory,
  logout,
  setActiveMyTicketsView,
  onClick,
  currentMetro,
  fetchLocalSportsTeams,
}) => {
  const navigate = useNavigate();
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [view, setView] = useState(VIEWS.DEFAULT);
  const [currentCategoryGroup, setCurrentCategoryGroup] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const { isIOS } = useAppContext().state;

  useLockBodyScroll();

  const setDefaultView = () => {
    setView(VIEWS.DEFAULT);
    setCurrentCategoryGroup('');
    setCurrentCategory('');
  };

  const fetchPerformersData = () => {
    const category = currentCategory || currentCategoryGroup;

    if (
      !STATIC_SPORTS_SUB_CATEGORIES.includes(category) &&
      category !== CATEGORIES_DROPDOWN.SPORTS
    ) {
      setPerformersCategory(category);
    }
  };

  const setCategoryGroupView = (categoryGroup) => {
    setView(VIEWS.CATEGORY_GROUP);
    setCurrentCategoryGroup(categoryGroup);
    setCurrentCategory('');

    fetchPerformersData();
  };

  const setSubCategoryView = (category) => {
    setView(VIEWS.CATEGORY);
    setCurrentCategoryGroup('');
    setCurrentCategory(category);

    fetchPerformersData();
  };

  const handleLoginClick = () => {
    showModal(MODALS.LOGIN);

    return onClick instanceof Function && onClick();
  };

  const handleLogoutClick = async () => {
    await logout();

    navigate('/');
    return onClick instanceof Function && onClick();
  };

  const renderPerformersList = (category) => {
    let categoryTitle = CATEGORY_URLS[category]
      ? CATEGORY_URLS[category].title
      : category.toUpperCase();

    if (categoryTitle === 'Shows') {
      categoryTitle = 'Theater';
    }

    const stateCategory = currentCategory || currentCategoryGroup;

    if (/tickets$/i.test(categoryTitle)) {
      categoryTitle = `All ${categoryTitle}`;
    } else {
      categoryTitle = `All ${categoryTitle} Tickets`;
    }

    let performersList = [];
    switch (category) {
      case OTHER_CATEGORY:
        performersList = OTHER_CATEGORIES.map((other) => (
          <DropdownButton
            key={other.id}
            text={other.text}
            to={other.link}
            onClick={onClick}
          />
        ));
        break;
      case NCAA_CATEGORY:
        performersList = NCAA_CATEGORIES.map((ncaa) => (
          <DropdownButton
            key={ncaa.id}
            text={ncaa.text}
            to={ncaa.link}
            onClick={onClick}
          />
        ));
        break;
      default:
        performersList.push(
          <MobilePerformersDropdown
            key={stateCategory}
            category={stateCategory}
            onClick={onClick}
            metro={currentMetro.id}
          />
        );
        break;
    }

    if (NCAA_CATEGORY === category || OTHER_CATEGORY === category) {
      return performersList;
    }

    performersList.unshift(
      <DropdownButton
        key={categoryTitle}
        text={categoryTitle}
        to={getCategoryPath(category)}
        onClick={onClick}
        capitalize
      />
    );

    return performersList;
  };

  const handleMyTicketsClick = () => {
    setActiveMyTicketsView('upcoming');
    navigate('/my-tickets');

    return onClick instanceof Function && onClick();
  };

  const handleMyAccountClick = () => {
    navigate('/my-account');

    return onClick instanceof Function && onClick();
  };

  const renderSportsSubCategoryList = () => {
    return SPORTS_SUB_CATEGORIES.map((category) => {
      const categoryTitle = CATEGORY_URLS[category]
        ? CATEGORY_URLS[category].title
        : category.toUpperCase();

      const handleClick = () => {
        setSubCategoryView(category);
      };

      return (
        <DropdownButton
          key={category}
          text={categoryTitle}
          type={TYPES.SUBTITLE}
          onClick={handleClick}
        />
      );
    });
  };

  const renderShowsSubCategoryList = () => {
    return SHOWS_SUB_CATEGORIES.map((category) => {
      let categoryTitle = CATEGORY_URLS[category]
        ? CATEGORY_URLS[category].title
        : category.toUpperCase();

      if (categoryTitle === 'Shows') {
        categoryTitle = 'Theater';
      }

      const handleClick = () => {
        setSubCategoryView(category);
      };

      return (
        <DropdownButton
          key={category}
          text={categoryTitle}
          type={TYPES.SUBTITLE}
          onClick={handleClick}
        />
      );
    });
  };

  // renders SPORTS, SHOWS, MUSIC
  const renderCategoryGroupButton = (categoryGroup, active, type) => {
    const handleClick = () => {
      if (currentCategoryGroup === categoryGroup) {
        setDefaultView();
      } else {
        let section = categoryGroup;
        if (section === 'theater') {
          section = 'shows';
        }

        const tracker = new ClickTracker()
          .interaction(Click.INTERACTIONS.HEADER())
          .sourcePageType(clickContext.sourcePageType)
          .payload({ section });
        analytics?.track(new Click(tracker.json()));
        setCategoryGroupView(categoryGroup);
      }
    };
    const text =
      categoryGroup === CATEGORIES_DROPDOWN.THEATER ? 'shows' : categoryGroup;

    return (
      <DropdownButton
        key={categoryGroup}
        text={text}
        active={active}
        type={type}
        onClick={handleClick}
        capitalize
        hasBottomDivider={false}
      />
    );
  };

  // renders MLB, NHL, NFL etc... (for shows category it renders theater and comedy)
  const renderCategoryButton = (category, active, type) => {
    let categoryTitle =
      CATEGORY_URLS[category]?.title || category.toUpperCase();

    if (categoryTitle === 'Shows') {
      categoryTitle = 'Theater';
    }

    const handleClick = () => {
      if (currentCategory === category) {
        if (SHOWS_SUB_CATEGORIES.includes(category)) {
          setCategoryGroupView(CATEGORIES_DROPDOWN.THEATER);
        } else {
          setCategoryGroupView(CATEGORIES_DROPDOWN.SPORTS);
        }
      } else {
        setSubCategoryView(category);
      }
    };

    return (
      <DropdownButton
        key={category}
        text={categoryTitle}
        active={active}
        type={type}
        onClick={handleClick}
      />
    );
  };

  const renderCategoryGroupContent = () => {
    switch (currentCategoryGroup) {
      case CATEGORIES_DROPDOWN.SPORTS:
        return renderSportsSubCategoryList();
      case CATEGORIES_DROPDOWN.MUSIC:
        return renderPerformersList(currentCategoryGroup);
      case CATEGORIES_DROPDOWN.THEATER:
        return renderShowsSubCategoryList();
      default:
        return null;
    }
  };

  const renderCategoryContent = () => {
    if (MAJOR_LEAGUE_SPORTS_PERFORMERS.includes(currentCategory)) {
      fetchLocalSportsTeams(currentMetro.id, currentCategory);
    }

    return renderPerformersList(currentCategory);
  };

  const renderAccountButton = () => {
    return !user ? (
      <>
        <DropdownButton
          key="get-the-app"
          text="Get the App"
          icon={<DownloadIcon />}
          type={TYPES.SUBTITLE}
          href={isIOS ? APP_STORE_LINKS.iOS.url : APP_STORE_LINKS.android.url}
          hideCaret
          hasTopDivider
        />
        <DropdownButton
          key="log-in"
          text="Log In"
          icon={<ProfileFillIcon />}
          type={TYPES.SUBTITLE}
          onClick={handleLoginClick}
          hideCaret
        />
      </>
    ) : (
      <>
        <DropdownButton
          key="my-tickets"
          text="My Tickets"
          icon={<TicketSetIcon />}
          type={TYPES.SUBTITLE}
          hideCaret
          hasBottomDivider={false}
          onClick={handleMyTicketsClick}
          hasTopDivider
        />

        <DropdownButton
          key="my-account"
          text="My Account"
          icon={<ProfileFillIcon />}
          type={TYPES.SUBTITLE}
          hideCaret
          hasBottomDivider={false}
          onClick={handleMyAccountClick}
        />
        <DropdownButton
          key="get-the-app"
          text="Get the App"
          icon={<DownloadIcon />}
          type={TYPES.SUBTITLE}
          href={isIOS ? APP_STORE_LINKS.iOS.url : APP_STORE_LINKS.android.url}
          hideCaret
        />
        <DropdownButton
          key="log-out"
          text="Log Out"
          icon={<LogOutIcon />}
          type={TYPES.SUBTITLE}
          onClick={handleLogoutClick}
          hideCaret
        />
      </>
    );
  };

  switch (view) {
    case VIEWS.CATEGORY_GROUP:
      return (
        <div>
          {renderCategoryGroupButton(currentCategoryGroup, true, TYPES.TITLE)}
          {renderCategoryGroupContent()}
        </div>
      );
    case VIEWS.CATEGORY:
      return (
        <div>
          {renderCategoryButton(currentCategory, true, TYPES.TITLE)}
          {renderCategoryContent(currentCategory)}
        </div>
      );
    case VIEWS.DEFAULT:
    default:
      return (
        <div>
          {Object.values(CATEGORIES_DROPDOWN).map((categoryGroup) =>
            renderCategoryGroupButton(categoryGroup, false, TYPES.TITLE)
          )}
          <HeaderPromoCategories />
          {renderAccountButton()}
        </div>
      );
  }
};

const mapStateToProps = (state) => {
  return {
    user: selectUserDetails(state),
  };
};

const mapDispatchToProps = {
  showModal: showModalDispatch,
  setPerformersCategory: setPerformersCategoryDispatch,
  logout: logoutDispatch,
  setActiveMyTicketsView: setActiveMyTicketsViewDispatch,
};

MobileDropdown.propTypes = {
  setPerformersCategory: PropTypes.func,
  onClick: PropTypes.func,
  user: PropTypes.object,
  showModal: PropTypes.func,
  currentMetro: PropTypes.object.isRequired,
  fetchLocalSportsTeams: PropTypes.func.isRequired,
  logout: PropTypes.func,
  setActiveMyTicketsView: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileDropdown);
