import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CategoriesButtonList from 'components/Headers/CategoriesButtonList/CategoriesButtonList';
import { device, useMediaQuery } from 'hooks';

import CategoryPerformersList from './CategoryPerformersList';
import { CATEGORIES_DROPDOWN, MAX_PERFORMERS_DESKTOP } from './constants';
import ShowsCategories from './ShowsCategories';
import SportsCategories from './SportsCategories';

import styles from './CategoriesDropdown.module.scss';

function CategoriesDropdown({
  type,
  onCategoryClick,
  onClick,
  currentMetro,
  fetchLocalSportsTeams,
}) {
  const isMobile = useMediaQuery(device.down.md);

  function renderList() {
    return (
      <>
        <div
          className={classNames({
            [styles.hide]: type !== CATEGORIES_DROPDOWN.MUSIC,
          })}
        >
          <CategoryPerformersList
            category={CATEGORIES_DROPDOWN.MUSIC}
            limit={MAX_PERFORMERS_DESKTOP}
            onClick={onClick}
          />
        </div>
        <div
          className={classNames({
            [styles.hide]: type !== CATEGORIES_DROPDOWN.THEATER,
          })}
        >
          <ShowsCategories
            limit={MAX_PERFORMERS_DESKTOP}
            onClick={onClick}
            currentMetro={currentMetro}
          />
        </div>
        <div
          className={classNames({
            [styles.hide]: type !== CATEGORIES_DROPDOWN.SPORTS,
          })}
        >
          <SportsCategories
            limit={MAX_PERFORMERS_DESKTOP}
            onClick={onClick}
            currentMetro={currentMetro}
            fetchLocalSportsTeams={fetchLocalSportsTeams}
          />
        </div>
      </>
    );
  }

  return (
    <div className={styles['categories-dropdown']}>
      {isMobile && (
        <CategoriesButtonList selected={type} onClick={onCategoryClick} />
      )}
      <div className={styles.categories}>{renderList()}</div>
    </div>
  );
}

CategoriesDropdown.propTypes = {
  type: PropTypes.string,
  onCategoryClick: PropTypes.func,
  onClick: PropTypes.func,
  currentMetro: PropTypes.object.isRequired,
  fetchLocalSportsTeams: PropTypes.func.isRequired,
};

export default CategoriesDropdown;
