/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './PriceBanner.module.scss';

const PriceBanner = ({ price, clickTracker }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.affirm) {
        window.affirm.ui.ready(() => {
          window.affirm.ui.refresh();
        });
      }
    }
  }, [price]);

  return (
    <div className={styles.container} onClick={clickTracker}>
      <div
        className="affirm-as-low-as"
        data-page-type="product"
        data-amount={`${price.toString()}00`}
        data-affirm-color="white"
        data-learnmore-show={false}
      />
    </div>
  );
};

PriceBanner.propTypes = {
  price: PropTypes.number.isRequired,
  clickTracker: PropTypes.func,
};

export default PriceBanner;
