import {
  FETCH_GLOBAL_HEADER_FAIL,
  FETCH_GLOBAL_HEADER_SUCCESS,
} from './actions';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GLOBAL_HEADER_SUCCESS:
      return {
        ...state,
        headerPromoCategories: action.result.fields.tabs,
      };
    case FETCH_GLOBAL_HEADER_FAIL:
    default:
      return state;
  }
}
