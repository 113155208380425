import {
  FETCH_STATIC_CONTENT,
  FETCH_STATIC_CONTENT_FAIL,
  FETCH_STATIC_CONTENT_SUCCESS,
} from './actions';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_STATIC_CONTENT:
      return {
        ...state,
      };
    case FETCH_STATIC_CONTENT_SUCCESS:
      return {
        ...state,
        [action.result.slug]: action.result.fields,
      };
    case FETCH_STATIC_CONTENT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
