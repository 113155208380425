import React from 'react';
import classNames from 'classnames';
import IconButton from 'ui/IconButton';

import {
  dealTypes,
  getUnlockedZoneDealInfo,
  saveUnlockZoneDealInfo,
} from 'components/Deals/helpers';
import Link from 'components/Link/Link';
import { ChevronIcon, CloseLineIcon } from 'icons';
import { Deal, Disclosure, Listing } from 'models';
import { DEAL } from 'pages/Event/Event.constants';
import { listingImagePropsSelector } from 'store/modules/resources/resource.selectors';
import colors from 'styles/colors.constants';
import { formatPriceWithComma } from 'utils/number';

import { useListingPrice, usePinDeal } from '../../../hooks';
import PinContainer from '../../../PinContainer';
import GalleryViewTooltip from '../../GalleryViewTooltip';
import IconSprite from '../../IconSprite';
import SeatMapPinHead from '../SeatMapPinHead';
import SeatMapPinLarge from '../SeatMapPinLarge';

import SeatMapPinExclusives from './SeatMapPinExclusives';

import pinStyles from '../../../PinContainer/PinContainer.module.scss';
import seatMapStyles from '../../SeatMap.module.scss';
import styles from './SeatMapPin.module.scss';

interface DealData {
  dealType: string | null;
  isLockedDeal: boolean;
  singleClickUnlock: boolean;
}

interface TrackingData {
  price: number;
  isAllInPriceActive: boolean;
  listingIndex: number;
  isListingOverlay: boolean;
  dealName: string;
  isListingDetails: boolean;
}

interface SeatMapPinProps {
  eventId: string;
  isMLBEvent: boolean;
  listing: Listing;
  listingIndex: number;
  onPinClick: (args: {
    listing: Listing;
    deal: DealData;
    tracking: TrackingData;
  }) => void;
  onPinHover: (args: { listing: Listing; tracking: TrackingData }) => void;
  isZoneDeal?: boolean;
  isFlashDeal?: boolean;
  isHighlighted: boolean;
  isListingDetails: boolean;
  mapImage: { width: number; height: number };
  venueName: string;
  allDeals: Record<string, Deal>;
  allDisclosures: Record<string, Disclosure>;
  isAllInPriceActive: boolean;
  priceWithPromoApplied: number;
  onListingClose: VoidFunction;
  isLockedZoneDeal: boolean;
  isPurchaseListing: boolean;
  initiateCheckoutFlow: VoidFunction;
  isListingFlow: boolean;
  observeMapHarmony: boolean;
  isCheckout: boolean;
  isHarmonyPlusOverlay: boolean;
  showGalleryViewTooltip: boolean;
  isExclusivesV1?: boolean;
}

const SeatMapPin = ({
  eventId,
  isMLBEvent,
  listing,
  listingIndex,
  onPinClick,
  onPinHover,
  isZoneDeal = false,
  isFlashDeal = false,
  isHighlighted,
  isListingDetails,
  mapImage,
  venueName,
  allDeals,
  allDisclosures,
  isAllInPriceActive,
  priceWithPromoApplied,
  onListingClose,
  isLockedZoneDeal,
  isPurchaseListing,
  initiateCheckoutFlow,
  isListingFlow,
  observeMapHarmony,
  isCheckout,
  isHarmonyPlusOverlay,
  showGalleryViewTooltip,
  isExclusivesV1,
}: SeatMapPinProps) => {
  const price = useListingPrice({
    listing,
    isAllInPriceActive,
    isListingDetails,
    priceWithPromoApplied,
    isPurchaseListing,
  });

  const deal = usePinDeal({
    listing,
    allDeals,
    isZoneDeal,
    isFlashDeal,
    isHighlighted,
  });

  const isFocusOnPurchaseListing = isListingDetails && isPurchaseListing;
  const isLargePin = isFocusOnPurchaseListing && !isHighlighted;
  const isListingOverlay = isHarmonyPlusOverlay && isListingFlow;
  const trackingData: TrackingData = {
    price,
    isAllInPriceActive,
    listingIndex,
    isListingOverlay,
    dealName: deal?.slug || '',
    isListingDetails,
  };

  const handleClick = () => {
    const dealData: DealData = {
      isLockedDeal: isLockedZoneDeal,
      dealType: null,
      singleClickUnlock: false,
    };

    if (isLockedZoneDeal) {
      const activeZoneDeal = getUnlockedZoneDealInfo(eventId);
      if (!activeZoneDeal) {
        saveUnlockZoneDealInfo(eventId);
        dealData.dealType = dealTypes.zoneDeal;
      }
      if (isListingDetails) {
        dealData.singleClickUnlock = true;
      }
    }

    onPinClick({ listing, deal: dealData, tracking: trackingData });
  };

  const isSponsoredDeal = isFlashDeal || isZoneDeal;
  const isFeaturedDeal = deal
    ? deal.slug === DEAL.RARE ||
      deal.slug === DEAL.BEST ||
      deal.slug === DEAL.CHEAPEST
    : false;

  const isExclusivesDeal = listing.isExclusivesDeal;

  const conditionalClassNames: Parameters<typeof classNames>[0] = {
    [styles.large]: isLargePin,
    [pinStyles['zindex-scale-01']]:
      !isFeaturedDeal && !isSponsoredDeal && !isHighlighted && !isLargePin,
    [pinStyles['zindex-scale-02']]: isFeaturedDeal,
    [pinStyles['zindex-scale-03']]: isSponsoredDeal || isExclusivesDeal,
    [pinStyles['zindex-scale-04']]:
      isHighlighted || listing.dealType === 'super',
    [pinStyles['zindex-scale-05']]: isLargePin,
  };

  const dealSlugStyles = {
    cheapest: styles.cheapest,
    best: styles.best,
    flash: styles.flash,
    zone: styles.zone,
    premium: styles.premium,
    rare: styles.rare,
  };

  function isDealSlug(slug?: string): slug is keyof typeof dealSlugStyles {
    return !!slug && slug in dealSlugStyles;
  }

  if (deal && isDealSlug(deal.slug)) {
    const className = dealSlugStyles[deal.slug];
    conditionalClassNames[className] = true;
  }

  function getBigPinButton() {
    if (isListingOverlay) {
      return (
        <IconButton
          onClick={initiateCheckoutFlow}
          icon={
            <ChevronIcon
              width="12"
              height="12"
              direction="right"
              color={colors.white}
            />
          }
        />
      );
    }

    if (isCheckout || (isListingFlow && !isHarmonyPlusOverlay)) {
      return (
        <IconButton
          onClick={onListingClose}
          icon={<CloseLineIcon width="16" height="16" fill={colors.white} />}
        />
      );
    }
  }

  return (
    <PinContainer
      listing={listing}
      mapImage={mapImage}
      isLargePin={isLargePin}
      pinContainerClassNames={classNames({
        ...conditionalClassNames,
        [styles['pin-stick']]: !isExclusivesV1,
      })}
      isExclusivesV1={isExclusivesV1}
    >
      <Link
        data-map-pin
        onClick={isCheckout || isPurchaseListing ? undefined : handleClick}
        rel="nofollow"
      >
        {isLargePin ? (
          <SeatMapPinHead
            listing={listing}
            observeMapHarmony={observeMapHarmony}
          >
            {isExclusivesV1 ? (
              <SeatMapPinExclusives
                isSelected={isHighlighted}
                variant={deal?.slug}
                text={
                  isListingFlow ? `$${formatPriceWithComma(price)}` : undefined
                }
                bigPinConfig={{
                  imageProps: listingImagePropsSelector(listing, venueName),
                  button: getBigPinButton(),
                }}
              />
            ) : (
              <SeatMapPinLarge
                price={price}
                deal={deal}
                onClose={
                  isCheckout || (isListingFlow && !isHarmonyPlusOverlay)
                    ? onListingClose
                    : undefined
                }
                isPriceHidden={!isListingFlow}
                imgProps={listingImagePropsSelector(listing, venueName)}
                onAdvance={isListingOverlay ? initiateCheckoutFlow : undefined}
                showSprite
              />
            )}
          </SeatMapPinHead>
        ) : (
          <SeatMapPinHead
            listing={listing}
            observeMapHarmony={observeMapHarmony}
          >
            <GalleryViewTooltip
              disableTooltip={!showGalleryViewTooltip || isLockedZoneDeal}
              listing={listing}
              isAllInPrice={isAllInPriceActive}
              allDisclosures={allDisclosures}
              isMLBEvent={isMLBEvent}
              onHover={() => onPinHover({ listing, tracking: trackingData })}
            >
              {isExclusivesV1 ? (
                <SeatMapPinExclusives
                  isSelected={isHighlighted}
                  variant={deal?.slug}
                  text={
                    (isLockedZoneDeal && deal?.title) ||
                    `$${formatPriceWithComma(price)}`
                  }
                />
              ) : (
                <div
                  style={deal?.styles}
                  className={classNames([
                    seatMapStyles.pinHead,
                    { [seatMapStyles.highlight]: isHighlighted },
                    { [seatMapStyles.deal]: !!deal },
                  ])}
                >
                  <div className={styles['small-pin']}>
                    {deal?.slug && (
                      <IconSprite
                        iconType={deal.slug}
                        variant={!isHighlighted ? 'color' : 'black'}
                      />
                    )}
                    {isLockedZoneDeal
                      ? deal?.title
                      : `$${formatPriceWithComma(price)}`}
                  </div>
                </div>
              )}
            </GalleryViewTooltip>
          </SeatMapPinHead>
        )}
      </Link>
    </PinContainer>
  );
};

export default React.memo(SeatMapPin);
