import { EVENT_TYPES } from '../event/constants';

export const LOCATION_TYPES = {
  CITY: 'City',
  PLACE: 'Place',
  MUSIC_VENUE: 'MusicVenue',
  PERFORMING_ARTS_THEATER: 'PerformingArtsTheater',
} as const;

export const ADDRESS_COUNTRIES = {
  US: 'US',
} as const;

export const ADDRESS_TYPES = {
  POSTAL_ADDRESS: 'PostalAddress',
  GEO_COORDINATES: 'GeoCoordinates',
  GEO_SHAPE: 'GeoShape',
} as const;

export const EVENT_TYPE_TO_LOCATION_TYPE_MAP = {
  [EVENT_TYPES.MUSIC]: LOCATION_TYPES.MUSIC_VENUE,
  [EVENT_TYPES.SPORT]: LOCATION_TYPES.CITY,
  [EVENT_TYPES.THEATER]: LOCATION_TYPES.PERFORMING_ARTS_THEATER,
  [EVENT_TYPES.EVENT]: LOCATION_TYPES.PLACE,
};
