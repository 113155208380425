import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  isListingFlashDeal,
  isListingZoneDeal,
} from 'components/Deals/helpers';
import { selectIsWebExclusivesV1Experiment } from 'experiments';
import { Deal, Disclosure } from 'models';
import { isZoneUnlockedSelector } from 'store/modules/data/Listings/selectors';
import { userPromosForListingPriceSelector } from 'store/modules/user/user.selectors';

import SeatMapPin from './SeatMapPin';

const SeatMapPins = ({
  listings,
  highlightedListingId = null,
  isListingDetailsPage = false,
  onPinClick,
  onPinHover,
  venueName,
  eventId,
  isMLBEvent,
  allDeals,
  allDisclosures,
  isAllInPriceActive,
  priceWithPromoApplied,
  onListingClose,
  zoneDealIsUnlocked,
  focusedPins = [],
  isHarmonyPlusOverlay,
  currentListingId,
  initiateCheckoutFlow,
  isListingFlow,
  observeMapHarmony = false,
  isCheckout,
  mapImage,
  isMapLoaded,
  onLastPinRender,
  showGalleryViewTooltip,
  isExclusivesV1,
}) => {
  const performanceLastPin = React.useRef(null);

  if (!isMapLoaded || (mapImage.height === -1 && mapImage.width === -1)) {
    return null;
  }

  const shouldShowHighlighted = (listing) => {
    if (isListingDetailsPage && isHarmonyPlusOverlay) return false;
    const showFocusedPins =
      !highlightedListingId && focusedPins.includes(listing.id);
    return listing.id === highlightedListingId || showFocusedPins;
  };

  return (
    <>
      {listings.map((listing, index) => {
        if (!listing) return null;

        const isZoneDeal = isListingZoneDeal(listing);
        const isFlashDeal = isListingFlashDeal(listing);
        const isHighlighted = shouldShowHighlighted(listing);

        if (index === listings.length - 1 && !performanceLastPin.current) {
          // Time Between LoadStartTime and the last pin
          performanceLastPin.current = Math.round(Date.now());
          onLastPinRender(performanceLastPin.current);
        }

        return (
          <SeatMapPin
            key={listing.id}
            eventId={eventId}
            listing={listing}
            isMLBEvent={isMLBEvent}
            listingIndex={index}
            onPinClick={onPinClick}
            onPinHover={onPinHover}
            isZoneDeal={isZoneDeal}
            isFlashDeal={isFlashDeal}
            isHighlighted={isHighlighted}
            isListingDetails={isListingDetailsPage}
            mapImage={mapImage}
            allDeals={allDeals}
            allDisclosures={allDisclosures}
            isAllInPriceActive={isAllInPriceActive}
            venueName={venueName}
            priceWithPromoApplied={priceWithPromoApplied}
            onListingClose={onListingClose}
            isLockedZoneDeal={isZoneDeal && !zoneDealIsUnlocked}
            isPurchaseListing={currentListingId === listing.id}
            initiateCheckoutFlow={initiateCheckoutFlow}
            isListingFlow={isListingFlow}
            isCheckout={isCheckout}
            observeMapHarmony={observeMapHarmony}
            isHarmonyPlusOverlay={isHarmonyPlusOverlay}
            showGalleryViewTooltip={showGalleryViewTooltip}
            isExclusivesV1={isExclusivesV1}
          />
        );
      })}
    </>
  );
};

function mapStateToProps(state, props) {
  const { eventId } = props;

  return {
    priceWithPromoApplied: userPromosForListingPriceSelector(state),
    zoneDealIsUnlocked: isZoneUnlockedSelector(state, eventId),
    isExclusivesV1: selectIsWebExclusivesV1Experiment(state),
  };
}

export default connect(mapStateToProps)(React.memo(SeatMapPins));

SeatMapPins.propTypes = {
  listings: PropTypes.array,
  isListingDetailsPage: PropTypes.bool,
  highlightedListingId: PropTypes.string,
  onPinClick: PropTypes.func.isRequired,
  onPinHover: PropTypes.func.isRequired,
  onListingClose: PropTypes.func,
  venueName: PropTypes.string,
  eventId: PropTypes.string.isRequired,
  isMLBEvent: PropTypes.bool.isRequired,
  allDeals: PropTypes.objectOf(PropTypes.instanceOf(Deal)),
  allDisclosures: PropTypes.objectOf(PropTypes.instanceOf(Disclosure)),
  isAllInPriceActive: PropTypes.bool,
  priceWithPromoApplied: PropTypes.number,
  isMapLoaded: PropTypes.bool,
  zoneDealIsUnlocked: PropTypes.bool,
  focusedPins: PropTypes.array,
  isHarmonyPlusOverlay: PropTypes.bool,
  currentListingId: PropTypes.string,
  initiateCheckoutFlow: PropTypes.func,
  isListingFlow: PropTypes.bool,
  observeMapHarmony: PropTypes.bool,
  isCheckout: PropTypes.bool,
  mapImage: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  onLastPinRender: PropTypes.func.isRequired,
  showGalleryViewTooltip: PropTypes.bool.isRequired,
  isExclusivesV1: PropTypes.bool,
};
