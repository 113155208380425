/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import SubmitButton from 'ui/SubmitButton/SubmitButton';
import * as Yup from 'yup';

import TextInput from 'components/Inputs/TextInput';
import { WarningCircleFillIcon } from 'icons';
import { updatePassword } from 'services/user/user.service';
import { setFormSubmitted, setFormSubmitting } from 'store/modules/app/app.ui';
import colors from 'styles/colors.constants';

import styles from './ResetPasswordForm.module.scss';

const ResetPasswordFormFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_+={}[\]|\\:;"<>,./?]).{8,}$/,
      {
        message: 'Please ensure password matches the requirements.',
      }
    )
    .required('Password is required.'),
  passwordConfirmed: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required.'),
});

const ResetPasswordForm = ({ userId, tempToken, dispatch }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitHandler = async (values, formActions) => {
    formActions.setSubmitting(true);
    setErrorMessage('');
    dispatch(setFormSubmitting(true));

    try {
      await updatePassword({
        userId: userId,
        token: tempToken,
        password: values.password,
      });
      formActions.setSubmitting(false);
      dispatch(setFormSubmitting(false));
      dispatch(setFormSubmitted(true));
      formActions.resetForm();
      setTimeout(() => {
        dispatch(setFormSubmitted(false));
      }, 1_000);
    } catch (err) {
      setErrorMessage('There was an error submitting the form');
      formActions.setSubmitting(false);
      dispatch(setFormSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmed: '',
        userId,
        tempToken,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={ResetPasswordFormFormValidationSchema}
      validateOnBlur
    >
      {({
        touched,
        errors,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
      }) => {
        return (
          <Form
            id="reset-password-form"
            onSubmit={handleSubmit}
            className={styles.form}
          >
            <TextInput
              id="forgot-password"
              name="password"
              label=""
              placeholder="New Password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.password && touched.password,
                message: errors.password,
              }}
              required
            />
            <TextInput
              id="forgot-password-confirm"
              name="passwordConfirmed"
              label=""
              placeholder="Confirm Password"
              type="password"
              value={values.passwordConfirmed}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.passwordConfirmed && touched.passwordConfirmed,
                message: errors.passwordConfirmed,
              }}
              required
            />
            <ul className={styles.requirements}>
              <li className={styles.requirement}>Minimum of 8 characters</li>
              <li className={styles.requirement}>
                At least 1 uppercase letter, 1 number, and 1 special character.
              </li>
            </ul>
            <SubmitButton
              text="Change Password"
              isLoading={isSubmitting}
              form="reset-password-form"
              isDisabled={
                isSubmitting || Object.keys(touched).length === 0 || !isValid
              }
            />
            {errorMessage !== '' && (
              <div className={styles['error-container']}>
                <WarningCircleFillIcon
                  fill={colors.softRed}
                  width="16"
                  height="16"
                />
                <span className={styles['error-message']}>{errorMessage}</span>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  userId: PropTypes.string,
  tempToken: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ResetPasswordForm);
