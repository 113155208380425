export const MLB_BALLPARK_PERFORMER_SLUGS = [
  'mlbsfg',
  'mlbchc',
  'mlbphi',
  'mlbstl',
  'mlbtex',
  'mlblaa',
  'mlbdet',
  'mlbkc',
  'mlbnym',
  'mlbmil',
  'mlbhou',
  'mlbmia',
  'mlbbos',
  'mlbpit',
  'mlbcle',
];
