import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import UrgencyMessage from 'components/UrgencyMessage/UrgencyMessage';

import styles from './IconCard.module.scss';

const IconCard = ({
  icon,
  title,
  headerStyling,
  onIconClick = () => {},
  subtitle,
  className,
  capitalize = true,
  disclosures,
  allDisclosures,
  children,
  uniformPadding,
  showUrgencyMessage,
  urgencyQuantity,
}) => {
  const titleStyleBase = 'icon-card-title';
  const titleStyle = capitalize
    ? classNames([titleStyleBase, 'capitalize'])
    : titleStyleBase;
  const containerStyle = className
    ? classNames(className, styles['icon-card-container'])
    : styles['icon-card-container'];

  const renderIconCardBody = () => {
    if (headerStyling) {
      return (
        <div className={styles['icon-card-header-title-row']}>
          <div className={styles[titleStyle]}>
            {title}
            {icon && (
              <button className={styles['title-icon']} onClick={onIconClick}>
                {icon}
              </button>
            )}
          </div>
          {subtitle && (
            <div className={styles['icon-card-subtitle']}>{subtitle}</div>
          )}
          <Disclosures
            allDisclosures={allDisclosures}
            disclosures={disclosures}
          />
        </div>
      );
    }

    return (
      <>
        <div className={styles['icon-container']}>
          <div>{icon}</div>
        </div>
        <div className={styles['icon-card-text']}>
          {title && <div className={styles[titleStyle]}>{title}</div>}
          {subtitle && (
            <div className={styles['icon-card-subtitle']}>{subtitle}</div>
          )}
          {showUrgencyMessage && (
            <UrgencyMessage type="checkout" quantity={urgencyQuantity} />
          )}
          <Disclosures
            allDisclosures={allDisclosures}
            disclosures={disclosures}
          />
        </div>
      </>
    );
  };

  return (
    <div className={containerStyle}>
      <div
        className={classNames(styles['icon-card-body'], {
          [styles['uniform-padding']]: uniformPadding,
        })}
      >
        {renderIconCardBody()}
      </div>
      {children}
    </div>
  );
};

IconCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  headerStyling: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  capitalize: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onIconClick: PropTypes.func,
  disclosures: PropTypes.array,
  allDisclosures: PropTypes.object,
  uniformPadding: PropTypes.bool,
  showUrgencyMessage: PropTypes.bool,
  urgencyQuantity: PropTypes.number,
};

export default IconCard;
