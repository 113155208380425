import React from 'react';
import PropTypes from 'prop-types';

import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import PerformerList from 'components/PerformerList/PerformerList';
import ScheduleList from 'pages/Event/components/OmnibarOptions/ScheduleList/ScheduleList';
import { getExpiredEventTitle } from 'pages/Event/helpers';

import styles from '../EventBar/EventBar.module.scss';

const ExpiredEvent = ({ schedule = [], relatedPerformers = [] }) => {
  const title = schedule.length ? 'Upcoming Events' : 'Similar Performers';
  const mobileTitle = getExpiredEventTitle(schedule.length);

  return (
    <div className={styles['omnibar-options-view']}>
      <div className={styles['invalid-event-mobile']}>
        <h1 className={styles.title}>{mobileTitle}</h1>
      </div>
      <div className={styles['invalid-event-desktop']}>
        <InfoHeader bold headerContent={title} isDarkTheme />
      </div>
      <div className={styles['omnibar-options-container']}>
        {schedule.length ? (
          <ScheduleList schedule={schedule} eventId={schedule[0].id} />
        ) : (
          <PerformerList performers={relatedPerformers} />
        )}
      </div>
    </div>
  );
};

ExpiredEvent.propTypes = {
  schedule: PropTypes.array,
  relatedPerformers: PropTypes.array,
};

export default ExpiredEvent;
