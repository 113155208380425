import { useEffect, useState } from 'react';

import {
  SEATMAP_HEIGHT_RATIO,
  SEATMAP_WIDTH_RATIO,
} from '../SeatMap.constants';

const useMapDimensions = (
  rectWidth: number,
  rectHeight: number,
  isMapLoaded: boolean
) => {
  const [mapImage, setMapImage] = useState<{ width: number; height: number }>({
    width: -1,
    height: -1,
  });

  useEffect(() => {
    if (!isMapLoaded || rectWidth < 1 || rectHeight < 1) {
      return;
    }

    let newMapWidth = rectWidth;
    let newMapHeight = newMapWidth * SEATMAP_HEIGHT_RATIO;

    if (newMapHeight > rectHeight) {
      newMapHeight = rectHeight;
      newMapWidth = newMapHeight * SEATMAP_WIDTH_RATIO;
    }

    setMapImage({
      width: Math.ceil(newMapWidth),
      height: Math.ceil(newMapHeight),
    });
  }, [rectHeight, rectWidth, isMapLoaded]);

  return mapImage;
};

export default useMapDimensions;
