import React from 'react';

export default (props) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47725 2 2 6.47726 2 12C2 17.5227 6.47725 22 12 22C17.5228 22 22 17.5227 22 12C22 6.47726 17.5228 2 12 2ZM15.1893 8.39646L10.25 13.3358L8.31061 11.3965C8.11535 11.2012 7.79877 11.2012 7.60351 11.3965L6.8964 12.1036C6.70114 12.2988 6.70114 12.6154 6.8964 12.8107L9.54285 15.4571C9.93337 15.8476 10.5665 15.8476 10.9571 15.4571L16.6035 9.81067C16.7988 9.61541 16.7988 9.29883 16.6035 9.10357L15.8964 8.39646C15.7011 8.2012 15.3846 8.2012 15.1893 8.39646Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
);
