import React, { useState } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Backdrop from 'ui/Backdrop/Backdrop';

import { isSpaceOrEnter } from 'utils/keystrokes';

import CategoriesDropdown from './components/CategoriesDropdown/CategoriesDropdown';
import MenuDropdown from './components/MenuDropdown/MenuDropdown';
import MobileDropdown from './components/MobileDropdown/MobileDropdown';

import styles from './HeaderDropdown.module.scss';

export const HEADER_DROPDOWNS = {
  SPORTS: 'sports',
  MUSIC: 'music',
  THEATER: 'theater',
  SEARCH: 'search',
  MOBILE: 'mobile',
  MENU: 'menu',
};

export const CATEGORIES_DROPDOWN = [
  HEADER_DROPDOWNS.SPORTS,
  HEADER_DROPDOWNS.MUSIC,
  HEADER_DROPDOWNS.THEATER,
];
function HeaderDropdown({
  currentMetro = {},
  fetchLocalSportsTeams,
  onCategoryClick,
  onClose = () => {},
  type,
}) {
  const [bottom, setBottom] = useState(0);
  const isCategoriesDropdown = CATEGORIES_DROPDOWN.includes(type);
  function handleOnKeyDown({ key }) {
    if (isSpaceOrEnter(key)) onClose();
  }
  function getDropdownContent() {
    if (type === HEADER_DROPDOWNS.MENU)
      return (
        <>
          <MenuDropdown onClose={onClose} />
          <div
            className={styles['backdrop--transparent']}
            onClick={onClose}
            onKeyDown={handleOnKeyDown}
          />
        </>
      );
    if (type === HEADER_DROPDOWNS.MOBILE)
      return (
        <div className={styles['content--mobile']}>
          <MobileDropdown
            onClick={onClose}
            currentMetro={currentMetro}
            fetchLocalSportsTeams={fetchLocalSportsTeams}
          />
        </div>
      );
  }
  return (
    <Measure
      bounds
      onResize={(contentRect) => setBottom(contentRect.bounds.bottom)}
    >
      {({ measureRef }) => (
        <div className={styles.dropdown} ref={measureRef}>
          {getDropdownContent()}
          <div
            className={classNames({
              [styles.hide]: !isCategoriesDropdown,
            })}
          >
            <div className={styles.content}>
              <CategoriesDropdown
                onCategoryClick={onCategoryClick}
                type={type}
                onClick={onClose}
                currentMetro={currentMetro}
                fetchLocalSportsTeams={fetchLocalSportsTeams}
              />
            </div>
            {isCategoriesDropdown && (
              <Backdrop
                onClick={onClose}
                variant="header"
                topPosition={bottom || 60}
              />
            )}
          </div>
        </div>
      )}
    </Measure>
  );
}

HeaderDropdown.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  onCategoryClick: PropTypes.func,
  currentMetro: PropTypes.object,
  fetchLocalSportsTeams: PropTypes.func.isRequired,
};

export default HeaderDropdown;
