import useZoomLevel from './useZoomLevel';

const useMapTracking = () => {
  const { getZoomLevel } = useZoomLevel();

  /**
    Tracking: getData
    {
      scale: zoom valule,
      translation: x and y img position,
      zoomType: ['zoomin', 'zoomout'],
      interaction: ['click', 'wheel', 'drag', 'touch', 'areaZoom'],
    }
   */
  const getData = (mapInteractionState) => {
    const {
      interaction: { zoomType, eventType },
      scale,
    } = mapInteractionState;

    const nextZoomLevel = getZoomLevel(scale);

    const baseTracking = {
      zoomType,
      zoomLevel: nextZoomLevel,
      interaction: eventType,
    };

    return {
      data: baseTracking,
    };
  };

  /*
    Track pageLoad time
 */
  const getLoadTime = (lastPinRenderMs, mapImageRenderMs) => {
    return {
      interaction: 'page_load',
      zoomType: null,
      lastPinRenderMs,
      mapImageRenderMs,
    };
  };

  return {
    getData,
    getLoadTime,
  };
};

export default useMapTracking;
