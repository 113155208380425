import React from 'react';
import PropTypes from 'prop-types';

import styles from './UserEmailRow.module.scss';

const UserEmailRow = ({ userEmail }) => {
  return (
    <div className={styles['user-email']}>
      <span className={styles.email}>{userEmail}</span>
    </div>
  );
};

UserEmailRow.propTypes = {
  userEmail: PropTypes.string.isRequired,
};

export default UserEmailRow;
