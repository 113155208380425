import {
  FETCH_BLOG_POST,
  FETCH_BLOG_POST_FAIL,
  FETCH_BLOG_POST_SUCCESS,
  FETCH_BLOG_POSTS,
  FETCH_BLOG_POSTS_FAIL,
  FETCH_BLOG_POSTS_SUCCESS,
} from './actions';

const initialState = {
  smallPosts: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_BLOG_POST:
      return {
        ...state,
      };
    case FETCH_BLOG_POST_SUCCESS:
      const { slug } = action.result;

      return {
        ...state,
        [slug]: action.result,
      };
    case FETCH_BLOG_POST_FAIL:
      return {
        ...state,
      };
    case FETCH_BLOG_POSTS:
      return {
        ...state,
      };
    case FETCH_BLOG_POSTS_FAIL:
      return {
        ...state,
      };
    case FETCH_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        smallPosts: action.result,
      };
    default:
      return state;
  }
}
