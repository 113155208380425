import { motion } from 'framer-motion';
import styled from 'styled-components';

import colors from 'styles/colors.constants';

export const Container = styled.div`
  width: 80%;
  height: 48px;
  max-width: 255px;
  border-radius: 25px;
  border: 1px solid ${({ color }) => color};
  background-color: ${colors.black};
  padding: 3px; // not-scale
  margin: 0;
  outline: none;
  position: relative;
  overflow: hidden;
`;

export const ButtonContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
`;

export const SwitchKnot = styled(motion.div)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
`;
