import React from 'react';
import colors from 'styles/colors.constants';

export default ({ fill = colors.gray800, width = '8', height = '8' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.55719 0.51753C3.71033 0.107985 4.28962 0.107984 4.44276 0.517529L5.04246 2.12132C5.1089 2.29899 5.2751 2.41974 5.46461 2.42803L7.17521 2.50277C7.61204 2.52186 7.79105 3.0728 7.44887 3.345L6.1089 4.41094C5.96045 4.52903 5.89696 4.72441 5.94765 4.90721L6.40517 6.55719C6.522 6.97853 6.05334 7.31903 5.68873 7.07771L4.26088 6.13271C4.1027 6.02802 3.89726 6.02802 3.73908 6.13271L2.31123 7.07771C1.94661 7.31903 1.47796 6.97853 1.59479 6.55719L2.05231 4.90721C2.10299 4.72441 2.03951 4.52903 1.89106 4.41094L0.551087 3.345C0.208909 3.0728 0.387919 2.52186 0.824742 2.50277L2.53535 2.42803C2.72486 2.41974 2.89106 2.29899 2.9575 2.12132L3.55719 0.51753Z"
      fill={fill}
    />
  </svg>
);
