import React from 'react';

const GreatViewIcon = ({width = '16', height = '16', viewBox = "0 0 16 16", fill = '#505CD4'}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns="http://www.w3.org/2000/svg">
      <path d="M8.45558 1.64009C8.21868 1.40319 8.14579 1.00228 7.79955 0C7.4533 1.00228 7.38041 1.40319 7.14351 1.64009C6.90661 1.87699 6.5057 1.94989 5.50342 2.29613C6.5057 2.64237 6.90661 2.71526 7.14351 2.95216C7.38041 3.18907 7.4533 3.58998 7.79955 4.59225C8.14579 3.58998 8.21868 3.18907 8.45558 2.95216C8.69248 2.71526 9.0934 2.64237 10.0957 2.29613C9.0934 1.94989 8.69248 1.87699 8.45558 1.64009Z" fill={fill}/>
      <path d="M12.7222 3.69853C12.9956 4.54505 13.1323 4.96831 13.4123 5.24827C13.6922 5.52824 14.1155 5.66492 14.962 5.93828C15.245 6.02968 15.5754 6.13635 15.9635 6.26877C15.5431 6.41221 15.1905 6.52477 14.8924 6.61993C14.0887 6.87647 13.681 7.00661 13.4088 7.27443C13.1286 7.55013 12.992 7.97176 12.7147 8.82741C12.625 9.10427 12.5205 9.42658 12.3918 9.80408C12.2518 9.39394 12.1413 9.04895 12.0476 8.75661C11.7875 7.94488 11.6574 7.53901 11.3895 7.27105C11.1215 7.00309 10.7156 6.87304 9.90392 6.61293C9.61158 6.51925 9.26659 6.40871 8.85645 6.26877C9.23395 6.13998 9.55626 6.03554 9.83312 5.94582C10.6888 5.66855 11.1104 5.53192 11.3861 5.25172C11.6539 4.97953 11.7841 4.57186 12.0406 3.76818C12.1358 3.47005 12.2483 3.11743 12.3918 2.69702C12.5242 3.08515 12.6308 3.4155 12.7222 3.69853Z" fill={fill}/>
      <path d="M5.89814 6.77723C6.30708 8.04548 6.5096 8.67355 6.92596 9.08547C7.33444 9.48959 7.94874 9.68567 9.16572 10.0741C9.61694 10.2181 10.151 10.3886 10.7881 10.6059C10.1832 10.8123 9.67123 10.9769 9.23483 11.1173C7.97531 11.5225 7.34555 11.7251 6.92938 12.1412C6.51321 12.5574 6.31063 13.1872 5.90547 14.4467C5.76509 14.8831 5.6004 15.3951 5.39407 16C5.18613 15.3904 5.02047 14.8751 4.87946 14.4365C4.47536 13.1795 4.27363 12.5521 3.85915 12.137C3.44598 11.7233 2.82138 11.5208 1.57414 11.1163C1.14202 10.9761 0.635149 10.8117 0.036438 10.6059C0.667191 10.3891 1.19601 10.2189 1.64288 10.0751C2.84785 9.68732 3.45706 9.49127 3.86257 9.08958C4.27724 8.67882 4.47891 8.05303 4.88681 6.78734C5.02622 6.35473 5.18973 5.84737 5.39407 5.24829C5.59682 5.84271 5.75937 6.34683 5.89814 6.77723Z" fill={fill}/>
    </svg>
  )
}

export default GreatViewIcon;
