import FullEvent from 'models/FullEvent';
import { DATE_FORMAT_EEEE_MMMM_D_YYYY, formatDate } from 'utils/datetime';

export const getEventPageDescription = (fullEvent: FullEvent) => {
  const {
    venue: { name: venueName, city },
    event: { datetimeLocal, tbd },
  } = fullEvent;

  const eventName = fullEvent.getName();
  const eventInfo = tbd
    ? `in ${city}`
    : `on ${formatDate(datetimeLocal, DATE_FORMAT_EEEE_MMMM_D_YYYY)}`;

  if (tbd) {
    return `Get cheap upcoming ${eventName} tickets at ${venueName} ${eventInfo}. Lowest Price Guaranteed!`;
  }

  return `Get cheap ${eventName} tickets at ${venueName} ${eventInfo}. Lowest Price Guaranteed!`;
};
