import React from 'react';

import CreditCardGooglePayIconImage from 'assets/GooglePay-logomark.svg';
import CreditCardAmexIconImage from 'assets/ic_24_credit_card_amex_SVG.svg';
import CreditCardAplpIconImage from 'assets/ic_24_credit_card_applepay_SVG.svg';
import CreditCardDiscoverIconImage from 'assets/ic_24_credit_card_discover_SVG.svg';
import CreditCardMasterCardIconImage from 'assets/ic_24_credit_card_mastercard_SVG.svg';
import CreditCardVisaIconImage from 'assets/ic_24_credit_card_visa_SVG.svg';
import { isObjectEmpty } from 'utils/objects';

export const CARD_TYPES = {
  VISA: 'Visa',
  DISCOVER: 'Discover',
  MASTERCARD: 'MasterCard',
  AMEX: 'American Express',
  APPLEPAY: 'APLP',
  PAYPAL: 'PYPL',
  VENMO: 'VNMO',
  GOOGLEPAY: 'ANDP',
};

export const NON_TOKEN_PAYMENT_TYPES = [
  CARD_TYPES.APPLEPAY,
  CARD_TYPES.GOOGLEPAY,
  CARD_TYPES.PAYPAL,
  CARD_TYPES.VENMO,
];

export const PAYMENT_TYPES_CONFIG = {
  [CARD_TYPES.VISA]: {
    displayName: 'Visa',
    supported: true,
    icon: <img alt="Visa" src={CreditCardVisaIconImage} />,
  },
  [CARD_TYPES.DISCOVER]: {
    displayName: 'Discover',
    supported: true,
    icon: <img alt="Discover" src={CreditCardDiscoverIconImage} />,
  },
  [CARD_TYPES.MASTERCARD]: {
    displayName: 'Mastercard',
    supported: true,
    icon: <img alt="Mastercard" src={CreditCardMasterCardIconImage} />,
  },
  [CARD_TYPES.AMEX]: {
    displayName: 'AMEX',
    supported: true,
    icon: <img alt="American Express" src={CreditCardAmexIconImage} />,
  },
  [CARD_TYPES.APPLEPAY]: {
    displayName: 'Apple Pay',
    supported: true,
    icon: <img alt="Apple Pay" src={CreditCardAplpIconImage} />,
  },
  [CARD_TYPES.PAYPAL]: {
    displayName: 'Paypal',
    supported: false,
  },
  [CARD_TYPES.VENMO]: {
    displayName: 'Venmo',
    supported: false,
  },
  [CARD_TYPES.GOOGLEPAY]: {
    displayName: 'Google Pay',
    supported: true,
    icon: (
      <img
        height="18px"
        style={{ borderRadius: 9 }}
        alt="Google Pay"
        src={CreditCardGooglePayIconImage}
      />
    ),
  },
};

export const isNonTokenBasedPaymentType = (defaultCardToken) =>
  NON_TOKEN_PAYMENT_TYPES.includes(defaultCardToken);

export const isSupportedPaymentType = (defaultCardToken) => {
  const config = PAYMENT_TYPES_CONFIG[defaultCardToken];

  if (isObjectEmpty(config)) {
    return false;
  }

  return config.supported;
};
