import React from 'react';

import { CircleInfoIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './Disclosure.module.scss';

type Props = {
  title: string;
  onClick?: () => void;
};

export default function Disclosure({ title, onClick }: Props) {
  if (onClick) {
    return (
      <button className={styles['disclosure-button']} onClick={onClick}>
        {title}
        <span className={styles.icon} data-testid="disclosure-circle-info-icon">
          <CircleInfoIcon fill={colors.softBlue} height="12" width="12" />
        </span>
      </button>
    );
  }

  return <span className={styles.disclosure}>{title}</span>;
}
