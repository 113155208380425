import { formatUrl } from 'utils/format';
import { secureUrl } from 'utils/url';

import Location from './Location';

export default class Venue {
  constructor(venue = {}, searchSessionId) {
    this.city = venue.city;
    this.id = venue.id;
    this.location = new Location(venue.location);
    this.name = venue.name;
    this.slug = venue.slug;
    this.state = venue.state;
    this.timezone = venue.timezone;
    this.metro = venue.metro;
    this.imageUrl = venue.image_url;
    this.showCurrency = venue.show_currency;
    this.algoliaFields = {
      queryId: venue.meta?.query_id,
      resultPosition: venue.meta?.result_position,
      searchIndex: venue.meta?.search_index,
      searchSessionId: searchSessionId,
    };
  }

  getTitle() {
    const { name, city, state } = this;
    return `${name} - ${city}, ${state}`;
  }

  getLocation() {
    const { city, state } = this;
    return `${city}, ${state}`;
  }

  getPath() {
    return `/${formatUrl(`${this.name} tickets`)}/venues/${this.slug}`;
  }

  getBreadcrumbConfig() {
    return {
      url: this.getPath(),
      name: this.name,
    };
  }

  getImageOptions() {
    const alt = `${this.name} Tickets`;
    return {
      alt,
      src: this.image,
    };
  }

  get image() {
    return secureUrl(this.imageUrl);
  }
}
