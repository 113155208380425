import { TrackEvent } from './TrackEvent';

const PAGE_TYPE_NAMES = {
  SMS_VERIFICATION: 'sms_verification',
};

export class SMSAutoFilledEvent extends TrackEvent {
  _eventName = 'sms_autofilled';

  constructor() {
    super({ pageType: PAGE_TYPE_NAMES.SMS_VERIFICATION });
  }
}
