export const featureFlags = {
  SHOW_TICKET_SOURCE: {
    name: 'SHOW_TICKET_SOURCE',
  },
  BRAZE_INTEGRATION: {
    name: 'BRAZE_INTEGRATION',
  },
  HOW_DID_YOU_HEAR_ABOUT_US_SURVEY: {
    name: 'HOW_DID_YOU_HEAR_ABOUT_US_SURVEY',
  },
  SMS_LOGIN_OPTION: {
    name: 'SMS_LOGIN_OPTION',
  },
  MLB_INTEGRATION: {
    name: 'MLB_INTEGRATION',
  },
  CALIFORNIA_VENUE_ALL_IN_PRICE: {
    name: 'CALIFORNIA_VENUE_ALL_IN_PRICE',
  },
  MARYLAND_VENUE_ALL_IN_PRICE: {
    name: 'MARYLAND_VENUE_ALL_IN_PRICE',
  },
  COLORADO_VENUE_ALL_IN_PRICE: {
    name: 'COLORADO_VENUE_ALL_IN_PRICE',
  },
  INSURANCE_PAYMENT_METHOD_APPLEPAY: {
    name: 'INSURANCE_PAYMENT_METHOD_APPLEPAY',
  },
  INSURANCE_PAYMENT_METHOD_GOOGLEPAY: {
    name: 'INSURANCE_PAYMENT_METHOD_GOOGLEPAY',
  },
  INSURANCE_PAYMENT_METHOD_AFFIRM: {
    name: 'INSURANCE_PAYMENT_METHOD_AFFIRM',
  },
  INSURANCE_PAYMENT_METHOD_PAYPAL: {
    name: 'INSURANCE_PAYMENT_METHOD_PAYPAL',
  },
  INSURANCE_PAYMENT_METHOD_CREDITCARD: {
    name: 'INSURANCE_PAYMENT_METHOD_CREDITCARD',
  },
} as const;
