import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  cleanupGooglePayScript,
  injectGooglePayScript,
} from 'helpers/Google/GooglePay';

const GooglePayContext = createContext();

export function GooglePayProvider({ children }) {
  const [googlePay, setGooglePay] = useState(null);

  useEffect(() => {
    injectGooglePayScript()
      .then((googlePay) => {
        setGooglePay(googlePay);
      })
      .catch(console.error);

    return () => {
      cleanupGooglePayScript();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      googlePay,
    }),
    [googlePay]
  );

  return (
    <GooglePayContext.Provider value={contextValue}>
      {children}
    </GooglePayContext.Provider>
  );
}

GooglePayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useGooglePay() {
  const context = useContext(GooglePayContext);
  if (!context) {
    throw new Error('useGooglePay may only be used within GooglePayProvider');
  }
  return context;
}

export function withGooglePay(Component) {
  const WithGooglePayComponent = (props) => (
    <GooglePayContext.Consumer>
      {(contextValue) => <Component {...props} {...contextValue} />}
    </GooglePayContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithGooglePayComponent.displayName = `withGooglePay(${displayName})`;

  return WithGooglePayComponent;
}
