import React from 'react';
import classNames from 'classnames';

import styles from './TabBar.module.scss';

type Props<T extends string> = {
  activeTab: T;
  onChange: (key: T) => void;
  tabs: { key: T; title: string }[];
};

function TabBar<T extends string>({ activeTab, onChange, tabs }: Props<T>) {
  return (
    <div className={styles['tab-bar']}>
      {tabs.map((tab) => (
        <button
          key={tab.key}
          type="button"
          className={classNames(styles['tab-item'], {
            [styles.active]: activeTab === tab.key,
          })}
          onClick={() => onChange(tab.key)}
        >
          <span className={styles['tab-item-content']}>{tab.title}</span>
        </button>
      ))}
    </div>
  );
}

export default TabBar;
