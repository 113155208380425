import React from 'react';
import PropTypes from 'prop-types';

import BulletList from 'components/BulletList/BulletList';
import IconCard from 'components/IconCard/IconCard';

import styles from './DetailEventCard.module.scss';

const DetailEventCard = ({
  fullEvent,
  viewUrl,
  uniformPadding,
  showUrgencyMessage,
  urgencyQuantity,
}) => {
  const imageStyle = {
    backgroundImage: `url('${viewUrl}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  return (
    <IconCard
      icon={<div className={styles['event-image-icon']} style={imageStyle} />}
      title={fullEvent.getName('@')}
      subtitle={<BulletList listItems={fullEvent.getDateTime()} />}
      uniformPadding={uniformPadding}
      showUrgencyMessage={showUrgencyMessage}
      urgencyQuantity={urgencyQuantity}
    />
  );
};

DetailEventCard.propTypes = {
  fullEvent: PropTypes.object.isRequired,
  viewUrl: PropTypes.string.isRequired,
  uniformPadding: PropTypes.bool,
  showUrgencyMessage: PropTypes.bool,
  urgencyQuantity: PropTypes.number,
};

export default DetailEventCard;
