import React from 'react';

export default ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <g filter="url(#filter0_i_1041_22011)">
      <path
        d="M9.21721 27.4088C8.04916 28.3893 8.00049 29.4923 8.00049 30.4728L8.12215 66.5053H72.0001V30.5954C72.0001 29.3208 71.4323 28.5936 71.1484 28.3893L43.4938 6.65052C41.5067 5.08852 38.7212 5.05822 36.704 6.58113C26.2602 14.4658 10.2625 26.5314 9.21721 27.4088Z"
        fill="#505CD4"
      />
    </g>
    <g filter="url(#filter1_d_1041_22011)">
      <path d="M11.1997 21.277H68.7991V60.8156H11.1997V21.277Z" fill="white" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2085 30.1279C37.2502 30.1279 34.852 32.5262 34.852 35.4844C34.852 38.4427 37.2502 40.8409 40.2085 40.8409C43.1667 40.8409 45.5649 38.4427 45.5649 35.4844C45.5649 32.5262 43.1667 30.1279 40.2085 30.1279ZM40.2085 28.6671C36.4434 28.6671 33.3911 31.7194 33.3911 35.4844C33.3911 39.2495 36.4434 42.3018 40.2085 42.3018C43.9735 42.3018 47.0258 39.2495 47.0258 35.4844C47.0258 31.7194 43.9735 28.6671 40.2085 28.6671ZM39.2347 36.0549L42.5843 32.7053C42.7744 32.5152 43.0828 32.5152 43.2729 32.7053L43.9616 33.394C44.1518 33.5842 44.1518 33.8925 43.9616 34.0827L39.9233 38.1209C39.543 38.5012 38.9264 38.5012 38.546 38.1209L36.4556 36.0305C36.2654 35.8403 36.2654 35.532 36.4556 35.3418L37.1442 34.6532C37.3344 34.463 37.6427 34.463 37.8329 34.6532L39.2347 36.0549Z"
      fill="#505CD4"
    />
    <g filter="url(#filter2_di_1041_22011)">
      <path
        d="M40.3481 53.3097L8.00049 30.4863V74.2495L40.3481 53.3097Z"
        fill="url(#paint0_linear_1041_22011)"
      />
    </g>
    <g filter="url(#filter3_di_1041_22011)">
      <path
        d="M40.3475 53.3097L71.9995 30.4863V74.2495L40.3475 53.3097Z"
        fill="url(#paint1_linear_1041_22011)"
      />
    </g>
    <g filter="url(#filter4_i_1041_22011)">
      <path
        d="M71.969 73.9429L43.3259 52.2701C41.3475 50.7732 38.6171 50.7666 36.6314 52.254L8.00049 73.7009C8.00049 75.7338 8.97339 76 9.9463 76H70.2664C71.9203 76 72.0906 74.6286 71.969 73.9429Z"
        fill="url(#paint2_linear_1041_22011)"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1041_22011"
        x="8.00049"
        y="5.45868"
        width="63.9995"
        height="63.8292"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.78259" />
        <feGaussianBlur stdDeviation="2.78259" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1041_22011"
        />
      </filter>
      <filter
        id="filter1_d_1041_22011"
        x="7.30408"
        y="17.3813"
        width="65.3909"
        height="47.3298"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.94781" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1041_22011"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1041_22011"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_di_1041_22011"
        x="2.91545"
        y="25.4012"
        width="42.5177"
        height="53.9333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.54252" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1041_22011"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1041_22011"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.78259" />
        <feGaussianBlur stdDeviation="2.78259" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1041_22011"
        />
      </filter>
      <filter
        id="filter3_di_1041_22011"
        x="35.2626"
        y="25.4012"
        width="41.8219"
        height="53.9333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.54252" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1041_22011"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1041_22011"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.78259" />
        <feGaussianBlur stdDeviation="2.78259" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1041_22011"
        />
      </filter>
      <filter
        id="filter4_i_1041_22011"
        x="8.00049"
        y="51.1429"
        width="63.9995"
        height="27.6397"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.78259" />
        <feGaussianBlur stdDeviation="4.17389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1041_22011"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1041_22011"
        x1="24.1743"
        y1="30.4863"
        x2="24.1743"
        y2="74.2495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A67CFF" />
        <stop offset="1" stopColor="#505CD4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1041_22011"
        x1="56.1735"
        y1="30.4863"
        x2="56.1735"
        y2="74.2495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A67CFF" />
        <stop offset="1" stopColor="#505CD4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1041_22011"
        x1="40.0003"
        y1="49.7421"
        x2="40.0003"
        y2="76"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A67CFF" />
        <stop offset="1" stopColor="#505CD4" />
      </linearGradient>
    </defs>
  </svg>
);
