export const OG_TYPES = {
  WEBSITE: 'website',
  ARTICLE: 'article',
  BOOK: 'book',
  PROFILE: 'profile',
  MUSIC_SONG: 'music.song',
  MUSIC_ALBUM: 'music.album',
  MUSIC_PLAYLIST: 'music.playlist',
  MUSIC_RADIO_STATION: 'music.radio_station',
  VIDEO_MOVIE: 'video.movie',
  VIDEO_EPISODE: 'video.episode',
  VIDEO_TV_SHOW: 'video.tv_show',
};
