import {
  API_SPORTS_SUB_CATEGORIES,
  MAX_PERFORMERS_DESKTOP,
} from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/constants';
import { MAJOR_LEAGUE_SPORTS_PERFORMERS } from 'store/modules/categories/category.helpers';

import { getLocalPerformers } from './selectors';

export const ACTIONS = {
  GET_PERFORMERS_REQUEST: 'GET_PERFORMERS_REQUEST',
  GET_PERFORMERS_REQUEST_SUCCESS: 'GET_PERFORMERS_REQUEST_SUCCESS',
  GET_PERFORMERS_REQUEST_FAILURE: 'GET_PERFORMERS_REQUEST_FAILURE',
  GET_TRENDING_PERFORMERS_REQUEST_SUCCESS:
    'GET_TRENDING_PERFORMERS_REQUEST_SUCCESS',
  SET_PERFORMERS_CATEGORY: 'SET_PERFORMERS_CATEGORY',
  ALL_PERFORMERS_FETCHED: 'ALL_PERFORMERS_FETCHED',
  GET_LOCAL_PERFORMERS_REQUEST: 'GET_LOCAL_PERFORMERS_REQUEST',
  GET_LOCAL_PERFORMERS_REQUEST_SUCCESS: 'GET_LOCAL_PERFORMERS_REQUEST_SUCCESS',
  GET_LOCAL_PERFORMERS_REQUEST_FAILURE: 'GET_LOCAL_PERFORMERS_REQUEST_FAILURE',
  CALCULATE_TOP_PICK_PERFORMERS: 'CALCULATE_TOP_PICK_PERFORMERS',
};

export const fetchPerformersByIds = (ids) => (dispatch) => {
  return dispatch({
    types: [
      ACTIONS.GET_PERFORMERS_REQUEST,
      ACTIONS.GET_PERFORMERS_REQUEST_SUCCESS,
      ACTIONS.GET_PERFORMERS_REQUEST_FAILURE,
    ],
    params: { id: ids },
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/performers',
        searchParams: { id: ids, page: 1, per_page: 20 },
      }),
  });
};

/*
 * fetch performers
 * @params: {Object} params
 * @params: {String} params.id - list of comma separated performer id(s) ex: '1ac3c,2bd5db,3dce8f'
 * @params: {String} params.slug - slug of performer to associate fetched performer(s)
 * @params: {Boolean} params.isRelatedPerformers - flag to determine if we are fetching related performers or not
 * @params: {Boolean} fetchTrending - flag to determine if we are fetching trending performers or not
 * In the future fetchPerformers will need to accomodate a list of ids that are not for related performers
 */
export const fetchPerformers = (params, metro, fetchTrending) => (dispatch) => {
  let trendingParams = {};
  if (fetchTrending) {
    trendingParams = {
      limit: MAX_PERFORMERS_DESKTOP,
      metro: metro && metro.id,
    };
  }

  const newParams = {
    page: 1,
    per_page: 225,
    ...params,
    ...trendingParams,
  };

  return dispatch({
    types: [
      ACTIONS.GET_PERFORMERS_REQUEST,
      ACTIONS.GET_PERFORMERS_REQUEST_SUCCESS,
      ACTIONS.GET_PERFORMERS_REQUEST_FAILURE,
    ],
    params: newParams,
    promise: (httpClient) =>
      httpClient.request({
        path: fetchTrending ? '/v1/performers/trending' : '/v1/performers',
        searchParams: newParams,
      }),
  });
};

export const fetchPerformerBySlug = (slug, metro) =>
  fetchPerformers({ slug }, metro);

export const fetchPerformersByCategory = (
  category,
  metro,
  fetchTrending = false,
  isSports = false
) => fetchPerformers({ category, isSports }, metro, fetchTrending);

export const fetchAllPerformers = (metro) => async (dispatch) => {
  await dispatch(
    fetchPerformersByCategory(
      API_SPORTS_SUB_CATEGORIES.join(','),
      metro,
      false,
      true
    )
  );

  const TRENDING_PERFORMERS = ['music', 'theater', 'comedy'];
  const trendingPromises = TRENDING_PERFORMERS.map((c) =>
    dispatch(fetchPerformersByCategory(c, metro, true, false))
  );

  await Promise.all(trendingPromises);

  return dispatch({ type: ACTIONS.ALL_PERFORMERS_FETCHED });
};

export const setPerformersCategory = (category) => {
  return {
    type: ACTIONS.SET_PERFORMERS_CATEGORY,
    category,
  };
};

export const fetchTrendingPerformersByCategoryGroup =
  (metroId, category_group = 'sport') =>
  (dispatch) => {
    const trendingParams = {
      limit: MAX_PERFORMERS_DESKTOP,
      metro: metroId,
      category_group,
    };

    const newParams = { page: 1, per_page: 225, ...trendingParams };

    return dispatch({
      types: [
        ACTIONS.GET_PERFORMERS_REQUEST,
        ACTIONS.GET_TRENDING_PERFORMERS_REQUEST_SUCCESS,
        ACTIONS.GET_PERFORMERS_REQUEST_FAILURE,
      ],
      params: newParams,
      promise: (httpClient) =>
        httpClient.request({
          path: '/v1/performers/trending',
          searchParams: newParams,
        }),
    });
  };

export const fetchLocalSportsTeams =
  (metro, category) => (dispatch, getState) => {
    const local = getLocalPerformers(getState(), { metro, category });

    if (local || !MAJOR_LEAGUE_SPORTS_PERFORMERS.includes(category)) {
      return Promise.resolve();
    }

    const trendingParams = {
      metro,
      category,
    };

    const newParams = { page: 1, per_page: 10, ...trendingParams };

    return dispatch({
      types: [
        ACTIONS.GET_LOCAL_PERFORMERS_REQUEST,
        ACTIONS.GET_LOCAL_PERFORMERS_REQUEST_SUCCESS,
        ACTIONS.GET_LOCAL_PERFORMERS_REQUEST_FAILURE,
      ],
      params: newParams,
      promise: (httpClient) =>
        httpClient.request({
          path: '/v1/performers/trending',
          searchParams: newParams,
        }),
    });
  };

export const calculateTopPickPerformers = (performersInContext, performer) => ({
  type: ACTIONS.CALCULATE_TOP_PICK_PERFORMERS,
  performersInContext,
  performer,
});
