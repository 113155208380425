import React, { ComponentType, createContext, useContext } from 'react';
import _merge from 'lodash/merge';

import {
  ClickContextProviderProps,
  ClickContextType,
  GetClickContext,
  WithClickContextProps,
} from './ClickContext.types';

const ClickContext = createContext<ClickContextType>({});

function useClickContext(): ClickContextType {
  return useContext(ClickContext);
}

function ClickContextProvider({
  getClickContext,
  children,
}: ClickContextProviderProps) {
  const parentContext = useClickContext(); // Get the parent context
  let clickContext = {};

  if (getClickContext) {
    const newClickContext = getClickContext({});
    if (newClickContext) {
      clickContext = _merge({}, parentContext, newClickContext);
    }
  } else {
    clickContext = parentContext;
  }

  return (
    <ClickContext.Provider value={clickContext}>
      {children}
    </ClickContext.Provider>
  );
}

function withClickContext(getClickContext?: GetClickContext) {
  return function <P extends WithClickContextProps>(
    WrappedComponent: ComponentType<P>
  ) {
    const withClickContextComponent = (
      props: Omit<P, keyof WithClickContextProps>
    ) => {
      return (
        <ClickContextProvider
          getClickContext={
            getClickContext ? () => getClickContext(props) : undefined
          }
        >
          <ClickContext.Consumer>
            {(clickContext) => (
              <WrappedComponent {...(props as P)} clickContext={clickContext} />
            )}
          </ClickContext.Consumer>
        </ClickContextProvider>
      );
    };
    return withClickContextComponent;
  };
}

export { ClickContext, useClickContext, withClickContext };
