import React from 'react';

import styles from './Posting.module.scss';

interface PostingProps {
  url: string;
  location: string;
  title: string;
}

function Posting({ url, location, title }: PostingProps) {
  return (
    <div className={styles.posting}>
      <a
        className={styles.link}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {title}
      </a>
      <span className={styles.location}>{location}</span>
    </div>
  );
}

export default Posting;
