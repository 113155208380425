import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { TransformContext } from './TransformProvider';

import style from './SeatMapInteraction.module.scss';

export const SeatMapInteractionMain = ({ children, isLoaded = false }) => {
  const { setComponents } = useContext(TransformContext);

  const wrapperRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const content = contentRef.current;
    if (wrapper !== null && content !== null && setComponents) {
      setComponents(wrapper, content);
    }
  }, [setComponents]);
  return (
    <div ref={wrapperRef} className={style.container}>
      <div
        ref={contentRef}
        className={style.content}
        style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
      >
        {children}
      </div>
    </div>
  );
};

SeatMapInteractionMain.propTypes = {
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
};

export default SeatMapInteractionMain;
