import React from 'react';

import colors from 'styles/colors.constants';

interface Props {
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
}

export default function ShieldIcon({
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  fill = colors.gray200,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      data-testid="shield-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9209 22.0674C12.3792 22.4887 11.6208 22.4887 11.0791 22.0674L5.31636 17.5852C3.85484 16.4485 3 14.7007 3 12.8491V5.22515C3 4.36429 3.55086 3.60001 4.36754 3.32778L10.7351 1.20527C11.5562 0.931578 12.4438 0.931577 13.2649 1.20527L19.6325 3.32778C20.4491 3.60001 21 4.36429 21 5.22515V12.8491C21 14.7007 20.1452 16.4485 18.6836 17.5852L12.9209 22.0674ZM12.1352 11.657V10.5356C12.1352 10.3788 12.2447 10.2533 12.3816 10.2533H14.7536C14.8905 10.2533 15 10.3788 15 10.5356V13.3548C15 15.0839 13.9218 16.4836 12.142 16.4836H12.0051C10.2253 16.4836 9 15.0721 9 13.3273V7.86156C9 6.15988 10.2253 4.78364 12.0051 4.78364H12.142C13.9218 4.78364 15.0034 6.09323 15 7.70472V7.90077C15 8.0576 14.8905 8.18307 14.7536 8.18307H13.4358C13.2989 8.18307 13.1894 8.0576 13.1894 7.90077V7.7949C13.1894 7.16364 12.7924 6.65 12.142 6.65H12.0051C11.4062 6.65 10.8756 7.21069 10.8756 7.89685V11.9511V12.449V13.2999C10.8756 13.986 11.4062 14.5663 12.0051 14.5663H12.142C12.741 14.5663 13.2544 13.986 13.2544 13.2999V12.1001C13.2544 12.0138 13.1928 11.9393 13.1141 11.9393H12.3816C12.2447 11.9393 12.1352 11.8138 12.1352 11.657Z"
        fill={fill}
      />
    </svg>
  );
}
