import React from 'react';
import PropTypes from 'prop-types';

import PastEventImage from 'assets/PastEvent.png';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import { getExpiredEventTitle } from 'pages/Event/helpers';
import colors from 'styles/colors.constants';

import styles from './InvalidEvent.module.scss';

const InvalidEvent = ({
  schedule = [],
  performersByCategory = [],
  eventId,
}) => {
  const getNextValidEventPath = () => {
    if (schedule.length === 0) return null;
    const nextValidEvent = schedule.find((futureEvent) => {
      return futureEvent.isValid() && futureEvent.id !== eventId;
    });
    return nextValidEvent ? nextValidEvent.getPath() : null;
  };

  const nextValidEventPath = getNextValidEventPath();

  const title = getExpiredEventTitle(nextValidEventPath);
  const subTitle = nextValidEventPath
    ? 'Get tickets for the next event.'
    : 'Choose a similar performer.';

  return (
    <div className={styles['invalid-event-map']}>
      <div className={styles.banner}>
        <img
          className={styles['past-event-img']}
          src={PastEventImage}
          alt="Past Event"
        />
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subTitle}</p>

        <div className={styles.cta}>
          {nextValidEventPath ? (
            <ThemedButtonLoader
              backgroundColor={colors.gametimeGreen}
              color={colors.white}
              to={nextValidEventPath}
              rounded
            >
              NEXT EVENT
            </ThemedButtonLoader>
          ) : (
            <div>
              {performersByCategory.slice(0, 3).map((performer) => (
                <ThemedButtonLoader
                  to={performer.getPath()}
                  backgroundColor={colors.gametimeGreen}
                  color={colors.white}
                  key={performer.slug}
                  rounded
                >
                  {performer.name}
                </ThemedButtonLoader>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InvalidEvent.propTypes = {
  schedule: PropTypes.array,
  performersByCategory: PropTypes.array,
  eventId: PropTypes.string.isRequired,
};

export default InvalidEvent;
