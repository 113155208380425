import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Image from 'components/Image/Image';
import MLBMarketplaceTag from 'components/MLBMarketplaceTag/MLBMarketplaceTag';

import styles from './HeroSection.module.scss';

const HeroSection = ({
  imageProps,
  title,
  className,
  breadcrumbProps,
  showMLBMarketplaceTag,
  heroLogo,
}) => {
  const showHeroLogo = !!heroLogo;
  return (
    <div className={classNames([styles['header-container']], className)}>
      <div className={styles['header-image-container']}>
        {imageProps && <Image {...imageProps} />}
        <div className={styles['bottom-fade']} />
      </div>
      {(title || breadcrumbProps) && (
        <Container
          className={classNames(styles['title-grid'], {
            [styles['hero-logo']]: showHeroLogo,
          })}
        >
          {heroLogo}
          <div className={styles['title-grid-main']}>
            <div className={styles['title-container']}>
              {breadcrumbProps && <Breadcrumbs {...breadcrumbProps} />}
              {title && <h1 className={styles.title}>{title}</h1>}
            </div>
            {showMLBMarketplaceTag && <MLBMarketplaceTag />}
          </div>
        </Container>
      )}
    </div>
  );
};

HeroSection.propTypes = {
  imageProps: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
  breadcrumbProps: PropTypes.object,
  showMLBMarketplaceTag: PropTypes.bool,
  heroLogo: PropTypes.node,
};

export default HeroSection;
