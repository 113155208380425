import React from 'react';

import { useMediaQuery } from 'hooks/useMediaQuery';
import GTGuaranteeFillIcon from 'icons/GTGuaranteeFillIcon';

import styles from './GTGuaranteeInlineBanner.module.scss';

const GTGuaranteeInlineBanner = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const iconSize = isMobile ? 48 : 56;

  return (
    <div className={styles['gt-guarantee-container']}>
      <div className={styles['gt-guarantee']}>
        <div className={styles['gt-guarantee-icon']}>
          <GTGuaranteeFillIcon width={iconSize} height={iconSize} />
        </div>
        <div className={styles['gt-guarantee-text']}>
          <p className={styles['gt-guarantee-header']}>
            Verified Tickets at the Best Prices.
            <span className={styles['gt-guarantee-header-extended']}>
              100% Gametime Guaranteed.
            </span>
          </p>
          <p className={styles['gt-guarantee-subheader']}>
            100% Gametime Guaranteed.
          </p>
          <ul className={styles['gt-guarantee-perks']}>
            <li className={styles['gt-guarantee-perk']}>
              Lowest Price Guarantee
            </li>
            <li className={styles['gt-guarantee-perk']}>
              Event Cancellation Protection
            </li>
            <li className={styles['gt-guarantee-perk']}>
              On-Time Ticket Delivery
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GTGuaranteeInlineBanner;
