import { ValueOf } from 'type-fest';

export const SIDEBAR_VIEWS = {
  PAST: 'past',
  PROFILE: 'profile',
  PROMO: 'promo',
  UPCOMING: 'upcoming',
} as const;

export type SidebarView = ValueOf<typeof SIDEBAR_VIEWS>;
