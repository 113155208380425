/* eslint-disable react/prop-types */
import React from 'react';
import { useMaskito } from '@maskito/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import { maskitoPhoneOptions, normalizePhoneForMaskito } from 'utils/phone';

import styles from './UserNamePhoneForm.module.scss';

const UserNamePhoneFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First name is required.'),
  lastName: Yup.string().trim().required('Last name is required.'),
  phone: Yup.string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter a valid phone number.'
    )
    .trim()
    .required('Please enter a valid phone number'),
});

const UserNamePhoneForm = ({
  handleSubmit,
  mpActions,
  ctaText = 'CONFIRM',
  clickTracker,
}) => {
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      await handleSubmit(values);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  const phoneInputRef = useMaskito({
    options: maskitoPhoneOptions,
  });

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', phone: '' }}
      onSubmit={onSubmitHandler}
      validationSchema={UserNamePhoneFormValidationSchema}
    >
      {(props) => {
        const {
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles['name-wrapper']}>
              <TextInput
                id="user-first-name"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.firstName && touched.firstName,
                  message: errors.firstName,
                }}
                required
              />
              <TextInput
                id="user-last-name"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.lastName && touched.lastName,
                  message: errors.lastName,
                }}
                required
              />
            </div>
            <TextInput
              id="user-phone"
              name="phone"
              label="Phone Number"
              placeholder="(415) 123-4567"
              type="tel"
              ref={phoneInputRef}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.phone && touched.phone,
                message: errors.phone,
              }}
              value={normalizePhoneForMaskito(values.phone)}
              required
            />
            <DarkThemedFormButtonLoader
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              mpActions={mpActions}
              clickTracker={clickTracker}
              showBackground
            />
          </Form>
        );
      }}
    </Formik>
  );
};

UserNamePhoneForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  mpActions: PropTypes.object,
  ctaText: PropTypes.string,
  clickTracker: PropTypes.object,
};

export default UserNamePhoneForm;
