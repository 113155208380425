import { EventSchema } from './event.schema';
import { ListingSchema } from './listing.schema';
import { MetrosSchema } from './metro.schema';
import { PerformersSchema } from './performer.schema';
import { SCHEMA_ENTITIES } from './schemaEntities';
import { VenueSchema } from './venue.schema';

const MERGE_ENTITIES = [SCHEMA_ENTITIES.PERFORMERS, SCHEMA_ENTITIES.EVENTS];

export {
  ListingSchema,
  VenueSchema,
  EventSchema,
  PerformersSchema,
  MetrosSchema,
  MERGE_ENTITIES,
};
