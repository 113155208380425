import React from 'react';

export default (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={props.fill || '#FFF'}
        d="M19.42 5.588l-6.75 13.5c-.13.26-.39.41-.67.41-.06 0-.12-.01-.18-.02a.75.75 0 0 1-.57-.73c0-3.31-2.69-6-6-6a.752.752 0 1 1-.34-1.42l13.5-6.75a.76.76 0 0 1 .87.14c.23.23.28.58.14.87"
      />
    </g>
  </svg>
);
