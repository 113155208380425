module.exports = {
  domain: 'web-testing.gametime.co',
  API_URL: 'https://testing.gametime.co',
  MOBILE_API_URL: 'https://mobile-testing.gametime.co',
  appleAppAssociationConstants: {
    universalLinkSuffix: '.beta',
  },
  PUSHER_KEY: '6020149ea8c2fbe74232',
  BRAZE: {
    API_KEY: '5d58f8d7-3186-4377-b3de-2a8d8c0e00b9',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
};
