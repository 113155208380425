import config from 'config/config';
import { HttpClient, RequestOptions } from 'services/HttpClient/HttpClient';
import { PostInsuranceQuote } from 'types';

interface PostInsuranceQuoteOptions {
  eventId: string;
  /**
   * quantity of tickets to insure
   */
  quantity: number;
  /**
   * Total purchase price including fees and taxes in dollars, subtracting
   * promo discounts. This is the total price for all tickets, not per ticket.
   */
  adjustedTotal: number;
  /**
   * authenticated user details
   */
  authUser: NonNullable<RequestOptions['authUser']>;
  /**
   * Optional zip code for the quote. If not provided, the IP address will be
   * used to create the initial quote. The inferred ZIP code will be included
   * in the response, and we can allow the user to update it if necessary.
   */
  zipCode?: string;
}

const httpClient = new HttpClient(config.MOBILE_API_URL);

/**
 * POST request to create an insurance quote package. The response also
 * includes eligibility results which should be used to show or hide the
 * insurance options during checkout.
 *
 * Note that the endpoint expects the total_price to be in cents, so we convert
 * from dollars to cents before sending the request.
 */
export async function postInsuranceQuote({
  eventId: event_id,
  quantity,
  adjustedTotal,
  authUser,
  zipCode,
}: PostInsuranceQuoteOptions) {
  return httpClient.request<PostInsuranceQuote>({
    path: '/v1/insurance/quote',
    method: 'POST',
    authUser,
    body: {
      event_id,
      quantity,
      total_price: adjustedTotal * 100,
      customer_postcode: zipCode,
    },
  });
}
