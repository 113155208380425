import { createSelector } from 'reselect';

import FullEvent from 'models/FullEvent';
import { isObjectEmpty } from 'utils/objects';

const _selectUpsellEvents = (state) => state.data.upsellEvents;
const _selectUpsellEventsEventId = (state, eventId) =>
  _selectUpsellEvents(state)[eventId];

export const selectUpsellEventsByEventId = createSelector(
  _selectUpsellEventsEventId,
  (eventsObjects) => {
    if (isObjectEmpty(eventsObjects)) {
      return null;
    }

    return eventsObjects.map((eventObject) => new FullEvent(eventObject));
  }
);
