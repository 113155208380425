export const FETCH_MLB_CONTENT = 'FETCH_MLB_CONTENT';
export const FETCH_MLB_CONTENT_FAIL = 'FETCH_MLB_CONTENT_FAIL';
export const FETCH_MLB_CONTENT_SUCCESS = 'FETCH_MLB_CONTENT_SUCCESS';

export const fetchMLBTeamLinksContent = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_MLB_CONTENT,
      FETCH_MLB_CONTENT_SUCCESS,
      FETCH_MLB_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('*', 'mlb-team-links'),
  });
