import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';

import styles from './Sections.module.scss';

const CATEGORY_TO_CATGRP = {
  sports: (metroId) => `sport/${metroId}`,
  music: '/c/concert',
  shows: '/c/theater',
};

function SectionLink({ isSports = false, category, city, id, onClick }) {
  const handleClick = () => {
    if (onClick) {
      return onClick(id);
    }
  };

  if (isSports) {
    return (
      <Link
        className={styles['city-button']}
        to={CATEGORY_TO_CATGRP[category.toLowerCase()](id)}
      >
        {`${category} in ${city}`}
      </Link>
    );
  }

  return (
    <a
      className={styles['city-button']}
      onClick={handleClick}
      onKeyDown={handleClick}
    >{`${category} in ${city}`}</a>
  );
}

SectionLink.propTypes = {
  isSports: PropTypes.bool,
  category: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Sections({ category, allMetros, updateUserPreference, router }) {
  const reRouteToMusicShows = (id) => {
    if (!updateUserPreference) {
      return null;
    }

    return Promise.resolve(updateUserPreference({ lastVisitedMetro: id })).then(
      () => router.navigate(CATEGORY_TO_CATGRP[category.toLowerCase()])
    );
  };

  return (
    <div className={styles['sections-container']}>
      <div className={styles['section-title']}>{category} </div>
      <div className={styles['section-cities']}>
        {allMetros.map((m) => {
          const { id, city } = m;
          return (
            <SectionLink
              key={`${category}_${city}`}
              category={category}
              city={city}
              id={id}
              onClick={reRouteToMusicShows}
              isSports={category.toLowerCase() === 'sports'}
            />
          );
        })}
      </div>
    </div>
  );
}

Sections.propTypes = {
  category: PropTypes.string.isRequired,
  allMetros: PropTypes.array.isRequired,
  updateUserPreference: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default Sections;
