export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkError';
  }
}

export class HttpError extends Error {
  status: number;
  _body: unknown;

  // error body can be a JSON parsed object
  get body(): undefined | Record<string, string | number | boolean> {
    if (typeof this._body === 'object' && this._body !== null) {
      return this._body as Record<string, string | number | boolean>;
    }
    return undefined;
  }

  constructor(message: string, status: number, body?: unknown) {
    super(message);
    this.name = 'HttpError';
    this.status = status;
    this._body = body;
  }
}

export class JsonParseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'JsonParseError';
  }
}

export class FormSubmitError extends Error {
  constructor(public errors: { [field: string]: string }) {
    super('Form submission failed');
    this.name = 'FormSubmitError';
  }
}
