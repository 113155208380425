import { appConfigSelector } from 'store/modules/app/app.selectors';
import poller from 'store/modules/helpers/poller';
import { updateUser } from 'store/modules/user/actions';
import {
  selectUserDetails,
  selectUserForterToken,
} from 'store/modules/user/user.selectors';
import {
  selectCompleteUserPurchasesStatus,
  userPurchaseBraintreeDataSelector,
} from 'store/modules/userPurchases/userPurchases.selectors';
import { REQUEST_STATUS } from 'utils/requestStatuses';

import { USER_PURCHASE_ACTION_TYPES } from './userPurchases.actionsTypes';

export const saveUserBraintreeData = (braintreeDeviceData) => ({
  type: USER_PURCHASE_ACTION_TYPES.SAVE_BRAINTREE_DEVICE_DATA,
  braintreeDeviceData,
});

export const createUserPurchase = (user, data) => (dispatch, getState) =>
  dispatch({
    types: [
      USER_PURCHASE_ACTION_TYPES.CREATE_USER_PURCHASE,
      USER_PURCHASE_ACTION_TYPES.CREATE_USER_PURCHASE_SUCCESS,
      USER_PURCHASE_ACTION_TYPES.CREATE_USER_PURCHASE_FAIL,
    ],
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/purchases',
        method: 'POST',
        headers: {
          'X-Gt-Riskified-Beacon': appConfigSelector(getState()).webSessionId,
          'X-Gt-Forter-DeviceId': selectUserForterToken(getState()),
        },
        body: {
          braintree_device_data: userPurchaseBraintreeDataSelector(getState()),
          version: appConfigSelector(getState()).version,
          ...data,
        },
        authUser: user,
      }),
  });

export const removeUserCardSuccessAction = (cardToken) => ({
  type: USER_PURCHASE_ACTION_TYPES.REMOVE_USER_CARD_SUCCESS,
  cardToken,
});

const fetchUserPurchaseResult = (user, inData) => {
  const data = {
    ...inData,
    user_id: user.id,
  };
  return {
    types: [
      USER_PURCHASE_ACTION_TYPES.FETCH_USER_PURCHASE_RESULT,
      USER_PURCHASE_ACTION_TYPES.FETCH_USER_PURCHASE_RESULT_SUCCESS,
      USER_PURCHASE_ACTION_TYPES.FETCH_USER_PURCHASE_RESULT_FAIL,
    ],
    promise: (httpClient) =>
      httpClient
        .request({
          path: `/v1/purchases/${data.transaction_id}/result`,
          authUser: user,
        })
        .then((result) => ({ ...result, ...inData })),
  };
};

export const fetchCompleteUserPurchase = (purchaseId, headers) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASE,
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASE_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASE_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v2/purchases/${purchaseId}`,
      headers,
    }),
});

export const pollFetchUserPurchaseResult = (dispatch, user, inData) => {
  const timeout = 60000;
  const interval = 1000;
  const pollFn = () => dispatch(fetchUserPurchaseResult(user, inData));

  const checkFn = (result) => {
    if (result.action === 'retry') {
      return false;
    }
    return true;
  };

  const errorFn = (error) => new Error(error);

  const pollingPromise = (resolve, reject) => {
    poller(timeout, interval, pollFn, checkFn, errorFn)
      .then((result) => resolve(result))
      .catch((error) => reject(new Error(error)));
  };

  return new Promise(pollingPromise);
};

export const fetchCompleteUserPurchases = (headers) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES,
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: '/v1/purchases',
      headers,
    }),
});

export const fetchUserPurchasesIfIdle = (dispatch, getState) => {
  const state = getState();
  const user = selectUserDetails(state);
  const completeUserPurchasesStatus = selectCompleteUserPurchasesStatus(state);

  if (user && completeUserPurchasesStatus === REQUEST_STATUS.IDLE) {
    return dispatch(
      fetchCompleteUserPurchases({
        user_id: user.id,
        session_token: user.session_token,
      })
    );
  }
};

/* This should be separated out */

export const updateUserPaymentAddress = (user, purchaseId, body) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_PAYMENT_ADDRESS,
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_PAYMENT_ADDRESS_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_PAYMENT_ADDRESS_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/purchases/${purchaseId}/billing-address`,
      method: 'POST',
      body,
      authUser: user,
    }),
});

export const updateUserDeliveryAddress = (user, purchaseId, body) => ({
  types: [
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DELIVERY_ADDRESS,
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DELIVERY_ADDRESS_SUCCESS,
    USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DELIVERY_ADDRESS_FAIL,
  ],
  promise: (httpClient) =>
    httpClient.request({
      path: `/v1/purchases/${purchaseId}/shipping-address`,
      method: 'POST',
      body,
      authUser: user,
    }),
});

export const updateUserDefaultPayment = (cardToken) => (dispatch, getState) => {
  const user = selectUserDetails(getState());
  const updatedUser = {
    ...user,
    default_card_token: cardToken,
  };

  dispatch({
    type: USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DEFAULT_PAYMENT_TOKEN,
  });

  return dispatch(updateUser(updatedUser))
    .then(() => {
      dispatch({
        type: USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DEFAULT_PAYMENT_TOKEN_SUCCESS,
        defaultCardToken: cardToken,
      });
    })
    .catch(() =>
      dispatch({
        type: USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DEFAULT_PAYMENT_TOKEN_FAIL,
      })
    );
};
