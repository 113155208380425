import React, { Component } from 'react';
import classNames from 'classnames';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import { CEO_JSON_LD, ORGANIZATION_JSON_LD } from 'components/JsonLD/constants';
import { getWebSiteJsonLD } from 'components/JsonLD/helpers/webSite/webSite';
import JsonLD from 'components/JsonLD/JsonLD';
import Link from 'components/Link/Link';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import homeFeatures from 'pages/Home/home.features';
import { fetchMetros } from 'store/modules/resources/resource.actions';

import styles from './About.module.scss';

@TrackPageView(() => ({
  pageType: View.PAGE_TYPES.STATIC_PAGE('about'),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.STATIC_PAGE('about'),
}))
class About extends Component {
  renderMeta() {
    return (
      <div>
        <HeadTitle title="About Us" />
        <HeadDescription description="Get last-minute tickets to the most popular events in sports, music, and theater in 60+ cities across the U.S. and Canada. Learn more about Gametime." />
        <JsonLD json={getWebSiteJsonLD()} />
        <JsonLD json={ORGANIZATION_JSON_LD} />
      </div>
    );
  }

  renderContent() {
    return (
      <>
        <div className={styles['module sizzle-module']}>
          <div className={styles['sizzle-image-container']}>
            <div className={styles['sizzle-image']}>
              <h1 className={styles['sizzle-text']}>
                We&rsquo;re in the business of making memories.
              </h1>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.module,
            styles.small,
            styles['headline-module']
          )}
        >
          <div className={styles['container']}>
            <h4>
              At Gametime, we revel in life&rsquo;s impromptu moments &mdash;
              like cheering on your hometown team at a Friday night baseball
              game or dancing the night away at an intimate concert. So, we
              built a ticket marketplace that makes it faster and easier to get
              into your favorite events at a moment&rsquo;s notice.
            </h4>
          </div>
        </div>

        <div className={classNames(styles.module, styles.small)}>
          <div className={styles['container']}>
            <h2>How We Roll</h2>
            <p>
              Our team is made up of sports fans and music buffs dedicated to
              making the ticket buying experience better. We&rsquo;re passionate
              about enabling incredible shared experiences, so we build
              technology that gets people out into the real world together. And
              with a bias for spontaneity, we&rsquo;re focused on making the
              process simple and smooth while you&rsquo;re on the move.
            </p>
            <div className={classNames(styles['highlight-shell'], styles.row)}>
              <div
                className={classNames(styles['col-xs-12'], styles['col-sm-4'])}
              >
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/about/icon-mobile-first.svg"
                    className={styles['highlight-img']}
                    alt="Mobile First"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Mobile First</h4>
                    <p>
                      We&rsquo;re built for mobile, so you can buy tickets and
                      access events all from your phone. No printer needed.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles['col-xs-12'], styles['col-sm-4'])}
              >
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/about/icon-transparent-pricing.svg"
                    className={styles['highlight-img']}
                    alt="Transparent Pricing"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Transparent Pricing</h4>
                    <p>
                      We're committed to clear, competitive pricing, showing you
                      the full cost of your tickets up front, with All-In
                      Pricing toggled on - no hidden fees!
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles['col-xs-12'], styles['col-sm-4'])}
              >
                <div>
                  <img
                    src="https://assets.gametime.co/static_pages/about/icon-guaranteed-tickets.svg"
                    className={styles['highlight-img']}
                    alt="Guaranteed Tickets"
                  />
                  <div className={styles['highlight-copy']}>
                    <h4>Guaranteed Tickets</h4>
                    <p>
                      Our Gametime Guarantee ensures you&rsquo;ll have your
                      tickets in time for the event and that they&rsquo;ll be
                      valid for entry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.module,
            styles.small,
            styles['aha-module']
          )}
        >
          <div className={styles['container']}>
            <div className={styles.row}>
              <div
                className={classNames(
                  styles['col-xs-12'],
                  styles['col-sm-8'],
                  styles['custom-aha-h2']
                )}
              >
                <h2>The &lsquo;Aha!&rsquo; Moment</h2>
                <p>
                  In 2012, Gametime founder Brad Griffith nearly missed a San
                  Francisco Giants game after scrambling to print last-minute
                  tickets in the basement of a local sports bar. Though he
                  eventually made it in to see the Giants win the National
                  League Championship, the experience stuck with him. Brad was
                  convinced there should be a faster, easier way to get into the
                  game. Not long after, Gametime was born.
                  <br />
                  <br />
                  Today, Gametime provides the premier marketplace for
                  last-minute tickets to the most popular events in sports,
                  music, and theater in more than 60 cities across the U.S. and
                  Canada. We eliminated the need for printing &mdash; no more
                  paper jams and bar basements! &mdash; and built a better way
                  to access the best live experiences, right from your phone.
                </p>
              </div>
              <div
                className={classNames(
                  styles['col-xs-12'],
                  styles['col-sm-4'],
                  styles['brad-griffith-profile-container']
                )}
              >
                <img
                  src="https://assets.gametime.co/static_pages/about/brad-griffith-profile-pic.png"
                  className={styles['brad-griffith-profile-image']}
                  alt="Brad Griffith"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.module} ${styles['why-different-module']}`}>
          <div className={styles['container']}>
            <div className={styles['why-different-header']}>
              Why We&rsquo;re Different
            </div>
            <p className={styles['why-different-subheader']}>
              We aren&rsquo;t the biggest, but we are committed to being the
              best. Our marketplace makes buying and selling event tickets a
              breeze, so you can skip the hassle and enjoy the moment.
              <br />
              <br />
              <br />
              The Gametime experience is designed to get you into your event
              seamlessly every time. We curate our listings to show only the
              best deals, instead of an impossibly long list of options. Use our
              panoramic seat view photos to choose your view before you buy;
              purchase in just two taps; then easily share tickets with friends
              via text. Playing it by ear? You can even grab tickets up to 90
              minutes after start time with LastCall. Because planning is
              overrated.
            </p>
          </div>
        </div>

        <div className={styles['line-break']} />

        <div
          className={`${styles.module} ${styles['shared-experience-module']}`}
        >
          <div className={styles['container']}>
            <div className={styles['shared-header']}>
              Uniting people through shared experiences
            </div>
            <div className={styles.row}>
              <div className={styles['shared-callout']}>
                <div className={styles['shared-number']}>5,423</div>
                <div className={styles['shared-copy']}>Teams &amp; Artists</div>
              </div>
              <div className={styles['shared-callout']}>
                <div className={styles['shared-number']}>148,308</div>
                <div className={styles['shared-copy']}>Events</div>
              </div>
              <div className={styles['shared-callout']}>
                <div className={styles['shared-number']}>61</div>
                <div className={styles['shared-copy']}>
                  Cities in the US &amp; Canada
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.module} ${styles['leadership-module']}`}>
          <div className={styles['container']}>
            <h2>Our Leadership Team</h2>
            <div className={styles.row}>
              <div className={styles['leadership-shell']}>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-brad-griffith.png"
                      alt="Brad Griffith"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Brad Griffith</div>
                  <div className={styles['leadership-title']}>
                    CEO &amp; Founder
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-michael-kunde.png"
                      alt="Michael Kunde"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Michael Kunde</div>
                  <div className={styles['leadership-title']}>
                    Chief Financial Officer
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/larry_martin.png"
                      alt="Larry Martin"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Larry Martin</div>
                  <div className={styles['leadership-title']}>
                    SVP of Business Development
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-ryan-miller.png"
                      alt="Ryan Miller"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Ryan Miller</div>
                  <div className={styles['leadership-title']}>
                    Head of People Ops
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-pete-deitos.png"
                      alt="Pete DeiTos"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Pete DeiTos</div>
                  <div className={styles['leadership-title']}>
                    VP of Product
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-marco-huerta.png"
                      alt="Marco Huerta"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Marco Huerta</div>
                  <div className={styles['leadership-title']}>
                    VP of Engineering
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-kathy-dalpes.png"
                      alt="Kathy Dalpes"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Kathy Dalpes</div>
                  <div className={styles['leadership-title']}>
                    VP of Customer Service
                  </div>
                </div>
                <div className={styles['leadership-role']}>
                  <div className={styles['leadership-img-container']}>
                    <img
                      className={styles['leadership-img']}
                      src="https://assets.gametime.co/static_pages/about/leadership-john.png"
                      alt="Jon Gordon"
                    />
                  </div>
                  <div className={styles['leadership-name']}>Jon Gordon</div>
                  <div className={styles['leadership-title']}>
                    VP of Creative
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.module,
            styles['action-callouts-module']
          )}
        >
          <div className={styles['container']}>
            <div className={styles.row}>
              <div
                className={classNames(styles['col-xs-12'], styles['col-sm-6'])}
              >
                <Link to="/careers">
                  <div className={styles['callout-container']}>
                    <div className={styles['callout-header']}>
                      We&rsquo;re hiring!
                    </div>
                    <div className={styles['callout-subheader']}>
                      We&rsquo;re growing fast and looking for curious,
                      motivated people to join us.
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderPress() {
    return (
      <div className={classNames(styles.module, styles['press-module'])}>
        <div className={styles['container']}>
          <div className={styles.features}>
            {homeFeatures.map((feature) => (
              <a
                href={feature.href}
                target="_blank"
                rel="noopener noreferrer"
                key={feature.name}
                className={classNames(styles['feature-image'], feature.name)}
              >
                <img loading="lazy" alt={feature.name} {...feature.imgProps} />
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <GTContainer
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
        className={styles['about-container']}
      >
        {this.renderMeta()}
        {this.renderContent()}
        {this.renderPress()}
        <JsonLD json={CEO_JSON_LD} />
      </GTContainer>
    );
  }
}

const loader =
  (_context) =>
  async ({ context: { store } = _context }) => {
    await store.dispatch(fetchMetros());

    return null;
  };

About.loader = loader;

export default About;
