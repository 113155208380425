import React from 'react';

type Props = Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'fill'>;

const RemoveCircleLineIcon = ({
  width = '24',
  height = '24',
  fill = '#5F5F62',
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="8" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9L15.0088 8L12 11.0355L8.99119 8L8 9L11.0088 12.0355L8.07043 15L9.06162 16L12 13.0355L14.9384 16L15.9296 15L12.9912 12.0355L16 9Z"
      fill="#0E0E0F"
    />
  </svg>
);

export default RemoveCircleLineIcon;
