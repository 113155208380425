import React from 'react';
import { Helmet } from 'react-helmet-async';

import { domain as productionDomain } from 'config/production.config';
import { getUrlFromRelativePathname } from 'store/modules/location';

type Props = {
  path: string;
  query?: object;
};

export const CanonicalPath = ({ path, query }: Props) => {
  if (!path && path !== '') {
    return null;
  }

  const url = getUrlFromRelativePathname(path, productionDomain, query);
  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
    </Helmet>
  );
};

export default CanonicalPath;
