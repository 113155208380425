export const FETCH_VENUE_CONTENT = 'FETCH_VENUE_CONTENT';
export const FETCH_VENUE_CONTENT_FAIL = 'FETCH_VENUE_CONTENT_FAIL';
export const FETCH_VENUE_CONTENT_SUCCESS = 'FETCH_VENUE_CONTENT_SUCCESS';

export const fetchVenueContentBySlug = (slug) => (dispatch) =>
  dispatch({
    types: [
      FETCH_VENUE_CONTENT,
      FETCH_VENUE_CONTENT_SUCCESS,
      FETCH_VENUE_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('venues', slug),
  });
