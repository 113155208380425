import React from 'react';

import colors from 'styles/colors.constants';

interface Props {
  width?: string;
  height?: string;
  fill?: string;
}

export default function SwapIcon({
  width = '24',
  height = '24',
  fill = colors.white,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="swap-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18164 12.3182C7.0059 12.4939 6.72098 12.4939 6.54524 12.3182L2.36344 8.13635C2.01197 7.78488 2.01197 7.21503 2.36344 6.86356L6.54524 2.68176C6.72098 2.50602 7.0059 2.50602 7.18164 2.68176L7.81803 3.31815C7.99377 3.49389 7.99377 3.77882 7.81803 3.95455L4.27263 7.49996L7.81803 11.0454C7.99377 11.2211 7.99377 11.506 7.81803 11.6818L7.18164 12.3182Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8184 11.6818C16.9941 11.5061 17.279 11.5061 17.4547 11.6818L21.6366 15.8636C21.988 16.2151 21.988 16.785 21.6366 17.1364L17.4548 21.3182C17.279 21.494 16.9941 21.494 16.8184 21.3182L16.182 20.6818C16.0062 20.5061 16.0062 20.2212 16.182 20.0454L19.7274 16.5L16.1819 12.9546C16.0062 12.7789 16.0062 12.494 16.1819 12.3182L16.5001 12L16.8184 11.6818Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1 7.94998C20.1 8.1985 19.8986 8.39998 19.65 8.39998H4.35002C4.1015 8.39998 3.90002 8.1985 3.90002 7.94998V7.04998C3.90002 6.80145 4.1015 6.59998 4.35002 6.59998H19.65C19.8986 6.59998 20.1 6.80145 20.1 7.04998V7.94998Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69998 16.05C5.69998 15.8015 5.90145 15.6 6.14998 15.6L19.65 15.6C19.8985 15.6 20.1 15.8015 20.1 16.05L20.1 16.95C20.1 17.1986 19.8985 17.4 19.65 17.4L6.14998 17.4C5.90145 17.4 5.69998 17.1986 5.69998 16.95L5.69998 16.05Z"
        fill={fill}
      />
    </svg>
  );
}
