import React from 'react';
import PropTypes from 'prop-types';

import styles from './Responsive.module.scss';

const propTypes = {
  xs: PropTypes.node,
  sm: PropTypes.node,
  md: PropTypes.node,
  lg: PropTypes.node,
  xl: PropTypes.node,
};

const getBreakpointsClassNames = (breakpointsAvailable) => {
  const breakpointsClassNames = {
    xs: [],
    sm: [],
    md: [],
    lg: [],
    xl: [],
  };

  let lastAvailableBreakpointKey = null;
  Object.keys(breakpointsAvailable).forEach((key) => {
    if (breakpointsAvailable[key]) {
      lastAvailableBreakpointKey = key;
    }

    breakpointsClassNames[lastAvailableBreakpointKey].push(key);
  });

  Object.keys(breakpointsClassNames).forEach((key) => {
    breakpointsClassNames[key] = breakpointsClassNames[key].join('-');
  });

  return breakpointsClassNames;
};

const Responsive = (props) => {
  const { xs, sm, md, lg, xl } = props;
  const breakpointsAvailable = {
    xs: !!xs,
    sm: !!sm,
    md: !!md,
    lg: !!lg,
    xl: !!xl,
  };
  const breakpointsClassNames = getBreakpointsClassNames(breakpointsAvailable);

  return (
    <span>
      {Object.keys(breakpointsClassNames).map(
        (key) =>
          key && (
            <span
              key={key}
              className={
                breakpointsClassNames[key] !== 'xs-sm-md-lg-xl'
                  ? styles[breakpointsClassNames[key]]
                  : ''
              }
            >
              {props[key]}
            </span>
          )
      )}
    </span>
  );
};

Responsive.propTypes = propTypes;

export default Responsive;
