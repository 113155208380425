export const TOKEN_METHOD = {
  EMAIL: 'email',
  SMS: 'sms',
} as const;

export const LOGIN_STEPS = {
  USER_EMAIL: 'USER_EMAIL',
  LOGIN_OPTIONS: 'LOGIN_OPTIONS',
  SMS_CODE: 'SMS_CODE',
  MAGIC_LINK_SUCCESS: 'MAGIC_LINK_SUCCESS',
};
