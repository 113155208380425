import _get from 'lodash/get';

import {
  CARD_TYPES,
  isNonTokenBasedPaymentType,
  isSupportedPaymentType,
} from 'store/datatypes/PAYMENT_TYPES';
import { USER_ACTION_TYPES } from 'store/modules/user/user.actionTypes';
import applePayReducer, {
  applePayInitialState,
} from 'store/modules/userPurchases/applePay/applePay';
import creditCardReducer, {
  creditCardInitialState,
} from 'store/modules/userPurchases/creditCard/creditCard';
import { REQUEST_STATUS } from 'utils/requestStatuses';

import { USER_PURCHASE_ACTION_TYPES } from './userPurchases.actionsTypes';

/* ********************* REDUCERS *********************** */

const initialState = {
  applePay: applePayInitialState,
  paypal: {
    paypalNonce: null,
  },
  creditCard: creditCardInitialState,
  defaultCardToken: null,
  purchases: {},
  activePurchaseId: null,
  braintreeDeviceData: null,
  completePurchases: {},
  completePurchasesStatus: REQUEST_STATUS.IDLE,
};

const selectCardTokenForSupportedNonTokenTypes = (
  paymentInformation,
  defaultCardToken
) => {
  const { statusChecked, available } = paymentInformation;

  if (statusChecked && !available) {
    return null;
  }

  return defaultCardToken;
};

export default function reducer(state = initialState, action = {}) {
  const getUpdatedDefaultToken = (matchingToken) => {
    const {
      creditCard: { cardsFetched },
    } = state;
    let updatedDefaultToken = state.defaultCardToken;
    const available = _get(action, 'result.available', false);

    if (
      cardsFetched &&
      state.defaultCardToken === matchingToken &&
      !available
    ) {
      const availableCards = Object.values(state.creditCard.cards);
      if (availableCards.length) {
        updatedDefaultToken = availableCards[0].token;
      } else {
        updatedDefaultToken = null;
      }
    }

    return updatedDefaultToken;
  };

  switch (action.type) {
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_SUCCESS:
    case USER_PURCHASE_ACTION_TYPES.INIT_APPLE_PAY_FAIL:
      return {
        ...state,
        applePay: applePayReducer(state.applePay, action),
        defaultCardToken: getUpdatedDefaultToken(CARD_TYPES.APPLEPAY),
      };
    case USER_PURCHASE_ACTION_TYPES.FETCH_USER_CARDS_SUCCESS:
      const { default_card_token } = action.result.card_data;
      const activeCards = action.result.card_data.cards.filter(
        (card) => !card.expired
      );
      let newDefaultCardToken = null;

      if (isNonTokenBasedPaymentType(default_card_token)) {
        if (
          isSupportedPaymentType(default_card_token) &&
          default_card_token === CARD_TYPES.APPLEPAY
        ) {
          newDefaultCardToken = selectCardTokenForSupportedNonTokenTypes(
            state.applePay,
            default_card_token
          );
        }
      } else {
        const defaultCard = activeCards.find(
          (card) => card.token === default_card_token
        );
        if (defaultCard) {
          newDefaultCardToken = default_card_token;
        }
      }

      if (!newDefaultCardToken) {
        if (activeCards.length) {
          newDefaultCardToken = activeCards[0].token;
        }
      }

      return {
        ...state,
        defaultCardToken: newDefaultCardToken,
        creditCard: creditCardReducer(state.creditCard, action),
      };
    case USER_PURCHASE_ACTION_TYPES.CREATE_USER_CARD_SUCCESS:
    case USER_PURCHASE_ACTION_TYPES.SAVE_PURCHASE_ZIP:
      return {
        ...state,
        creditCard: creditCardReducer(state.creditCard, action),
      };
    case USER_PURCHASE_ACTION_TYPES.REMOVE_USER_CARD_SUCCESS:
      const { cards } = state.creditCard;

      if (!action.cardToken || Object.keys(cards).length === 0) {
        return state;
      }

      const updatedCards = Object.keys(cards).reduce((result, tokenId) => {
        if (tokenId !== action.cardToken) {
          result[tokenId] = cards[tokenId];
        }
        return result;
      }, {});

      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          cards: updatedCards,
        },
      };

    case USER_PURCHASE_ACTION_TYPES.UPDATE_USER_DEFAULT_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        defaultCardToken: action.defaultCardToken,
      };

    case USER_ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        applePay: state.applePay,
        creditCard: creditCardReducer(state.creditCard, action),
      };

    case USER_PURCHASE_ACTION_TYPES.CREATE_USER_PURCHASE_SUCCESS:
      return {
        ...state,
        activePurchaseId: action.result.purchase_id,
      };

    case USER_PURCHASE_ACTION_TYPES.FETCH_USER_PURCHASE_RESULT_SUCCESS:
      const purchaseResult = action.result;
      return {
        ...state,
        purchases: {
          ...state.purchases,
          [purchaseResult.transaction_id]: purchaseResult,
        },
      };

    case USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASE_SUCCESS:
      return {
        ...state,
        completePurchases: {
          ...state.completePurchases,
          ...{ [action.result.id]: action.result },
        },
      };

    case USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES:
      return {
        ...state,
        completePurchasesStatus: REQUEST_STATUS.LOADING,
      };

    case USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES_SUCCESS:
      let purchasesMap = {};
      if (action.result.purchases) {
        purchasesMap = action.result.purchases.reduce((map, purchase) => {
          map[purchase.id] = purchase;
          return map;
        }, {});
      }

      return {
        ...state,
        completePurchasesStatus: REQUEST_STATUS.SUCCESS,
        completePurchases: {
          ...state.completePurchases,
          ...purchasesMap,
        },
      };

    case USER_PURCHASE_ACTION_TYPES.FETCH_COMPLETE_USER_PURCHASES_FAIL:
      return {
        ...state,
        completePurchasesStatus: REQUEST_STATUS.FAILURE,
      };

    case USER_PURCHASE_ACTION_TYPES.SAVE_BRAINTREE_DEVICE_DATA:
      return {
        ...state,
        braintreeDeviceData: action.braintreeDeviceData,
      };

    default:
      return state;
  }
}
