import { REDUCERS } from 'store/modules/reducer.constants';

export const showAppSpinnerSelector = (globalState) =>
  globalState[REDUCERS.APP].showAppSpinner;
export const appConfigSelector = (globalState) =>
  globalState[REDUCERS.APP].config;
export const appStatusSelector = (globalState) =>
  appConfigSelector(globalState).status;
export const redirectPathSelector = (globalState) =>
  appConfigSelector(globalState).redirectPath;
export const appConfigDomainSelector = (globalState) =>
  appConfigSelector(globalState).domain;
export const isProductionEnvironmentSelector = (globalState) =>
  appConfigSelector(globalState).production;

export const activeMyTicketsViewSelector = (globalState) =>
  globalState[REDUCERS.APP].activeMyTicketsView;

export const branchLinkSelectorByCampaign = (globalState, campaign) =>
  globalState[REDUCERS.APP].branchLinks[campaign];
