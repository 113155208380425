import React from 'react';
import classNames from 'classnames';

import { RadioContextValue, RadioProvider } from '../Radio.context';

import styles from './RadioGroup.module.scss';

interface Props<T> extends RadioContextValue<T> {
  className?: string;
  children: React.ReactNode;
}

export default function RadioGroup<T>({
  name,
  children,
  value,
  onChange,
  className,
}: Props<T>) {
  return (
    <RadioProvider name={name} value={value} onChange={onChange}>
      <div
        // aria-label or aria-labelledby for the group?
        role="radiogroup"
        className={classNames(styles['radio-group'], className)}
      >
        {children}
      </div>
    </RadioProvider>
  );
}
