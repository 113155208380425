import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link/Link';
import { SUPER_BOWL_FAQ_RELATIVE_URL } from 'utils/superBowl';

import styles from './DetailConfirmationMessageCard.module.scss';

const getMessage = (isPending) => {
  if (isPending) {
    return `We're processing your order and will reach out if we need any additional info. You should receive an order confirmation email at `;
  }

  return 'Your purchase is complete and we sent a confirmation email to ';
};

function _LocalPickupMessage({ email }) {
  return (
    <>
      <h3 className={styles['pickup-header']}>Pick Up In Person</h3>
      <p>
        You will pick up your tickets in person near the venue. Keep an eye out
        for a follow-up email from Gametime at{' '}
        <span className={styles['user-email']}>{email}</span> with the exact
        pick-up time and location.
      </p>
    </>
  );
}

_LocalPickupMessage.propTypes = {
  email: PropTypes.string.isRequired,
};

function _SuperBowlMessage() {
  return (
    <p>
      <span>Questions? Check out our</span>
      <Link
        className={styles['highlight-info']}
        to={SUPER_BOWL_FAQ_RELATIVE_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Super Bowl FAQ
      </Link>
      <span>or contact us at</span>
      <Link className={styles['highlight-info']} href="tel:1-833-741-0574">
        (833) 741-0574
      </Link>
      <span>or</span>
      <Link
        className={styles['highlight-info']}
        to="mailto:support@gametime.co"
        target="_blank"
        rel="noopener noreferrer"
      >
        support@gametime.co
      </Link>
      .
    </p>
  );
}

const SuperBowlMessage = _SuperBowlMessage;
const LocalPickupMessage = _LocalPickupMessage;

function _DetailConfirmationMessageCard({
  user,
  message,
  isSuperBowl,
  isPending,
  isLocalPickup,
}) {
  return (
    <div className={styles['confirmation-container']}>
      {isLocalPickup ? (
        <LocalPickupMessage email={user.email} />
      ) : (
        <>
          <p>
            {getMessage(isPending)}
            <span className={styles['user-email']}>{user.email}</span>.
          </p>
          {message && <p>{message}</p>}
          {isSuperBowl && <SuperBowlMessage />}
        </>
      )}
    </div>
  );
}

_DetailConfirmationMessageCard.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.string,
  isSuperBowl: PropTypes.bool,
  isPending: PropTypes.bool,
  isLocalPickup: PropTypes.bool,
};

export default _DetailConfirmationMessageCard;
