import React from 'react';
import classNames from 'classnames';

import { device, useMediaQuery } from 'hooks';
import CircleQuestionFillIcon from 'icons/CircleQuestionFillIcon';
import colors from 'styles/colors.constants';

import styles from './SupportLink.module.scss';

interface SupportLinkProps {
  icon?: React.ReactNode;
}

const SupportLink = ({ icon }: SupportLinkProps) => {
  const isMobile = useMediaQuery(device.down.md);

  return (
    <li>
      <a
        href="https://support.gametime.co"
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(styles['support-link'], {
          [styles.mobile]: isMobile,
        })}
      >
        <div className={styles['icon-text-container']}>
          <CircleQuestionFillIcon fill={colors.white} />
          <span className={styles.text}>Have A Question?</span>
        </div>
        {icon}
      </a>
    </li>
  );
};
export default SupportLink;
