import React from 'react';

import { PerformerClickTracker } from 'analytics';
import CollectionCard from 'components/CollectionCard/CollectionCard';
import { dataToJsonLD } from 'components/JsonLD/helpers';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { PerformerInContext } from 'models';

import styles from './AllEventsSection.module.scss';

export const ALL_EVENTS_TITLE = 'All Events';

interface AllEventsSectionProps {
  performersInContext: PerformerInContext[];
}

function AllEventsSection({ performersInContext }: AllEventsSectionProps) {
  return (
    <>
      <SectionHeader title={ALL_EVENTS_TITLE} />
      <div className={styles.performers}>
        {performersInContext.map((pic) => {
          return (
            <div key={pic.id()} className={styles['performer-card']}>
              <CollectionCard
                source={pic}
                clickTracker={new PerformerClickTracker(pic.performer)}
                toListingPage
                jsonLD={dataToJsonLD(pic)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AllEventsSection;
