import React from 'react';
import PropTypes from 'prop-types';

import { PerformerClickTracker } from 'analytics';
import GTFullWidthList from 'components/GTFullWidthList/GTFullWidthList';
import {
  CATEGORIES,
  getCategoryPath,
} from 'store/modules/categories/category.helpers';
import { ascSort } from 'utils/sorts';

import { NCAA_CATEGORY } from './constants';

export const NCAA_CATEGORIES = [
  {
    id: CATEGORIES.BOWL,
    text: 'CFB Bowl Games',
    link: getCategoryPath(CATEGORIES.BOWL),
    clickTracker: new PerformerClickTracker(CATEGORIES.BOWL),
  },
  {
    id: CATEGORIES.CBB,
    text: 'College Basketball',
    link: getCategoryPath(CATEGORIES.CBB),
    clickTracker: new PerformerClickTracker(CATEGORIES.CBB),
  },
  {
    id: CATEGORIES.CBS,
    text: 'College Baseball',
    link: getCategoryPath(CATEGORIES.CBS),
    clickTracker: new PerformerClickTracker(CATEGORIES.CBS),
  },
  {
    id: CATEGORIES.CFB,
    text: 'College Football',
    link: getCategoryPath(CATEGORIES.CFB),
    clickTracker: new PerformerClickTracker(CATEGORIES.CFB),
  },
  {
    id: CATEGORIES.CHO,
    text: 'College Hockey',
    link: getCategoryPath(CATEGORIES.CHO),
    clickTracker: new PerformerClickTracker(CATEGORIES.CHO),
  },
  {
    id: CATEGORIES.CLAX,
    text: 'College Lacrosse',
    link: getCategoryPath(CATEGORIES.CLAX),
    clickTracker: new PerformerClickTracker(CATEGORIES.CLAX),
  },
  {
    id: CATEGORIES.WCBB,
    text: `Women's College Basketball`,
    link: getCategoryPath(CATEGORIES.WCBB),
    clickTracker: new PerformerClickTracker(CATEGORIES.WCBB),
  },
];

ascSort(NCAA_CATEGORIES, 'text');

const CategoryPerformersNCAA = (props) => {
  return (
    <GTFullWidthList
      key={NCAA_CATEGORY}
      items={NCAA_CATEGORIES}
      vertical
      onClick={props.onClick}
      listClassName="categoryPerformerList"
    />
  );
};

CategoryPerformersNCAA.propTypes = {
  onClick: PropTypes.func,
};

export default CategoryPerformersNCAA;
