import React from 'react';

import colors from 'styles/colors.constants';

import 'external-svg-loader';

import styles from './ExternalIcon.module.scss';

interface ExternalIconProps {
  src: string;
  color?: string;
  title?: string;
  width?: number;
  height?: number;
  testId?: string;
}

const ExternalIcon = ({
  src,
  color = colors.white,
  title = 'disclosure',
  width = 16,
  height = 16,
  testId = 'external-icon',
}: ExternalIconProps) => {
  return (
    <svg
      role="img"
      data-testid={testId}
      data-src={src}
      aria-label={title}
      width={width}
      height={height}
      className={styles['custom-icon']}
      style={{ '--fill-color': color } as React.CSSProperties}
    />
  );
};

export default ExternalIcon;
